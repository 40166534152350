import { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { isFood, isRecipe } from "../../../../constants/FoodTemplate";
import Firebase from "../../../../data/Firebase";
import { FoodId } from "../../../../data/models/documentProperties/foodId";
import { DocumentSummary } from "../../../../data/models/userDatabase";
import { CurrentDocumentIdSelector } from "../../../../store/data/current-document/selectors/currentDocument";
import { clientUserDatabaseSelector } from "../../../../store/data/selectors/clientDatabase";
import {
  databaseIdSelector,
  userIngredientSummariesSelector,
} from "../../../../store/data/selectors/database";
import { IsAlternateIdsEnabledSelector } from "../../../../store/data/selectors/databaseProperties";
import { RootState } from "../../../../store/reducers";
import { useDatabaseSummaries } from "../../../dialogs/database/hooks/importDocumentsDialog";
import { useDocumentIdentifier } from "../DocumentIdentifier";
import { IngredientSummaryItem } from "./tabs/ingredients/editing_grid/rows/cells/IngredientCell";

const useAllIngredientSummaries = (firebase : Firebase) => {


  const [ingredientSummaries, setIngredientSummaries] = useState<IngredientSummaryItem[]>([])
  const databases = useDatabaseSummaries(firebase);

  useEffect(() => {

    let documentSummaries : IngredientSummaryItem[] = [];

    for (const database of databases) {
      documentSummaries = documentSummaries.concat(database.documentSummaries.map(
        (documentSummary: DocumentSummary): IngredientSummaryItem => {
          return {
            foodId: new FoodId({
              datasourceId: database.summary.id,
              documentId: documentSummary.documentId,
            }),
            label: documentSummary.label,
            isPublic: false,
            state: documentSummary.status,
            templateId: documentSummary.templateId.toString(),
          };
        }
      ));
    }

    setIngredientSummaries(documentSummaries)

  }, [databases])

  return ingredientSummaries

}

const filterSummaries = (
  summaries: IngredientSummaryItem[],
  databaseId: string,
  documentId: string,
) => summaries.filter(
    (item: IngredientSummaryItem): boolean =>
      (isRecipe(item.templateId.toString()) ||
        isFood(item.templateId.toString())) &&
      item.foodId.identifier !== `${databaseId}:${documentId}`
  );


export const useSummaries = (
  publicSummaries: IngredientSummaryItem[], firebase : Firebase
): IngredientSummaryItem[] => {
  const [summaries, setSummaries] = useState<IngredientSummaryItem[]>([]);

  const documentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const clientDatabaseId = useSelector<RootState, string>(clientUserDatabaseSelector)

  const databaseId: string = useSelector<RootState, string>(databaseIdSelector);

  const [documentIdentifier] = useDocumentIdentifier();

  const isAlternateIdsEnabled: boolean = useSelector<RootState, boolean>(
    IsAlternateIdsEnabledSelector
  );

  const userIngredientSummaries: IngredientSummaryItem[] = useSelector<
    RootState,
    IngredientSummaryItem[]
  >(userIngredientSummariesSelector);


const allUserIngredientSummaries : IngredientSummaryItem[] = useAllIngredientSummaries(firebase)
 

  const addIds = useCallback(
    (summaries: IngredientSummaryItem[]) => {
      const newSummaries: IngredientSummaryItem[] = [];
      for (const summary of summaries) {
        const id1: string | undefined = documentIdentifier.get(
          summary.foodId.documentId
        )?.id1;
        const id1String: string = id1 ? ` -- ${id1}` : "";
        const id2: string | undefined = documentIdentifier.get(
          summary.foodId.documentId
        )?.id2;
        const id2String: string | undefined = id2 ? ` -- ${id2}` : "";

        const updatedLabel: string = summary.label + id1String + id2String;

        newSummaries.push({ ...summary, label: updatedLabel });
      }
      return newSummaries;
    },
    [documentIdentifier]
  );

  const getSummaries = useCallback(() => {
    const newSummaries = filterSummaries(
        //This is to allow all food and recipe documents from all user databases to be able to be added to a meal plan/food record within a client 
      databaseId === clientDatabaseId ? allUserIngredientSummaries : userIngredientSummaries,
      databaseId,
      documentId,
    ).concat(publicSummaries);


    setSummaries(isAlternateIdsEnabled ? addIds(newSummaries) : newSummaries);
  }, [
    addIds,
    databaseId,
    documentId,
    publicSummaries,
    userIngredientSummaries,
    isAlternateIdsEnabled,
    clientDatabaseId,
    allUserIngredientSummaries
  ]);

  useEffect(() => {
    getSummaries();
  }, [getSummaries]);

  return summaries;
};

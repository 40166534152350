import React from "react";
import { Breadcrumbs, Typography, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  getClientDashboardRouteData,
  getClientRouteData,
  getDatabaseDashboardRouteData,
  getDatabaseRouteData,
} from "../../data/routing/routing";
import { RootState } from "../../store/reducers";
import { currentRouteSelector } from "../../store/ui/selectors/routing";
import { handleRouteChange } from "../../store/ui/thunks/routing";
import { appTheme } from "../../styling/style";
import { currentClientSelector } from "../../store/data/selectors/clientDatabase";
import { Client } from "../../data/models/client";
import { Document } from "../../data/models/document";
import { documentSelector } from "../../store/data/current-document/selectors/document";
import { databaseNameSelector } from "../../store/data/selectors/database";
import { RouteData } from "../../data/routing/types";

const useStyles = makeStyles(() => ({
  link: {
    cursor: "pointer",
    "&:hover": {
      color: appTheme.colors.gainsbruh,
    },
  },
}));

const FoodworksBreadcrumb = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onNavigateToDatabaseDashboard = () =>
    dispatch(handleRouteChange(getDatabaseDashboardRouteData()));

  const onNavigateToClientDashboard = () =>
    dispatch(handleRouteChange(getClientDashboardRouteData()));

  const onNavigateToDatabase = () =>
    dispatch(
      handleRouteChange(getDatabaseRouteData(currentRoute!.databaseId!))
    );
  const onNavigateToClient = () =>
    dispatch(handleRouteChange(getClientRouteData(currentRoute!.clientId!)));

  const currentRoute: RouteData | undefined = useSelector<
    RootState,
    RouteData | undefined
  >(currentRouteSelector);

  const currentClient: Client = useSelector<RootState, Client>(
    currentClientSelector
  );

  const currentDocument: Document = useSelector<RootState, Document>(
    documentSelector
  );

  const currentDatabaseName: string = useSelector<RootState, string>(
    databaseNameSelector
  );

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {currentRoute!.screen === "clients" ? (
        <Typography
          className={currentRoute!.clientId !== "dashboard" ? classes.link : ""}
          color="textPrimary"
          onClick={onNavigateToClientDashboard}
        >
          {currentRoute!.clientId !== "dashboard"
            ? "Clients"
            : "Client Dashboard"}
        </Typography>
      ) : (
        <Typography
          className={
            currentRoute!.databaseId !== "dashboard" ? classes.link : ""
          }
          color="textPrimary"
          onClick={onNavigateToDatabaseDashboard}
        >
          {currentRoute!.databaseId !== "dashboard"
            ? "Databases"
            : "Database Dashboard"}
        </Typography>
      )}
      {currentRoute?.screen === "clients" &&
        currentRoute!.clientId &&
        currentRoute!.clientId !== "dashboard" && (
          <Typography
            className={currentRoute.documentId ? classes.link : ""}
            color="textPrimary"
            onClick={onNavigateToClient}
          >
            {currentClient.name}
          </Typography>
        )}
      {currentRoute?.screen === "databases" &&
        currentRoute!.databaseId &&
        currentRoute!.databaseId !== "dashboard" && (
          <Typography
            className={currentRoute.documentId ? classes.link : ""}
            color="textPrimary"
            onClick={onNavigateToDatabase}
          >
            {currentDatabaseName}
          </Typography>
        )}
      {currentRoute?.screen === "clients" &&
        currentRoute!.clientId &&
        currentRoute!.documentId && (
          <Typography color="textPrimary">{currentDocument.name}</Typography>
        )}
      {currentRoute?.screen === "databases" &&
        currentRoute!.databaseId &&
        currentRoute!.documentId && (
          <Typography color="textPrimary">{currentDocument.name}</Typography>
        )}
    </Breadcrumbs>
  );
});

export default FoodworksBreadcrumb;

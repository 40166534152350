import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Sex } from "../../../../../../../data/models/nutrientReferenceValues";
import { setClientSex } from "../../../../../../../store/data/current_client/action_creators/clientProfile";
import { clientSexSelector } from "../../../../../../../store/data/current_client/selectors/clientProfile";
import { RootState } from "../../../../../../../store/reducers";

const useClientSex = (): [Sex, (sex: Sex) => void] => {
  const dispatch = useDispatch();

  const sex: Sex = useSelector<RootState, Sex>(clientSexSelector);

  const onSetClientSex = useCallback(
    (sex: Sex) => dispatch(setClientSex(sex)),
    [dispatch]
  );

  return [sex, onSetClientSex];
};

export default useClientSex;

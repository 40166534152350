import { useMemo } from "react";
import { useSelector } from "react-redux";

import { MeasurementEntry } from "../../../../../../../data/models/clientProperties/measurement";
import {
  latestActiveAndInactiveMeasurementEntrySelector,
  latestMeasurementEntrySelector,
} from "../../../../../../../store/data/current_client/selectors/clientMeasurements";
import { RootState } from "../../../../../../../store/reducers";

export interface PreviousMeasurement {
  value: number;
  date: Date;
}

export const usePreviousMeasurement = (
  title: string,
  unit: string
): PreviousMeasurement | undefined => {
  const selectRecentMeasurement = useMemo(latestMeasurementEntrySelector, []);

  const latestMeasurement = useSelector<
    RootState,
    MeasurementEntry | undefined
  >((state: RootState) => selectRecentMeasurement(state, title, unit));

  return latestMeasurement
    ? { value: latestMeasurement.value, date: new Date(latestMeasurement.date) }
    : undefined;
};

export const usePreviousPerpetualMeasurement = (
  title: string,
  unit: string
): PreviousMeasurement | undefined => {
  const selectRecentMeasurement = useMemo(
    latestActiveAndInactiveMeasurementEntrySelector,
    []
  );

  const latestMeasurement = useSelector<
    RootState,
    MeasurementEntry | undefined
  >((state: RootState) => selectRecentMeasurement(state, title, unit));

  return latestMeasurement
    ? { value: latestMeasurement.value, date: new Date(latestMeasurement.date) }
    : undefined;
};

import {
  IActionsSetSelectedClientDocuments,
  IActionsSetUseRMR,
} from "../actions/clientScreen";

export const setUseRMR = (useRMR: boolean): IActionsSetUseRMR => ({
  type: "SET_USE_RMR",
  useRMR,
});

export const setSelectedClientDocuments = (
  documents: string[]
): IActionsSetSelectedClientDocuments => ({
  type: "SET_SELECTED_CLIENT_DOCUMENTS",
  documents,
});

import React, { useState, ReactNode } from "react";
import { makeStyles, AppBar, Toolbar, IconButton } from "@material-ui/core";
import { AccountCircle, HelpOutline } from "@material-ui/icons";
import TelegramIcon from "@material-ui/icons/Telegram";
import { useDispatch } from "react-redux";

import logo from "../../../images/foodworks_horizontal.png";
import { FeedbackDialog } from "../../dialogs/FeedbackDialog";
import { AccountDialog } from "../../dialogs/AccountDialog";
import { appTheme } from "../../../styling/style";
import { AccountMenu } from "../../common/AccountMenu";
import { FoodworksTooltip } from "../../common/InfoTooltip";
import { addAlert } from "../../../store/ui/actionCreators/alert";
import ClientsButton from "./components/ClientsButton";
import DatabasesButton from "./components/DatabasesButton";

const useStyles = makeStyles(() => ({
  root: { minHeight: 50, height: 50 },
  logo: { height: 40, marginBottom: 7, marginRight: 40 },
  successAlert: { marginTop: 40 },
  growDiv: { flex: 1 },
  appBarButton: {
    color: appTheme.colors.xiketic,
    "&:hover": {
      color: appTheme.colors.primary,
    },
  },
}));

const FoodworksAppBar = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showAccountDialog, setShowAccountDialog] = useState(false);

  const [accountAnchor, setAccountAnchor] = useState<null | HTMLElement>(null);

  const onAddSuccessAlert = (message: string) => dispatch(addAlert(message));

  const onAccountClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAccountAnchor(event.currentTarget);
  };

  const onFeedbackSend = () => {
    setShowFeedbackDialog(false);
    onAddSuccessAlert("Thank you for your feedback!");
  };

  const sendFeedbackButton: ReactNode = (
    <>
      <FoodworksTooltip title="Send Feedback">
        <IconButton
          className={classes.appBarButton}
          onClick={() => setShowFeedbackDialog(true)}
        >
          <TelegramIcon />
        </IconButton>
      </FoodworksTooltip>
      {showFeedbackDialog ? (
        <FeedbackDialog
          onClose={() => setShowFeedbackDialog(false)}
          onSend={onFeedbackSend}
        />
      ) : null}
    </>
  );

  const helpButton: ReactNode = (
    <FoodworksTooltip title="Get help">
      <a
        href="https://support.foodworks.online/hc/en-au"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton className={classes.appBarButton}>
          <HelpOutline />
        </IconButton>
      </a>
    </FoodworksTooltip>
  );

  const accountButton = (
    <>
      <FoodworksTooltip title="Account">
        <IconButton className={classes.appBarButton} onClick={onAccountClicked}>
          <AccountCircle />
        </IconButton>
      </FoodworksTooltip>
      <AccountMenu
        setShowAccountDialog={setShowAccountDialog}
        handleClose={() => setAccountAnchor(null)}
        anchorElement={accountAnchor}
      />
    </>
  );

  return (
    <AppBar
      data-cy="appBar"
      position="sticky"
      className={classes.root}
      elevation={1}
    >
      <Toolbar className={classes.root}>
        <img src={logo} alt="Logo" className={classes.logo} />
        <DatabasesButton />
        <ClientsButton />
        <div className={classes.growDiv} />
        {sendFeedbackButton}
        {helpButton}
        {accountButton}
        {showAccountDialog ? (
          <AccountDialog onClose={() => setShowAccountDialog(false)} />
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default FoodworksAppBar;

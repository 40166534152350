import React from "react";
import { Button, makeStyles } from "@material-ui/core";

import { NutritionRadioOption } from "../../../../../store/ui/reducers/nutritionPaneReducers";
import { appTheme } from "../../../../../styling/style";
import { FoodWorksNumberInput } from "../../../../common/FoodWorksTextInput";

const useStyles = makeStyles(() => ({
  input: {
    margin: 0,
    maxLength: 10,
    "&:focus": {
      border: `1px solid ${appTheme.colors.xiketic}`,
      margin: 0,
      background: appTheme.colors.white[0],
    },
  },
  customInputDiv: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  radioButton: {
    width: 60,
    padding: 3,
    margin: 2,
  },
}));

interface CompositionOptionButtonProps {
  option: NutritionRadioOption;
  selectedOption: NutritionRadioOption;
  customAmount: number;
  onSelectOption: (option: NutritionRadioOption) => void;
  onUpdateCustomAmount: (amount: string) => void;
}

const CompositionOptionButton = React.memo<CompositionOptionButtonProps>(
  (props) => {
    const classes = useStyles();

    const {
      option,
      selectedOption,
      customAmount,
      onSelectOption,
      onUpdateCustomAmount,
    } = { ...props };

    const buttonVariant =
      selectedOption === option.toString() ? "contained" : "outlined";

    return (
      <Button
        key={option}
        className={classes.radioButton}
        variant={buttonVariant}
        color="secondary"
        onClick={() => onSelectOption(option)}
        size="small"
      >
        {option === NutritionRadioOption.CUSTOM ? (
          <div className={classes.customInputDiv}>
            <FoodWorksNumberInput
              key={"customInput"}
              data-cy="customInput"
              className={classes.input}
              setValue={onUpdateCustomAmount}
              inputProps={{
                value: customAmount,
              }}
            />
            g
          </div>
        ) : (
          option.toString()
        )}
      </Button>
    );
  }
);

export default CompositionOptionButton;

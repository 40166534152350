import {
  IActionsUpdateVolumeConversion,
  IActionsUpdateDocumentName,
  IActionsUpdateDocumentIdentifier,
  IActionsUpdateDocumentAltIdentifier,
  IActionsUpdateDocumentDescription,
  IActionsUpdateDocumentMethod,
  IActionsUpdateDocumentYield,
  IActionsUpdateDocumentServes,
  IActionsToggleIsLiquid,
  IActionsUpdateDocumentNote,
  IActionsUpdateDocumentStatus,
  IActionsUpdateDocumentModifiedDate,
  IActionsUpdateCalculationMethod,
  IActionsSetDocumentMappingId,
  IActionsSetDocumentTagIds,
  IActionsDeleteMethodSection,
  IActionsCreateMethodSection,
  IActionsUpdateMethodSectionIsCollapsed,
  IActionsUpdateMethodSectionTitle,
} from "../actions/document";
import { Document, MethodContents } from "../../../../data/models/document";
import { IActionsSetDocumentData } from "../actions/currentDocument";
import { YieldType } from "../../../../data/models/documentProperties/yield";
import { ServeType } from "../../../../data/models/documentProperties/serve";
import { VolumeConversionObject } from "../../../../data/models/documentProperties/volumeConversionFactor";
import { DocumentStatus } from "../../../../data/models/userDatabase";

export const setDocumentData = (
  document: Document
): IActionsSetDocumentData => {
  return {
    type: "SET_DOCUMENT_DATA",
    document: document,
  };
};

export const updateVolumeConversion = (
  volumeConversion: VolumeConversionObject
): IActionsUpdateVolumeConversion => {
  return {
    type: "UPDATE_VOLUME_CONVERSION",
    volumeConversion: volumeConversion,
  };
};

export const updateDocumentName = (
  name: string
): IActionsUpdateDocumentName => {
  return {
    type: "UPDATE_DOCUMENT_NAME",
    name: name,
  };
};

export const updateDocumentIdentifier = (
  identifier: string
): IActionsUpdateDocumentIdentifier => {
  return {
    type: "UPDATE_DOCUMENT_IDENTIFIER",
    identifier: identifier,
  };
};

export const updateDocumentAltIdentifier = (
  altIdentifier: string
): IActionsUpdateDocumentAltIdentifier => {
  return {
    type: "UPDATE_DOCUMENT_ALT_IDENTIFIER",
    altIdentifier: altIdentifier,
  };
};

export const updateDocumentDescription = (
  description: string
): IActionsUpdateDocumentDescription => {
  return {
    type: "UPDATE_DOCUMENT_DESCRIPTION",
    description: description,
  };
};

export const updateDocumentMethod = (
  section: MethodContents
): IActionsUpdateDocumentMethod => {
  return {
    type: "UPDATE_DOCUMENT_METHOD",
    section: section,
    index: section.index,
  };
};

export const deleteMethodSection = (
  index: number
): IActionsDeleteMethodSection => {
  return {
    type: "DELETE_METHOD_SECTION",
    index: index,
  };
};

export const createMethodSection = (
  newSection: MethodContents
): IActionsCreateMethodSection => {
  return {
    type: "CREATE_METHOD_SECTION",
    newSection: newSection,
  };
};

export const updateMethodSectionIsCollapsed = (
  sectionIndex: number,
  isCollapsed: boolean
): IActionsUpdateMethodSectionIsCollapsed => {
  return {
    type: "UPDATE_METHOD_SECTION_IS_COLLAPSED",
    index: sectionIndex,
    isCollapsed: isCollapsed,
  };
};

export const updateMethodSectionTitle = (
  index: number,
  newTitle: string
): IActionsUpdateMethodSectionTitle => {
  return {
    type: "UPDATE_METHOD_SECTION_TITLE",
    index: index,
    newTitle: newTitle,
  };
};

export const updateDocumentYield = (
  yieldType: YieldType,
  amount: number | undefined
): IActionsUpdateDocumentYield => {
  return {
    type: "UPDATE_DOCUMENT_YIELD",
    yieldType: yieldType,
    amount: amount,
  };
};

export const updateDocumentServes = (
  serveType: ServeType,
  amount: number | undefined
): IActionsUpdateDocumentServes => {
  return {
    type: "UPDATE_DOCUMENT_SERVES",
    serveType: serveType,
    amount: amount,
  };
};

export const updateDocumentLastModified = (
  lastModified: string
): IActionsUpdateDocumentModifiedDate => {
  return {
    type: "UPDATE_DOCUMENT_MODIFIED_DATE",
    date: lastModified,
  };
};

export const toggleIsLiquid = (): IActionsToggleIsLiquid => {
  return {
    type: "TOGGLE_PROPERTY_IS_LIQUID",
  };
};

export const updateDocumentStatus = (status : DocumentStatus): IActionsUpdateDocumentStatus => {
  return {
    type: "UPDATE_DOCUMENT_STATUS",
    status 
  };
};

export const updateDocumentNote = (
  note: string
): IActionsUpdateDocumentNote => {
  return {
    type: "UPDATE_DOCUMENT_NOTE",
    note: note,
  };
};

export const updateCalculationMethod = (
  calculationMethod: number,
  documentId: string
): IActionsUpdateCalculationMethod => {
  return {
    type: "UPDATE_CALCULATION_METHOD",
    calculationMethod: calculationMethod,
    mappedDocumentId: documentId,
  };
};

export const setDocumentMappingId = (
  documentId: string
): IActionsSetDocumentMappingId => {
  return {
    type: "SET_DOCUMENT_MAPPING_ID",
    documentId: documentId,
  };
};

export const setDocumentTagIds = (
  tagIds: string[]
): IActionsSetDocumentTagIds => {
  return {
    type: "SET_DOCUMENT_TAG_IDS",
    tagIds: tagIds,
  };
};

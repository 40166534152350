import React, { useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { makeStyles } from "@material-ui/core";

import usePFCASources, { quantitiesData } from "./hooks/usePFCASources";
import PFCATable, { TableRows } from "./PFCATable";
import GraphSelectorDialog from "../../../../../common/graphs/GraphTableDialog";
import DocumentSelectorDropdown from "../../../../../common/DocumentSelectorDropdown";

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  graphContainer: {
    display: "flex",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 30,
    width: "50%",
    cursor: "pointer",
  },
}));

export interface PFCAVisData {
  angle: number;
  label: string;
  color: string;
}

export const getTotal = (nutrientQuantities: number[]): number =>
  nutrientQuantities.reduce((acc, curr) => acc + curr, 0);
export const getColors = (nutrientQuantities: quantitiesData): string[] =>
  nutrientQuantities["colors"];
export const getLabels = (nutrientQuantities: quantitiesData): string[] =>
  nutrientQuantities["labels"];
export const getSeries = (nutrientQuantities: quantitiesData): number[] =>
  nutrientQuantities["angles"];
export const getTableRows = (nutrientQuantities: quantitiesData): TableRows[] =>
  nutrientQuantities["colors"].map((color, index) => {
    return {
      label: nutrientQuantities["labels"][index],
      color: color,
      angle: nutrientQuantities["angles"][index],
    };
  });

interface CardProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  nutrientQuantities: quantitiesData;
  chartOptions: ApexCharts.ApexOptions;
  title: string;
}

export const GraphCard = (props: CardProps) => {
  const getTotalMemo = useMemo(
    () => getTotal(props.nutrientQuantities.angles),
    [props.nutrientQuantities.angles]
  );
  const getTableRowsMemo = useMemo(
    () => getTableRows(props.nutrientQuantities),
    [props.nutrientQuantities]
  );
  const getSeriesMemo = useMemo(() => getSeries(props.nutrientQuantities), [
    props.nutrientQuantities,
  ]);

  return (
    <React.Fragment>
      {getSeriesMemo.length > 0 && (
        <GraphSelectorDialog
          title={props.title}
          graph={
            <Chart
              options={props.chartOptions}
              series={getSeriesMemo}
              type="pie"
              width={400}
            />
          }
          table={
            <PFCATable
              total={getTotalMemo}
              rows={getTableRowsMemo}
              headings={["Name", "Amount (g)", "Percentage"]}
            />
          }
        />
      )}
    </React.Fragment>
  );
};

const PFCAGraph = (): JSX.Element => {
  const classes = useStyles();

  const [
    setCurrentDocument,
    nutrientQuantitiesPFCA,
    nutrientQuantitiesPMS,
  ] = usePFCASources();

  const [PFCADiag, setPFCADiag] = useState(false);
  const [PMSDiag, setPMSDiag] = useState(false);

  const chartOptionsPFCA: ApexCharts.ApexOptions = {
    chart: {
      width: 500,
      type: "pie",
    },
    labels: getLabels(nutrientQuantitiesPFCA),
    colors: getColors(nutrientQuantitiesPFCA),
  };
  const chartOptionsPMS: ApexCharts.ApexOptions = {
    chart: {
      width: 500,
      type: "pie",
    },
    labels: getLabels(nutrientQuantitiesPMS),
    colors: getColors(nutrientQuantitiesPMS),
  };

  return (
    <div className={classes.root}>
      <DocumentSelectorDropdown onChange={setCurrentDocument}/>
      <div className={classes.graphContainer}>
        <GraphCard
          title="Percent energy derived from Protein, Fat, Carbs, Alcohol & Fibre"
          open={PFCADiag}
          onOpen={() => setPFCADiag(true)}
          onClose={() => setPFCADiag(false)}
          nutrientQuantities={nutrientQuantitiesPFCA}
          chartOptions={chartOptionsPFCA}
        />
        <GraphCard
          title="Poly, Mono, Sat - Fats"
          open={PMSDiag}
          onOpen={() => setPMSDiag(true)}
          onClose={() => setPMSDiag(false)}
          nutrientQuantities={nutrientQuantitiesPMS}
          chartOptions={chartOptionsPMS}
        />
      </div>
    </div>
  );
};

export default PFCAGraph;

import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  isMealPlan,
  isFoodRecord,
  is24HourRecall,
  isFood,
} from "../../../../../constants/FoodTemplate";
import { RouteData } from "../../../../../data/routing/types";
import {
  templateIdSelector,
  isLiquidSelector,
} from "../../../../../store/data/current-document/selectors/document";
import { RootState } from "../../../../../store/reducers";
import { NutritionRadioOption } from "../../../../../store/ui/reducers/nutritionPaneReducers";
import { currentRouteSelector } from "../../../../../store/ui/selectors/routing";

const generateRadioOptions = (
  templateId: string,
  isLiquid: boolean,
  currentRoute: RouteData | undefined
): NutritionRadioOption[] => {
  const options: NutritionRadioOption[] = [];

  if (currentRoute && !currentRoute.documentId) {
    options.push(NutritionRadioOption.DAY);
    options.push(NutritionRadioOption.MEGAJOULE);
    options.push(NutritionRadioOption.TOTAL);
  } else {
    if (
      isMealPlan(templateId) ||
      isFoodRecord(templateId) ||
      is24HourRecall(templateId)
    ) {
      if (!is24HourRecall(templateId)) {
        options.push(NutritionRadioOption.DAY);
      }
      options.push(NutritionRadioOption.MEGAJOULE);
    } else {
      options.push(NutritionRadioOption.CUSTOM);
      isLiquid
        ? options.push(NutritionRadioOption.ONE_HUNDRED_ML)
        : options.push(NutritionRadioOption.ONE_HUNDRED_G);

      if (!isFood(templateId)) {
        options.push(NutritionRadioOption.PER_SERVE);
      }
    }

    if (!isFood(templateId)) {
      options.push(NutritionRadioOption.TOTAL);
    }
  }
  return options;
};

const useCompositionOptions = (): NutritionRadioOption[] => {
  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const isLiquid: boolean = useSelector<RootState, boolean>(isLiquidSelector);

  const currentRoute: RouteData | undefined = useSelector<
    RootState,
    RouteData | undefined
  >(currentRouteSelector);

  const compositionOptions: NutritionRadioOption[] = useMemo(
    () => generateRadioOptions(templateId, isLiquid, currentRoute),
    [templateId, isLiquid, currentRoute]
  );

  return compositionOptions;
};

export default useCompositionOptions;

export interface defaultMeasurement {
  label: string;
  unit: string;
  subCategory: string;
}

export const anthroMeasurements: defaultMeasurement[] = [
  {
    label: "Weight",
    unit: "kg",
    subCategory: "Body composition",
  },
  {
    label: "Height",
    unit: "cm",
    subCategory: "Body composition",
  },
  {
    label: "Body Mass Index (BMI)",
    unit: "kg/m2",
    subCategory: "Body composition",
  },
  {
    label: "Waist circumference",
    unit: "cm",
    subCategory: "Body composition",
  },
  {
    label: "Hip circumference",
    unit: "cm",
    subCategory: "Body composition",
  },
  {
    label: "Waist:hip ratio",
    unit: "ratio",
    subCategory: "Body composition",
  },
  {
    label: "Ankle girth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Forearm girth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Elbow breadth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Arm flexed girth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Arm relaxed girth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Arm perimiter",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Calf girth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Chest girth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Head circumference",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Mid thigh girth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Neck girth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Shoulder girth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Thigh girth",
    unit: "cm",
    subCategory: "Body measurements",
  },
  {
    label: "Chest skinfold",
    unit: "mm",
    subCategory: "Skin folds",
  },
  {
    label: "Bicep skinfold",
    unit: "mm",
    subCategory: "Skin folds",
  },
  {
    label: "Front thigh skinfold",
    unit: "mm",
    subCategory: "Skin folds",
  },
  {
    label: "Iliac crest skinfold",
    unit: "mm",
    subCategory: "Skin folds",
  },
  {
    label: "Medial calf skinfold",
    unit: "mm",
    subCategory: "Skin folds",
  },
  {
    label: "Midaxillary skinfold",
    unit: "mm",
    subCategory: "Skin folds",
  },
  {
    label: "Subscapular skinfold",
    unit: "mm",
    subCategory: "Skin folds",
  },
  {
    label: "Suprailiac skinfold",
    unit: "mm",
    subCategory: "Skin folds",
  },
  {
    label: "Supraspinale skinfold",
    unit: "mm",
    subCategory: "Skin folds",
  },
  {
    label: "Triceps skinfold",
    unit: "mm",
    subCategory: "Skin folds",
  },
  {
    label: "Lean body mass",
    unit: "kg",
    subCategory: "Body composition",
  },
  {
    label: "Lean body mass",
    unit: " % ",
    subCategory: "Body composition",
  },
];

export const biochemMeasurements: defaultMeasurement[] = [
  {
    label: "Blood Glucose Levels (BGL)",
    unit: "mmol/L",
    subCategory: "Blood sugars",
  },
  {
    label: "HBA1c",
    unit: " % ",
    subCategory: "Blood sugars",
  },
  {
    label: "Total cholesterol",
    unit: "mmol/L",
    subCategory: "Blood lipid profile",
  },
  {
    label: "HDL cholesterol",
    unit: "mmol/L",
    subCategory: "Blood lipid profile",
  },
  {
    label: "LDL cholesterol",
    unit: "mmol/L",
    subCategory: "Blood lipid profile",
  },
  {
    label: "Triglycerides",
    unit: "mmol/L",
    subCategory: "Blood lipid profile",
  },
  {
    label: "Random BGL",
    unit: "mmol/L",
    subCategory: "Blood sugars",
  },
  {
    label: "Fasting BGL",
    unit: "mmol/L",
    subCategory: "Blood sugars",
  },
  {
    label: "Postprandial BGL",
    unit: "mmol/L",
    subCategory: "Blood sugars",
  },
  {
    label: "Preprandial BGL",
    unit: "mmol/L",
    subCategory: "Blood sugars",
  },
  {
    label: "HDL:LDL ratio",
    unit: "ratio",
    subCategory: "Blood lipid profile",
  },
  {
    label: "Sodium",
    unit: "mmol/L",
    subCategory: "Electrolytes",
  },
  {
    label: "Potassium",
    unit: "mmol/L",
    subCategory: "Electrolytes",
  },
  {
    label: "Chloride",
    unit: "mmol/L",
    subCategory: "Electrolytes",
  },
  {
    label: "Phosphate",
    unit: "mmol/L",
    subCategory: "Electrolytes",
  },
  {
    label: "Bicarbonate (total CO2)",
    unit: "mmol/L",
    subCategory: "Electrolytes",
  },
  {
    label: "Anion gap",
    unit: "mmol/L",
    subCategory: "Electrolytes",
  },
  {
    label: "Osmolality",
    unit: "mmol/L",
    subCategory: "Electrolytes",
  },
  {
    label: "Osmolarity",
    unit: "mmol/L",
    subCategory: "Electrolytes",
  },
  {
    label: "Albumin",
    unit: "g/L",
    subCategory: "Proteins",
  },
  {
    label: "Prealbumin",
    unit: "mg/dL",
    subCategory: "Proteins",
  },
  {
    label: "Total protein",
    unit: "g/L",
    subCategory: "Proteins",
  },
  {
    label: "Globulin",
    unit: "g/L",
    subCategory: "Proteins",
  },
  {
    label: "C reactive protien",
    unit: "g/L",
    subCategory: "Proteins",
  },
  {
    label: "Mean corpuscular volume",
    unit: "g/L",
    subCategory: "Haematology",
  },
  {
    label: "Haemoglobin",
    unit: "g/L",
    subCategory: "Haematology",
  },
  {
    label: "Haematocrit",
    unit: " % ",
    subCategory: "Haematology",
  },
  {
    label: "Erythrocyte sedimentation rate",
    unit: "mm/hr",
    subCategory: "Haematology",
  },
  {
    label: "BUN",
    unit: "mmol/L",
    subCategory: "Renal",
  },
  {
    label: "eGFR",
    unit: "mL/min",
    subCategory: "Renal",
  },
  {
    label: "Creatinine",
    unit: "mmol/L",
    subCategory: "Renal",
  },
  {
    label: "Urea",
    unit: "mmol/L",
    subCategory: "Renal",
  },
  {
    label: "Cholecalciferol",
    unit: "mmol/L",
    subCategory: "Vitamins",
  },
  {
    label: "Cobalamin",
    unit: "ng/L",
    subCategory: "Vitamins",
  },
  {
    label: "Tocopherol",
    unit: "μmol/L",
    subCategory: "Vitamins",
  },
  {
    label: "Vitamin A",
    unit: "μmol/L",
    subCategory: "Vitamins",
  },
  {
    label: "Vitamin B12",
    unit: "μmol/L",
    subCategory: "Vitamins",
  },
  {
    label: "Vitamin D",
    unit: "nmol/L",
    subCategory: "Vitamins",
  },
  {
    label: "Ascorbic acid",
    unit: "μmol/L",
    subCategory: "Vitamins",
  },
  {
    label: "Carotene",
    unit: "μmol/L",
    subCategory: "Vitamins",
  },
  {
    label: "Folate",
    unit: "nmol/L",
    subCategory: "Vitamins",
  },
  // *** Include the *descriptions???
  {
    label: "AST",
    unit: "U/L",
    subCategory: "Liver function tests",
  },
  {
    label: "GGT",
    unit: "U/L",
    subCategory: "Liver function tests",
  },
  {
    label: "ALT",
    unit: "U/L",
    subCategory: "Liver function tests",
  },
  {
    label: "ALP",
    unit: "U/L",
    subCategory: "Liver function tests",
  },
  {
    label: "CK",
    unit: "U/L",
    subCategory: "Liver function tests",
  },
  {
    label: "LDH",
    unit: "U/L",
    subCategory: "Liver function tests",
  },
  {
    label: "Bilirubin",
    unit: "μMOL/L",
    subCategory: "Liver function tests",
  },
  {
    label: "Total serum iron",
    unit: "μmol/L",
    subCategory: "Iron studies",
  },
  {
    label: "Transferrin",
    unit: "μmol/L",
    subCategory: "Iron studies",
  },
  {
    label: "Transferrin saturaion",
    unit: " % ",
    subCategory: "Iron studies",
  },
  {
    label: "Serum ferritin",
    unit: "μg/L",
    subCategory: "Iron studies",
  },
];

export const clinicalMeasurements: defaultMeasurement[] = [
  {
    label: "Blood pressure",
    unit: "mmHg",
    subCategory: "No category",
  },
  {
    label: "Appetite rating",
    unit: "1-5",
    subCategory: "No category",
  },
  {
    label: "Body temperature",
    unit: "febrile/afebrile or °C",
    subCategory: "No category",
  },
  {
    label: "Heart rate",
    unit: "bpm",
    subCategory: "No category",
  },
  {
    label: "Resipratory rate",
    unit: "breaths/min",
    subCategory: "No category",
  },
  {
    label: "Pulse rate",
    unit: "bpm",
    subCategory: "No category",
  },
];

export const initialAnthroLabels = [
  "Weight",
  "Height",
  "Body Mass Index (BMI)",
  "Waist circumference",
  "Hip circumference",
  "Waist:hip ratio",
];

export const initialBiochemLabels = [
  "Blood Glucose Levels (BGL)",
  "HBA1c",
  "Total cholesterol",
  "HDL cholesterol",
  "LDL cholesterol",
  "Triglycerides",
];

export const initialClinicalLabels = ["Blood pressure", "Appetite rating"];

export const initialAnthroMeasurements = anthroMeasurements.filter(
  (measurement) => initialAnthroLabels.includes(measurement.label)
);
export const initialBiochemMeasurements = biochemMeasurements.filter(
  (measurement) => initialBiochemLabels.includes(measurement.label)
);
export const initialClinicalMeasurements = clinicalMeasurements.filter(
  (measurement) => initialClinicalLabels.includes(measurement.label)
);

export const measurementSortOptions = ["A-Z", "Active", "Category", "Inactive"];

import React, { FunctionComponent } from "react";

import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { RetentionFactorProfilesSelector } from "../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../store/reducers";
import { RetentionFactorProfile } from "../../../../data/models/documentProperties/retentionFactor";
import {
  RetentionFactorListBox,
  RetentionFactorListItem,
} from "./RetentionFactorListBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: "column",
      flex: 1,
      display: "flex",
      marginLeft: 25,
    },
  })
);

const PROCESS_HEADER = "Select Cooking Process";

export interface ProcessListBoxProps {
  selectedGroupId: string | null;
  currentSelectedProfileId: string | null;
  onSelectProcess: (selectedProcess: string) => void;
}

export const ProcessListBox: FunctionComponent<ProcessListBoxProps> = ({
  selectedGroupId,
  currentSelectedProfileId,
  onSelectProcess,
}) => {
  const classes = useStyles();

  /* *** State *** */

  const retentionFactorProfiles: RetentionFactorProfile[] = useSelector<
    RootState,
    RetentionFactorProfile[]
  >((state: RootState) =>
    RetentionFactorProfilesSelector(state, selectedGroupId)
  );

  /* ----- */

  const handleSelectProcess = (processId: string) => {
    onSelectProcess(processId);
  };

  const header = (
    <Typography color="textPrimary" variant="subtitle1">
      {PROCESS_HEADER}
    </Typography>
  );

  const listItems: RetentionFactorListItem[] = retentionFactorProfiles.map(
    (profile: RetentionFactorProfile): RetentionFactorListItem => {
      return {
        id: profile.id,
        name: profile.process,
      };
    }
  );

  return (
    <div className={classes.root}>
      {header}
      <RetentionFactorListBox
        selectedOption={currentSelectedProfileId}
        selectOption={handleSelectProcess}
        listItems={listItems}
      />
    </div>
  );
};

import React, { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  makeStyles,
  Button,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";

import { BaseDialog } from "../BaseDialog";
import { appTheme } from "../../../styling/style";
import { deleteDatabase } from "../../../store/data/thunks/database";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "center",
  },
  titleBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  flex: {
    display: "flex",
  },
  deleteButton: {
    color: appTheme.colors.error,
  },
  databaseName: {
    marginLeft: 10,
    fontWeight: "bold",
  },
  confirmationInput: {
    marginTop: 10,
    width: 300,
    fontSize: appTheme.fontSize.textInput,
  },
  none: {},
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: appTheme.colors.success,
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: appTheme.colors.error,
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderColor: appTheme.colors.error,
      borderWidth: 2,
      borderLeftWidth: 6,
      padding: "4px !important",
    },
  },
})(TextField);

interface DeleteDatabaseDialogProps {
  open: boolean;
  onClose: () => void;
  databaseName: string;
  databaseId: string;
}
export const DeleteDatabaseDialog: FunctionComponent<DeleteDatabaseDialogProps> =
  ({ open, onClose, databaseName, databaseId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onDeleteDatabase = () => dispatch(deleteDatabase(databaseId));

    const [confirmText, setConfirmText] = useState("");

    const incorrectDatabaseName: boolean = useMemo(
      () => confirmText.trim() !== databaseName.trim(),
      [confirmText, databaseName]
    );

    const dialogBody: ReactNode = (
      <div>
        <Typography>
          Are you sure you want to permanently delete this database?
          <br />
          <br />
          Doing so will permanently delete all data in this database including
          all its documents. You will not be able to recover the database.
        </Typography>
        <div className={classes.flex}>
          <Typography>
            Confirm you want to delete this database by typing the database
            name:
          </Typography>
          <Typography
            data-cy="deleteDatabaseName"
            className={classes.databaseName}
          >
            {databaseName}
          </Typography>
        </div>
        <ValidationTextField
          data-cy="deleteDatabaseNameInput"
          className={classes.confirmationInput}
          id="databaseToDelete"
          size="small"
          variant="outlined"
          placeholder={databaseName}
          required
          error={incorrectDatabaseName}
          helperText={incorrectDatabaseName ? `Enter the database name` : ""}
          value={confirmText}
          onChange={(event) => setConfirmText(event.currentTarget.value)}
        />
      </div>
    );

    const onClickDelete = () => {
      onDeleteDatabase();
      onClose();
    };

    const dialogActions: ReactNode = (
      <div>
        <Button color="default" onClick={onClose}>
          Cancel
        </Button>
        <Button
          data-cy="confirmDeleteDatabaseButton"
          className={
            incorrectDatabaseName ? classes.none : classes.deleteButton
          }
          disabled={incorrectDatabaseName}
          onClick={onClickDelete}
        >
          Delete
        </Button>
      </div>
    );
    return (
      <BaseDialog
        onClose={onClose}
        dataCy="deleteDatabaseDialog"
        open={open}
        title="Delete database"
        body={dialogBody}
        action={dialogActions}
        maxWidth="md"
      ></BaseDialog>
    );
  };

import React, { FunctionComponent, ReactNode } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { Day } from "../../../../data/models/documentProperties/day";
import { FoodWorksTextInput } from "../../../common/FoodWorksTextInput";
import { FoodworksTooltip } from "../../../common/InfoTooltip";
import FoodworksDatePicker from "../../../common/date/FoodworksDatePicker";
import { DAY_IN_MILLISECONDS } from "../../../../data/models/documentProperties/date";
import { MEDIUM_FIELD } from "../../../../constants/textInputs";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  titleInput: {
    flex: 1,
  },
  icon: {
    width: 40,
  },
  dateContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
}));

interface DaySummaryListItemProps {
  day: Day;
  onUpdateTitle: (title: string) => void;
  onUpdateDate: (date: string) => void;
  onDelete: () => void;
  disabled?: boolean;
  previousDate: Date | null;
  nextDate: Date | null;
  clearable: boolean;
}

export const DaySummaryListItem: FunctionComponent<DaySummaryListItemProps> = ({
  day,
  onUpdateTitle,
  onUpdateDate,
  onDelete,
  disabled = false,
  previousDate,
  nextDate,
  clearable,
}) => {
  const classes = useStyles();

  const titleInput: ReactNode = (
    <FoodWorksTextInput
      maxLength={MEDIUM_FIELD}
      key={`${day.index}-title`}
      value={day.title}
      onChange={(event) => onUpdateTitle(event.currentTarget.value)}
      className={classes.titleInput}
    />
  );

  const deleteDayButton: ReactNode = (
    <FoodworksTooltip title="Delete day">
      <span>
        <IconButton
          disabled={disabled}
          key={`${day.index}-delete`}
          onClick={onDelete}
          className={classes.icon}
          size="small"
        >
          <Close />
        </IconButton>
      </span>
    </FoodworksTooltip>
  );

  return (
    <div className={classes.root}>
      {titleInput}
      <div className={classes.dateContainer}>
        <FoodworksDatePicker
          date={day.date ? new Date(day.date) : null}
          handleChange={(date) => onUpdateDate(date?.toISOString() || "")}
          minDate={
            previousDate
              ? new Date().setTime(
                  previousDate.getTime() + 1 * DAY_IN_MILLISECONDS
                )
              : null
          }
          maxDate={
            nextDate
              ? new Date().setTime(nextDate.getTime() - 1 * DAY_IN_MILLISECONDS)
              : null
          }
          clearable={clearable}
        />
        {deleteDayButton}
      </div>
    </div>
  );
};

import _ from "lodash";
import { createSelector } from "reselect";

import {
  Measurement,
  MeasurementCategory,
  MeasurementEntry,
} from "../../../../data/models/clientProperties/measurement";
import { RootState } from "../../../reducers";

export const clientMeasurementsSelector = (state: RootState): Measurement[] =>
  state.clientDatabase.currentClient.measurements;

export const activeClientMeasurementsSelector = createSelector(
  clientMeasurementsSelector,
  (measurements): Measurement[] =>
    measurements.filter((measurement) => measurement.active)
);

export const inactiveClientMeasurementsSelector = createSelector(
  clientMeasurementsSelector,
  (measurements): Measurement[] =>
    measurements.filter((measurements) => !measurements.active)
);

export const activeClientMeasurementsByCategorySelector = createSelector(
  activeClientMeasurementsSelector,
  (measurements: Measurement[]) =>
    new Map(
      _.chain(measurements)
        .groupBy("category")
        .map((value, key): [MeasurementCategory, Measurement[]] => [
          key as MeasurementCategory,
          value,
        ])
        .value()
    )
);

export const measurementEntriesSelector = (
  state: RootState,
  label: string,
  units: string
): Measurement | undefined =>
  state.clientDatabase.currentClient.measurements.find(
    (measurement) => measurement.label === label && measurement.unit === units
  );

const measurementTitleIdentitySelector = (
  _state: RootState,
  title: string
): string => title;

const measurementUnitsIdentitySelector = (
  _state: RootState,
  _title: string,
  units: string
) => units;

export const measurementSelector = createSelector(
  activeClientMeasurementsSelector,
  measurementTitleIdentitySelector,
  measurementUnitsIdentitySelector,
  (activeMeasurements, title, unit): Measurement | undefined =>
    activeMeasurements.find(
      (measurement: Measurement) =>
        measurement.label === title && measurement.unit === unit
    )
);

const activeAndInactiveMeasurementSelecor = createSelector(
  clientMeasurementsSelector,
  measurementTitleIdentitySelector,
  measurementUnitsIdentitySelector,
  (
    allMeasurements: Measurement[],
    title: string,
    unit: string
  ): Measurement | undefined =>
    allMeasurements.find(
      (measurement: Measurement) =>
        measurement.label === title && measurement.unit === unit
    )
);

export const latestMeasurementEntrySelector = () =>
  createSelector(measurementSelector, (measurement):
    | MeasurementEntry
    | undefined => {
    if (!measurement || !measurement.entries.length) return;

    return measurement.entries[measurement.entries.length - 1];
  });

export const latestActiveAndInactiveMeasurementEntrySelector = () =>
  createSelector(activeAndInactiveMeasurementSelecor, (measurement):
    | MeasurementEntry
    | undefined => {
    if (!measurement || !measurement.entries.length) return;

    return measurement.entries[measurement.entries.length - 1];
  });

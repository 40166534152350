import firebase from "firebase";

const USER_PERMISSIONS_DATABASE = "user-permissions";

export class FirebaseUserPermissions {
  collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(firestore: firebase.firestore.Firestore) {
    this.collection = firestore.collection(USER_PERMISSIONS_DATABASE);
  }

  doGetUserPermissions(
    uid: string
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    return this.collection.doc(uid);
  }

  async doGetUserPermissableDatabases(uid: string): Promise<string[]> {
    const userPermissions = await this.collection.doc(uid).get();

    return userPermissions.data()!.databases;
  }

  async doGetUserClientDatabase(uid: string): Promise<string> {
    return this.collection
      .doc(uid)
      .get()
      .then((userPermissions) => userPermissions.data()!.clientDatabase);
  }

  doCreateUserPermissions(uid: string): Promise<void> {
    return this.collection.doc(uid).set({
      databases: [],
      clientDatabase: "",
    });
  }

  doSetClientDatabasePermission(
    uid: string,
    clientDatabaseId: string
  ): Promise<void> {
    return this.collection
      .doc(uid)
      .update({ clientDatabase: clientDatabaseId });
  }

  doAddDatabasePermission(uid: string, databaseId: string): Promise<void> {
    return this.collection.doc(uid).update({
      databases: firebase.firestore.FieldValue.arrayUnion(databaseId),
    });
  }

  doRemoveDatabasePermission(uid: string, databaseId: string): Promise<void> {
    return this.collection.doc(uid).update({
      databases: firebase.firestore.FieldValue.arrayRemove(databaseId),
    });
  }
}

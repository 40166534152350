import React, { FunctionComponent } from "react";

import { Divider, makeStyles, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { FoodworksMenu } from "./FoodworksMenu";
import { handleRouteChange } from "../../store/ui/thunks/routing";
import { getSignInRouteData } from "../../data/routing/routing";

export interface CreateMenuProps {
  handleClose: () => void;
  anchorElement: HTMLElement | null;
  setShowAccountDialog: (showDialog: boolean) => void;
}

const useStyles = makeStyles(() => ({
  divider: { margin: "5px 0px" },
}));

export const AccountMenu: FunctionComponent<CreateMenuProps> = ({
  handleClose,
  anchorElement,
  setShowAccountDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserLogout = () => dispatch(handleRouteChange(getSignInRouteData()));

  return (
    <FoodworksMenu
      id="simple-menu"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() => {
          setShowAccountDialog(true);
        }}
      >
        Profile
      </MenuItem>

      <Divider className={classes.divider} />
      <MenuItem onClick={onUserLogout}>Log Out</MenuItem>
    </FoodworksMenu>
  );
};

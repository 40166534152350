import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { isReferenceDataLoadedSelector } from "../../../store/data/selectors/referenceData";
import { fetchReferenceData } from "../../../store/data/thunks/referenceData";
import initialiseUser from "../../../store/data/thunks/user/initialiseUser";
import { RootState } from "../../../store/reducers";
import { useReduxDispatch } from "../../../store/store";

const useInitialiseUser = (user: firebase.User | undefined) => {
  const dispatch = useReduxDispatch();

  const isReferenceDataLoaded: boolean = useSelector<RootState, boolean>(
    isReferenceDataLoadedSelector
  );

  const onSetReferenceData = useCallback(
    async () => await dispatch(fetchReferenceData()),
    [dispatch]
  );

  const onInitialiseUser = useCallback(
    () => dispatch(initialiseUser()),
    [dispatch]
  );

  const loadUser = useCallback(() => {
    if (user && !isReferenceDataLoaded) {
      onSetReferenceData();
    }

    if (user) {
      onInitialiseUser();
    }
  }, [user, isReferenceDataLoaded, onSetReferenceData, onInitialiseUser]);

  useEffect(loadUser, [user, loadUser]);
};

export default useInitialiseUser;

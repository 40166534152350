import React, { ReactNode } from "react";
import { batch, useDispatch, useSelector } from "react-redux";

import { DialogOption } from "../common/FoodWorksDialog";
import { Day } from "../../data/models/documentProperties/day";
import { FoodItemPosition } from "../../data/models/foodItemPosition";
import { deleteSections } from "../../store/data/current-document/action-creators/sections";
import { daySelector } from "../../store/data/current-document/selectors/days";
import { templateIdSelector } from "../../store/data/current-document/selectors/document";
import { deleteSelectedFoodItems } from "../../store/data/current-document/thunks/foodItems";
import { RootState } from "../../store/reducers";
import { appTheme } from "../../styling/style";
import { checkForOnlySectionsSelected } from "../screens/databases/documents/tabs/ingredients/editing_grid/toolbar/IngredientRowCopy";
import { setSelectedSectionTags } from "../../store/ui/actionCreators/nutritionPaneActionCreators";
import { BaseDialog } from "./BaseDialog";
import { Button, Typography } from "@material-ui/core";
import useUpdateSelection from "../screens/databases/documents/tabs/ingredients/hooks/useUpdateSelection";

interface DeleteRowsDialogProps {
  dayIndex: number;
  open: boolean;
  onClose: () => void;
  rowsToRemove: FoodItemPosition[];
}

export const DeleteRowsDialog = React.memo<DeleteRowsDialogProps>(
  ({ dayIndex, open, onClose, rowsToRemove }) => {
    const dispatch = useDispatch();

    const updateSelection = useUpdateSelection()

    const onResetSelection = () =>
      batch(() => {
        updateSelection([])
        dispatch(setSelectedSectionTags([]));
      });

    const onDeleteFoodItems = (rowsToRemove: FoodItemPosition[]) =>
      dispatch(deleteSelectedFoodItems(rowsToRemove));

    const onDeleteSection = (sectionsToDelete: number[]) =>
      dispatch(deleteSections(dayIndex, sectionsToDelete, templateId));

    const templateId: string = useSelector<RootState, string>(
      templateIdSelector
    );

    const onConfirmDeleteFoodItems = () => {
      onDeleteFoodItems(rowsToRemove);
      onResetSelection();
      onClose();
    };

    const onConfirmDeleteSections = () => {
      batch(() => {
        onDeleteSection(sectionsToDelete);
        onResetSelection();
      });
      onClose();
    };

    const day: Day = useSelector<RootState, Day>(
      (state: RootState) => daySelector(state, dayIndex)!
    );

    const sectionsToDelete = checkForOnlySectionsSelected(day, rowsToRemove);

    const dialogOptions: DialogOption[] = [
      {
        text: "Cancel",
        onClick: onClose,
        color: appTheme.colors.xiketic,
      },
      {
        text: `Delete (${rowsToRemove.length}) food item${rowsToRemove.length > 1 ? "s" : ""}`,
        onClick: onConfirmDeleteFoodItems,
        color: appTheme.colors.error,
      },
    ];

    if (sectionsToDelete.length && day.sections.length > 1)
      dialogOptions.push({
        text: `Delete (${sectionsToDelete.length}) section${sectionsToDelete.length > 1 ? "s" : ""}`,

        onClick: onConfirmDeleteSections,
        color: appTheme.colors.error,
      });

    const action: ReactNode = (
      <div>
        {dialogOptions.map((option: DialogOption) => (
          <Button
            data-cy="dialogButton"
            key={option.text}
            onClick={option.onClick}
            style={{ color: option.color }}
          >
            {option.text}
          </Button>
        ))}
      </div>
    );

    const body: ReactNode = (
      <Typography component="div" variant="body1" data-cy="dialogDescription">
        {`Are you sure you want to delete ${rowsToRemove.length > 1 ? "these items" : "this item"}?`}
      </Typography>
    );

    return (
      <BaseDialog
        open={open}
        onClose={onClose}
        title=""
        body={body}
        action={action}
        maxWidth="md"
      ></BaseDialog>
    );
  }
);

import React, { useState } from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { appTheme } from "../../../../styling/style";
import { CreateDatabaseDialog } from "../../../dialogs/database/CreateDatabaseDialog";
import { UserDatabaseSummary } from "../../../../data/models/userDatabase";
import DatabaseListItem from "./components/DatabaseListItem";

import DatabaseTable from "./components/DatabaseTable";
import useRecentDatabases from "../../appBar/hooks/useRecentDatabases";
import FoodworksBreadcrumb from "../../../common/FoodworksBreadcrumb";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  body: {
    width: "60%",
    flexDirection: "column",
    alignItems: "center",
  },
  databaseList: {
    display: "flex",
    alignItems: "center",
    padding: 0,
  },
  databaseListSection: {
    marginTop: 25,
  },
  addIcon: {
    color: appTheme.colors.primary,
  },
  createDatabaseContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  iconButton: {
    flex: 1,
    width: 40,
    height: 40,
    margin: "5px 0px",
  },
  availableContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    marginTop: 25,
    width: "100%",
  },
  mostRecent: {
    marginTop: 25,
  },
  databaseTable: {
    display: "flex",
    marginTop: 25,
  },
  breadcrumbContainer: {
    marginTop: 10,
  },
}));

interface LastModifiedDatabaseProps {
  database: UserDatabaseSummary[];
}

const LastModifiedDatabase = (
  props: LastModifiedDatabaseProps
): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.mostRecent}>
      <Typography variant="h3">Most Recent:</Typography>
      <DatabaseListItem summary={props.database[0]} />
    </div>
  );
};

interface DatabaseTableProps {
  userDatabaseSummaries: UserDatabaseSummary[];
}

export const DatabasesAvailableContainer = (props: DatabaseTableProps) => {
  const classes = useStyles();
  props.userDatabaseSummaries.sort((b, a) =>
    a.date.lastModified > b.date.lastModified
      ? 1
      : a.date.lastModified === b.date.lastModified
      ? a.date.created > b.date.created
        ? 1
        : -1
      : -1
  );

  return (
    <div className={classes.availableContainer}>
      <LastModifiedDatabase
        database={props.userDatabaseSummaries.slice(0, 1)}
      />
      <DatabaseTable userDatabaseSummaries={props.userDatabaseSummaries} />
    </div>
  );
};

const CreateDatabase = (): JSX.Element => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <div className={classes.createDatabaseContainer}>
      <Button
        data-cy="createDatabaseButton"
        startIcon={<Add className={classes.addIcon} />}
        onClick={() => setOpen(true)}
      >
        Create database
      </Button>
      {open && <CreateDatabaseDialog onClose={() => setOpen(false)} />}
    </div>
  );
};

const DatabaseDashboard = (): JSX.Element => {
  const classes = useStyles();

  const databases = useRecentDatabases(100);
  const NoDatabasesFound = (
    <Typography variant="h2">
      Consider creating a database to get started!
    </Typography>
  );

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.breadcrumbContainer}>
          <FoodworksBreadcrumb />
        </div>
        <div style={{ marginTop: 50 }}>
          <Typography data-cy="databaseDashboardTitle" variant="h1">
            Databases
          </Typography>
          <CreateDatabase />

          {!databases.length ? (
            NoDatabasesFound
          ) : (
            <DatabasesAvailableContainer userDatabaseSummaries={databases} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DatabaseDashboard;

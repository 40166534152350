import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DocumentProperties } from "../../../../../data/models/documentProperties/extraProperties";
import { DocumentStatus } from "../../../../../data/models/userDatabase";
import { updateDocumentStatus } from "../../../../../store/data/current-document/action-creators/document";
import { documentPropertiesSelector } from "../../../../../store/data/current-document/selectors/document";
import { saveDocument } from "../../../../../store/data/current-document/thunks/currentDocument";
import { archiveDocument } from "../../../../../store/data/current-document/thunks/document";
import { RootState } from "../../../../../store/reducers";

const useArchiveDocument = (documentId: string, isCurrentDocument: boolean) => {
  const dispatch = useDispatch();

  const documentProperties = useSelector<RootState, DocumentProperties>(
    documentPropertiesSelector
  );

  const onToggleArchiveDocument = useCallback(() => {
    let stateToChangeTo: DocumentStatus;

    if (documentProperties.state === "deleted") {
      stateToChangeTo = "deleted";
    } else {
      stateToChangeTo =
        documentProperties.state === "active" ? "archived" : "active";
    }

    if (isCurrentDocument) {
      dispatch(updateDocumentStatus(stateToChangeTo));
      dispatch(saveDocument(documentId));
    } else {
      dispatch(archiveDocument(documentId));
    }
  }, [documentId, documentProperties, dispatch, isCurrentDocument]);

  return onToggleArchiveDocument;
};

export default useArchiveDocument;

import React from "react";
import { InputAdornment, makeStyles } from "@material-ui/core";

import { FoodWorksNumberInput } from "../../../../../../common/FoodWorksTextInput";
import useClientRMR from "../hooks/useClientRMR";
import ProfileItem from "./ProfileItem";

const useStyles = makeStyles(() => ({
  input: {
    width: 100,
  },
}));

const CustomEnergyRequirementInput = React.memo((): JSX.Element => {
  const classes = useStyles();

  const [rate, setRate, updateRate, enabled] = useClientRMR();

  return (
    <ProfileItem
      component={
        <FoodWorksNumberInput
          className={classes.input}
          inputProps={{
            value: rate?.toString() || "",
            onBlur: updateRate,
            disabled: !enabled,
            endAdornment: (
              <InputAdornment variant="outlined" position="end">
                kJ
              </InputAdornment>
            ),
          }}
          setValue={(value) => setRate(!value ? undefined : Number(value))}
          maxValue={100000}
        />
      }
      disabled={!enabled}
      label="Custom Energy Requirement"
    />
  );
});

export default CustomEnergyRequirementInput;

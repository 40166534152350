import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  CompositionState,
  CompositionCache,
  Composition,
} from "../../../../../../data/models/composition";
import { CompositionCalculator } from "../../../../../../data/models/compositionCalculator";
import { RetentionFactor } from "../../../../../../data/models/documentProperties/retentionFactor";
import { ReferenceMeasure } from "../../../../../../data/models/referenceMeasure";
import {
  CurrentDocumentSelector,
  FinalCompositionSelector,
} from "../../../../../../store/data/current-document/selectors/currentDocument";
import { DocumentMap } from "../../../../../../store/data/reducers/documentCache";
import { compositionCacheSelector } from "../../../../../../store/data/selectors/compositionCache";
import { allCachedDocumentsSelector } from "../../../../../../store/data/selectors/documentCache";
import {
  ReferenceMeasuresSelector,
  getRetentionFactorMap,
} from "../../../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../../../store/reducers";
import { Document } from "../../../../../../data/models/document";
import { FoodItemPosition } from "../../../../../../data/models/foodItemPosition";
import { FoodItem } from "../../../../../../data/models/documentProperties/foodItem";
import { documentSelector } from "../../../../../../store/data/current-document/selectors/document";
import { selectedRowsSelector } from "../../../../../../store/ui/selectors/recipeGrid";
import { NutritionRadioOption } from "../../../../../../store/ui/reducers/nutritionPaneReducers";
import { compositionOptionSelector } from "../../../../../../store/ui/selectors/nutritionPaneSelectors";
import useSelectedClientDocuments from "../../../../clients/tabs/documents/hooks/useSelectedClientDocuments";
import { databaseIdSelector } from "../../../../../../store/data/selectors/database";
import { getIdentifier } from "../../../../../../data/models/documentProperties/foodId";
import { CurrentDocumentState } from "../../../../../../store/data/current-document/reducers/currentDocument";

const updateIngredientAmounts = (
  documents: Document[],
  nutrientId: string,
  compositionCalculator: CompositionCalculator,
  compositionCache: CompositionCache,
  documentCache: DocumentMap,
  selectedRows: FoodItemPosition[],
  compositionOption: NutritionRadioOption
): Map<string, number> => {
  const ingredients: Map<string, number> = new Map();
  if (nutrientId) {
    for (const document of documents) {
      const foodItems: FoodItem[] = compositionCalculator.getValidFoodItems(
        document,
        selectedRows
      );
      for (const item of foodItems) {
        if (!item.foodId?.identifier) {
          break;
        }
        const composition = compositionCache.compositionMap.get(
          item.foodId!.identifier
        )!;
        if (!composition) {
          break;
        }
        const document: Document = documentCache[item.foodId!.identifier];
        const quantity_in_g = item.quantity
          ? compositionCalculator.getBaseQuantity(item.quantity, document)
          : 0;
        const item_quantity = item.quantity?.amount
          ? item.quantity!.amount
          : undefined;
        if (!item_quantity) {
          break;
        }
        const amount = composition.getNutrientValue(nutrientId)
          ? composition.getNutrientValue(nutrientId)! *
            (quantity_in_g / 100) *
            item_quantity
          : 0;

        let existing_amount: number | undefined = ingredients.get(
          document.name
        );
        if (!existing_amount) {
          existing_amount = 0;
        }
        if (amount) {
          ingredients.set(document.name, existing_amount + amount);
        }
      }

      if (compositionOption === NutritionRadioOption.DAY) {
        for (const ingredient of ingredients.keys()) {
          ingredients.set(
            ingredient,
            ingredients.get(ingredient)! / document.days.length
          );
        }
      }

      if (compositionOption === NutritionRadioOption.MEGAJOULE) {
        const energyWeightRatio: number =
          compositionCalculator.calculateComposition(document, foodItems)
            .energy / 1000;
        for (const ingredient of ingredients.keys()) {
          ingredients.set(
            ingredient,
            ingredients.get(ingredient)! / energyWeightRatio
          );
        }
      }
    }
  }
  return ingredients;
};

const useIngredientSources = (
  nutrientId: string
): [number, Map<string, number>] => {
  const [nutrientCompositionTotal, setNutrientCompositionTotal] = useState(0);
  const [ingredients, setIngredients] = useState<Map<string, number>>(
    new Map([])
  );

  const totalComposition: CompositionState = useSelector<
    RootState,
    CompositionState
  >(FinalCompositionSelector);

  const compositionCache: CompositionCache = useSelector<
    RootState,
    CompositionCache
  >(compositionCacheSelector);
  const documentCache: DocumentMap = useSelector<RootState, DocumentMap>(
    allCachedDocumentsSelector
  );
  const referenceMeasures: ReferenceMeasure[] = useSelector<
    RootState,
    ReferenceMeasure[]
  >(ReferenceMeasuresSelector);

  const retentionFactorMap: Map<string, RetentionFactor> = useSelector<
    RootState,
    Map<string, RetentionFactor>
  >(getRetentionFactorMap);

  const selectedRows = useSelector<RootState, FoodItemPosition[]>(
    selectedRowsSelector
  );

  const compositionOption = useSelector<RootState, NutritionRadioOption>(
    compositionOptionSelector
  );
  const currentDatabaseId: string = useSelector<RootState, string>(
    databaseIdSelector
  );
  const [selectedDocumentIDs] = useSelectedClientDocuments();
  const selectedDocuments = useMemo(
    () =>
      selectedDocumentIDs.map(
        id => documentCache[getIdentifier(currentDatabaseId, id)]
      ),
    [currentDatabaseId, documentCache, selectedDocumentIDs]
  );

  const selectedDocCompositions: Composition[] = useMemo(
    () =>
      selectedDocumentIDs.map(documentId => {
        const compositionCalculator = new CompositionCalculator(
          compositionCache,
          documentCache,
          referenceMeasures,
          retentionFactorMap
        );
        const document: Document =
          documentCache[getIdentifier(currentDatabaseId, documentId)];

        const composition = compositionCalculator.calculateComposition(
          document,
          compositionCalculator.getValidFoodItems(document, [])
        );
        const days = document.days.length;
        return composition.multiplyByFactor(1 / days);
      }),
    [
      compositionCache,
      currentDatabaseId,
      documentCache,
      referenceMeasures,
      retentionFactorMap,
      selectedDocumentIDs,
    ]
  );

  const totalSelectedComposition = useMemo(
    () =>
      selectedDocCompositions
        .slice(1, selectedDocCompositions.length)
        .reduce(
          (prevComp, currComp) => prevComp.addComposition(currComp),
          selectedDocCompositions[0]
        ),
    [selectedDocCompositions]
  );

  const currentDocument: CurrentDocumentState = useSelector<
    RootState,
    CurrentDocumentState
  >(CurrentDocumentSelector);
  const documentValid = currentDocument.id !== "";
  const document: Document = useSelector<RootState, Document>(documentSelector);
  const documents = useMemo(() => [document], [document]);
  const updateIngredientAmountsMemo = useMemo(() => {
    return updateIngredientAmounts(
      !documentValid ? selectedDocuments : documents,
      nutrientId,
      new CompositionCalculator(
        compositionCache,
        documentCache,
        referenceMeasures,
        retentionFactorMap
      ),
      compositionCache,
      documentCache,
      selectedRows,
      compositionOption
    );
  }, [
    documentValid,
    selectedDocuments,
    documents,
    nutrientId,
    compositionCache,
    documentCache,
    referenceMeasures,
    retentionFactorMap,
    selectedRows,
    compositionOption,
  ]);

  useEffect(() => {
    if (!documentValid && !!totalSelectedComposition) {
      setNutrientCompositionTotal(
        totalSelectedComposition.getNutrientValue(nutrientId)!
      );
    } else {
      setNutrientCompositionTotal(totalComposition[nutrientId]);
    }
    setIngredients(updateIngredientAmountsMemo);
  }, [
    nutrientId,
    totalComposition,
    documentValid,
    totalSelectedComposition,
    updateIngredientAmountsMemo,
  ]);

  return [nutrientCompositionTotal, ingredients];
};

export default useIngredientSources;

import React from "react";
import { makeStyles } from "@material-ui/core";

import ClientNavigation from "../navigation/client/ClientNavigation";
import ClientDatabaseNavigation from "../navigation/client_database/ClientDatabaseNavigation";
import ClientScreen from "./ClientScreen";
import ClientDashboard from "./dashboard/ClientDashboard";
import useAutoResetNutrititionPane from "./hooks/useAutoResetNutrititionPane";
import {
  useIsClientDashBoard,
  useIsClientScreen,
} from "./hooks/useClientScreen";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
}));

const Clients = (): JSX.Element => {
  const classes = useStyles();

  const isClientDashboard: boolean = useIsClientDashBoard();
  const isClientScreen: boolean = useIsClientScreen();

  useAutoResetNutrititionPane();

  return (
    <>
      {isClientDashboard && (
        <div className={classes.root}>
          <ClientDatabaseNavigation />
          <ClientDashboard />
        </div>
      )}
      {isClientScreen && (
        <div className={classes.root}>
          <ClientNavigation />
          <ClientScreen />
        </div>
      )}
    </>
  );
};

export default Clients;

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActivityLevel } from "../../../../../../../data/models/nutrientReferenceValues";
import { setClientActivityLevel } from "../../../../../../../store/data/current_client/action_creators/clientProfile";
import { clientActivityLevelSelector } from "../../../../../../../store/data/current_client/selectors/clientProfile";
import { RootState } from "../../../../../../../store/reducers";

const useClientActivityLevel = (): [
  ActivityLevel,
  (input: ActivityLevel) => void
] => {
  const dispatch = useDispatch();

  const activityLevel: ActivityLevel = useSelector<RootState, ActivityLevel>(
    clientActivityLevelSelector
  );

  const onSetClientActivityLevel = useCallback(
    (input: ActivityLevel) => dispatch(setClientActivityLevel(input)),
    [dispatch]
  );

  return [activityLevel, onSetClientActivityLevel];
};

export default useClientActivityLevel;

import { ClientProfile } from "../../../../data/models/clientProperties/clientProfile";
import { FoodWorksDate } from "../../../../data/models/documentProperties/date";
import { RootState } from "../../../reducers";
import { ClientGoal } from "../../../../data/models/clientProperties/clientGoal";

export const clientNameSelector = (state: RootState): string =>
  state.clientDatabase.currentClient.name;

export const clientDocumentIdsSelector = (state: RootState): string[] =>
  state.clientDatabase.currentClient.documents;

export const clientProfileSelector = (state: RootState): ClientProfile =>
  state.clientDatabase.currentClient.profile;

export const clientMobileSelector = (state: RootState): string =>
  state.clientDatabase.currentClient.contact.mobile;

export const clientEmailSelector = (state: RootState): string =>
  state.clientDatabase.currentClient.contact.email;

export const clientAddressSelector = (state: RootState): string =>
  state.clientDatabase.currentClient.contact.address;

export const clientNotesSelector = (state: RootState): string =>
  state.clientDatabase.currentClient.notes;

export const clientDateSelector = (state: RootState): FoodWorksDate =>
  state.clientDatabase.currentClient.date;

export const clientTagSelector = (state: RootState): string[] =>
  state.clientDatabase.currentClient.tagIds;

export const clientGoalSelector = (state: RootState): ClientGoal[] =>
  state.clientDatabase.currentClient.goals;

export const clientStateSelector = (state: RootState): string =>
  state.clientDatabase.currentClient.state;

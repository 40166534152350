import {
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  Dispatch,
} from "react";
import { useSelector } from "react-redux";

import { ClientSummary } from "../../../../../data/models/client";
import { Tag } from "../../../../../data/models/documentProperties/section";
import { clientTagSelector } from "../../../../../store/data/current_client/selectors/client";
import {
  clientDatabaseTagsSelector,
  clientSummariesSelector,
} from "../../../../../store/data/selectors/clientDatabase";
import { RootState } from "../../../../../store/reducers";

export const useCurrentClientTags = (): [
  Tag[],
  Dispatch<SetStateAction<Tag[]>>
] => {
  const clientTags: Tag[] = useSelector<RootState, Tag[]>(
    clientDatabaseTagsSelector
  );

  const clientTagIds: string[] = useSelector<RootState, string[]>(
    clientTagSelector
  );

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const loadTags = useCallback(() => {
    const tags: Tag[] = clientTags.filter((tag) =>
      clientTagIds.includes(tag.id)
    );

    setSelectedTags(tags);
  }, [clientTags, clientTagIds]);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  return [selectedTags, setSelectedTags];
};

export const useClientSummariesTag = (
  clientId: string
): [Tag[], Dispatch<SetStateAction<Tag[]>>] => {
  const clientTags: Tag[] = useSelector<RootState, Tag[]>(
    clientDatabaseTagsSelector
  );

  const clientSummaries = useSelector<RootState, ClientSummary[]>(
    clientSummariesSelector
  );

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const loadTags = useCallback(() => {
    const clientSummary: ClientSummary | undefined = clientSummaries.find(
      (summary: ClientSummary) => summary.clientId === clientId
    );

    if (clientSummary) {
      const tags: Tag[] = clientTags.filter((tag) =>
        clientSummary.tagIds.includes(tag.id)
      );
      setSelectedTags(tags);
    }
  }, [clientTags, clientId, clientSummaries]);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  return [selectedTags, setSelectedTags];
};

export const useLoadClients = (
  selectedTag: string
): [string[], Dispatch<SetStateAction<string[]>>] => {
  const [selectedClients, setSelectedClients] = useState<string[]>([]);

  const clientSummaries = useSelector<RootState, ClientSummary[]>(
    clientSummariesSelector
  );

  const loadClients = useCallback(() => {
    const clients: string[] = clientSummaries
      .filter((summary: ClientSummary) => summary.tagIds.includes(selectedTag))
      .map((summary: ClientSummary) => summary.clientId);
    setSelectedClients(clients);
  }, [clientSummaries, selectedTag]);

  useEffect(() => {
    loadClients();
  }, [clientSummaries, loadClients]);

  return [selectedClients, setSelectedClients];
};

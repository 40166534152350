import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core";

import {
  Measurement,
  MeasurementCategory,
} from "../../../../../../data/models/clientProperties/measurement";
import { addClientMeasurement } from "../../../../../../store/data/current_client/action_creators/clientMeasurements";
import { FoodWorksTextInput } from "../../../../../common/FoodWorksTextInput";
import { NAME_FIELD } from "../../../../../../constants/textInputs";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  gridContainer: {
    margin: 10
  }
}));

interface Props {
  category: MeasurementCategory;
  close: () => void;
}

const NewMeasurementInput = (props: Props) => {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [units, setUnits] = useState("");

  const dispatch = useDispatch();

  const handleSave = () => {
    const measurement: Measurement = {
      category: props.category,
      unit: units,
      label: name,
      subCategory: "Custom",
      active: true,
      entries: [],
    };
    dispatch(addClientMeasurement(measurement));
    props.close();
  };


  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignItems="center" justify="space-around" className={classes.gridContainer}>
        <Grid item>
          <Typography>Measurement name</Typography>
        </Grid>
        <Grid>
          <FoodWorksTextInput maxLength={NAME_FIELD} onChange={(event) => setName(event.target.value)}/>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" justify="space-around" className={classes.gridContainer}>
        <Grid item>
          <Typography>Unit of measurement</Typography>
        </Grid>
        <Grid>
          <FoodWorksTextInput maxLength={NAME_FIELD} onChange={(event) => setUnits(event.target.value)} />
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" justify="flex-end" >
        <Grid item>
          <Button onClick={props.close}>Close</Button>
        </Grid>
        <Grid>
          <Button onClick={handleSave} disabled={name && units ? false : true} >Save measurement</Button>
        </Grid>
      </Grid>
      
      
    </div>
  );
};

export default NewMeasurementInput;

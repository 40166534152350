import { RootState } from "../../reducers";
import { NavigationState } from "../reducers/navigationReducers";

export const SearchTextSelector = (
  state: RootState
): NavigationState["searchText"] => state.navigation.searchText;

export const QuickFiltersEnabledSelector = (
  state: RootState
): NavigationState["quickFiltersEnabled"] =>
  state.navigation.quickFiltersEnabled;

export const SelectedQuickFiltersSelector = (
  state: RootState
): NavigationState["selectedQuickFilters"] =>
  state.navigation.selectedQuickFilters;

export const NavIsLoadingSelector = (
  state: RootState
): NavigationState["isLoading"] => state.navigation.isLoading;

export const navAccordionsExpandedSelector = (state: RootState): number[] =>
  state.navigation.navAccordionsExpanded;

import React, { FunctionComponent, ReactNode, useState } from "react";
import {
  makeStyles,
  IconButton,
  Typography,
  Collapse,
  List,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Edit } from "@material-ui/icons";

import { appTheme } from "../../../../styling/style";
import { DataSourceDialog } from "../../../dialogs/database/DataSourceDialog";

const useStyles = makeStyles(() => ({
  listRoot: { overflow: "auto", maxHeight: 200 },
  listItemRoot: {
    display: "flex",
  },
  bulletPoint: {
    color: appTheme.colors.xiketic,
    marginRight: 2,
    width: 5,
    height: 5,
  },
  nutrientListPart: {
    display: "flex",
    alignItems: "center",
  },
  headerRoot: {
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    textDecoration: "underline",
  },
  editIcon: {
    color: appTheme.colors.secondary,
    width: 20,
    marginLeft: 5,
  },
}));

interface EnabledDatasourcesListProps {
  openDialog: boolean;
  onToggleDialog: () => void;
}

export const EnabledDatasourcesList: FunctionComponent<EnabledDatasourcesListProps> = ({
  openDialog,
  onToggleDialog,
}) => {
  const classes = useStyles();

  const [showList, setShowList] = useState(false);

  const enabledDatasourcesHeader: ReactNode = (
    <div className={classes.headerRoot}>
      <IconButton
        size="small"
        color="secondary"
        onClick={() => setShowList((prev) => !prev)}
      >
        {showList ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <Typography className={classes.headerText} variant="h4">
        Data sources
      </Typography>
      <IconButton
        size="small"
        data-cy="enabledDatasourcesButton"
        onClick={onToggleDialog}
      >
        <Edit className={classes.editIcon} />
      </IconButton>
      {openDialog && <DataSourceDialog open onClose={onToggleDialog} />}
    </div>
  );

  return (
    <div>
      {enabledDatasourcesHeader}
      <Collapse in={showList}>
        <List className={classes.listRoot}></List>
      </Collapse>
    </div>
  );
};

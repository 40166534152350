import firebase, { firestore } from "firebase";

import { FoodWorksDate } from "../../models/documentProperties/date";
import {
  Database,
  databaseConverter,
  DatabaseProperties,
  DocumentSummary,
  Profile,
  SharingHistory,
  UserDatabaseSummary,
} from "../../models/userDatabase";
import { Document, documentConverter } from "../../models/document";
import Firebase from "../firebase";
import { initialDatabasePropertiesState } from "../../../store/data/reducers/databaseProperties";
import { Tag } from "../../models/documentProperties/section";

const USER_DATABASES_DATABASE = "user-databases";
const DATABASE_DOCUMENTS = "documents";

export class FirebaseUserDatabases {
  collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(firestore: firebase.firestore.Firestore) {
    this.collection = firestore.collection(USER_DATABASES_DATABASE);
  }

  userDatabaseDocument(
    databaseId?: string
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    return databaseId ? this.collection.doc(databaseId) : this.collection.doc();
  }

  userDocument(
    databaseId: string,
    documentId: string
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    return this.userDatabaseDocument(databaseId)
      .collection(DATABASE_DOCUMENTS)
      .doc(documentId);
  }

  async doGetUserDatabase(databaseId: string): Promise<Database> {
    const databaseData: firebase.firestore.DocumentSnapshot<Database> = await this.userDatabaseDocument(
      databaseId
    )
      .withConverter(databaseConverter)
      .get();

    return databaseData.data()!;
  }

  async doGetUserDocument(
    databaseId: string,
    documentId: string
  ): Promise<Document> {
    const documentData: firestore.DocumentSnapshot<Document> = await this.userDatabaseDocument(
      databaseId
    )
      .collection(DATABASE_DOCUMENTS)
      .withConverter(documentConverter)
      .doc(documentId)
      .get();

    return documentData.data()!;
  }

  async doCreateCopyOfDatabase(databaseToCopy: Database): Promise<string> {
    return Promise.resolve(this.userDatabaseDocument()).then((databaseData) =>
      databaseData
        .set({
          ...databaseToCopy,
          summary: { ...databaseToCopy.summary, id: databaseData.id },
        })
        .then(() => databaseData.id)
    );
  }

  async doCreateUserDatabase(
    name: string,
    date: FoodWorksDate
  ): Promise<string> {
    const newUserDatabase = this.userDatabaseDocument();

    const newDatabase: Database = {
      documentSummaries: [],
      summary: {
        id: newUserDatabase.id!,
        name: name,
        date: date,
        copiedDatabaseProperties: initialDatabasePropertiesState,
      },
      properties: initialDatabasePropertiesState,
      description: "",
      profiles: [],
      sharingHistory: [],
      documentTags: [],
    };

    await newUserDatabase.set(newDatabase);

    return newUserDatabase.id;
  }

  doCreateUserDocument(
    databaseId: string,
    document: Document
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > {
    return this.userDatabaseDocument(databaseId)
      .collection(DATABASE_DOCUMENTS)
      .withConverter(documentConverter)
      .add(document);
  }

  async doUpdateUserDocument(
    databaseId: string,
    documentId: string,
    document: Document
  ): Promise<[string, Document]> {
    return this.userDocument(databaseId, documentId)
      .withConverter(documentConverter)
      .set(document, {
        merge: true,
      })
      .then(() => [documentId, document]);
  }

  async doCreateUserDocumentSummary(
    databaseId: string,
    document: Document,
    documentId: string
  ): Promise<DocumentSummary> {
    const newDocumentSummary: DocumentSummary = {
      documentId: documentId,
      templateId: document.templateId,
      label: document.name,
      status: document.properties.state,
      searchableProperties: { ...document.identifier },
      sectionTags: document.sectionTags,
      lastModified: document.date.lastModified,
      documentTagIds: document.documentTags,
    };
    return this.userDatabaseDocument(databaseId)
      .update({
        documentSummaries: firebase.firestore.FieldValue.arrayUnion(
          newDocumentSummary
        ),
      })
      .then(() => newDocumentSummary);
  }

  async doUpdateUserDocumentSummary(
    databaseId: string,
    newDocumentSummary: DocumentSummary,
    existingDocumentSummary: DocumentSummary
  ): Promise<void> {
    const databaseRef = this.userDatabaseDocument(databaseId);

    const batch = Firebase.batch();

    batch.update(databaseRef, {
      documentSummaries: firebase.firestore.FieldValue.arrayRemove(
        existingDocumentSummary
      ),
    });

    batch.update(databaseRef, {
      documentSummaries: firebase.firestore.FieldValue.arrayUnion(
        newDocumentSummary
      ),
    });

    await batch.commit();
  }

  async doUpdateUserDocumentSummaries(
    databaseId: string,
    documentSummaries: DocumentSummary[]
  ): Promise<void> {
    return this.userDatabaseDocument(databaseId).update({
      documentSummaries: documentSummaries,
    });
  }

  async doRemoveUserDocumentSummary(
    databaseId: string,
    documentSummary: DocumentSummary
  ): Promise<void> {
    await this.userDatabaseDocument(databaseId).update({
      documentSummaries: firebase.firestore.FieldValue.arrayRemove(
        documentSummary
      ),
    });
  }

  async doUpdateUserDatabaseSummaryName(
    databaseId: string,
    newDatabaseName: string
  ) {
    const database: Database = await this.doGetUserDatabase(databaseId);

    const updatedDatabaseSummary: UserDatabaseSummary = {
      ...database.summary,
      name: newDatabaseName,
    };

    await this.userDatabaseDocument(databaseId).update({
      summary: updatedDatabaseSummary,
    });
  }

  async doUpdateUserDatabaseSummaryCopiedProperties(
    databaseId: string,
    copiedDatabaseProperties: DatabaseProperties
  ) {
    const database: Database = await this.doGetUserDatabase(databaseId);

    const updatedDatabaseSummary: UserDatabaseSummary = {
      ...database.summary,
      copiedDatabaseProperties: copiedDatabaseProperties,
    };

    await this.userDatabaseDocument(databaseId).update({
      summary: updatedDatabaseSummary,
    });
  }

  doUpdateUserDatabaseProperties(
    databaseId: string,
    databaseProperties: DatabaseProperties
  ): Promise<void> {
    return this.userDatabaseDocument(databaseId).update({
      properties: databaseProperties,
    });
  }

  doUpdateUserDatabaseDescription(
    databaseId: string,
    description: string
  ): Promise<void> {
    return this.userDatabaseDocument(databaseId).update({
      description: description,
    });
  }

  doUpdateUserDatabaseSharingHistory(
    databaseId: string,
    history: SharingHistory[]
  ): Promise<void> {
    return this.userDatabaseDocument(databaseId).update({
      sharingHistory: history,
    });
  }

  async doUpdateUserDatabaseSummaryLastModified(
    databaseId: string,
    lastModified: string
  ) {
    const database: Database = await this.doGetUserDatabase(databaseId);

    const updatedDatabaseSummary: UserDatabaseSummary = {
      ...database.summary,
      date: { ...database.summary.date, lastModified: lastModified },
    };

    await this.userDatabaseDocument(databaseId).update({
      summary: updatedDatabaseSummary,
    });
  }

  async doUpdateUserDatabaseCopiedDatabaseProperties(
    databaseId: string,
    databaseProperties: DatabaseProperties
  ) {
    const database: Database = await this.doGetUserDatabase(databaseId);

    const updatedDatabaseSummary: UserDatabaseSummary = {
      ...database.summary,
      copiedDatabaseProperties: databaseProperties,
    };

    await this.userDatabaseDocument(databaseId).update({
      summary: updatedDatabaseSummary,
    });
  }

  doUpdateUserDatabaseProfiles = (
    databaseId: string,
    profiles: Profile[]
  ): Promise<void> =>
    this.userDatabaseDocument(databaseId).update({ profiles: profiles });

  doUpdateDatabaseDocumentTags = (
    databaseId: string,
    documentTags: Tag[]
  ): Promise<void> =>
    this.userDatabaseDocument(databaseId).update({
      documentTags: documentTags,
    });
}

import React, { FunctionComponent, ReactNode, useState } from "react";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { RootState } from "../../../../../../store/reducers";
import { appTheme } from "../../../../../../styling/style";
import {
  CommonMeasure,
  CommonMeasures,
} from "../../../../../../data/models/documentProperties/measure";
import { MeasureRow } from "./MeasureRow";
import {
  DefaultCommonMeasureSelector,
  CommonMeasuresSelector,
} from "../../../../../../store/data/current-document/selectors/commonMeasures";

const TABLE_COLUMN_WIDTHS = {
  NAME: "20%",
  DEFAULT_MEASURES: "10%",
  WEIGHT: "10%",
  DESCRIPTION: "45%",
  DELETE: "5%",
};

const useStyles = makeStyles({
  root: {
    maxHeight: "100%",
    height: "100%",
  },
  table: {
    minWidth: 650,
  },
  headerCell: {
    backgroundColor: appTheme.colors.white[4],
  },
  name: { width: TABLE_COLUMN_WIDTHS.NAME },
  default: {
    width: TABLE_COLUMN_WIDTHS.DEFAULT_MEASURES,
    textAlign: "center",
  },
  weight: { width: TABLE_COLUMN_WIDTHS.WEIGHT },
  description: { width: TABLE_COLUMN_WIDTHS.DESCRIPTION },
  deleteButton: { width: TABLE_COLUMN_WIDTHS.DELETE },
});

export interface SimpleTableProps {}

export const MeasuresTable: FunctionComponent<SimpleTableProps> = () => {
  const classes = useStyles();

  const commonMeasures = useSelector<RootState, CommonMeasures["measures"]>(
    CommonMeasuresSelector
  );

  const defaultMeasureId = useSelector<RootState, CommonMeasures["default"]>(
    DefaultCommonMeasureSelector
  );

  const [lastRowId, setLastRowId] = useState(uuidv4());

  const tableRows: ReactNode[] = commonMeasures.map(
    (measure: CommonMeasure, index: number): ReactNode => {
      if (lastRowId === measure.id) setLastRowId(uuidv4());
      return (
        <MeasureRow
          key={measure.id}
          measure={measure}
          isDefault={defaultMeasureId === measure.id}
          isLastRow={false}
        />
      );
    }
  );

  tableRows.push(
    <MeasureRow
      key={lastRowId}
      measure={{
        id: lastRowId,
        name: "",
        value: 100,
        description: "",
        usedIn: [],
      }}
      isLastRow={true}
      isDefault={false}
    />
  );

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table
        stickyHeader
        className={classes.table}
        aria-label="simple table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.headerCell} ${classes.name}`}>
              <Typography variant="h5">Name</Typography>
            </TableCell>
            <TableCell className={`${classes.headerCell} ${classes.default}`}>
              <Typography variant="h5">Default measure</Typography>
            </TableCell>
            <TableCell className={`${classes.headerCell} ${classes.weight}`}>
              <Typography variant="h5">Weight (g)</Typography>
            </TableCell>
            <TableCell
              className={`${classes.headerCell} ${classes.description}`}
            >
              <Typography variant="h5">Description</Typography>
            </TableCell>
            <TableCell
              className={`${classes.headerCell} ${classes.deleteButton}`}
            />
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

import React from "react";
import { makeStyles, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

import { alertMessageSelector } from "../../store/ui/selectors/alert";
import { RootState } from "../../store/reducers";
import { resetAlert } from "../../store/ui/actionCreators/alert";

const useStyles = makeStyles(() => ({
  successAlert: { marginTop: 40 },
}));

export const SuccessAlert = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onResetAlert = () => dispatch(resetAlert());

  const currentAlertMessage =
    useSelector<RootState, string>(alertMessageSelector);

  const onFeedbackAlertClose = (_: any, reason: string) => {
    if (reason === "timeout") {
      onResetAlert();
    }
  };

  return (
    <>
      {currentAlertMessage && (
        <Snackbar
          data-cy="successAlert"
          className={classes.successAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={!!currentAlertMessage}
          autoHideDuration={2000}
          onClose={onFeedbackAlertClose}
        >
          <Alert severity="success">{currentAlertMessage}</Alert>
        </Snackbar>
      )}
    </>
  );
});

import {
  OverridesScreenActions,
  SET_RECENT_MAPPED_DOCUMENT_ID,
} from "../actions/overridesScreen";

export interface OverridesScreenState {
  recentMappedDocumentId: string;
}

export const initialOverridesScreenState: OverridesScreenState = {
  recentMappedDocumentId: "",
};

export const overridesScreen = (
  state: OverridesScreenState = initialOverridesScreenState,
  action: OverridesScreenActions
) => {
  switch (action.type) {
    case SET_RECENT_MAPPED_DOCUMENT_ID:
      return { ...state, recentMappedDocumentId: action.id };
    default:
      return state;
  }
};

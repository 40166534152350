import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as DatabaseIcon } from "../../../../images/icons/Database.svg";
import { RootState } from "../../../../store/reducers";
import {
  databaseIdSelector,
  databaseNameSelector,
} from "../../../../store/data/selectors/database";
import { handleRouteChange } from "../../../../store/ui/thunks/routing";
import { getDatabaseRouteData } from "../../../../data/routing/routing";
import NavigationHeader from "../shared/NavigationHeader";

const DatabaseButton = (): JSX.Element => {
  const dispatch = useDispatch();

  const onNavigateToDatabaseOverview = () =>
    dispatch(handleRouteChange(getDatabaseRouteData(currentDatabaseId)));

  const currentDatabaseId: string =
    useSelector<RootState, string>(databaseIdSelector);

  const databaseName: string =
    useSelector<RootState, string>(databaseNameSelector);

  return (
    <NavigationHeader
      onClick={onNavigateToDatabaseOverview}
      text={databaseName}
      icon={<DatabaseIcon />}
    />
  );
};

export default DatabaseButton;

import { useState, useCallback, useEffect } from "react";

import Firebase from "../../../../data/Firebase";
import {
  Database,
  DocumentSummary,
} from "../../../../data/models/userDatabase";

interface SummariesState {
  databaseId: string;
  documentSummaries: DocumentSummary[];
}

export const useDatabaseSummaries = (firebase: Firebase): Database[] => {
  const [userDatabases, setUserDatabases] = useState<Database[]>([]);

  const getUserDatabases = useCallback(
    async (): Promise<Database[]> =>
      await firebase.getAllUserDatabases(firebase.auth.currentUser!.uid),
    [firebase]
  );

  useEffect(() => {
    getUserDatabases().then((databases: Database[]) => {
      setUserDatabases(databases);
    });
  }, [getUserDatabases]);

  return userDatabases;
};

export const useDocumentSummaries = (
  firebase: Firebase
): [DocumentSummary[], string, (id: string) => void, boolean] => {
  const userDatabases: Database[] = useDatabaseSummaries(firebase);

  const [summaries, setSummaries] = useState<SummariesState>({
    databaseId: "",
    documentSummaries: [],
  });

  const setDatabaseId = useCallback(
    (id: string) =>
      setSummaries({
        ...summaries,
        databaseId: id,
      }),
    [summaries]
  );

  useEffect(() => {
    const updateSummaries = async (databaseId: string) => {
      let documentSummaries: DocumentSummary[] = [];
      if (databaseId) {
        const database: Database = userDatabases.find(
          (database: Database): boolean => database.summary.id === databaseId
        )!;
        documentSummaries = database.documentSummaries;
      }
      setSummaries({
        databaseId: databaseId,
        documentSummaries: documentSummaries,
      });
    };

    updateSummaries(summaries.databaseId);
  }, [summaries.databaseId, firebase, userDatabases]);

  return [
    summaries.documentSummaries,
    summaries.databaseId,
    setDatabaseId,
    !!userDatabases.length,
  ];
};

import { CommonMeasure } from "../../../../data/models/documentProperties/measure";
import {
  IActionsAddCommonMeasure,
  IActionsSetDefaultCommonMeasure,
  IActionsUpdateCommonMeasure,
  IActionsRemoveCommonMeasure,
} from "../actions/commonMeasures";

export const addCommonMeasure = (
  measure: CommonMeasure
): IActionsAddCommonMeasure => {
  return {
    type: "ADD_COMMON_MEASURE",
    measure: measure,
  };
};

export const setDefaultCommonMeasure = (
  measureId: string
): IActionsSetDefaultCommonMeasure => {
  return {
    type: "SET_DEFAULT_COMMON_MEASURE",
    measureId: measureId,
  };
};

export const updateCommonMeasure = (
  updatedMeasure: CommonMeasure
): IActionsUpdateCommonMeasure => {
  return {
    type: "UPDATE_COMMON_MEASURE",
    updatedMeasure: updatedMeasure,
  };
};

export const removeCommonMeasure = (
  measure: string
): IActionsRemoveCommonMeasure => {
  return {
    type: "REMOVE_COMMON_MEASURE",
    measure: measure,
  };
};

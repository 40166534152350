import React, { useState } from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { FoodWorksNumberInput } from "../../../../../common/FoodWorksTextInput";
import { BaseDialog } from "../../../../../dialogs/BaseDialog";
import { TextOverflowTooltip } from "../../../../../common/InfoTooltip";
import { updateClientMeasurementEntry } from "../../../../../../store/data/current_client/action_creators/clientMeasurements";
import { MeasurementEntry } from "../../../../../../data/models/clientProperties/measurement";
import FoodworksDateTimePicker from "../../../../../common/date/FoodworksDateTimePicker";
import FoodworksTimeDatePicker from "../../../../../common/date/FoodworksTimeDatePicker";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    width: 250,
  },
  label: {
    marginRight: 5,
  },
  value: {
    width: 75,
  },
  unit: {
    marginLeft: 5,
    maxWidth: 75,
  },
}));

interface Props {
  measurementTitle: string;
  measurementUnit: string;
  measurementEntry: MeasurementEntry;
  onClose: () => void;
}

const EditMeasurementEntryDialog = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { measurementTitle, measurementUnit, measurementEntry, onClose } = {
    ...props,
  };

  const dispatch = useDispatch();

  const onUpdateMeasurementEntry = () =>
    dispatch(
      updateClientMeasurementEntry(measurementTitle, measurementUnit, {
        ...measurementEntry,
        value: value,
        date: date.toISOString(),
      })
    );

  const [value, setValue] = useState<number>(measurementEntry.value);
  const [date, setDate] = useState<Date>(new Date(measurementEntry.date));

  const changesMade: boolean =
    value !== measurementEntry.value ||
    measurementEntry.date !== date.toISOString();

  const onSaveChanges = () => {
    onUpdateMeasurementEntry();
    onClose();
  };

  return (
    <BaseDialog
      maxWidth="sm"
      body={
        <div className={classes.root}>
          <div className={classes.inputContainer}>
            <Typography className={classes.label}>Date</Typography>
            <FoodworksDateTimePicker
              date={date}
              handleChange={input => setDate(input!)}
            />
            <FoodworksTimeDatePicker
              date={date}
              handleChange={input => setDate(input!)}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography className={classes.label}>Value</Typography>
            <FoodWorksNumberInput
              setValue={input => setValue(Number(input))}
              inputProps={{ className: classes.value, value: value }}
            />
            <div className={classes.unit}>
              <TextOverflowTooltip label={measurementUnit}>
                <Typography noWrap>{measurementUnit}</Typography>
              </TextOverflowTooltip>
            </div>
          </div>
        </div>
      }
      open
      onClose={onClose}
      title={`Edit ${measurementTitle} entry`}
      action={[
        <Button onClick={onClose}>Cancel</Button>,
        <Button
          color="secondary"
          disabled={!changesMade}
          onClick={onSaveChanges}
        >
          Save changes
        </Button>,
      ]}
    />
  );
};

export default EditMeasurementEntryDialog;

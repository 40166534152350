import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from "react";
import {
  Button,
  Card,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Delete, FileCopy } from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { UserDatabaseSummary } from "../../../../../data/models/userDatabase";
import { FoodworksTooltip } from "../../../../common/InfoTooltip";
import { handleRouteChange } from "../../../../../store/ui/thunks/routing";
import { DeleteDatabaseDialog } from "../../../../dialogs/database/DeleteDatabaseDialog";
import { appTheme } from "../../../../../styling/style";
import duplicateDatabase from "../../../../../store/data/thunks/database/duplicateDatabase";
import { getDatabaseRouteData } from "../../../../../data/routing/routing";

const useStyles = makeStyles(() => ({
  "@keyframes enable": {
    "0%": {
      transform: "scale(1)",
      backgroundColor: appTheme.colors.white[5],
    },
    "60%": {
      transform: "scale(1.1)",
    },
    "100%": {
      transform: "scale(1)",
      backgroundColor: appTheme.colors.white[3],
    },
  },
  root: {
    margin: 20,
    height: 120,
  },
  button: {
    padding: 0,
    display: "flex",
  },
  hoverCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: appTheme.colors.white[5],
    height: 50,
    width: 100,
    padding: 25,
    "&:hover": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 140,
      height: 50,
      padding: "40px 5px 40px 25px",
      animation: "$enable 0.3s linear",
    },
  },
  iconButton: {
    flex: 1,
    width: 40,
    height: 40,
    margin: "5px 0px",
  },
  dateText: {
    fontSize: 10,
    marginTop: 5,
  },
  onHoverButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    marginLeft: 5,
  },
  divider: { width: "100%" },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface IconButtonWithToolTipProps {
  tooltip: string;
  onClick: () => void;
  children?: ReactNode;
  dataCy?: string;
}

const IconButtonWithToolTip = ({
  tooltip,
  onClick,
  children,
  dataCy,
}: IconButtonWithToolTipProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FoodworksTooltip title={tooltip}>
      <div
        data-cy={dataCy}
        className={classes.iconButton}
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        {children}
      </div>
    </FoodworksTooltip>
  );
};

const DeleteDatabase = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <IconButtonWithToolTip
      dataCy="deleteDatabaseButton"
      tooltip={"Delete"}
      onClick={onClick}
    >
      <Delete />
    </IconButtonWithToolTip>
  );
};

const DuplicateDatabase = ({
  databaseId,
}: {
  databaseId: string;
}): JSX.Element => {
  const dispatch = useDispatch();

  const onDuplicateDatabase = () => dispatch(duplicateDatabase(databaseId));
  return (
    <IconButtonWithToolTip tooltip="Create copy" onClick={onDuplicateDatabase}>
      <FileCopy />
    </IconButtonWithToolTip>
  );
};

const DatabaseName = ({ name }: { name: string }): JSX.Element => (
  <Typography data-cy="databaseName" variant="h4">
    {name}
  </Typography>
);

const getLocalDate = (date: string): string =>
  new Date(date).toLocaleDateString();

const getDateText = (date: string): string =>
  `Last modified on ${getLocalDate(date)}`;

const DatabaseDate = ({ date }: { date: string }): JSX.Element => {
  const classes = useStyles();

  return (
    <Typography
      data-cy="databaseDate"
      variant="subtitle2"
      className={classes.dateText}
    >
      {getDateText(date)}
    </Typography>
  );
};

interface HoveredDatabaseCardProps {
  summary: UserDatabaseSummary;
  onClickDelete: () => void;
  hovered: boolean;
}

const HoverCard = ({
  summary,
  onClickDelete,
  hovered,
}: HoveredDatabaseCardProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Card className={classes.hoverCard}>
      <div className={classes.textContainer}>
        <DatabaseName name={summary.name} />
        <DatabaseDate date={summary.date.lastModified} />
      </div>
      {hovered && (
        <div className={classes.onHoverButtonsContainer}>
          <DuplicateDatabase databaseId={summary.id} />
          <Divider className={classes.divider} />
          <DeleteDatabase onClick={onClickDelete} />
        </div>
      )}
    </Card>
  );
};

interface DatabaseListItemProps {
  summary: UserDatabaseSummary;
}

interface DatabaseListItemState {
  hovered: boolean;
  openDeleteDialog: boolean;
}

const DatabaseListItem: FunctionComponent<DatabaseListItemProps> = ({
  summary,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onChangeCurrentDatabase = useCallback(
    () => dispatch(handleRouteChange(getDatabaseRouteData(summary.id))),
    [dispatch, summary.id]
  );
  const [state, setState] = useState<DatabaseListItemState>({
    hovered: false,
    openDeleteDialog: false,
  });

  const handleDeleteClick = () =>
    setState((prevState) => {
      return { ...prevState, openDeleteDialog: true };
    });

  return (
    <div className={classes.root}>
      <Button
        data-cy="databaseButton"
        className={classes.button}
        onClick={onChangeCurrentDatabase}
        onMouseEnter={() =>
          setState((prevState) => {
            return { ...prevState, hovered: true };
          })
        }
        onMouseLeave={() =>
          setState((prevState) => {
            return { ...prevState, hovered: false };
          })
        }
      >
        <HoverCard
          hovered={state.hovered}
          summary={summary}
          onClickDelete={handleDeleteClick}
        />
      </Button>
      {state.openDeleteDialog && (
        <DeleteDatabaseDialog
          open
          databaseId={summary.id}
          databaseName={summary.name}
          onClose={() => setState({ ...state, openDeleteDialog: false })}
        />
      )}
    </div>
  );
};

export default DatabaseListItem;

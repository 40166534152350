import { ThunkAction } from "redux-thunk";

import { fetchDocument } from "../../../../data/Firebase/helpers/fetchDocument";
import { FoodId } from "../../../../data/models/documentProperties/foodId";
import { RootState } from "../../../reducers";
import { ExtraArguments } from "../../../store";
import { IActionsFetchComposition } from "../../actions/compositionCache";
import {
  allDocumentsFetched,
  changeDocumentLoadingState,
} from "../action-creators/currentDocument";
import {
  IActionsAllDocumentsFetched,
  IActionsSetDocumentLoading,
} from "../actions/currentDocument";
import { IActionsUpdateCalculationMethod } from "../actions/document";
import { fetchChildDocuments } from "./document";
import { Document } from "../../../../data/models/document";
import { updateCalculationMethod } from "../action-creators/document";
import { fetchComposition } from "../../action-creators/compositionCache";
import { CalculationMethod } from "../../../../constants/calculationMethod";

export const getDocument =
  (
    foodId: FoodId,
    isPublic: boolean,
    isMapped: boolean
  ): ThunkAction<
    Promise<Document>,
    RootState,
    ExtraArguments,
    | IActionsUpdateCalculationMethod
    | IActionsAllDocumentsFetched
    | IActionsSetDocumentLoading
    | IActionsFetchComposition
  > =>
  async (dispatch, getState, { firebase }) => {
    dispatch(changeDocumentLoadingState(true));
    const document: Document = await fetchDocument(firebase, foodId, isPublic);

    await dispatch(fetchChildDocuments(document, foodId));

    dispatch(fetchComposition(foodId.identifier, document));

    if (isMapped) {
      dispatch(
        updateCalculationMethod(CalculationMethod.MAPPED, foodId.identifier)
      );
    }

    dispatch(allDocumentsFetched());

    return document;
  };

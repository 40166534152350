import React from "react";
import { MOBILE_FIELD } from "../../../../../../../constants/textInputs";

import { FoodWorksTextInput } from "../../../../../../common/FoodWorksTextInput";
import ProfileItem from "../../profile/components/ProfileItem";
import useClientMobile from "../hooks/useClientMobile";

const MobileInput = React.memo(
  (): JSX.Element => {
    const [mobile, setMobile, updateMobile] = useClientMobile();

    return (
      <ProfileItem
        component={
          <FoodWorksTextInput
            maxLength={MOBILE_FIELD}
            value={mobile}
            onChange={(event) => setMobile(event.currentTarget.value)}
            onBlur={updateMobile}
          />
        }
        label="Mobile"
      />
    );
  }
);

export default MobileInput;

import { createSelector } from "reselect";

import {
  FoodItem,
  FoodItems,
  FoodItemState,
} from "../../../../data/models/documentProperties/foodItem";
import { RootState } from "../../../reducers";
import { getRetentionFactorMap } from "../../selectors/referenceData";
import { daysSelector } from "./days";
import { sectionStateSelector } from "./sections";

export const foodItemStateSelector = (
  rootState: RootState,
  dayIndex: number,
  sectionIndex: number,
  foodItemIndex: number
): FoodItemState | undefined => {
  const foodItems: FoodItemState[] = sectionStateSelector(
    rootState,
    dayIndex,
    sectionIndex
  ).foodItems;

  return foodItemIndex < foodItems.length
    ? foodItems[foodItemIndex]
    : undefined;
};

export const foodItemsStateSelector = (
  rootState: RootState,
  dayIndex: number,
  sectionIndex: number
): FoodItemState[] =>
  sectionStateSelector(rootState, dayIndex, sectionIndex).foodItems;

export const foodItemsSelector = () =>
  createSelector(
    foodItemsStateSelector,
    getRetentionFactorMap,
    (foodItemsState, retentionFactorMap) =>
      new FoodItems(foodItemsState, retentionFactorMap)
  );

export const foodItemSelector = () =>
  createSelector(
    foodItemStateSelector,
    getRetentionFactorMap,
    (foodItemState, retentionFactorMap) =>
      foodItemState
        ? FoodItem.fromObject(foodItemState, retentionFactorMap)
        : undefined
  );

export const allFoodItemsSelector = (state: RootState): FoodItem[] => {
  let foodItems: FoodItem[] = [];
  for (const day of daysSelector(state).days) {
    for (const section of day.sections.items) {
      foodItems = [...foodItems, ...section.foodItems.items];
    }
  }
  return foodItems;
};

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  Composition,
  CompositionCache,
} from "../../../../../../../data/models/composition";
import { getIdentifier } from "../../../../../../../data/models/documentProperties/foodId";
import { compositionCacheSelector } from "../../../../../../../store/data/selectors/compositionCache";
import { databaseIdSelector } from "../../../../../../../store/data/selectors/database";
import { RootState } from "../../../../../../../store/reducers";

export interface quantitiesData {
  angles: number[];
  labels: string[];
  colors: string[];
}

export interface quantitiesObject {
  PFCA: quantitiesData;
  PMS: quantitiesData;
}

const updateNutrientQuantities = (
  currentDocument: string,
  databaseId: string,
  compositionCache: CompositionCache
): quantitiesObject => {
  const composition: Composition | undefined = compositionCache.getComposition(
    getIdentifier(databaseId, currentDocument)
  );

  const totalPFCA: quantitiesData = { angles: [], labels: [], colors: [] };
  const totalPMS: quantitiesData = { angles: [], labels: [], colors: [] };
  const colors: string[] = [
    "#3FD79C",
    "#3B85EC",
    "#B2A6FF",
    "#FFBD59",
    "#00D5E0",
  ];
  const nutrientsPCFA = [
    {
      name: "Protein",
      id: "3",
    },
    {
      name: "Carbs",
      id: "5",
    },
    {
      name: "Fats",
      id: "4",
    },
    {
      name: "Alcohol",
      id: "6",
    },
    {
      name: "Fibre",
      id: "26",
    },
  ];

  if (!composition) {
    return {
      PFCA: totalPFCA,
      PMS: totalPMS,
    };
  }
  for (let index = 0; index < nutrientsPCFA.length; index++) {
    const nutrient = nutrientsPCFA[index];
    if (composition.getNutrientValue(nutrient.id) !== undefined) {
      totalPFCA["angles"].push(
        Number(composition.getNutrientValue(nutrient.id)!.toFixed(2))
      );
      totalPFCA["colors"].push(colors[index]);
      totalPFCA["labels"].push(nutrient.name);
    }
  }

  const nutrientsPMS = [
    {
      name: "Poly",
      id: "10",
    },
    {
      name: "Sat",
      id: "8",
    },
    {
      name: "Mono",
      id: "9",
    },
  ];

  for (let index = 0; index < nutrientsPMS.length; index++) {
    const nutrient = nutrientsPMS[index];
    if (composition.getNutrientValue(nutrient.id) !== undefined) {
    totalPMS["angles"].push(
      Number(composition.getNutrientValue(nutrient.id)!.toFixed(2))
    );
    totalPMS["colors"].push(colors[index]);
    totalPMS["labels"].push(nutrient.name);
  }
}

  return {
    PFCA: totalPFCA,
    PMS: totalPMS,
  };
};

const usePFCASources = (): [
  (id: string) => void,
  quantitiesData,
  quantitiesData
] => {
  const [currentDocument, setCurrentDocument] = useState("");
  const [PFCAQuantities, setPFCAQuantities] = useState<quantitiesData>({
    angles: [],
    labels: [],
    colors: [],
  });
  const [PMSQuantities, setPMSQuantities] = useState<quantitiesData>({
    angles: [],
    labels: [],
    colors: [],
  });

  const currentDatabaseId: string =
    useSelector<RootState, string>(databaseIdSelector);
  const compositionCache: CompositionCache = useSelector<
    RootState,
    CompositionCache
  >(compositionCacheSelector);

  const updateNutrientQuantitiesMemo = useMemo(
    () =>
      updateNutrientQuantities(
        currentDocument,
        currentDatabaseId,
        compositionCache
      ),
    [currentDocument, compositionCache, currentDatabaseId]
  );

  useEffect(() => {
    setPFCAQuantities(updateNutrientQuantitiesMemo["PFCA"]);
    setPMSQuantities(updateNutrientQuantitiesMemo["PMS"]);
  }, [currentDocument, updateNutrientQuantitiesMemo]);

  return [setCurrentDocument, PFCAQuantities, PMSQuantities];
};

export default usePFCASources;

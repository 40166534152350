import { ErrorActions, ADD_ERROR, RESET_ERROR } from "../actions/errorActions";
import { FoodWorksError } from "../../constants/errors";

export type ErrorState = FoodWorksError;

export const initialErrorState: ErrorState = {
  code: null,
  message: null,
  description: null,
};

export const currentError = (
  state: ErrorState = initialErrorState,
  action: ErrorActions
): ErrorState => {
  switch (action.type) {
    case ADD_ERROR:
      return action.error;
    case RESET_ERROR:
      return initialErrorState;
    default:
      return state;
  }
};

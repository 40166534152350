import { useSelector } from "react-redux";
import { ENERGY_ID } from "../../../../../../../constants/nutrientIds";

import { NRVType } from "../../../../../../../data/models/nutrientReferenceValues";
import { nutrientReferenceValuesSelector } from "../../../../../../../store/data/selectors/clientDatabase";
import { RootState } from "../../../../../../../store/reducers";

const useClientEER = (): string => {
  const nutrientReferenceValues: Map<
    string,
    Map<NRVType, number>
  > = useSelector<RootState, Map<string, Map<NRVType, number>>>(
    nutrientReferenceValuesSelector
  );

  return nutrientReferenceValues.get(ENERGY_ID)
    ? `${nutrientReferenceValues.get(ENERGY_ID)?.get("EERM")?.toFixed(0)} kJ`
    : "?";
};

export default useClientEER;

import React, { FunctionComponent, useCallback, useState } from "react";

import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { DeleteRowsDialog } from "../../../../../../../dialogs/DeleteRowsDialog";
import { FoodItemPosition } from "../../../../../../../../data/models/foodItemPosition";
import { FoodworksTooltip } from "../../../../../../../common/InfoTooltip";

interface DeleteButtonProps {
  onClick: () => void;
  disabled: boolean;
}

const DeleteButton = React.memo<DeleteButtonProps>(({ onClick, disabled }) => {
  return (
    <FoodworksTooltip title="Delete selection">
      <span>
        <IconButton
          size="small"
          data-cy="toolbarDelete"
          onClick={onClick}
          disabled={disabled}
        >
          <DeleteIcon />
        </IconButton>
      </span>
    </FoodworksTooltip>
  );
});

interface IngredientRowDeleteProps {
  dayIndex: number;
  rowsToRemove: FoodItemPosition[];
}

export const IngredientRowDelete: FunctionComponent<IngredientRowDeleteProps> = ({
  dayIndex,
  rowsToRemove,
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onClickDeleteButton = () => setShowDeleteDialog(true);

  const onDeleteRowsDialogClose = useCallback(
    () => setShowDeleteDialog(false),
    []
  );

  return (
    <>
      <DeleteButton
        onClick={onClickDeleteButton}
        disabled={!rowsToRemove.length}
      />
      <DeleteRowsDialog
        rowsToRemove={rowsToRemove}
        dayIndex={dayIndex}
        onClose={onDeleteRowsDialogClose}
        open={showDeleteDialog}
      />
    </>
  );
};

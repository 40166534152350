import React, { ReactNode, useState } from "react";
import { Button, Card, makeStyles, Theme } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { MeasurementCategory } from "../../../../../../data/models/clientProperties/measurement";
import { appTheme } from "../../../../../../styling/style";
import { BaseDialog } from "../../../../../dialogs/BaseDialog";
import MeasurementsDialogue from "../dialogs/MeasurementsDialogue";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 250,
    height: 130,
    margin: 25,
  },
  button: {
    width: "100%",
    height: "100%",
    color: appTheme.colors.primary,
  },
  card: {
    width: "100%",
    height: "100%",
    border: "none",
    boxShadow: "none",
  },
  addIcon: {
    fontSize: 50,
  },
}));

interface AddMeasurementCardProps {
  category: MeasurementCategory;
}

const AddMeasurementCard = (props: AddMeasurementCardProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  const dialogBody: ReactNode = (
    <MeasurementsDialogue category={props.category} />
  );

  return (
    <div className={classes.root}>
      <BaseDialog
        open={open}
        onClose={() => setOpen(false)}
        title={props.category + " measurements"}
        body={dialogBody}
        maxWidth={"md"}
      />
      <Card className={classes.card}>
        <Button
          onClick={() => setOpen(true)}
          className={classes.button}
          fullWidth
        >
          <Add className={classes.addIcon} fontSize={"inherit"} />
        </Button>
      </Card>
    </div>
  );
};

export default AddMeasurementCard;

export class FirebaseAuthentication {
  auth: firebase.auth.Auth;

  constructor(auth: firebase.auth.Auth) {
    this.auth = auth;
  }

  doCreateUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  doSignInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  doSignOut(): Promise<void> {
    return this.auth.signOut();
  }

  doPasswordReset(email: string): Promise<void> {
    return this.auth.sendPasswordResetEmail(email);
  }

  doPasswordUpdate(password: string): Promise<void> | undefined {
    return this.auth.currentUser?.updatePassword(password);
  }

  doSendEmailVerification(user: firebase.auth.UserCredential) {
    return this.auth.currentUser?.sendEmailVerification();
  }
}

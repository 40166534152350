import React, { ReactNode } from "react";
import { makeStyles, MenuItem, Select } from "@material-ui/core";

import { appTheme } from "../../styling/style";
import { FoodworksSelectProps } from "./types";

const useStyles = makeStyles(() => ({
  icon: {
    fill: appTheme.colors.primary,
  },
  root: {
    padding: "6px",
    "&:hover": {
      borderColor: appTheme.colors.primary,
    },
  },
}));

function FoodworksSelect<T>({
  value,
  onChange,
  options,
  className,
  readOnly,
  children
}: FoodworksSelectProps<T> ): JSX.Element {
  const classes = useStyles();

  return (
    <Select
      variant="outlined"
      className={className}
      value={value}
      onChange={(event) => onChange(event.target.value as T)}
      inputProps={{
        classes: {
          icon: classes.icon,
          root: classes.root,
        },
        readOnly: readOnly
      }}
    >
      {children ? children : 
      options!.map(
        (value: T): ReactNode => (
          <MenuItem key={`${value}`} value={`${value}`}>
            {value}
          </MenuItem>
        )
      )}
    </Select>
  );
}

export default FoodworksSelect;

import { createSelector } from "reselect";

import {
  RetentionFactorProfileMap,
  NutrientMap,
  CategoryMap,
  ReferenceMeasureMap,
  ReferenceDataState,
  initialReferenceDataState,
  DataSourceMap,
} from "../reducers/referenceData";
import { RootState } from "../../reducers";
import {
  RetentionFactor,
  RetentionFactorProfile,
} from "../../../data/models/documentProperties/retentionFactor";
import { Category } from "../../../data/models/category";
import {
  ReferenceMeasure,
  ReferenceMeasureObject,
} from "../../../data/models/referenceMeasure";
import { Nutrient } from "../../../data/models/nutrient";
import {
  enabledMeasuresSelector,
  enabledNutrientsSelector,
} from "./databaseProperties";
import { DataSource } from "../../../data/models/datasource";

export const referenceDataLoadedLogic = (
  referenceData: ReferenceDataState
): boolean => referenceData !== initialReferenceDataState;

export const isReferenceDataLoadedSelector = createSelector(
  (state: RootState) => state.referenceData,
  referenceDataLoadedLogic
);

export const CategoriesSelectorLogic = (
  categories: CategoryMap
): Map<string, Category> =>
  new Map(
    Object.entries(categories).sort(
      (a: [string, Category], b: [string, Category]) =>
        a[1].displayOrder - b[1].displayOrder
    )
  );

export const CategoriesSelector = createSelector(
  (state: RootState) => state.referenceData.categories,
  CategoriesSelectorLogic
);

export const NutrientsDataSelectorLogic = (
  nutrients: NutrientMap
): Map<string, Nutrient> => {
  const sorted = Object.entries(nutrients).sort(
    (a: [string, Nutrient], b: [string, Nutrient]) =>
      a[1].displayOrder - b[1].displayOrder
  );
  return new Map(sorted);
};

export const NutrientsDataSelector = createSelector(
  (state: RootState) => state.referenceData.nutrientData,
  NutrientsDataSelectorLogic
);

export const enabledNutrientsDataSelector = createSelector(
  NutrientsDataSelector,
  enabledNutrientsSelector,
  (nutrients, enabledNutrientIds) =>
    new Map<string, Nutrient>(
      [...nutrients].filter(([id, nutrient]: [string, Nutrient]): boolean =>
        enabledNutrientIds.includes(id)
      )
    )
);

export const enabledNutrientIdsMapSelector = createSelector(
  enabledNutrientsDataSelector,
  (enabledNutrientsData): Map<string, string[]> => {
    const map = new Map<string, string[]>();
    for (const [id, nutrients] of enabledNutrientsData) {
      const currentNutrientIds = map.get(nutrients.category);

      !currentNutrientIds
        ? map.set(nutrients.category, [id])
        : map.set(nutrients.category, [...currentNutrientIds, id]);
    }
    return map;
  }
);

export const ReferenceMeasuresSelectorLogic = (
  referenceMeasures: ReferenceMeasureMap
): ReferenceMeasure[] =>
  Object.entries(referenceMeasures).map(
    ([id, referenceMeasure]: [
      string,
      ReferenceMeasureObject
    ]): ReferenceMeasure =>
      new ReferenceMeasure(
        id,
        referenceMeasure.name,
        referenceMeasure.displayName,
        referenceMeasure.isVolume,
        referenceMeasure.isWeight,
        referenceMeasure.conversionFactor,
        referenceMeasure.note
      )
  );

export const ReferenceMeasuresSelector = createSelector(
  (state: RootState) => state.referenceData.referenceMeasures,
  ReferenceMeasuresSelectorLogic
);

export const enabledReferenceMeasuresDataSelector = createSelector(
  ReferenceMeasuresSelector,
  enabledMeasuresSelector,
  (referenceMeasures, enabledMeasureIds) =>
    referenceMeasures.filter((measure: ReferenceMeasure): boolean =>
      enabledMeasureIds.includes(measure.id)
    )
);

export const RetentionFactorGroupsSelector = (
  state: RootState
): [string, string][] =>
  Object.entries(
    state.referenceData.retentionFactorData.retentionFactorGroupMap
  );

export const RetentionFactorProfilesSelector = (
  state: RootState,
  groupId: string | null
): RetentionFactorProfile[] =>
  groupId
    ? state.referenceData.retentionFactorData.retentionFactorProfileMap[groupId]
    : [];

export const AllRetentionFactorProfilesSelector = (
  state: RootState
): RetentionFactorProfileMap =>
  state.referenceData.retentionFactorData.retentionFactorProfileMap;

export const getRetentionFactorMap = createSelector(
  AllRetentionFactorProfilesSelector,
  (profiles) => {
    const retentionFactorProfiles = new Map<string, RetentionFactor>();
    for (const [groupId, group] of Object.entries(profiles)) {
      for (const profile of group) {
        retentionFactorProfiles.set(profile.id, {
          profileId: profile.id,
          groupId: groupId,
          description: profile.description,
        });
      }
    }
    return retentionFactorProfiles;
  }
);

export const referenceDataSourceSelector = (state: RootState): DataSourceMap =>
  state.referenceData.dataSources;

export const getDataSourceMap = createSelector(
  referenceDataSourceSelector,
  (dataSources) => {
    const dataSourceMap = new Map<string, DataSource>();
    for (const [id, dataSource] of Object.entries(dataSources)) {
      dataSourceMap.set(id, dataSource);
    }
    return dataSourceMap;
  }
);

import React, { ReactNode } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@material-ui/core";

import { DialogOption } from "../common/FoodWorksDialog";
import { deleteSections } from "../../store/data/current-document/action-creators/sections";
import { templateIdSelector } from "../../store/data/current-document/selectors/document";
import { RootState } from "../../store/reducers";
import { appTheme } from "../../styling/style";
import { BaseDialog } from "./BaseDialog";
import { deleteMethodSection } from "../../store/data/current-document/action-creators/document";

interface SectionDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  dayIndex: number;
  sectionIndex: number;
  foodItemsLength: number;
}

export const SectionDeleteDialog = React.memo<SectionDeleteDialogProps>(
  ({ open, onClose, dayIndex, sectionIndex, foodItemsLength }) => {
    const dispatch = useDispatch();

    const documentTemplateId: string = useSelector<RootState, string>(
      templateIdSelector
    );

    const onDelete = () => {
      onClose();
      dispatch(deleteSections(dayIndex, [sectionIndex], documentTemplateId));
    };

    const dialogOptions: DialogOption[] = [
      {
        text: "Cancel",
        onClick: onClose,
        color: appTheme.colors.xiketic,
      },
      {
        text: `Delete`,
        onClick: onDelete,
        color: appTheme.colors.error,
      },
    ];

    const action: ReactNode = (
      <div>
        {dialogOptions.map((option: DialogOption) => (
          <Button
            data-cy="dialogButton"
            key={option.text}
            onClick={option.onClick}
            style={{ color: option.color }}
          >
            {option.text}
          </Button>
        ))}
      </div>
    );

    const body: ReactNode = (
      <Typography component="div" variant="body1" data-cy="dialogDescription">
        {`This section contains ${foodItemsLength} food item${foodItemsLength > 1 ? "s" : ""} which will also be deleted. Are you certain you want to continue with your deletion?`}
      </Typography>
    );

    return (
      <BaseDialog
        onClose={onClose}
        open={open}
        title=""
        body={body}
        action={action}
        maxWidth="md"
      />
    );
  }
);

interface MethodSectionDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  sectionIndex: number;
}

export const MethodSectionDeleteDialog = React.memo<MethodSectionDeleteDialogProps>(
  ({ open, onClose, sectionIndex }) => {
    const dispatch = useDispatch();

    const onDelete = () => {
      onClose();
      dispatch(deleteMethodSection(sectionIndex));
    };

    const dialogOptions: DialogOption[] = [
      {
        text: "Cancel",
        onClick: onClose,
        color: appTheme.colors.xiketic,
      },
      {
        text: `Delete`,
        onClick: onDelete,
        color: appTheme.colors.error,
      },
    ];

    const action: ReactNode = (
      <div>
        {dialogOptions.map((option: DialogOption) => (
          <Button
            data-cy="dialogButton"
            key={option.text}
            onClick={option.onClick}
            style={{ color: option.color }}
          >
            {option.text}
          </Button>
        ))}
      </div>
    );

    const body: ReactNode = (
      <Typography component="div" variant="body1" data-cy="dialogDescription">
        {`Deleting this section will also delete its content. Are you certain you want to continue with your deletion?`}
      </Typography>
    );

    return (
      <BaseDialog
        onClose={onClose}
        open={open}
        title="Confirm section deletion"
        body={body}
        action={action}
        maxWidth="md"
      ></BaseDialog>
    );
  }
);

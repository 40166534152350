import { Day } from "../../../data/models/documentProperties/day";

export const SET_DISABLE_SAVE_BUTTON = "SET_DISABLE_SAVE_BUTTON";
export const UNDO_DOCUMENT = "UNDO_DOCUMENT";
export const REDO_DOCUMENT = "REDO_DOCUMENT";
export const ADD_DOCUMENT_PAST = "ADD_DOCUMENT_PAST";
export const REMOVE_DUPLICATE_HISTORY = "REMOVE_DUPLICATE_HISTORY";

export interface IActionsSetDisableSaveButton {
  type: "SET_DISABLE_SAVE_BUTTON";
  disable: boolean;
}

export interface IActionsUndoDocument {
  type: "UNDO_DOCUMENT";
}

export interface IActionsRedoDocument {
  type: "REDO_DOCUMENT";
}

export interface IActionsAddDocumentPast {
  type: "ADD_DOCUMENT_PAST";
  currentAction: Day[];
  reset: boolean;
}
export interface IActionsRemoveDuplicateHistory {
  type: "REMOVE_DUPLICATE_HISTORY";
}

export type DocumentEditingActions =
  | IActionsSetDisableSaveButton
  | IActionsUndoDocument
  | IActionsRedoDocument
  | IActionsAddDocumentPast
  | IActionsRemoveDuplicateHistory;

import React, { useMemo } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Nutrient } from "../../../../../../data/models/nutrient";
import { FoodWorksTextInput } from "../../../../../common/FoodWorksTextInput";

const useStyles = makeStyles(() => ({
  dropDownContainer: {
    marginTop: 3,
    display: "flex",
    alignItems: "center",
  },
  dropdown : {
    width : "100%"
  },
  input :{
    width : "100%"
  },
  autoComplete : {
    width : "50%",
    padding: 5
  },
}));

interface MenuItemOption {
  name: string;
  value: string;
  onClick: () => any;
}

interface DropDownProps {
  handleChange: (id : string) => void;
  enabledNutrients: Map<string, Nutrient>;
  nutrientId : string;
}

export const Dropdown = (props: DropDownProps) => {
  const classes = useStyles();

  const {handleChange, nutrientId, enabledNutrients} = {...props}

  const menuItems = [...enabledNutrients].map(
    ([id, value]: [string, Nutrient]): MenuItemOption => ({
      name: value.name,
      value: id,
      onClick: () => handleChange(id),
    })
  );

  const currentSelection : MenuItemOption = useMemo(() => {
    return menuItems.find((item) => item.value === nutrientId)!
  }, [menuItems, nutrientId])

  return (
    <div className={classes.dropDownContainer}>
      <Typography variant="h3" >
        Based on
      </Typography>
      <Autocomplete
        id="source-selector"
        value={currentSelection}
        options={menuItems as MenuItemOption[]}
        getOptionLabel={(option) => option.name}
        className={classes.autoComplete}
        onChange={(_: any, newOption: any) => {
          if (newOption) {
            handleChange(newOption.value);
          }
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref} className={classes.dropdown}>
            <FoodWorksTextInput
              className={classes.dropdown}
              inputProps={params.inputProps}
            />
          </div>
        )}
        getOptionSelected={(option, value) => option.value === value.value}
      />
    </div>
  );
};

export default Dropdown;

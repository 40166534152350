import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  List,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import _ from "lodash";

import { MEDIUM_FIELD } from "../../../constants/textInputs";
import { Category } from "../../../data/models/category";
import { Nutrient } from "../../../data/models/nutrient";
import { enabledNutrientsSelector } from "../../../store/data/selectors/databaseProperties";
import {
  CategoriesSelector,
  NutrientsDataSelector,
} from "../../../store/data/selectors/referenceData";
import { updateEnabledNutrients } from "../../../store/data/thunks/databaseProperties";
import { RootState } from "../../../store/reducers";
import { appTheme } from "../../../styling/style";
import {
  CopyDatabaseProperty,
  CopyPropertiesButton,
} from "../../common/CopyPropertiesButton";
import { FoodWorksTextInput } from "../../common/FoodWorksTextInput";
import { OpenDialogButton } from "../../common/OpenDialogButton";
import { BaseDialog } from "../BaseDialog";
import { CreateProfileDialog } from "./CreateProfileDialog";

const useStyles = makeStyles((theme) => ({
  listButton: {
    borderRadius: 4,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
    padding: "0px",
    alignItems: "center",
  },
  selectedListButton: {
    borderRadius: 4,
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
    padding: "0px",
    alignItems: "center",
  },
  sectionContainer: {
    margin: "10px 0px",
  },
  components: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flex: 1,
    margin: "0px 10px",
  },
  componentRootContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    height: 600,
  },
  listContainer: {
    overflow: "auto",
    flex: "1",
  },
  openDialogRootContainer: {
    display: "block",
    flex: 1,
    margin: "10px 0px",
  },
}));

const nutrientReducer = (
  accumulator: Map<string, NutrientValues[]>,
  currentValue: [string, Nutrient],
  enabledNutrientIds: string[]
) => {
  const key = currentValue[1].category;

  const value: NutrientValues = {
    id: currentValue[0],
    displayName: currentValue[1].name,
    isOpen: enabledNutrientIds.includes(currentValue[0]),
  };

  const nutrientsArray = accumulator.get(key);
  if (nutrientsArray) {
    return accumulator.set(key, [...nutrientsArray, value]);
  } else {
    return accumulator.set(key, [value]);
  }
};

const getNutrientsMap = (
  enabledNutrientIds: string[],
  nutrientData: Map<string, Nutrient>
) =>
  [...nutrientData].reduce<Map<string, NutrientValues[]>>(
    (nutrienstMap, nutrient) =>
      nutrientReducer(nutrienstMap, nutrient, enabledNutrientIds),
    new Map()
  );

interface NutrientsDialogProps {
  open: boolean;
  onClose: () => void;
}

interface CategoryValues {
  displayName?: string;
  isOpen: boolean;
}

interface NutrientValues {
  id: string;
  displayName: string;
  isOpen: boolean;
}

const getUsedCategories = (nutrientData: Map<string, Nutrient>): string[] =>
  _.uniq(
    [...nutrientData.values()].map(
      (nutrient: Nutrient): string => nutrient.category
    )
  );

export const NutrientsDialog: FunctionComponent<NutrientsDialogProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUpdateDisplayedNutrients = (nutrientsIds: string[]) =>
    dispatch(updateEnabledNutrients(nutrientsIds));

  const categoryData: Map<string, Category> =
    useSelector<RootState, Map<string, Category>>(CategoriesSelector);

  const nutrientData: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(NutrientsDataSelector);

  const enabledNutrientIds: string[] = useSelector<RootState, string[]>(
    enabledNutrientsSelector
  );

  const [categoriesMap, setCategoriesMap] = useState<
    Map<string, CategoryValues>
  >(new Map());
  const [nutrientsMap, setNutrientsMap] = useState<
    Map<string, NutrientValues[]>
  >(new Map());
  const [lastSelectedCategoryId, setLastSelectedCategoryId] =
    useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedNutrientIds, setSelectedNutrientIds] = useState<string[]>([]);
  const [nutrientProfileButton, setNutrientProfileButton] =
    useState<boolean>(false);

  const filteredNutrients = (
    searchTerm: string,
    categoryId: string
  ): string[] => {
    const filteredNutrientIds: string[] = [];
    const shownNutrients: NutrientValues[] | undefined =
      nutrientsMap.get(categoryId);

    if (shownNutrients !== undefined) {
      for (const nutrient of shownNutrients)
        if (
          nutrient.displayName.toLowerCase().includes(searchTerm.toLowerCase())
        )
          filteredNutrientIds.push(nutrient.id);
    }
    return filteredNutrientIds || [];
  };

  const applyFilter = (searchTerm: string, categoryId: string) => {
    const filtered: string[] = filteredNutrients(searchTerm, categoryId);
    setSelectedNutrientIds(filtered);
  };

  const loadCategories = useCallback(() => {
    const newCategoryMap = new Map<string, CategoryValues>();

    for (const category of [...categoryData].filter(
      ([id]: [string, Category]) => getUsedCategories(nutrientData).includes(id)
    )) {
      const key = category[0];
      const value = {
        displayName: category[1].name,
        isOpen: false,
      };

      newCategoryMap.set(key, value);
    }
    setCategoriesMap(newCategoryMap);
  }, [categoryData, nutrientData]);

  useEffect(loadCategories, [loadCategories]);

  const loadNutrients = useCallback(
    (enabledNutrientIds: string[]) => {
      const newNutrientsMap: Map<string, NutrientValues[]> = getNutrientsMap(
        enabledNutrientIds,
        nutrientData
      );
      setNutrientsMap(newNutrientsMap);
    },
    [nutrientData]
  );

  useEffect(() => {
    loadNutrients(enabledNutrientIds);
  }, [nutrientData, loadNutrients, enabledNutrientIds]);

  const handleCategoryOnClick = (categoryId: string) => {
    const oldValue = categoriesMap.get(lastSelectedCategoryId);
    const value = categoriesMap.get(categoryId);
    if (lastSelectedCategoryId === categoryId) {
      setCategoriesMap(
        new Map(
          categoriesMap.set(categoryId, { ...value, isOpen: !value?.isOpen })
        )
      );
      setLastSelectedCategoryId("");
    } else {
      const newMap = new Map(
        categoriesMap.set(lastSelectedCategoryId, {
          ...oldValue,
          isOpen: false,
        })
      );
      newMap.set(categoryId, { ...value, isOpen: true });
      setCategoriesMap(newMap);
      setLastSelectedCategoryId(categoryId);
    }
    loadNutrients(enabledNutrientIds);
    applyFilter(searchTerm, categoryId);
  };

  const handleNutrientOnClick = (categoryId: string, nutrientId: string) => {
    const arrayOfNutrients: NutrientValues[] | undefined =
      nutrientsMap.get(categoryId);
    if (!arrayOfNutrients) return;

    const newArrayOfNutrients = arrayOfNutrients.map(
      (nutrient: NutrientValues) =>
        nutrient.id === nutrientId
          ? { ...nutrient, isOpen: !nutrient.isOpen }
          : nutrient
    );
    setNutrientsMap(new Map(nutrientsMap.set(categoryId, newArrayOfNutrients)));
  };

  const nutrientItem = (
    categoryId: string,
    arrayOfNutrients: NutrientValues[]
  ): ReactNode => {
    if (categoryId === lastSelectedCategoryId)
      return arrayOfNutrients.map((nutrient: NutrientValues) => (
        <>
          {(!searchTerm || selectedNutrientIds.includes(nutrient.id)) && (
            <MenuItem
              key={nutrient.id}
              button
              onClick={() => handleNutrientOnClick(categoryId, nutrient.id)}
              className={
                nutrient.isOpen
                  ? classes.selectedListButton
                  : classes.listButton
              }
            >
              <Checkbox
                data-cy={
                  nutrient.isOpen
                    ? "nutrientCheckboxTrue"
                    : "nutrientCheckboxFalse"
                }
                name={nutrient.id}
                checked={nutrient.isOpen}
              />
              <Typography>{nutrient.displayName}</Typography>
            </MenuItem>
          )}
        </>
      ));
  };

  const resetLocalState = () => {
    setSearchTerm("");
    setSelectedNutrientIds([]);
  };

  const handleOnChangeSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    applyFilter(searchTerm, lastSelectedCategoryId);
  };

  const getOpenIds = (arrayOfNutrients: NutrientValues[]): string[] =>
    arrayOfNutrients
      .filter((nutrient: NutrientValues) => nutrient.isOpen)
      .map((nutrient: NutrientValues) => nutrient.id);

  const currentNutrientIdsHaveChanged = (): boolean => {
    let currentEnabledIds: string[] = [];
    for (const arrayOfNutrients of nutrientsMap.values()) {
      const openIds: string[] = getOpenIds(arrayOfNutrients);
      currentEnabledIds = currentEnabledIds.concat(openIds);
    }

    if (currentEnabledIds.length !== enabledNutrientIds.length) return false;

    const sortedCurrentIds: string[] = [...currentEnabledIds.sort()];
    const sortedEnabledIds: string[] = [...enabledNutrientIds.sort()];

    return !sortedCurrentIds.some(
      (nutrientId, i) => nutrientId !== sortedEnabledIds[i]
    );
  };

  const applyChanges = () => {
    const nutrientsIds: string[] = [];
    for (const arrayOfNutrients of nutrientsMap.values()) {
      for (const nutrient of arrayOfNutrients) {
        if (nutrient.isOpen) nutrientsIds.push(nutrient.id);
      }
    }
    onUpdateDisplayedNutrients(nutrientsIds);
  };

  const handleClose = () => {
    loadNutrients(enabledNutrientIds);
    resetLocalState();
    onClose();
  };

  const categoryItems: ReactNode = [...categoriesMap].map(
    ([categoryId, category]: [string, CategoryValues]) => (
      <MenuItem
        className={
          category.isOpen ? classes.selectedListButton : classes.listButton
        }
        key={categoryId}
        button
        onClick={() => handleCategoryOnClick(categoryId)}
      >
        <Typography
          className={
            category.isOpen ? classes.selectedListButton : classes.listButton
          }
          data-cy="categoryItem"
        >
          {category.displayName}
        </Typography>
      </MenuItem>
    )
  );

  const categoryComponent: ReactNode = [
    <Typography
      key="nutrientsDialog-category"
      className={classes.sectionContainer}
    >
      Categories
    </Typography>,
    <Card
      key="nutrientsDialog-categoryList"
      data-cy="categoryList"
      className={classes.listContainer}
    >
      <List>{categoryItems}</List>
    </Card>,
  ];

  const searchBar: ReactNode = (
    <FoodWorksTextInput
      maxLength={MEDIUM_FIELD}
      fullWidth
      data-cy="nutrientFilterInput"
      onChange={(event) => handleOnChangeSearch(event.target.value)}
      value={searchTerm}
      placeholder="Search nutrients here..."
    />
  );

  const nutrientItems: ReactNode = [...nutrientsMap].map(
    ([categoryId, arrayOfNutrients]: [string, NutrientValues[]]) => {
      return nutrientItem(categoryId, arrayOfNutrients);
    }
  );

  const nutrientComponent: ReactNode = [
    <Typography
      key="nutrientsDialog"
      className={classes.sectionContainer}
    >{`Nutrients and components`}</Typography>,
    <div key="nutrientsDialogSearchBar">{searchBar}</div>,
    <Card key="nutrientComponentCard" className={classes.listContainer}>
      <List key="nutrientComponentList">{nutrientItems}</List>
    </Card>,
  ];

  const nutrientsProfileButton: ReactNode = [
    <OpenDialogButton
      key="nutrientsDialog-openDialogButton"
      label="Create nutrient profiles"
      data-cy="nutrientsProfileButton"
      onClick={() => setNutrientProfileButton(true)}
    />,
    <CreateProfileDialog
      key="nutrientsDialog-createProfileDialog"
      open={nutrientProfileButton}
      onClose={() => setNutrientProfileButton(false)}
    />,
  ];

  const body: ReactNode = [
    <div
      key="nutrientsDialog-rootContainer"
      className={classes.componentRootContainer}
    >
      <div key="categoryComponent" className={classes.components}>
        <Typography>Search for and enable nutrients and components for your database.</Typography>
        <br/>
        {categoryComponent}
      </div>
      <div key="nutrientComponent" className={classes.components}>
        <br/>
        <br/>
        {nutrientComponent}
      </div>
    </div>,

    <div
      key="nutrientsDialog-openDialogRootContainer"
      className={classes.openDialogRootContainer}
      data-cy="nutrientsDialogBody"
    >
      <div>
        <CopyPropertiesButton
          databaseProperty={CopyDatabaseProperty.ENABLED_NUTRIENTS}
          fetchDatabaseProperties={(ids: string[]) => loadNutrients(ids)}
        />
      </div>
      <div>{nutrientsProfileButton}</div>
    </div>,
  ];

  const action: ReactNode = [
    <Button
      key="nutrientsDialogCancel"
      data-cy="nutrientCancel"
      onClick={handleClose}
    >
      Cancel
    </Button>,
    <Button
      key="nutrientsDialogApply"
      disabled={currentNutrientIdsHaveChanged()}
      data-cy="nutrientApplyChanges"
      onClick={applyChanges}
    >
      Apply
    </Button>,
  ];

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title={`Nutrients and components`}
      body={body}
      action={action}
      maxWidth="md"
      dataCy="nutrientsDialog"
    />
  );
};

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import { FoodId } from "../../../../data/models/documentProperties/foodId";
import { TEMPORARY_DOCUMENT } from "../../../../store/data/current-document/reducers/currentDocument";
import { CurrentDocumentIdSelector } from "../../../../store/data/current-document/selectors/currentDocument";
import { getDocument } from "../../../../store/data/current-document/thunks/getDocument";
import { databaseIdSelector } from "../../../../store/data/selectors/database";
import { RootState } from "../../../../store/reducers";
import { useReduxDispatch } from "../../../../store/store";

const usePinnedDocument = (): [string, (documentId: string) => void] => {
  const dispatch = useReduxDispatch();

  const currentDatabaseId: string =
    useSelector<RootState, string>(databaseIdSelector);

  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const [lockedDocumentId, setLockedDocumentId] = useState("");

  useEffect(() => {
    if (!lockedDocumentId || lockedDocumentId === TEMPORARY_DOCUMENT)
      setLockedDocumentId(currentDocumentId);
  }, [currentDocumentId, lockedDocumentId]);

  const onLockDocumentInExplorer = useCallback(
    async (documentId: string) => {
      dispatch(
        getDocument(
          new FoodId({
            datasourceId: currentDatabaseId,
            documentId: documentId,
          }),
          false,
          false
        )
      ).then(() => setLockedDocumentId(documentId));
    },
    [currentDatabaseId, dispatch]
  );

  return [lockedDocumentId, onLockDocumentInExplorer];
};

export default usePinnedDocument;

import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from "react";
import {
  Button,
  ButtonProps,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";
import InformationIcon from "@material-ui/icons/InfoOutlined";

import { appTheme } from "../../styling/style";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  infoIcon: {
    color: appTheme.colors.xiketic,
    alignSelf: "center",
    height: 12,
    width: 12,
    marginRight: 4,
    "&:hover": {
      color: appTheme.colors.help,
    },
  },
}));

export const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: appTheme.colors.gainsbruh,
    color: appTheme.colors.xiketic,
    maxWidth: 300,
    fontSize: appTheme.fontSize.textInput,
    border: `1px solid ${appTheme.colors.gainsbruh}`,
  },
  tooltipPlacementTop: {
    margin: 0,
  },
  tooltipPlacementBottom: {
    margin: 0,
  },
}))(Tooltip);

type TooltipPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top"
  | undefined;

interface FoodworksToolTipProps {
  title: string;
  placement?: TooltipPlacement;
  children: ReactElement;
  className?: string;
  interactive?: boolean;
  enterDelay?: number;
}

export const FoodworksTooltip = React.memo<FoodworksToolTipProps>(
  ({
    title,
    placement = "bottom",
    children,
    className = "",
    interactive,
    enterDelay,
  }) => {
    return (
      <StyledTooltip
        title={title && <Typography variant="caption">{title}</Typography>}
        placement={placement}
        className={className}
        enterDelay={enterDelay}
        interactive={interactive}
      >
        {children}
      </StyledTooltip>
    );
  }
);

interface TextOverflowTooltipProps {
  label: string;
  children: ReactNode;
  placement?: TooltipPlacement;
  className?: string;
}

export const TextOverflowTooltip = React.memo<TextOverflowTooltipProps>(
  (props): JSX.Element => {
    const { children, className, label, placement } = { ...props };

    const ref = useRef<HTMLSpanElement>(null);

    const [overflowed, setOverFlowed] = useState(false);

    useEffect(() => {
      !!ref.current && ref.current.scrollWidth > ref.current.offsetWidth
        ? setOverFlowed(true)
        : setOverFlowed(false);
    }, [ref]);

    const element = React.isValidElement(children) ? (
      React.cloneElement(children, { ref: ref })
    ) : (
      <></>
    );

    return overflowed ? (
      <StyledTooltip
        placement={placement}
        className={className}
        title={label && <Typography variant="caption">{label}</Typography>}
      >
        {element}
      </StyledTooltip>
    ) : (
      element
    );
  }
);

interface InfoTooltipProps {
  title: string;
  icon?: ReactElement;
}

export const InfoTooltip: FunctionComponent<InfoTooltipProps> = ({
  title,
  icon,
}) => {
  const classes = useStyles();

  if (!title) return <span className={classes.infoIcon} />;

  return (
    <FoodworksTooltip className={classes.infoIcon} title={title}>
      {icon ? icon : <InformationIcon />}
    </FoodworksTooltip>
  );
};

interface DisabledButtonWithToolTipProps {
  disabled: boolean;
  label: ReactNode;
  disabledTooltip: string;
  enabledTooltip?: string;
  buttonProps: ButtonProps;
  dataCy?: string;
}

export const DisabledButtonWithToolTip = (
  props: DisabledButtonWithToolTipProps
) => {
  const {
    disabled,
    label,
    disabledTooltip,
    enabledTooltip,
    buttonProps,
    dataCy,
  } = { ...props };

  const StyledButton = withStyles({
    root: {
      "&.Mui-disabled": {
        pointerEvents: "auto",
      },
    },
  })(MuiButton);

  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : buttonProps.onClick,
  };

  return disabled ? (
    <FoodworksTooltip title={disabledTooltip}>
      <StyledButton data-cy={dataCy} {...buttonProps} {...adjustedButtonProps}>
        {label}
      </StyledButton>
    </FoodworksTooltip>
  ) : (
    <FoodworksTooltip title={enabledTooltip || ""}>
      <Button data-cy={dataCy} {...adjustedButtonProps} {...buttonProps}>
        {label}
      </Button>
    </FoodworksTooltip>
  );
};

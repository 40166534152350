import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, makeStyles, Typography } from "@material-ui/core";

import { Tag } from "../../../../data/models/documentProperties/section";
import { setClientTags } from "../../../../store/data/current_client/action_creators/client";
import { clientTagSelector } from "../../../../store/data/current_client/selectors/client";
import {
  clientDatabaseTagsSelector,
  currentClientIdSelector,
} from "../../../../store/data/selectors/clientDatabase";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import { FoodworksTooltip } from "../../../common/InfoTooltip";
import { ClientDocumentTags } from "./ClientTags";
import { ReactComponent as AddTagIcon } from "../../../../images/icons/Add_Tag.svg";
import { TagMenu } from "../common/TagMenu";
import { CreateClientTagDialog } from "./CreateClientTagDialog";

const useStyles = makeStyles(() => ({
  inputRow: {
    display: "flex",
    alignItems: "center",
  },
  titles: {
    width: "12ch",
    textAlign: "end",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 10,
    marginRight: 10,
    height: 40,
    width: "100%",
  },
  documentTagSection: {
    backgroundColor: appTheme.colors.white[10],
    flex: 1,
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 4,
    alignItems: "center",
    display: "flex",
    height: 40,
    justifyContent: "space-between",
  },
  leftButtonGroup: {
    display: "flex",
    alignItems: "center",
  },
  invisible: {
    visibility: "hidden",
  },
  visible: {
    visibility: "visible",
  },
}));

export const ClientTagMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateTags = (tagIds: string[]) => dispatch(setClientTags(tagIds));

  const clientDatabaseTags = useSelector<RootState, Tag[]>(
    clientDatabaseTagsSelector
  );
  const clientTagIds = useSelector<RootState, string[]>(clientTagSelector);
  const clientId = useSelector<RootState, string>(currentClientIdSelector);

  const [tagMenuAnchorEl, setTagMenuAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [openDocumentDialog, setOpenDocumentDialog] = useState<boolean>(false);
  const [rowHovered, setRowHovered] = useState<boolean>(false);

  const onAddTag = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setTagMenuAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.inputRow}>
      <Typography className={classes.titles} variant="h4">
        Client tags
      </Typography>
      <div
        className={classes.sectionHeader}
        onMouseEnter={() => setRowHovered(true)}
        onMouseLeave={() => setRowHovered(false)}
      >
        <div className={classes.documentTagSection}>
          <div className={classes.leftButtonGroup}>
            <ClientDocumentTags clientId={clientId} />
          </div>
          <div>
            {[
              <FoodworksTooltip
                title="Add client tag"
                key="clientTagMenu-tooltip"
                className={rowHovered ? classes.visible : classes.invisible}
              >
                <IconButton data-cy="addDocTag" size="small" onClick={onAddTag}>
                  <AddTagIcon />
                </IconButton>
              </FoodworksTooltip>,
              <TagMenu
                key="clientTagMenu"
                title="client"
                anchorElement={tagMenuAnchorEl}
                onClose={() => setTagMenuAnchorEl(null)}
                onClickCreateTag={() => setOpenDocumentDialog(true)}
                tags={clientDatabaseTags}
                tagIds={clientTagIds}
                dispatchSetId={(tagIds: string[]) => updateTags(tagIds)}
              />,
              <CreateClientTagDialog
                key="clientTagMenu-createClientTagDialog"
                open={openDocumentDialog}
                onClose={() => setOpenDocumentDialog(false)}
                isEditing={false}
              />,
            ]}
          </div>
        </div>
      </div>
    </div>
  );
};

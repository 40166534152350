import {
  ClientScreenActions,
  SET_SELECTED_CLIENT_DOCUMENTS,
  SET_USE_RMR,
} from "../actions/clientScreen";

export interface ClientScreenState {
  useRMR: boolean;
  selectedDocuments: string[];
}

export const initialClientScreenState: ClientScreenState = {
  useRMR: false,
  selectedDocuments: [],
};

export const clientScreen = (
  state: ClientScreenState = initialClientScreenState,
  action: ClientScreenActions
): ClientScreenState => {
  switch (action.type) {
    case SET_USE_RMR:
      return { ...state, useRMR: action.useRMR };
    case SET_SELECTED_CLIENT_DOCUMENTS:
      return { ...state, selectedDocuments: action.documents };
    default:
      return state;
  }
};

import React, { ReactNode, useState } from "react";

import {
  Button,
  Card,
  Checkbox,
  List,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import { withFirebase } from "../../../data/Firebase";
import { FirebaseProps } from "../../../data/Firebase/firebase";
import {
  DocumentSummary,
  UserDatabaseSummary,
} from "../../../data/models/userDatabase";
import { sortedUserDatabaseSummariesSelector } from "../../../store/data/selectors/user";
import { RootState } from "../../../store/reducers";
import { appTheme } from "../../../styling/style";
import { BaseDialog } from "../BaseDialog";
import { useDocumentSummaries } from "./hooks/importDocumentsDialog";
import { FoodWorksTextInput } from "../../common/FoodWorksTextInput";
import importDocuments from "../../../store/data/thunks/database/importDocuments";
import { getIdentifier } from "../../../data/models/documentProperties/foodId";
import { useReduxDispatch } from "../../../store/store";
import { databaseIdSelector } from "../../../store/data/selectors/database";
import { clientUserDatabaseSelector } from "../../../store/data/selectors/clientDatabase";
import { MEDIUM_FIELD } from "../../../constants/textInputs";

const useStyles = makeStyles(() => ({
  dialogRoot: {},
  list: {
    height: 300,
    overflow: "auto",
    paddingTop: 0,
  },
  card: {
    marginTop: 5,
  },
  documentListTitle: {
    marginTop: 10,
  },
  selectedListItem: {
    borderRadius: 4,
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
    display: "flex",
    alignItems: "center",
  },
  unSelectedListItem: {
    borderRadius: 4,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
  },
}));

interface DialogAction {
  onClick: () => void;
  label: string;
  color?: "default" | "inherit" | "primary" | "secondary";
  disabled?: boolean;
  cypressTag?: string;
}

interface ImportDocumentDialogProps {
  onClose: () => void;
}

interface ListItemProps {
  onClick: () => void;
  label: string;
  useCheckbox: boolean;
  cypressTag?: string;
}

interface DocumentListProps {
  onSelectItem: (ids: string[]) => void;
  selectedIds: string[];
  documentSummaries: DocumentSummary[];
  selectedDatabaseId: string;
}

interface DatabaseListProps {
  onSelectItem: (id: string) => void;
  selectedId: string;
  databaseDataLoaded: boolean;
}

const getDialogActions = (actions: DialogAction[]): ReactNode =>
  actions.map(
    (action: DialogAction): ReactNode => (
      <Button
        key={`${action.label}-button`}
        disabled={action.disabled}
        onClick={action.onClick}
        color={action.color || "default"}
        data-cy={action.cypressTag}
      >
        {action.label}
      </Button>
    )
  );

const getUpdatedSelectedIds = (
  selectedId: string,
  previousIds: string[],
  selectedDatabaseId: string
): string[] =>
  previousIds.includes(`${selectedDatabaseId}:${selectedId}`)
    ? previousIds.filter(
        (id: string) => id !== `${selectedDatabaseId}:${selectedId}`
      )
    : [...previousIds, `${selectedDatabaseId}:${selectedId}`];

const getDocumentListItems = (
  summaries: DocumentSummary[],
  selectedIds: string[],
  onSelectItem: (ids: string[]) => void,
  selectedDatabaseId: string
): ReactNode =>
  summaries.map((summary) =>
    selectedIds.includes(`${selectedDatabaseId}:${summary.documentId}`) ? (
      <SelectedListItem
        key={`${summary.documentId}-selected`}
        onClick={() =>
          onSelectItem(
            getUpdatedSelectedIds(
              summary.documentId,
              selectedIds,
              selectedDatabaseId
            )
          )
        }
        label={summary.label}
        useCheckbox
        cypressTag="documentItem"
      />
    ) : (
      <UnselectedListItem
        key={`${summary.documentId}-unselected`}
        onClick={() =>
          onSelectItem(
            getUpdatedSelectedIds(
              summary.documentId,
              selectedIds,
              selectedDatabaseId
            )
          )
        }
        label={summary.label}
        useCheckbox
        cypressTag="documentItem"
      />
    )
  );

const getDatabaseListItems = (
  summaries: UserDatabaseSummary[],
  selectedId: string,
  onSelectItem: (id: string) => void
): ReactNode =>
  summaries.map((summary) =>
    summary.id === selectedId ? (
      <SelectedListItem
        key={`${summary.id}-selected`}
        label={summary.name}
        onClick={() => onSelectItem(summary.id)}
        useCheckbox={false}
        cypressTag="databaseItem"
      />
    ) : (
      <UnselectedListItem
        key={`${summary.id}-unselected`}
        label={summary.name}
        onClick={() => onSelectItem(summary.id)}
        useCheckbox={false}
        cypressTag="databaseItem"
      />
    )
  );

const UnselectedListItem = ({
  onClick,
  label,
  useCheckbox,
  cypressTag,
}: ListItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <MenuItem
      data-cy={cypressTag}
      className={classes.unSelectedListItem}
      style={{
        paddingTop: useCheckbox ? 0 : 6,
        paddingBottom: useCheckbox ? 0 : 6,
        paddingLeft: useCheckbox ? 0 : 12,
      }}
      onClick={onClick}
    >
      {useCheckbox && (
        <Checkbox
          data-cy={`${cypressTag}Checkbox`}
          size="small"
          color="secondary"
          checked={false}
        />
      )}
      {label}
    </MenuItem>
  );
};

const SelectedListItem = ({
  onClick,
  label,
  useCheckbox,
  cypressTag,
}: ListItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <MenuItem
      data-cy={cypressTag}
      className={classes.selectedListItem}
      style={{
        paddingTop: useCheckbox ? 0 : 6,
        paddingBottom: useCheckbox ? 0 : 6,
        paddingLeft: useCheckbox ? 0 : 12,
      }}
      onClick={onClick}
    >
      {useCheckbox && (
        <Checkbox
          data-cy={`${cypressTag}Checkbox`}
          size="small"
          color="secondary"
          checked
        />
      )}
      {label}
    </MenuItem>
  );
};

const getDocumentIds = (
  databaseId: string,
  documentSummaries: DocumentSummary[]
): string[] =>
  documentSummaries.map((summary) =>
    getIdentifier(databaseId, summary.documentId)
  );

const getFilteredDocumentSummaries = (
  documentSummaries: DocumentSummary[],
  text: string
): DocumentSummary[] =>
  documentSummaries.filter((summary: DocumentSummary) =>
    summary.label.toLowerCase().includes(text.toLowerCase())
  );

const DocumentList = ({
  onSelectItem,
  selectedIds,
  documentSummaries,
  selectedDatabaseId,
}: DocumentListProps): JSX.Element => {
  const classes = useStyles();

  const [searchInput, setSearchInput] = useState("");

  return (
    <>
      <Typography className={classes.documentListTitle}>
        Select the documents to import
      </Typography>
      <Card className={classes.card}>
        <List className={classes.list}>
          <FoodWorksTextInput
            maxLength={MEDIUM_FIELD}
            placeholder="Search documents..."
            value={searchInput}
            onChange={(event) => setSearchInput(event.currentTarget.value)}
          />
          {getDocumentListItems(
            getFilteredDocumentSummaries(documentSummaries, searchInput),
            selectedIds,
            onSelectItem,
            selectedDatabaseId
          )}
        </List>
      </Card>
      <div>
        {getDialogActions([
          {
            onClick: () =>
              onSelectItem(
                _.without(
                  selectedIds,
                  ...getDocumentIds(
                    selectedDatabaseId,
                    getFilteredDocumentSummaries(documentSummaries, searchInput)
                  )
                )
              ),
            label: "Deselect all",
            cypressTag: "importDocumentsDeselectAll",
          },
          {
            onClick: () =>
              onSelectItem(
                _.union(
                  selectedIds,
                  getDocumentIds(
                    selectedDatabaseId,
                    getFilteredDocumentSummaries(documentSummaries, searchInput)
                  )
                )
              ),
            label: "Select all",
            cypressTag: "importDocumentsSelectAll",
          },
        ])}
      </div>
    </>
  );
};

const DatabaseList = ({
  onSelectItem,
  selectedId,
  databaseDataLoaded,
}: DatabaseListProps): JSX.Element => {
  const classes = useStyles();

  const databaseSummaries: UserDatabaseSummary[] = useSelector<
    RootState,
    UserDatabaseSummary[]
  >(sortedUserDatabaseSummariesSelector);
  const clientDatabaseId = useSelector<RootState, string>(
    clientUserDatabaseSelector
  );

  const currentDatabaseId = useSelector<RootState, string>(databaseIdSelector);

  return (
    <>
      <Typography>Select the database to import from</Typography>
      <Card className={classes.card}>
        <List className={classes.list}>
          {getDatabaseListItems(
            databaseDataLoaded
              ? databaseSummaries.filter(
                  (summary: UserDatabaseSummary) =>
                    summary.id !== currentDatabaseId &&
                    summary.id !== clientDatabaseId
                )
              : [],
            selectedId,
            onSelectItem
          )}
        </List>
      </Card>
    </>
  );
};

const ImportDocumentsDialogInner = ({
  onClose,
  firebase,
}: ImportDocumentDialogProps & FirebaseProps): JSX.Element => {
  const dispatch = useReduxDispatch();

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  const [
    documentSummaries,
    selectedDatabase,
    setSelectedDatabase,
    databaseDataLoaded,
  ] = useDocumentSummaries(firebase!);

  return (
    <BaseDialog
      title=""
      open
      onClose={onClose}
      body={
        <div>
          <DatabaseList
            onSelectItem={setSelectedDatabase}
            selectedId={selectedDatabase}
            databaseDataLoaded={databaseDataLoaded}
          />
          <DocumentList
            documentSummaries={documentSummaries}
            selectedIds={selectedDocuments}
            selectedDatabaseId={selectedDatabase}
            onSelectItem={(ids: string[]) => setSelectedDocuments(ids)}
          />
        </div>
      }
      maxWidth="sm"
      action={getDialogActions([
        { label: "Cancel", onClick: onClose },
        {
          label: "Import",
          onClick: () =>
            dispatch(importDocuments(selectedDocuments)).then(() => onClose()),
          color: "secondary",
          cypressTag: "importDialogAction",
        },
      ])}
      dataCy="importDocumentsDialog"
    />
  );
};

const ImportDocumentsDialog = withFirebase(ImportDocumentsDialogInner);

export default ImportDocumentsDialog;

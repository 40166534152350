import { ClipboardType } from "../reducers/clipboardReducers";
import { IActionsSetClipboard } from "../actions/clipboardActions";

export const setClipboard = (
  clipboardData: ClipboardType
): IActionsSetClipboard => {
  return {
    type: "SET_CLIPBOARD",
    clipboardData: clipboardData,
  };
};

import { InputBase, withStyles, createStyles, Theme } from "@material-ui/core";
import { appTheme } from "../../styling/style";

export const GridCellInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    input: {
      position: "relative",
      backgroundColor: "transparent",
      margin: 1,
      fontSize: appTheme.fontSize.textInput,
      fontFamily: appTheme.fontFamily.textInput,
      padding: "6px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
  })
)(InputBase);

import React, { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  makeStyles,
  createStyles,
  Paper,
  Checkbox,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import {
  IngredientCell,
  IngredientSummaryItem,
  INGREDIENT_CELL_MAX_WIDTH,
} from "./cells/IngredientCell";
import { QuantityCell } from "./cells/QuantityCell";
import { NoteCell } from "./cells/NoteCell";
import { appTheme } from "../../../../../../../../styling/style";
import { FoodItem } from "../../../../../../../../data/models/documentProperties/foodItem";
import { Document } from "../../../../../../../../data/models/document";
import { cachedDocumentSelector } from "../../../../../../../../store/data/selectors/documentCache";
import { IngredientMenu } from "./IngredientMenu";
import { enabledReferenceMeasuresDataSelector } from "../../../../../../../../store/data/selectors/referenceData";
import { ReferenceMeasure } from "../../../../../../../../data/models/referenceMeasure";
import { foodItemSelector } from "../../../../../../../../store/data/current-document/selectors/foodItems";
import { VolumeConversionFactor } from "../../../../../../../../data/models/documentProperties/volumeConversionFactor";
import { RootState } from "../../../../../../../../store/reducers";
import { templateIdSelector } from "../../../../../../../../store/data/current-document/selectors/document";
import {
  CopyIngredientRowButton,
  DeleteIngredientRowButton,
} from "./IngredientRowButtons";
import { FoodworksTooltip } from "../../../../../../../common/InfoTooltip";
import { FoodItemPosition } from "../../../../../../../../data/models/foodItemPosition";
import {
  displayNutrientValuesSelector,
  lockNutrientColumnsSelector,
  selectedRowsSelector,
} from "../../../../../../../../store/ui/selectors/recipeGrid";
import { displayedNutrientsSelector } from "../../../../../../../../store/data/selectors/databaseProperties";
import { Composition } from "../../../../../../../../data/models/composition";
import {
  foodItemCompositionSelector,
  sectionCompositionSelector,
} from "../../../../../../../../store/data/selectors/compositionCache";
import { isRecipe } from "../../../../../../../../constants/FoodTemplate";
import useUpdateSelection from "../../hooks/useUpdateSelection";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      padding: "2px 2px",
      flex: 2,
      background: appTheme.colors.white[0],
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      boxShadow: "none",
    },
    retentionFactorCell: {
      width: 50,
    },
    tooltip: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 11,
    },
    moreButton: {
      width: 40,
      minWidth: 40,
      height: 28,
    },
    checkbox: {
      padding: "0px 0px 0px 9px",
    },
    nutrientValue: {
      width: 70,
      paddingLeft: 1,
      paddingRight: 1,
    },
    sectionTotalRoot: {
      background: appTheme.colors.white[10],
      borderRadius: 4,
      border: "none",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-end",
      display: "flex",
      boxShadow: "none",
    },
    flex: {
      display: "flex",
    },
    buttonPlaceholder: {
      width: 40,
    },
  })
);

export interface IngredientRowProps {
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
  summaries: IngredientSummaryItem[];
  focusedCell: string;
  setFocusedCell: (cell: string) => void;
  isLastRow: boolean;
  isLastSection: boolean;
  searchTermMap: Map<string, string[]>;
}

export const IngredientRow: FunctionComponent<IngredientRowProps> = ({
  dayIndex,
  sectionIndex,
  rowIndex,
  focusedCell,
  setFocusedCell,
  isLastRow,
  isLastSection,
  summaries,
  searchTermMap,
}) => {
  const classes = useStyles();

  const updateSelection = useUpdateSelection();

  const onSetSelectedRows = (rowsToSelect: FoodItemPosition[]) =>
    {
      updateSelection(rowsToSelect)
    }

  const [rowHovered, setRowHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const selectFoodItem = useMemo(foodItemSelector, []);

  const foodItem: FoodItem | undefined = useSelector<
    RootState,
    FoodItem | undefined
  >((state) => selectFoodItem(state, dayIndex, sectionIndex, rowIndex));

  const foodItemDocument: Document | undefined = useSelector<
    RootState,
    Document | undefined
  >((state) => {
    return foodItem?.foodId
      ? cachedDocumentSelector(state, foodItem.foodId.identifier)
      : undefined;
  });

  const lockNutrientColumns: boolean = useSelector<RootState, boolean>(
    lockNutrientColumnsSelector
  );

  const selectFoodItemComposition = useMemo(foodItemCompositionSelector, []);

  const foodItemComposition: Composition | undefined = useSelector<
    RootState,
    Composition | undefined
  >((state) =>
    selectFoodItemComposition(state, {
      foodId: foodItem?.foodId,
      quantity: foodItem?.quantity,
      document: foodItemDocument,
    })
  );

  const selectSectionComposition = useMemo(sectionCompositionSelector, []);

  const sectionComposition: Composition | undefined = useSelector<
    RootState,
    Composition | undefined
  >((state) => selectSectionComposition(state, dayIndex, sectionIndex));

  const documentTemplateId: string =
    useSelector<RootState, string>(templateIdSelector);

  const enabledReferenceMeasures: ReferenceMeasure[] = useSelector<
    RootState,
    ReferenceMeasure[]
  >(enabledReferenceMeasuresDataSelector);

  const selectedRows: FoodItemPosition[] =
    useSelector<RootState, FoodItemPosition[]>(selectedRowsSelector);

  const displayNutrientValues: boolean = useSelector<RootState, boolean>(
    displayNutrientValuesSelector
  );

  const displayedNutrients: string[] = useSelector<RootState, string[]>(
    displayedNutrientsSelector
  );

  const isVolumeDefined = new VolumeConversionFactor(
    foodItemDocument?.volumeConversion
  ).isDefined;

  const relevantReferenceMeasures: ReferenceMeasure[] = foodItemDocument
    ? foodItemDocument.properties.isLiquid || isVolumeDefined
      ? enabledReferenceMeasures
      : enabledReferenceMeasures.filter(
          (referenceMeasure: ReferenceMeasure): boolean =>
            referenceMeasure.isWeight
        )
    : [];

  const ingredientCell = (useSetSize: boolean): ReactNode => (
    <IngredientCell
      currentFocusedCell={focusedCell}
      setFocusedCell={setFocusedCell}
      key={`ing-${rowIndex}`}
      dayIndex={dayIndex}
      sectionIndex={sectionIndex}
      rowIndex={rowIndex}
      columnIndex={0}
      summaries={summaries}
      value={foodItemDocument?.name || undefined}
      selectedFoodItem={foodItem}
      useSetSize={useSetSize}
      searchTermMap={searchTermMap}
      documentTemplateId={documentTemplateId}
    />
  );

  const quantityCell = (useSetSize: boolean): ReactNode => {
    return (
      <QuantityCell
        currentFocusedCell={focusedCell}
        setFocusedCell={setFocusedCell}
        dayIndex={dayIndex}
        sectionIndex={sectionIndex}
        rowIndex={rowIndex}
        columnIndex={1}
        key={`q-${rowIndex}`}
        foodItem={foodItem!}
        foodDocument={foodItemDocument!}
        useSetSize={useSetSize}
        relatedReferenceMeasures={relevantReferenceMeasures}
      />
    );
  };

  const noteCell: ReactNode = (
    <NoteCell
      currentFocusedCell={focusedCell}
      setFocusedCell={setFocusedCell}
      dayIndex={dayIndex}
      sectionIndex={sectionIndex}
      rowIndex={rowIndex}
      columnIndex={2}
      key={`n-${rowIndex}`}
      initialInput={foodItem?.note || ""}
      rowHovered={rowHovered}
    />
  );

  const retentionFactorCell: ReactNode = (
    <FoodworksTooltip
      interactive={true}
      title={foodItem?.retentionFactor?.description || ""}
      key="ingredientRow-foodworksTooltip"
    >
      <Box key={`rf-${rowIndex}`} className={classes.retentionFactorCell}>
        RF: ...
      </Box>
    </FoodworksTooltip>
  );

  const rowContainsFocusedCell: boolean =
    focusedCell.split("-")[0] === sectionIndex.toString() &&
    focusedCell.split("-")[1] === rowIndex.toString();

  const createCells = (): ReactNode[] => {
    let row: ReactNode[] = [];

    row.push(ingredientCell(!!foodItemDocument));
    if (foodItemDocument) {
      row.push(quantityCell(foodItem?.quantity !== undefined));
      if (foodItem?.retentionFactor) row.push(retentionFactorCell);
      if (foodItem?.quantity) row.push(noteCell);
    }

    if (!lockNutrientColumns && (rowHovered || rowContainsFocusedCell)) {
      if (foodItem) {
        row.push(
          <CopyIngredientRowButton
            key={`copyIngredientRowButton-${foodItem.foodId?.identifier}`}
            foodItem={foodItem}
          />
        );
        row.push(
          <DeleteIngredientRowButton
            key={`deleteIngredientRowButton-${foodItem.foodId?.identifier}`}
            dayIndex={dayIndex}
            sectionIndex={sectionIndex}
            rowIndex={rowIndex}
          />
        );
      }
      row.push(
        <Button
          key={`moreOptions-${rowIndex}`}
          className={classes.moreButton}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          size="small"
        >
          <MoreHorizIcon />
        </Button>
      );
    } else {
      if (displayNutrientValues) {
        for (const nutrientId of displayedNutrients) {
          if (isLastRow || !foodItemComposition) break;
          row.push(
            <Typography
              align="center"
              className={classes.nutrientValue}
              color={isLastRow ? "secondary" : "textPrimary"}
              noWrap
              variant="body1"
            >
              {foodItemComposition!.getRoundedNutrientValue(nutrientId)}
            </Typography>
          );
        }
        if (lockNutrientColumns) {
          if (rowHovered) {
            row.push(
              <Button
                key={`moreOptions-${rowIndex}`}
                className={classes.moreButton}
                onClick={(event) => setAnchorEl(event.currentTarget)}
                size="small"
              >
                <MoreHorizIcon />
              </Button>
            );
          } else {
            row.push(<div className={classes.buttonPlaceholder} />);
          }
        }
      }
    }
    return row;
  };

  const toolTipString = foodItemDocument
    ? foodItemDocument.name.length > INGREDIENT_CELL_MAX_WIDTH
      ? foodItemDocument.name
      : ""
    : "";

  const isSelected = !!selectedRows.find(
    (position: FoodItemPosition): boolean =>
      position.isEqual(new FoodItemPosition(dayIndex, sectionIndex, rowIndex))
  );

  const rowBackground = (): string => {
    if (isLastRow) return appTheme.colors.white[0];
    if (isSelected) {
      if (rowHovered) return appTheme.colors.oceanBlue[1];
      return appTheme.colors.oceanBlue[0];
    }

    return rowHovered ? appTheme.colors.white[8] : appTheme.colors.white[0];
  };

  const onSelectRow = () => {
    let rowsToSelect: FoodItemPosition[] = [];
    if (isSelected) {
      rowsToSelect = selectedRows.filter(
        (position: FoodItemPosition): boolean =>
          !position.isEqual(
            new FoodItemPosition(dayIndex, sectionIndex, rowIndex)
          )
      );
    } else {
      rowsToSelect = selectedRows.concat(
        new FoodItemPosition(dayIndex, sectionIndex, rowIndex)
      );
    }
    onSetSelectedRows(rowsToSelect);
  };

  const createSectionTotalCells = (): ReactNode => {
    const row: ReactNode[] = [];
    if (!sectionComposition) return;
    for (const nutrientId of displayedNutrients) {
      row.push(
        <Typography
          align="center"
          className={classes.nutrientValue}
          color="secondary"
          style={{ fontWeight: "bold" }}
          noWrap
          variant="body1"
        >
          {sectionComposition.getRoundedNutrientValue(nutrientId)}
        </Typography>
      );
    }

    return row;
  };

  const showSectionTotals: boolean =
    displayNutrientValues &&
    isLastRow &&
    !!rowIndex &&
    (!isRecipe(documentTemplateId) || !isLastSection);

  const rootStyles = {
    borderRadius: rowContainsFocusedCell
      ? "4px"
      : isLastRow
      ? "0px 0px 4px 4px"
      : !rowIndex
      ? "4px 4px 0px 0px"
      : "0px",
    background: rowBackground(),
    border: rowContainsFocusedCell
      ? `2px solid ${appTheme.colors.primary}`
      : "none",
    borderBottom: rowContainsFocusedCell
      ? `2px solid ${appTheme.colors.primary}`
      : !isLastRow
      ? `1px solid ${appTheme.colors.gainsbruh}`
      : "none",
    padding: rowContainsFocusedCell ? 0 : 2,
  };

  return (
    <div className={classes.flex}>
      <FoodworksTooltip
        enterDelay={500}
        placement="bottom-start"
        title={toolTipString}
      >
        <Paper
          className={classes.root}
          style={rootStyles}
          onMouseEnter={() => setRowHovered(true)}
          onMouseLeave={() => setRowHovered(false)}
          data-cy="ingredientRow"
        >
          <Checkbox
            className={classes.checkbox}
            data-cy="rowCheckbox"
            size="small"
            disabled={isLastRow}
            checked={!isLastRow && isSelected}
            onClick={onSelectRow}
          />
          {createCells()}
          <IngredientMenu
            dayIndex={dayIndex}
            sectionIndex={sectionIndex}
            rowIndex={rowIndex}
            open={Boolean(anchorEl)}
            parentButtonRef={anchorEl}
            onClose={() => setAnchorEl(null)}
            foodItem={foodItem}
          />
        </Paper>
      </FoodworksTooltip>
      {showSectionTotals && (
        <div
          className={classes.sectionTotalRoot}
          style={{
            paddingRight: lockNutrientColumns ? 40 : 0,
          }}
        >
          {createSectionTotalCells()}
        </div>
      )}
    </div>
  );
};

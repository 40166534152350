import { Action } from "redux";

import { Document } from "../../../data/models/document";
import { FoodId } from "../../../data/models/documentProperties/foodId";

export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const UPDATE_CACHED_DOCUMENT = "UPDATE_CACHED_DOCUMENT";
export const FETCH_DOCUMENT = "FETCH_DOCUMENT";

export interface IActionsAddDocument extends Action {
  type: "ADD_DOCUMENT";
  documentMapId: string;
  document: Document;
}

export interface IActionsRemoveDocument extends Action {
  type: "REMOVE_DOCUMENT";
  documentMapId: string;
}

export interface IActionsUpdateCachedDocument extends Action {
  type: "UPDATE_CACHED_DOCUMENT";
  documentMapId: string;
  document: Document;
}

export interface IActionsFetchDocument extends Action {
  type: "FETCH_DOCUMENT";
  foodId: FoodId;
  isPublic: boolean;
  isMapped: boolean;
}

export type DocumentCacheActions =
  | IActionsAddDocument
  | IActionsRemoveDocument
  | IActionsUpdateCachedDocument
  | IActionsFetchDocument;

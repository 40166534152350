import { ThunkAction } from "redux-thunk";

import {
  updateFoodItem,
  addFoodItem,
  foodItemsPasted,
  removeMultipleFoodItem,
} from "../action-creators/foodItems";
import {
  IActionsUpdateFoodItem,
  IActionsRemoveFoodItem,
  IActionsAddFoodItem,
  IActionsFoodItemsPasted,
  IActionsRemoveMultipleFoodItems,
} from "../actions/foodItems";
import {
  FoodItem,
  FoodItems,
} from "../../../../data/models/documentProperties/foodItem";
import { RootState } from "../../../reducers";
import Firebase from "../../../../data/Firebase";
import { daysSelector } from "../selectors/days";
import { Sections } from "../../../../data/models/documentProperties/section";
import { FoodItemPosition } from "../../../../data/models/foodItemPosition";
import { Days } from "../../../../data/models/documentProperties/day";
import { IActionsSetSelectedRows } from "../../../ui/actions/recipeGrid";
import {
  ClipboardDataType,
  ClipboardState,
} from "../../../reducers/clipboardReducers";

export const getMapOfDaysToRows = (
  rows: FoodItemPosition[]
): Map<number, Map<number, number[]>> => {
  const daysToSectionsMap = new Map<number, Map<number, number[]>>();
  for (const foodItem of rows) {
    if (daysToSectionsMap.has(foodItem.day)) {
      daysToSectionsMap.get(foodItem.day)!.has(foodItem.section)
        ? daysToSectionsMap
            .get(foodItem.day)!
            .get(foodItem.section)!
            .push(foodItem.row)
        : daysToSectionsMap
            .get(foodItem.day)!
            .set(foodItem.section, [foodItem.row]);
    } else {
      daysToSectionsMap.set(
        foodItem.day,
        new Map<number, number[]>([[foodItem.section, [foodItem.row]]])
      );
    }
  }
  return daysToSectionsMap;
};

export const deleteSelectedFoodItems = (
  rowsToRemove: FoodItemPosition[]
): ThunkAction<
  void,
  RootState,
  Firebase,
  | IActionsUpdateFoodItem
  | IActionsRemoveFoodItem
  | IActionsRemoveMultipleFoodItems
> => async (dispatch, getState) => {
  const onUpdateFoodItem = (
    dayIndex: number,
    sectionIndex: number,
    foodItem: FoodItem
  ) => dispatch(updateFoodItem(dayIndex, sectionIndex, foodItem));

  const onRemoveFoodItems = (
    foodObjects: { dayIndex: number; sectionIndex: number; rowIndex: number }[]
  ) => dispatch(removeMultipleFoodItem(foodObjects));

  const days: Days = daysSelector(getState());

  const indexesToRemoveMap: Map<
    number,
    Map<number, number[]>
  > = getMapOfDaysToRows(rowsToRemove);

  const foods: {
    dayIndex: number;
    sectionIndex: number;
    rowIndex: number;
  }[] = [];

  for (const [dayIndex, section] of Array.from(indexesToRemoveMap.entries())) {
    for (const [sectionIndex, foodItemsToRemove] of section) {
      let deletePointer = 0;
      const foodItems =
        days.days[dayIndex].sections.items[sectionIndex].foodItems;
      for (let i = 0; i < foodItems.length; i++) {
        if (
          deletePointer === foodItemsToRemove.length ||
          i < foodItemsToRemove[deletePointer]
        ) {
          const foodItem: FoodItem = foodItems.items[i];
          foodItem.rowIndex = i - deletePointer;
          onUpdateFoodItem(dayIndex, sectionIndex, foodItem);
        } else {
          deletePointer++;
        }
      }

      foodItemsToRemove.forEach((val: number, idx: number) =>
        foods.push({
          dayIndex,
          sectionIndex,
          rowIndex: foodItems.length - (1 + idx),
        })
      );
    }
  }
  onRemoveFoodItems(foods);
};

export const addFoodItemsFromIndex = (
  foodItemsToInsert: FoodItem[],
  rowIndex: number,
  dayIndex: number,
  sectionIndex: number
): ThunkAction<
  void,
  RootState,
  Firebase,
  IActionsUpdateFoodItem | IActionsAddFoodItem | IActionsFoodItemsPasted
> => async (dispatch, getState) => {
  const onUpdateFoodItem = (foodItem: FoodItem) => {
    dispatch(updateFoodItem(dayIndex, sectionIndex, foodItem));
  };

  const onAddFoodItem = (foodItem: FoodItem) =>
    dispatch(addFoodItem(dayIndex, sectionIndex, foodItem));

  const onFoodItemsPasted = () => dispatch(foodItemsPasted());

  const foodItems = daysSelector(getState()).days[dayIndex].sections.items[
    sectionIndex
  ].foodItems;

  const foodItemLength = foodItems.length;

  const rowsToInsert = foodItemsToInsert.length;

  for (let i = 0; i < rowsToInsert; i++) {
    onAddFoodItem(
      new FoodItem(undefined, undefined, "", foodItemLength + i, null, "")
    );
  }

  const sortedItems = foodItems.items.sort(
    (a: FoodItem, b: FoodItem) => a.rowIndex - b.rowIndex
  );

  for (let i = sortedItems.length - 1; i >= 0; i--) {
    const foodItem = sortedItems[i];

    if (foodItem.rowIndex < rowIndex) break;

    foodItem.rowIndex = foodItem.rowIndex + rowsToInsert;
    onUpdateFoodItem(foodItem);
  }

  for (let i = 0; i < rowsToInsert; i++) {
    foodItemsToInsert[i].rowIndex = rowIndex + i;
    onUpdateFoodItem(foodItemsToInsert[i]);
  }

  onFoodItemsPasted();
};

export const pasteFoodItems = (
  clipboard: ClipboardState,
  dayIndex: number,
  sectionIndex: number,
  rowIndex: number
): ThunkAction<void, RootState, Firebase, IActionsSetSelectedRows> => async (
  dispatch
) => {
  let data: FoodItem[] = [];
  if (clipboard.clipboardData!.dataType === ClipboardDataType.SECTIONS) {
    const sections = clipboard.clipboardData!.data as Sections;
    for (const section of sections.items) {
      data = data.concat(section.foodItems.items);
    }
  } else if (
    clipboard.clipboardData!.dataType === ClipboardDataType.INGREDIENT_ROWS
  ) {
    const foodItems = clipboard.clipboardData!.data as FoodItems;
    data = foodItems.items;
  }

    dispatch(addFoodItemsFromIndex(data, rowIndex, dayIndex, sectionIndex));
};

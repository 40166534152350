import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { Button, makeStyles, MenuItem, Typography } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import { ChevronRight, ExpandMore } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import {
  FOOD_TEMPLATES,
  FoodTemplate,
} from "../../../../constants/FoodTemplate";
import {
  documentsContainedSelector,
  documentsUsedInSelector,
  SummaryData,
} from "../../../../store/data/current-document/selectors/document";
import { changeCurrentDocument } from "../../../../store/data/current-document/thunks/currentDocument";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import { IngredientSummaryItem } from "../../databases/documents/tabs/ingredients/editing_grid/rows/cells/IngredientCell";
import {
  allCachedDocumentsSelector,
  cachedDocumentSelector,
} from "../../../../store/data/selectors/documentCache";
import { databaseIdSelector } from "../../../../store/data/selectors/database";
import { Document, DocumentTemplateId } from "../../../../data/models/document";
import { CurrentDocumentIdSelector } from "../../../../store/data/current-document/selectors/currentDocument";
import { FoodworksTooltip } from "../../../common/InfoTooltip";
import { DocumentMap } from "../../../../store/data/reducers/documentCache";
import FoodWorksTagSwitch from "../../../common/FoodWorksTagSwitch";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 5,
    marginTop: 5,
  },
  currentDocumentTextRoot: {
    display: "flex",
    overflow: "hidden",
    alignItems: "end",
    alignContent: "center",
  },
  currentDocumentName: {
    overflow: "visible",
    fontWeight: 600,
    color: appTheme.colors.primary,

    padding: 5,
    borderRadius: 5,
  },
  usedInText: {
    margin: "auto",
    alignContent: "center",
  },
  listRoot: {
    overflow: "auto",
  },
  menuItemRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: 0,
  },
  menuItemIcon: {
    fill: appTheme.colors.xiketic,
    width: 24,
    height: 24,
  },
  menuItemText: {
    paddingLeft: 0,
    marginLeft: 5,
    marginRight: 5,
  },
  selected: {
    backgroundColor: appTheme.colors.oceanBlue[0],
  },
  selectedText: {
    fontWeight: 600,
    color: appTheme.colors.primary,
  },
  iconButton: {
    width: 40,
    borderRadius: 4,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

interface DocumentExplorerProps {
  documentId: string;
}

export const DocumentExplorer = React.memo<DocumentExplorerProps>(
  ({ documentId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onChangeCurrentDocument = useCallback(
      (documentId: string) => dispatch(changeCurrentDocument(documentId)),
      [dispatch]
    );

    const currentDatabaseId: string =
      useSelector<RootState, string>(databaseIdSelector);

    const currentDocumentId: string = useSelector<RootState, string>(
      CurrentDocumentIdSelector
    );

    const document: Document | undefined = useSelector<
      RootState,
      Document | undefined
    >(
      (state: RootState) =>
        cachedDocumentSelector(state, `${currentDatabaseId}:${documentId}`)!
    );

    const documentCache: DocumentMap = useSelector<RootState, DocumentMap>(
      allCachedDocumentsSelector
    );

    const selectDocumentsUsedIn = useMemo(documentsUsedInSelector, []);

    const documentsUsedIn: IngredientSummaryItem[] = useSelector<
      RootState,
      IngredientSummaryItem[]
    >((state: RootState) => selectDocumentsUsedIn(state, document));

    const selectDocumentsContained = useMemo(documentsContainedSelector, []);

    const documentsContained: SummaryData[] = useSelector<
      RootState,
      SummaryData[]
    >((state: RootState) => selectDocumentsContained(state, documentId));

    const [showContained, setShowContained] = useState(false);

    const documentItem = useCallback(
      (summary: IngredientSummaryItem): ReactNode => {
        const template = FOOD_TEMPLATES.find(
          (template: FoodTemplate): boolean =>
            template.id.toString() === summary.templateId
        )!;
        return (
          <MenuItem
            component="div"
            className={
              summary.foodId.documentId === currentDocumentId
                ? `${classes.menuItemRoot} ${classes.selected}`
                : classes.menuItemRoot
            }
            key={summary.foodId.identifier}
            onClick={() => onChangeCurrentDocument(summary.foodId.documentId)}
          >
            <template.icon className={classes.menuItemIcon} />
            <Typography
              className={
                summary.foodId.documentId === currentDocumentId
                  ? `${classes.menuItemText} ${classes.selectedText}`
                  : classes.menuItemText
              }
              noWrap
            >
              {summary.label}
            </Typography>
          </MenuItem>
        );
      },
      [classes, currentDocumentId, onChangeCurrentDocument]
    );

    const renderUsedInTree = useMemo(
      () =>
        (nodes: IngredientSummaryItem[]): ReactNode =>
          nodes.map((summary: IngredientSummaryItem): ReactNode => {
            return (
              <TreeItem
                key={`tree-${summary.foodId.identifier}`}
                nodeId={`tree-${summary.foodId.identifier}`}
                label={documentItem(summary)}
              ></TreeItem>
            );
          }),
      [documentItem]
    );

    const renderContainedTree = useMemo(
      () =>
        (nodes: SummaryData[]): ReactNode =>
          nodes.map((summaryData: SummaryData): ReactNode => {
            return (
              <TreeItem
                key={`tree-${summaryData.summary.foodId.identifier}`}
                nodeId={`tree-${summaryData.summary.foodId.identifier}`}
                label={documentItem(summaryData.summary)}
              >
                {renderContainedTree(summaryData.children)}
              </TreeItem>
            );
          }),
      [documentItem]
    );

    const treeComponent = (
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpanded={["root"]}
        defaultExpandIcon={<ChevronRight />}
      >
        {showContained
          ? renderContainedTree(documentsContained)
          : renderUsedInTree(documentsUsedIn)}
      </TreeView>
    );

    const documentContainsText = (templateId?: DocumentTemplateId): string => {
      const contains: string = "contains:";
      const isUsedIn: string = "is used in:";

      switch (templateId) {
        case DocumentTemplateId.FOOD:
          return isUsedIn;
        case DocumentTemplateId.RECIPE:
          return showContained ? contains : isUsedIn;
        case DocumentTemplateId.FOOD_RECORD:
          return contains;
        case DocumentTemplateId.MEAL_PLAN:
          return contains;
        case DocumentTemplateId.RECALL:
          return contains;
        default:
          return "";
      }
    };

    return (
      <div className={classes.root}>
        {documentId && `${currentDatabaseId}:${documentId}` in documentCache && (
          <div className={classes.body}>
            <div className={classes.headerContainer}>
              <div className={classes.currentDocumentTextRoot}>
                <Button onClick={() => onChangeCurrentDocument(documentId)}>
                  <Typography
                    className={
                      documentId === currentDocumentId
                        ? `${classes.currentDocumentName} ${classes.selected}`
                        : classes.currentDocumentName
                    }
                  >
                    {document?.name || ""}
                  </Typography>
                </Button>
                <FoodworksTooltip
                  title={documentContainsText(document?.templateId)}
                >
                  <Typography className={classes.usedInText} noWrap>
                    {documentContainsText(document?.templateId)}
                  </Typography>
                </FoodworksTooltip>
              </div>
              {document!.templateId === DocumentTemplateId.RECIPE && (
                <FoodworksTooltip title="Change viewing mode">
                  <span>
                    <FoodWorksTagSwitch
                      checked={showContained}
                      onChange={() => setShowContained((prev) => !prev)}
                    />
                  </span>
                </FoodworksTooltip>
              )}
            </div>
            {treeComponent}
          </div>
        )}
      </div>
    );
  }
);

import { RootState } from "../../reducers";
import { DocumentMap } from "../reducers/documentCache";
import { Document } from "../../../data/models/document";

export const allCachedDocumentsSelector = (state: RootState): DocumentMap =>
  state.documentCache;

export const cachedDocumentSelector = (
  state: RootState,
  foodId: string | undefined
): Document | undefined => {
  if (!foodId) return undefined;
  return state.documentCache[foodId];
};

import { Action } from "redux";

export const USER_LOGOUT = "USER_LOGOUT";

export interface IActionsUserLogout extends Action {
  type: "USER_LOGOUT";
}

export type RootActions = IActionsUserLogout;

export const userLogout = (): IActionsUserLogout => {
  return {
    type: "USER_LOGOUT",
  };
};

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { Nutrient } from "../../../../data/models/nutrient";
import { NRVType } from "../../../../data/models/nutrientReferenceValues";
import {
  enabledNutrientIdsMapSelector,
  enabledNutrientsDataSelector,
} from "../../../../store/data/selectors/referenceData";
import { ITEM_ELEMENTS } from "../components/NRVTableItems";

export interface NutrientValues {
  name: string;
  units: string;
}

export const useCategoryNutrientsMap = (): [
  Map<string, Map<string, NutrientValues>>,
  Dispatch<SetStateAction<Map<string, Map<string, NutrientValues>>>>
] => {
  const [map, setMap] = useState<Map<string, Map<string, NutrientValues>>>(
    new Map()
  );

  const enabledCategories: Map<string, string[]> = useSelector(
    enabledNutrientIdsMapSelector
  );

  const enabledNutrientsData: Map<string, Nutrient> = useSelector(
    enabledNutrientsDataSelector
  );

  const loadMap = useCallback(() => {
    const newMap = new Map<string, Map<string, NutrientValues>>();

    for (const [categoryId, nutrientIds] of enabledCategories) {
      for (const nutrientId of nutrientIds) {
        const nutrientsData: Nutrient | undefined =
          enabledNutrientsData.get(nutrientId);

        if (nutrientsData) {
          const nutrientValues: NutrientValues = {
            name: nutrientsData.name,
            units: nutrientsData.units,
          };

          const nutrientsValueMap: Map<string, NutrientValues> | undefined =
            newMap.get(categoryId);

          !nutrientsValueMap
            ? newMap.set(categoryId, new Map().set(nutrientId, nutrientValues))
            : newMap.set(
                categoryId,
                nutrientsValueMap.set(nutrientId, nutrientValues)
              );
        }
      }
    }
    setMap(newMap);
  }, [enabledCategories, enabledNutrientsData]);

  useEffect(() => {
    loadMap();
  }, [loadMap]);

  return [map, setMap];
};

export const buildHeader = (categoryName: string) => {
  return categoryName.toLowerCase() === "energy"
    ? [
        categoryName,
        "Goal Min",
        ITEM_ELEMENTS.smallColorSpace,
        "Goal Max",
        ITEM_ELEMENTS.bigWhiteSpace,
        "EERM",
        ITEM_ELEMENTS.smallColorSpace,
        "DEER",
        ITEM_ELEMENTS.bigWhiteSpace,
        ITEM_ELEMENTS.bigWhiteSpace,
        ITEM_ELEMENTS.bigWhiteSpace,
        ITEM_ELEMENTS.bigWhiteSpace,
        ITEM_ELEMENTS.smallWhiteSpace,
        ITEM_ELEMENTS.smallWhiteSpace,
        ITEM_ELEMENTS.smallWhiteSpace,
        ITEM_ELEMENTS.smallWhiteSpace,
      ]
    : [
        categoryName,
        "Goal Min",
        ITEM_ELEMENTS.smallColorSpace,
        "Goal Max",
        ITEM_ELEMENTS.bigWhiteSpace,
        "EAR",
        ITEM_ELEMENTS.smallColorSpace,
        "RDI",
        ITEM_ELEMENTS.smallColorSpace,
        "SDT MIN",
        ITEM_ELEMENTS.smallColorSpace,
        "SDT MAX",
        ITEM_ELEMENTS.smallColorSpace,
        "AI",
        ITEM_ELEMENTS.smallColorSpace,
        "UL",
      ];
};

const parseNRValues = (num: number, decimalPoints: number): string =>
  parseFloat(num.toFixed(decimalPoints)).toString();

export const convertNRValuesToRows = (
  values: Map<NRVType, number> | undefined,
  decimalPoints: number = 2
): string[] => {
  if (!values) return ["", "-", "", "-", "", "-", "", "-", "", "-", "", "-"];

  const ear: number | undefined = values.get("EAR");
  const rdi: number | undefined = values.get("RDI");
  const stdmin: number | undefined = values.get("SDTMIN");
  const stdmax: number | undefined = values.get("SDTMAX");
  const ai: number | undefined = values.get("AI");
  const uil: number | undefined = values.get("UIL");

  const parsedEar: string = !ear ? "-" : parseNRValues(ear, decimalPoints);
  const parsedRdi: string = !rdi ? "-" : parseNRValues(rdi, decimalPoints);
  const parsedStdmin: string = !stdmin
    ? "-"
    : parseNRValues(stdmin, decimalPoints);
  const parsedStdmax: string = !stdmax
    ? "-"
    : parseNRValues(stdmax, decimalPoints);
  const parsedAi: string = !ai ? "-" : parseNRValues(ai, decimalPoints);
  const parsedUil: string = !uil ? "-" : parseNRValues(uil, decimalPoints);

  return [
    "",
    parsedEar,
    "",
    parsedRdi,
    "",
    parsedStdmin,
    "",
    parsedStdmax,
    "",
    parsedAi,
    "",
    parsedUil,
  ];
};

export const convertEnergyNRValuesToRows = (
  values: Map<NRVType, number> | undefined,
  decimalPoints: number = 2
): string[] => {
  if (!values) return ["", "-", "", "-", "", "", "", "", "", "", "", ""];

  const eerm: number | undefined = values.get("EERM");
  const deer: number | undefined = values.get("DEER");

  const parsedEERM: string = !eerm ? "-" : parseNRValues(eerm, decimalPoints);
  const parsedDEER: string = !deer ? "-" : parseNRValues(deer, decimalPoints);

  return ["", parsedEERM, "", parsedDEER, "", "", "", "", "", "", "", ""];
};

import React, { FunctionComponent, ReactNode, useState } from "react";

import {
  Typography,
  Button,
  Card,
  List,
  ListSubheader,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import { Days, DayState } from "../../../../data/models/documentProperties/day";
import { SectionState } from "../../../../data/models/documentProperties/section";
import { daysSelector } from "../../../../store/data/current-document/selectors/days";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import { FoodWorksTextInput } from "../../../common/FoodWorksTextInput";
import { TAG_FIELD } from "../../../../constants/textInputs";

const useStyles = makeStyles((theme) => ({
  listButton: {
    borderRadius: 4,
    marginTop: 2,
    marginBottom: 2,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
  },
  selectedListButton: {
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
  },
  sectionSelectorContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
  },
  selectButtonsContainer: {
    display: "flex",
  },
  sectionsList: {
    height: 300,
    overflowY: "auto",
  },
  selected: {
    backgroundColor: appTheme.colors.oceanBlue[0],
  },
}));

interface SelectSectionsListProps {
  selectedSections: { day: number; section: number }[];
  setSelectedSections: (
    sectionsToSelect: { day: number; section: number }[]
  ) => void;
  tagId?: string;
  disabled: boolean;
}

export const SelectSectionsList: FunctionComponent<SelectSectionsListProps> = ({
  selectedSections,
  setSelectedSections,
  disabled,
  tagId,
}) => {
  const classes = useStyles();

  const days: Days = useSelector<RootState, Days>(daysSelector);

  const [searchInput, setSearchInput] = useState("");

  const isSectionSelected = (dayIndex: number, sectionIndex: number) =>
    !!selectedSections.find(
      (section: { day: number; section: number }): boolean =>
        section.day === dayIndex && section.section === sectionIndex
    );

  const onSectionSelect = (dayIndex: number, sectionIndex: number) => {
    if (!isSectionSelected(dayIndex, sectionIndex)) {
      setSelectedSections([
        ...selectedSections,
        { day: dayIndex, section: sectionIndex },
      ]);
    } else {
      setSelectedSections(
        selectedSections.filter(
          (section: { day: number; section: number }): boolean =>
            section.day !== dayIndex || section.section !== sectionIndex
        )
      );
    }
  };

  const filteredSections = (): DayState[] => {
    const filteredDays: DayState[] = [];
    for (const day of days.days) {
      const sections: SectionState[] = day.object.sections.filter(
        (section: SectionState): boolean =>
          section.title.toLowerCase().includes(searchInput.toLowerCase())
      );
      if (sections.length) {
        filteredDays.push({ ...day.object, sections: sections });
      }
    }
    return filteredDays;
  };

  const createSectionsList = () =>
    filteredSections().map(
      (day: DayState): ReactNode => (
        <div key={day.id}>
          <ListSubheader
            disableSticky
          >{`${day.title} ${day.date}`}</ListSubheader>
          {day.sections.map(
            (section: SectionState): ReactNode => (
              <MenuItem
                key={section.id}
                disabled={disabled}
                className={
                  isSectionSelected(day.index, section.index)
                    ? `${classes.listButton} ${classes.selectedListButton}`
                    : classes.listButton
                }
                onClick={() => onSectionSelect(day.index, section.index)}
              >
                {section.title}
              </MenuItem>
            )
          )}
        </div>
      )
    );

  const onSelectAll = () => {
    const sectionsToAdd: { day: number; section: number }[] = [];
    for (const day of filteredSections()) {
      for (const section of day.sections) {
        if (!isSectionSelected(day.index, section.index)) {
          sectionsToAdd.push({ day: day.index, section: section.index });
        }
      }
    }
    setSelectedSections([...selectedSections, ...sectionsToAdd]);
  };

  const onDeselectAll = () => {
    let sections = [...selectedSections];
    for (const day of filteredSections()) {
      for (const section of day.sections) {
        if (isSectionSelected(day.index, section.index)) {
          sections = sections.filter(
            (selectedSection: { day: number; section: number }): boolean =>
              selectedSection.day !== day.index ||
              selectedSection.section !== section.index
          );
        }
      }
    }
    setSelectedSections(sections);
  };

  return (
    <div className={classes.sectionSelectorContainer}>
      <Typography variant="body1">Add tag to sections</Typography>
      <FoodWorksTextInput
        maxLength={TAG_FIELD}
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value)}
        placeholder={"Search section titles..."}
      />
      <div className={classes.selectButtonsContainer}>
        <Button onClick={onSelectAll} disabled={disabled}>
          Select all
        </Button>
        <Button onClick={onDeselectAll} disabled={disabled}>
          Deselect all
        </Button>
      </div>

      <Card>
        <List className={classes.sectionsList}>{createSectionsList()}</List>
      </Card>
    </div>
  );
};

import React from "react";
import { Card, makeStyles, Typography } from "@material-ui/core";

import EERDisplay from "./components/EERDisplay";
import BMRDisplay from "./components/BMRDisplay";
import EnergyRequirementMethodInput from "./components/EnergyRequirementMethodInput";

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 25,
    padding: 6,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  energyMethodContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    width: 450,
  },
  middleText: {
    margin: "0px 3px",
  },
}));

const RequirementsPanel = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <Card className={classes.root}>
        <div className={classes.energyMethodContainer}>
          <Typography variant="h3">Energy Requirements</Typography>
          <Typography
            className={classes.middleText}
          >{`calculated using`}</Typography>
          <EnergyRequirementMethodInput />
        </div>
        <div className={classes.body}>
          <EERDisplay />
          <BMRDisplay />
        </div>
      </Card>
    </div>
  );
};

export default RequirementsPanel;

import { CommonMeasuresState } from "../reducers/commonMeasures";
import { RootState } from "../../../reducers";

import { DocumentMap } from "../../reducers/documentCache";
import { createSelector } from "reselect";
import {
  buildCommonMeasuresForMappedDocument,
  CommonMeasure,
} from "../../../../data/models/documentProperties/measure";
import { Document } from "../../../../data/models/document";

export const CommonMeasuresSelector = (state: RootState): CommonMeasure[] =>
  state.currentDocument.document.commonMeasures.measures;

export const DefaultCommonMeasureSelector = (
  state: RootState
): CommonMeasuresState["default"] =>
  state.currentDocument.document.commonMeasures.default;

export const documentCacheSelector = (rootState: RootState): DocumentMap =>
  rootState.documentCache;

const documentIdentitySelector = (_: any, document: Document) => document;

export const commonMeasureBuilderSelector = () =>
  createSelector(
    documentIdentitySelector,
    documentCacheSelector,
    (document, documentCache) =>
      buildCommonMeasuresForMappedDocument(document, documentCache)
  );

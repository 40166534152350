import { useCallback, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";

import {
  setClientAge,
  setClientDOB,
} from "../../../../../../../store/data/current_client/action_creators/clientProfile";
import { clientAgeSelector } from "../../../../../../../store/data/current_client/selectors/clientProfile";
import { RootState } from "../../../../../../../store/reducers";

const useClientAge = (
  date: Date | null
): [number | undefined, (age: number | undefined) => void, () => void] => {
  const dispatch = useDispatch();

  const [ageInput, setAgeInput] = useState<number | undefined>(undefined);

  const age: number | undefined =
    useSelector<RootState, number | undefined>(clientAgeSelector);

  const onSetClientAge = useCallback(() => {
    batch(() => {
      dispatch(setClientAge(ageInput));
      dispatch(setClientDOB(""));
    });
  }, [ageInput, dispatch]);

  useEffect(() => {
    if (date)
      dispatch(setClientAge(new Date().getFullYear() - date.getFullYear()));
  }, [date, dispatch]);

  useEffect(() => {
    setAgeInput(age);
  }, [age]);

  return [ageInput, setAgeInput, onSetClientAge];
};

export default useClientAge;

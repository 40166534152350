import { batch } from "react-redux";
import { ThunkAction } from "redux-thunk";

import { ClientDatabase } from "../../../../data/models/clientDatabase";
import { User } from "../../../../data/models/user";
import { UserDatabaseSummary } from "../../../../data/models/userDatabase";
import {
  getClientDashboardRouteData,
  getDatabaseRouteData,
} from "../../../../data/routing/routing";
import { RootState } from "../../../reducers";
import { ExtraArguments } from "../../../store";
import { handleRouteChange } from "../../../ui/thunks/routing";
import {
  setClientUserDatabaseId,
  setClientSummaries,
  setClientDatabaseTags,
} from "../../action-creators/clientDatabase";
import { setUserDatabaseSummaries } from "../../action-creators/user";
import {
  IActionsSetClientDatabaseId,
  IActionsSetClientDatabaseTags,
  IActionsSetClientSummaries,
} from "../../actions/clientDatabase";
import { IActionsSetUserDatabaseSummaries } from "../../actions/user";

const initialiseUser =
  (): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    | IActionsSetClientSummaries
    | IActionsSetClientDatabaseId
    | IActionsSetClientDatabaseTags
    | IActionsSetUserDatabaseSummaries
  > =>
  async (dispatch, _, { firebase }) => {
    const uid: string | undefined = firebase.auth.currentUser?.uid;
    if (!uid) return;

    // Initialise client database

    const clientDatabaseId: string =
      await firebase.userPermissions.doGetUserClientDatabase(uid);

    const clientDatabase: ClientDatabase =
      await firebase.clientDatabases.doGetUserClientDatabase(clientDatabaseId);

    batch(() => {
      dispatch(setClientUserDatabaseId(clientDatabase.databaseId));
      dispatch(setClientSummaries(clientDatabase.clientSummaries));
      dispatch(setClientDatabaseTags(clientDatabase.tags));
    });

    const user: User | undefined = await firebase.users.doGetUser(uid);

    if (!user) return;

    // Navigate user to their last visited screen

    switch (user.lastScreen) {
      case "clients":
        dispatch(handleRouteChange(getClientDashboardRouteData()));
        break;
      case "databases":
        dispatch(
          handleRouteChange(getDatabaseRouteData(user.lastUsedDatabase))
        );
        break;
      default:
        break;
    }

    // Fetch summaries for all the user's databases

    const databaseSummaries: UserDatabaseSummary[] =
      await firebase.getAllAvailableUserDatabases(uid);

    dispatch(setUserDatabaseSummaries(databaseSummaries));
  };

export default initialiseUser;

export const LARGE_FIELD = 1000
export const MEDIUM_FIELD = 100
export const SMALL_FIELD = 75
export const XS_FIELD = 12

export const NAME_FIELD = SMALL_FIELD
export const PASSWORD_FIELD = MEDIUM_FIELD
export const EMAIL_FIELD = MEDIUM_FIELD
export const AGE_FIELD = 3
export const TAG_FIELD = XS_FIELD
export const UNITS_FIELD = XS_FIELD
export const MOBILE_FIELD = 16
export const METHOD_FIELD = 100000
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FOOD_TEMPLATES } from "../../../../constants/FoodTemplate";
import { DocumentSummary } from "../../../../data/models/userDatabase";
import { changeCurrentDocument } from "../../../../store/data/current-document/thunks/currentDocument";
import { RootState } from "../../../../store/reducers";
import { tappedNavAccordion } from "../../../../store/ui/actionCreators/navigationActionCreators";
import { navAccordionsExpandedSelector } from "../../../../store/ui/selectors/navigationSelectors";
import {
  AccordionItem,
  AccordionList,
  AccordionProps,
} from "../../../common/AccordionList";
import { DocumentItem } from "./NavigationListItems";
import { appTheme } from "../../../../styling/style";

const getDocumentItems = (
  documents: DocumentSummary[],
  lockedDocumentId: { value: string; set: (id: string) => void },
  onDocumentClick: (id: string) => void,
  showDocumentTags: boolean
): AccordionItem[] =>
  documents.map(
    (summary: DocumentSummary): AccordionItem => ({
      id: summary.documentId,
      item: (
        <DocumentItem
          documentSummary={summary}
          onDocumentClick={onDocumentClick}
          lockedDocumentId={lockedDocumentId.value}
          setLockedDocumentId={lockedDocumentId.set}
          showDocumentTags={showDocumentTags}
        />
      ),
    })
  );

const getDocumentGroupings = (
  visibleDocuments: DocumentSummary[],
  lockedDocumentId: { value: string; set: (id: string) => void },
  onDocumentClick: (id: string) => void,
  navAccordionsExpanded: number[],
  onTappedNavAccordion: (templateId: string) => void,
  showDocumentTags: boolean
): AccordionProps[] =>
  FOOD_TEMPLATES.map(
    (template): AccordionProps => ({
      id: template.id,
      header: template.title,
      icon: (
        <template.icon
          style={{ fill: appTheme.colors.xiketic, width: 24, height: 24 }}
        />
      ),
      items: getDocumentItems(
        visibleDocuments.filter(
          (summary: DocumentSummary) =>
            template.id.toString() === summary.templateId
        ),
        lockedDocumentId,
        onDocumentClick,
        showDocumentTags
      ),
      open: navAccordionsExpanded.includes(template.id),
      onClick: () => onTappedNavAccordion(template.id.toString()),
    })
  ).filter((accordion: AccordionProps) => accordion.items.length);

interface NavigationDocumentListProps {
  lockedDocumentId: { value: string; set: (id: string) => void };
  navIsLoading: boolean;
  visibleDocuments: DocumentSummary[];
  showTags: boolean;
}

const NavigationDocumentList = ({
  lockedDocumentId,
  navIsLoading,
  visibleDocuments,
  showTags,
}: NavigationDocumentListProps): JSX.Element => {
  const dispatch = useDispatch();

  const onChangeCurrentDocument = useCallback(
    (documentId: string) => dispatch(changeCurrentDocument(documentId)),
    [dispatch]
  );

  const onTappedNavAccordion = (templateId: string) =>
    dispatch(tappedNavAccordion(Number(templateId)));

  const onDocumentItemClick = useCallback(
    (documentId: string) => {
      onChangeCurrentDocument(documentId);
      lockedDocumentId.set(documentId);
    },
    [onChangeCurrentDocument, lockedDocumentId]
  );

  const navAccordionsExpanded = useSelector<RootState, number[]>(
    navAccordionsExpandedSelector
  );

  const documentAccordions = getDocumentGroupings(
    visibleDocuments,
    lockedDocumentId,
    onDocumentItemClick,
    navAccordionsExpanded,
    onTappedNavAccordion,
    showTags
  );

  return <AccordionList values={navIsLoading ? [] : documentAccordions} />;
};

export default NavigationDocumentList;

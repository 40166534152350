import { Document } from "../document";
import { DocumentMap } from "../../../store/data/reducers/documentCache";

export class VolumeConversionFactor {
  volumeG: number | undefined;
  volumeMl: number | undefined;

  constructor(volumeConversion: VolumeConversionObject | undefined) {
    this.volumeG = volumeConversion?.volumeG;
    this.volumeMl = volumeConversion?.volumeMl;
  }

  get isDefined(): boolean {
    return Boolean(this.volumeG) && Boolean(this.volumeMl);
  }

  get factor(): number | undefined {
    return !this.volumeG || !this.volumeMl
      ? undefined
      : this.volumeG / this.volumeMl;
  }
}

export interface VolumeConversionObject {
  volumeG: number | undefined;
  volumeMl: number | undefined;
}

export const volumeConversionConverter = {
  toFirestore: (
    volumeConversion: VolumeConversionObject
  ): firebase.firestore.DocumentData => {
    return {
      volumeG: volumeConversion.volumeG || null,
      volumeMl: volumeConversion.volumeMl || null,
    };
  },

  fromFirestore: (
    data: firebase.firestore.DocumentData
  ): VolumeConversionObject => {
    return {
      volumeG: data.volumeG || data.g || undefined,
      volumeMl: data.volumeMl || data.mL || undefined,
    };
  },
};

export const getVolumeConversionFromMappedDocument = (
  document: Document,
  documentCache: DocumentMap
): VolumeConversionFactor => {
  const mappedDocument: Document = documentCache[document.documentMappingId];
  return new VolumeConversionFactor(mappedDocument.volumeConversion);
};

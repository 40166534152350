import { Action } from "redux";

import { FoodWorksError } from "../../constants/errors";

export const ADD_ERROR = "ADD_ERROR";
export const RESET_ERROR = "RESET_ERROR";

export interface IActionsAddError extends Action {
  type: "ADD_ERROR";
  error: FoodWorksError;
}

export interface IActionsResetError extends Action {
  type: "RESET_ERROR";
}

export type ErrorActions = IActionsAddError | IActionsResetError;

import React from "react";
import { Card, Icon, makeStyles, Typography } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";

import ActivityLevelInput from "./components/ActivityLevelInput";
import AgeInput from "./components/AgeInput";
import BMIDisplay from "./components/BMIDisplay";
import CurrentWeightInput from "./components/CurrentWeightInput";
import HeightInput from "./components/HeightInput";
import LactatingInput from "./components/LactatingInput";
import PALDisplay from "./components/PALDisplay";
import PregnancyLevelInput from "./components/PregnancyLevelInput";
import SexInput from "./components/SexInput";
import CustomEnergyRequirementInput from "./components/RestingMetabolicRateInput";
import { appTheme } from "../../../../../../styling/style";
import { FoodworksTooltip } from "../../../../../common/InfoTooltip";

const useStyles = makeStyles(() => ({
  profileContainer: {
    padding: 6,
  },
  headingContainer: {
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
  },
  warningIcon: {
    color: appTheme.colors.warning,
  },
}));

const ClientProfile = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Card className={classes.profileContainer}>
      <div className={classes.headingContainer}>
        <Typography variant="h3">Profile</Typography>
        <FoodworksTooltip title="Fields must be filled for graphs to produce results.">
          <Icon className={classes.warningIcon}>
            <WarningRounded />
          </Icon>
        </FoodworksTooltip>
      </div>
      <AgeInput />
      <SexInput />
      <CurrentWeightInput />
      <HeightInput />
      <BMIDisplay />
      <ActivityLevelInput />
      <PALDisplay />
      <PregnancyLevelInput />
      <LactatingInput />
      <CustomEnergyRequirementInput />
    </Card>
  );
};

export default ClientProfile;

import React, { FunctionComponent, ReactNode, useState } from "react";
import {
  makeStyles,
  Button,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { Document } from "../../../data/models/document";
import { BaseDialog } from "../BaseDialog";
import { appTheme } from "../../../styling/style";
import { RootState } from "../../../store/reducers";
import { deleteDocument } from "../../../store/data/current-document/thunks/document";
import { DocumentSummary } from "../../../data/models/userDatabase";
import { cachedDocumentSelector } from "../../../store/data/selectors/documentCache";
import {
  databaseIdSelector,
  userDocumentSummariesSelector,
} from "../../../store/data/selectors/database";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "center",
  },
  titleBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  flex: {
    display: "flex",
  },
  deleteButton: {
    color: appTheme.colors.error,
  },
  databaseName: {
    marginLeft: 10,
    fontWeight: "bold",
  },
  confirmationInput: {
    marginTop: 10,
    width: 300,
    fontSize: appTheme.fontSize.textInput,
  },
  none: {},
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: appTheme.colors.success,
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: appTheme.colors.error,
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderColor: appTheme.colors.error,
      borderWidth: 2,
      borderLeftWidth: 6,
      padding: "4px !important",
    },
  },
})(TextField);

interface DeleteDatabaseDialogProps {
  open: boolean;
  onClose: () => void;
  documentSummary: DocumentSummary;
}
export const DeleteDocumentDialog: FunctionComponent<DeleteDatabaseDialogProps> = ({
  open,
  onClose,
  documentSummary,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const databaseId = useSelector<RootState, string>(databaseIdSelector);
  const documentSummaries = useSelector<RootState, DocumentSummary[]>(
    userDocumentSummariesSelector
  );
  const selectedDocument: Document | undefined = useSelector<
    RootState,
    Document | undefined
  >((state) => {
    return cachedDocumentSelector(
      state,
      databaseId + ":" + documentSummary.documentId
    );
  });
  // Doesn't always seem to be loaded into document cache when trying to delete? Usually on first reload?
  const [confirmText, setConfirmText] = useState("");

  const incorrectDatabaseName: boolean = confirmText !== documentSummary.label;

  const dialogBody: ReactNode = (
    <div>
      <Typography>
        Doing so will permanently delete the data in this document!
      </Typography>
      {selectedDocument && selectedDocument.usedIn.length > 0 && (
        <Typography>
          This document can't be deleted! It is used in:{" "}
          {selectedDocument.usedIn.map((docId) => {
            return documentSummaries
              .map((summary) =>
                databaseId + ":" + summary.documentId === docId
                  ? summary.label + " "
                  : ""
              )
              .flat();
          })}
        </Typography>
      )}
      <div className={classes.flex}>
        {selectedDocument && selectedDocument.usedIn.length === 0 && (
          <Typography>
            Confirm you want to delete this document by typing its name:
          </Typography>
        )}
        {selectedDocument && selectedDocument.usedIn.length === 0 && (
          <Typography
            data-cy="deleteDatabaseName"
            className={classes.databaseName}
          >
            {documentSummary.label}
          </Typography>
        )}
      </div>
      {selectedDocument && selectedDocument.usedIn.length === 0 && (
        <ValidationTextField
          data-cy="deleteDatabaseNameInput"
          className={classes.confirmationInput}
          id="databaseToDelete"
          size="small"
          variant="outlined"
          placeholder={documentSummary.label}
          required
          error={incorrectDatabaseName}
          helperText={incorrectDatabaseName ? `Enter the document name` : ""}
          value={confirmText}
          onChange={(event) => setConfirmText(event.currentTarget.value)}
        />
      )}
    </div>
  );

  const onClickDelete = () => {
    onDeleteDocument();
    onClose();
  };

  const onDeleteDocument = () => {
      dispatch(deleteDocument(documentSummary.documentId));
  };

  const dialogActions: ReactNode = (
    <div>
      <Button color="default" onClick={onClose}>
        Cancel
      </Button>
      <Button
        data-cy="confirmDeleteDatabaseButton"
        className={incorrectDatabaseName ? classes.none : classes.deleteButton}
        disabled={incorrectDatabaseName}
        onClick={onClickDelete}
      >
        Delete
      </Button>
    </div>
  );
  return (
    <BaseDialog
      onClose={onClose}
      dataCy="deleteDatabaseDialog"
      open={open}
      title="Permanently delete this document?"
      body={dialogBody}
      action={dialogActions}
      maxWidth="md"
    ></BaseDialog>
  );
};

import {
  IActionsSetDisplayedNutrients,
  IActionsSetEnabledDatasources,
  IActionsSetEnabledMeasures,
  IActionSetEnabledNutrients,
  IActionSetIsAlternateIdsEnabled,
} from "../actions/databaseProperties";

export const setEnabledDatasources = (
  sources: string[]
): IActionsSetEnabledDatasources => {
  return {
    type: "SET_ENABLED_DATASOURCES",
    sources: sources,
  };
};

export const setDisplayedNutrients = (
  nutrientIds: string[]
): IActionsSetDisplayedNutrients => {
  return {
    type: "SET_DISPLAYED_NUTRIENTS",
    nutrientIds: nutrientIds,
  };
};

export const setEnabledMeasures = (
  enabledMeasures: string[]
): IActionsSetEnabledMeasures => {
  return {
    type: "SET_ENABLED_MEASURES",
    enabledMeasures: enabledMeasures,
  };
};

export const setEnabledNutrients = (
  enabledNutrients: string[]
): IActionSetEnabledNutrients => {
  return {
    type: "SET_ENABLED_NUTRIENTS",
    enabledNutrients: enabledNutrients,
  };
};

export const setIsAlternateIdsEnabled = (
  isAlternateIdsEnabled: boolean
): IActionSetIsAlternateIdsEnabled => {
  return {
    type: "SET_IS_ALTERNATE_IDS_ENABLED",
    isAlternateIdsEnabled,
  };
};

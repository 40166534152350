import React from "react";
import { Card, makeStyles, Typography } from "@material-ui/core";

import AddressInput from "./components/AddressInput";
import EmailInput from "./components/EmailInput";
import MobileInput from "./components/MobileInput";

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 25,
    padding: 6,
  },
  title: {
    marginBottom: 10,
  },
}));

const ClientContactDetails = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <Card className={classes.root}>
        <Typography variant="h3" className={classes.title}>
          Contact details
        </Typography>
        <EmailInput />
        <MobileInput />
        <AddressInput />
      </Card>
    </div>
  );
};

export default ClientContactDetails;

import {
  DELETED_DOCUMENTS_FILTER,
  FoodFilter,
  initialQuickFilterState,
} from "../../../../../../../../../constants/QuickFilter";
import { DocumentSummary } from "../../../../../../../../../data/models/userDatabase";
import { GridCellType } from "./AutoCompleteCell";
import { IngredientSummaryItem } from "./IngredientCell";
import { AUS_FOODS_ID } from "../../../../../../../../../constants/datasources";
import { NavigationSearchFilters } from "../../../../../../../navigation/hooks/useDocuments";
import { ClientSummary } from "../../../../../../../../../data/models/client";
import { Tag } from "../../../../../../../../../data/models/documentProperties/section";

const matchesFoodFilters = (
  foodFilter: FoodFilter[],
  option: FoodSummaryType
): boolean => {
  if (
    ("status" in option && option.status === "archived") ||
    ("status" in option && option.status === "deleted")
  ) {
    return foodFilter.includes(DELETED_DOCUMENTS_FILTER);
  }
  if (!foodFilter.length && "status" in option) {
    return option.status === "active";
  }
  if (
    ("state" in option && option.state === "archived") ||
    ("state" in option && option.state === "deleted")
  ) {
    return foodFilter.includes(DELETED_DOCUMENTS_FILTER);
  }
  if (!foodFilter.length && "state" in option) {
    return option.state === "active";
  }
  for (const quickFilter of foodFilter) {
    if (quickFilter.filter(option)) {
      return true;
    }
  }
  return false;
};

const matchesDocumentTags = (
  documentTags: Tag[],
  documentSummary: DocumentSummary
): boolean => {
  if (!documentTags.length) return true;

  for (const tag of documentTags) {
    if (documentSummary.documentTagIds.includes(tag.id)) {
      return true;
    }
  }

  return false;
};

const matchesClientTags = (
  clientTags: Tag[],
  clientSummary: ClientSummary
): boolean => {
  if (!clientTags.length) return true;

  for (const tag of clientTags) {
    if (clientSummary.tagIds.includes(tag.id)) {
      return true;
    }
  }
  return false;
};

export type FoodSummaryType = ClientSummary | DocumentSummary | GridCellType;

const isDocumentSummary = (object: any): object is DocumentSummary => {
  const summary: DocumentSummary = {
    documentId: object.documentId,
    label: object.label,
    templateId: object.templateId,
    status: object.status,
    searchableProperties: object.searchableProperties,
    sectionTags: object.sectionTags,
    lastModified: object.lastModified,
    documentTagIds: object.documentTagIds,
  };

  return (
    typeof summary.documentId === "string" &&
    typeof summary.label === "string" &&
    typeof summary.templateId === "string" &&
    typeof summary.status === "string" &&
    typeof summary.searchableProperties === "object" &&
    typeof summary.sectionTags === "object" &&
    typeof summary.lastModified === "string" &&
    typeof summary.documentTagIds === "object"
  );
};

const isClientSummary = (object: any): object is ClientSummary => {
  const summary: ClientSummary = {
    clientId: object.clientId,
    label: object.label,
    tagIds: object.tagIds,
    date: object.date,
    state: object.state,
  };

  return (
    typeof summary.clientId === "string" &&
    typeof summary.label === "string" &&
    typeof summary.tagIds === "object" &&
    typeof summary.state === "string"
  );
};

const generateSearchTerms = (searchText: string): string[] => {
  searchText = searchText.toLowerCase();

  let searchTerms: string[] = [];

  const splitOnSpaces = searchText.split(" ");

  for (const term of splitOnSpaces) {
    searchTerms = searchTerms.concat(term.split(","));
  }

  return searchTerms;
};

export const filterIngredients = (
  filter: NavigationSearchFilters,
  options: FoodSummaryType[],
  searchTermMap: Map<string, string[]>
): FoodSummaryType[] => {
  const searchTerms = generateSearchTerms(filter.searchText);
  return options.filter((option: FoodSummaryType) => {
    const { foodFilter, documentTags, clientTags } = filter.quickFilters;
    if (!matchesFoodFilters(foodFilter, option)) return false;

    if (isDocumentSummary(option)) {
      const documentSummary: DocumentSummary = option as DocumentSummary;
      if (!matchesDocumentTags(documentTags, documentSummary)) return false;
    } else if (isClientSummary(option)) {
      const clientSummary: ClientSummary = option as ClientSummary;
      if (!matchesClientTags(clientTags, clientSummary)) return false;
    }

    if (!filter.searchText) return true;
    if ("searchableProperties" in option) {
      if (
        option.searchableProperties.id1 === filter.searchText ||
        option.searchableProperties.id2 === filter.searchText
      ) {
        return true;
      }
    }
    let words = [...(searchTermMap.get(option.label) || [])];
    for (const term of searchTerms) {
      let matched = false;
      for (const word of [...words]) {
        if (word.startsWith(term)) {
          let index = words.findIndex((aWord) => word === aWord);
          words.splice(index, 1);
          matched = true;
          break;
        }
      }
      if (!matched) return false;
    }
    return true;
  });
};

export const createSearchMap = (
  summaries: IngredientSummaryItem[] | DocumentSummary[] | ClientSummary[]
) => {
  const optionToWords = new Map<string, string[]>();
  for (const summary of summaries) {
    const optionWords = summary.label.toLowerCase().split(",");
    let actualWords: string[] = [];

    for (const word of optionWords) {
      actualWords = actualWords
        .concat(...word.split(" "))
        .map((word: string) => word.replace(/[()]/g, ""));
    }
    optionToWords.set(summary.label, actualWords);
  }

  return optionToWords;
};

export const documentsSort = (
  searchText: string | undefined,
  searchTermMap: Map<string, string[]>
) => (a: FoodSummaryType, b: FoodSummaryType) => {
  // For now this is just doing any user documents, then aus foods, then all other public
  if ("foodId" in a && "foodId" in b) {
    const aIsAusFood = a.foodId.datasourceId === AUS_FOODS_ID;
    const bIsAusFood = b.foodId.datasourceId === AUS_FOODS_ID;

    if (!a.isPublic || !b.isPublic) {
      if (!a.isPublic && !b.isPublic) {
        return a.label > b.label ? 1 : -1;
      }
      return !a.isPublic ? -1 : 1;
    }

    if (aIsAusFood || bIsAusFood) {
      if (aIsAusFood && bIsAusFood) {
        let aMatches = false;
        let bMatches = false;

        for (const term of generateSearchTerms(searchText || "")) {
          if (!aMatches) {
            const aContainsSearchedKeyword = searchTermMap
              .get(a.label)!
              [KEYWORD_INDEX].startsWith(term);
            if (aContainsSearchedKeyword) aMatches = true;
          }

          if (!bMatches) {
            const bContainsSearchedKeyword = searchTermMap
              .get(b.label)!
              [KEYWORD_INDEX].startsWith(term);
            if (bContainsSearchedKeyword) bMatches = true;
          }
        }

        if (aMatches || bMatches) {
          if (aMatches && bMatches) return a.label > b.label ? 1 : -1;
          return aMatches ? -1 : 1;
        }

        return a.label > b.label ? 1 : -1;
      }
      return aIsAusFood ? -1 : 1;
    }

    return a.label > b.label ? 1 : -1;
  }

  return a.label > b.label ? 1 : -1;
};

const KEYWORD_INDEX = 0;

export const filterOptions = (
  options: GridCellType[],
  searchText: string | undefined,
  searchTermMap: Map<string, string[]>
): GridCellType[] => {
  const filtered = filterIngredients(
    { searchText: searchText || "", quickFilters: initialQuickFilterState },
    options,
    searchTermMap
  );

  return filtered.sort(
    documentsSort(searchText || "", searchTermMap)
  ) as IngredientSummaryItem[];
};

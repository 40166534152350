import React, { ReactNode, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@material-ui/core";

import { Tag } from "../../../../data/models/documentProperties/section";
import { appTheme } from "../../../../styling/style";
import { DisabledButtonWithToolTip } from "../../../common/InfoTooltip";
import { BaseDialog } from "../../BaseDialog";
import { SectionTagCreation } from "../../section_tags/components/SectionTagCreation";

interface CreateTagDialogProps {
  open: boolean;
  onClose: () => void;
  isEditing: boolean;
  tagId?: string;
  onCreateTag: (tag: Tag) => void;
  onUpdateTag: (tag: Tag) => void;
  tagSelector: Tag[];
  label: string;
}

export const CreateTagDialog = ({
  open,
  onClose,
  isEditing,
  tagId,
  onCreateTag,
  onUpdateTag,
  tagSelector,
  label,
}: CreateTagDialogProps): JSX.Element => {
  const [tagLabel, setTagLabel] = useState("");
  const [colorIndex, setColorIndex] = useState(0);
  const labelInputRef = useRef<HTMLInputElement>(null);

  const allTagLabels: string[] = tagSelector.map(
    (tag: Tag): string => tag.label
  );

  useEffect(() => {
    const tag: Tag | undefined = tagSelector.find(
      (tag: Tag) => tag.id === tagId
    );

    if (tag) {
      setTagLabel(tag.label);
      setColorIndex(appTheme.colors.activeTags.indexOf(tag.activeColor));
    }
  }, [tagId, tagSelector]);

  const onClickSaveTag = () => {
    if (isEditing) {
      onUpdateTag({
        id: tagId!,
        label: tagLabel,
        activeColor: appTheme.colors.activeTags[colorIndex],
        inactiveColor: appTheme.colors.inactiveTags[colorIndex],
      });
      onClose();
    } else {
      onCreateTag({
        id: uuidv4(),
        label: tagLabel,
        activeColor: appTheme.colors.activeTags[colorIndex],
        inactiveColor: appTheme.colors.inactiveTags[colorIndex],
      });
      labelInputRef.current?.focus();
      labelInputRef.current?.select();
    }
  };

  const body: ReactNode = (
    <SectionTagCreation
      tagLabel={tagLabel}
      colorIndex={colorIndex}
      setTagLabel={setTagLabel}
      setColorIndex={setColorIndex}
      ref={labelInputRef}
    />
  );

  const action: ReactNode = [
    <Button
      key="createTagDialog"
      onClick={onClose}
      color="default"
      data-cy={`create${label}TagClose`}
    >
      Close
    </Button>,
    <DisabledButtonWithToolTip
      key={`disabledButtonToolTip-${label}-${tagId}`}
      disabled={
        !tagLabel ||
        (!isEditing && allTagLabels.includes(tagLabel.trim())) ||
        (isEditing &&
          !!tagSelector.find(
            (tag: Tag): boolean =>
              tag.id !== tagId && tagLabel.trim() === tag.label
          ))
      }
      label="Save tag"
      disabledTooltip="Tag label must be unique"
      buttonProps={{ onClick: onClickSaveTag, color: "secondary" }}
      dataCy={`${label}SaveTagButton`}
    />,
  ];

  return (
    <BaseDialog
      dataCy={`create${label}TagDialog`}
      open={open}
      onClose={onClose}
      title={isEditing ? `Edit ${label} tag` : `Create a new ${label} tag`}
      body={body}
      action={action}
      maxWidth="md"
    />
  );
};

import { Section } from "../../../../../../../../data/models/documentProperties/section";
import { FoodItemPosition } from "../../../../../../../../data/models/foodItemPosition";

export const countSelectedRowsInSection = (
  dayIndex: number,
  section: Section,
  selectedRows: FoodItemPosition[]
): number =>
  selectedRows.filter(
    (position: FoodItemPosition): boolean =>
      dayIndex === position.day && section.index === position.section
  ).length;

export const countSelectedRowsInDay = (
  dayIndex: number,
  selectedRows: FoodItemPosition[]
): number =>
  selectedRows.filter(
    (position: FoodItemPosition): boolean => dayIndex === position.day
  ).length;

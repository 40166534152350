import { v4 as uuidv4 } from "uuid";

import { isRecipe } from "../../../../constants/FoodTemplate";
import {
  Section,
  SectionState,
} from "../../../../data/models/documentProperties/section";
import {
  ADD_FOOD_ITEM,
  CLEAR_FOOD_ITEM,
  CLEAR_QUANTITY,
  CLEAR_RETENTION_FACTOR,
  REMOVE_FOOD_ITEM,
  REMOVE_MULTIPLE_FOOD_ITEMS,
  UPDATE_FOOD_ITEM,
  UPDATE_NOTE,
  UPDATE_QUANTITY,
  UPDATE_RETENTION_FACTOR,
} from "../actions/foodItems";
import {
  ADD_MULTIPLE_SECTIONS,
  ADD_SECTION,
  ADD_SECTION_TAG,
  DELETE_SECTIONS,
  REMOVE_SECTION_TAG,
  SectionsActions,
  UPDATE_MULTIPLE_SECTIONS,
  UPDATE_SECTION,
  UPDATE_SECTION_IS_COLLAPSED,
  UPDATE_SECTION_TITLE,
} from "../actions/sections";
import { foodItems } from "./foodItems";

export const initialSectionsState: SectionState[] = [];

export const sections = (
  state: SectionState[] = initialSectionsState,
  action: SectionsActions
): SectionState[] => {
  switch (action.type) {
    case ADD_SECTION:
      return [...state, action.section.object].sort(
        (a: SectionState, b: SectionState) => a.index - b.index
      );
    case ADD_MULTIPLE_SECTIONS: {
      const sectionState: SectionState[] = [
        ...action.sections.map((section: Section) => section.object),
      ].sort((a: SectionState, b: SectionState) => a.index - b.index);
      return [...state.concat(sectionState)];
    }
    case UPDATE_SECTION:
      return state.map(
        (section: SectionState): SectionState =>
          section.index === action.section.index
            ? action.section.object
            : section
      );
    case UPDATE_MULTIPLE_SECTIONS: {
      const updatedSections: SectionState[] = [];
      for (const currentSection of state) {
        const foundSection = action.sections.find(
          (incomingSection: Section) =>
            incomingSection.index === currentSection.index
        );
        const section: SectionState = !foundSection
          ? currentSection
          : foundSection.object;
        updatedSections.push(section);
      }
      return updatedSections;
    }
    case UPDATE_SECTION_TITLE:
      return state.map(
        (section: SectionState): SectionState =>
          section.index === action.sectionIndex
            ? { ...section, title: action.title }
            : section
      );
    case DELETE_SECTIONS:
      const sections: SectionState[] = state
        .filter(
          (section: SectionState): boolean =>
            !action.sectionIndexes.includes(section.index)
        )
        .map(
          (section: SectionState, index: number): SectionState => {
            return { ...section, index: index };
          }
        );

      return sections.length || !isRecipe(action.templateId)
        ? sections
        : [
            {
              id: uuidv4(),
              index: 0,
              foodItems: [],
              title: "New section",
              isCollapsed: false,
              tags: [],
            },
          ];
    case UPDATE_SECTION_IS_COLLAPSED:
      return state.map(
        (section: SectionState): SectionState =>
          section.index === action.sectionIndex
            ? { ...section, isCollapsed: action.isCollapsed }
            : section
      );
    case ADD_SECTION_TAG:
      return state.map(
        (section: SectionState): SectionState =>
          section.index === action.sectionIndex
            ? { ...section, tags: [...section.tags, action.tagId] }
            : section
      );
    case REMOVE_SECTION_TAG:
      return state.map(
        (section: SectionState): SectionState =>
          section.index === action.sectionIndex
            ? {
                ...section,
                tags: section.tags.filter(
                  (tagId: string): boolean => tagId !== action.tagId
                ),
              }
            : section
      );
    case ADD_FOOD_ITEM:
    case REMOVE_FOOD_ITEM:
    case CLEAR_FOOD_ITEM:
    case UPDATE_FOOD_ITEM:
    case UPDATE_QUANTITY:
    case CLEAR_QUANTITY:
    case UPDATE_NOTE:
    case UPDATE_RETENTION_FACTOR:
    case CLEAR_RETENTION_FACTOR:
      return [
        ...state.map(
          (section: SectionState): SectionState =>
            section.index === action.sectionIndex
              ? { ...section, foodItems: foodItems(section.foodItems, action) }
              : { ...section }
        ),
      ];
    case REMOVE_MULTIPLE_FOOD_ITEMS:
    default:
      return state;
  }
};

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Sex } from "../../../../../../../data/models/nutrientReferenceValues";
import { setClientIsLactating } from "../../../../../../../store/data/current_client/action_creators/clientProfile";
import {
  clientIsLactatingSelector,
  clientSexSelector,
} from "../../../../../../../store/data/current_client/selectors/clientProfile";
import { RootState } from "../../../../../../../store/reducers";

const useClientIsLactating = (): [
  boolean,
  (input: boolean) => void,
  boolean
] => {
  const dispatch = useDispatch();

  const sex = useSelector<RootState, Sex>(clientSexSelector);

  const lactatingState: boolean = useSelector<RootState, boolean>(
    clientIsLactatingSelector
  );

  const isLactating = sex === "Female" && lactatingState;

  const onSetClientIsLactating = useCallback(
    (input: boolean) => dispatch(setClientIsLactating(input)),
    [dispatch]
  );

  return [isLactating, onSetClientIsLactating, sex === "Male"];
};

export default useClientIsLactating;

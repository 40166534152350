import React, { useState } from "react";
import { Button, Icon, makeStyles, Paper, Typography } from "@material-ui/core";
import { Add, ArrowDropDown } from "@material-ui/icons";
import _ from "lodash";
import { useDispatch } from "react-redux";

import { ReactComponent as FoodRecordIcon } from "../../../../../../images/icons/FoodRecord.svg";
import DocumentTable, { RowData, SortData } from "./DocumentTable";
import useDocumentData from "../hooks/useDocumentData";
import { FoodWorksTextInput } from "../../../../../common/FoodWorksTextInput";
import useSelectedClientDocuments from "../hooks/useSelectedClientDocuments";
import {
  FoodTemplate,
  FOOD_RECORDS,
  TWENTY_FOUR_HOUR_RECALL,
} from "../../../../../../constants/FoodTemplate";
import DocumentMenu from "../../../../../common/DocumentMenu";
import createNewDocument from "../../../../../../store/data/current-document/thunks/document/createNewDocument";
import { MEDIUM_FIELD } from "../../../../../../constants/textInputs";

const useStyles = makeStyles(() => ({
  tableRoot: {
    flex: 1,
    diplay: "flex",
    flexDirection: "column",
    padding: 10,
  },
  tableContainer: {
    marginTop: 10,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  searchContainer: {
    width: "60%",
    marginTop: 15,
  },
  headerText: {
    marginLeft: 10,
  },
}));

interface FoodRecordTableProps {
  stackTables: boolean;
}

const FoodRecordTable = React.memo<FoodRecordTableProps>(
  ({ stackTables }): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onCreateNewDocument = (template: FoodTemplate) =>
      dispatch(createNewDocument(template));

    const [searchText, setSearchText] = useState("");
    const [sortMethod, setSortMethod] = useState<SortData>({
      method: "DATE_RANGE",
      order: "desc",
    });
    const [docAnchor, setDocAnchor] = useState<null | HTMLElement>(null);

    const rows: RowData[] = useDocumentData(
      searchText,
      "Record",
      sortMethod.order,
      sortMethod.method
    );

    const [selectedDocuments, setSelectedDocuments]: [
      string[],
      (documents: string[]) => void
    ] = useSelectedClientDocuments();

    return (
      <div className={classes.tableRoot}>
        <div className={classes.header}>
          <Icon>
            <FoodRecordIcon />
          </Icon>
          <Typography className={classes.headerText} variant="h2">
            Food records/24 hour recalls
          </Typography>
        </div>
        <div className={classes.searchContainer}>
          <FoodWorksTextInput
            maxLength={MEDIUM_FIELD}
            placeholder="Search food records and 24 hour recalls"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.currentTarget.value);
              if (
                _.intersection(
                  selectedDocuments,
                  rows.map((row: RowData): string => row.documentId)
                ).length
              ) {
                setSelectedDocuments([]);
              }
            }}
          />
        </div>
        <div className={classes.tableContainer}>
          <Paper>
            <Button
              onClick={(event) => setDocAnchor(event.currentTarget)}
              startIcon={<Add color="secondary" />}
              endIcon={<ArrowDropDown color="secondary" />}
            >
              Create new
            </Button>
            <DocumentMenu
              handleClose={() => setDocAnchor(null)}
              anchorElement={docAnchor}
              onClick={(template: FoodTemplate) => {
                onCreateNewDocument(template);
                setDocAnchor(null);
              }}
              options={[TWENTY_FOUR_HOUR_RECALL, FOOD_RECORDS]}
            />
            <DocumentTable
              checkedItems={_.intersection(
                selectedDocuments,
                rows.map((row: RowData): string => row.documentId)
              )}
              setCheckedItems={setSelectedDocuments}
              rows={rows}
              sortMethod={sortMethod}
              setSortMethod={setSortMethod}
              stackTables={stackTables}
            />
          </Paper>
        </div>
      </div>
    );
  }
);

export default FoodRecordTable;

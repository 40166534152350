import { useSelector } from "react-redux";

import { DocumentSummary } from "../../../../../data/models/userDatabase";
import { userDocumentSummariesSelector } from "../../../../../store/data/selectors/database";
import { RootState } from "../../../../../store/reducers";

const useRecentDocuments = (numberToReturn: number): DocumentSummary[] => {
  const documentSummaries: DocumentSummary[] = useSelector<
    RootState,
    DocumentSummary[]
  >(userDocumentSummariesSelector);

  const sortedDocumentSummaries = documentSummaries
    .sort((a, b) =>
      b.lastModified < a.lastModified
        ? -1
        : b.lastModified > a.lastModified
        ? 1
        : 0
    )
    .filter((document) => document.status === "active");

  return sortedDocumentSummaries.length <= numberToReturn
    ? sortedDocumentSummaries
    : sortedDocumentSummaries.slice(numberToReturn);
};

export default useRecentDocuments;

import { RootState } from "../../../reducers";
import { CurrentDocumentState } from "../reducers/currentDocument";
import { Document, documentFromJson } from "../../../../data/models/document";
import { CompositionState } from "../../../../data/models/composition";

export const CurrentDocumentSelector = (
  state: RootState
): CurrentDocumentState => state.currentDocument;

export const CurrentDocumentIdSelector = (
  state: RootState
): CurrentDocumentState["id"] => state.currentDocument.id;

export const ServerDocumentSelector = (
  state: RootState
): Document | undefined =>
  state.currentDocument.serverDocument
    ? documentFromJson(state.currentDocument.serverDocument!)
    : undefined;

export const dayCountSelector = (state: RootState): number =>
  state.currentDocument.dayCount;

export const BaseCompositionSelector = (state: RootState): CompositionState =>
  state.currentDocument.baseComposition;

export const BaseCompositionValueSelector = (
  state: RootState,
  id: string
): number | undefined => state.currentDocument.baseComposition[id];

export const FinalCompositionSelector = (state: RootState): CompositionState =>
  state.currentDocument.finalComposition;

export const DocumentIsLoadingSelector = (state: RootState): boolean =>
  state.currentDocument.isLoading;

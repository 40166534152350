import { FoodItemPosition } from "../foodItemPosition";
import { FoodItem } from "./foodItem";
import { RetentionFactor } from "./retentionFactor";
import { Section, sectionConverter, Sections, SectionState } from "./section";

export class Day {
  id: string;
  index: number;
  sections: Sections;
  title: string;
  date: string;

  constructor(
    id: string,
    index: number,
    sections: Sections,
    title: string,
    date: string
  ) {
    this.id = id;
    this.index = index;
    this.sections = sections;
    this.title = title;
    this.date = date;
  }

  get object(): DayState {
    const sectionsState: SectionState[] = this.sections.items.map(
      (section: Section): SectionState => section.object
    );

    return {
      id: this.id,
      index: this.index,
      title: this.title,
      sections: sectionsState,
      date: this.date,
    };
  }

  get foodItemCount(): number {
    let count: number = 0;
    for (const section of this.sections.items) {
      count += section.foodItems.length;
    }
    return count;
  }

  static fromObject = (
    dayState: DayState,
    retentionFactorMap: Map<string, RetentionFactor>
  ) => {
    const sections: Sections = new Sections(
      dayState.sections,
      retentionFactorMap
    );
    return new Day(
      dayState.id,
      dayState.index,
      sections,
      dayState.title,
      dayState.date
    );
  };
}

export class Days {
  days: Day[];

  constructor(
    daysState: DayState[],
    retentionFactorMap: Map<string, RetentionFactor>
  ) {
    this.days = daysState.map(
      (dayState: DayState): Day => Day.fromObject(dayState, retentionFactorMap)
    );
  }

  getSectionsWithTag = (
    tagIds: string[]
  ): { day: number; section: number }[] => {
    if (!tagIds.length) return [];
    let rowsToSelect: { day: number; section: number }[] = [];
    for (const day of this.days) {
      for (const section of day.sections.items) {
        if (section.tags.some((tag: string) => tagIds.indexOf(tag) >= 0)) {
          rowsToSelect = rowsToSelect.concat({
            day: day.index,
            section: section.index,
          });
        }
      }
    }
    return rowsToSelect;
  };

  getSection = (dayIndex: number, sectionIndex: number): Section | undefined =>
    this.days
      .find((day: Day): boolean => day.index === dayIndex)
      ?.sections.items.find(
        (section: Section): boolean => section.index === sectionIndex
      );

  getTaggedRowPositions = (tagIds: string[]): FoodItemPosition[] => {
    if (!tagIds.length) return [];
    let rowsToSelect: FoodItemPosition[] = [];
    for (const day of this.days) {
      for (const section of day.sections.items) {
        if (section.tags.some((tag: string) => tagIds.indexOf(tag) >= 0)) {
          rowsToSelect = rowsToSelect.concat(
            section.foodItems.items.map(
              (item: FoodItem): FoodItemPosition =>
                new FoodItemPosition(day.index, section.index, item.rowIndex)
            )
          );
        }
      }
    }
    return rowsToSelect;
  };
}

export interface DayState {
  id: string;
  index: number;
  sections: SectionState[];
  title: string;
  date: string;
}

export const dayConverter = {
  toFirestore: function (day: DayState): firebase.firestore.DocumentData {
    const sections = day.sections.map(
      (sectionState: SectionState): firebase.firestore.DocumentData =>
        sectionConverter.toFirestore(sectionState)
    );
    return {
      id: day.id,
      index: day.index,
      sections: sections,
      title: day.title,
      date: day.date,
    };
  },
  fromFirestore: function (data: firebase.firestore.DocumentData): DayState {
    const sections = data.sections.map(
      (sectionData: firebase.firestore.DocumentData): SectionState =>
        sectionConverter.fromFirestore(sectionData)
    );
    return {
      id: data.id,
      index: data.index,
      sections: sections,
      title: data.title,
      date: data.date || "",
    };
  },
};

export const daysConverter = {
  toFirestore: function (days: DayState[]): firebase.firestore.DocumentData {
    return days.map(
      (dayState: DayState): firebase.firestore.DocumentData =>
        dayConverter.toFirestore(dayState)
    );
  },
  fromFirestore: function (
    daysData: firebase.firestore.DocumentData
  ): DayState[] {
    return daysData.map(
      (dayData: firebase.firestore.DocumentData): DayState =>
        dayConverter.fromFirestore(dayData)
    );
  },
};

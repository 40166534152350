import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import {
  AccordionItem,
  AccordionList,
  AccordionProps,
} from "../../../common/AccordionList";
import Barchart from "./IngredientSources/components/Barchart";
import { BarProps } from "./IngredientSources/components/Bar";
import {
  NRVType,
  nrvTypeToFullTitle,
} from "../../../../data/models/nutrientReferenceValues";
import { Nutrient } from "../../../../data/models/nutrient";
import { appTheme } from "../../../../styling/style";
import { Composition } from "../../../../data/models/composition";
import { ClientGoal } from "../../../../data/models/clientProperties/clientGoal";
import useIntakeGoals from "../../clients/tabs/analysis/hooks/useIntakeGoals";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    flex: 1,
  },
  error: {
    padding: 20,
  },
  errorAction: {
    marginTop: 5,
  },
}));

const getAccordionBarchart = (
  nutrientMap: Map<string, number>,
  allNutrientsMap: Map<string, Nutrient>,
  composition: Composition | undefined
): AccordionItem[] => {
  const data: BarProps[] = [...nutrientMap]
    .sort(([aID, aValue], [bID, bValue]) => {
      if (
        allNutrientsMap.get(aID)!.displayOrder ===
        allNutrientsMap.get(bID)!.displayOrder
      )
        return 0;
      return allNutrientsMap.get(aID)!.displayOrder >
        allNutrientsMap.get(bID)!.displayOrder
        ? 1
        : -1;
    })
    .map(([id, value]: [string, number]) => {
      let nutrientValue = composition?.getNutrientValue(id);
      if (!nutrientValue) nutrientValue = 0;
      const percentage =
        (nutrientValue / value) * 100 !== Infinity
          ? (nutrientValue / value) * 100
          : 0;

      return {
        label: allNutrientsMap.get(id) ? allNutrientsMap.get(id)!.name : "",
        percentage: percentage.toFixed(0) + "%",
        value: "",
        barWidth: "20px",
        barColor:
          percentage < 100
            ? appTheme.colors.barGreen
            : appTheme.colors.barPurple,
      };
    });

  return [
    {
      id: "Test",
      item: <Barchart data={data} />,
    },
  ];
};

const getAccordionCategories = (
  shown: Map<AccordionIndex, boolean>,
  NRVMap: Map<NRVType, Map<string, number>>,
  nutrientsMap: Map<string, Nutrient>,
  composition: Composition | undefined,
  onClickAccordion: (id: AccordionIndex) => void
): AccordionProps[] =>
  [...NRVMap].map(([NRVType, nutrientIdMap], index) => {
    const temp: AccordionProps = {
      id: index,
      header: nrvTypeToFullTitle(NRVType as string),
      items: getAccordionBarchart(nutrientIdMap, nutrientsMap, composition),
      open: shown.get(index.toString() as AccordionIndex)!,
      onClick: (id: string) => onClickAccordion(id as AccordionIndex),
    };
    return temp;
  });

interface NRVAnalysisProps {
  toggle: boolean;
}

type AccordionIndex = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7";

const NRVAnalysis = React.memo((props: NRVAnalysisProps) => {
  const classes = useStyles();

  const { toggle } = { ...props };

  const [NRVMap, composition, nutrientsMap]: [
    Map<NRVType, Map<string, number>>,
    Composition | undefined,
    Map<string, Nutrient>,
    ClientGoal[]
  ] = useIntakeGoals();

  const show = Boolean(NRVMap.size);
  const [categoryAccordionState, setCategoryAccordionState] = useState<
    Map<AccordionIndex, boolean>
  >(
    new Map([
      ["0", false],
      ["1", false],
      ["2", false],
      ["3", false],
      ["4", false],
      ["5", false],
      ["6", false],
      ["7", false],
    ])
  );

  const onCategoryTappedCallback = useCallback(
    (id: AccordionIndex) =>
      setCategoryAccordionState(
        new Map(
          [...categoryAccordionState].map(
            ([catId, value]: [AccordionIndex, boolean]): [
              AccordionIndex,
              boolean
            ] => (id === catId ? [catId, !value] : [catId, value])
          )
        )
      ),
    [categoryAccordionState]
  );

  const getAccordionCategoriesMemo = useMemo(
    () =>
      getAccordionCategories(
        categoryAccordionState,
        NRVMap,
        nutrientsMap,
        composition,
        onCategoryTappedCallback
      ),
    [
      categoryAccordionState,
      NRVMap,
      nutrientsMap,
      composition,
      onCategoryTappedCallback,
    ]
  );

  return (
    <div hidden={toggle} className={classes.root}>
      {show && <AccordionList values={getAccordionCategoriesMemo} />}
      {!show && (
        <div className={classes.error}>
          <Alert severity="error">Error calculating nutrition graphs.</Alert>
          <Alert className={classes.errorAction} severity="error">
            Make sure all required client profile information is completed.
          </Alert>
        </div>
      )}
    </div>
  );
});

export default NRVAnalysis;

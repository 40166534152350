import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../store/reducers";
import { toggleQuickFilters } from "../../../../store/ui/actionCreators/navigationActionCreators";

const useToggleDocumentFilter = (): [boolean, () => void] => {
  const dispatch = useDispatch();

  const toggleQuickFiltersEnabled = useCallback(
    () => dispatch(toggleQuickFilters()),
    [dispatch]
  );

  const quickFiltersEnabled: boolean = useSelector<RootState, boolean>(
    (state) => state.navigation.quickFiltersEnabled
  );

  return [quickFiltersEnabled, toggleQuickFiltersEnabled];
};

export default useToggleDocumentFilter;

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import { FoodWorksTextInput } from "../../../../../common/FoodWorksTextInput";
import useClientNotes from "../hooks/useClientNotes";
import { LARGE_FIELD } from "../../../../../../constants/textInputs";

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 25,
    marginTop: 10,
  },
  input: {
    padding: 0,
  },
}));

const NotesInput = React.memo(
  (): JSX.Element => {
    const classes = useStyles();
    const [notes, setNotes, updateNotes] = useClientNotes();

    return (
      <div className={classes.root}>
        <Typography>Notes</Typography>
        <FoodWorksTextInput
          maxLength={LARGE_FIELD}
          className={classes.input}
          value={notes}
          onChange={(event) => setNotes(event.currentTarget.value)}
          onBlur={updateNotes}
          multiline
          rows={4}
        />
      </div>
    );
  }
);

export default NotesInput;

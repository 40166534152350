import { useSelector } from "react-redux";

import { ClientSummary } from "../../../../data/models/client";
import { DocumentStatus } from "../../../../data/models/userDatabase";
import { clientSummariesSelector } from "../../../../store/data/selectors/clientDatabase";
import { RootState } from "../../../../store/reducers";

export function searchClients(
  clients: ClientSummary[],
  filters: DocumentStatus[],
  searchText: string
): ClientSummary[] {
  if (!filters.length) {
    return clients
      .filter((client) => client.state === "active")
      .filter((client) =>
        searchText.toLowerCase().search(`/${client.label.toLowerCase()}/ig`)
      );
  } else {
    return clients
      .filter((client) => filters.includes(client.state))
      .filter((client) =>
        searchText.toLowerCase().search(`/${client.label.toLowerCase()}/ig`)
      );
  }
}

const useClients = (
  searchText: string,
  filters: DocumentStatus[]
): [ClientSummary[], boolean, boolean] => {
  const clientSummaries: ClientSummary[] = useSelector<
    RootState,
    ClientSummary[]
  >(clientSummariesSelector);

  return [
    searchClients(clientSummaries, filters, searchText),
    //True/False are temporary booleans to represent "deleted filter selection" and "any non-deleted clients"
    true,
    false,
  ];
};

export default useClients;

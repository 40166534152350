import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  List,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import { withFirebase } from "../../../../data/Firebase";
import Firebase, { FirebaseProps } from "../../../../data/Firebase/firebase";
import { Tag } from "../../../../data/models/documentProperties/section";
import {
  Database,
  UserDatabaseSummary,
} from "../../../../data/models/userDatabase";
import {
  databaseDocumentTagsSelector,
  databaseIdSelector,
} from "../../../../store/data/selectors/database";
import { sortedUserDatabaseSummariesSelector } from "../../../../store/data/selectors/user";
import { updateDatabaseDocumentTags } from "../../../../store/data/thunks/database";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import { BaseDialog } from "../../BaseDialog";
import { clientUserDatabaseSelector } from "../../../../store/data/selectors/clientDatabase";

const useStyles = makeStyles((theme) => ({
  listButton: {
    borderRadius: 4,
    marginTop: 2,
    marginBottom: 2,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
  },
  selectedListButton: {
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
  },
  sectionsList: {
    height: 300,
    overflowY: "auto",
  },
  sectionTagListContainer: {
    marginTop: 10,
  },
  colorButton: {
    height: 10,
    width: 10,
    minWidth: 10,
    marginRight: 5,
    borderRadius: 4,
  },
  tagListContainer: {
    marginTop: 10,
  },
  tagMenuLabel: {
    display: "flex",
    alignItems: "center",
  },
  importButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  importContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  card: {
    marginTop: 5,
  },
  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeIcon: {
    width: 40,
    height: 40,
  },
}));

const useUserDatabaseDocumentTags = (
  firebase: Firebase
): Map<string, Tag[]> => {
  const [documentTagMap, setDocumentTagMap] = useState<Map<string, Tag[]>>(
    new Map()
  );

  const getUserDatabases = useCallback(
    async (): Promise<Database[]> =>
      await firebase.getAllUserDatabases(firebase.auth.currentUser!.uid),
    [firebase]
  );

  const getUserDocumentTags = useCallback(async (): Promise<
    Map<string, Tag[]>
  > => {
    const newDocumentTagMap = new Map<string, Tag[]>();

    try {
      const userDatabases: Database[] = await getUserDatabases();
      userDatabases.forEach((database: Database) => {
        newDocumentTagMap.set(database.summary.id, database.documentTags);
      });
      return newDocumentTagMap;
    } catch (err) {
      // could not fetch from user database
      return newDocumentTagMap;
    }
  }, [getUserDatabases]);

  useEffect(() => {
    getUserDocumentTags().then((result) => setDocumentTagMap(result));
  }, [getUserDatabases, getUserDocumentTags]);

  return documentTagMap;
};

interface ImportDocumentTagDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ImportDocumentTagDialogInner = ({
  open,
  onClose,
  firebase,
}: ImportDocumentTagDialogProps & FirebaseProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentDatabaseId = useSelector<RootState, string>(databaseIdSelector);

  const userDatabaseSummaries: UserDatabaseSummary[] = useSelector<
    RootState,
    UserDatabaseSummary[]
  >(sortedUserDatabaseSummariesSelector);
  const databaseDocumentTags: Tag[] = useSelector<RootState, Tag[]>(
    databaseDocumentTagsSelector
  );
  const clientDatabaseId = useSelector<RootState, string>(
    clientUserDatabaseSelector
  );

  const [currentDocumentTags, setCurrentDocumentTags] =
    useState<Tag[]>(databaseDocumentTags);
  const [selectedDatabases, setSelectedDatabases] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const userDatabaseDocumentTags: Map<string, Tag[]> =
    useUserDatabaseDocumentTags(firebase!);

  const onImportTags = () => {
    const newTags = selectedTags.map(
      (tag: Tag): Tag => ({ ...tag, id: uuidv4() })
    );
    const updatedTags = currentDocumentTags.concat(newTags);
    setCurrentDocumentTags(updatedTags);
  };

  const confirmChanges = () => {
    dispatch(updateDatabaseDocumentTags(currentDocumentTags));
    setSelectedTags([]);
  };

  const currentDocumentTagList: ReactNode = (
    <div className={classes.tagListContainer}>
      <Typography variant="body1">Current database's document tags</Typography>
      <Card className={classes.card}>
        <List className={classes.sectionsList} data-cy="currentDocumentTagList">
          {currentDocumentTags.map(
            (tag: Tag): ReactNode => (
              <MenuItem key={tag.id} className={classes.listButton}>
                <div className={classes.tagMenuLabel}>
                  <div
                    className={classes.colorButton}
                    style={{ backgroundColor: tag.activeColor }}
                  ></div>
                  {tag.label}
                </div>
              </MenuItem>
            )
          )}
        </List>
      </Card>
    </div>
  );

  const onSelectTag = (selectedTag: Tag) => {
    if (selectedTags.some((tag: Tag) => tag.id === selectedTag.id)) {
      const updatedTags = selectedTags.filter(
        (tag: Tag) => tag.id !== selectedTag.id
      );
      setSelectedTags(updatedTags);
    } else {
      setSelectedTags([...selectedTags, selectedTag]);
    }
  };

  const isTagSelected = (tagId: string) =>
    selectedTags.some((tag: Tag) => tag.id === tagId);

  const isTagSelectable = (selectedTag: Tag) => {
    const isUniqueLabel: boolean = currentDocumentTags.some(
      (tag: Tag) => tag.label === selectedTag.label
    );
    const selectedNewNameAndTag: boolean = selectedTags.some(
      (tag: Tag) => tag.label === selectedTag.label && tag.id !== selectedTag.id
    );
    return isUniqueLabel || selectedNewNameAndTag;
  };

  const tagItems = (tags: Tag[]): ReactNode =>
    tags.map(
      (tag: Tag): ReactNode => (
        <MenuItem
          disabled={isTagSelectable(tag)}
          className={
            isTagSelected(tag.id)
              ? `${classes.listButton} ${classes.selectedListButton}`
              : classes.listButton
          }
          onClick={() => onSelectTag(tag)}
        >
          <div className={classes.tagMenuLabel}>
            <div
              className={classes.colorButton}
              style={{ backgroundColor: tag.activeColor }}
            ></div>
            {tag.label}
          </div>
        </MenuItem>
      )
    );

  const tagsToImport: ReactNode = (
    <div className={classes.tagListContainer} data-cy="tagsToImport">
      <Typography variant="body1">Select document tags to import</Typography>
      <Card className={classes.card}>
        <List className={classes.sectionsList}>
          {[...userDatabaseDocumentTags].map(
            ([databaseId, tags]: [string, Tag[]]): ReactNode =>
              selectedDatabases.includes(databaseId) && tagItems(tags)
          )}
        </List>
      </Card>
    </div>
  );

  const onSelectDatabase = (databaseId: string) => {
    if (selectedDatabases.includes(databaseId)) {
      const newIds: string[] = selectedDatabases.filter(
        (id) => id !== databaseId
      );
      setSelectedDatabases(newIds);

      const thisDatabaseTags: Tag[] | undefined =
        userDatabaseDocumentTags.get(databaseId);
      if (thisDatabaseTags) {
        const newTagIds: Tag[] = selectedTags.filter(
          (tag: Tag) =>
            !thisDatabaseTags.some((thisTag: Tag) => thisTag.id === tag.id)
        );
        setSelectedTags(newTagIds);
      }
    } else {
      const newIds: string[] = [...selectedDatabases, databaseId];
      setSelectedDatabases(newIds);
    }
  };

  const userDatabases: ReactNode = (
    <Card className={classes.card} data-cy="userDatabaseCard">
      <List className={classes.sectionsList}>
        {userDatabaseSummaries.map(
          (summary: UserDatabaseSummary): ReactNode =>
            summary.id !== currentDatabaseId &&
            summary.id !== clientDatabaseId && (
              <MenuItem
                key={summary.id}
                className={
                  selectedDatabases.includes(summary.id)
                    ? `${classes.listButton} ${classes.selectedListButton}`
                    : classes.listButton
                }
                onClick={() => onSelectDatabase(summary.id)}
              >
                {summary.name}
              </MenuItem>
            )
        )}
      </List>
    </Card>
  );

  const body: ReactNode = (
    <div>
      <Typography variant="body1">
        Select one or more databases to import from
      </Typography>
      {userDatabases}
      <div className={classes.importContainer}>
        {tagsToImport}
        <div
          className={classes.importButtonContainer}
          data-cy="importTagButtonContainer"
        >
          <Button
            color="secondary"
            disabled={!selectedTags.length}
            onClick={onImportTags}
            endIcon={<ArrowForward color="inherit" />}
          >
            Import
          </Button>
        </div>
        {currentDocumentTagList}
      </div>
    </div>
  );

  const action: ReactNode = [
    <Button data-cy="importTagClose" onClick={onClose}>
      Cancel
    </Button>,
    <Button
      disabled={_.isEqual(currentDocumentTags, databaseDocumentTags)}
      color="secondary"
      onClick={confirmChanges}
    >
      Confirm
    </Button>,
  ];

  return (
    <BaseDialog
      dataCy="importDocumentTagDialog"
      open={open}
      title=""
      body={body}
      action={action}
      maxWidth="sm"
      onClose={onClose}
    />
  );
};

const ImportDocumentTagDialog = withFirebase(ImportDocumentTagDialogInner);

export default ImportDocumentTagDialog;

import { IActionsSetRecentMappedDocumentId } from "../actions/overridesScreen";

export const setRecentMappedDocumentId = (
  id: string
): IActionsSetRecentMappedDocumentId => {
  return {
    type: "SET_RECENT_MAPPED_DOCUMENT_ID",
    id: id,
  };
};

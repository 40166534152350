import { useMemo } from "react";
import { useSelector } from "react-redux";

import { NRVType } from "../../../../../../data/models/nutrientReferenceValues";
import { nutrientsPerNRVSelector } from "../../../../../../store/data/selectors/clientDatabase";
import { Nutrient } from "../../../../../../data/models/nutrient";
import {
  getRetentionFactorMap,
  NutrientsDataSelector,
  ReferenceMeasuresSelector,
} from "../../../../../../store/data/selectors/referenceData";
import { databaseIdSelector } from "../../../../../../store/data/selectors/database";
import { compositionCacheSelector } from "../../../../../../store/data/selectors/compositionCache";
import { getIdentifier } from "../../../../../../data/models/documentProperties/foodId";
import { clientGoalSelector } from "../../../../../../store/data/current_client/selectors/client";
import { ClientGoal } from "../../../../../../data/models/clientProperties/clientGoal";
import {
  CurrentDocumentSelector,
  FinalCompositionSelector,
} from "../../../../../../store/data/current-document/selectors/currentDocument";
import {
  Composition,
  CompositionCache,
  CompositionState,
} from "../../../../../../data/models/composition";
import { RootState } from "../../../../../../store/reducers";
import { allCachedDocumentsSelector } from "../../../../../../store/data/selectors/documentCache";
import { DocumentMap } from "../../../../../../store/data/reducers/documentCache";
import { ReferenceMeasure } from "../../../../../../data/models/referenceMeasure";
import { RetentionFactor } from "../../../../../../data/models/documentProperties/retentionFactor";
import { CompositionCalculator } from "../../../../../../data/models/compositionCalculator";
import { Document } from "../../../../../../data/models/document";
import useSelectedClientDocuments from "../../documents/hooks/useSelectedClientDocuments";
import { CurrentDocumentState } from "../../../../../../store/data/current-document/reducers/currentDocument";

export const useIntakeGoals = (
  docId?: string | undefined
): [
  Map<NRVType, Map<string, number>>,
  Composition | undefined,
  Map<string, Nutrient>,
  ClientGoal[]
] => {
  const NRVMap: Map<NRVType, Map<string, number>> = useSelector<
    RootState,
    Map<NRVType, Map<string, number>>
  >(nutrientsPerNRVSelector);

  const nutrientsMap: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(NutrientsDataSelector);

  const currentDatabaseId: string = useSelector<RootState, string>(
    databaseIdSelector
  );

  const [selectedDocuments] = useSelectedClientDocuments();

  const finalCompositionCache: CompositionState = useSelector<
    RootState,
    CompositionState
  >(FinalCompositionSelector);
  const finalComposition = new Composition(finalCompositionCache);

  const compositionCache: CompositionCache = useSelector<
    RootState,
    CompositionCache
  >(compositionCacheSelector);

  const documentCache = useSelector<RootState, DocumentMap>(
    allCachedDocumentsSelector
  );

  const referenceMeasures = useSelector<RootState, ReferenceMeasure[]>(
    ReferenceMeasuresSelector
  );

  const retentionFactorMap = useSelector<
    RootState,
    Map<string, RetentionFactor>
  >(getRetentionFactorMap);

  const goals: ClientGoal[] = useSelector<RootState, ClientGoal[]>(
    clientGoalSelector
  );

  const selectedDocCompositions: Composition[] = useMemo(
    () =>
      selectedDocuments.map(documentId => {
        const compositionCalculator = new CompositionCalculator(
          compositionCache,
          documentCache,
          referenceMeasures,
          retentionFactorMap
        );
        const document: Document =
          documentCache[getIdentifier(currentDatabaseId, documentId)];

        const composition = compositionCalculator.calculateComposition(
          document,
          compositionCalculator.getValidFoodItems(document, [])
        );
        const days = document.days.length;
        return composition.multiplyByFactor(1 / days);
      }),
    [
      compositionCache,
      currentDatabaseId,
      documentCache,
      referenceMeasures,
      retentionFactorMap,
      selectedDocuments,
    ]
  );

  const totalSelectedComposition = useMemo(
    () =>
      selectedDocCompositions
        .slice(1, selectedDocCompositions.length)
        .reduce(
          (prevComp, currComp) => prevComp.addComposition(currComp),
          selectedDocCompositions[0]
        ),
    [selectedDocCompositions]
  );

  const composition: Composition | undefined = useMemo(() => {
    const compositionCalculator = new CompositionCalculator(
      compositionCache,
      documentCache,
      referenceMeasures,
      retentionFactorMap
    );
    const document: Document =
      documentCache[getIdentifier(currentDatabaseId, docId ? docId : "")];
    if (!document) {
      return undefined;
    }
    const composition = compositionCalculator.calculateComposition(
      document,
      compositionCalculator.getValidFoodItems(document, [])
    );
    const days = document.days.length;
    return composition.multiplyByFactor(1 / days);
  }, [
    currentDatabaseId,
    docId,
    compositionCache,
    documentCache,
    referenceMeasures,
    retentionFactorMap,
  ]);
  const currentDocument: CurrentDocumentState = useSelector<
    RootState,
    CurrentDocumentState
  >(CurrentDocumentSelector);
  const documentValid = currentDocument.id !== "";

  return [
    NRVMap,
    docId
      ? composition
      : documentValid
      ? finalComposition
      : totalSelectedComposition,
    nutrientsMap,
    goals,
  ];
};

export default useIntakeGoals;

import React, { FunctionComponent, ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";

export interface DialogOption {
  text: string;
  onClick: () => void;
  color: string;
}

export interface FoodWorksDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: ReactNode;
  options: DialogOption[];
}

export const FoodWorksDialog: FunctionComponent<FoodWorksDialogProps> = ({
  open,
  onClose,
  title,
  description,
  options,
}) => {
  return (
    <Dialog open={open} onClose={onClose} data-cy="foodworksDialog">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        <Typography component="div" variant="body1" data-cy="dialogDescription">
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        {options.map((option: DialogOption) => (
          <Button
            data-cy="dialogButton"
            key={option.text}
            onClick={option.onClick}
            style={{ color: option.color }}
          >
            {option.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

import {
  ActivityLevel,
  PregnancyLevel,
  Sex,
} from "../../../../data/models/nutrientReferenceValues";
import { RootState } from "../../../reducers";

export const clientAgeSelector = (state: RootState): number | undefined =>
  state.clientDatabase.currentClient.profile.age;

export const clientDOBSelector = (state: RootState): string | undefined =>
  state.clientDatabase.currentClient.profile.dob;

export const clientSexSelector = (state: RootState): Sex =>
  state.clientDatabase.currentClient.profile.sex;

export const clientCurrentWeightSelector = (
  state: RootState
): number | undefined =>
  state.clientDatabase.currentClient.profile.weight;

export const clientHeightSelector = (state: RootState): number | undefined =>
  state.clientDatabase.currentClient.profile.height;

export const clientActivityLevelSelector = (state: RootState): ActivityLevel =>
  state.clientDatabase.currentClient.profile.activityLevel;

export const clientPregnancyLevelSelector = (
  state: RootState
): PregnancyLevel => state.clientDatabase.currentClient.profile.pregnancyLevel;

export const clientIsLactatingSelector = (state: RootState): boolean =>
  state.clientDatabase.currentClient.profile.isLactating;

export const clientRestingMetabolicRateSelector = (
  state: RootState
): number | undefined =>
  state.clientDatabase.currentClient.profile.restingMetabolicRate;

export const clientIsUseCER = (state: RootState): boolean =>
  state.clientDatabase.currentClient.profile.useCER;

import { useSelector } from "react-redux";

import {
  Composition,
  CompositionCache,
} from "../../../../../data/models/composition";
import { CompositionCalculator } from "../../../../../data/models/compositionCalculator";
import { Document } from "../../../../../data/models/document";
import { getIdentifier } from "../../../../../data/models/documentProperties/foodId";
import { RetentionFactor } from "../../../../../data/models/documentProperties/retentionFactor";
import {
  NutrientValue,
  NutrientValues,
} from "../../../../../data/models/nutrientValue";
import { ReferenceMeasure } from "../../../../../data/models/referenceMeasure";
import { DocumentMap } from "../../../../../store/data/reducers/documentCache";
import { compositionCacheSelector } from "../../../../../store/data/selectors/compositionCache";
import { databaseIdSelector } from "../../../../../store/data/selectors/database";
import { allCachedDocumentsSelector } from "../../../../../store/data/selectors/documentCache";
import {
  getRetentionFactorMap,
  ReferenceMeasuresSelector,
} from "../../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../../store/reducers";
import { NutritionRadioOption } from "../../../../../store/ui/reducers/nutritionPaneReducers";
import { selectedClientDocumentsSelector } from "../../../../../store/ui/selectors/clientScreen";
import { compositionOptionSelector } from "../../../../../store/ui/selectors/nutritionPaneSelectors";

const applyCompositionOption = (
  option: NutritionRadioOption,
  composition: Composition,
  dayCount: number,
  compositionCalculator: CompositionCalculator
): Composition => {
  let weightFactor: number = 1;

  if (option === NutritionRadioOption.MEGAJOULE) {
    weightFactor = 1000 / composition.energy;
    return compositionCalculator.applyFinalWeightToComposition(
      composition,
      weightFactor
    );
  }
  if (option === NutritionRadioOption.DAY) {
    weightFactor = 1 / dayCount;
    return compositionCalculator.applyFinalWeightToComposition(
      composition,
      weightFactor
    );
  }

  return composition;
};

const useNutrientValues = (): NutrientValues => {
  const selectedClientDocuments: string[] = useSelector<RootState, string[]>(
    selectedClientDocumentsSelector
  );

  const currentDatabaseId: string = useSelector<RootState, string>(
    databaseIdSelector
  );

  const selectedCompositionOption: NutritionRadioOption = useSelector<
    RootState,
    NutritionRadioOption
  >(compositionOptionSelector);

  const documentCache: DocumentMap = useSelector<RootState, DocumentMap>(
    allCachedDocumentsSelector
  );

  const compositionCache: CompositionCache = useSelector<
    RootState,
    CompositionCache
  >(compositionCacheSelector);

  const referenceMeasures: ReferenceMeasure[] = useSelector<
    RootState,
    ReferenceMeasure[]
  >(ReferenceMeasuresSelector);

  const retentionFactorMap: Map<string, RetentionFactor> = useSelector<
    RootState,
    Map<string, RetentionFactor>
  >(getRetentionFactorMap);

  const compositionCalculator = new CompositionCalculator(
    compositionCache,
    documentCache,
    referenceMeasures,
    retentionFactorMap
  );

  let totalComposition: Composition = new Composition({});
  let totalDayCount: number = 0;

  for (const doc of selectedClientDocuments) {
    const document: Document | undefined =
      documentCache[getIdentifier(currentDatabaseId, doc)];

    const newComposition = compositionCalculator.calculateCompositionFromFoodItems(
      compositionCalculator.getValidFoodItems(document, [])
    );

    totalComposition = totalComposition.addComposition(newComposition);
    totalDayCount = totalDayCount + document.days.length;
  }

  totalComposition = applyCompositionOption(
    selectedCompositionOption,
    totalComposition,
    totalDayCount,
    compositionCalculator
  );

  const nutrientValues = new NutrientValues(
    new Map(
      [
        ...totalComposition.nutrientValues,
      ].map(([id, value]: [string, number]): [string, NutrientValue] => [
        id,
        new NutrientValue(3, 1, value),
      ])
    )
  );

  return nutrientValues;
};

export default useNutrientValues;

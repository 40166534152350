import React, { useEffect, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { batch, useSelector } from "react-redux";

import { appTheme } from "../../../styling/style";
import { BaseDialog } from "../../dialogs/BaseDialog";
import { RootState } from "../../../store/reducers";
import { unsavedClientChangesSelector } from "../../../store/ui/selectors/saving";
import { handleRouteChange } from "../../../store/ui/thunks/routing";
import { nextRouteSelector } from "../../../store/ui/selectors/routing";
import { setUnsavedClientChanges } from "../../../store/ui/actionCreators/saving";
import { setNextRoute } from "../../../store/ui/actionCreators/routing";
import { serverClientSelector } from "../../../store/data/selectors/clientDatabase";
import { Client } from "../../../data/models/client";
import { setCurrentClient } from "../../../store/data/action-creators/clientDatabase";
import { saveClient } from "../../../store/data/current_client/thunks/client";
import { useReduxDispatch } from "../../../store/store";
import { RouteData } from "../../../data/routing/types";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  saveButton: {
    color: appTheme.colors.success,
  },
  deleteButton: {
    color: appTheme.colors.error,
  },
}));

const SaveClientPrompt = (): JSX.Element => {
  const classes = useStyles();

  const dispatch = useReduxDispatch();

  const onNavigateAway = () => dispatch(handleRouteChange(nextRouteData!));

  const onResetNextRoute = () => dispatch(setNextRoute(undefined));

  const onDiscardClientChanges = () =>
    dispatch(setCurrentClient(serverClient!));

  const onSaveClientChanges = () => dispatch(saveClient());

  const onResetClientHasUnsavedChanges = () =>
    dispatch(setUnsavedClientChanges(false));

  const onCancel = () =>
    batch(() => {
      onResetNextRoute();
      onResetClientHasUnsavedChanges();
    });

  const onDiscardChanges = () => {
    onDiscardClientChanges();
    onNavigateAway();
    onResetClientHasUnsavedChanges();
  };

  const onSaveChanges = async () => {
    onSaveClientChanges().then(() => {
      batch(() => {
        onNavigateAway();
        onResetClientHasUnsavedChanges();
      });
    });
  };

  const serverClient: Client | undefined =
    useSelector<RootState, Client | undefined>(serverClientSelector);

  const nextRouteData: RouteData | undefined =
    useSelector<RootState, RouteData | undefined>(nextRouteSelector);

  const clientUnsavedChanges: boolean = useSelector<RootState, boolean>(
    unsavedClientChangesSelector
  );

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setShowDialog(clientUnsavedChanges);
  }, [clientUnsavedChanges]);

  return (
    <BaseDialog
      body={null}
      title="You have made unsaved changes to this client"
      maxWidth="sm"
      action={[
        <Button
          key="CancelButtonSaveClientPrompt"
          onClick={onCancel}
          color="secondary"
        >
          Cancel
        </Button>,
        <Button
          key="DiscardChangesButtonSaveClientPrompt"
          onClick={onDiscardChanges}
          className={classes.deleteButton}
        >
          Discard changes
        </Button>,
        <Button
          key="SaveChangesButtonSaveClientPrompt"
          onClick={onSaveChanges}
          color="secondary"
        >
          Save changes
        </Button>,
      ]}
      open={showDialog}
      onClose={onCancel}
    />
  );
};

export default SaveClientPrompt;

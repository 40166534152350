import { FoodItems } from "../../data/models/documentProperties/foodItem";
import { Sections } from "../../data/models/documentProperties/section";
import { ClipboardActions, SET_CLIPBOARD } from "../actions/clipboardActions";

export enum ClipboardDataType {
  NONE,
  INGREDIENT_ROWS,
  SECTIONS,
}

export interface SectionClipboardData {
  dataType: ClipboardDataType.SECTIONS;
  data: Sections;
}

export interface IngredientRowClipboardData {
  dataType: ClipboardDataType.INGREDIENT_ROWS;
  data: FoodItems;
}

export type ClipboardType = SectionClipboardData | IngredientRowClipboardData;

export type ClipboardState = {
  clipboardData: ClipboardType | null;
};

export const initialClipboardState: ClipboardState = {
  clipboardData: null,
};

export const clipboard = (
  state: ClipboardState = initialClipboardState,
  action: ClipboardActions
): ClipboardState => {
  switch (action.type) {
    case SET_CLIPBOARD:
      return {
        ...state,
        clipboardData: action.clipboardData,
      };
    default:
      return state;
  }
};

import React, { FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { createStyles, IconButton, makeStyles } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  deleteMethodSection,
  updateMethodSectionIsCollapsed,
} from "../../../../../../store/data/current-document/action-creators/document";
import { FoodworksTooltip } from "../../../../../common/InfoTooltip";
import { MethodSectionDeleteDialog } from "../../../../../dialogs/SectionDeleteDialog";

const useStyles = makeStyles(() =>
  createStyles({
    expandButton: {},
    deleteIcon: {
      padding: 0,
    },
  })
);

interface MethodSectionButtonProps {
  sectionIndex: number;
}

export const HideMethodSectionButton = React.memo<MethodSectionButtonProps>(
  ({ sectionIndex }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onHideSection = () =>
      dispatch(updateMethodSectionIsCollapsed(sectionIndex, true));

    return (
      <IconButton
        className={`notSelectable`}
        color="secondary"
        size="small"
        onClick={onHideSection}
      >
        <ArrowDropUp
          data-cy="expandLess"
          className={`notSelectable ${classes.expandButton}`}
          color="secondary"
        />
      </IconButton>
    );
  }
);

export const ShowMethodSectionButton = React.memo<MethodSectionButtonProps>(
  ({ sectionIndex }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onShowSection = () =>
      dispatch(updateMethodSectionIsCollapsed(sectionIndex, false));

    return (
      <IconButton
        className={`notSelectable`}
        color="secondary"
        size="small"
        onClick={onShowSection}
      >
        <ArrowDropDown
          data-cy="expandMore"
          className={`notSelectable ${classes.expandButton}`}
          color="secondary"
        />
      </IconButton>
    );
  }
);

interface DeleteSectionButtonProps {
  sectionIndex: number;
  isSectionContent: boolean;
}

export const DeleteSectionButton: FunctionComponent<DeleteSectionButtonProps> = ({
  sectionIndex,
  isSectionContent,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDeleteSections = () => dispatch(deleteMethodSection(sectionIndex));

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onDeleteSection = () =>
    isSectionContent ? setShowDeleteDialog(true) : onDeleteSections();

  const onCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  return (
    <>
      <FoodworksTooltip title="Delete section" placement="bottom">
        <div>
          <IconButton
            size="small"
            data-cy="deleteSectionButton"
            className={`notSelectable ${classes.deleteIcon}`}
            onClick={onDeleteSection}
          >
            <DeleteIcon className={classes.deleteIcon} />
          </IconButton>
        </div>
      </FoodworksTooltip>
      <MethodSectionDeleteDialog
        open={showDeleteDialog}
        onClose={onCloseDeleteDialog}
        sectionIndex={sectionIndex}
      />
    </>
  );
};

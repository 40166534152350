import { useMemo } from "react";
import { useSelector } from "react-redux";

import { DocumentSummary } from "../../../../../../../data/models/userDatabase";
import { clientDocumentIdsSelector } from "../../../../../../../store/data/current_client/selectors/client";
import { activeUserDocumentSummariesSelector } from "../../../../../../../store/data/selectors/database";
import { RootState } from "../../../../../../../store/reducers";

const getClientDocuments = (
  clientDocumentIds: string[],
  documentSummaries: DocumentSummary[]
): DocumentSummary[] => {
  return documentSummaries.filter((summary: DocumentSummary) =>
    clientDocumentIds.includes(summary.documentId)
  );
};

const useClientDocuments = () => {

  const clientDocumentIds: string[] = useSelector<RootState, string[]>(
    clientDocumentIdsSelector
  );

  const documentSummaries: DocumentSummary[] = useSelector<
    RootState,
    DocumentSummary[]
  >(activeUserDocumentSummariesSelector);

  const getClientDocumentsMemo = useMemo(
    () => getClientDocuments(clientDocumentIds, documentSummaries),
    [clientDocumentIds, documentSummaries]
  );

  return getClientDocumentsMemo;
};

export default useClientDocuments;

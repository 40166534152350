import {
  Button,
  Card,
  Checkbox,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, {
  ReactNode,
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { Profile } from "../../../data/models/userDatabase";
import { databaseProfilesSelector } from "../../../store/data/selectors/database";
import { enabledNutrientsSelector } from "../../../store/data/selectors/databaseProperties";
import { updateDatabaseProfiles } from "../../../store/data/thunks/database";
import { RootState } from "../../../store/reducers";
import { BaseDialog } from "../BaseDialog";
import { appTheme } from "../../../styling/style";
import { FoodWorksTextInput } from "../../common/FoodWorksTextInput";
import { Nutrient } from "../../../data/models/nutrient";
import { NutrientsDataSelector } from "../../../store/data/selectors/referenceData";
import { MEDIUM_FIELD, NAME_FIELD } from "../../../constants/textInputs";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    margin: "10px 0px",
  },
  addIcon: {
    color: appTheme.colors.primary,
  },
  componentRootContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    height: 550,
  },
  components: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flex: 1,
    margin: "0px 10px",
  },
  listContainer: {
    overflow: "auto",
    flex: "1",
  },
  centeredFlex: {
    display: "flex",
    alignItems: "center",
  },
  listButton: {
    borderRadius: 4,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
    padding: "0px",
    alignItems: "center",
  },
  selectedListButton: {
    borderRadius: 4,
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
    padding: "0px",
    alignItems: "center",
  },
  horizontalPadding: {
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

interface NutrientsDialogProps {
  open: boolean;
  onClose: () => void;
  profileId: string;
}

interface NutrientValues {
  nutrientId: string;
  displayName: string;
  isOpen: boolean;
}

export const NutrientsProfileDialog: FunctionComponent<NutrientsDialogProps> = ({
  open,
  onClose,
  profileId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getDefaultProfileState = (profileId: string): Profile => ({
    id: profileId || uuidv4(),
    name: "",
    nutrients: [],
  });

  const onUpdateDatabaseProfiles = () => {
    const profile: Profile | undefined = profileData.find(
      (profile) => profile.id === profileId
    );

    if (!profile) {
      const updatedProfile: Profile[] = [...profileData, myProfile];
      dispatch(updateDatabaseProfiles(updatedProfile));
    } else {
      const updatedProfile: Profile[] = profileData.map((profile) =>
        profile.id === profileId ? myProfile : profile
      );
      dispatch(updateDatabaseProfiles(updatedProfile));
    }
  };

  const profileData: Profile[] = useSelector<RootState, Profile[]>(
    databaseProfilesSelector
  );

  const enabledNutrientIds: string[] = useSelector<RootState, string[]>(
    enabledNutrientsSelector
  );

  const nutrientData: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(NutrientsDataSelector);

  const [myProfile, setMyProfile] = useState<Profile>(
    getDefaultProfileState(profileId)
  );
  const [shownNutrients, setShownNutrients] = useState<NutrientValues[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNutrientIds, setFilteredNutrientIds] = useState<string[]>([]);

  const filteredNutrients = (searchTerm: string): string[] => {
    const filteredNutrientIds: string[] = [];

    for (const nutrient of shownNutrients)
      if (nutrient.displayName.toLowerCase().includes(searchTerm.toLowerCase()))
        filteredNutrientIds.push(nutrient.nutrientId);
    return filteredNutrientIds;
  };

  const applyFilter = (searchTerm: string) => {
    const filtered: string[] = filteredNutrients(searchTerm);
    setFilteredNutrientIds(filtered);
  };

  const loadNutrients = useCallback(() => {
    const shownNutrients: NutrientValues[] = [];
    for (const nutrient of nutrientData) {
      const nutrientId: string = nutrient[0];
      const displayName: string = nutrient[1].name;
      if (enabledNutrientIds.includes(nutrientId)) {
        shownNutrients.push({
          nutrientId,
          displayName,
          isOpen: myProfile.nutrients.includes(nutrientId),
        });
      }
    }
    setShownNutrients(shownNutrients);
  }, [enabledNutrientIds, myProfile.nutrients, nutrientData]);

  useEffect(() => {
    loadNutrients();
  }, [loadNutrients]);

  const loadProfile = useCallback(() => {
    const newProfile: Profile | undefined = profileData.find(
      (profile: Profile) => profile.id === profileId
    );
    newProfile
      ? setMyProfile(newProfile)
      : setMyProfile(getDefaultProfileState(profileId));
  }, [profileData, profileId]);

  useEffect(() => {
    loadProfile();
  }, [profileData, loadProfile]);

  const handleClose = () => {
    loadProfile();
    onClose();
  };

  const applyChanges = () => {
    onUpdateDatabaseProfiles();
    onClose();
  };

  const profileInput: ReactNode = [
    <Typography key="nutrientsProfileDialog-profileName">
      Profile Name
    </Typography>,
    <FoodWorksTextInput
      maxLength={NAME_FIELD}
      key="nutrientsProfileDialog-profileInput"
      className={classes.horizontalPadding}
      data-cy="profileNameInput"
      onChange={(event) =>
        setMyProfile({ ...myProfile, name: event.target.value })
      }
      value={myProfile.name}
      placeholder=""
    />,
  ];

  const handleNutrientOnClick = (nutrientId: string) => {
    const updatedShownNutrients: NutrientValues[] = shownNutrients.map(
      (nutrient: NutrientValues) =>
        nutrient.nutrientId === nutrientId
          ? { ...nutrient, isOpen: !nutrient.isOpen }
          : nutrient
    );
    setShownNutrients(updatedShownNutrients);

    const enabledNutrientIds: string[] = updatedShownNutrients
      .filter((nutrient: NutrientValues) => nutrient.isOpen)
      .map((nutrient: NutrientValues) => nutrient.nutrientId);

    setMyProfile({ ...myProfile, nutrients: enabledNutrientIds });
  };

  const handleOnChangeSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    applyFilter(searchTerm);
  };

  const searchBar: ReactNode = (
    <FoodWorksTextInput
      maxLength={MEDIUM_FIELD}
      key="nutrientsProfileDialog-searchBar"
      data-cy="nutrientProfileFilterInput"
      onChange={(event) => handleOnChangeSearch(event.target.value)}
      value={searchTerm}
      placeholder="Search nutrients here..."
    />
  );

  const nutrientItems: ReactNode = shownNutrients.map(
    (nutrient: NutrientValues) =>
      (!searchTerm || filteredNutrientIds.includes(nutrient.nutrientId)) && [
        <ListItem
          button
          key={nutrient.nutrientId}
          onClick={() => handleNutrientOnClick(nutrient.nutrientId)}
          className={
            nutrient.isOpen ? classes.selectedListButton : classes.listButton
          }
        >
          <Checkbox
            data-cy="nutrientProfileCheckbox"
            name={nutrient.nutrientId}
            checked={nutrient.isOpen}
          />
          <Typography>{nutrient.displayName}</Typography>
        </ListItem>,
      ]
  );

  const nutrientComponent: ReactNode = [
    <div
      key="nutrientsProfileDialog-nutrientComponent"
      className={classes.centeredFlex}
    >
      {profileInput}
    </div>,
    <Typography
      key="nutrientsProfileDialog-nutrientComponent-typography"
      className={classes.sectionContainer}
    >
      Available Nutrients
    </Typography>,
    searchBar,
    <Card key="nutrientComponentCard" className={classes.listContainer}>
      <List data-cy="nutrientProfileList">{nutrientItems}</List>
    </Card>,
  ];

  const body: ReactNode = [
    <div
      key="nutrientsProfileDialog-rootContainer"
      className={classes.componentRootContainer}
    >
      <div className={classes.components}> {nutrientComponent}</div>
    </div>,
  ];

  const action: ReactNode = [
    <Button
      key="mnutrientProfileDialog-cancel"
      data-cy="nutrientProfileCancel"
      onClick={handleClose}
    >
      Cancel
    </Button>,
    <Button
      key="mnutrientProfileDialog-apply"
      disabled={!myProfile.name.trim()}
      data-cy="nutrientProfileApplyChanges"
      onClick={applyChanges}
    >
      Apply
    </Button>,
  ];

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title="Create a new profile"
      body={body}
      action={action}
      maxWidth="md"
      dataCy="nutrientsProfileDialog"
    />
  );
};

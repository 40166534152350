import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { DocumentSummary } from "../../../../data/models/userDatabase";
import ClientNavigationListItem from "./ClientNavigationListItem";
import { RootState } from "../../../../store/reducers";
import { navAccordionsExpandedSelector } from "../../../../store/ui/selectors/navigationSelectors";
import { FOOD_TEMPLATES } from "../../../../constants/FoodTemplate";
import { appTheme } from "../../../../styling/style";
import {
  AccordionItem,
  AccordionList,
  AccordionProps,
} from "../../../common/AccordionList";
import { tappedNavAccordion } from "../../../../store/ui/actionCreators/navigationActionCreators";

const getListItems = (
  documents: DocumentSummary[],
  showTags: boolean
): AccordionItem[] =>
  documents.map(
    (summary: DocumentSummary): AccordionItem => ({
      item: (
        <ClientNavigationListItem
          documentSummary={summary}
          showTags={showTags}
        />
      ),
      id: summary.documentId,
    })
  );

const getDocumentGroupings = (
  visibleDocuments: DocumentSummary[],
  navAccordionsExpanded: number[],
  onTappedNavAccordion: (templateId: string) => void,
  showTags: boolean
): AccordionProps[] =>
  FOOD_TEMPLATES.map(
    (template): AccordionProps => ({
      id: template.id,
      header: template.title,
      icon: (
        <template.icon
          style={{ fill: appTheme.colors.xiketic, width: 24, height: 24 }}
        />
      ),
      items: getListItems(
        visibleDocuments.filter(
          (summary: DocumentSummary) =>
            template.id.toString() === summary.templateId
        ),
        showTags
      ),
      open: navAccordionsExpanded.includes(template.id),
      onClick: () => onTappedNavAccordion(template.id.toString()),
    })
  ).filter((accordion: AccordionProps) => accordion.items.length);

interface ClientNavigationListProps {
  visibleDocuments: DocumentSummary[];
  showTags: boolean;
}

const ClientNavigationList = ({
  visibleDocuments,
  showTags,
}: ClientNavigationListProps): JSX.Element => {
  const dispatch = useDispatch();

  const onTappedNavAccordion = (templateId: string) =>
    dispatch(tappedNavAccordion(Number(templateId)));

  const navAccordionsExpanded = useSelector<RootState, number[]>(
    navAccordionsExpandedSelector
  );

  const documentAccordions = getDocumentGroupings(
    visibleDocuments,
    navAccordionsExpanded,
    onTappedNavAccordion,
    showTags
  );

  return <AccordionList values={documentAccordions} />;
};

export default ClientNavigationList;

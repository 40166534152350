import React, { ReactNode, useRef, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { useSelector } from "react-redux";

import { DocumentSummary } from "../../../../data/models/userDatabase";
import { appTheme } from "../../../../styling/style";
import { DocumentItemMenu } from "./DocumentItemMenu";
import { FoodworksTooltip } from "../../../common/InfoTooltip";
import { RootState } from "../../../../store/reducers";
import {
  CurrentDocumentIdSelector,
  DocumentIsLoadingSelector,
} from "../../../../store/data/current-document/selectors/currentDocument";
import NavigationListButton from "../shared/NavigationListButton";
import { ReactComponent as PushPin } from "../../../../images/icons/Push_Pin_Flat.svg";
import { ReactComponent as PushpinAngled } from "../../../../images/icons/Push_Pin_Angled.svg";

const useStyles = makeStyles(() => ({
  leftButtonGroup: {
    width: "100%",
    alignItems: "center",
    display: "flex",
  },
  selected: {
    backgroundColor: appTheme.colors.oceanBlue[0],
  },
  root: {
    display: "flex",
    width: "100%",
    alignContent: "center",
    height: 30,
    justifyContent: "space-between",
    marginLeft: "-5px",
    padding: "2px 0px",
  },
  iconButton: {
    width: 30,
    height: 30,
    borderRadius: 4,
  },
  menuIconButton: {
    color: "grey",
  },
  flex: {
    display: "flex",
  },
  buttonPlaceholderDiv: {
    width: 30,
  },
  flatPinIcon: {
    width: "18px",
  },
  angledPinIcon: {
    width: "35px",
    fill: appTheme.colors.primary,
  },
}));

interface DocumentItemProps {
  documentSummary: DocumentSummary;
  onDocumentClick: (documentId: string) => void;
  lockedDocumentId: string;
  setLockedDocumentId: (documentId: string) => void;
  showDocumentTags: boolean;
}

export const DocumentItem = React.memo<DocumentItemProps>(
  ({
    documentSummary,
    onDocumentClick,
    lockedDocumentId,
    setLockedDocumentId,
    showDocumentTags,
  }) => {
    const classes = useStyles();

    const documentIsLoading: boolean = useSelector<RootState, boolean>(
      DocumentIsLoadingSelector
    );

    const currentDocumentId: string = useSelector<RootState, string>(
      CurrentDocumentIdSelector
    );

    const [hovered, setHovered] = useState(false);

    const [menuAnchorEl, setMenuAnchorEl] =
      useState<HTMLDivElement | null>(null);

    const rootDivRef = useRef<HTMLDivElement | null>(null);

    const toggleLockDocumentInExploreButton: ReactNode = (
      <FoodworksTooltip title="Lock document in explorer">
        <IconButton
          color="secondary"
          size="small"
          className={classes.iconButton}
          onClick={() => setLockedDocumentId(documentSummary.documentId)}
        >
          {lockedDocumentId === documentSummary.documentId ||
          currentDocumentId === documentSummary.documentId ? (
            <PushpinAngled
              className={classes.angledPinIcon}
            />
          ) : (
            <PushPin className={classes.flatPinIcon}/>
          )}
        </IconButton>
      </FoodworksTooltip>
    );

    const documentLocked: ReactNode = (
      <div className={classes.flex}>{toggleLockDocumentInExploreButton}</div>
    );

    const onHoverButtons: ReactNode = (
      <div className={classes.flex}>
        <IconButton
          size="small"
          className={classes.iconButton}
          color={
            lockedDocumentId === documentSummary.documentId ||
            currentDocumentId === documentSummary.documentId
              ? "secondary"
              : "default"
          }
          onClick={() => setMenuAnchorEl(rootDivRef.current)}
        >
          <MoreHoriz className={classes.menuIconButton} />
        </IconButton>
      </div>
    );

    const showDocumentIsPinned =
      lockedDocumentId === documentSummary.documentId &&
      !documentIsLoading &&
      documentLocked;

    return (
      <div
        ref={rootDivRef}
        className={
          documentSummary.documentId === currentDocumentId
            ? `${classes.root} ${classes.selected}`
            : classes.root
        }
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className={classes.leftButtonGroup}>
          {(hovered && toggleLockDocumentInExploreButton) ||
            showDocumentIsPinned || (
              <div className={classes.buttonPlaceholderDiv} />
            )}
          <NavigationListButton
            id={documentSummary.documentId}
            label={documentSummary.label}
            isSelected={documentSummary.documentId === currentDocumentId}
            isPinned={lockedDocumentId === documentSummary.documentId}
            type="Document"
            showTags={showDocumentTags}
            onClick={onDocumentClick}
          />
          {hovered && onHoverButtons}
        </div>
        {!!menuAnchorEl && (
          <DocumentItemMenu
            anchorElement={menuAnchorEl}
            onClose={() => setMenuAnchorEl(null)}
            documentSummary={documentSummary}
          />
        )}
      </div>
    );
  }
);

import { BaseQuantity, WeightBaseQuantity } from "../baseQuantity";
import { Document } from "../document";
import { DocumentMap } from "../../../store/data/reducers/documentCache";

export const SERVE_ID = "Serve";

export class Measure {
  id: string;
  name: string;
  description: string;
  value: number;

  constructor(id: string, name: string, value: number, description: string) {
    this.id = id;
    this.name = name;
    this.value = value;
    this.description = description;
  }

  baseQuantity = (): BaseQuantity | undefined =>
    new WeightBaseQuantity(this.value);
}

export interface CommonMeasure {
  id: string;
  name: string;
  description: string;
  value: number;
  usedIn: string[];
}

export interface CommonMeasures {
  measures: CommonMeasure[];
  default: string;
}

export const buildCommonMeasuresForMappedDocument = (
  document: Document,
  documentCache: DocumentMap
) => {
  let measures: CommonMeasure[] = [];

  while (document) {
    const visibleMeasures = document.commonMeasures.measures.filter(
      (measure: CommonMeasure) =>
        // HACK - EF: the Boolean check here is to get around AusBrand CommonMeasures not
        // being stored correctly. Fix at the source please - Jira ticket FWPW-405
        measure.name[0] !== "_" && Boolean(measure.value)
    );

    measures = measures.concat(visibleMeasures);

    document = documentCache[document.documentMappingId];
  }

  return measures;
};

import React, { FunctionComponent } from "react";
import {
  InputBase,
  makeStyles,
  InputProps,
  InputBaseProps,
  Theme,
} from "@material-ui/core";

import { appTheme } from "../../styling/style";
import { isNumber } from "../screens/databases/documents/tabs/ingredients/editing_grid/rows/cells/quantityUtil";
import { MEDIUM_FIELD } from "../../constants/textInputs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "&.Mui-error": {
      border: `2px solid ${appTheme.colors.error}`,
      borderRadius: "4px",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: appTheme.colors.input,
    border: `1px solid ${appTheme.colors.gainsbro}`,
    fontSize: appTheme.fontSize.textInput,
    fontFamily: appTheme.fontFamily.textInput,
    padding: "6px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    margin: 1,
    "&:hover": {
      background: appTheme.colors.inputHover,
    },
    "&:focus": {
      border: `2px solid ${theme.palette.secondary.main}`,
      margin: 0,
      background: appTheme.colors.white[0],
    },
    "&:disabled": {
      background: appTheme.colors.gainsbruh,
    },
  },
}));

const BaseFoodWorksTextInput = (props: InputBaseProps) => {
  const classes = useStyles();
  return <InputBase classes={{ input: `${classes.input}` }} {...props} />;
};

interface FoodWorksTextInputProps {
  maxLength?: number;
  placeholder?: string;
}

export const FoodWorksTextInput = ({
  maxLength = MEDIUM_FIELD,
  placeholder,
  ...inputProps
}: FoodWorksTextInputProps & InputBaseProps): JSX.Element => {
  const classes = useStyles();
  return (
    <BaseFoodWorksTextInput
      classes={{ input: `${classes.input}` }}
      inputProps={{ maxLength, placeholder }}
      {...inputProps}
    />
  );
};

interface FoodworksNumberInputProps extends FoodWorksTextInputProps {
  setValue: (value: string) => void;
  minValue?: number;
  maxValue?: number;
  inputProps?: InputProps;
  className?: string;
  /**
   * The number of decimal places allowed in text field.
   * If empty, decimal places is unrestricted.
   */
  decimalPlaces?: number;
}

const DECIMAL_POINT: string = ".";

const isDecimalPlaces = (input: string, n: number) =>
  new RegExp("^-?\\d*(\\.\\d{0," + n + "})?$").test(input);

export const FoodWorksNumberInput: FunctionComponent<FoodworksNumberInputProps> =
  ({
    setValue,
    minValue = 0,
    maxValue,
    inputProps,
    className,
    placeholder,
    maxLength,
    decimalPlaces = 0,
  }) => {
    const useStyles = makeStyles((theme: Theme) => ({
      input: {
        textAlign: "right",
        borderRadius: 4,
        position: "relative",
        backgroundColor: appTheme.colors.input,
        border: `1px solid ${appTheme.colors.gainsbro}`,
        fontSize: appTheme.fontSize.textInput,
        fontFamily: appTheme.fontFamily.textInput,
        padding: "6px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        margin: 1,
        "&:hover": {
          background: appTheme.colors.inputHover,
        },
        "&:focus": {
          border: `2px solid ${theme.palette.secondary.main}`,
          margin: 0,
          background: appTheme.colors.white[0],
        },
        "&:disabled": {
          background: appTheme.colors.gainsbruh,
        },
      },
    }));

    const classes = useStyles();
    const onChange = (input: string): void => {
      if (
        !input ||
        input === DECIMAL_POINT ||
        (isNumber(input) &&
          Number(input) >= minValue &&
          Number(input) < Number.MAX_SAFE_INTEGER)
      ) {
        if (decimalPlaces && isDecimalPlaces(input, decimalPlaces)) {
          setValue(input);
        } else if (!decimalPlaces) {
          setValue(input);
        }
      }

      if (maxValue && Number(input) > maxValue) {
        setValue(maxValue.toString());
      }
    };
    return (
      <FoodWorksTextInput
        data-cy="numberInput"
        classes={{ input: `${classes.input}` }}
        className={`${className} `}
        onChange={(event) => onChange(event.currentTarget.value)}
        placeholder={placeholder}
        maxLength={maxLength}
        {...inputProps}
      />
    );
  };

import { Day } from "../../../data/models/documentProperties/day";
import { RootState } from "../../reducers";

export const disableSaveButtonSelector = (state: RootState): boolean =>
  state.documentEditing.disableSaveButton;

export const currentHistorySelector = (state: RootState): Day[] | null =>
  state.documentEditing.actionHistory.current;

export const pastUndoHistorySelector = (state: RootState): Array<Day[]> =>
  state.documentEditing.actionHistory.past;

export const futureRedoHistorySelector = (state: RootState): Array<Day[]> =>
  state.documentEditing.actionHistory.future;

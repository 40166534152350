import { ENABLED_DATA_SOURCES } from "../../../constants/datasources";
import {
  DatabasePropertyActions,
  SET_DISPLAYED_NUTRIENTS,
  SET_IS_ALTERNATE_IDS_ENABLED,
  SET_ENABLED_DATASOURCES,
  SET_ENABLED_MEASURES,
  SET_ENABLED_NUTRIENTS,
} from "../actions/databaseProperties";
import { ENABLED_NUTRIENTS, ENABLED_REFERENCE_MEASURES } from "./referenceData";

export type DatabasePropertiesState = {
  enabledDatasources: string[];
  displayedNutrients: string[];
  enabledMeasures: string[];
  enabledNutrients: string[];
  isAlternateIdsEnabled: boolean;
};

export const initialDatabasePropertiesState: DatabasePropertiesState = {
  enabledDatasources: ENABLED_DATA_SOURCES,
  displayedNutrients: [],
  enabledMeasures: ENABLED_REFERENCE_MEASURES,
  enabledNutrients: ENABLED_NUTRIENTS,
  isAlternateIdsEnabled: false,
};

export const databaseProperties = (
  state: DatabasePropertiesState = initialDatabasePropertiesState,
  action: DatabasePropertyActions
): DatabasePropertiesState => {
  switch (action.type) {
    case SET_ENABLED_DATASOURCES:
      return {
        ...state,
        enabledDatasources: action.sources,
      };
    case SET_DISPLAYED_NUTRIENTS:
      return { ...state, displayedNutrients: action.nutrientIds };
    case SET_ENABLED_MEASURES:
      return { ...state, enabledMeasures: action.enabledMeasures };
    case SET_ENABLED_NUTRIENTS:
      return { ...state, enabledNutrients: action.enabledNutrients };
    case SET_IS_ALTERNATE_IDS_ENABLED:
      return { ...state, isAlternateIdsEnabled: action.isAlternateIdsEnabled };
    default:
      return state;
  }
};

import React, { useState } from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";

import { MeasurementEntry } from "../../../../../../../data/models/clientProperties/measurement";
import { appTheme } from "../../../../../../../styling/style";
import EditMeasurementEntryDialog from "../EditMeasurementEntryDialog";
import DeleteMeasurementEntryDialog from "../DeleteMeasurementEntryDialog";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: 4,
  },
  valuesContainer: {
    display: "flex",
    flex: 1,
    background: appTheme.colors.white[5],
    border: `1px solid ${appTheme.colors.gainsbruh}`,
    borderRadius: 4,
    marginLeft: 5,
    paddingLeft: 2,
  },
  indexContainer: {
    display: "flex",
    justifyContent: "center",
    width: 25,
  },
  dateContainer: {
    paddingLeft: 10,
    paddingRight: 5,
    flex: 2,
    display: "flex",
    alignItems: "center",
  },
  valueContainer: {
    borderLeft: `1px solid ${appTheme.colors.gainsbruh}`,
    paddingLeft: 20,
    flex: 3,
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    height: 25,
    width: 25,
  },
}));

interface Props {
  index: number;
  measurementUnit: string;
  measurementTitle: string;
  entry: MeasurementEntry;
}
const MeasurementEntryRow = React.memo<Props>((props): JSX.Element => {
  const classes = useStyles();
  const { index, entry, measurementUnit, measurementTitle } = { ...props };

  const [hovered, setHovered] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onClickEdit = () => {
    setHovered(false);
    setShowEditDialog(true);
  };

  const onClickDelete = () => {
    setHovered(false);
    setShowDeleteDialog(true);
  };

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={classes.root}
    >
      <div className={classes.indexContainer}>
        <Typography>{index}</Typography>
      </div>
      <div className={classes.valuesContainer}>
        <div className={classes.dateContainer}>
          <Typography>{new Date(entry.date).toLocaleString()}</Typography>
        </div>
        <div className={classes.valueContainer}>
          <Typography>{`${entry.value} ${measurementUnit}`}</Typography>
        </div>
        <IconButton
          onClick={onClickEdit}
          className={classes.iconButton}
          color="secondary"
          size="small"
        >
          {hovered && <EditOutlined />}
        </IconButton>
        <IconButton
          onClick={onClickDelete}
          size="small"
          className={classes.iconButton}
        >
          {hovered && <DeleteOutlined />}
        </IconButton>
      </div>
      {showEditDialog && (
        <EditMeasurementEntryDialog
          measurementTitle={measurementTitle}
          measurementUnit={measurementUnit}
          measurementEntry={entry}
          onClose={() => setShowEditDialog(false)}
        />
      )}
      {showDeleteDialog && (
        <DeleteMeasurementEntryDialog
          measurementTitle={measurementTitle}
          measurementUnit={measurementUnit}
          measurementEntryId={entry.id}
          onClose={() => setShowDeleteDialog(false)}
        />
      )}
    </div>
  );
});

export default MeasurementEntryRow;

import React, { ReactNode } from "react";
import {
  List,
  ListItem,
  Collapse,
  makeStyles,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { appTheme } from "../../styling/style";

const useStyles = makeStyles((theme: any) => ({
  item: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  accordionHeader: {
    color: "secondary",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    display: "flex",
    textTransform: "none",
    marginLeft: 5,
  },
  accordionHeaderText: {
    color: appTheme.colors.xiketic,
  },
  accordionHeaderItems: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "flex",
    justifyItems: "start",
    alignItems: "center",
    flex: 1,
  },
}));

export interface AccordionItem {
  id: string;
  item: ReactNode;
}
export interface AccordionProps {
  id?: number;
  header: string;
  items: AccordionItem[];
  open: boolean;
  icon?: ReactNode;
  onClick: (id: string) => void;
}

const AccordionGroup = React.memo(
  ({ id, header, items, open, icon, onClick }: AccordionProps): JSX.Element => {
    const classes = useStyles();

    return (
      <div key={id}>
        <Button
          data-cy="accordionHeader"
          color="secondary"
          fullWidth={true}
          className={classes.accordionHeader}
          onClick={() => onClick(id!.toString())}
          startIcon={icon}
        >
          <div className={classes.accordionHeaderItems}>
            <Typography variant="h4">{header}</Typography>

            {open ? <ExpandLess /> : <ExpandMore />}
          </div>
        </Button>
        <Divider variant="middle" />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding data-cy="accordionCategory">
            {items.map((item: AccordionItem, index: number) => (
              <ListItem className={classes.item} key={`${item.id}-${index}`}>
                {item.item}
              </ListItem>
            ))}
          </List>
        </Collapse>
      </div>
    );
  }
);

export interface AccordionListProps {
  values: AccordionProps[];
}

export const AccordionList = ({ values }: AccordionListProps) => {
  return (
    <>
      <List data-cy="accordionList" disablePadding={true} component="nav">
        {values.map((accordion: AccordionProps) => (
          <AccordionGroup
            key={`${accordion.id}-accordianGroup`}
            {...accordion}
          />
        ))}
      </List>
    </>
  );
};

export type NRVType =
  | "EAR"
  | "AI"
  | "RDI"
  | "UIL"
  | "SDTMIN"
  | "SDTMAX"
  | "EERM"
  | "DEER";

export const NRVTypeOrder = new Map([
  ["EERM", 0],
  ["DEER", 1],
  ["EAR", 2],
  ["AI", 3],
  ["UL", 4],
  ["RDI", 5],
  ["SDTMIN", 6],
  ["SDTMAX", 7],
]);

export const nrvTypeToFullTitle = (type: string): string => {
  switch (type) {
    case "EAR":
      return "EAR - Estimated Average Requirement";
    case "AI":
      return "AI - Adequate Intake";
    case "RDI":
      return "RDI - Recommended Dietary Intake";
    case "UIL":
      return "UL - Upper Level";
    case "SDTMIN":
      return "SDTMIN - Suggested Dietary Target (Min)";
    case "SDTMAX":
      return "SDTMAX - Suggested Dietary Target (Max)";
    case "EERM":
      return "EERM - Estimated Energy Requirement for Maintenance";
    case "DEER":
      return "DEER - Desirable Estimated Energy Requirement";
    default:
      return "Type Not Found";
  }
};

export type Sex = "Male" | "Female";

export type ActivityLevel =
  | "None"
  | "Bed-rest"
  | "Very-sedentary"
  | "Sedentary"
  | "Light"
  | "Light-moderate"
  | "Moderate"
  | "Heavy"
  | "Very-heavy";

export const getActivityLevelValue = (activityLevel: ActivityLevel): number => {
  switch (activityLevel) {
    case "None":
      return 1.0;
    case "Bed-rest":
      return 1.2;
    case "Very-sedentary":
      return 1.4;
    case "Sedentary":
      return 1.5;
    case "Light":
      return 1.6;
    case "Light-moderate":
      return 1.7;
    case "Moderate":
      return 1.8;
    case "Heavy":
      return 2.0;
    case "Very-heavy":
      return 2.2;
  }
};

export type PregnancyLevel =
  | "None"
  | "Trimester1"
  | "Trimester2"
  | "Trimester3";

export const getAdditionalEnergyFactor = (
  pregnancyLevel: PregnancyLevel,
  isLactating: boolean
): number => {
  if (isLactating) {
    return 2000;
  }

  switch (pregnancyLevel) {
    case "None":
      return 0;
    case "Trimester1":
      return 0;
    case "Trimester2":
      return 1400;
    case "Trimester3":
      return 1900;
  }
};

export const getNRVCategory = (
  sex: Sex,
  pregnancyLevel: PregnancyLevel,
  isLactating: boolean
): string => {
  if (sex === "Male") {
    return sex;
  }

  if (pregnancyLevel === "None") {
    return isLactating ? "FemaleLactating" : "Female";
  }

  return "Female".concat(pregnancyLevel);
};

export interface NRVInfo {
  type: NRVType;
  aFactor: number;
  bFactor: number;
  cFactor: number;
  nutrientId: string;
}

export interface ERVInfo {
  aFactor: number;
  bFactor: number;
  cFactor: number;
  isBMR: boolean;
}

export interface NutrientReferenceValue {
  type: NRVType;
  value: number;
  nutrient: string;
}

export const NRVInfoConverter = {
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): NRVInfo {
    const data = snapshot.data();
    return {
      type: data.type,
      aFactor: data.aFactor,
      bFactor: data.bFactor,
      cFactor: data.cFactor,
      nutrientId: data.nutrient,
    };
  },
  toFirestore: function () {
    throw new Error("Unimplemented");
  },
};

export const ERVInfoConverter = {
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): ERVInfo {
    const data = snapshot.data();
    return {
      aFactor: data.aFactor,
      bFactor: data.bFactor,
      cFactor: data.cFactor,
      isBMR: data.isBMR,
    };
  },
  toFirestore: function () {
    throw new Error("Unimplemented");
  },
};

import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { FoodWorksDate } from "../../../../../data/models/documentProperties/date";
import { UserDatabaseSummary } from "../../../../../data/models/userDatabase";
import DatabaseTableRow from "./DatabaseTableRow";

const useStyles = makeStyles(() => ({
  body: {
    marginTop: 50,
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  databaseTable: {
    display: "flex",
    marginTop: 25,
    width: "100%",
  },
  table: {
    width: "100%",
  },
  header: {
    width: "100%",
  },
}));

interface TableData {
  name: string;
  date: FoodWorksDate;
  id: string;
}

const createTableData = (
  name: string,
  date: FoodWorksDate,
  id: string
): TableData => ({ name, date, id });

interface DatabaseTableProps {
  userDatabaseSummaries: UserDatabaseSummary[];
}

const DatabaseTable = (props: DatabaseTableProps) => {
  const classes = useStyles();
  const rows: TableData[] = props.userDatabaseSummaries.map((summary) => {
    return createTableData(summary.name, summary.date, summary.id);
  });

  return (
    <>
      <Typography variant="h2">All Databases</Typography>
      <div className={classes.databaseTable}>
        <Table className={classes.table}>
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell>Database Name</TableCell>
              <TableCell align="left">Created Date</TableCell>
              <TableCell align="left">Modified Date</TableCell>
              {/* extending header divider to end of table --  */}
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <DatabaseTableRow
                key={row.name + row.id}
                name={row.name}
                date={row.date}
                id={row.id}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default DatabaseTable;

import { Action } from "redux";
import { CompositionState } from "../../../../data/models/composition";

import { Document } from "../../../../data/models/document";

export const All_DOCUMENTS_FETCHED = "ALL_DOCUMENTS_FETCHED";
export const SET_DOCUMENT_LOADING = "SET_DOCUMENT_LOADING";
export const UPDATE_BASE_COMPOSITION = "UPDATE_BASE_COMPOSITION";
export const UPDATE_FINAL_COMPOSITION = "UPDATE_FINAL_COMPOSITION";
export const SET_DOCUMENT_DATA = "SET_DOCUMENT_DATA";
export const SET_DOCUMENT_ID = "SET_DOCUMENT_ID";
export const UPDATE_SERVER_DOCUMENT = "UPDATE_SERVER_DOCUMENT";
export const UPDATE_CURRENT_DOCUMENT = "UPDATE_CURRENT_DOCUMENT";

export interface IActionsUpdateBaseComposition extends Action {
  type: "UPDATE_BASE_COMPOSITION";
  composition: CompositionState;
}

export interface IActionsSetDocumentLoading extends Action {
  type: "SET_DOCUMENT_LOADING";
  isLoading: boolean;
}

export interface IActionsAllDocumentsFetched extends Action {
  type: "ALL_DOCUMENTS_FETCHED";
}

export interface IActionsUpdateFinalComposition extends Action {
  type: "UPDATE_FINAL_COMPOSITION";
  composition: CompositionState;
}

export interface IActionsSetDocumentId extends Action {
  type: "SET_DOCUMENT_ID";
  id: string;
}

export interface IActionsSetDocumentData extends Action {
  type: "SET_DOCUMENT_DATA";
  document: Document;
}

export interface IActionsUpdateServerDocument extends Action {
  type: "UPDATE_SERVER_DOCUMENT";
  document: Document;
}

export interface IActionsUpdateCurrentDocument extends Action {
  type: "UPDATE_CURRENT_DOCUMENT";
  document: Document;
}

export type CurrentDocumentActions =
  | IActionsUpdateBaseComposition
  | IActionsSetDocumentLoading
  | IActionsUpdateFinalComposition
  | IActionsAllDocumentsFetched
  | IActionsSetDocumentId
  | IActionsSetDocumentData
  | IActionsUpdateServerDocument
  | IActionsUpdateCurrentDocument;

import { Size } from "re-resizable";

export const NAV_PANE_SIZE: Size = {
  width: "15%",
  height: "auto",
};

export const NUT_PANE_SIZE: Size = {
  width: 450,
  height: "auto",
};

import React, { FunctionComponent, ReactNode, useState } from "react";

import {
  MenuItem,
  List,
  ListSubheader,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { batch, useDispatch, useSelector } from "react-redux";

import {
  Section,
  Tag,
} from "../../../../../../../../data/models/documentProperties/section";
import { addSectionTag } from "../../../../../../../../store/data/current-document/action-creators/sections";
import { sectionTagsSelector } from "../../../../../../../../store/data/current-document/selectors/sectionTags";
import { RootState } from "../../../../../../../../store/reducers";
import { FoodworksMenu } from "../../../../../../../common/FoodworksMenu";
import { CreateSectionTagDialog } from "../../../../../../../dialogs/section_tags/CreateSectionTagDialog";
import { selectedSectionTagsSelector } from "../../../../../../../../store/ui/selectors/nutritionPaneSelectors";
import { FoodItemPosition } from "../../../../../../../../data/models/foodItemPosition";
import { selectedRowsSelector } from "../../../../../../../../store/ui/selectors/recipeGrid";
import { FoodItem } from "../../../../../../../../data/models/documentProperties/foodItem";
import { MAX_TAG_SHOWN } from "../../../../../../../../constants/tags";
import useUpdateSelection from "../../hooks/useUpdateSelection";

const useStyles = makeStyles(() =>
  createStyles({
    allTagsList: {
      maxHeight: 200,
      width: 180,
      overflow: "auto",
    },
    menuWidth: {
      width: 240,
    },
    colorButton: {
      height: 10,
      width: 10,
      minWidth: 10,
      marginTop: 2,
      marginRight: 5,
      borderRadius: 4,
    },
    tagMenuLabel: {
      display: "flex",
      alignItems: "center",
    },
  })
);

interface SectionTagMenuProps {
  dayIndex: number;
  section: Section;
  anchorElement: HTMLElement | null;
  onClose: () => void;
  onClickCreateTag: () => void;
}

export const SectionTagMenu: FunctionComponent<SectionTagMenuProps> = ({
  dayIndex,
  section,
  anchorElement,
  onClose,
  onClickCreateTag,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateSelection = useUpdateSelection();

  const onAddSectionTag = (tagId: string) =>
    dispatch(addSectionTag(dayIndex, section.index, tagId));

  const onSetSelectedRows = (rowsToSelect: FoodItemPosition[]) =>
    updateSelection(rowsToSelect)


  const documentSectionTags: Tag[] = useSelector<RootState, Tag[]>(
    sectionTagsSelector
  );

  const selectedSectionTags: string[] = useSelector<RootState, string[]>(
    selectedSectionTagsSelector
  );

  const selectedRows: FoodItemPosition[] = useSelector<
    RootState,
    FoodItemPosition[]
  >(selectedRowsSelector);

  const [
    allTagsMenuAnchor,
    setAllTagsMenuAnchor,
  ] = useState<HTMLElement | null>(null);

  const [showCreateTagDialog, setShowCreateTagDialog] = useState(false);

  const onCreateSectionTag = () => {
    onClose();
    onClickCreateTag();
  };

  const selectTag = (tagId: string) => {
    if (!section.tags.includes(tagId)) {
      batch(() => {
        onAddSectionTag(tagId);
        if (selectedSectionTags.includes(tagId)) {
          const rowsToSelect = selectedRows
            .filter(
              (position: FoodItemPosition): boolean =>
                position.day !== dayIndex || position.section !== section.index
            )
            .concat(
              section.foodItems.items.map(
                (item: FoodItem): FoodItemPosition =>
                  new FoodItemPosition(dayIndex, section.index, item.rowIndex)
              )
            );
          onSetSelectedRows(rowsToSelect);
        }
      });
    }
  };

  const onCloseCreateSectionTagDialog = () => {
    setShowCreateTagDialog(false);
  };

  const recentlyCreatedTags: ReactNode = !documentSectionTags.length ? null : (
    <List
      className={classes.menuWidth}
      subheader={
        <ListSubheader key="recentlyUsed" disableSticky>
          Recently created section tags
        </ListSubheader>
      }
    >
      {documentSectionTags
        .slice(-MAX_TAG_SHOWN)
        .reverse()
        .map(
          (tag: Tag): ReactNode => (
            <MenuItem key={tag.id} onClick={() => selectTag(tag.id)}>
              <div className={classes.tagMenuLabel}>
                <div
                  className={classes.colorButton}
                  style={{ backgroundColor: tag.activeColor }}
                ></div>
                {tag.label}
              </div>
            </MenuItem>
          )
        )}
    </List>
  );

  const onCloseAllTagsMenu = () => {
    setAllTagsMenuAnchor(null);
    onClose();
  };

  const viewAllTagsMenu: ReactNode = documentSectionTags.length >
    MAX_TAG_SHOWN && [
    <MenuItem
      key="moreTags"
      onClick={(event) => setAllTagsMenuAnchor(event.currentTarget)}
    >
      All section tags
    </MenuItem>,
    <FoodworksMenu
      id="moreTagsMenu"
      open={!!allTagsMenuAnchor}
      anchorEl={allTagsMenuAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={onCloseAllTagsMenu}
    >
      <List className={classes.allTagsList}>
        {documentSectionTags.map(
          (tag: Tag): ReactNode => (
            <MenuItem key={tag.id} onClick={() => selectTag(tag.id)}>
              <div className={classes.tagMenuLabel}>
                <div
                  className={classes.colorButton}
                  style={{ backgroundColor: tag.activeColor }}
                ></div>
                {tag.label}
              </div>
            </MenuItem>
          )
        )}
      </List>
    </FoodworksMenu>,
  ];

  return (
    <>
      <FoodworksMenu
        open={!!anchorElement}
        anchorEl={anchorElement}
        onClose={onClose}
      >
        {viewAllTagsMenu}
        {recentlyCreatedTags}
        <MenuItem key="createSection" onClick={onCreateSectionTag}>
          Create section tag
        </MenuItem>
      </FoodworksMenu>
      {showCreateTagDialog && (
        <CreateSectionTagDialog
          sectionIndex={section.index}
          dayIndex={dayIndex}
          onClose={onCloseCreateSectionTagDialog}
        />
      )}
    </>
  );
};

import React, { FunctionComponent, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Resizable } from "re-resizable";
import { useDispatch } from "react-redux";

import { appTheme } from "../../../../../styling/style";
import {
  HandleAlignment,
  ResizableHandle,
} from "../../../../common/ResizableHandle";
import { LoadingBarPadding } from "../../../../common/LoadingBarPadding";
import CollapsePaneButton from "../../../navigation/shared/CollapsePaneButton";
import { NUT_PANE_SIZE } from "../../../../../constants/paneSize";
import { Direction } from "re-resizable/lib/resizer";
import { setNutritionPaneDimensions } from "../../../../../store/ui/actionCreators/nutritionPaneActionCreators";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    background: appTheme.colors.white[8],
    padding: 5,
  },
  container: {
    zIndex: 26,
  },
  collapsed: {
    padding: 5,
    background: appTheme.colors.white[8],
    borderLeft: `1px solid ${appTheme.colors.gainsbro}`,
  },
}));

interface NutritionPaneBodyProps {
  open: boolean;
  toggleOpen: () => void;
}

const NutritionPaneBody: FunctionComponent<NutritionPaneBodyProps> = ({
  open,
  toggleOpen,
  children,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onResizeStop = (refToElement: HTMLElement) => {
    const height: number = refToElement.clientHeight;
    const width: number = refToElement.clientWidth;

    dispatch(setNutritionPaneDimensions({ height, width }));
  };

  const [navResizeHovered, setNavResizeHovered] = useState(false);
  const [isDragged, setIsDragged] = useState(false);

  const handleComponent = (
    <ResizableHandle
      isDragged={isDragged}
      isHovered={navResizeHovered}
      onMouseEnter={() => setNavResizeHovered(true)}
      onMouseLeave={() => setNavResizeHovered(false)}
      alignment={HandleAlignment.LEFT}
    />
  );

  const resizableSides = {
    top: false,
    right: false,
    bottom: false,
    left: true,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  };

  return open ? (
    <Resizable
      className={classes.root}
      style={{
        borderLeft:
          navResizeHovered || isDragged
            ? `3px solid ${appTheme.colors.oceanBlue[3]}`
            : `1px solid ${appTheme.colors.gainsbro}`,
        paddingLeft: navResizeHovered || isDragged ? 0 : 2,
      }}
      handleWrapperClass={classes.container}
      minWidth={200}
      maxWidth={NUT_PANE_SIZE.width}
      defaultSize={NUT_PANE_SIZE}
      handleStyles={{ left: { display: "flex", cursor: "ew-resize" } }}
      onResizeStart={() => setIsDragged(true)}
      onResizeStop={(
        e: MouseEvent | TouchEvent,
        dir: Direction,
        refToElement: HTMLElement
      ) => {
        setIsDragged(false);
        setNavResizeHovered(false);
        onResizeStop(refToElement);
      }}
      handleComponent={{
        left: handleComponent,
      }}
      enable={resizableSides}
    >
      {children}
    </Resizable>
  ) : (
    <div className={classes.collapsed}>
      <LoadingBarPadding />
      <CollapsePaneButton open={open} setOpen={toggleOpen} type="Nutrition" />
    </div>
  );
};

export default NutritionPaneBody;

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import { FoodWorksTextInput } from "../../../../../common/FoodWorksTextInput";
import useClientName from "../hooks/useClientName";
import { NAME_FIELD } from "../../../../../../constants/textInputs";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 6,
  },
  input: {
    marginLeft: 10,
  },
}));

const NameInput = React.memo(
  (): JSX.Element => {
    const classes = useStyles();
    const [name, setName, updateName] = useClientName();

    return (
      <div className={classes.root}>
        <Typography variant="h4">Name</Typography>
        <FoodWorksTextInput
          maxLength={NAME_FIELD}
          className={classes.input}
          value={name}
          onChange={(event) => setName(event.currentTarget.value)}
          onBlur={updateName}
        />
      </div>
    );
  }
);

export default NameInput;

import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  Checkbox,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { appTheme } from "../../../../../../styling/style";
import { FoodworksTooltip } from "../../../../../common/InfoTooltip";
import { RootState } from "../../../../../../store/reducers";
import { currentClientIdSelector } from "../../../../../../store/data/selectors/clientDatabase";
import { handleRouteChange } from "../../../../../../store/ui/thunks/routing";
import { getClientDocumentRouteData } from "../../../../../../data/routing/routing";
import { SortingMethod, Order } from "../hooks/useDocumentData";

const useStyles = makeStyles(() => ({
  table: {
    borderTop: `solid 1px ${appTheme.colors.gainsbruh}`,
  },
  tableHead: {
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  icon: {
    color: `${appTheme.colors.primary} !important`,
  },
  indeterminate: {
    color: appTheme.colors.primary,
  },
}));

interface HeadCell {
  label: string;
  filter: SortingMethod;
  align: "left" | "right";
}

interface DocumentTableHeadProps {
  cells: HeadCell[];
  checked: boolean;
  indeterminate: boolean;
  selectAllRows: () => void;
  orderBy: Order;
  setOrderBy: (order: Order) => void;
  currentFilter: SortingMethod;
  setCurrentFilter: (filter: SortingMethod) => void;
}

const DocumentTableHead = ({
  cells,
  checked,
  indeterminate,
  selectAllRows,
  orderBy,
  setOrderBy,
  currentFilter,
  setCurrentFilter,
}: DocumentTableHeadProps): JSX.Element => {
  const classes = useStyles();
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="none"></TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            classes={{
              indeterminate: classes.indeterminate,
            }}
            checked={checked}
            indeterminate={indeterminate}
            onChange={selectAllRows}
          />
        </TableCell>
        {cells.map(
          (cell: HeadCell): ReactNode => (
            <TableCell key={cell.filter} align={cell.align} padding="default">
              <TableSortLabel
                classes={{ icon: classes.icon }}
                active={currentFilter === cell.filter}
                direction={orderBy === "asc" ? "asc" : "desc"}
                onClick={() =>
                  currentFilter === cell.filter
                    ? setOrderBy(orderBy === "asc" ? "desc" : "asc")
                    : setCurrentFilter(cell.filter)
                }
              >
                <Typography noWrap>{cell.label}</Typography>
                <span className={classes.visuallyHidden}>
                  {orderBy === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              </TableSortLabel>
            </TableCell>
          )
        )}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export interface SortData {
  method: SortingMethod;
  order: Order;
}

export interface RowData {
  documentId: string;
  name: string;
  startDate: string;
  endDate: string;
  createdDate: string;
  documentType: string;
  dayCount: number;
}

interface DocumentTableRowProps {
  row: RowData;
  index: number;
  isItemSelected: boolean;
  selectRow: (id: string) => void;
  onClickEdit: (id: string) => void;
}

const DocumentTableRow = React.memo<DocumentTableRowProps>(
  ({ row, index, isItemSelected, selectRow, onClickEdit }): JSX.Element => {
    return (
      <TableRow key={row.documentId}>
        <TableCell>{index}</TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onChange={() => selectRow(row.documentId)}
          />
        </TableCell>

        <TableCell align="left">
          {!row.startDate || !row.endDate
            ? "N/A"
            : `${new Date(row.startDate).toLocaleDateString()} - ${new Date(
                row.endDate
              ).toLocaleDateString()}`}
        </TableCell>
        <TableCell align="right">{row.dayCount}</TableCell>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">{row.documentType}</TableCell>
        <TableCell align="right">
          {new Date(row.createdDate).toLocaleDateString()}
        </TableCell>
        <TableCell align="left">
          <FoodworksTooltip title="Edit document">
            <IconButton
              onClick={() => onClickEdit(row.documentId)}
              size="small"
            >
              <EditOutlined color="secondary" />
            </IconButton>
          </FoodworksTooltip>
        </TableCell>
      </TableRow>
    );
  }
);

const COLUMN_WIDTHS = ["7%", "7%", "17.5%", "17.5%", "17.5%", "7%"];

const HEADER_CELLS: HeadCell[] = [
  { align: "left", label: "Date range", filter: "DATE_RANGE" },
  { align: "right", label: "Day count", filter: "DAY_COUNT" },
  { align: "left", label: "Document", filter: "DOCUMENT_NAME" },
  { align: "left", label: "Base Meal Plan", filter: "DOCUMENT_TYPE" },
  { align: "right", label: "Created date", filter: "DATE_CREATED" },
];

const DEFAULT_ROWS_PER_PAGE = 15;
const COMPRESSED_ROWS_PER_PAGE = 5;

interface DocumentTableProps {
  checkedItems: string[];
  setCheckedItems: (ids: string[]) => void;
  rows: RowData[];
  sortMethod: SortData;
  setSortMethod: (method: SortData) => void;
  stackTables: boolean;
}

const DocumentTable = ({
  checkedItems,
  setCheckedItems,
  rows,
  sortMethod,
  setSortMethod,
  stackTables,
}: DocumentTableProps): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const currentClientId: string = useSelector<RootState, string>(
    currentClientIdSelector
  );

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);

  const onNavigateToClientDocument = useCallback(
    (documentId: string) =>
      dispatch(
        handleRouteChange(
          getClientDocumentRouteData(currentClientId, documentId)
        )
      ),
    [dispatch, currentClientId]
  );

  const selectRow = useCallback(
    (id: string) => {
      setCheckedItems(
        checkedItems.includes(id)
          ? checkedItems.filter((docId: string) => id !== docId)
          : [...checkedItems, id]
      );
    },
    [setCheckedItems, checkedItems]
  );

  const selectAllRows = () =>
    setCheckedItems(
      checkedItems.length && checkedItems.length === rows.length
        ? []
        : rows.map((row: RowData): string => row.documentId)
    );

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setRowsPerPage(
      stackTables ? COMPRESSED_ROWS_PER_PAGE : DEFAULT_ROWS_PER_PAGE
    );
  }, [stackTables]);

  return (
    <>
      <TableContainer>
        <Table stickyHeader size="small" className={classes.table}>
          <colgroup>
            {COLUMN_WIDTHS.map((width: string, idx: number) => (
              <col key={`DocTableColumn ${idx}`} style={{ width }} />
            ))}
          </colgroup>
          <DocumentTableHead
            cells={HEADER_CELLS}
            selectAllRows={selectAllRows}
            checked={
              !!checkedItems.length && checkedItems.length === rows.length
            }
            indeterminate={
              !!checkedItems.length && rows.length > checkedItems.length
            }
            orderBy={sortMethod.order}
            setOrderBy={(order: Order) =>
              setSortMethod({ ...sortMethod, order })
            }
            currentFilter={sortMethod.method}
            setCurrentFilter={(method: SortingMethod) =>
              setSortMethod({ ...sortMethod, method })
            }
          />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                (row: RowData, index: number): ReactNode => (
                  <DocumentTableRow
                    key={row.documentId}
                    row={row}
                    index={page * rowsPerPage + index + 1}
                    selectRow={selectRow}
                    isItemSelected={checkedItems.includes(row.documentId)}
                    onClickEdit={onNavigateToClientDocument}
                  />
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default DocumentTable;

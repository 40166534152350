import { createSelector } from "reselect";

import { NutrientOverride } from "../../../../data/models/documentProperties/nutrientOverride";
import { RootState } from "../../../reducers";
import { NutrientsDataSelector } from "../../selectors/referenceData";
import { Nutrient } from "../../../../data/models/nutrient";
import {
  BaseCompositionSelector,
  FinalCompositionSelector,
} from "./currentDocument";
import { DocumentState } from "../reducers/document";
import { NutrientOverridesSelector } from "./nutrientOverrides";
import { VolumeConversionFactor } from "../../../../data/models/documentProperties/volumeConversionFactor";
import {
  Document,
  getUniqueDocumentIds,
  MethodContents,
} from "../../../../data/models/document";
import {
  NutrientValue,
  NutrientValues,
} from "../../../../data/models/nutrientValue";
import { CompositionState } from "../../../../data/models/composition";
import {
  databaseIdSelector,
  userIngredientSummariesSelector,
} from "../../selectors/database";
import { IngredientSummaryItem } from "../../../../components/screens/databases/documents/tabs/ingredients/editing_grid/rows/cells/IngredientCell";
import { allCachedDocumentsSelector } from "../../selectors/documentCache";
import { DocumentMap } from "../../reducers/documentCache";
import { enabledNutrientsSelector } from "../../selectors/databaseProperties";
import { WEIGHT_ID } from "../../../../constants/nutrientIds";

const DEFAULT_WEIGHT_FACTOR = 1.0;

export const documentSelector = (state: RootState): Document =>
  state.currentDocument.document;

export const nameSelector = (state: RootState): DocumentState["name"] =>
  state.currentDocument.document.name;

export const templateIdSelector = (
  state: RootState
): DocumentState["templateId"] => state.currentDocument.document.templateId;

export const methodSelector = (state: RootState): MethodContents[] =>
  state.currentDocument.document.method;

export const servesSelector = (state: RootState): DocumentState["serve"] =>
  state.currentDocument.document.serve;

export const yieldSelector = (state: RootState): DocumentState["yield"] =>
  state.currentDocument.document.yield;

export const documentTagIdsSelector = (state: RootState): string[] =>
  state.currentDocument.document.documentTags;

export const documentPropertiesSelector = (
  state: RootState
): DocumentState["properties"] => state.currentDocument.document.properties;

export const isLiquidSelector = (state: RootState): boolean =>
  state.currentDocument.document.properties.isLiquid;

export const volumeConversionSelector = (
  state: RootState
): VolumeConversionFactor =>
  new VolumeConversionFactor(state.currentDocument.document.volumeConversion);

export const NutrientValuesSelector = createSelector<
  RootState,
  Map<string, Nutrient>,
  string[],
  CompositionState,
  CompositionState,
  NutrientOverride[],
  NutrientValues
>(
  NutrientsDataSelector,
  enabledNutrientsSelector,
  BaseCompositionSelector,
  FinalCompositionSelector,
  NutrientOverridesSelector,

  (
    nutrients,
    enabledNutrientIds,
    baseComposition,
    finalComposition,
    nutrientOverrides
  ) => {
    const filteredNutrients: Map<string, Nutrient> = new Map(
      [...nutrients].filter(([id]: [string, Nutrient]) =>
        enabledNutrientIds.includes(id)
      )
    );
    return NutrientValues.fromState(
      DEFAULT_WEIGHT_FACTOR,
      filteredNutrients,
      baseComposition,
      finalComposition,
      nutrientOverrides
    );
  }
);

export const OverridesNutrientValuesSelector = createSelector<
  RootState,
  Map<string, Nutrient>,
  CompositionState,
  CompositionState,
  NutrientOverride[],
  NutrientValues
>(
  NutrientsDataSelector,
  BaseCompositionSelector,
  FinalCompositionSelector,
  NutrientOverridesSelector,

  (nutrients, baseComposition, finalComposition, nutrientOverrides) => {
    const weightOverrideValue: number = nutrientOverrides.find(
      (override: NutrientOverride): boolean => override.id === WEIGHT_ID
    )?.value!;
    const weightValue: number =
      baseComposition[WEIGHT_ID] || weightOverrideValue;
    let weightFactor = weightOverrideValue / weightValue;
    if (isNaN(weightFactor)) {
      weightFactor = 0;
    }

    return NutrientValues.fromState(
      weightFactor,
      nutrients,
      baseComposition,
      finalComposition,
      nutrientOverrides
    );
  }
);

export const overridesNutrientValueSelector = (id: string) =>
  createSelector<RootState, NutrientValues, NutrientValue>(
    OverridesNutrientValuesSelector,
    (overrideNutrientValues) => overrideNutrientValues.nutrientValue(id)
  );

export const calculationMethodSelector = (state: RootState): number =>
  state.currentDocument.document.calculationMethod;

export const documentMappingIdSelector = (state: RootState): string =>
  state.currentDocument.document.documentMappingId;

export const documentsUsedInStateSelector = (state: RootState): string[] =>
  state.currentDocument.document.usedIn;

const documentIdIdentitySelector = (_: RootState, documentId: string): string =>
  documentId;

const documentIdentitySelector = (
  _: RootState,
  document: Document | undefined
): Document | undefined => document;

export const documentsUsedInSelector = () =>
  createSelector(
    documentIdentitySelector,
    userIngredientSummariesSelector,
    (document, summaries) => {
      if (!document) return [];

      const documentData: IngredientSummaryItem[] = [];
      for (const id of document.usedIn) {
        const summary = summaries.find(
          (summary: IngredientSummaryItem): boolean =>
            summary.foodId.identifier === id
        );
        if (!summary) {
          return [];
        }
        documentData.push(summary);
      }

      return documentData;
    }
  );

export interface SummaryData {
  summary: IngredientSummaryItem;
  children: SummaryData[];
}

const getSummaries = (
  summaryData: SummaryData,
  summaries: IngredientSummaryItem[],
  documentCache: DocumentMap,
  databaseId: string
) => {
  const document: Document =
    documentCache[summaryData.summary.foodId.identifier];

  if (!document) return;

  const uniqueIds: string[] = getUniqueDocumentIds([databaseId], document);

  for (const id of uniqueIds) {
    const summary = summaries.find(
      (summary: IngredientSummaryItem): boolean =>
        summary.foodId.identifier === id
    );
    if (!summary) {
      return;
    }
    const newSummaryData: SummaryData = { summary: summary, children: [] };
    getSummaries(newSummaryData, summaries, documentCache, databaseId);
    summaryData.children.push(newSummaryData);
  }
};

export const documentsContainedSelector = () =>
  createSelector(
    documentIdIdentitySelector,
    allCachedDocumentsSelector,
    userIngredientSummariesSelector,
    databaseIdSelector,
    (documentId, documentCache, summaries, databaseId) => {
      if (!documentId) return [];

      const summary: IngredientSummaryItem = summaries.find(
        (summaryItem: IngredientSummaryItem): boolean =>
          summaryItem.foodId.identifier === `${databaseId}:${documentId}`
      )!;

      if (!summary) return [];

      const summaryData: SummaryData = { summary: summary, children: [] };

      getSummaries(summaryData, summaries, documentCache, databaseId);

      return summaryData.children;
    }
  );

import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Collapse, makeStyles, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { RootState } from "../../../../../store/reducers";
import { appTheme } from "../../../../../styling/style";
import Barchart from "./components/Barchart";
import Dropdown from "./components/Dropdown";
import { enabledNutrientsDataSelector } from "../../../../../store/data/selectors/referenceData";
import { Nutrient } from "../../../../../data/models/nutrient";
import { BarProps } from "./components/Bar";
import useIngredientSources from "./hooks/useIngredientSources";

const graphHeight = 300;
export const titleBarHeight = 35;

const useStyles = makeStyles(() => ({
  base: {
    width: "100%",
    flexDirection: "column",
    display: "flex",
    backgroundColor: appTheme.colors.white[4],
    overflowX: "hidden",
  },
  expandIcon: {
    color: appTheme.colors.secondary,
  },
  title: {
    display: "flex",
    padding: 5,
    justifyContent: "center",
    width: "100%",
    backgroundColor: appTheme.colors.white[0],
    border: `1px solid ${appTheme.colors.gainsbruh}`,
    borderRadius: 0,
  },
  titleText: {
    color: appTheme.colors.secondary,
  },
  content: {
    height: graphHeight,
    overflowY: "auto",
    padding: "0px 3px",
  },
}));

interface bar {
  label: string;
  value: string;
  barWidth: string;
  percentage: string;
}

const barComparator = (a: bar, b: bar): number => {
  // Sorts bar graph data by greatest percentage in decending order
  const perc_a = Number(a.percentage.slice(0, -1));
  const perc_b = Number(b.percentage.slice(0, -1));

  if (perc_a < perc_b) {
    return 1;
  }
  if (perc_a > perc_b) {
    return -1;
  }
  return 0;
};

const convertToChartData = (
  ingredients: Map<string, number>,
  nutrientCompositionTotal: number
): BarProps[] =>
  [...ingredients]
    .map(([id, value]): BarProps => {
      // Constructs bar data for bar graph from ingredient map.
      return {
        label: "" + id,
        value: "",
        barWidth: "20px",
        percentage: ((value / nutrientCompositionTotal) * 100).toFixed(0) + "%",
        barColor: "#A0EBCE",
      };
    })
    .sort(barComparator);

export const SourcesGraph = () => {
  const classes = useStyles();

  const enabledNutrients: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(enabledNutrientsDataSelector);

  const [expanded, setExpanded] = useState(false);
  const [nutrientId, setNutrientId] = useState("0");

  const [nutrientCompositionTotal, ingredients] =
    useIngredientSources(nutrientId);
  const expand = () => setExpanded(!expanded);

  const bars = useMemo(
    () => convertToChartData(ingredients, nutrientCompositionTotal),
    [ingredients, nutrientCompositionTotal]
  );

  const handleChange = (id: string) => {
    if (id) {
      setNutrientId(id);
    }
  };

  return (
    <div className={classes.base}>
      <Button className={classes.title} onClick={expand}>
        {expanded ? (
          <Typography noWrap={true} className={classes.titleText} variant="h2">
            Hide Sources
          </Typography>
        ) : (
          <Typography noWrap={true} className={classes.titleText} variant="h2">
            Show Sources
          </Typography>
        )}
        {expanded ? (
          <ExpandMore className={classes.expandIcon} />
        ) : (
          <ExpandLess className={classes.expandIcon} />
        )}
      </Button>
      <Collapse in={expanded}>
        <div className={classes.content}>
          <Dropdown
            enabledNutrients={enabledNutrients}
            handleChange={handleChange}
            nutrientId={nutrientId}
          />
          <Barchart data={bars} />
        </div>
      </Collapse>
    </div>
  );
};

export default SourcesGraph;

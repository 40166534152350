import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { DataSource } from "../../../data/models/datasource";
import { DataSourceMap } from "../../../store/data/reducers/referenceData";
import {
  IsAlternateIdsEnabledSelector,
  enabledDatasourcesSelector,
} from "../../../store/data/selectors/databaseProperties";
import { referenceDataSourceSelector } from "../../../store/data/selectors/referenceData";
import {
  updateEnabledAlternateIds,
  updateEnabledDatasources,
} from "../../../store/data/thunks/databaseProperties";
import { RootState } from "../../../store/reducers";
import { appTheme } from "../../../styling/style";
import {
  CopyDatabaseProperty,
  CopyPropertiesButton,
} from "../../common/CopyPropertiesButton";
import { BaseDialog } from "../BaseDialog";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    margin: "10px 0px",
  },
  listButton: {
    borderRadius: 4,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
    padding: "0px",
    alignItems: "center",
  },
  selectedListButton: {
    borderRadius: 4,
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
    padding: "0px",
    alignItems: "center",
  },
}));

const dataSourceReducer = (
  accumulator: Map<string, DataSourceValues>,
  currentValue: DataSource,
  enabledDataSources: string[]
) => {
  const value: DataSourceValues = {
    name: currentValue.name,
    id: currentValue.id,
    isOpen: enabledDataSources.includes(currentValue.id),
  };
  return accumulator.set(currentValue.id, value);
};

const getDataSourceMap = (
  enabledDataSources: string[],
  dataSourceData: DataSourceMap
) =>
  Object.values(dataSourceData).reduce<Map<string, DataSourceValues>>(
    (dataSourceMap, dataSourceValues) =>
      dataSourceReducer(dataSourceMap, dataSourceValues, enabledDataSources),
    new Map()
  );

interface DataSourceDialogProps {
  open: boolean;
  onClose: () => void;
}

interface DataSourceValues {
  name: string;
  id: string;
  isOpen: boolean;
}

export const DataSourceDialog: FunctionComponent<DataSourceDialogProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUpdateEnabledDatasources = (dataSourceIds: string[]) =>
    dispatch(updateEnabledDatasources(dataSourceIds));

  const onUpdateEnabledAlternateIds = (isEnabled: boolean) =>
    dispatch(updateEnabledAlternateIds(isEnabled));

  const enabledDataSources = useSelector<RootState, string[]>(
    enabledDatasourcesSelector
  );

  const dataSourceData = useSelector<RootState, DataSourceMap>(
    referenceDataSourceSelector
  );

  const [dataSourceMap, setDataSourceMap] = useState<
    Map<string, DataSourceValues>
  >(new Map());

  const isAlternateIdsEnabled = useSelector<RootState, boolean>(
    IsAlternateIdsEnabledSelector
  );

  const [isEnabledAlternateIds, setAlternateEnableIds] = useState<boolean>(
    false
  );

  useEffect(() => {
    setAlternateEnableIds(isAlternateIdsEnabled);
  }, [isAlternateIdsEnabled]);

  const loadDataSourceMap = useCallback(
    (enabledDataSources: string[]) => {
      const newDataSourceMap = getDataSourceMap(
        enabledDataSources,
        dataSourceData
      );
      setDataSourceMap(newDataSourceMap);
    },
    [dataSourceData]
  );

  useEffect(() => {
    loadDataSourceMap(enabledDataSources);
  }, [loadDataSourceMap, enabledDataSources]);

  const applyChanges = () => {
    const enabledDataSourceIds: string[] = [];
    for (const dataSourceValues of dataSourceMap.values()) {
      if (dataSourceValues.isOpen)
        enabledDataSourceIds.push(dataSourceValues.id);
    }
    onUpdateEnabledDatasources(enabledDataSourceIds);
    onUpdateEnabledAlternateIds(isEnabledAlternateIds);
    onClose();
  };

  const handleClose = () => {
    loadDataSourceMap(enabledDataSources);
    onClose();
  };

  const handleDataSourceOnClick = (dataSourceId: string) => {
    const value: DataSourceValues | undefined = dataSourceMap.get(dataSourceId);

    if (value) {
      const newValue: DataSourceValues = { ...value, isOpen: !value.isOpen };
      setDataSourceMap(new Map(dataSourceMap.set(dataSourceId, newValue)));
    }
  };

  const onClickEnableAlternateIds = () => {
    setAlternateEnableIds(!isEnabledAlternateIds);
  };

  const checkBoxes: ReactNode = (
    <List>
      {[...dataSourceMap].map(
        ([key, dataSource]: [string, DataSourceValues]): ReactNode => (
          <ListItem
            key={key}
            button
            onClick={() => handleDataSourceOnClick(key)}
            className={
              dataSource.isOpen
                ? classes.selectedListButton
                : classes.listButton
            }
          >
            <Checkbox
              data-cy="dataSourceCheckbox"
              checked={dataSource.isOpen}
            />
            <Typography>{dataSource.name}</Typography>
          </ListItem>
        )
      )}
    </List>
  );

  const body: ReactNode = [
    <Typography>Choose data sources from which to select reference foods from. {"\n"}
    For Australian users we recommend using AusBrands and AusFoods, New Zealand users select NZ FOODfiles.</Typography>,
    <br/>,
    <Typography
      key="dataSourceDialog-bodyTypography"
      className={classes.sectionContainer}
    >
      Data sources
    </Typography>,
    <Card key="dataSourceDialog-bodyCard">
      <CardContent>{checkBoxes}</CardContent>
    </Card>,
    <CopyPropertiesButton
      key="dataSourceDialog-copyPropButton"
      databaseProperty={CopyDatabaseProperty.ENABLED_DATASOURCES}
      fetchDatabaseProperties={(ids: string[]) => loadDataSourceMap(ids)}
    />,
    <ListItem
      key={"enableAlternateIds"}
      button
      onClick={onClickEnableAlternateIds}
      className={
        isEnabledAlternateIds ? classes.selectedListButton : classes.listButton
      }
    >
      <Checkbox
        data-cy="enableAlternateIdsCheckbox"
        checked={isEnabledAlternateIds}
      />
      <Typography>Enable alternate IDs</Typography>
    </ListItem>,
  ];

  const action: ReactNode = [
    <Button
      key="dataSourceDialog-cancel"
      data-cy="dataSourceCancel"
      onClick={handleClose}
    >
      Cancel
    </Button>,
    <Button
      key="dataSourceDialog-apply"
      data-cy="dataSourceApply"
      onClick={applyChanges}
    >
      Apply
    </Button>,
  ];

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title="Data sources"
      body={body}
      action={action}
      maxWidth="sm"
      dataCy="dataSourceDialog"
    />
  );
};

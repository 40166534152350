import { createSelector } from "reselect";

import {
  Section,
  SectionState,
} from "../../../../data/models/documentProperties/section";
import { RootState } from "../../../reducers";
import { getRetentionFactorMap } from "../../selectors/referenceData";
import { dayStateSelector } from "./days";

export const sectionStateSelector = (
  rootState: RootState,
  dayIndex: number,
  sectionIndex: number
): SectionState => dayStateSelector(rootState, dayIndex).sections[sectionIndex];

export const sectionCreateSelector = createSelector(
  sectionStateSelector,
  getRetentionFactorMap,
  (sectionState, retentionFactorMap) =>
    Section.fromObject(sectionState, retentionFactorMap)
);

export const sectionSelector = () => sectionCreateSelector;

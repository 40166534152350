import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";

import {
  Button,
  Card,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Cancel, Check } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { BaseDialog } from "../BaseDialog";
import Firebase, { withFirebase } from "../../../data/Firebase";
import { FoodWorksTextInput } from "../../common/FoodWorksTextInput";
import { appTheme } from "../../../styling/style";
import { InfoTooltip } from "../../common/InfoTooltip";
import { RootState } from "../../../store/reducers";
import {
  databaseIdSelector,
  sharingHistorySelector,
} from "../../../store/data/selectors/database";
import { SharingHistory } from "../../../data/models/userDatabase";
import { getCurrentDate } from "../../../data/models/documentProperties/date";
import { updateDatabaseSharingHistory } from "../../../store/data/thunks/database";
import { MEDIUM_FIELD } from "../../../constants/textInputs";

const useStyles = makeStyles({
  bodyRoot: {
    display: "flex",
    flexDirection: "column",
  },
  emailSearchRoot: {
    display: "flex",
    flexDirection: "column",
    marginTop: 25,
  },
  emailSearchContainer: {
    display: "flex",
    alignItems: "center",
  },
  emailSearchInput: {
    marginTop: 3,
    width: 300,
  },
  searchButton: {
    marginLeft: 5,
  },
  confirmationBoldText: {
    fontWeight: 600,
    marginLeft: 3,
    whiteSpace: "nowrap",
  },
  confirmationText: {
    whiteSpace: "nowrap",
  },
  confirmationTextSpace: {
    marginLeft: 3,
    whiteSpace: "nowrap",
  },
  accountConfirmationRoot: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
    overflow: "wrap",
  },
  checkIcon: {
    color: appTheme.colors.success,
  },
  cancelIcon: {
    color: appTheme.colors.error,
  },
  confirmationTextContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 5,
  },
  shareDatabaseRoot: {
    display: "flex",
    marginTop: 5,
    alignItems: "center",
  },
  shareDatabaseButton: {
    marginRight: 5,
  },
  sharingHistoryRoot: { width: "60%" },
  sharingHistoryCard: {
    marginTop: 10,
  },
  sharingHistoryList: {
    height: 300,
    overflow: "auto",
  },
});

interface ShareDatabaseDialogProps {
  open: boolean;
  onClose: () => void;
  firebase?: Firebase;
}

export interface SharedUserData {
  name: string;
  uid: string;
}

const ShareDatabaseDialogInner: FunctionComponent<ShareDatabaseDialogProps> = ({
  open,
  onClose,
  firebase,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUpdateDatabaseSharingHistory = (history: SharingHistory[]) =>
    dispatch(updateDatabaseSharingHistory(history));

  const currentDatabaseId: string = useSelector<RootState, string>(
    databaseIdSelector
  );

  const sharingHistory: SharingHistory[] = useSelector<
    RootState,
    SharingHistory[]
  >(sharingHistorySelector);

  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState<boolean | null>(null);
  const [sharedUser, setSharedUser] = useState<SharedUserData>({
    name: "",
    uid: "",
  });

  useEffect(() => {
    setEmailValid(null);
  }, [email]);

  const onSearchForEmail = async (): Promise<void> => {
    const data = await firebase?.functions.doGetUserNameByEmail(email);
    if (!data) {
      setEmailValid(false);
      return;
    }
    setEmailValid(true);
    setSharedUser({
      name: `${data.firstName} ${data.lastName}`,
      uid: data.uid,
    });
  };

  const accountConfirmation: ReactNode = emailValid !== null && (
    <div className={classes.accountConfirmationRoot}>
      {emailValid ? (
        <Check className={classes.checkIcon} />
      ) : (
        <Cancel className={classes.cancelIcon} />
      )}
      {emailValid ? (
        <div className={classes.confirmationTextContainer}>
          <Typography className={classes.confirmationText}>
            Found an account associated with the email
          </Typography>
          <Typography className={classes.confirmationBoldText}>
            {email}
          </Typography>
          <Typography className={classes.confirmationTextSpace}>
            belonging to
          </Typography>
          <Typography className={classes.confirmationBoldText}>
            {sharedUser.name}
          </Typography>
        </div>
      ) : (
        <div className={classes.confirmationTextContainer}>
          <Typography>
            No account was found associated with the email
          </Typography>
          <Typography className={classes.confirmationBoldText}>
            {email}
          </Typography>
        </div>
      )}
    </div>
  );

  const sharingHistoryList: ReactNode = (
    <div className={classes.sharingHistoryRoot}>
      <Typography>Sharing history </Typography>
      <Card className={classes.sharingHistoryCard}>
        <List className={classes.sharingHistoryList}>
          {sharingHistory.map(
            (history: SharingHistory, index: number): ReactNode => (
              <ListItem key={index}>
                <Typography>
                  {`Shared with ${history.recipient} on ${new Date(
                    history.dateShared
                  ).toLocaleDateString()}`}
                </Typography>
              </ListItem>
            )
          )}
        </List>
      </Card>
    </div>
  );

  const emailSearch = (
    <div className={classes.emailSearchRoot}>
      <Typography>Enter the email of the account to share with</Typography>

      <div className={classes.emailSearchContainer}>
        <FoodWorksTextInput
          maxLength={MEDIUM_FIELD}
          className={classes.emailSearchInput}
          value={email}
          onChange={(event) => setEmail(event.currentTarget.value)}
        />
        <Button
          onClick={onSearchForEmail}
          className={classes.searchButton}
          variant="outlined"
          color="secondary"
        >
          Search
        </Button>
        {accountConfirmation}
      </div>
    </div>
  );

  const onClickShareDatabase = async () => {
    const response = await firebase!.shareDatabase(
      sharedUser.uid,
      currentDatabaseId
    );
    if (response.success) {
      onUpdateDatabaseSharingHistory([
        ...sharingHistory,
        { dateShared: getCurrentDate(), recipient: sharedUser.name },
      ]);
      setEmail("");
      setEmailValid(null);
      setSharedUser({ name: "", uid: "" });
    }
  };

  const shareDatabase: ReactNode = (
    <div className={classes.shareDatabaseRoot}>
      <Button
        className={classes.shareDatabaseButton}
        color="secondary"
        variant="outlined"
        disabled={!emailValid}
        onClick={onClickShareDatabase}
      >
        Share database
      </Button>
      <InfoTooltip title="This will create a copy of this database for the above user. You will not be able to see any changes they make to the shared database"></InfoTooltip>
    </div>
  );

  const body: ReactNode = (
    <div className={classes.bodyRoot}>
      <Typography>Share a copy of your Foodworks database with another Foodworks user.</Typography>
      <br/>
      {sharingHistoryList}
      {emailSearch}
      {shareDatabase}
    </div>
  );

  const actions: ReactNode = [<Button onClick={onClose}>Close</Button>];

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      body={body}
      action={actions}
      title="Share database"
      maxWidth="md"
    />
  );
};

export const ShareDatabaseDialog = withFirebase(ShareDatabaseDialogInner);

import { Action } from "redux";
import { LoginError } from "../thunks/login";

export const SET_SIGN_UP_ERROR = "SET_SIGN_UP_ERROR";

export interface IActionsSetSignUpError extends Action {
  type: "SET_SIGN_UP_ERROR";
  error: LoginError;
}

export type LoginActions = IActionsSetSignUpError;

export class FoodId {
  datasourceId: string;
  documentId: string;

  constructor(foodId: FoodIdObject) {
    this.datasourceId = foodId!.datasourceId;
    this.documentId = foodId!.documentId;
  }

  static fromIdentifier(identifier: string): FoodId {
    const [databaseId, documentId] = getIdentifierParts(identifier);
    return new FoodId({ datasourceId: databaseId, documentId: documentId });
  }

  public get identifier(): string {
    return `${this.datasourceId}:${this.documentId}`;
  }
}

export interface FoodIdObject {
  datasourceId: string;
  documentId: string;
}

export const foodIdConverter = {
  toJson: (foodId: FoodIdObject | undefined): FoodIdObject => {
    return !foodId
      ? { datasourceId: "undefined", documentId: "undefined" }
      : {
          datasourceId: foodId.datasourceId,
          documentId: foodId.documentId,
        };
  },

  fromJson: (
    data: firebase.firestore.DocumentData
  ): FoodIdObject | undefined => {
    if (!data) return undefined;
    return data.datasourceId === "undefined" && data.documentId === "undefined"
      ? undefined
      : {
          datasourceId: data.datasourceId,
          documentId: data.documentId,
        };
  },
};

export const getIdentifier = (databaseId: string, documentId: string) =>
  `${databaseId}:${documentId}`;

export const getIdentifierParts = (identifier: string): [string, string] => {
  const idParts: string[] = identifier.split(":");
  return [idParts[0], idParts[1]];
};

export const getFoodId = (identifier: string): FoodIdObject => {
  const [datasourceId, documentId] = getIdentifierParts(identifier);
  return { datasourceId, documentId };
};

import React, { FunctionComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { appTheme } from "../../../../../../styling/style";
import { RootState } from "../../../../../../store/reducers";
import {
  methodSelector,
  templateIdSelector,
} from "../../../../../../store/data/current-document/selectors/document";
import { createMethodSection } from "../../../../../../store/data/current-document/action-creators/document";
import { hasYieldAndServe } from "../../../../../../constants/FoodTemplate";
import { YieldAndServeSelection } from "./YieldAndServeSelection";
import { MethodSection } from "./MethodSection";
import { MethodAddSectionButton } from "./MethodAddSectionButton";
import { MethodContents } from "../../../../../../data/models/document";

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: appTheme.colors.white[2],
    border: `1px solid ${appTheme.colors.gainsbro}`,
    borderRadius: 4,
    boxShadow: "none",
    flex: 4,
    overflowY: "auto",
    padding: 4,
    paddingBottom: 30,
  },
  paper: {
    display: "flex",
    width: "100%",
    flex: 1,
    flexDirection: "column",
    padding: 10,
    marginTop: 10,
    background: appTheme.colors.white[0],
    overflowY: "auto",
  },
  addSectionButton: {
    color: appTheme.colors.xiketic,
  },
  addIcon: {
    color: appTheme.colors.success,
  },
  buttonContainer: {
    marginLeft: 10,
    marginTop: 10,
    display: "flex",
  },
}));

export const MethodTab: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const documentMethod = useSelector<RootState, MethodContents[]>(
    methodSelector
  );
  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const onAddMethodSection = (newSection: MethodContents): void => {
    dispatch(createMethodSection(newSection));
  };

  const nextIndex: number =
    documentMethod.reduce((prev, current) =>
      prev.index > current.index ? prev : current
    ).index + 1;
  const isOnlySectionOfMethod: boolean = !(documentMethod.length > 1);

  const sections = documentMethod.map((section, i) => {
    return (
      <MethodSection
        isOnlySection={isOnlySectionOfMethod}
        sectionContents={section}
        key={`methodSection-${i}`}
      />
    );
  });

  return (
    <div className={classes.paper}>
      {hasYieldAndServe(templateId) && <YieldAndServeSelection />}
      <div className={classes.root} data-cy="method">
        {sections}
        <div className={classes.buttonContainer}>
          <MethodAddSectionButton
            isOnlySection={isOnlySectionOfMethod}
            nextIndex={nextIndex}
            addMethodSection={onAddMethodSection}
          />
        </div>
      </div>
    </div>
  );
};

import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { SettingsRounded } from "@material-ui/icons";
import {
  Image,
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import { Nutrient } from "../../../../data/models/nutrient";
import { NRVType } from "../../../../data/models/nutrientReferenceValues";
import logo from "../../../../images/foodworks_horizontal.png";
import { CurrentDocumentState } from "../../../../store/data/current-document/reducers/currentDocument";
import { CurrentDocumentSelector } from "../../../../store/data/current-document/selectors/currentDocument";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import { FoodWorksTextInput } from "../../../common/FoodWorksTextInput";
import { DisabledButtonWithToolTip } from "../../../common/InfoTooltip";
import { BaseDialog } from "../../../dialogs/BaseDialog";
import useIntakeGoals from "../../clients/tabs/analysis/hooks/useIntakeGoals";
import useFoodItemReport from "./hooks/useFoodItemReport";
import { DayState } from "../../../../data/models/documentProperties/day";
import { FoodIdObject } from "../../../../data/models/documentProperties/foodId";
import { Quantity } from "../../../../data/models/documentProperties/quantity";

const useStyles = makeStyles(() => ({
  button: {
    padding: 1,
    margin: 1,
  },
  studentDetailsSection: {
    margin: 5,
  },
}));

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#F6F6F9",
  },
  section: {
    padding: 20,
  },
  foodSection: {
    margin: 10,
    flexGrow: 1,
    fontSize: 12,
  },
  categoryHeader: {
    fontSize: 16,
    paddingBottom: 5,
    fontWeight: "heavy",
    borderBottom: 1,
  },
  sectionHeader: {
    marginLeft: 5,
    borderBottom: 1,
    borderStyle: "dotted",
    borderColor: appTheme.colors.gainsbruh,
    fontSize: 12,
  },
  dayHeader: {
    color: appTheme.colors.primary,
    fontSize: 12,
    marginBottom: 10,
  },
  nameSection: {
    padding: 20,
    borderTop: 2,
    borderBottom: 1,
    borderColor: appTheme.colors.barGreen,
  },
  studentName: {
    fontSize: 20,
    color: appTheme.colors.primary,
  },
  descriptionText: {
    fontSize: 12,
  },
  logo: {
    width: "30%",
  },
  logoContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 50,
  },
  foodItem: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  analysisText: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 12,
  },
  analysisTextNil: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 12,
    color: appTheme.colors.gainsbruh,
  },
  nutrientColumn: {
    width: "35%",
    borderBottom: 1,
    borderColor: appTheme.colors.gainsbruh,
  },
  column: {
    width: "15%",
    textAlign: "right",
    borderBottom: 1,
    borderColor: appTheme.colors.gainsbruh,
  },
  EARcolumn: {
    width: "10%",
    textAlign: "right",
    borderBottom: 1,
    borderColor: appTheme.colors.gainsbruh,
  },
  alertColumn: {
    width: "30%",
    textAlign: "right",
    borderBottom: 1,
    borderColor: appTheme.colors.gainsbruh,
  },
  downloadLink: {
    padding: 10,
    color: appTheme.colors.white[0],
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    color: appTheme.colors.primary,
    padding: 10,
    fontSize: 10,
  },
});
Font.registerEmojiSource({
  format: "png",
  url: "https://twemoji.maxcdn.com/2/72x72/",
});

const GenerateDocumentSummary = (props: {
  document: CurrentDocumentState;
  foodMap: Map<
    DayState,
    Map<
      string,
      Map<
        {
          measureName: string | undefined;
          foodId: FoodIdObject | undefined;
          quantity: Quantity | undefined;
          frequency: string;
          rowIndex: number;
          retentionFactorId: string | null;
          note: string;
        },
        string
      >
    >
  >;
  nutrientMap: Map<string, number>;
  allNutrientsMap: Map<string, Nutrient>;
  NRVMap: Map<NRVType, Map<string, number>>;
  name: string;
}) => {
  const { document, foodMap, nutrientMap, allNutrientsMap, NRVMap, name } =
    props;
  const description = document.document.description;

  const generateDays = (): JSX.Element[] => {
    const struct = [...foodMap].map(
      ([day, daySections]: [DayState, any], index) => {
        return (
          <>
            <Text key={`${day.title} ${index}`} style={styles.dayHeader}>
              {`${day.title} ${
                !!day.date.length
                  ? " - " + new Date(day.date).toLocaleDateString()
                  : " "
              }`}
            </Text>
            {[...daySections].map(([sectionName, section]) => {
              return (
                <>
                  <Text style={styles.sectionHeader}>{sectionName}</Text>
                  <View style={styles.foodSection}>
                    {[...section].map(([foodItem, foodName]) => {
                      return (
                        <>
                          <View style={styles.foodItem}>
                            <Text>{foodName ? `- ${foodName}` : ""}</Text>
                            <Text>
                              {foodName
                                ? `${
                                    foodItem.quantity?.amount || "Nil Amount"
                                  } ${foodItem.measureName || ""}`
                                : ""}
                            </Text>
                          </View>
                        </>
                      );
                    })}
                  </View>
                </>
              );
            })}
          </>
        );
      }
    );
    return struct;
  };

  const generateDocumentAnalysisSummary = () => {
    const EAR: Map<string, number> | undefined = NRVMap.get("EAR");
    return (
      <>
        <View style={styles.analysisText}>
          <Text style={styles.nutrientColumn}>Nutrient</Text>
          <Text style={styles.column}>AVG/DAY</Text>
          <Text style={styles.EARcolumn}>EAR</Text>
          <Text style={styles.EARcolumn}>EAR %</Text>
          <Text style={styles.alertColumn}>Alert</Text>
        </View>
        {[...nutrientMap].map(
          ([name, value]: [string, number], index: number) => {
            return (
              <View
                key={`Analysis ${name} ${value}`}
                style={styles.analysisText}
              >
                <Text style={styles.nutrientColumn}>
                  {allNutrientsMap.get(name)?.name}
                </Text>
                <Text style={styles.column}>{`${value.toFixed(2)} ${
                  allNutrientsMap.get(name)?.units === "μg"
                    ? "ug"
                    : allNutrientsMap.get(name)?.units
                }`}</Text>
                <Text style={styles.EARcolumn}>
                  {EAR?.get(name)?.toFixed(2)}
                </Text>
                <Text style={styles.EARcolumn}>
                  {EAR?.get(name)
                    ? ((value / EAR?.get(name)!) * 100).toFixed(0) + "%"
                    : ""}
                </Text>
                <Text style={styles.alertColumn}>
                  {[...NRVMap]
                    .map(
                      ([NRVType, valueMap]: [NRVType, Map<string, number>]) => {
                        return valueMap.get(name) && valueMap.get(name)! < value
                          ? `<${NRVType as string}`
                          : "";
                      }
                    )
                    .filter((str: string) => str !== "")
                    .toString()}
                </Text>
              </View>
            );
          }
        )}
      </>
    );
  };

  const days: JSX.Element[] = generateDays();
  const nutrients: JSX.Element = generateDocumentAnalysisSummary();
  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        <View fixed style={styles.logoContainer}>
          <Image fixed style={styles.logo} src={logo}></Image>
        </View>

        <View style={styles.nameSection}>
          <Text style={styles.studentName}>{document.document.name}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.categoryHeader}>Description</Text>
          <Text style={styles.descriptionText}>
            {description || "Enter a description in the general tab"}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.categoryHeader}>Food Records</Text>
          {days}
        </View>
        <View style={styles.section}>
          <Text style={styles.categoryHeader}>Analysis Summary</Text>
          <View>{nutrients}</View>
        </View>

        <View fixed style={styles.footer}>
          <Text>{name}</Text>
          <Text>{`${new Date(Date.now()).toLocaleDateString()}`}</Text>
        </View>
      </Page>
    </Document>
  );
};

const SaveReportButton = (): JSX.Element => {
  const document: CurrentDocumentState = useSelector<
    RootState,
    CurrentDocumentState
  >(CurrentDocumentSelector);

  const [name, setName] = useState<string>("");
  const [generating, setGenerating] = useState<boolean>(false);
  const MyDocument = GenerateDocumentSummary;
  const { foodMap, nutrientMap, allNutrientsMap } = useFoodItemReport(document);
  const [NRVMap] = useIntakeGoals();

  return (
    <>
      <FoodWorksTextInput
        placeholder={"Author's Name / ID"}
        onChange={(
          event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => setName(event.target.value)}
      />
      {!generating && (
        <DisabledButtonWithToolTip
          buttonProps={{
            color: "secondary",
            className: "",
            variant: "contained",
            onClick: () => setGenerating(true),
          }}
          label="Generate Report"
          disabled={false}
          enabledTooltip="Generate the report"
          disabledTooltip=""
        />
      )}
      {generating && (
        <DisabledButtonWithToolTip
          buttonProps={{
            style: { padding: 0 },
            color: "secondary",
            className: "",
            variant: "contained",
            onClick: () => setGenerating(false),
          }}
          label={
            <PDFDownloadLink
              style={styles.downloadLink}
              document={
                <MyDocument
                  foodMap={foodMap}
                  nutrientMap={nutrientMap}
                  allNutrientsMap={allNutrientsMap}
                  NRVMap={NRVMap}
                  document={document}
                  name={name}
                />
              }
              fileName={document.document.name + ".pdf"}
            >
              {({ loading }) => (loading ? "Generating..." : "Save now!")}
            </PDFDownloadLink>
          }
          disabled={false}
          enabledTooltip={
            generating
              ? "Report is now being generated, please wait"
              : "Save report"
          }
          disabledTooltip=""
        />
      )}
    </>
  );
};

const ClientReportButton = (): JSX.Element => {
  const classes = useStyles();

  const currentDocument: CurrentDocumentState = useSelector<
    RootState,
    CurrentDocumentState
  >(CurrentDocumentSelector);

  const [open, setOpen] = useState<boolean>(false);

  const documentValid = currentDocument.id !== "";
  const closeModal = () => setOpen(false);

  return (
    <>
      {open && (
        <BaseDialog
          maxWidth="md"
          open={open}
          title={`Generate Report For ${currentDocument.document.name}`}
          body={<>{documentValid && <SaveReportButton />}</>}
          onClose={closeModal}
        />
      )}
      <DisabledButtonWithToolTip
        buttonProps={{
          startIcon: <SettingsRounded />,
          color: "secondary",
          className: classes.button,
          onClick: () => setOpen(!open),
        }}
        label="Report"
        disabled={!documentValid}
        enabledTooltip="Generate document report"
        disabledTooltip="Select a document to generate a report"
      />
    </>
  );
};

export default ClientReportButton;

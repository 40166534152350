import { Client, ClientSummary } from "../../../data/models/client";
import { Tag } from "../../../data/models/documentProperties/section";
import { NutrientReferenceValue } from "../../../data/models/nutrientReferenceValues";
import {
  IActionsAddClientSummary,
  IActionsRemoveClientSummary,
  IActionsSetClientId,
  IActionsSetClientSummaries,
  IActionsSetCurrentClient,
  IActionsSetClientDatabaseId,
  IActionsSetServerClient,
  IActionsUpdateClientSummary,
  IActionsSetCurrentClientNRV,
  IActionsSetClientDatabaseTags,
} from "../actions/clientDatabase";

export const setClientId = (clientId: string): IActionsSetClientId => ({
  type: "SET_CLIENT_ID",
  clientId: clientId,
});

export const setCurrentClient = (client: Client): IActionsSetCurrentClient => ({
  type: "SET_CURRENT_CLIENT",
  client: client,
});

export const setServerClient = (
  client: Client | undefined
): IActionsSetServerClient => ({
  type: "SET_SERVER_CLIENT",
  client: client,
});

export const setClientUserDatabaseId = (
  databaseId: string
): IActionsSetClientDatabaseId => ({
  type: "SET_CLIENT_DATABASE_ID",
  databaseId: databaseId,
});

export const setClientSummaries = (
  summaries: ClientSummary[]
): IActionsSetClientSummaries => ({
  type: "SET_CLIENT_SUMMARIES",
  summaries: summaries,
});

export const addClientSummary = (
  summary: ClientSummary
): IActionsAddClientSummary => ({
  type: "ADD_CLIENT_SUMMARY",
  summary: summary,
});

export const removeClientSummary = (
  clientId: string
): IActionsRemoveClientSummary => ({
  type: "REMOVE_CLIENT_SUMMARY",
  clientId: clientId,
});

export const updateClientSummary = (
  summary: ClientSummary
): IActionsUpdateClientSummary => ({
  type: "UPDATE_CLIENT_SUMMARY",
  summary: summary,
});

export const setCurrentClientNRV = (
  values: NutrientReferenceValue[]
): IActionsSetCurrentClientNRV => ({
  type: "SET_CURRENT_CLIENT_NRV",
  values: values,
});

export const setClientDatabaseTags = (
  tags: Tag[]
): IActionsSetClientDatabaseTags => {
  return {
    type: "SET_CLIENT_DATABASE_TAGS",
    tags: tags,
  };
};

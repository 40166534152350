import React, { ReactNode } from "react";
import { List } from "@material-ui/core";

import { FoodTemplate } from "../../constants/FoodTemplate";
import { FoodworksMenu } from "./FoodworksMenu";
import DocumentMenuItem from "./DocumentMenuItem";

export interface CreateMenuProps {
  handleClose: () => void;
  anchorElement: HTMLElement | null;
  onClick: (template: FoodTemplate) => void;
  options: FoodTemplate[];
}

const getMenuItems = (
  options: FoodTemplate[],
  onClick: (template: FoodTemplate) => void
): ReactNode =>
  options.map((template: FoodTemplate) => (
    <DocumentMenuItem key={template.id} template={template} onClick={onClick} />
  ));

const DocumentMenu = ({
  handleClose,
  anchorElement,
  onClick,
  options,
}: CreateMenuProps) => {
  return (
    <FoodworksMenu
      id="simple-menu"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={handleClose}
    >
      <List disablePadding>{getMenuItems(options, onClick)}</List>
    </FoodworksMenu>
  );
};

export default DocumentMenu;

import React, { ReactNode, FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, Delete, Edit } from "@material-ui/icons";
import {
  Button,
  Card,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { Profile } from "../../../data/models/userDatabase";
import { databaseProfilesSelector } from "../../../store/data/selectors/database";
import { updateDatabaseProfiles } from "../../../store/data/thunks/database";
import { RootState } from "../../../store/reducers";
import { BaseDialog } from "../BaseDialog";
import { appTheme } from "../../../styling/style";
import { NutrientsProfileDialog } from "./NutrientsProfileDialog";
import { FoodworksTooltip } from "../../common/InfoTooltip";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    margin: "10px 0px",
  },
  addIcon: {
    color: appTheme.colors.primary,
  },
  componentRootContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    height: 600,
  },
  components: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flex: 1,
    margin: "0px 10px",
  },
  listContainer: {
    overflow: "auto",
    flex: "1",
  },
  icon: {
    height: 20,
    width: 40,
  },
  buttonsContainer: {
    display: "flex",
  },
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
}));

interface CreateProfileDialogProps {
  open: boolean;
  onClose: () => void;
}

interface ProfileState {
  selectedProfileId: string;
  hoveredProfileId: string;
}

export const CreateProfileDialog: FunctionComponent<CreateProfileDialogProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUpdateDatabaseProfiles = (profiles: Profile[]) =>
    dispatch(updateDatabaseProfiles(profiles));

  const onDeleteProfile = (profileId: string) => {
    const newProfileData: Profile[] = profileData.filter(
      (profile: Profile) => profile.id !== profileId
    );
    onUpdateDatabaseProfiles(newProfileData);
  };

  const profileData: Profile[] = useSelector<RootState, Profile[]>(
    databaseProfilesSelector
  );

  const [createProfileOpen, setCreateProfileOpen] = useState<boolean>(false);
  const [editProfileOpen, setEditProfileOpen] = useState<boolean>(false);
  const [profileState, setProfileState] = useState<ProfileState>({
    selectedProfileId: "",
    hoveredProfileId: "",
  });

  const createProfileButton: ReactNode = [
    <Button
      key="createProfileButton"
      data-cy="createProfileButton"
      startIcon={<Add className={classes.addIcon} />}
      onClick={() => setCreateProfileOpen(true)}
      size="small"
    >
      Create profile
    </Button>,
    <NutrientsProfileDialog
      key="createProfile-nutrientsProfile"
      open={createProfileOpen}
      onClose={() => setCreateProfileOpen(false)}
      profileId=""
    />,
  ];

  const profileTagButtons = (profileId: string): ReactNode => [
    <div
      key="createProfileDialog-buttonsContainer"
      className={classes.buttonsContainer}
    >
      <FoodworksTooltip title="Edit profile">
        <IconButton
          data-cy="editButton"
          size="small"
          className={classes.icon}
          onClick={() => {
            setEditProfileOpen(true);
            setProfileState({ ...profileState, selectedProfileId: profileId });
          }}
        >
          <Edit />
        </IconButton>
      </FoodworksTooltip>

      <FoodworksTooltip title="Delete profile">
        <IconButton
          data-cy="deleteButton"
          size="small"
          className={classes.icon}
          onClick={() => onDeleteProfile(profileId)}
        >
          <Delete />
        </IconButton>
      </FoodworksTooltip>
    </div>,
  ];

  const profileItems: ReactNode = [...profileData].map((profile: Profile) => (
    <ListItem
      data-cy="createProfileListItem"
      button
      key={profile.id}
      onMouseEnter={() =>
        setProfileState({ ...profileState, hoveredProfileId: profile.id })
      }
      onMouseLeave={() =>
        setProfileState({ ...profileState, hoveredProfileId: "" })
      }
    >
      <div className={classes.itemContainer}>
        <Typography>{profile.name}</Typography>
        {profileState.hoveredProfileId === profile.id &&
          profileTagButtons(profile.id)}
      </div>
    </ListItem>
  ));

  const profileComponent: ReactNode = [
    <Typography
      key="createProfileDialog-availableProfile"
      className={classes.sectionContainer}
    >
      Available profile
    </Typography>,
    <Card
      key="createProfileDialog-profileComponentCard"
      className={classes.listContainer}
    >
      <List>
        <ListItem>{createProfileButton}</ListItem>
        {profileItems}
        <NutrientsProfileDialog
          key="createProfile-nutrientsProfile"
          open={editProfileOpen}
          onClose={() => {
            setProfileState({ ...profileState, selectedProfileId: "" });
            setEditProfileOpen(false);
          }}
          profileId={profileState.selectedProfileId}
        ></NutrientsProfileDialog>
      </List>
    </Card>,
  ];

  const body: ReactNode = [
    <div
      key="createProfileDialog-rootContainer"
      className={classes.componentRootContainer}
    >
      
      <div className={classes.components}><Typography>Create nutrient profiles to view in the analysis pane and for reports.</Typography>
      <br/> {profileComponent}</div>
    </div>,
  ];

  const action: ReactNode = [
    <Button
      key="createProfileDialogCancel"
      data-cy="createProfileClose"
      onClick={onClose}
    >
      Cancel
    </Button>,
  ];

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Create nutrient profiles"
      body={body}
      action={action}
      maxWidth="sm"
      dataCy="createProfileDialog"
    />
  );
};

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setClientMobile } from "../../../../../../../store/data/current_client/action_creators/client";
import { clientMobileSelector } from "../../../../../../../store/data/current_client/selectors/client";
import { RootState } from "../../../../../../../store/reducers";

const useClientMobile = (): [string, (input: string) => void, () => void] => {
  const dispatch = useDispatch();

  const mobile: string = useSelector<RootState, string>(clientMobileSelector);

  const [input, setInput] = useState(mobile);

  const onSetClientMobile = useCallback(
    () => dispatch(setClientMobile(input)),
    [dispatch, input]
  );

  useEffect(() => {
    setInput(mobile);
  }, [mobile]);

  return [input, setInput, onSetClientMobile];
};

export default useClientMobile;

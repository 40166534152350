import { createSelector } from "reselect";

import {
  Composition,
  CompositionCache,
} from "../../../data/models/composition";
import { CompositionCalculator } from "../../../data/models/compositionCalculator";
import { Document } from "../../../data/models/document";
import { FoodId } from "../../../data/models/documentProperties/foodId";
import { FoodItem } from "../../../data/models/documentProperties/foodItem";
import { Quantity } from "../../../data/models/documentProperties/quantity";
import { RootState } from "../../reducers";
import { documentCacheSelector } from "../current-document/selectors/commonMeasures";
import { sectionCreateSelector } from "../current-document/selectors/sections";
import { CompositionMap } from "../reducers/compositionCache";
import {
  getRetentionFactorMap,
  ReferenceMeasuresSelector,
} from "./referenceData";

export interface FoodItemData {
  foodId: FoodId | undefined;
  quantity: Quantity | undefined;
  document: Document | undefined;
}

const compositionCacheStateSelector = (state: RootState): CompositionMap =>
  state.compositionCache;

export const compositionCacheSelector = (state: RootState): CompositionCache =>
  new CompositionCache(state.compositionCache);

const foodItemDataIdentitySelector = (_: any, data: FoodItemData) => data;

export const foodItemCompositionSelector = () =>
  createSelector(
    compositionCacheStateSelector,
    documentCacheSelector,
    getRetentionFactorMap,
    ReferenceMeasuresSelector,
    foodItemDataIdentitySelector,
    (
      compositionCache,
      documentCache,
      retentionFactorMap,
      referenceMeasures,
      foodItemData
    ): Composition | undefined => {
      if (
        !(foodItemData.foodId && foodItemData.quantity && foodItemData.document)
      )
        return undefined;

      if (!compositionCache[foodItemData.foodId.identifier]) return undefined;

      const composition: Composition | undefined = new Composition(
        compositionCache[foodItemData.foodId.identifier]
      );

      if (!composition) return undefined;

      const compositionCalculator = new CompositionCalculator(
        new CompositionCache(compositionCache),
        documentCache,
        referenceMeasures,
        retentionFactorMap
      );

      try {
        return compositionCalculator.applyBaseQuantity(
          composition,
          foodItemData.quantity,
          foodItemData.document
        );
      } catch (e) {
        return undefined;
      }
    }
  );

export const sectionCompositionSelector = () =>
  createSelector(
    compositionCacheStateSelector,
    sectionCreateSelector,
    documentCacheSelector,
    getRetentionFactorMap,
    ReferenceMeasuresSelector,
    (
      compositionCache,
      section,
      documentCache,
      retentionFactorMap,
      referenceMeasures
    ): Composition | undefined => {
      const compositionCalculator = new CompositionCalculator(
        new CompositionCache(compositionCache),
        documentCache,
        referenceMeasures,
        retentionFactorMap
      );

      try {
        const filteredFoodItems = section.foodItems.items.filter(
          (item: FoodItem): boolean => !!item.foodId && !!item.quantity
        );

        return !filteredFoodItems.length
          ? undefined
          : compositionCalculator.calculateCompositionFromFoodItems(
              filteredFoodItems
            );
      } catch (e) {
        console.log("composition error");
        return undefined;
      }
    }
  );

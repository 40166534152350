import React, { ReactNode } from "react";
import { Divider, makeStyles, MenuItem, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { People } from "@material-ui/icons";

import { FoodworksMenu } from "../../../common/FoodworksMenu";
import { handleRouteChange } from "../../../../store/ui/thunks/routing";
import {
  getClientDashboardRouteData,
  getClientRouteData,
} from "../../../../data/routing/routing";
import useRecentClients from "../hooks/useRecentClients";
import { ClientSummary } from "../../../../data/models/client";
import AppBarMenuItem from "./AppBarMenuItem";
import { appTheme } from "../../../../styling/style";
import { createNewClient } from "../../../../store/data/current_client/thunks/client";

const useStyles = makeStyles(() => ({
  databaseIcon: {
    fill: appTheme.colors.primary,
    width: 24,
    height: 24,
  },
  divider: { margin: "5px 0px" },
}));

const MAX_CLIENTS_TO_SHOW = 4;

const getMenuItems = (
  items: ClientSummary[],
  onClick: (id: string) => void,
  iconStyle: string
): ReactNode =>
  items.map(
    (summary: ClientSummary): ReactNode => (
      <AppBarMenuItem
        key={summary.clientId}
        id={summary.clientId}
        label={summary.label}
        lastModifiedDate={summary.date.lastModified}
        onClick={() => onClick(summary.clientId)}
        icon={<People className={iconStyle} />}
      />
    )
  );

interface ClientsMenuProps {
  anchorElement: HTMLElement | null;
  onClose: () => void;
}

const ClientsMenu = React.memo<ClientsMenuProps>(
  ({ onClose, anchorElement }): JSX.Element => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const onNavigateToClientDashboard = () =>
      dispatch(handleRouteChange(getClientDashboardRouteData()));

    const onRouteToClient = (clientId: string) =>
      dispatch(handleRouteChange(getClientRouteData(clientId)));

    const onCreateClient = () => dispatch(createNewClient());

    const sortedClientSummaries: ClientSummary[] =
      useRecentClients(MAX_CLIENTS_TO_SHOW);

    const onClickCreateClient = () => {
      onCreateClient();
      onClose();
    };

    return (
      <FoodworksMenu
        open={!!anchorElement}
        anchorEl={anchorElement}
        onClose={onClose}
      >
        {[
          <MenuItem key="clientsMenuRecent" disabled>
            <Typography variant="caption">RECENT</Typography>
          </MenuItem>,
          getMenuItems(
            sortedClientSummaries,
            (id: string) => {
              onRouteToClient(id);
              onClose();
            },
            classes.databaseIcon
          ),
          <Divider key="clientMenuDivider" className={classes.divider} />,
          <MenuItem
            key="clientsMenuSeeAllClients"
            data-cy="seeAllClients"
            onClick={() => {
              onNavigateToClientDashboard();
              onClose();
            }}
          >
            View all clients
          </MenuItem>,
          <MenuItem key="CreateClientMenuItem" onClick={onClickCreateClient}>
            Create client
          </MenuItem>,
        ]}
      </FoodworksMenu>
    );
  }
);

export default ClientsMenu;

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tag } from "../../../../data/models/documentProperties/section";
import { databaseDocumentTagsSelector } from "../../../../store/data/selectors/database";
import { updateDatabaseDocumentTags } from "../../../../store/data/thunks/database";
import { RootState } from "../../../../store/reducers";
import { CreateTagDialog } from "../common/CreateTagDialog";

interface CreateDocumentTagDialogProps {
  open: boolean;
  onClose: () => void;
  isEditing: boolean;
  tagId?: string;
}

export const CreateDocumentTagDialog = ({
  open,
  onClose,
  isEditing,
  tagId,
}: CreateDocumentTagDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const onCreateTag = (tag: Tag) =>
    dispatch(updateDatabaseDocumentTags([...documentTags, tag]));

  const onUpdateTag = (updatedTag: Tag) => {
    const updatedTags: Tag[] = documentTags.map((tag: Tag) =>
      tag.id === updatedTag.id ? updatedTag : tag
    );
    dispatch(updateDatabaseDocumentTags(updatedTags));
  };

  const documentTags = useSelector<RootState, Tag[]>(
    databaseDocumentTagsSelector
  );

  return (
    <CreateTagDialog
      open={open}
      onClose={onClose}
      isEditing={isEditing}
      tagId={tagId}
      onCreateTag={onCreateTag}
      onUpdateTag={onUpdateTag}
      tagSelector={documentTags}
      label="document"
    />
  );
};

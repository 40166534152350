import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, makeStyles, Typography } from "@material-ui/core";

import { ReactComponent as AddTagIcon } from "../../../../images/icons/Add_Tag.svg";
import { Tag } from "../../../../data/models/documentProperties/section";
import { setDocumentTagIds } from "../../../../store/data/current-document/action-creators/document";
import { documentTagIdsSelector } from "../../../../store/data/current-document/selectors/document";
import { databaseDocumentTagsSelector } from "../../../../store/data/selectors/database";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import { CurrentDocumentIdSelector } from "../../../../store/data/current-document/selectors/currentDocument";
import { DocumentTags } from "./DocumentTags";
import { TagMenu } from "../common/TagMenu";
import { FoodworksTooltip } from "../../../common/InfoTooltip";
import { CreateDocumentTagDialog } from "./CreateDocumentTagDialog";

const useStyles = makeStyles(() => ({
  inputRow: {
    display: "flex",
    alignItems: "center",
  },
  titles: {
    width: "12ch",
    textAlign: "end",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 17,
    marginRight: 10,
    height: 40,
    width: "100%",
  },
  documentTagSection: {
    backgroundColor: appTheme.colors.white[10],
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 4,
    alignItems: "center",
    display: "flex",
    height: 40,
    justifyContent: "space-between",
  },
  leftButtonGroup: {
    display: "flex",
    alignItems: "center",
  },
  invisible: {
    visibility: "hidden",
  },
  visible: {
    visibility: "visible",
  },
}));

export const DocumentTagMenu = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateTags = (tagIds: string[]) => {
    dispatch(setDocumentTagIds(tagIds));
  };

  const documentTags = useSelector<RootState, Tag[]>(
    databaseDocumentTagsSelector
  );
  const documentTagIds: string[] = useSelector<RootState, string[]>(
    documentTagIdsSelector
  );
  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const [tagMenuAnchorEl, setTagMenuAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const [rowHovered, setRowHovered] = useState(false);
  const [openDocumentDialog, setOpenDocumentDialog] = useState<boolean>(false);

  const onAddTag = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setTagMenuAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.inputRow}>
      <Typography className={classes.titles} variant="body1">
        Document tags
      </Typography>
      <div
        className={classes.sectionHeader}
        onMouseEnter={() => setRowHovered(true)}
        onMouseLeave={() => setRowHovered(false)}
      >
        <div className={classes.documentTagSection}>
          <div className={classes.leftButtonGroup}>
            <DocumentTags documentId={currentDocumentId} />
          </div>
          <div>
            <FoodworksTooltip
              title="Add document tag"
              key="documentTagMenu-foodWorksTooltip"
              className={rowHovered ? classes.visible : classes.invisible}
            >
              <IconButton data-cy="addDocTag" size="small" onClick={onAddTag}>
                <AddTagIcon />
              </IconButton>
            </FoodworksTooltip>

            <TagMenu
              key="documentTagMenu-tagMenu"
              title="document"
              anchorElement={tagMenuAnchorEl}
              onClose={() => setTagMenuAnchorEl(null)}
              onClickCreateTag={() => setOpenDocumentDialog(true)}
              tags={documentTags}
              tagIds={documentTagIds}
              dispatchSetId={(tagIds: string[]) => updateTags(tagIds)}
            />

            <CreateDocumentTagDialog
              key="documentTagMenu-createDocumentTagDialog"
              open={openDocumentDialog}
              onClose={() => setOpenDocumentDialog(false)}
              isEditing={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { LoginActions, SET_SIGN_UP_ERROR } from "../actions/login";
import { LoginError } from "../thunks/login";

export interface LoginState {
  signUpError: LoginError;
}

export const initialLoginState: LoginState = {
  signUpError: { type: "", message: null },
};

export const login = (
  state: LoginState = initialLoginState,
  action: LoginActions
) => {
  switch (action.type) {
    case SET_SIGN_UP_ERROR:
      return { ...state, signUpError: action.error };
    default:
      return state;
  }
};

import React, { useState } from "react";
import { Divider, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import CollapsePaneButton from "../shared/CollapsePaneButton";
import NavigationPaneBody from "../shared/NavigationPaneBody";
import ClientNavHeader from "./ClientNavHeader";
import CreateDocumentButton from "../shared/CreateDocumentButton";
import {
  FOOD_RECORDS,
  MEAL_PLANS,
  TWENTY_FOUR_HOUR_RECALL,
} from "../../../../constants/FoodTemplate";
import ClientNavigationList from "./ClientNavigationList";
import NavigationPaneMessage from "../shared/NavigationPaneMessage";
import NavigationFilter from "../shared/NavigationFilter";
import {
  DELETED_DOCUMENTS_FILTER,
  FOOD_RECORDS_FILTER,
  MEAL_PLANS_FILTER,
  TWENTY_FOUR_HOUR_RECALL_FILTER,
} from "../../../../constants/QuickFilter";
import { Tag } from "../../../../data/models/documentProperties/section";
import { databaseDocumentTagsSelector } from "../../../../store/data/selectors/database";
import { RootState } from "../../../../store/reducers";
import { NavIsLoadingSelector } from "../../../../store/ui/selectors/navigationSelectors";
import useClientDocuments from "../hooks/useClientDocuments";
import FoodWorksTagSwitch from "../../../common/FoodWorksTagSwitch";

const useStyles = makeStyles(() => ({
  currentDatabaseDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "15px 0px",
    overflow: "hidden",
  },
  topButtons: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  switchContainer: {
    margin: "5px 0px",
  },
}));

const ClientNavigation = (): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [showTags, setShowTags] = useState(false);

  const navIsLoading: boolean =
    useSelector<RootState, boolean>(NavIsLoadingSelector);

  const databaseDocumentTags: Tag[] = useSelector<RootState, Tag[]>(
    databaseDocumentTagsSelector
  );

  const [clientDocuments, hasNonDeletedDocuments, deleteFilterApplied] =
    useClientDocuments(searchText);

  return (
    <NavigationPaneBody open={open} setOpen={setOpen}>
      <div className={classes.topButtons}>
        <div className={classes.currentDatabaseDiv}>
          <ClientNavHeader />
          <CollapsePaneButton
            type="Navigation"
            open={open}
            setOpen={(open) => setOpen(open)}
          />
        </div>
        <CreateDocumentButton
          options={[TWENTY_FOUR_HOUR_RECALL, FOOD_RECORDS, MEAL_PLANS]}
        />
      </div>
      <NavigationFilter
        fetchSearchText={setSearchText}
        searchText={searchText}
        foodFilters={[
          FOOD_RECORDS_FILTER,
          MEAL_PLANS_FILTER,
          DELETED_DOCUMENTS_FILTER,
          TWENTY_FOUR_HOUR_RECALL_FILTER,
        ]}
        availableTags={databaseDocumentTags}
        isClient={undefined}
      />
      <Divider />
      <div className={classes.switchContainer}>
        <FoodWorksTagSwitch
          label="Show document tags"
          checked={showTags}
          onChange={() => setShowTags((prev) => !prev)}
        />
      </div>
      <NavigationPaneMessage
        isLoading={navIsLoading}
        deletedDocumentFilterApplied={deleteFilterApplied}
        hasNonDeletedDocuments={hasNonDeletedDocuments}
        hasDocumentsToDisplay={!!clientDocuments.length}
        itemType="clientDocument"
      />
      <ClientNavigationList
        visibleDocuments={clientDocuments}
        showTags={showTags}
      />
    </NavigationPaneBody>
  );
};

export default ClientNavigation;

import React, { useState } from "react";
import { People } from "@material-ui/icons";

import { ClientSummary } from "../../../../data/models/client";
import ClientDatabaseNavigationListItem from "./ClientDatabaseNavigationListItem";
import { AccordionItem, AccordionList } from "../../../common/AccordionList";

const getListItems = (
  summaries: ClientSummary[],
  showTags: boolean
): AccordionItem[] =>
  summaries.map(
    (summary: ClientSummary): AccordionItem => ({
      id: summary.clientId,
      item: (
        <ClientDatabaseNavigationListItem
          summary={summary}
          showTags={showTags}
        />
      ),
    })
  );

interface ClientDatabaseNavigationListProps {
  searchText: string;
  visibleClients: ClientSummary[];
  showTags: boolean;
}

const ClientDatabaseNavigationList = ({
  searchText,
  visibleClients,
  showTags,
}: ClientDatabaseNavigationListProps): JSX.Element => {
  const [accordionOpen, setAccordionOpen] = useState(true);

  return (
    <div>
      {visibleClients.length ? (
        <AccordionList
          values={[
            {
              items: getListItems(
                visibleClients.filter((summary: ClientSummary) =>
                  summary.label.toLowerCase().includes(searchText.toLowerCase())
                ),
                showTags
              ),
              header: "Clients",
              icon: <People color="secondary" />,
              open: accordionOpen,
              id: 0,
              onClick: () => setAccordionOpen((prev) => !prev),
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default ClientDatabaseNavigationList;

import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, MenuItem, Typography } from "@material-ui/core";
import { ArchiveRounded, DeleteRounded } from "@material-ui/icons";

import { ClientSummary } from "../../../../data/models/client";
import { FoodworksMenu } from "../../../common/FoodworksMenu";
import { deleteClient } from "../../../../store/data/current_client/thunks/client";
import { DeleteClientDialog } from "../../../dialogs/clients/DeleteClientDialog";

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: -40,
  },
  iconContainer: {
    display: "flex",
  },
  icon: {
    paddingRight: 5,
  },
}));

interface ClientItemMenuProps {
  anchorElement: HTMLDivElement | null;
  onClose: () => void;
  clientSummary: ClientSummary;
}

export const ClientItemMenu = ({
  anchorElement,
  onClose,
  clientSummary,
}: ClientItemMenuProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [permaDeleteDialog, setPermaDialog] = useState(false);

  const onArchiveClient = () => {
    dispatch(deleteClient(clientSummary.clientId));
  };

  const onClickDelete = () => {
    onArchiveClient();
    onClose();
  };

  const onClickPermaDelete = () => {
    setPermaDialog(true);
  };

  const deleteMenuItem: ReactNode = (
    <div>
      {permaDeleteDialog && (
        <DeleteClientDialog
          open={permaDeleteDialog}
          onClose={() => setPermaDialog(false)}
          clientSummary={clientSummary}
        />
      )}
      <MenuItem key={`${clientSummary.clientId}-archive`}>
        {clientSummary.state === "archived" ? (
          <div className={classes.iconContainer}>
            <DeleteRounded className={classes.icon} color="error" />
            <Typography onClick={onClickPermaDelete}>Delete</Typography>
          </div>
        ) : (
          <div className={classes.iconContainer}>
            <ArchiveRounded className={classes.icon} color="action" />
            <Typography onClick={onClickDelete}>Archive</Typography>
          </div>
        )}
      </MenuItem>
    </div>
  );

  return (
    <FoodworksMenu
      open
      onClose={onClose}
      anchorEl={anchorElement}
      className={classes.root}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {deleteMenuItem}
    </FoodworksMenu>
  );
};

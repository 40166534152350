import {
  IActionsSetDatabaseProperties,
  IActionsSetUserDocumentSummaries,
  IActionsSetReferenceSummaries,
  IActionsSetDatabaseId,
  IActionsAddUserDocumentSummary,
  IActionsDeleteUserDocumentSummary,
  IActionsUpdateUserDocumentSummary,
  IActionsSetDatabaseName,
  IActionsSetDatabaseDescription,
  IActionsSetDatabaseProfiles,
  IActionsSetSharingHistory,
  IActionsSetDatabaseDocumentTags,
  IActionsSetDatabase,
} from "../actions/database";
import { DatabaseSummaryMap } from "../reducers/database";
import {
  DocumentSummary,
  SharingHistory,
  Profile,
  Database,
} from "../../../data/models/userDatabase";
import { DatabasePropertiesState } from "../reducers/databaseProperties";
import { Tag } from "../../../data/models/documentProperties/section";

export const setDatabase = (database: Database): IActionsSetDatabase => ({
  type: "SET_DATABASE",
  database,
});

export const setDatabaseId = (databaseId: string): IActionsSetDatabaseId => {
  return {
    type: "SET_DATABASE_ID",
    databaseId: databaseId,
  };
};

export const setDatabaseName = (name: string): IActionsSetDatabaseName => {
  return {
    type: "SET_DATABASE_NAME",
    name: name,
  };
};

export const setDatabaseProperties = (
  databaseProperties: DatabasePropertiesState
): IActionsSetDatabaseProperties => {
  return {
    type: "SET_DATABASE_PROPERTIES",
    databaseProperties: databaseProperties,
  };
};

export const setUserDocumentSummaries = (
  userDocumentSummaries: DocumentSummary[]
): IActionsSetUserDocumentSummaries => {
  return {
    type: "SET_USER_DOCUMENT_SUMMARIES",
    userDocumentSummaries: userDocumentSummaries,
  };
};

export const setReferenceSummaries = (
  referenceSummaries: DatabaseSummaryMap
): IActionsSetReferenceSummaries => {
  return {
    type: "SET_REFERENCE_SUMMARIES",
    referenceSummaries: referenceSummaries,
  };
};

export const addUserDocumentSummary = (
  documentSummary: DocumentSummary
): IActionsAddUserDocumentSummary => {
  return {
    type: "ADD_USER_DOCUMENT_SUMMARY",
    documentSummary: documentSummary,
  };
};

export const updateUserDocumentSummary = (
  documentSummary: DocumentSummary
): IActionsUpdateUserDocumentSummary => {
  return {
    type: "UPDATE_USER_DOCUMENT_SUMMARY",
    documentSummary: documentSummary,
  };
};

export const deleteUserDocumentSummary = (
  documentId: string
): IActionsDeleteUserDocumentSummary => {
  return {
    type: "DELETE_USER_DOCUMENT_SUMMARY",
    documentId: documentId,
  };
};

export const setDatabaseDescription = (
  description: string
): IActionsSetDatabaseDescription => {
  return {
    type: "SET_DATABASE_DESCRIPTION",
    description: description,
  };
};

export const setDatabaseProfiles = (
  profiles: Profile[]
): IActionsSetDatabaseProfiles => {
  return {
    type: "SET_DATABASE_PROFILES",
    profiles: profiles,
  };
};

export const setSharingHistory = (
  history: SharingHistory[]
): IActionsSetSharingHistory => {
  return {
    type: "SET_SHARING_HISTORY",
    history: history,
  };
};

export const setDatabaseDocumentTags = (
  documentTags: Tag[]
): IActionsSetDatabaseDocumentTags => {
  return {
    type: "SET_DATABASE_DOCUMENT_TAGS",
    documentTags: documentTags,
  };
};

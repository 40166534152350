import { usePreviousPerpetualMeasurement } from "../../../tracking/tracking-card/hooks/usePreviousMeasurement";

const useClientHeight = (): number => {
  const currentHeight: { value: number; date: Date } | undefined =
    usePreviousPerpetualMeasurement("Height", "cm");

  return currentHeight ? currentHeight.value : 0;
};

export default useClientHeight;

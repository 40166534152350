import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  defaultMeasurement,
  anthroMeasurements,
  biochemMeasurements,
  clinicalMeasurements,
} from "../../../../../../constants/measurements";
import {
  Measurement,
  MeasurementCategory,
} from "../../../../../../data/models/clientProperties/measurement";
import { clientMeasurementsSelector } from "../../../../../../store/data/current_client/selectors/clientMeasurements";
import { RootState } from "../../../../../../store/reducers";

const getDefaultMeasurements = (
  category: MeasurementCategory
): Measurement[] => {
  switch (category) {
    case "Anthropometric":
      return getMeasurement(anthroMeasurements, category);

    case "Biochemical":
      return getMeasurement(biochemMeasurements, category);

    case "Clinical":
      return getMeasurement(clinicalMeasurements, category);
  }
};

const getMeasurement = (
  constant: defaultMeasurement[],
  category: MeasurementCategory
): Measurement[] => {
  return constant.map((measurement) => ({
    category: category,
    label: measurement.label,
    unit: measurement.unit,
    subCategory: measurement.subCategory,
    entries: [],
    active: false,
  }));
};

const sortByAlphabetical = (measurements: Measurement[]): Measurement[] => {
  return measurements.sort((a, b) => (a.label > b.label ? 1 : -1));
};

const sortByActive = (measurements: Measurement[]): Measurement[] => {
  return sortByAlphabetical(measurements).sort((a, b) => (b.active ? 1 : -1));
};

const sortByCategory = (measurements: Measurement[]): Measurement[] => {
  return sortByActive(measurements).sort((a, b) =>
    b.subCategory > a.subCategory ? -1 : 1
  );
};

const sortByInactive = (measurements: Measurement[]): Measurement[] => {
  return sortByAlphabetical(measurements).sort((a, b) => (b.active ? -1 : 1));
};

const filterMeasurements = (
  searchText: string,
  measurements: Measurement[]
): Measurement[] => {
  return searchText
    ? measurements.filter((measurement: Measurement) =>
        measurement.label.toLowerCase().includes(searchText.toLowerCase())
      )
    : measurements;
};

const sortAndFilterMeasurements = (
  searchText: string,
  sortOption: string,
  measurementsToSort: Measurement[]
): Measurement[] => {
  const filtered = filterMeasurements(searchText, measurementsToSort);
  switch (sortOption) {
    case "A-Z":
      return sortByAlphabetical(filtered);
    case "Active":
      return sortByActive(filtered);
    case "Category":
      return sortByCategory(filtered);
    case "Inactive":
      return sortByInactive(filtered);
    default:
      return filtered;
  }
};

export const useMeasurements = (
  category: MeasurementCategory,
  searchText: string,
  sortOption: string
): [string[], Measurement[]] => {
  const addedMeasurements = useSelector<RootState, Measurement[]>(
    clientMeasurementsSelector
  ).filter((measurement) => measurement.category === category);

  const addedMeasurementsList = addedMeasurements.map(
    (measurement: Measurement): string => measurement.label
  );

  //filters the default measurements to avoid duplicates
  const allMeasurements = addedMeasurements.concat(
    getDefaultMeasurements(category).filter(
      (measurement) => !addedMeasurementsList.includes(measurement.label)
    )
  );

  const allMeasurementsMemo = useMemo(() => allMeasurements, [allMeasurements]);

  const sortedMeasurements = sortAndFilterMeasurements(
    searchText,
    sortOption,
    allMeasurementsMemo
  );

  return [addedMeasurementsList, sortedMeasurements];
};

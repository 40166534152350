import React from "react";
import { useDispatch } from "react-redux";
import { Button, makeStyles } from "@material-ui/core";

import { deleteClientMeasurementEntry } from "../../../../../../store/data/current_client/action_creators/clientMeasurements";
import { BaseDialog } from "../../../../../dialogs/BaseDialog";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "30%",
  },
}));

interface Props {
  measurementTitle: string;
  measurementUnit: string;
  measurementEntryId: string;
  onClose: () => void;
}

const DeleteMeasurementEntryDialog = (props: Props): JSX.Element => {
  const classes = useStyles();

  const { measurementEntryId, measurementTitle, measurementUnit, onClose } = {
    ...props,
  };

  const dispatch = useDispatch();

  const onDeleteClientMeasurementEntry = () =>
    dispatch(
      deleteClientMeasurementEntry(
        measurementTitle,
        measurementUnit,
        measurementEntryId
      )
    );

  const onConfirmDelete = () => {
    onDeleteClientMeasurementEntry();
    onClose();
  };

  return (
    <BaseDialog
      open
      maxWidth="sm"
      body={
        <div className={classes.root}>
          <div className={classes.buttonContainer}>
            <Button onClick={onClose} variant="outlined" color="secondary">
              No
            </Button>
            <Button
              onClick={onConfirmDelete}
              variant="contained"
              color="secondary"
            >
              Yes
            </Button>
          </div>
        </div>
      }
      onClose={onClose}
      title={`Are you sure you want to delete this ${measurementTitle.toLowerCase()} entry?`}
      action={[]}
    />
  );
};

export default DeleteMeasurementEntryDialog;

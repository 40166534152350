import {
  ActivityLevel,
  ERVInfo,
  getActivityLevelValue,
  getAdditionalEnergyFactor,
  NRVInfo,
  PregnancyLevel,
} from "./nutrientReferenceValues";

export const calculateEnergyExpenditure = (
  erv: ERVInfo,
  weight: number,
  additionFactor: number,
  activityFactor: number
): number => {
  return (
    (weight * erv.aFactor + erv.bFactor) * activityFactor * 1000 +
    erv.cFactor +
    additionFactor
  );
};

export const getEnergyExpenditure = (
  erv: ERVInfo,
  weight: number,
  pregnancyLevel: PregnancyLevel,
  isLactating: boolean,
  activityLevel: ActivityLevel
): number =>
  calculateEnergyExpenditure(
    erv,
    weight,
    getAdditionalEnergyFactor(pregnancyLevel, isLactating),
    getActivityLevelValue(activityLevel)
  );

export const calculateNutrientReferenceValue = (
  nrv: NRVInfo,
  weight: number,
  energyExpenditure: number
): number =>
  weight * nrv.aFactor +
  nrv.bFactor +
  (energyExpenditure * nrv.cFactor) / 10000;

export const getNutrientReferenceValueFromProvidedEnergy = (
  nrv: NRVInfo,
  energyExpenditure: number,
  weight: number
): number => calculateNutrientReferenceValue(nrv, weight, energyExpenditure);

export const getNutrientReferenceValue = (
  nrv: NRVInfo,
  erv: ERVInfo,
  weight: number,
  pregnancyLevel: PregnancyLevel,
  isLactating: boolean,
  activityLevel: ActivityLevel
): number =>
  calculateNutrientReferenceValue(
    nrv,
    weight,
    getEnergyExpenditure(
      erv,
      weight,
      pregnancyLevel,
      isLactating,
      activityLevel
    )
  );

export const calculateBMI = (weight: number, height: number): number =>
  weight / (((height / 100) * height) / 100);

export const calculateBMR = (erv: ERVInfo, weight: number): number =>
  (weight * erv.aFactor + erv.bFactor) * 1000;

export const isHealthyBMI = (bmi: number): boolean =>
  bmi >= 18.5 && bmi <= 24.9;

export const calculateWeightFromBMI = (bmi: number, height: number): number =>
  bmi * height * height;

export const getAdjustedWeight = (
  weight: number,
  height: number,
  bmi: number
): number => (isHealthyBMI(bmi) ? weight : calculateWeightFromBMI(22, height/100));

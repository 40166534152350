import { Action } from "redux";

import { DatabaseSummaryMap } from "../reducers/database";
import {
  Database,
  DocumentSummary,
  Profile,
  SharingHistory,
} from "../../../data/models/userDatabase";
import { DatabasePropertiesState } from "../reducers/databaseProperties";
import { Tag } from "../../../data/models/documentProperties/section";

export const SET_DATABASE = "SET_DATABASE";
export const SET_DATABASE_ID = "SET_DATABASE_ID";
export const SET_DATABASE_NAME = "SET_DATABASE_NAME";
export const SET_DATABASE_PROPERTIES = "SET_DATABASE_PROPERTIES";
export const SET_USER_DOCUMENT_SUMMARIES = "SET_USER_DOCUMENT_SUMMARIES";
export const SET_REFERENCE_SUMMARIES = "SET_REFERENCE_SUMMARIES";
export const SET_DATABASE_DESCRIPTION = "SET_DATABASE_DESCRIPTION";
export const SET_SHARING_HISTORY = "SET_SHARING_HISTORY";

export const ADD_USER_DOCUMENT_SUMMARY = "ADD_USER_DOCUMENT_SUMMARY";
export const UPDATE_USER_DOCUMENT_SUMMARY = "UPDATE_USER_DOCUMENT_SUMMARY";
export const DELETE_USER_DOCUMENT_SUMMARY = "DELETE_USER_DOCUMENT_SUMMARY";

export const SET_DATABASE_PROFILES = "SET_DATABASE_PROFILES";
export const SET_DATABASE_DOCUMENT_TAGS = "SET_DATABASE_DOCUMENT_TAGS";

export interface IActionsSetDatabase extends Action {
  type: "SET_DATABASE";
  database: Database;
}

export interface IActionsSetDatabaseId extends Action {
  type: "SET_DATABASE_ID";
  databaseId: string;
}

export interface IActionsSetDatabaseName extends Action {
  type: "SET_DATABASE_NAME";
  name: string;
}

export interface IActionsSetDatabaseProperties extends Action {
  type: "SET_DATABASE_PROPERTIES";
  databaseProperties: DatabasePropertiesState;
}

export interface IActionsSetUserDocumentSummaries extends Action {
  type: "SET_USER_DOCUMENT_SUMMARIES";
  userDocumentSummaries: DocumentSummary[];
}

export interface IActionsSetReferenceSummaries extends Action {
  type: "SET_REFERENCE_SUMMARIES";
  referenceSummaries: DatabaseSummaryMap;
}

export interface IActionsAddUserDocumentSummary extends Action {
  type: "ADD_USER_DOCUMENT_SUMMARY";
  documentSummary: DocumentSummary;
}

export interface IActionsUpdateUserDocumentSummary extends Action {
  type: "UPDATE_USER_DOCUMENT_SUMMARY";
  documentSummary: DocumentSummary;
}

export interface IActionsDeleteUserDocumentSummary extends Action {
  type: "DELETE_USER_DOCUMENT_SUMMARY";
  documentId: string;
}

export interface IActionsSetDatabaseDescription extends Action {
  type: "SET_DATABASE_DESCRIPTION";
  description: string;
}

export interface IActionsSetDatabaseProfiles extends Action {
  type: "SET_DATABASE_PROFILES";
  profiles: Profile[];
}

export interface IActionsSetSharingHistory extends Action {
  type: "SET_SHARING_HISTORY";
  history: SharingHistory[];
}

export interface IActionsSetDatabaseDocumentTags extends Action {
  type: "SET_DATABASE_DOCUMENT_TAGS";
  documentTags: Tag[];
}

export type DatabaseActions =
  | IActionsSetDatabase
  | IActionsSetDatabaseId
  | IActionsSetDatabaseName
  | IActionsSetDatabaseProperties
  | IActionsSetUserDocumentSummaries
  | IActionsSetReferenceSummaries
  | IActionsAddUserDocumentSummary
  | IActionsUpdateUserDocumentSummary
  | IActionsDeleteUserDocumentSummary
  | IActionsSetDatabaseDescription
  | IActionsSetDatabaseProfiles
  | IActionsSetSharingHistory
  | IActionsSetDatabaseDocumentTags;

import React, { FunctionComponent, ReactNode, useState } from "react";

import {
  Button,
  ButtonGroup,
  CircularProgress,
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
  withStyles,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";

import { OverridesTable } from "./OverridesTable";
import { RootState } from "../../../../../../store/reducers";
import {
  CategoriesSelector,
  NutrientsDataSelector,
} from "../../../../../../store/data/selectors/referenceData";
import { CategoryRowProps } from "./CategoryRow";
import {
  Category,
  FOOD_GROUP_CATEGORY_ID,
} from "../../../../../../data/models/category";
import { Nutrient } from "../../../../../../data/models/nutrient";
import { Document } from "../../../../../../data/models/document";
import { IngredientSummaryItem } from "../ingredients/editing_grid/rows/cells/IngredientCell";
import { MappedFoodDialog } from "../../../../../dialogs/MappedFoodDialog";
import { CalculationMethod } from "../../../../../../constants/calculationMethod";
import { updateCalculationMethod } from "../../../../../../store/data/current-document/action-creators/document";
import { DocumentIsLoadingSelector } from "../../../../../../store/data/current-document/selectors/currentDocument";
import { recentMappedDocumentIdSelector } from "../../../../../../store/ui/selectors/overridesScreen";
import { cachedDocumentSelector } from "../../../../../../store/data/selectors/documentCache";
import {
  calculationMethodSelector,
  templateIdSelector,
} from "../../../../../../store/data/current-document/selectors/document";
import { isFood } from "../../../../../../constants/FoodTemplate";
import { FoodworksTooltip } from "../../../../../common/InfoTooltip";
import { enabledNutrientsSelector } from "../../../../../../store/data/selectors/databaseProperties";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  calculationMethodRoot: {
    display: "flex",
    alignItems: "center",
    marginLeft: 10,
    marginTop: 10,
  },
  openCalculationMethodButton: {
    width: 200,
    marginLeft: 10,
    textTransform: "none",
    overflow: "hidden",
  },
  overridesTable: {
    height: "100%",
  },
  progress: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
}));

const StyledMenu = withStyles({
  paper: {
    width: 200,
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

interface OverridesProps {
  summaries: IngredientSummaryItem[];
  searchTermMap: Map<string, string[]>;
}

const CalculationMethodMenuMapping: { [key: number]: string } = {
  0: "Ingredients",
  1: "Other Item",
  2: "Set To Zero",
  3: "Set To Unknown",
};

export const Overrides: FunctionComponent<OverridesProps> = ({
  summaries,
  searchTermMap,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUpdateCalculationMethodAndId = (
    calculationMethod: number,
    documentId: string
  ) => dispatch(updateCalculationMethod(calculationMethod, documentId));

  const mappedDocumentId: string = useSelector<RootState, string>(
    recentMappedDocumentIdSelector
  );

  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const nutrients: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(NutrientsDataSelector);

  const enabledNutrients: string[] = useSelector<RootState, string[]>(
    enabledNutrientsSelector
  );

  const nutrientCategories: Map<string, Category> =
    useSelector<RootState, Map<string, Category>>(CategoriesSelector);

  const lastSelectedMappedDocumentName: string | undefined = useSelector<
    RootState,
    Document | undefined
  >((state) => cachedDocumentSelector(state, mappedDocumentId))?.name;

  const calculationMenuOption: number = useSelector<RootState, number>(
    calculationMethodSelector
  );

  const documentIsLoading: boolean = useSelector<RootState, boolean>(
    DocumentIsLoadingSelector
  );

  const [openMappedToMenu, setOpenMappedToMenu] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const createNutrientOverrideCategories = (): CategoryRowProps[] => {
    const categoryMap: Map<string, CategoryRowProps> = new Map<
      string,
      CategoryRowProps
    >(
      [...nutrientCategories].map(([id, category]: [string, Category]): [
        string,
        CategoryRowProps
      ] => [
        id,
        {
          categoryId: id,
          categoryName: category.name,
          nutrients: [],
        },
      ])
    );

    //Hide food groups category from overrides table
    categoryMap.delete(FOOD_GROUP_CATEGORY_ID);

    for (const [id, nutrient] of [...nutrients].filter(
      ([id]: [string, Nutrient]) => enabledNutrients.includes(id)
    )) {
      if (categoryMap.has(nutrient.category)) {
        categoryMap.get(nutrient.category)!.nutrients.push({
          name: nutrient.name,
          id: id,
          description: nutrient.description,
          unit: nutrient.units,
        });
      }
    }

    return Array.from(categoryMap.values()).filter(
      (props: CategoryRowProps): boolean => Boolean(props.nutrients.length)
    );
  };

  const nutrientAccordionInfo = createNutrientOverrideCategories();

  const handleClick = (option: number) => {
    if (option === CalculationMethod.MAPPED) {
      setOpenDialog(true);
    } else {
      onUpdateCalculationMethodAndId(option, "");
    }
    setOpenMappedToMenu(false);
  };

  const onBaseCompositionClick = (currentTarget: HTMLButtonElement) => {
    setOpenMappedToMenu(true);
    setAnchorEl(currentTarget);
  };

  const onPreviousMappedDocumentClick = () => {
    onUpdateCalculationMethodAndId(CalculationMethod.MAPPED, mappedDocumentId);

    setOpenMappedToMenu(false);
  };

  const mappedToMenuOptions = (): ReactNode[] => {
    const menuOptions: ReactNode[] = Object.entries(
      CalculationMethodMenuMapping
    ).map(([methodId, label]: [string, string]): ReactNode => {
      return methodId !== CalculationMethod.INGREDIENTS.toString() ||
        !isFood(templateId) ? (
        <MenuItem
          key={methodId}
          data-cy="menuItem"
          onClick={() => handleClick(Number(methodId))}
        >
          {label}
        </MenuItem>
      ) : null;
    });
    if (lastSelectedMappedDocumentName) {
      menuOptions.push(
        <FoodworksTooltip
          key="overrides-tooltip"
          placement="right"
          title={
            calculationMenuOption === CalculationMethod.MAPPED
              ? lastSelectedMappedDocumentName || ""
              : ""
          }
        >
          <MenuItem
            key="lastMapped"
            data-cy="menuItem"
            onClick={() => onPreviousMappedDocumentClick()}
          >
            <Typography noWrap>{lastSelectedMappedDocumentName}</Typography>
          </MenuItem>
        </FoodworksTooltip>
      );
    }
    return menuOptions;
  };

  const calculationMethodInfoText: ReactNode = (
    <Typography color="textPrimary" variant="body1">
      Base analysis on
    </Typography>
  );

  const openCalculationMethodButton: ReactNode = (
    <FoodworksTooltip
      title={
        calculationMenuOption === CalculationMethod.MAPPED
          ? lastSelectedMappedDocumentName || ""
          : ""
      }
    >
      <Button
        data-cy="calcMethodButton"
        className={classes.openCalculationMethodButton}
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={(event) => onBaseCompositionClick(event.currentTarget)}
      >
        {documentIsLoading ? (
          <div className={classes.progress}>
            <CircularProgress color="secondary" size={20} />
          </div>
        ) : (
          <Typography variant="body1" noWrap>
            {calculationMenuOption === CalculationMethod.MAPPED
              ? lastSelectedMappedDocumentName
              : CalculationMethodMenuMapping[calculationMenuOption]}
          </Typography>
        )}
      </Button>
    </FoodworksTooltip>
  );

  const openMapToFoodButton: ReactNode = (
    <Button
      data-cy="openMappedFoodsButton"
      variant="outlined"
      key={`mappedFoodsButton`}
      onClick={(event) => setOpenDialog(true)}
      size="small"
    >
      <MoreHorizIcon />
    </Button>
  );

  const calculationMethodMenu: ReactNode = (
    <StyledMenu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={openMappedToMenu}
      onClose={() => setOpenMappedToMenu(false)}
    >
      {mappedToMenuOptions()}
    </StyledMenu>
  );

  const calculationMethodSelection: ReactNode = (
    <div className={classes.calculationMethodRoot}>
      {calculationMethodInfoText}
      <ButtonGroup>
        {openCalculationMethodButton}
        {openMapToFoodButton}
      </ButtonGroup>
      {calculationMethodMenu}
    </div>
  );

  return (
    <div className={classes.root}>
      {calculationMethodSelection}
      <OverridesTable
        data-cy="overrideTable"
        categories={nutrientAccordionInfo}
      />
      {openDialog && (
        <MappedFoodDialog
          open
          onClose={() => setOpenDialog(false)}
          combinedSummaries={summaries}
          searchTermMap={searchTermMap}
        />
      )}
    </div>
  );
};

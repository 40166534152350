import { ThunkAction } from "redux-thunk";

import { RootState } from "../../reducers";
import { IActionsSetSignUpError } from "../actions/login";
import { setSignUpError } from "../actionCreators/login";
import { IActionsSetDatabaseId } from "../../data/actions/database";
import { Routing } from "../../../data/routing/routing";
import { User } from "../../../data/models/user";
import { ExtraArguments } from "../../store";

export interface LoginError {
  type: string;
  message: string | null;
}

const ERRORS: { [key: string]: LoginError } = {
  "auth/email-already-in-use": {
    type: "email",
    message: "Account already exists",
  },
  "auth/invalid-email": {
    type: "email",
    message: "Invalid email address",
  },
  "auth/weak-password": {
    type: "password",
    message: null,
  },
  "auth/user-not-found": {
    type: "email",
    message: "User not found",
  },
  "auth/user-disabled": {
    type: "email",
    message: "User not found",
  },
  "auth/wrong-password": {
    type: "password",
    message: "Incorrect password",
  },
};

export interface RouterHistory {
  push: (path: string) => void;
  replace: (path: string) => void;
}

export const createUserWithEmailAndPassword = (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  allowMarketingEmails: boolean,
  routing: Routing
): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  IActionsSetSignUpError
> => async (dispatch, getState, { firebase, routing }) => {
  const onSignUpError = (error: LoginError) => dispatch(setSignUpError(error));

  await firebase?.setPersistence(false);

  return firebase
    .createNewUser(email, password, firstName, lastName, allowMarketingEmails)
    .then((newDatabaseId: string) => {
      routing.navigateToDatabase(newDatabaseId);
    })
    .catch((error) => {
      const errorInfo = ERRORS[error.code];
      onSignUpError({
        type: errorInfo?.type || "general",
        message: errorInfo?.message || error.message,
      });
      return;
    });
};

export const signInUserWithEmailAndPassword = (
  email: string,
  password: string,
  rememberMe: boolean,
  routing: Routing
): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  IActionsSetSignUpError | IActionsSetDatabaseId
> => async (dispatch, getState, { firebase, routing }) => {
  const onSignUpError = (error: LoginError) => dispatch(setSignUpError(error));

  await firebase.setPersistence(rememberMe);

  return firebase?.authentication
    .doSignInWithEmailAndPassword(email, password)
    .then((auth: firebase.auth.UserCredential) => {
      firebase?.users
        .doGetUser(auth!.user!.uid)
        .then((user: User | undefined) => {
          routing.navigateToDatabase(user!.lastUsedDatabase);
        });
    })
    .catch((error) => {
      const errorInfo = ERRORS[error.code];
      onSignUpError({
        type: errorInfo?.type || "general",
        message: errorInfo?.message || error.message,
      });
    });
};

export const sendForgotPasswordEmail = (
  email: string
): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  IActionsSetSignUpError | IActionsSetDatabaseId
> => async (dispatch, getState, { firebase, routing }) => {
  const onSignUpError = (error: LoginError) => dispatch(setSignUpError(error));
  const onSignUpSuccess = () =>
    dispatch(setSignUpError({ type: "", message: null }));

  return firebase?.authentication
    .doPasswordReset(email)
    .then((result) => {
      onSignUpSuccess();
      return result;
    })
    .catch((error) => {
      const errorInfo = ERRORS[error.code];
      onSignUpError({
        type: errorInfo?.type || "general",
        message: errorInfo?.message || error.message,
      });
    });
};

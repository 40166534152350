import { createStore, applyMiddleware, Action } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk, { ThunkDispatch } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { useDispatch } from "react-redux";

import reducers, { RootState } from "./reducers";
import { compositionSaga } from "./data/sagas/composition";
import Firebase from "../data/Firebase";
import { fetchCompositionSaga } from "./data/sagas/fetchComposition";
import { Routing } from "../data/routing/routing";
import undoRedoDocumentSaga from "./data/sagas/undoRedo";
import { nutrientReferenceValueSaga } from "./data/sagas/nutrientReferenceValues";

const sagaMiddleware = createSagaMiddleware();

export interface ExtraArguments {
  firebase: Firebase;
  routing: Routing;
}

export const createOurStore = (extraArguments: ExtraArguments) => {
  const middleware = [sagaMiddleware, thunk.withExtraArgument(extraArguments)];

  const devTools =
    process.env.NODE_ENV === "production"
      ? applyMiddleware(...middleware)
      : composeWithDevTools(applyMiddleware(...middleware));

  const store = createStore(reducers, devTools);

  sagaMiddleware.run(compositionSaga);
  sagaMiddleware.run(fetchCompositionSaga);
  sagaMiddleware.run(undoRedoDocumentSaga);
  sagaMiddleware.run(nutrientReferenceValueSaga, extraArguments.firebase);

  return store;
};

export type ReduxDispatch = ThunkDispatch<RootState, any, Action>;

export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

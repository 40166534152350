import React, { useState } from "react";
import { makeStyles, Divider } from "@material-ui/core";
import { useSelector } from "react-redux";

import { RootState } from "../../../../store/reducers";
import { NavIsLoadingSelector } from "../../../../store/ui/selectors/navigationSelectors";
import { DocumentExplorer } from "./DocumentExplorer";
import CreateDocumentButton from "../shared/CreateDocumentButton";
import DatabaseButton from "./DatabaseButton";
import SupportButtonBar from "../shared/SupportButtonBar";
import CollapsePaneButton from "../shared/CollapsePaneButton";
import NavigationPaneBody from "../shared/NavigationPaneBody";
import NavigationDocumentList from "./DocumentList";
import NavigationPaneMessage from "../shared/NavigationPaneMessage";
import { FOODS, RECIPES } from "../../../../constants/FoodTemplate";
import {
  DELETED_DOCUMENTS_FILTER,
  FOODS_FILTER,
  RECIPES_FILTER,
} from "../../../../constants/QuickFilter";
import { Tag } from "../../../../data/models/documentProperties/section";
import { databaseDocumentTagsSelector } from "../../../../store/data/selectors/database";
import NavigationFilter from "../shared/NavigationFilter";
import useDocuments from "../hooks/useDocuments";
import usePinnedDocument from "../hooks/usePinnedDocument";
import FoodWorksTagSwitch from "../../../common/FoodWorksTagSwitch";

const useStyles = makeStyles(() => ({
  body: {
    flex: 2,
    overflowY: "auto",
    overflowX: "hidden",
  },
  currentDatabaseDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "15px 0px",
    overflow: "hidden",
  },
  bodyRoot: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  topButtons: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  switchContainer: {
    margin: "5px 0px",
  },
}));

const DocumentNavigationPane = (): JSX.Element => {
  const classes = useStyles();

  const navIsLoading: boolean =
    useSelector<RootState, boolean>(NavIsLoadingSelector);

  const databaseDocumentTags: Tag[] = useSelector<RootState, Tag[]>(
    databaseDocumentTagsSelector
  );

  const [open, setOpen] = useState(true);

  const [pinnedDocumentId, onPinDocumentInExplorer] = usePinnedDocument();
  const [searchText, setSearchText] = useState<string>("");
  const [showDocumentTags, setShowDocumentTags] = useState<boolean>(false);

  const { documents, hasNonDeletedDocuments, deletedFilterApplied } = {
    ...useDocuments(searchText),
  };

  return (
    <NavigationPaneBody open={open} setOpen={setOpen}>
      <div className={classes.topButtons}>
        <div className={classes.currentDatabaseDiv}>
          <DatabaseButton />
          <CollapsePaneButton type="Navigation" open={open} setOpen={setOpen} />
        </div>
        <CreateDocumentButton options={[FOODS, RECIPES]} />
      </div>
      <NavigationFilter
        fetchSearchText={setSearchText}
        searchText={searchText}
        foodFilters={[
          FOODS_FILTER,
          RECIPES_FILTER,
          DELETED_DOCUMENTS_FILTER,
        ]}
        availableTags={databaseDocumentTags}
        isClient={undefined}
      />
      <Divider />
      <div data-cy="navBody" className={classes.bodyRoot}>
        <div className={classes.body}>
          <div className={classes.switchContainer}>
            <FoodWorksTagSwitch
              label="Show document tags"
              checked={showDocumentTags}
              onChange={() => setShowDocumentTags((prev) => !prev)}
            />
          </div>
          <NavigationPaneMessage
            isLoading={navIsLoading}
            deletedDocumentFilterApplied={deletedFilterApplied}
            hasNonDeletedDocuments={hasNonDeletedDocuments}
            hasDocumentsToDisplay={!!documents.length}
            itemType="document"
          />
          <NavigationDocumentList
            showTags={showDocumentTags}
            visibleDocuments={documents}
            navIsLoading={navIsLoading}
            lockedDocumentId={{
              value: pinnedDocumentId,
              set: onPinDocumentInExplorer,
            }}
          />
        </div>
        <Divider />
        <DocumentExplorer documentId={pinnedDocumentId} />
        <SupportButtonBar />
      </div>
    </NavigationPaneBody>
  );
};

export default DocumentNavigationPane;

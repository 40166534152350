import { Action } from "redux";

import {
  Measurement,
  MeasurementEntry,
} from "../../../../data/models/clientProperties/measurement";

export const ADD_CLIENT_MEASUREMENT = "ADD_CLIENT_MEASUREMENT";
export const EDIT_CLIENT_MEASUREMENT = "EDIT_CLIENT_MEASUREMENT";
export const DELETE_CLIENT_MEASUREMENT = "DELETE_CLIENT_MEASUREMENT";
export const TOGGLE_CLIENT_MEASUREMENT = "TOGGLE_CLIENT_MEASUREMENT";
export const ADD_CLIENT_MEASUREMENT_ENTRY = "ADD_CLIENT_MEASUREMENT_ENTRY";
export const UPDATE_CLIENT_MEASUREMENT_ENTRY =
  "UPDATE_CLIENT_MEASUREMENT_ENTRY";
export const DELETE_CLIENT_MEASUREMENT_ENTRY =
  "DELETE_CLIENT_MEASUREMENT_ENTRY";

export interface IActionsAddClientMeasurement extends Action {
  type: "ADD_CLIENT_MEASUREMENT";
  measurement: Measurement;
}

export interface IActionsEditClientMeasurement extends Action {
  type: "EDIT_CLIENT_MEASUREMENT";
  measurement: Measurement;
  newLabel: string;
  newUnits: string;
  newSubCategory: string;
}

export interface IActionsDeleteClientMeasurement extends Action {
  type: "DELETE_CLIENT_MEASUREMENT";
  label: string;
  unit: string;
}

export interface IActionsToggleClientMeasurement extends Action {
  type: "TOGGLE_CLIENT_MEASUREMENT";
  label: string;
  unit: string;
}

export interface IActionsAddClientMeasurementEntry extends Action {
  type: "ADD_CLIENT_MEASUREMENT_ENTRY";
  label: string;
  unit: string;
  entry: MeasurementEntry;
}

export interface IActionsUpdateClientMeasurementEntry extends Action {
  type: "UPDATE_CLIENT_MEASUREMENT_ENTRY";
  label: string;
  unit: string;
  entry: MeasurementEntry;
}

export interface IActionsDeleteClientMeasurementEntry extends Action {
  type: "DELETE_CLIENT_MEASUREMENT_ENTRY";
  label: string;
  unit: string;
  id: string;
}

export type ClientMeasurementsActions =
  | IActionsAddClientMeasurement
  | IActionsEditClientMeasurement
  | IActionsDeleteClientMeasurement
  | IActionsToggleClientMeasurement
  | IActionsAddClientMeasurementEntry
  | IActionsUpdateClientMeasurementEntry
  | IActionsDeleteClientMeasurementEntry;

import React, { FunctionComponent, ReactNode, useState } from "react";

import {
  makeStyles,
  Button,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { BaseDialog } from "../BaseDialog";
import { appTheme } from "../../../styling/style";
import { deleteClient } from "../../../store/data/current_client/thunks/client";
import { ClientSummary } from "../../../data/models/client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "center",
  },
  titleBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  flex: {
    display: "flex",
  },
  deleteButton: {
    color: appTheme.colors.error,
  },
  databaseName: {
    marginLeft: 10,
    fontWeight: "bold",
  },
  confirmationInput: {
    marginTop: 10,
    width: 300,
    fontSize: appTheme.fontSize.textInput,
  },
  none: {},
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: appTheme.colors.success,
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: appTheme.colors.error,
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderColor: appTheme.colors.error,
      borderWidth: 2,
      borderLeftWidth: 6,
      padding: "4px !important",
    },
  },
})(TextField);

interface DeleteDatabaseDialogProps {
  open: boolean;
  onClose: () => void;
  clientSummary: ClientSummary;
}
export const DeleteClientDialog: FunctionComponent<DeleteDatabaseDialogProps> = ({
  open,
  onClose,
  clientSummary,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [confirmText, setConfirmText] = useState("");
  const incorrectClientName: boolean = confirmText !== clientSummary.label;

  const dialogBody: ReactNode = (
    <div>
      <Typography>
        Doing so will permanently delete the data for this Client!
      </Typography>
      <div className={classes.flex}>
        <Typography>
          Confirm you want to delete this document by typing their name:
        </Typography>
        <Typography
          data-cy="deleteDatabaseName"
          className={classes.databaseName}
        >
          {clientSummary.label}
        </Typography>
      </div>

      <ValidationTextField
        data-cy="deleteDatabaseNameInput"
        className={classes.confirmationInput}
        id="databaseToDelete"
        size="small"
        variant="outlined"
        placeholder={clientSummary.label}
        required
        error={incorrectClientName}
        helperText={incorrectClientName ? `Enter the document name` : ""}
        value={confirmText}
        onChange={(event) => setConfirmText(event.currentTarget.value)}
      />
    </div>
  );

  const onClickDelete = () => {
    onDeleteClient();
    onClose();
  };

  const onDeleteClient = () => {
    dispatch(deleteClient(clientSummary.clientId));
  };

  const dialogActions: ReactNode = (
    <div>
      <Button color="default" onClick={onClose}>
        Cancel
      </Button>
      <Button
        data-cy="confirmDeleteDatabaseButton"
        className={incorrectClientName ? classes.none : classes.deleteButton}
        disabled={incorrectClientName}
        onClick={onClickDelete}
      >
        Delete
      </Button>
    </div>
  );
  return (
    <BaseDialog
      onClose={onClose}
      dataCy="deleteDatabaseDialog"
      open={open}
      title="Permanently delete this document?"
      body={dialogBody}
      action={dialogActions}
      maxWidth="md"
    ></BaseDialog>
  );
};

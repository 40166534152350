import React, { FunctionComponent } from "react";

import { Button } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";

interface OpenDialogButtonProps {
  onClick: () => void;
  label: string;
  className?: string;
  cypressTag?: string;
}

export const OpenDialogButton: FunctionComponent<OpenDialogButtonProps> = ({
  onClick,
  label,
  className,
  cypressTag,
}) => {
  return (
    <Button
      className={className}
      data-cy={cypressTag}
      size="small"
      startIcon={<OpenInNew color="inherit" />}
      color="secondary"
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

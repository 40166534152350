import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";

import { FoodworksTooltip } from "../../../../../../common/InfoTooltip";
import { PostAdd } from "@material-ui/icons";
import { appTheme } from "../../../../../../../styling/style";

const useStyles = makeStyles(() => ({
  input: {
    width: 50,
  },
  addEntryButton: {
    color: appTheme.colors.primary,
  },
  submitButtonContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export interface SubmitMeasurementEntryButtonProps {
  onClickSubmitEntry: any;
  disabled: boolean;
}

const SubmitMeasurementEntryButton =
  React.memo<SubmitMeasurementEntryButtonProps>((props) => {
    const classes = useStyles();

    return (
      <div className={classes.submitButtonContainer}>
        <FoodworksTooltip title="Submit new entry">
          <IconButton
            size="small"
            onClick={props.onClickSubmitEntry}
            className={classes.addEntryButton}
            disabled={props.disabled}
          >
            <PostAdd />
          </IconButton>
        </FoodworksTooltip>
      </div>
    );
  });

export default SubmitMeasurementEntryButton;

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import RecentClientList from "./components/RecentClientList";
import { appTheme } from "../../../../styling/style";
import FoodworksBreadcrumb from "../../../common/FoodworksBreadcrumb";
import { ManageClientTagsButton } from "../../../dialogs/tags/client_tags/ManageClientTagsButton";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  title: {
    marginTop: 25,
    marginLeft: 15,
  },
  content: {
    flex: 1,
    overflow: "hidden",
    padding: 25,
    margin: "25px 25px 25px 15px",
    border: `solid 1px ${appTheme.colors.gainsbruh}`,
    borderRadius: 4,
  },
  breadcrumbContainer: {
    margin: 10,
  },
}));

const ClientDashboard = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.breadcrumbContainer}>
        <FoodworksBreadcrumb />
      </div>
      <Typography className={classes.title} variant="h1">
        Clients
      </Typography>
      <div className={classes.content}>
        <RecentClientList />
        <ManageClientTagsButton />
      </div>
    </div>
  );
};

export default ClientDashboard;

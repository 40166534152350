import { usePreviousPerpetualMeasurement } from "../../../tracking/tracking-card/hooks/usePreviousMeasurement";

const useClientCurentWeight = (): number => {
  const currentWeight: { value: number; date: Date } | undefined =
    usePreviousPerpetualMeasurement("Weight", "kg");

  return currentWeight ? currentWeight.value : 0;
};

export default useClientCurentWeight;

import {
  IActionsSetDocumentId,
  IActionsAllDocumentsFetched,
  IActionsUpdateBaseComposition,
  IActionsUpdateFinalComposition,
  IActionsSetDocumentLoading,
  IActionsUpdateServerDocument,
  IActionsUpdateCurrentDocument,
} from "../actions/currentDocument";
import { Document } from "../../../../data/models/document";
import { CompositionState } from "../../../../data/models/composition";

export const setDocumentId = (documentId: string): IActionsSetDocumentId => {
  return {
    type: "SET_DOCUMENT_ID",
    id: documentId,
  };
};

export const allDocumentsFetched = (): IActionsAllDocumentsFetched => {
  return {
    type: "ALL_DOCUMENTS_FETCHED",
  };
};

export const updateBaseComposition = (
  composition: CompositionState
): IActionsUpdateBaseComposition => {
  return {
    type: "UPDATE_BASE_COMPOSITION",
    composition: composition,
  };
};

export const updateFinalComposition = (
  composition: CompositionState
): IActionsUpdateFinalComposition => {
  return {
    type: "UPDATE_FINAL_COMPOSITION",
    composition: composition,
  };
};

export const changeDocumentLoadingState = (
  isLoading: boolean
): IActionsSetDocumentLoading => {
  return {
    type: "SET_DOCUMENT_LOADING",
    isLoading: isLoading,
  };
};

export const updateServerDocument = (
  document: Document
): IActionsUpdateServerDocument => {
  return {
    type: "UPDATE_SERVER_DOCUMENT",
    document: document,
  };
};

export const updateCurrentDocument = (
  document: Document
): IActionsUpdateCurrentDocument => {
  return {
    type: "UPDATE_CURRENT_DOCUMENT",
    document: document,
  };
};

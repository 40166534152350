import { ClientSummary } from "./client";
import { Tag } from "./documentProperties/section";

export interface ClientDatabase {
  clientSummaries: ClientSummary[];
  databaseId: string;
  properties: ClientDatabaseProperties;
  tags: Tag[];
}

export interface ClientDatabaseProperties {}

export const clientDatabaseConverter = {
  toFirestore: function (clientDatabase: ClientDatabase) {
    throw new Error("Unimplemented");
  },
  fromFirestore: function (
    snapshot: firebase.firestore.DocumentData
  ): ClientDatabase {
    const data = snapshot.data();

    return {
      clientSummaries: data.clientSummaries,
      databaseId: data.databaseId,
      properties: data.properties,
      tags: data.tags,
    };
  },
};

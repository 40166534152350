import {
  DocumentSavingActions,
  SET_UNSAVED_CLIENT_CHANGES,
  SET_UNSAVED_DOCUMENT_CHANGES,
} from "../actions/saving";

export interface SavingState {
  openSaveDialog: boolean;
  openClientDialog: boolean;
}

export const initialSavingState: SavingState = {
  openSaveDialog: false,
  openClientDialog: false,
};

export const saving = (
  state: SavingState = initialSavingState,
  action: DocumentSavingActions
): SavingState => {
  switch (action.type) {
    case SET_UNSAVED_DOCUMENT_CHANGES:
      return {
        ...state,
        openSaveDialog: action.openSaveDialog,
      };
    case SET_UNSAVED_CLIENT_CHANGES:
      return { ...state, openClientDialog: action.openClientDialog };
    default:
      return state;
  }
};

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import { FoodWorksNumberInput } from "../../../../../../common/FoodWorksTextInput";
import ProfileItem from "./ProfileItem";
import { MeasurementEntry } from "../../../../../../../data/models/clientProperties/measurement";
import { addClientMeasurementEntry } from "../../../../../../../store/data/current_client/action_creators/clientMeasurements";
import SubmitMeasurementEntryButton from "./SubmitMeasurementEntryButton";
import { appTheme } from "../../../../../../../styling/style";
import { TextOverflowTooltip } from "../../../../../../common/InfoTooltip";
import useClientHeight from "../hooks/useClientHeight";

const useStyles = makeStyles(() => ({
  input: {
    width: 50,
  },
  addEntryButton: {
    color: appTheme.colors.primary,
  },
  submitButtonContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  inputValueContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContents: "space-between",
  },
  valueInput: {
    width: 75,
    marginRight: 10,
  },
  unitText: {
    width: 75,
  },
}));

const HeightInput = React.memo((): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const previousHeight: number = useClientHeight();
  const [newHeight, setNewHeight] = useState<number>(0);

  const onAddClientMeasurementEntry = (entry: MeasurementEntry) =>
    dispatch(addClientMeasurementEntry(entry, "Height", "cm"));

  const onClickSubmitEntry = () => {
    onAddClientMeasurementEntry({
      value: newHeight,
      date: new Date().toISOString(),
      id: uuidv4(),
    });
  };

  const inputComponent: JSX.Element = (<div className={classes.inputValueContainer}>
    <FoodWorksNumberInput
      inputProps={{
        value: newHeight ? newHeight.toString() : "",
        className: classes.valueInput,
      }}
      maxValue={250}
      setValue={(input: string) => setNewHeight(Number(input))}
    />
    <TextOverflowTooltip label="cm">
      <Typography className={classes.unitText} noWrap>
        cm
      </Typography>
    </TextOverflowTooltip>
    <SubmitMeasurementEntryButton
      onClickSubmitEntry={onClickSubmitEntry}
      disabled={!newHeight}
    />
  </div>)

const displayComponent: JSX.Element = (
  <Typography color="secondary">{`${previousHeight} cm`}</Typography>
)

  return (
    <>
      <ProfileItem
        tooltip="Updated height can be logged in the tracking tab"
        component={!previousHeight ? inputComponent : displayComponent}
        label={`${!previousHeight ? "Start" : "Current"} height`}
        isEmptyInfoTooltip={
          !newHeight && !previousHeight ? "This field must not be empty." : ""
        }
      />
    </>
  );
});

export default HeightInput;

import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import {
  DocumentSummary,
  UserDatabaseSummary,
} from "../../../../data/models/userDatabase";
import { RootState } from "../../../reducers";
import { ExtraArguments } from "../../../store";
import {
  databaseIdSelector,
  userDocumentSummariesSelector,
} from "../../selectors/database";
import { sortedUserDatabaseSummariesSelector } from "../../selectors/user";
import { fetchUserDocumentSummaries } from "../database";
import cloneDocumentsToDatabase from "./helper-functions/cloneDocuments";

const importDocuments =
  (
    selectedDocumentsToImport: string[]
  ): ThunkAction<Promise<void>, RootState, ExtraArguments, Action> =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const userDatabaseIds: string[] = sortedUserDatabaseSummariesSelector(
      getState()
    ).map((summary: UserDatabaseSummary): string => summary.id);

    const allDocumentNames: string[] = userDocumentSummariesSelector(
      getState()
    ).map((summary: DocumentSummary): string => summary.label);

    return cloneDocumentsToDatabase(
      selectedDocumentsToImport,
      currentDatabaseId,
      allDocumentNames,
      userDatabaseIds,
      firebase
    ).then(() => dispatch(fetchUserDocumentSummaries()));
  };

export default importDocuments;

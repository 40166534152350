import { Size } from "re-resizable";

import { NUT_PANE_SIZE } from "../../../constants/paneSize";
import {
  NutritionPaneActions,
  UPDATE_SELECTED_AMOUNT,
  UPDATE_CUSTOM_AMOUNT,
  TAPPED_CATEGORY_ACCORDION,
  SET_SELECTED_SECTION_TAGS,
  SET_NUT_PANE_DIMENSIONS,
} from "../actions/nutritionPaneActions";

export enum NutritionRadioOption {
  TOTAL = "Total",
  PER_SERVE = "Serve",
  ONE_HUNDRED_G = "100g",
  CUSTOM = "Custom",
  ONE_HUNDRED_ML = "100ml",
  DAY = "Avg/Day",
  MEGAJOULE = "1MJ",
}

const GENERAL_ID = "33";
const MACRONUTRIENTS_ID = "2";

export interface NutritionPaneState {
  selectedAmount: NutritionRadioOption;
  customAmount: number;
  categoriesExpanded: string[];
  selectedSectionTags: string[];
  dimensions: Size;
}

export const initialNutritionPaneState: NutritionPaneState = {
  selectedAmount: NutritionRadioOption.ONE_HUNDRED_G,
  customAmount: 100,
  categoriesExpanded: [GENERAL_ID, MACRONUTRIENTS_ID],
  selectedSectionTags: [],
  dimensions: NUT_PANE_SIZE,
};

export const nutritionPane = (
  state: NutritionPaneState = initialNutritionPaneState,
  action: NutritionPaneActions
): NutritionPaneState => {
  switch (action.type) {
    case UPDATE_SELECTED_AMOUNT:
      return { ...state, selectedAmount: action.selectedAmount };
    case UPDATE_CUSTOM_AMOUNT:
      return { ...state, customAmount: action.customAmount };
    case TAPPED_CATEGORY_ACCORDION:
      if (state.categoriesExpanded.includes(action.categoryId)) {
        return {
          ...state,
          categoriesExpanded: state.categoriesExpanded.filter(
            (categoryId: string): boolean => categoryId !== action.categoryId
          ),
        };
      }
      return {
        ...state,
        categoriesExpanded: state.categoriesExpanded.concat(action.categoryId),
      };
    case SET_SELECTED_SECTION_TAGS:
      return { ...state, selectedSectionTags: action.tagIds };
    case SET_NUT_PANE_DIMENSIONS:
      return { ...state, dimensions: action.size };
    default:
      return state;
  }
};

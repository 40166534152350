import { Quantity, quantityConverter } from "./quantity";
import { FoodIdObject, foodIdConverter, FoodId } from "./foodId";
import { RetentionFactor } from "./retentionFactor";

export class FoodItem {
  foodId: FoodId | undefined;
  quantity: Quantity | undefined;
  frequency: string;
  rowIndex: number;
  retentionFactor: RetentionFactor | null;
  note: string;

  constructor(
    foodId: FoodId | undefined,
    quantity: Quantity | undefined,
    frequency: string,
    rowIndex: number,
    retentionFactor: RetentionFactor | null,
    note: string
  ) {
    this.foodId = foodId;
    this.quantity = quantity;
    this.frequency = frequency;
    this.rowIndex = rowIndex;
    this.retentionFactor = retentionFactor;
    this.note = note;
  }

  static fromObject = (
    foodItemState: FoodItemState,
    retentionFactorMap: Map<string, RetentionFactor>
  ): FoodItem => {
    const foodId: FoodId | undefined = !foodItemState.foodId
      ? undefined
      : new FoodId(foodItemState.foodId);
    let retentionFactor: RetentionFactor | null = null;
    if (foodItemState.retentionFactorId) {
      retentionFactor = retentionFactorMap.get(
        foodItemState.retentionFactorId
      )!;
    }
    return new FoodItem(
      foodId,
      foodItemState.quantity,
      foodItemState.frequency,
      foodItemState.rowIndex,
      retentionFactor,
      foodItemState.note
    );
  };

  public get object(): FoodItemState {
    const foodId: FoodIdObject | undefined = !this.foodId
      ? undefined
      : {
          datasourceId: this.foodId.datasourceId,
          documentId: this.foodId.documentId,
        };
    const retentionFactorId: string | null = !this.retentionFactor
      ? null
      : this.retentionFactor.profileId;
    return {
      foodId: foodId,
      quantity: this.quantity,
      frequency: this.frequency,
      rowIndex: this.rowIndex,
      retentionFactorId: retentionFactorId,
      note: this.note,
    };
  }
}

export class FoodItems {
  items: FoodItem[];

  constructor(
    foodItemsState: FoodItemState[],
    retentionFactorMap: Map<string, RetentionFactor>
  ) {
    this.items = foodItemsState.map(
      (foodItemState: FoodItemState): FoodItem =>
        FoodItem.fromObject(foodItemState, retentionFactorMap)
    );
  }

  get length(): number {
    return this.items.length;
  }
}

export interface FoodItemState {
  foodId: FoodIdObject | undefined;
  quantity: Quantity | undefined;
  frequency: string;
  rowIndex: number;
  retentionFactorId: string | null;
  note: string;
}

export const foodItemsConverter = {
  toFirestore: function (
    foodItems: FoodItemState[]
  ): firebase.firestore.DocumentData {
    return foodItems.map(
      (foodItem: FoodItemState): firebase.firestore.DocumentData => {
        const foodId: FoodIdObject = foodIdConverter.toJson(foodItem.foodId);
        const quantity: Quantity = quantityConverter.toJson(foodItem.quantity);

        return {
          foodId: foodId,
          quantity: quantity,
          frequency: foodItem.frequency,
          rowIndex: foodItem.rowIndex,
          retentionFactorId: foodItem.retentionFactorId,
          note: foodItem.note,
        };
      }
    );
  },

  fromFirestore: function (
    data: firebase.firestore.DocumentData
  ): FoodItemState[] {
    if (!data) return [];

    return data.map(
      (foodItem: firebase.firestore.DocumentData): FoodItemState => {
        const foodId: FoodIdObject | undefined = foodIdConverter.fromJson(
          foodItem.foodId
        );
        const quantity: Quantity | undefined = quantityConverter.fromJson(
          foodItem.quantity
        );
        return {
          foodId: foodId,
          quantity: quantity,
          frequency: foodItem.frequency,
          rowIndex: foodItem.rowIndex,
          retentionFactorId: foodItem.retentionFactorId,
          note: foodItem.note,
        };
      }
    );
  },
};

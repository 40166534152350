import {
  initialAnthroMeasurements,
  initialBiochemMeasurements,
  initialClinicalMeasurements,
} from "../../../../constants/measurements";
import {
  Measurement,
  MeasurementEntry,
} from "../../../../data/models/clientProperties/measurement";
import {
  ADD_CLIENT_MEASUREMENT,
  ADD_CLIENT_MEASUREMENT_ENTRY,
  ClientMeasurementsActions,
  DELETE_CLIENT_MEASUREMENT,
  DELETE_CLIENT_MEASUREMENT_ENTRY,
  EDIT_CLIENT_MEASUREMENT,
  TOGGLE_CLIENT_MEASUREMENT,
  UPDATE_CLIENT_MEASUREMENT_ENTRY,
} from "../actions/clientMeasurements";

export type MeasurementsState = Measurement[];

export const initialMeasurementsState: MeasurementsState =
  initialBiochemMeasurements
    .map(
      (measurement): Measurement => ({
        category: "Biochemical",
        label: measurement.label,
        unit: measurement.unit,
        subCategory: measurement.subCategory,
        entries: [],
        active: true,
      })
    )
    .concat(
      initialClinicalMeasurements.map(
        (measurement): Measurement => ({
          category: "Clinical",
          label: measurement.label,
          unit: measurement.unit,
          subCategory: measurement.subCategory,
          entries: [],
          active: true,
        })
      )
    )
    .concat(
      initialAnthroMeasurements.map(
        (measurement): Measurement => ({
          category: "Anthropometric",
          label: measurement.label,
          unit: measurement.unit,
          subCategory: measurement.subCategory,
          entries: [],
          active: true,
        })
      )
    );

export const clientMeasurements = (
  state: MeasurementsState = initialMeasurementsState,
  action: ClientMeasurementsActions
): MeasurementsState => {
  switch (action.type) {
    case ADD_CLIENT_MEASUREMENT:
      return state.some(
        (measurement) =>
          measurement.label === action.measurement.label &&
          measurement.unit === action.measurement.unit
      )
        ? state.map(
            (measurement: Measurement): Measurement =>
              measurement.label === action.measurement.label &&
              measurement.unit === action.measurement.unit
                ? { ...measurement, active: true }
                : measurement
          )
        : [...state, action.measurement];

    case EDIT_CLIENT_MEASUREMENT:
      return state.map((measurement: Measurement) =>
        measurement.label === action.measurement.label &&
        measurement.unit === action.measurement.unit
          ? {
              ...measurement,
              label: action.newLabel,
              unit: action.newUnits,
              subCategory: action.newSubCategory,
            }
          : measurement
      );
    case DELETE_CLIENT_MEASUREMENT:
      return state.map((measurement: Measurement) =>
        measurement.label === action.label && measurement.unit === action.unit
          ? { ...measurement, active: false }
          : measurement
      );
    case TOGGLE_CLIENT_MEASUREMENT:
      return state.map(
        (measurement: Measurement): Measurement =>
          measurement.label === action.label && measurement.unit === action.unit
            ? { ...measurement, active: !measurement.active }
            : measurement
      );
    case ADD_CLIENT_MEASUREMENT_ENTRY:
      //Adds and sorts measurement entries
      return state.map(
        (measurement: Measurement): Measurement =>
          measurement.label === action.label && measurement.unit === action.unit
            ? {
                ...measurement,
                entries: measurement.entries.find(
                  (entry) =>
                    entry.date === action.entry.date &&
                    entry.value === action.entry.value
                )
                  ? [...measurement.entries]
                  : [...measurement.entries, action.entry].sort(
                      (a: MeasurementEntry, b: MeasurementEntry) =>
                        b.date > a.date ? -1 : b.date < a.date ? 1 : 0
                    ),
              }
            : measurement
      );

    case UPDATE_CLIENT_MEASUREMENT_ENTRY:
      //Updates and sorts measurement entries
      return state.map(
        (measurement: Measurement): Measurement =>
          measurement.label === action.label && measurement.unit === action.unit
            ? {
                ...measurement,
                entries: measurement.entries
                  .map(
                    (entry: MeasurementEntry): MeasurementEntry =>
                      entry.id === action.entry.id ? action.entry : entry
                  )
                  .sort((a: MeasurementEntry, b: MeasurementEntry) =>
                    b.date > a.date ? -1 : b.date < a.date ? 1 : 0
                  ),
              }
            : measurement
      );
    case DELETE_CLIENT_MEASUREMENT_ENTRY:
      return state.map(
        (measurement: Measurement): Measurement =>
          measurement.label === action.label && measurement.unit === action.unit
            ? {
                ...measurement,
                entries: measurement.entries.filter(
                  (entry: MeasurementEntry) => entry.id !== action.id
                ),
              }
            : measurement
      );

    default:
      return state;
  }
};

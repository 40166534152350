import app, { firestore } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";

import { documentConverter, Document } from "../models/document";
import {
  Database,
  DocumentSummary,
  UserDatabaseSummary,
} from "../models/userDatabase";
import {
  getCurrentDate,
  FoodWorksDate,
} from "../models/documentProperties/date";
import { FirebaseAuthentication } from "./helpers/authentication";
import { FirebaseUsers } from "./helpers/users";
import { FirebaseReferenceData } from "./helpers/referenceData";
import { FirebaseUserPermissions } from "./helpers/userPermissions";
import { FirebaseUserDatabases } from "./helpers/userDatabases";
import { FirebasePublicDatabases } from "./helpers/publicDatabases";
import { FirebaseFunctions } from "./helpers/functions";
import { FirebaseClientDatabases } from "./helpers/clientDatabases";

declare global {
  interface Window {
    Cypress?: any;
  }
}

export const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

export interface FirebaseProps {
  firebase?: Firebase;
}

interface FirebaseCollectionQuery {
  get: (
    options?: firestore.GetOptions | undefined
  ) => Promise<firestore.QuerySnapshot<any>>;
}

interface FirebaseDocumentQuery {
  get: (
    options?: firestore.GetOptions | undefined
  ) => Promise<firestore.DocumentSnapshot<any>>;
}

class Firebase {
  auth: firebase.auth.Auth;
  db: firebase.firestore.Firestore;
  storage: firebase.storage.Storage;

  authentication: FirebaseAuthentication;
  users: FirebaseUsers;
  userPermissions: FirebaseUserPermissions;
  userDatabases: FirebaseUserDatabases;
  publicDatabases: FirebasePublicDatabases;
  clientDatabases: FirebaseClientDatabases;
  referenceData: FirebaseReferenceData;
  functions: FirebaseFunctions;

  constructor() {
    app.initializeApp(config);
    app.analytics();
    this.auth = app.auth();

    this.db = app.firestore();
    this.storage = app.storage();

    const firestoreSettings: {
      host?: string;
      ssl?: boolean;
      experimentalForceLongPolling?: boolean;
    } = {};
    // Connect to emulators in dev environment
    if (window.location.hostname === "localhost") {
      const firestoreEmulatorHost =
        process.env.REACT_APP_FIRESTORE_EMULATOR_HOST;

      if (firestoreEmulatorHost) {
        firestoreSettings.host = firestoreEmulatorHost;
        firestoreSettings.ssl = false;
      }
    }
    if (window.Cypress) {
      // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
      firestoreSettings.experimentalForceLongPolling = true;
    }

    this.db.settings(firestoreSettings);

    this.db.enablePersistence().catch(function (err) {
      if (err.code === "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });

    this.authentication = new FirebaseAuthentication(app.auth());
    this.users = new FirebaseUsers(app.firestore());
    this.userPermissions = new FirebaseUserPermissions(app.firestore());
    this.userDatabases = new FirebaseUserDatabases(app.firestore());
    this.publicDatabases = new FirebasePublicDatabases(app.firestore());
    this.clientDatabases = new FirebaseClientDatabases(app.firestore());
    this.referenceData = new FirebaseReferenceData(app.firestore());
    this.functions = new FirebaseFunctions();
  }

  setPersistence = (shouldPersist: boolean): Promise<void> => {
    return this.auth.setPersistence(
      shouldPersist
        ? firebase.auth.Auth.Persistence.LOCAL
        : firebase.auth.Auth.Persistence.SESSION
    );
  };

  static batch() {
    return app.firestore().batch();
  }

  static cachedCollectionGet = async (
    firebaseQuery: FirebaseCollectionQuery
  ): Promise<firestore.QuerySnapshot<any>> => {
    let snapshot = await firebaseQuery.get({ source: "cache" });
    if (snapshot.empty) {
      return firebaseQuery.get({ source: "server" });
    }
    return snapshot;
  };

  static cachedDocumentGet = async (
    firebaseQuery: FirebaseDocumentQuery
  ): Promise<app.firestore.DocumentSnapshot<any>> => {
    let snapshot: app.firestore.DocumentSnapshot<any>;
    try {
      snapshot = await firebaseQuery.get({ source: "cache" });
    } catch (e) {
      snapshot = await firebaseQuery.get({ source: "server" });
    }
    return snapshot;
  };

  async createNewUser(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    allowMarketingEmails: boolean
  ): Promise<string> {
    const authResult: app.auth.UserCredential =
      await this.authentication.doCreateUserWithEmailAndPassword(
        email,
        password
      );

    authResult.user?.sendEmailVerification();

    const userId: string = authResult.user?.uid!;

    await this.users.doCreateUser({
      id: userId,
      firstName: firstName,
      lastName: lastName,
      allowMarketingEmails: allowMarketingEmails,
      lastUsedDatabase: "",
      lastScreen: "databases",
    });

    await this.userPermissions.doCreateUserPermissions(userId);

    const createdAndModifiedDate = getCurrentDate();

    const clientUserDatabaseId: string = await this.createNewUserDatabase(
      userId,
      "Client documents",
      { created: createdAndModifiedDate, lastModified: createdAndModifiedDate }
    );

    await this.clientDatabases
      .doCreateUserClientDatabase(clientUserDatabaseId)
      .then((clientDatabaseId) =>
        this.userPermissions.doSetClientDatabasePermission(
          userId,
          clientDatabaseId
        )
      );

    return this.createNewUserDatabase(userId, "New database", {
      created: createdAndModifiedDate,
      lastModified: createdAndModifiedDate,
    });
  }

  async createNewUserDatabase(
    uid: string,
    name: string,
    date: FoodWorksDate
  ): Promise<string> {
    const newDatabaseId: string = await this.userDatabases.doCreateUserDatabase(
      name,
      date
    );

    await this.userPermissions.doAddDatabasePermission(uid, newDatabaseId);

    await this.users.doUpdateUserLastUsedDatabase(uid, newDatabaseId);

    return newDatabaseId;
  }

  async getAllAvailableUserDatabases(
    uid: string
  ): Promise<UserDatabaseSummary[]> {
    const userDatabaseIds: string[] =
      await this.userPermissions.doGetUserPermissableDatabases(uid);
    let databaseSummaries: UserDatabaseSummary[] = [];
    for (const id of userDatabaseIds) {
      const userDatabase: Database = await this.userDatabases.doGetUserDatabase(
        id
      );

      databaseSummaries.push(userDatabase.summary);
    }

    return databaseSummaries;
  }

  async shareDatabase(
    uidToShareTo: string,
    databaseId: string
  ): Promise<{ success: boolean }> {
    const database: Database = await this.userDatabases.doGetUserDatabase(
      databaseId
    );

    const databaseDocumentsData = await this.userDatabases
      .userDatabaseDocument(databaseId)
      .collection("documents")
      .get();

    const documentsToCopy: firestore.DocumentData[] = [];
    const newDocumentSummaries: DocumentSummary[] = [];

    for (const documentData of databaseDocumentsData.docs) {
      const newDocumentId: string = uuidv4();
      const document = documentData.data();

      newDocumentSummaries.push({
        status: document.properties.state,
        templateId: document.templateId,
        documentId: newDocumentId,
        searchableProperties: document.identifier,
        sectionTags: document.sectionTags,
        label: `${document.name}`,
        lastModified: document.date.lastModified,
        documentTagIds: document.documentTags,
      });
      documentsToCopy.push(document);
    }

    const newDatabase: Database = {
      ...database,
      documentSummaries: newDocumentSummaries,
    };

    return this.functions.doCreateSharedDatabase(
      uidToShareTo,
      newDatabase,
      documentsToCopy
    );
  }

  async doGetListOfDocuments(
    isPublic: boolean,
    databaseId: string,
    documentIds: string[]
  ): Promise<[string, Document][]> {
    const databaseDocument = isPublic
      ? this.publicDatabases.publicDatabaseDocument(databaseId)
      : this.userDatabases.userDatabaseDocument(databaseId);

    const documentsQuery = databaseDocument
      .collection("documents")
      .withConverter(documentConverter);

    const toFetch = [];
    let documents: app.firestore.DocumentSnapshot<Document>[] = [];
    for (const documentId of documentIds) {
      let cachedDocument: app.firestore.DocumentSnapshot<any>;
      try {
        cachedDocument = await documentsQuery
          .doc(documentId)
          .get({ source: "cache" });
        documents.push(cachedDocument);
      } catch (e) {
        toFetch.push(documentId);
      }
    }

    if (toFetch.length) {
      const data: app.firestore.QuerySnapshot<Document> = await documentsQuery
        .where(firebase.firestore.FieldPath.documentId(), "in", toFetch)
        .get();
      documents = documents.concat(data.docs);
    }

    return documents.map((snapshot: app.firestore.DocumentSnapshot<Document>): [
      string,
      Document
    ] => [snapshot.id!, snapshot.data()!]);
  }

  async getAllUserDatabases(uid: string): Promise<Database[]> {
    const databaseIds: string[] =
      await this.userPermissions.doGetUserPermissableDatabases(uid);

    const databases: Database[] = [];
    for (const id of databaseIds) {
      const database: Database = await this.userDatabases.doGetUserDatabase(id);
      databases.push(database);
    }

    return databases;
  }
}

export default Firebase;

import { ThunkAction } from "redux-thunk";

import Firebase from "../../../../data/Firebase";
import { FoodItems } from "../../../../data/models/documentProperties/foodItem";
import { Section } from "../../../../data/models/documentProperties/section";
import { RootState } from "../../../reducers";
import { getRetentionFactorMap } from "../../selectors/referenceData";
import { foodItemsPasted } from "../action-creators/foodItems";
import {
  addMultipleSections,
  updateMultipleSections,
  updateSection,
} from "../action-creators/sections";
import { IActionsFoodItemsPasted } from "../actions/foodItems";
import {
  IActionsAddMultipleSections,
  IActionsUpdateMultipleSections,
  IActionsUpdateSection,
} from "../actions/sections";
import { daysSelector } from "../selectors/days";

export const addSectionsFromIndex = (
  sectionsToInsert: Section[],
  dayIndex: number,
  sectionIndex: number
): ThunkAction<
  void,
  RootState,
  Firebase,
  | IActionsUpdateSection
  | IActionsUpdateMultipleSections
  | IActionsAddMultipleSections
  | IActionsFoodItemsPasted
> => async (dispatch, getState) => {
  const onUpdateSection = (section: Section) => {
    dispatch(updateSection(dayIndex, section));
  };

  const retentionFactorMap = getRetentionFactorMap(getState());

  const onAddSections = (sections: Section[]) =>
    dispatch(addMultipleSections(dayIndex, sections));

  const onUpdateSections = (sections: Section[]) =>
    dispatch(updateMultipleSections(dayIndex, sections));

  const onFoodItemsPasted = () => dispatch(foodItemsPasted());

  const sections = daysSelector(getState()).days[dayIndex]!.sections;

  const sectionsLength = sections.length;

  const rowsToInsert = sectionsToInsert.length;

  const sectionsToAdd: Section[] = sectionsToInsert.map(
    (section: Section, idx: number) =>
      new Section(
        section.id,
        sectionsLength + idx,
        new FoodItems([], retentionFactorMap),
        "New section",
        false,
        []
      )
  );

  onAddSections(sectionsToAdd);

  const sortedSections = sections.items.sort(
    (a: Section, b: Section) => a.index - b.index
  );

  for (let i = sortedSections.length - 1; i >= 0; i--) {
    const section = sortedSections[i];

    if (section.index < sectionIndex) break;

    section.index = section.index + rowsToInsert;
    onUpdateSection(section);
  }

  const batchSectionsToInsert: Section[] = [];
  for (let i = 0; i < rowsToInsert; i++) {
    sectionsToInsert[i].index = sectionIndex + i;
    batchSectionsToInsert.push(sectionsToInsert[i]);
  }

  onUpdateSections(batchSectionsToInsert);

  onFoodItemsPasted();
};

import { Size } from "re-resizable";

import {
  IActionsUpdateSelectedAmount,
  IActionsUpdateCustomAmount,
  IActionsTappedCategoryAccordion,
  IActionsSetSelectedSectionTags,
  IActionsSetNutritionPaneDimensions,
} from "../actions/nutritionPaneActions";
import { NutritionRadioOption } from "../reducers/nutritionPaneReducers";

export const updateSelectedAmount = (
  selectedAmount: NutritionRadioOption
): IActionsUpdateSelectedAmount => {
  return {
    type: "UPDATE_SELECTED_AMOUNT",
    selectedAmount: selectedAmount,
  };
};

export const updateCustomAmount = (
  customAmount: number
): IActionsUpdateCustomAmount => {
  return {
    type: "UPDATE_CUSTOM_AMOUNT",
    customAmount: customAmount,
  };
};

export const tappedCategoryAccordion = (
  categoryId: string
): IActionsTappedCategoryAccordion => {
  return {
    type: "TAPPED_CATEGORY_ACCORDION",
    categoryId: categoryId,
  };
};

export const setSelectedSectionTags = (
  tagIds: string[]
): IActionsSetSelectedSectionTags => {
  return {
    type: "SET_SELECTED_SECTION_TAGS",
    tagIds: tagIds,
  };
};

export const setNutritionPaneDimensions = (
  size: Size
): IActionsSetNutritionPaneDimensions => ({
  type: "SET_NUT_PANE_DIMENSIONS",
  size,
});

import { Action } from "redux";

import { CompositionState } from "../../../data/models/composition";
import { Document } from "../../../data/models/document";

export const ADD_COMPOSITION = "ADD_COMPOSITION";
export const UPDATE_COMPOSITION = "UPDATE_COMPOSITION";
export const FETCH_COMPOSITION = "FETCH_COMPOSITION";

export interface IActionsAddComposition extends Action {
  type: "ADD_COMPOSITION";
  identifier: string;
  composition: CompositionState;
}

export interface IActionsUpdateComposition extends Action {
  type: "UPDATE_COMPOSITION";
  identifier: string;
  composition: CompositionState;
}

export interface IActionsFetchComposition extends Action {
  type: "FETCH_COMPOSITION";
  identifier: string;
  document: Document;
}

export type CompositionCacheActions =
  | IActionsAddComposition
  | IActionsUpdateComposition;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

import { DatabaseMenu } from "./DatabaseMenu";
import { RootState } from "../../../../store/reducers";
import { currentRouteSelector } from "../../../../store/ui/selectors/routing";
import { appTheme } from "../../../../styling/style";
import { RouteData } from "../../../../data/routing/types";

const useStyles = makeStyles(() => ({
  buttonStyle: { textTransform: "none" },
  dropdownButton: { marginLeft: 10, marginTop: 7 },
  highlighted: {
    backgroundColor: appTheme.colors.oceanBlue[0],
  },
}));

const DatabasesButton = (): JSX.Element => {
  const classes = useStyles();

  const currentRoute: RouteData | undefined =
    useSelector<RootState, RouteData | undefined>(currentRouteSelector);

  const [dbAnchor, setDbAnchor] = useState<null | HTMLElement>(null);

  const onDatabasesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDbAnchor(event.currentTarget);
  };

  return (
    <>
      <Button
        className={
          currentRoute?.screen === "databases"
            ? `${classes.dropdownButton} ${classes.highlighted}`
            : classes.dropdownButton
        }
        data-cy="databaseMenuButton"
        variant="text"
        color="secondary"
        size="small"
        endIcon={<ArrowDropDown />}
        onClick={onDatabasesClick}
      >
        <Typography variant="h3" className={classes.buttonStyle}>
          Databases
        </Typography>
      </Button>
      <DatabaseMenu
        onClose={() => setDbAnchor(null)}
        anchorElement={dbAnchor}
      />
    </>
  );
};

export default DatabasesButton;

import React, { useState } from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import FoodworksDatePicker from "../../../../../common/date/FoodworksDatePicker";
import { BaseDialog } from "../../../../../dialogs/BaseDialog";
import { convertMealPlanToFoodRecord } from "../../../../../../store/data/current-document/thunks/document";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    marginRight: 10,
  },
}));

interface Props {
  onClose: () => void;
  mealPlanDocumentId: string;
}

const ConvertPlanToRecordDialog = (props: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onConvertPlanToRecord = () =>
    dispatch(convertMealPlanToFoodRecord(mealPlanDocumentId, date!));

  const { onClose, mealPlanDocumentId } = { ...props };

  const [date, setDate] = useState<Date | null>(new Date());

  return (
    <BaseDialog
      open
      body={
        <div className={classes.root}>
          <Typography className={classes.label}>Start date</Typography>
          <FoodworksDatePicker
            date={date}
            handleChange={(input) => setDate(input)}
          />
        </div>
      }
      onClose={onClose}
      title="Select the starting date for your converted food record"
      maxWidth="sm"
      action={[
        <Button onClick={onClose}>Cancel</Button>,
        <Button
          onClick={() => {
            onConvertPlanToRecord();
            onClose();
          }}
          color="secondary"
        >
          Ok
        </Button>,
      ]}
    />
  );
};

export default ConvertPlanToRecordDialog;

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tag } from "../../../../data/models/documentProperties/section";
import { updateClientDatabaseTags } from "../../../../store/data/thunks/clientDatabase";
import { clientDatabaseTagsSelector } from "../../../../store/data/selectors/clientDatabase";
import { RootState } from "../../../../store/reducers";
import { CreateTagDialog } from "../common/CreateTagDialog";

interface CreateClientTagDialogProps {
  open: boolean;
  onClose: () => void;
  isEditing: boolean;
  tagId?: string;
}

export const CreateClientTagDialog = ({
  open,
  onClose,
  isEditing,
  tagId,
}: CreateClientTagDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const onCreateTag = (tag: Tag) => {
    dispatch(updateClientDatabaseTags([...clientTags, tag]));
  };

  const onUpdateTag = (updatedTag: Tag) => {
    const updatedTags: Tag[] = clientTags.map((tag: Tag) =>
      tag.id === updatedTag.id ? updatedTag : tag
    );
    dispatch(updateClientDatabaseTags(updatedTags));
  };

  const clientTags: Tag[] = useSelector<RootState, Tag[]>(
    clientDatabaseTagsSelector
  );

  return (
    <CreateTagDialog
      open={open}
      onClose={onClose}
      isEditing={isEditing}
      tagId={tagId}
      onCreateTag={onCreateTag}
      onUpdateTag={onUpdateTag}
      tagSelector={clientTags}
      label="client"
    />
  );
};

import React from "react";
import { Typography } from "@material-ui/core";

import useClientBMI from "../hooks/useClientBMI";
import ProfileItem from "./ProfileItem";

const BMIDisplay = React.memo((): JSX.Element => {
  const bmi: number | undefined = useClientBMI();

  return (
    <ProfileItem
      label="BMI"
      tooltip="Calculation based on current weight and height"
      component={
        <Typography color="secondary">{bmi ? bmi.toFixed(1) : "?"}</Typography>
      }
    />
  );
});

export default BMIDisplay;

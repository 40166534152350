import { ThunkAction } from "redux-thunk";

import { FoodTemplate } from "../../../../../constants/FoodTemplate";
import { DocumentSummary } from "../../../../../data/models/userDatabase";
import { RootState } from "../../../../reducers";
import { ExtraArguments } from "../../../../store";
import {
  addDocument,
  updateCachedDocument,
} from "../../../action-creators/documentCache";
import {
  IActionsAddDocument,
  IActionsUpdateCachedDocument,
} from "../../../actions/documentCache";
import {
  currentDatabaseIsClientDatabaseSelector,
  userDocumentSummariesSelector,
} from "../../../selectors/database";
import { allCachedDocumentsSelector } from "../../../selectors/documentCache";
import { TEMPORARY_NEW_DOCUMENT } from "../../reducers/currentDocument";
import { changeCurrentDocument } from "../currentDocument";
import {
  Document,
  DocumentTemplateId,
} from "../../../../../data/models/document";
import { currentClientSelector } from "../../../selectors/clientDatabase";
import { Client } from "../../../../../data/models/client";
import { generateNewDocument } from "./createNewDocumentHelpers";

const createNewDocument =
  (
    template: FoodTemplate
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddDocument | IActionsUpdateCachedDocument
  > =>
  async (dispatch, getState) => {
    const documentCache = allCachedDocumentsSelector(getState());

    const userDocumentSummaries: DocumentSummary[] =
      userDocumentSummariesSelector(getState());

    const currentDatabaseIsClientDatabase: boolean =
      currentDatabaseIsClientDatabaseSelector(getState());

    const currentClient: Client = currentClientSelector(getState());

    const newDocument: Document = generateNewDocument(
      template.id.toString() as DocumentTemplateId,
      template.title,
      userDocumentSummaries,
      currentDatabaseIsClientDatabase,
      currentClient
    );

    const cachedDocument: Document | undefined =
      documentCache[TEMPORARY_NEW_DOCUMENT];

    cachedDocument
      ? dispatch(updateCachedDocument(TEMPORARY_NEW_DOCUMENT, newDocument))
      : dispatch(addDocument(newDocument, TEMPORARY_NEW_DOCUMENT));

    return dispatch(changeCurrentDocument(TEMPORARY_NEW_DOCUMENT));
  };

export default createNewDocument;

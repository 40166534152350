import React from "react";

import { Sex } from "../../../../../../../data/models/nutrientReferenceValues";
import useClientSex from "../hooks/useClientSex";
import FoodworksSelect from "../../../../../../common/FoodworksSelect";
import ProfileItem from "./ProfileItem";

const SexInput = React.memo(
  (): JSX.Element => {
    const [sex, updateSex] = useClientSex();

    return (
      <ProfileItem
        component={
          <FoodworksSelect<Sex>
            value={sex}
            onChange={updateSex}
            options={["Male", "Female"]}
          />
        }
        label="Sex"
      />
    );
  }
);

export default SexInput;

export interface RetentionFactor {
  profileId: string;
  groupId: string;
  description: string;
}

export interface RetentionFactorProfile {
  id: string;
  process: string;
  description: string;
}

export interface NutrientRetentionValue {
  id: string;
  factor: number;
  note: string;
}

export const retentionFactorProfileConverter = {
  fromFirestore: function (
    data: firebase.firestore.DocumentData
  ): RetentionFactorProfile {
    return {
      id: data.id,
      process: data.process,
      description: data.description,
    };
  },

  toFirestore: function (
    retentionFactorProfile: RetentionFactorProfile
  ): firebase.firestore.DocumentData {
    return {
      id: retentionFactorProfile.id,
      process: retentionFactorProfile.process,
      description: retentionFactorProfile.description,
    };
  },
};

export const retentionFactorProfilesConverter = {
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): RetentionFactorProfile[] {
    const profilesData: firebase.firestore.DocumentData[] = snapshot.data()
      .profiles;
    return profilesData.map(
      (profileData: firebase.firestore.DocumentData): RetentionFactorProfile =>
        retentionFactorProfileConverter.fromFirestore(profileData)
    );
  },

  toFirestore: function (
    retentionFactorProfiles: RetentionFactorProfile[]
  ): firebase.firestore.DocumentData {
    return retentionFactorProfiles.map(
      (profile: RetentionFactorProfile): firebase.firestore.DocumentData =>
        retentionFactorProfileConverter.toFirestore(profile)
    );
  },
};

export const nutrientRetentionValuesConverter = {
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): NutrientRetentionValue[] {
    const data: firebase.firestore.DocumentData = snapshot.data();

    return data.values.map(
      (
        nutrientRetentionValue: firebase.firestore.DocumentData
      ): NutrientRetentionValue => {
        return {
          id: nutrientRetentionValue.id,
          factor: nutrientRetentionValue.factor,
          note: nutrientRetentionValue.note,
        };
      }
    );
  },
  toFirestore: function (
    nutrientRetentionValues: NutrientRetentionValue[]
  ): firebase.firestore.DocumentData {
    return nutrientRetentionValues.map(
      (
        nutrientRetentionValue: NutrientRetentionValue
      ): firebase.firestore.DocumentData => {
        return {
          id: nutrientRetentionValue.id,
          factor: nutrientRetentionValue.factor,
          note: nutrientRetentionValue.note,
        };
      }
    );
  },
};

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Days } from "../../../../data/models/documentProperties/day";
import { daysSelector } from "../../../../store/data/current-document/selectors/days";
import { RootState } from "../../../../store/reducers";

const getBoundingDates = (days: Days): [Date, Date] => {
  const startDate: string = days.days[0].date;
  const endDate: string = days.days[days.days.length - 1].date;

  return [
    startDate ? new Date(startDate) : new Date(),
    endDate ? new Date(endDate) : startDate ? new Date(startDate) : new Date(),
  ];
};

interface DocumentDatesState {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
}

const useDocumentDates = (): DocumentDatesState => {
  const days: Days = useSelector<RootState, Days>(daysSelector);

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  useEffect(() => {
    const [start, end] = getBoundingDates(days);
    setStartDate(start);
    setEndDate(end);
  }, [days]);

  return { startDate, endDate, setStartDate, setEndDate };
};

export default useDocumentDates;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/reducers";
import { currentRouteSelector } from "../../../store/ui/selectors/routing";
import { FoodScreen } from "../databases/documents/DocumentScreen";
import { usePublicSummaries } from "../databases/OpenDatabase";
import { withFirebase } from "../../../data/Firebase";
import { FirebaseProps } from "../../../data/Firebase/firebase";
import { NutritionPane } from "../databases/nutrition_pane/NutritionPane";
import { TabbedPane, TabbedPaneTab } from "../../common/TabbedPane";
import GeneralTab from "./tabs/general/GeneralTab";
import DocumentsTab from "./tabs/documents/DocumentsTab";
import NRVTab from "./tabs/NRVTab";
import TrackingTab from "./tabs/tracking/TrackingTab";
import AnalysisTab from "./tabs/analysis/AnalysisTab";
import ClientTitleBar from "./title_bar/ClientTitleBar";
import FoodworksBreadcrumb from "../../common/FoodworksBreadcrumb";
import { selectedClientDocumentsSelector } from "../../../store/ui/selectors/clientScreen";
import { RouteData } from "../../../data/routing/types";
import useNutritionPaneWidth from "../databases/nutrition_pane/hooks/useNutritionPaneWidth";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
  clientRoot: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: 5,
    marginBottom: 0,
    width: "100%",
    overflow: "auto",
  },
  title: {
    marginBottom: 25,
    marginTop: 10,
    marginLeft: 5,
  },
  breadcrumbContainer: {
    margin: 10,
  },
}));

const CLIENT_TABS: TabbedPaneTab[] = [
  { title: "General", content: <GeneralTab /> },
  { title: "Documents", content: <DocumentsTab /> },
  { title: "NRVs / Goals", content: <NRVTab /> },
  { title: "Tracking", content: <TrackingTab /> },
  { title: "Graphs", content: <AnalysisTab /> },
];

const ClientScreenInner = ({ firebase }: FirebaseProps): JSX.Element => {
  const classes = useStyles();

  const currentRoute: RouteData | undefined = useSelector<
    RootState,
    RouteData | undefined
  >(currentRouteSelector);

  const [currentTab, setCurrentTab] = useState(0);

  const clientSelectedDocuments: string[] = useSelector<RootState, string[]>(
    selectedClientDocumentsSelector
  );

  const nutritionPaneWidth: number = useNutritionPaneWidth();

  const publicSummaries = usePublicSummaries(firebase!);

  return (
    <>
      {!currentRoute?.documentId && (
        <>
          <div className={classes.clientRoot}>
            <div className={classes.breadcrumbContainer}>
              <FoodworksBreadcrumb />
            </div>
            <div style={{ width: `calc(99% - ${nutritionPaneWidth + 20}px)` }}>
              <ClientTitleBar />
            </div>
            <TabbedPane
              tabs={CLIENT_TABS}
              currentTab={currentTab}
              onChangeTab={setCurrentTab}
            />
          </div>
          {currentTab === 1 && !!clientSelectedDocuments.length && (
            <NutritionPane />
          )}
        </>
      )}
      {currentRoute?.documentId && (
        <>
          <div className={classes.root}>
            <FoodScreen publicSummaries={publicSummaries} screenType="Client" />
          </div>
          <NutritionPane />
        </>
      )}
    </>
  );
};

const ClientScreen = withFirebase(ClientScreenInner);

export default ClientScreen;

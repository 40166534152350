import React, { FunctionComponent, ReactNode, useState } from "react";

import {
  makeStyles,
  IconButton,
  Typography,
  Collapse,
  List,
  ListItem,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  Edit,
  FiberManualRecord,
} from "@material-ui/icons";
import { useSelector } from "react-redux";

import { ReferenceMeasure } from "../../../../data/models/referenceMeasure";
import { enabledReferenceMeasuresDataSelector } from "../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import { ReferenceMeasureDialog } from "../../../dialogs/database/ReferenceMeasureDialog";

const useStyles = makeStyles(() => ({
  listRoot: { overflow: "auto", maxHeight: 200 },
  listItemRoot: {
    display: "flex",
  },
  bulletPoint: {
    color: appTheme.colors.xiketic,
    marginRight: 2,
    width: 5,
    height: 5,
  },
  nutrientListPart: {
    display: "flex",
    alignItems: "center",
  },
  headerRoot: {
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    textDecoration: "underline",
  },
  editIcon: {
    color: appTheme.colors.secondary,
    width: 20,
    marginLeft: 5,
  },
}));

interface EnabledMeasuresListProps {
  openDialog: boolean;
  onToggleDialog: () => void;
}

export const EnabledMeasuresList: FunctionComponent<EnabledMeasuresListProps> = ({
  openDialog,
  onToggleDialog,
}) => {
  const classes = useStyles();

  const enabledReferenceMeasures: ReferenceMeasure[] = useSelector<
    RootState,
    ReferenceMeasure[]
  >(enabledReferenceMeasuresDataSelector);

  const [showList, setShowList] = useState(false);

  const generatePairedList = (): [
    ReferenceMeasure,
    ReferenceMeasure | undefined
  ][] => {
    const pairedReferenceMeasures: [
      ReferenceMeasure,
      ReferenceMeasure | undefined
    ][] = [];
    for (let i = 0; i < Math.ceil(enabledReferenceMeasures.length / 2); ++i) {
      let measureA, measureB;
      measureA = enabledReferenceMeasures[i * 2];
      if (i * 2 + 1 < enabledReferenceMeasures.length) {
        measureB = enabledReferenceMeasures[i * 2 + 1];
      }

      pairedReferenceMeasures.push([measureA, measureB]);
    }
    return pairedReferenceMeasures;
  };

  const enabledMeasuresHeader: ReactNode = (
    <div className={classes.headerRoot}>
      <IconButton
        size="small"
        color="secondary"
        onClick={() => setShowList((prev) => !prev)}
      >
        {showList ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <Typography className={classes.headerText} variant="h4">
        Reference measures
      </Typography>
      <IconButton
        size="small"
        data-cy="referenceMeasureButton"
        onClick={onToggleDialog}
      >
        <Edit className={classes.editIcon} />
      </IconButton>
      {openDialog && <ReferenceMeasureDialog open onClose={onToggleDialog} />}
    </div>
  );

  const createListItem = (label: string, secondItem: boolean): ReactNode => (
    <div className={classes.nutrientListPart}>
      <FiberManualRecord
        className={classes.bulletPoint}
        style={{ marginLeft: secondItem ? 20 : 0 }}
      />
      <Typography>{label}</Typography>
    </div>
  );

  return (
    <div>
      {enabledMeasuresHeader}
      <Collapse in={showList}>
        <List className={classes.listRoot}>
          {generatePairedList().map(
            ([measureA, measureB]: [
              ReferenceMeasure,
              ReferenceMeasure | undefined
            ]): ReactNode => (
              <ListItem
                key={`enabledMeasuresList-${measureA.id}`}
                className={classes.listItemRoot}
              >
                {createListItem(measureA.name, false)}
                {measureB && createListItem(measureB.name, true)}
              </ListItem>
            )
          )}
        </List>
      </Collapse>
    </div>
  );
};

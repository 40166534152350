import {
  ADD_CLIENT_GOAL,
  IActionsAddClientGoal,
  IActionsAddDocumentId,
  IActionsRemoveClientGoal,
  IActionsSetClientAddress,
  IActionsSetClientEmail,
  IActionsSetClientMobile,
  IActionsSetClientNotes,
  IActionsSetClientTags,
  IActionsToggleClientState,
  IActionsUpdateClientGoal,
  IActionsUpdateClientName,
  REMOVE_CLIENT_GOAL,
  UPDATE_CLIENT_GOAL,
} from "../actions/client";
import { ClientGoal } from "../../../../data/models/clientProperties/clientGoal";

export const updateClientName = (name: string): IActionsUpdateClientName => ({
  type: "UPDATE_CLIENT_NAME",
  name: name,
});

export const addDocumentId = (id: string): IActionsAddDocumentId => ({
  type: "ADD_DOCUMENT_ID",
  id: id,
});

export const setClientMobile = (mobile: string): IActionsSetClientMobile => ({
  type: "SET_CLIENT_MOBILE",
  mobile,
});

export const setClientAddress = (
  address: string
): IActionsSetClientAddress => ({
  type: "SET_CLIENT_ADDRESS",
  address,
});

export const setClientEmail = (email: string): IActionsSetClientEmail => ({
  type: "SET_CLIENT_EMAIL",
  email,
});

export const setClientNotes = (notes: string): IActionsSetClientNotes => ({
  type: "SET_CLIENT_NOTES",
  notes,
});

export const setClientTags = (tagIds: string[]): IActionsSetClientTags => ({
  type: "SET_CLIENT_TAGS",
  tagIds,
});

export const toggleClientState = (): IActionsToggleClientState => ({
  type: "TOGGLE_CLIENT_STATE",
});

export const addClientGoal = (goal: ClientGoal): IActionsAddClientGoal => ({
  type: ADD_CLIENT_GOAL,
  goal: goal,
});

export const removeClientGoal = (
  goal: ClientGoal
): IActionsRemoveClientGoal => ({
  type: REMOVE_CLIENT_GOAL,
  goal: goal,
});

export const updateClientGoal = (
  goal: ClientGoal
): IActionsUpdateClientGoal => ({
  type: UPDATE_CLIENT_GOAL,
  goal: goal,
});

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Firebase from "../../../../../../../data/Firebase";
import { ClientProfile } from "../../../../../../../data/models/clientProperties/clientProfile";
import { calculateBMR } from "../../../../../../../data/models/nrvCalculator";
import { clientProfileSelector } from "../../../../../../../store/data/current_client/selectors/client";
import { RootState } from "../../../../../../../store/reducers";

const useClientBMR = (firebase: Firebase): string => {
  const profile: ClientProfile = useSelector<RootState, ClientProfile>(
    clientProfileSelector
  );

  const [bmr, setBmr] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!profile.weight || !profile.age) {
      setBmr(undefined);
      return;
    }

    firebase.referenceData
      .doFetchEnergyReferenceValue(profile.sex, profile.age)
      .then((erv) => setBmr(calculateBMR(erv, profile.weight!)));
  }, [profile, firebase]);

  return bmr ? `${bmr.toFixed(0)} kJ` : "?";
};

export default useClientBMR;

import { Size } from "re-resizable";
import { Action } from "redux";

import { NutritionRadioOption } from "../reducers/nutritionPaneReducers";

export const UPDATE_SELECTED_AMOUNT = "UPDATE_SELECTED_AMOUNT";
export const UPDATE_CUSTOM_AMOUNT = "UPDATE_CUSTOM_AMOUNT";
export const TAPPED_CATEGORY_ACCORDION = "TAPPED_CATEGORY_ACCORDION";
export const SET_SELECTED_SECTION_TAGS = "SET_SELECTED_SECTION_TAGS";
export const SET_NUT_PANE_DIMENSIONS = "SET_NUT_PANE_DIMENSIONS";

export interface IActionsUpdateSelectedAmount extends Action {
  type: "UPDATE_SELECTED_AMOUNT";
  selectedAmount: NutritionRadioOption;
}

export interface IActionsUpdateCustomAmount extends Action {
  type: "UPDATE_CUSTOM_AMOUNT";
  customAmount: number;
}

export interface IActionsTappedCategoryAccordion extends Action {
  type: "TAPPED_CATEGORY_ACCORDION";
  categoryId: string;
}

export interface IActionsSetSelectedSectionTags extends Action {
  type: "SET_SELECTED_SECTION_TAGS";
  tagIds: string[];
}
export interface IActionsSetNutritionPaneDimensions extends Action {
  type: "SET_NUT_PANE_DIMENSIONS";
  size: Size;
}

export type NutritionPaneActions =
  | IActionsUpdateSelectedAmount
  | IActionsUpdateCustomAmount
  | IActionsTappedCategoryAccordion
  | IActionsSetSelectedSectionTags
  | IActionsSetNutritionPaneDimensions;

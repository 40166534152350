export interface FoodItemPositionState {
  day: number;
  section: number;
  row: number;
}

export class FoodItemPosition {
  day: number;
  section: number;
  row: number;

  constructor(day: number, section: number, row: number) {
    this.day = day;
    this.section = section;
    this.row = row;
  }

  isEqual = (other: FoodItemPosition) =>
    this.day === other.day &&
    this.section === other.section &&
    this.row === other.row;

  static fromObject(foodItemPositionState: FoodItemPositionState) {
    return new FoodItemPosition(
      foodItemPositionState.day,
      foodItemPositionState.section,
      foodItemPositionState.row
    );
  }

  get object(): FoodItemPositionState {
    return { day: this.day, section: this.section, row: this.row };
  }
}

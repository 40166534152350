import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

export interface TableRows {
  label: string;
  color: string;
  angle: number;
}

const getHeadings = (headings: string[]): JSX.Element[] =>
  headings.map((heading) => (
    <TableCell key={`${heading} TableCell`}>{heading}</TableCell>
  ));
const getDataRows = (rows: TableRows[], total: number): JSX.Element[] => {
  return rows.map((row) => {
    return (
      <TableRow
        key={`${row.label} TableCell`}
        style={{ backgroundColor: row.color }}
      >
        <TableCell>
          <Typography variant="h3">{row.label}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="h3">{row.angle}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="h3">
            {((row.angle / total) * 100).toFixed(2) + "%"}
          </Typography>
        </TableCell>
      </TableRow>
    );
  });
};

interface PFCATableProps {
  headings: string[];
  rows: TableRows[];
  total: number;
}

export default function PFCATable(props: PFCATableProps): JSX.Element {
  return (
    <TableContainer>
      <Table aria-label="PFCA TABLE">
        <TableHead>
          <TableRow>{getHeadings(props.headings)}</TableRow>
        </TableHead>
        <TableBody>{getDataRows(props.rows, props.total)}</TableBody>
      </Table>
    </TableContainer>
  );
}

import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { makeStyles } from "@material-ui/core";

import FoodworksAppBar from "./appBar/FoodworksAppBar";
import Firebase, { withFirebase } from "../../data/Firebase";
import { RootState } from "../../store/reducers";
import { ErrorPopup } from "../common/ErrorPopup";
import { SIGN_UP } from "../../constants/routes";
import SaveDocumentPrompt from "./components/SaveDocumentPrompt";
import { LoadingBar } from "./LoadingBar";
import { SuccessAlert } from "../common/SuccessAlert";
import { Databases } from "./databases/Databases";
import { currentRouteSelector } from "../../store/ui/selectors/routing";
import Clients from "./clients/Clients";
import SaveClientPrompt from "./components/SaveClientPrompt";
import useInitialiseUser from "./hooks/useInitialiseUser";
import useFoodworksError from "./hooks/useFoodworksError";
import { RouteData } from "../../data/routing/types";

const useStyles = makeStyles(() => ({
  root: { display: "flex", flex: 1, flexDirection: "column", width: "100%" },
  content: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  navPane: {
    display: "flex",
  },
  documentScreen: {
    display: "flex",
    flex: 1,
    overflow: "auto",
  },
  loadingBar: {
    height: LOADING_BAR_HEIGHT,
  },
}));

export const LOADING_BAR_HEIGHT = 3;

export interface HomeProps {
  firebase?: Firebase;
}

const HomeInner: FunctionComponent<HomeProps> = ({ firebase }) => {
  const classes = useStyles();
  const [user, loading] = useAuthState(firebase!.auth);

  const currentRoute: RouteData | undefined =
    useSelector<RootState, RouteData | undefined>(currentRouteSelector);

  useInitialiseUser(user);

  const { showAlert, setShowAlert, currentError } = useFoodworksError();

  if (loading) return null;
  if (!user) return <Redirect to={SIGN_UP} />;

  const handleErrorSnackbarClose = (reason: string) => {
    if (reason === "timeout") {
      setShowAlert(false);
    }
  };

  const handleErrorAlertClose = () => {
    setShowAlert(false);
  };

  const loadCurrentScreen = () => {
    if (currentRoute?.screen === "databases") {
      return <Databases />;
    }

    if (currentRoute?.screen === "clients") {
      return <Clients />;
    }
  };

  return (
    <div className={classes.root}>
      <FoodworksAppBar />
      <LoadingBar />
      {loadCurrentScreen()}
      <SaveDocumentPrompt />
      <SaveClientPrompt />
      <ErrorPopup
        showError={showAlert}
        onAlertClose={handleErrorAlertClose}
        onSnackBarClose={handleErrorSnackbarClose}
        error={currentError}
      />
      <SuccessAlert />
    </div>
  );
};

export const Home = withFirebase(HomeInner);

import { RouteData } from "../../../data/routing/types";
import {
  IActionsSetCurrentRoute,
  IActionsSetNextRoute,
} from "../actions/routing";

export const setNextRoute = (
  route: RouteData | undefined
): IActionsSetNextRoute => {
  return {
    type: "SET_NEXT_ROUTE",
    route: route,
  };
};

export const setCurrentRoute = (route: RouteData): IActionsSetCurrentRoute => {
  return {
    type: "SET_CURRENT_ROUTE",
    route: route,
  };
};

import { Day } from "../../../data/models/documentProperties/day";
import {
  IActionsAddDocumentPast,
  IActionsRedoDocument,
  IActionsRemoveDuplicateHistory,
  IActionsSetDisableSaveButton,
  IActionsUndoDocument,
} from "../actions/documentEditing";

export const setDisableSaveButton = (
  disable: boolean
): IActionsSetDisableSaveButton => {
  return {
    type: "SET_DISABLE_SAVE_BUTTON",
    disable: disable,
  };
};

export const undoDocument = (): IActionsUndoDocument => ({
  type: "UNDO_DOCUMENT",
});

export const redoDocument = (): IActionsRedoDocument => ({
  type: "REDO_DOCUMENT",
});

export const addDocumentPast = (
  action: Day[],
  reset?: boolean
): IActionsAddDocumentPast => {
  return {
    type: "ADD_DOCUMENT_PAST",
    currentAction: action,
    reset: reset || false,
  };
};

export const removeDuplicateHistory = (): IActionsRemoveDuplicateHistory => {
  return {
    type: "REMOVE_DUPLICATE_HISTORY",
  };
};

import React from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import { FoodTemplate } from "../../constants/FoodTemplate";
import { appTheme } from "../../styling/style";

const useStyles = makeStyles(() => ({
  icon: {
    fill: appTheme.colors.primary,
    width: 24,
    height: 24,
  },
  skinnyAvatar: {
    minWidth: 40,
    display: "flex",
    alignItems: "center",
  },
}));

interface DocumentMenuItemProps {
  template: FoodTemplate;
  onClick: (template: FoodTemplate) => void;
}

const DocumentMenuItem = ({
  template,
  onClick,
}: DocumentMenuItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <ListItem
      data-cy="createMenuItem"
      button
      onClick={() => onClick(template)}
      key={template.id}
    >
      <ListItemAvatar className={classes.skinnyAvatar}>
        <template.icon className={classes.icon} />
      </ListItemAvatar>
      <ListItemText
        primary={`Create ${template.title.slice(0, -1).toLowerCase()}`}
        primaryTypographyProps={{ variant: "body1" }}
      />
    </ListItem>
  );
};

export default DocumentMenuItem;

import React from "react";
import { makeStyles, Theme } from "@material-ui/core";

import { MeasurementCategory } from "../../../../../../data/models/clientProperties/measurement";
import { FoodWorksTextInput } from "../../../../../common/FoodWorksTextInput";
import { MEDIUM_FIELD } from "../../../../../../constants/textInputs";

const useStyles = makeStyles((theme: Theme) => ({
  searchBar: {
    padding: "2px 0px",
    margin: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  input: {
    flex: 1,
  },
}));

interface MeasurementsSearchProps {
  fetchSearchText: (text: string) => void;
  searchText: string;
  category: MeasurementCategory;
}

const MeasurementsSearch = ({
  fetchSearchText,
  searchText,
  category,
}: MeasurementsSearchProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.searchBar}>
      <FoodWorksTextInput
        maxLength={MEDIUM_FIELD}
        value={searchText}
        onChange={(event) => fetchSearchText(event.target.value)}
        className={classes.input}
        placeholder={`Search ${category.toLowerCase()} measurements...`}
        type="text"
        data-cy="navSearchInput"
      />
    </div>
  );
};

export default MeasurementsSearch;

import React, { FunctionComponent, ReactNode, useState } from "react";

import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { SectionCheckbox } from "./SectionCheckbox";
import { Section } from "../../../../../../../../data/models/documentProperties/section";
import {
  AddSectionTagButton,
  CopySectionButton,
  DeleteSectionButton,
  HideSectionButton,
  SectionMenuButton,
  ShowSectionButton,
} from "./SectionHeaderButtons";
import { SectionTitle } from "./SectionTitle";
import { FoodItemPosition } from "../../../../../../../../data/models/foodItemPosition";
import { appTheme } from "../../../../../../../../styling/style";
import { SectionTags } from "./SectionTags";
import { RootState } from "../../../../../../../../store/reducers";
import {
  displayNutrientValuesSelector,
  lockNutrientColumnsSelector,
} from "../../../../../../../../store/ui/selectors/recipeGrid";
import { displayedNutrientsSelector } from "../../../../../../../../store/data/selectors/databaseProperties";
import { NutrientsDataSelector } from "../../../../../../../../store/data/selectors/referenceData";
import { Nutrient } from "../../../../../../../../data/models/nutrient";
import { FoodworksTooltip } from "../../../../../../../common/InfoTooltip";
import { CreateSectionTagDialog } from "../../../../../../../dialogs/section_tags/CreateSectionTagDialog";

const useStyles = makeStyles(() =>
  createStyles({
    sectionHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    leftButtonGroup: {
      display: "flex",
      alignItems: "center",
    },
    addIcon: {
      color: appTheme.colors.success,
      padding: 0,
    },
    nutrientColumn: {
      width: 70,
      paddingLeft: 1,
      paddingRight: 1,
      fontSize: 14,
    },
    rightGroup: {
      display: "flex",
      flex: 1,
      alignItems: "center",
    },
    buttonPlaceholder: {
      width: 40,
    },
  })
);

interface SectionHeaderProps {
  dayIndex: number;
  section: Section;
  sectionIsCollapsed: boolean;
  selectedRows: FoodItemPosition[];
}

export const SectionHeader: FunctionComponent<SectionHeaderProps> = ({
  dayIndex,
  section,
  sectionIsCollapsed,
  selectedRows,
}) => {
  const classes = useStyles();

  const displayNutrientValues: boolean = useSelector<RootState, boolean>(
    displayNutrientValuesSelector
  );

  const lockNutrientColumns: boolean = useSelector<RootState, boolean>(
    lockNutrientColumnsSelector
  );

  const displayedNutrients: string[] = useSelector<RootState, string[]>(
    displayedNutrientsSelector
  );

  const nutrientData: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(NutrientsDataSelector);

  const [rowHovered, setRowHovered] = useState(false);
  const [showCreateTagDialog, setShowCreateTagDialog] = useState(false);

  const onMouseEnterHeader = () => setRowHovered(true);

  const onMouseLeaveHeader = () => setRowHovered(false);

  const collapseSectionButton = (): ReactNode =>
    sectionIsCollapsed ? (
      <ShowSectionButton dayIndex={dayIndex} sectionIndex={section.index} />
    ) : (
      <HideSectionButton dayIndex={dayIndex} sectionIndex={section.index} />
    );

  const sectionHeaderButtons: ReactNode[] = [];

  const createOnHoverButtons = () => {
    if (rowHovered) {
      sectionHeaderButtons.push(
        <AddSectionTagButton
          key="tag"
          dayIndex={dayIndex}
          section={section}
          onClickCreateTag={() => setShowCreateTagDialog(true)}
        />
      );
      sectionHeaderButtons.push(
        <CopySectionButton key="copy" section={section} />
      );
      sectionHeaderButtons.push(
        <DeleteSectionButton
          key="delete"
          dayIndex={dayIndex}
          sectionIndex={section.index}
          sectionItemsLength={section.foodItems.length}
        />
      );
      sectionHeaderButtons.push(
        <SectionMenuButton
          key="menu"
          dayIndex={dayIndex}
          section={section}
          onClickCreateTag={() => setShowCreateTagDialog(true)}
        />
      );
    }
  };

  const createNutrientColumns = () => {
    if (!lockNutrientColumns && rowHovered) {
      createOnHoverButtons();
    } else {
      sectionHeaderButtons.push(
        displayedNutrients.map(
          (nutrientId: string): ReactNode => (
            <FoodworksTooltip
              key={`${nutrientId}-column-tooltip`}
              title={
                `${nutrientData.get(nutrientId)?.name} (${
                  nutrientData.get(nutrientId)?.units
                })` || ""
              }
            >
              <Typography
                key={`${nutrientId}-column-text`}
                align="center"
                className={classes.nutrientColumn}
                noWrap
                variant="h4"
              >
                {nutrientData.get(nutrientId)?.name || ""}
              </Typography>
            </FoodworksTooltip>
          )
        )
      );

      if (lockNutrientColumns) {
        if (rowHovered) {
          sectionHeaderButtons.push(
            <SectionMenuButton
              key="menu"
              dayIndex={dayIndex}
              section={section}
              onClickCreateTag={() => setShowCreateTagDialog(true)}
            />
          );
        } else {
          sectionHeaderButtons.push(
            <div key="placeholder" className={classes.buttonPlaceholder}></div>
          );
        }
      }
    }
  };

  displayNutrientValues ? createNutrientColumns() : createOnHoverButtons();

  return (
    <div
      className={classes.sectionHeader}
      onMouseEnter={onMouseEnterHeader}
      onMouseLeave={onMouseLeaveHeader}
    >
      <div className={classes.leftButtonGroup}>
        <SectionCheckbox
          dayIndex={dayIndex}
          section={section}
          selectedRows={selectedRows}
        />
        {collapseSectionButton()}
        <SectionTitle
          dayIndex={dayIndex}
          sectionIndex={section.index}
          title={section.title}
        />
        <SectionTags
          dayIndex={dayIndex}
          sectionIndex={section.index}
          sectionTagIds={section.tags}
        />
      </div>
      <div className={classes.leftButtonGroup}> {sectionHeaderButtons}</div>
      {showCreateTagDialog && (
        <CreateSectionTagDialog
          sectionIndex={section.index}
          dayIndex={dayIndex}
          onClose={() => setShowCreateTagDialog(false)}
        />
      )}
    </div>
  );
};

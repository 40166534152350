import React, { useState } from "react";
import { Card, IconButton, makeStyles, Typography } from "@material-ui/core";
import { PostAdd } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import * as uuidv4 from "uuid";
import { isAfter, isBefore, isValid } from "date-fns";

import { appTheme } from "../../../../../../styling/style";
import {
  MeasurementCategory,
  MeasurementEntry,
} from "../../../../../../data/models/clientProperties/measurement";
import {
  FoodworksTooltip,
  TextOverflowTooltip,
} from "../../../../../common/InfoTooltip";
import PreviousEntriesButton from "./components/PreviousEntriesButton";
import EditMeasurementPropertiesButton from "./components/EditMeasurementPropertiesButton";
import RemoveMeasurementButton from "./components/RemoveMeasurementButton";
import MeasurementInputs from "./components/MeasurementInputs";
import { addClientMeasurementEntry } from "../../../../../../store/data/current_client/action_creators/clientMeasurements";
import { addAlert } from "../../../../../../store/ui/actionCreators/alert";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 250,
    margin: 25,
  },
  card: {
    padding: 5,
    border: `1px solid ${appTheme.colors.gainsbro}`,
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 24,
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: appTheme.colors.primary,
  },
  addEntryButton: {
    color: appTheme.colors.primary,
  },
  submitButtonContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  inputsContainer: {
    marginTop: 5,
  },
}));

export interface TrackedItemProps {
  measurementTitle: string;
  measurementUnits: string;
  measurementCategory: MeasurementCategory;
  measurementSubCategory: string;
}

export const TrackedItemCard = React.memo<TrackedItemProps>((props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onAddClientMeasurementEntry = (entry: MeasurementEntry) =>
    dispatch(
      addClientMeasurementEntry(entry, measurementTitle, measurementUnits)
    );

  const onAddAlert = (message: string) => dispatch(addAlert(message));

  const { measurementTitle, measurementUnits } = { ...props };

  const [newMeasurementValue, setNewMeasurementValue] =
    useState<string | undefined>(undefined);

  const [newMeasurementDate, setNewMeasurementDate] =
    useState<Date | null>(null);

  const onClickSubmitEntry = () => {
    onAddClientMeasurementEntry({
      value: Number(newMeasurementValue!),
      date: newMeasurementDate!.toISOString(),
      id: uuidv4.v4(),
    });
    setNewMeasurementValue(undefined);
    setNewMeasurementDate(null);
    onAddAlert(`Successfully added a new entry for ${measurementTitle}.`);
  };
  const disabled =
    !newMeasurementValue ||
    !newMeasurementDate ||
    !isValid(newMeasurementDate) ||
    isAfter(newMeasurementDate, Date.now()) ||
    isBefore(newMeasurementDate, new Date("1900-01-01"));
  return (
    <>
      <div className={classes.root}>
        <Card className={classes.card}>
          <div className={classes.row}>
            <TextOverflowTooltip label={measurementTitle}>
              <Typography noWrap variant="h3">
                {measurementTitle}
              </Typography>
            </TextOverflowTooltip>
            <div className={classes.buttonGroup}>
              <PreviousEntriesButton {...props} />
              <EditMeasurementPropertiesButton {...props} />
              <RemoveMeasurementButton {...props} />
            </div>
          </div>
          <div>
            <div className={classes.inputsContainer}>
              <MeasurementInputs
                {...props}
                measurementValue={newMeasurementValue}
                setMeasurementValue={setNewMeasurementValue}
                measurementDate={newMeasurementDate}
                setMeasurementDate={setNewMeasurementDate}
                submitButton={
                  <FoodworksTooltip title="Submit new entry">
                    <IconButton
                      size="small"
                      onClick={onClickSubmitEntry}
                      className={classes.addEntryButton}
                      disabled={disabled}
                    >
                      <PostAdd />
                    </IconButton>
                  </FoodworksTooltip>
                }
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
});

export default TrackedItemCard;

import { Action } from "redux";

export const SET_RECENT_MAPPED_DOCUMENT_ID = "SET_RECENT_MAPPED_DOCUMENT_ID";

export interface IActionsSetRecentMappedDocumentId extends Action {
  type: "SET_RECENT_MAPPED_DOCUMENT_ID";
  id: string;
}

export type OverridesScreenActions = IActionsSetRecentMappedDocumentId;

import React, { ReactNode, useMemo, useState } from "react";
import {
  Collapse,
  Divider,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import DocumentSelectorDropdown from "../../../../../common/DocumentSelectorDropdown";
import useIntakeGoals from "../hooks/useIntakeGoals";
import { BarProps } from "../../../../../common/graphs/HBar";
import { appTheme } from "../../../../../../styling/style";
import { ClientGoal } from "../../../../../../data/models/clientProperties/clientGoal";
import { Nutrient } from "../../../../../../data/models/nutrient";
import {
  NRVType,
  nrvTypeToFullTitle,
} from "../../../../../../data/models/nutrientReferenceValues";
import GraphTableDialog from "../../../../../common/graphs/GraphTableDialog";
import { Composition } from "../../../../../../data/models/composition";
import FoodworksHBarChart from "../../../../../common/graphs/FoodworksHBarChart";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "2rem",
  },
  dropdown: {},
  chartsContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  card: {
    width: "45%",
    margin: 15,
    padding: 15,
  },
  expandIcon: {
    color: appTheme.colors.primary,
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  divider: {
    margin: "0rem 0.5rem",
  },
}));

const getHeadings = (headings: string[]): ReactNode =>
  headings.map(heading => <TableCell key={heading}>{heading}</TableCell>);

const getRows = (data: BarProps[]): ReactNode => {
  return data.map((row, index) => {
    return (
      <TableRow
        key={index}
        style={{ backgroundColor: appTheme.colors.white[0] }}
      >
        <TableCell size="small">{row.label}</TableCell>
        <TableCell size="small">{row.percentage}</TableCell>
        <TableCell>{row.value}</TableCell>
      </TableRow>
    );
  });
};

const tableFromChartData = (data: BarProps[], title: string): ReactNode => {
  const headings = ["Nutrient", "Percentage", "Value"];
  return (
    <TableContainer key={`${title} TABLE`}>
      <Table aria-label={title}>
        <TableHead>
          <TableRow>{getHeadings(headings)}</TableRow>
        </TableHead>
        <TableBody>{getRows(data)}</TableBody>
      </Table>
    </TableContainer>
  );
};

const getGoalRows = (
  data: ClientGoal[],
  allNutrientNames: Map<string, Nutrient>
): ReactNode => {
  return data.map((row): ReactNode => {
    return (
      <TableRow
        key={row.nutrientId}
        style={{ backgroundColor: appTheme.colors.white[0] }}
      >
        <TableCell size="small">
          {allNutrientNames.get(row.nutrientId)
            ? allNutrientNames.get(row.nutrientId)!.name
            : ""}
        </TableCell>
        <TableCell size="small">
          {row.value +
            " " +
            (allNutrientNames.get(row.nutrientId)
              ? allNutrientNames.get(row.nutrientId)!.units
              : "")}
        </TableCell>
      </TableRow>
    );
  });
};

const tableFromGoalData = (
  data: ClientGoal[],
  allNutrientNames: Map<string, Nutrient>
): ReactNode => {
  const headings = ["Nutrient", "Goal Value"];
  return (
    <TableContainer key="NRVsIntakesTable">
      <Table>
        <TableHead>
          <TableRow>{getHeadings(headings)}</TableRow>
        </TableHead>
        <TableBody>{getGoalRows(data, allNutrientNames)}</TableBody>
      </Table>
    </TableContainer>
  );
};

const generateChartData = (
  NRVData: Map<string, number>,
  composition: Composition | undefined,
  allNutrientNames: Map<string, Nutrient>
): BarProps[] => {
  return [...NRVData].map(([id, value]): BarProps => {
    let nutrientValue = composition?.getNutrientValue(id);
    if (!nutrientValue) nutrientValue = 0;

    const percentage =
      (nutrientValue / value) * 100 !== Infinity
        ? (nutrientValue / value) * 100
        : 0;

    return {
      label: allNutrientNames.get(id) ? allNutrientNames.get(id)!.name : "",
      percentage: percentage.toFixed(0) + "%",
      value:
        nutrientValue.toFixed(2) +
        (allNutrientNames.get(id) ? allNutrientNames.get(id)!.units : "") +
        " / " +
        value.toFixed(2) +
        (allNutrientNames.get(id) ? allNutrientNames.get(id)!.units : ""),
      barWidth: "20px",
      barColor:
        percentage < 100 ? appTheme.colors.barGreen : appTheme.colors.barPurple,
    };
  });
};

const generateChartsIntake = (
  NRVMap: Map<NRVType, Map<string, number>>,
  composition: Composition | undefined,
  allNutrientNames: Map<string, Nutrient>
): JSX.Element[] => {
  const charts = [...NRVMap]
    .filter(([NRV, _]) => ["AI", "RDI", "EAR", "UIL"].includes(NRV))
    .map(([NRV, nutrient]) => {
      const data = generateChartData(nutrient, composition, allNutrientNames);
      const graph = graphGenerate(NRV, data);
      const table = tableFromChartData(data, NRV);
      return (
        <GraphTableDialog
          key={NRV + "GraphTable"}
          graph={graph}
          table={table}
        />
      );
    });
  return charts;
};

const graphGenerate = (NRV: NRVType, data: BarProps[]): ReactNode => (
  <FoodworksHBarChart
    key={NRV}
    title={nrvTypeToFullTitle(NRV)}
    data={data}
    width={"100%"}
    labelWidth={"20%"}
    maxPercent={200}
    percentLocation={"right"}
    titleDivider={false}
  />
);

const goalGraphGenerate = (
  title: string,
  goals: ClientGoal[],
  composition: Composition | undefined,
  allNutrientNames: Map<string, Nutrient>,
  type: "Min" | "Max"
) => (
  <FoodworksHBarChart
    key={title}
    title={title}
    data={generateGoalChartData(
      goals.filter(goal => goal.type === type),
      composition,
      allNutrientNames
    )}
    width={"100%"}
    labelWidth={"20%"}
    maxPercent={200}
    percentLocation={"right"}
    titleDivider={false}
  />
);

const generateChartsMinMax = (
  NRVMap: Map<NRVType, Map<string, number>>,
  composition: Composition | undefined,
  allNutrientNames: Map<string, Nutrient>
): JSX.Element[] => {
  const charts = [...NRVMap]
    .filter(([NRV, _]) => ["SDTMIN", "SDTMAX"].includes(NRV))
    .map(([NRV, nutrient]) => {
      const data = generateChartData(nutrient, composition, allNutrientNames);
      const graph = graphGenerate(NRV, data);
      const table = tableFromChartData(data, NRV);
      return (
        <GraphTableDialog key={NRV} title={""} graph={graph} table={table} />
      );
    });
  return charts;
};

const generateGoalChartData = (
  goals: ClientGoal[],
  composition: Composition | undefined,
  allNutrientNames: Map<string, Nutrient>
): BarProps[] => {
  return goals.map(goal => {
    let nutrientValue = composition?.getNutrientValue(goal.nutrientId);
    if (!nutrientValue) nutrientValue = 0;

    const percentage =
      (nutrientValue / goal.value) * 100 !== Infinity
        ? (nutrientValue / goal.value) * 100
        : 0;

    return {
      label: allNutrientNames.get(goal.nutrientId)
        ? allNutrientNames.get(goal.nutrientId)!.name
        : "",
      percentage: percentage.toFixed(0) + "%",
      value: "",
      barWidth: "20px",
      barColor:
        percentage < 100 ? appTheme.colors.barGreen : appTheme.colors.barPurple,
    };
  });
};

const generateGoalCharts = (
  goals: ClientGoal[],
  composition: Composition | undefined,
  allNutrientNames: Map<string, Nutrient>
): JSX.Element[] => {
  const min = goalGraphGenerate(
    "Minimum Goals",
    goals,
    composition,
    allNutrientNames,
    "Min"
  );
  const max = goalGraphGenerate(
    "Maximum Goals",
    goals,
    composition,
    allNutrientNames,
    "Max"
  );

  const tableMin = tableFromGoalData(
    goals.filter(goal => goal.type === "Min"),
    allNutrientNames
  );

  const tableMax = tableFromGoalData(
    goals.filter(goal => goal.type === "Max"),
    allNutrientNames
  );

  return [
    <GraphTableDialog key={"min"} title={""} graph={min} table={tableMin} />,
    <GraphTableDialog key={"max"} title={""} graph={max} table={tableMax} />,
  ];
};

export const NrVsIntakes = () => {
  const classes = useStyles();

  const [docId, setDocId] = useState<string | undefined>();
  const [expanded, setExpanded] = useState<{
    intakes: boolean;
    goals: boolean;
  }>({ intakes: true, goals: true });

  const [NRVMap, composition, nutrientsMap, goals]: [
    Map<NRVType, Map<string, number>>,
    Composition | undefined,
    Map<string, Nutrient>,
    ClientGoal[]
  ] = useIntakeGoals(docId);

  const charts = useMemo(
    () => generateChartsIntake(NRVMap, composition, nutrientsMap),
    [NRVMap, composition, nutrientsMap]
  );
  const chartsMinMax = useMemo(
    () => generateChartsMinMax(NRVMap, composition, nutrientsMap),
    [NRVMap, composition, nutrientsMap]
  );
  const chartsGoals = useMemo(
    () => generateGoalCharts(goals, composition, nutrientsMap),
    [goals, composition, nutrientsMap]
  );

  return (
    <div className={classes.root}>
      <div
        key="DocumentSelectorAnalysisTabDropdown"
        className={classes.dropdown}
      >
        <DocumentSelectorDropdown onChange={setDocId} />
      </div>
      <div>
        <div className={classes.header}>
          <IconButton
            onClick={() =>
              setExpanded(state => ({ ...state, intakes: !state.intakes }))
            }
            size="small"
            color="secondary"
          >
            {expanded.intakes ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography variant="h2">Intake</Typography>
        </div>
        <Divider className={classes.divider} />
      </div>
      <Collapse in={expanded.intakes}>
        <div className={classes.root}>
          <div className={classes.chartsContainer}>{charts}</div>
        </div>
      </Collapse>
      <div>
        <div className={classes.header}>
          <IconButton
            onClick={() =>
              setExpanded(state => ({ ...state, goals: !state.goals }))
            }
            size="small"
            color="secondary"
          >
            {expanded.goals ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography variant="h2">Minimum/maximum intake and goals</Typography>
        </div>
        <Divider className={classes.divider} />
      </div>
      <Collapse in={expanded.goals}>
        <div className={classes.root}>
          <div className={classes.chartsContainer}>
            {chartsMinMax}
            {chartsGoals}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default NrVsIntakes;

import {
  FoodItemPosition,
  FoodItemPositionState,
} from "../../../data/models/foodItemPosition";
import {
  RecipeGridActions,
  SET_SELECTED_ROWS,
  TOGGLE_DISPLAY_NUTRIENT_VALUES,
  TOGGLE_LOCK_NUTRIENT_COLUMNS,
  UPDATE_CURRENT_DAY,
} from "../actions/recipeGrid";

export interface RecipeGridState {
  selectedRows: FoodItemPositionState[];
  currentDay: number;
  displayNutrientValues: boolean;
  lockNutrientColumns: boolean;
}

export const initialRecipeGridState: RecipeGridState = {
  selectedRows: [],
  currentDay: 0,
  displayNutrientValues: false,
  lockNutrientColumns: false,
};

export const recipeGrid = (
  state: RecipeGridState = initialRecipeGridState,
  action: RecipeGridActions
): RecipeGridState => {
  switch (action.type) {
    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: [
          ...action.foodItemPositions.map(
            (position: FoodItemPosition): FoodItemPositionState =>
              position.object
          ),
        ],
      };
    case UPDATE_CURRENT_DAY:
      return { ...state, currentDay: action.day };
    case TOGGLE_DISPLAY_NUTRIENT_VALUES:
      return { ...state, displayNutrientValues: !state.displayNutrientValues };
    case TOGGLE_LOCK_NUTRIENT_COLUMNS:
      return { ...state, lockNutrientColumns: !state.lockNutrientColumns };
    default:
      return state;
  }
};

import React, { FunctionComponent, ReactNode, useState } from "react";
import { makeStyles, MenuItem, Typography } from "@material-ui/core";
import {  useSelector } from "react-redux";
import {
  ArchiveRounded,
  DeleteRounded,
  FileCopyRounded,
  RestoreRounded,
} from "@material-ui/icons";

import { Document } from "../../../../data/models/document";
import { CurrentDocumentIdSelector } from "../../../../store/data/current-document/selectors/currentDocument";
import { RootState } from "../../../../store/reducers";
import { FoodworksMenu } from "../../../common/FoodworksMenu";
import { DocumentSummary } from "../../../../data/models/userDatabase";
import {
  duplicateDocument,
} from "../../../../store/data/current-document/thunks/document";
import { DeleteDocumentDialog } from "../../../dialogs/document_editing/DeleteDocumentDialog";
import { databaseIdSelector } from "../../../../store/data/selectors/database";
import { cachedDocumentSelector } from "../../../../store/data/selectors/documentCache";
import { useReduxDispatch } from "../../../../store/store";
import { FoodId } from "../../../../data/models/documentProperties/foodId";
import { appTheme } from "../../../../styling/style";
import { getDocument } from "../../../../store/data/current-document/thunks/getDocument";
import useArchiveDocument from "../../databases/documents/hooks/useArchiveDocument";

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: -40,
  },
  iconContainer: {
    display: "flex",
  },
  iconContainerSuccess: {
    display: "flex",
    color: appTheme.colors.success,
  },
  icon: {
    paddingRight: 5,
  },
}));

interface DocumentItemMenuProps {
  anchorElement: HTMLDivElement | null;
  onClose: () => void;
  documentSummary: DocumentSummary;
}

export const DocumentItemMenu: FunctionComponent<DocumentItemMenuProps> = ({
  anchorElement,
  onClose,
  documentSummary,
}) => {
  const classes = useStyles();
  const dispatch = useReduxDispatch();

  const databaseId = useSelector<RootState, string>(databaseIdSelector);
  const selectedDocument: Document | undefined = useSelector<
    RootState,
    Document | undefined
  >((state) => {
    return cachedDocumentSelector(
      state,
      databaseId + ":" + documentSummary.documentId
    );
  });
  const [permaDialog, setPermaDialog] = useState(false);

  const onDuplicateDocument = () =>
    dispatch(duplicateDocument(documentSummary.documentId));

  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const onArchiveDocument = useArchiveDocument(
    documentSummary.documentId,
    documentSummary.documentId === currentDocumentId
  );

  const onClickArchive = () => {
    onArchiveDocument();
    onClose();
  };

  const onClickDuplicate = () => {
    onDuplicateDocument();
    onClose();
  };

  const onClickPermaDelete = () => {
    if (!selectedDocument) {
      dispatch(
        getDocument(
          new FoodId({
            datasourceId: databaseId,
            documentId: documentSummary.documentId,
          }),
          false,
          false
        )
      ).then(() => setPermaDialog(true));
      return;
    }
    setPermaDialog(true);
  };

  const deleteMenuItem: ReactNode = (
    <MenuItem
      key={`${documentSummary.documentId}-delete`}
      onClick={onClickArchive}
    >
      {documentSummary.status === "archived" ? (
        <div className={classes.iconContainerSuccess}>
          <RestoreRounded color="inherit" />
          <Typography>Restore</Typography>
        </div>
      ) : (
        <div className={classes.iconContainer}>
          <ArchiveRounded className={classes.icon} color="action" />
          <Typography>Archive</Typography>
        </div>
      )}
    </MenuItem>
  );
  const permanentDeleteMenuItem: ReactNode = documentSummary.status ===
    "archived" && (
    <div key={`${documentSummary.documentId} DELETEMENUITEM`}>
      <MenuItem
        key={`${documentSummary.documentId}-delete`}
        onClick={() => onClickPermaDelete()}
      >
        <DeleteRounded className={classes.icon} color="error" />
        <Typography>Delete</Typography>
      </MenuItem>
      {permaDialog && (
        <DeleteDocumentDialog
          key={documentSummary.documentId + "deleteDialog"}
          open={permaDialog}
          onClose={() => setPermaDialog(false)}
          documentSummary={documentSummary}
        />
      )}
    </div>
  );
  const duplicateMenuItem: ReactNode = (
    <MenuItem
      key={`${documentSummary.documentId}-duplicate`}
      onClick={onClickDuplicate}
      disabled={documentSummary.status === "archived"}
    >
      <FileCopyRounded className={classes.icon} color="action" />
      <Typography>Duplicate</Typography>
    </MenuItem>
  );

  const menuItems: ReactNode = [
    deleteMenuItem,
    duplicateMenuItem,
    permanentDeleteMenuItem,
  ];

  return (
    <FoodworksMenu
      open
      onClose={onClose}
      anchorEl={anchorElement}
      className={classes.root}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {menuItems}
    </FoodworksMenu>
  );
};

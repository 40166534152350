import {
  BaseQuantity,
  VolumeBaseQuantity,
  WeightBaseQuantity,
} from "./baseQuantity";
import { Measure } from "./documentProperties/measure";
import firebase from "firebase";

export class ReferenceMeasure extends Measure {
  isVolume: boolean;
  isWeight: boolean;
  displayName: string;

  constructor(
    id: string,
    name: string,
    displayName: string,
    isVolume: boolean,
    isWeight: boolean,
    conversionFactor: number,
    note: string
  ) {
    super(id, name, conversionFactor, note);

    this.isVolume = isVolume;
    this.isWeight = isWeight;
    this.displayName = displayName;
  }

  baseQuantity = (): BaseQuantity =>
    this.isVolume
      ? new VolumeBaseQuantity(this.value)
      : new WeightBaseQuantity(this.value);
}

export interface ReferenceMeasureObject {
  id: string;
  name: string;
  displayName: string;
  isVolume: boolean;
  isWeight: boolean;
  conversionFactor: number;
  note: string;
}

export const referenceMeasureConverter = {
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): ReferenceMeasureObject {
    const data: firebase.firestore.DocumentData = snapshot.data();
    return {
      id: data.id,
      name: data.name,
      displayName: data.displayName,
      isVolume: data.isVolume,
      isWeight: data.isWeight,
      conversionFactor: data.conversionFactor,
      note: data.note,
    };
  },

  toFirestore: function (
    referenceMeasure: ReferenceMeasureObject
  ): firebase.firestore.DocumentData {
    return {
      id: referenceMeasure.id,
      name: referenceMeasure.name,
      displayName: referenceMeasure.displayName,
      isVolume: referenceMeasure.isVolume,
      isWeight: referenceMeasure.isWeight,
      conversionFactor: referenceMeasure.conversionFactor,
      note: referenceMeasure.note,
    };
  },
};

import { Action } from "redux";

export const ADD_NUTRIENT_OVERRIDE = "ADD_NUTRIENT_OVERRIDE";
export const REMOVE_NUTRIENT_OVERRIDE = "REMOVE_NUTRIENT_OVERRIDE";
export const UPDATE_NUTRIENT_OVERRIDE_VALUE = "UPDATE_NUTRIENT_OVERRIDE_VALUE";
export const UPDATE_NUTRIENT_OVERRIDE_NOTE = "UPDATE_NUTRIENT_OVERRIDE_NOTE";

export interface IActionsUpdateNutrientOverrideValue extends Action {
  type: "UPDATE_NUTRIENT_OVERRIDE_VALUE";
  id: string;
  value: number;
}

export interface IActionsUpdateNutrientOverrideNote extends Action {
  type: "UPDATE_NUTRIENT_OVERRIDE_NOTE";
  id: string;
  note: string;
}

export interface IActionsAddNutrientOverride extends Action {
  type: "ADD_NUTRIENT_OVERRIDE";
  id: string;
  value: number;
}

export interface IActionsRemoveNutrientOverride extends Action {
  type: "REMOVE_NUTRIENT_OVERRIDE";
  id: string;
}

export type NutrientOverridesActions =
  | IActionsUpdateNutrientOverrideNote
  | IActionsUpdateNutrientOverrideValue
  | IActionsAddNutrientOverride
  | IActionsRemoveNutrientOverride;

import React, { ReactNode, useMemo, useState } from "react";
import { Card, IconButton, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { differenceInDays } from "date-fns";
import { FilterListOutlined } from "@material-ui/icons";

import {
  Measurement,
  MeasurementEntry,
} from "../../../../../../../data/models/clientProperties/measurement";
import { measurementSelector } from "../../../../../../../store/data/current_client/selectors/clientMeasurements";
import { RootState } from "../../../../../../../store/reducers";
import MeasurementEntryRow from "./MeasurementEntry";
import { FoodworksTooltip } from "../../../../../../common/InfoTooltip";
import FoodworksDatePicker from "../../../../../../common/date/FoodworksDatePicker";

const useStyles = makeStyles(() => ({
  root: {},
  filterContainer: {
    display: "flex",
    alignItems: "center",
  },
  card: {
    overflow: "auto",
    height: 300,
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
  },
  dateLabel: {
    marginRight: 5,
    marginLeft: 5,
  },
  message: {
    marginLeft: 5,
    marginTop: 5,
  },
}));

interface Props {
  measurementTitle: string;
  measurementUnit: string;
}

const getFilteredEntries = (
  entries: MeasurementEntry[],
  useFilter: boolean,
  startDate: Date,
  endDate: Date
): MeasurementEntry[] =>
  [...entries].filter((entry: MeasurementEntry) =>
    useFilter
      ? startDate!.toLocaleDateString() <=
          new Date(entry.date).toLocaleDateString() &&
        endDate!.toLocaleDateString() >=
          new Date(entry.date).toLocaleDateString()
      : true
  );

const PreviousEntriesTable = (props: Props): JSX.Element => {
  const classes = useStyles();

  const { measurementTitle, measurementUnit } = { ...props };

  const selectMeasurement = useMemo(() => measurementSelector, []);

  const measurement: Measurement | undefined = useSelector<
    RootState,
    Measurement | undefined
  >((state: RootState) =>
    selectMeasurement(state, measurementTitle, measurementUnit)
  );

  const [filterApplied, setFilterApplied] = useState(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const onToggleFilter = () => {
    setFilterApplied((prev) => !prev);
  };

  const onStartDateChange = (date: Date) => {
    if (differenceInDays(endDate!, date!) < 0) {
      setEndDate(date);
    }
    setStartDate(date);
  };

  const filteredEntries = useMemo(
    () =>
      getFilteredEntries(
        measurement?.entries || [],
        filterApplied,
        startDate!,
        endDate!
      ),
    [measurement, filterApplied, startDate, endDate]
  );

  return (
    <div>
      <div className={classes.filterContainer}>
        <FoodworksTooltip title="Toggle date filter">
          <IconButton
            onClick={onToggleFilter}
            color={filterApplied ? "secondary" : "default"}
          >
            <FilterListOutlined />
          </IconButton>
        </FoodworksTooltip>
        <div className={classes.dateContainer}>
          <Typography className={classes.dateLabel}>From</Typography>
          <FoodworksDatePicker
            disabled={!filterApplied}
            date={startDate}
            handleChange={(date) => onStartDateChange(date!)}
          />
          <Typography className={classes.dateLabel}>to</Typography>
          <FoodworksDatePicker
            disabled={!filterApplied}
            date={endDate}
            handleChange={(date) => setEndDate(date!)}
            minDate={startDate}
          />
        </div>
      </div>
      <Card className={classes.card}>
        {filteredEntries.length ? (
          filteredEntries.map(
            (entry: MeasurementEntry, index: number): ReactNode => (
              <MeasurementEntryRow
                entry={entry}
                measurementUnit={measurementUnit}
                measurementTitle={measurementTitle}
                index={index + 1}
              />
            )
          )
        ) : (
          <Typography className={classes.message}>
            No recorded measurements.
          </Typography>
        )}
      </Card>
    </div>
  );
};

export default PreviousEntriesTable;

import _ from "lodash";

import { Day, DayState } from "../../../data/models/documentProperties/day";
import {
  ADD_DOCUMENT_PAST,
  DocumentEditingActions,
  REDO_DOCUMENT,
  REMOVE_DUPLICATE_HISTORY,
  SET_DISABLE_SAVE_BUTTON,
  UNDO_DOCUMENT,
} from "../actions/documentEditing";

export interface ActionHistory {
  past: Array<Day[]>;
  current: Day[] | null;
  future: Array<Day[]>;
}

export const initialActionHistory = {
  past: new Array<Day[]>(),
  current: null,
  future: new Array<Day[]>(),
};

export interface DocumentEditingState {
  disableSaveButton: boolean;
  actionHistory: ActionHistory;
  historyLimit: number;
}

export const initialDocumentEditingState: DocumentEditingState = {
  disableSaveButton: false,
  actionHistory: initialActionHistory,
  historyLimit: 15,
};

export const documentEditing = (
  state: DocumentEditingState = initialDocumentEditingState,
  action: DocumentEditingActions
) => {
  switch (action.type) {
    case SET_DISABLE_SAVE_BUTTON:
      return { ...state, disableSaveButton: action.disable };
    case ADD_DOCUMENT_PAST: {
      if (action.reset) {
        return {
          ...state,
          actionHistory: {
            past: new Array<DayState[]>(),
            current: action.currentAction,
            future: new Array<DayState[]>(),
          },
        };
      }

      const { past, current }: ActionHistory = {
        ...state.actionHistory,
      };
      if (!!current) past.push(current);
      return {
        ...state,
        actionHistory: {
          past: past.length > state.historyLimit ? past.slice(1) : past,
          current: action.currentAction,
          future: new Array<DayState[]>(),
        },
      };
    }
    case UNDO_DOCUMENT: {
      const { past, current, future }: ActionHistory = {
        ...{
          ...state.actionHistory,
        },
      };
      const lastUndoAction: Day[] | undefined = past.pop();
      if (!!current) future.push(current);
      return {
        ...state,
        actionHistory: {
          past: [...past],
          current: lastUndoAction,
          future: [...future],
        },
      };
    }

    case REDO_DOCUMENT: {
      const { past, current, future }: ActionHistory = {
        ...state.actionHistory,
      };

      const firstRedoAction: Day[] | undefined = future.pop();
      if (!!current) past.push(current);

      return {
        ...state,
        actionHistory: {
          past: [...past],
          current: firstRedoAction,
          future: [...future],
        },
      };
    }
    case REMOVE_DUPLICATE_HISTORY: {
      const { past, current, future }: ActionHistory = {
        ...state.actionHistory,
      };

      const setPast = _.uniqWith(past, _.isEqual);
      if (
        setPast.length > 0 &&
        _.isEqual(setPast[setPast.length - 1], current)
      ) {
        setPast.pop();
      }

      return {
        ...state,
        actionHistory: { past: setPast, current, future },
      };
    }

    default:
      return state;
  }
};

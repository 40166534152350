import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { appTheme } from "../../../../styling/style";
import { FoodWorksNumberInput } from "../../FoodWorksTextInput";
import { ClientGoal } from "../../../../data/models/clientProperties/clientGoal";
import {
  addClientGoal,
  removeClientGoal,
  updateClientGoal,
} from "../../../../store/data/current_client/action_creators/client";
import { NRV_GOAL_MAX, NRV_GOAL_MIN } from "../../../../constants/nrv";

export const ITEM_ELEMENTS = {
  smallColorSpace: "x", // Add small spacing with color between columns
  smallWhiteSpace: "!x", // Add small spacing without color between columns
  bigColorSpace: "X", // Add big spacing with color between columns
  bigWhiteSpace: "!X", // Add small spacing without color between columns
};

const useStyles = makeStyles(() => ({
  header: {
    "& th": {
      background: "white",
      color: "black",
      zIndex: 1,
    },
    "& th:nth-child(n+3)": {
      borderTopRightRadius: "5px",
      borderTopLeftRadius: "5px",
      textAlign: "center",
    },
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "show",
  },
  invisible: {
    visibility: "hidden",
  },
  row: {
    borderBottom: `1px solid ${appTheme.colors.table.underline}`,
    "& td": {
      color: appTheme.colors.table.fontColor,
      fontWeight: "600",
      maxWidth: 300,
    },
    "& td:nth-child(n+3)": {
      textAlign: "center",
    },
    marginTop: 15,
    marginBottom: 15,
  },
  icon: {
    color: appTheme.colors.table.fontColor,
  },
  input: {
    width: 70,
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
}));

const getColor = (header: string, idx: number): string => {
  if (idx === 0) {
    return "white";
  }

  if (
    header === ITEM_ELEMENTS.bigColorSpace ||
    header === ITEM_ELEMENTS.smallColorSpace
  )
    return `linear-gradient(180deg, white 50%, ${appTheme.colors.oceanBlue[0]} 50%)`;

  if (
    header === ITEM_ELEMENTS.bigWhiteSpace ||
    header === ITEM_ELEMENTS.smallWhiteSpace
  )
    return "white";

  return !header ? "white" : appTheme.colors.table.header;
};

const getWidth = (header: string, idx: number): number => {
  if (!idx) return 350;

  switch (header) {
    case ITEM_ELEMENTS.smallColorSpace:
      return 20;
    case ITEM_ELEMENTS.smallWhiteSpace:
      return 20;
    case ITEM_ELEMENTS.bigColorSpace:
      return 90;
    case ITEM_ELEMENTS.bigWhiteSpace:
      return 90;
    default:
      return 90;
  }
};

interface RowItems {
  title: string;
  row: string[];
}

interface RowItemsProps {
  row: string[];
  open: boolean;
  nutrientId: string;
  goals: ClientGoal[] | undefined;
}

const getGoalValues = (
  goals: ClientGoal[] | undefined
): Map<string, string> => {
  const map = new Map([
    ["min", ""],
    ["max", ""],
  ]);
  if (goals) {
    for (const goal of goals) {
      goal.type === "Min"
        ? map.set("min", goal.value.toFixed(0))
        : map.set("max", goal.value.toFixed(0));
    }
  }
  return map;
};

const RowItems = ({
  row,
  open,
  nutrientId,
  goals,
}: RowItemsProps): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const goalsValues = useMemo(() => getGoalValues(goals), [goals]);

  const [goalMin, setGoalMin] = useState<string>(goalsValues.get("min")!);
  const [goalMax, setGoalMax] = useState<string>(goalsValues.get("max")!);
  const [minExists, setMinExists] = useState<boolean>(goalMin !== "");
  const [maxExists, setMaxExists] = useState<boolean>(goalMax !== "");
  const [title, setTitle] = useState<string>("");
  const [items, setItems] = useState<string[]>([]);

  useEffect(() => {
    const rowItems = [...row];
    const rowTitle = rowItems.shift() || "";

    setTitle(rowTitle);
    setItems(rowItems);
  }, [row]);

  useEffect(() => {
    setGoalMin(goalsValues.get("min")!);
    setGoalMax(goalsValues.get("max")!);
  }, [goalsValues]);

  const onChangeGoalMin = () => {
    const goal: ClientGoal = {
      value: Number(goalMin),
      nutrientId: nutrientId,
      type: "Min",
    };

    if (goalMin !== goalsValues.get("min")!) {
      if (goalMin === "") {
        dispatch(removeClientGoal(goal));
        setMinExists(false);
      } else if (minExists) {
        dispatch(updateClientGoal(goal));
      } else {
        dispatch(addClientGoal(goal));
        setMinExists(true);
      }
    }
  };

  const onChangeGoalMax = () => {
    const goal: ClientGoal = {
      value: Number(goalMax),
      nutrientId: nutrientId,
      type: "Max",
    };

    if (goalMax !== goalsValues.get("max")!) {
      if (goalMax === "") {
        dispatch(removeClientGoal(goal));
        setMaxExists(false);
      } else if (maxExists) {
        dispatch(updateClientGoal(goal));
      } else {
        dispatch(addClientGoal(goal));
        setMaxExists(true);
      }
    }
  };

  return (
    <tr
      className={
        open
          ? `${classes.show} ${classes.row}`
          : `${classes.hidden} ${classes.row}`
      }
    >
      <td />
      <td>
        <Typography>{title}</Typography>
      </td>
      <td>
        <div className={classes.center}>
          <FoodWorksNumberInput
            className={classes.input}
            setValue={(value: string) => setGoalMin(value)}
            inputProps={{
              onBlur: onChangeGoalMin,
              value: goalMin,
            }}
            minValue={NRV_GOAL_MIN}
            maxValue={NRV_GOAL_MAX}
          />
        </div>
      </td>
      <td />
      <td>
        <div className={classes.center}>
          <FoodWorksNumberInput
            className={classes.input}
            setValue={(value: string) => setGoalMax(value)}
            inputProps={{
              onBlur: onChangeGoalMax,
              value: goalMax,
            }}
            minValue={NRV_GOAL_MIN}
            maxValue={NRV_GOAL_MAX}
          />
        </div>
      </td>
      {items.map((value: string, idx: number) => (
        <td key={`${nutrientId}-${idx}`}>{value}</td>
      ))}
    </tr>
  );
};

interface NRVTableItemsProps {
  headers: string[];
  rows: string[][];
  nutrientIds: string[];
  isOpen?: boolean;
  goals: Map<string, ClientGoal[]>;
}

export const NRVTableItems = ({
  headers,
  rows,
  nutrientIds,
  isOpen = true,
  goals,
}: NRVTableItemsProps): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(isOpen);

  const headerItems: ReactNode = (
    <tr
      key={`nrvTableRow-${headers[0]}`}
      className={classes.header}
      style={{
        borderBottom: open
          ? `1px solid ${appTheme.colors.helperText}`
          : "white",
      }}
    >
      <th key={`${headers[0]}`} style={{ width: "40px" }}>
        <IconButton
          className={classes.icon}
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </th>
      {headers.map((header: string, idx: number) => (
        <th
          key={`${headers[0]}-${idx}`}
          style={{
            background: getColor(header, idx),
            minWidth: getWidth(header, idx),
            visibility: !open && idx > 0 ? "hidden" : "visible",
          }}
        >
          {Object.values(ITEM_ELEMENTS).includes(header) ? "" : header}
        </th>
      ))}
    </tr>
  );

  return (
    <>
      {headerItems}
      {rows.map((row: string[], i: number) => (
        <RowItems
          key={nutrientIds[i]}
          row={row}
          open={open}
          nutrientId={nutrientIds[i]}
          goals={goals.get(nutrientIds[i])}
        />
      ))}
    </>
  );
};

import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { createNewClient } from "../../../../store/data/current_client/thunks/client";

const useStyles = makeStyles(() => ({
  newClientButton: {
    overflow: "hidden",
    alignItems: "center",
    padding: 6,
  },
}));

const CreateClientButton = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onCreateClient = () => dispatch(createNewClient());

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        className={classes.newClientButton}
        onClick={onCreateClient}
        endIcon={<ArrowDropDown />}
        size="small"
      >
        <Typography color="primary" variant="h3">
          New client
        </Typography>
      </Button>
    </div>
  );
};

export default CreateClientButton;

import { Action } from "redux";

import { RouteData } from "../../../data/routing/types";

export const SET_NEXT_ROUTE = "SET_NEXT_ROUTE";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";

export interface IActionsSetNextRoute extends Action {
  type: "SET_NEXT_ROUTE";
  route: RouteData | undefined;
}

export interface IActionsSetCurrentRoute extends Action {
  type: "SET_CURRENT_ROUTE";
  route: RouteData;
}

export type RoutingActions = IActionsSetNextRoute | IActionsSetCurrentRoute;

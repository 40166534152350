import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../../../store/reducers";
import { setSelectedClientDocuments } from "../../../../../../store/ui/actionCreators/clientScreen";
import { selectedClientDocumentsSelector } from "../../../../../../store/ui/selectors/clientScreen";

const useSelectedClientDocuments = (): [
  string[],
  (documents: string[]) => void
] => {
  const dispatch = useDispatch();

  const selectedDocuments: string[] = useSelector<RootState, string[]>(
    selectedClientDocumentsSelector
  );

  const onSetSelectedClientDocuments = useCallback(
    (documents: string[]) => dispatch(setSelectedClientDocuments(documents)),
    [dispatch]
  );

  return [selectedDocuments, onSetSelectedClientDocuments];
};

export default useSelectedClientDocuments;

import React, { FunctionComponent } from "react";

import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import { Home } from "./components/screens/Home";
import { muiTheme } from "./styling/style";
import SignUp from "./components/onboarding/SignUp";
import SignIn from "./components/onboarding/SignIn";
import * as ROUTES from "./constants/routes";
import RoutingContext from "./data/routing/context";
import { Routing } from "./data/routing/routing";

export interface AppProps {
  routing: Routing;
}

const App: FunctionComponent<AppProps> = ({ routing }) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <Router>
        <Route
          path={ROUTES.SIGN_UP}
          render={(props) => {
            if (!routing.routeProps) {
              routing.setRouteProps(props);
            }
            return (
              <RoutingContext.Provider value={routing}>
                <SignUp />
              </RoutingContext.Provider>
            );
          }}
        />

        <Route
          path={ROUTES.SIGN_IN}
          render={(props) => {
            if (!routing.routeProps) {
              routing.setRouteProps(props);
            }
            return (
              <RoutingContext.Provider value={routing}>
                <SignIn />
              </RoutingContext.Provider>
            );
          }}
        />
        <Route
          exact
          path={`${ROUTES.HOME}/:screen/:id?/:docId?`}
          render={(props) => {
            if (!routing.routeProps) {
              routing.setRouteProps(props);
            }
            return (
              <RoutingContext.Provider value={routing}>
                <Home />
              </RoutingContext.Provider>
            );
          }}
        />
        <Route exact path="/">
          <Redirect to={ROUTES.DATABASES} />
        </Route>
      </Router>
    </ThemeProvider>
  );
};

export default App;

import { Action } from "redux";

import { Quantity } from "../../../../data/models/documentProperties/quantity";
import { FoodItem } from "../../../../data/models/documentProperties/foodItem";

export const ADD_FOOD_ITEM = "ADD_FOOD_ITEM";
export const CLEAR_FOOD_ITEM = "CLEAR_FOOD_ITEM";
export const REMOVE_FOOD_ITEM = "REMOVE_FOOD_ITEM";
export const UPDATE_FOOD_ITEM = "UPDATE_FOOD_ITEM";
export const SET_ALL_FOOD_ITEMS = "SET_ALL_FOOD_ITEMS";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const CLEAR_QUANTITY = "CLEAR_QUANTITY";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const UPDATE_RETENTION_FACTOR = "UPDATE_RETENTION_FACTOR";
export const CLEAR_RETENTION_FACTOR = "CLEAR_RETENTION_FACTOR";
export const FOOD_ITEMS_PASTED = "FOOD_ITEMS_PASTED";
export const REMOVE_MULTIPLE_FOOD_ITEMS = "REMOVE_MULTIPLE_FOOD_ITEMS";

export interface IActionsAddFoodItem extends Action {
  type: "ADD_FOOD_ITEM";
  dayIndex: number;
  sectionIndex: number;
  foodItem: FoodItem;
}

export interface IActionsClearFoodItem extends Action {
  type: "CLEAR_FOOD_ITEM";
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
}

export interface IActionsRemoveFoodItem extends Action {
  type: "REMOVE_FOOD_ITEM";
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
}

export interface IActionsRemoveMultipleFoodItems extends Action {
  type: "REMOVE_MULTIPLE_FOOD_ITEMS";
  foodObjects: {
    dayIndex: number;
    sectionIndex: number;
    rowIndex: number;
  }[];
}

export interface IActionsUpdateFoodItem extends Action {
  type: "UPDATE_FOOD_ITEM";
  dayIndex: number;
  sectionIndex: number;
  foodItem: FoodItem;
}

export interface IActionsSetAllFoodItems extends Action {
  type: "SET_ALL_FOOD_ITEMS";
  dayIndex: number;
  sectionIndex: number;
  foodItems: FoodItem[];
}

export interface IActionsUpdateQuantity extends Action {
  type: "UPDATE_QUANTITY";
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
  quantity: Quantity;
}

export interface IActionsClearQuantity extends Action {
  type: "CLEAR_QUANTITY";
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
}

export interface IActionsUpdateNote extends Action {
  type: "UPDATE_NOTE";
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
  note: string;
}

export interface IActionsUpdateRetentionFactor extends Action {
  type: "UPDATE_RETENTION_FACTOR";
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
  id: string | null;
}

export interface IActionsClearRetentionFactor extends Action {
  type: "CLEAR_RETENTION_FACTOR";
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
}

export interface IActionsFoodItemsPasted extends Action {
  type: "FOOD_ITEMS_PASTED";
}

export type FoodItemsActions =
  | IActionsAddFoodItem
  | IActionsClearFoodItem
  | IActionsRemoveFoodItem
  | IActionsUpdateFoodItem
  | IActionsSetAllFoodItems
  | IActionsUpdateQuantity
  | IActionsClearQuantity
  | IActionsUpdateNote
  | IActionsUpdateRetentionFactor
  | IActionsClearRetentionFactor
  | IActionsRemoveMultipleFoodItems
  | IActionsFoodItemsPasted;

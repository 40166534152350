import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  PregnancyLevel,
  Sex,
} from "../../../../../../../data/models/nutrientReferenceValues";
import { setClientPregnancyLevel } from "../../../../../../../store/data/current_client/action_creators/clientProfile";
import {
  clientIsLactatingSelector,
  clientPregnancyLevelSelector,
  clientSexSelector,
} from "../../../../../../../store/data/current_client/selectors/clientProfile";
import { RootState } from "../../../../../../../store/reducers";

const useClientPregnancyLevel = (): [
  PregnancyLevel,
  (input: PregnancyLevel) => void,
  boolean
] => {
  const dispatch = useDispatch();

  const pregnancyLevel: PregnancyLevel = useSelector<RootState, PregnancyLevel>(
    clientPregnancyLevelSelector
  );

  const onSetClientPregnancyLevel = useCallback(
    (input: PregnancyLevel) => dispatch(setClientPregnancyLevel(input)),
    [dispatch]
  );

  const sex = useSelector<RootState, Sex>(clientSexSelector);

  const isLactating = useSelector<RootState, boolean>(
    clientIsLactatingSelector
  );

  const pregnancyIsPossible: boolean = sex !== "Male" && !isLactating;

  return [pregnancyLevel, onSetClientPregnancyLevel, pregnancyIsPossible];
};

export default useClientPregnancyLevel;

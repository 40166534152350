import { RouteComponentProps } from "react-router-dom";

import { CLIENTS, DATABASES, SIGN_IN } from "../../constants/routes";
import { handleCurrentDocumentChange } from "../../store/data/current-document/thunks/document";
import { handleCurrentClientChange } from "../../store/data/thunks/clientDatabase";
import { signUserOut } from "../../store/thunks";
import {
  setUnsavedClientChanges,
  setUnsavedDocumentChanges,
} from "../../store/ui/actionCreators/saving";
import {
  setCurrentRoute,
  setNextRoute,
} from "../../store/ui/actionCreators/routing";
import handleCurrentDatabaseChange from "../../store/data/thunks/database/handleCurrentDatabaseChange";
import { AvailableRoutes, RouteData, RoutingResponse } from "./types";





export class Routing {
  routeProps: RouteComponentProps | undefined;

  setRouteProps(routeComponentProps: RouteComponentProps) {
    this.routeProps = routeComponentProps;
  }

  navigateToDatabase(databaseId: string) {
    this.routeProps!.history.replace(`${DATABASES}/${databaseId}`);
  }

  navigateToDatabaseDocument(databaseId: string, documentId: string) {
    this.routeProps!.history.replace(
      `${DATABASES}/${databaseId}/${documentId}`
    );
  }

  navigateToClientDashboard() {
    this.routeProps!.history.replace(`${CLIENTS}/dashboard`);
  }

  navigateToClient(clientId: string) {
    this.routeProps!.history.replace(`${CLIENTS}/${clientId}`);
  }

  navigateToClientDocument(clientId: string, documentId: string) {
    this.routeProps!.history.replace(`${CLIENTS}/${clientId}/${documentId}`);
  }

  navigateToDatabaseDashboard() {
    this.routeProps!.history.replace(`${DATABASES}/dashboard`);
  }

  navigateToSignIn() {
    this.routeProps!.history.replace(SIGN_IN);
  }

  navigateToDatabases() {
    this.routeProps!.history.replace(DATABASES);
  }
}



export const getDatabaseRouteData = (databaseId: string): RouteData => ({
  screen: "databases",
  databaseId,
});

export const getDatabaseDocumentRouteData = (
  databaseId: string,
  documentId: string
): RouteData => ({ screen: "databases", databaseId, documentId });

export const getDatabaseDashboardRouteData = (): RouteData => ({
  screen: "databases",
  databaseId: "dashboard",
});
export const getClientRouteData = (clientId: string): RouteData => ({
  screen: "clients",
  clientId,
});

export const getClientDocumentRouteData = (
  clientId: string,
  documentId: string
): RouteData => ({ screen: "clients", clientId, documentId });

export const getClientDashboardRouteData = (): RouteData => ({
  screen: "clients",
  clientId: "dashboard",
});

export const getSignInRouteData = (): RouteData => ({
  screen: "signin",
});

export class Routes {
  private clientUserDatabaseId: string;
  private routing: Routing;
  private documentHasChanges: boolean;
  private clientHasChanges: boolean;

  constructor(
    clientUserDatabaseId: string,
    routing: Routing,
    documentHasChanges: boolean,
    clientHasChanges: boolean
  ) {
    this.clientUserDatabaseId = clientUserDatabaseId;
    this.routing = routing;
    this.documentHasChanges = documentHasChanges;
    this.clientHasChanges = clientHasChanges;
  }

  getRoute(route: RouteData | undefined): AvailableRoutes | undefined {
    if (!route) return;

    if (route.screen === "databases") {
      if (!route.databaseId) return;

      if (route.databaseId === "dashboard") {
        return "DATABASE_DASHBOARD";
      }

      if (!route.documentId) {
        return "DATABASE";
      }

      if (route.documentId) {
        return "DATABASE_DOCUMENT";
      }
    }

    if (route.screen === "clients") {
      if (!route.clientId) return;

      if (route.clientId === "dashboard") {
        return "CLIENT_DASHBOARD";
      }

      if (!route.documentId) {
        return "CLIENT";
      }

      return "CLIENT_DOCUMENT";
    }

    if (route.screen === "signin") {
      return "SIGN_IN";
    }

    if (route.screen === "signup") {
      return "SIGN_UP";
    }
  }

  handleRoutingFromDatabaseDocument(nextRoute: RouteData): RoutingResponse {
    const route: AvailableRoutes | undefined = this.getRoute(nextRoute);

    if (!route) return {actionsToTake : []};

    if (this.documentHasChanges) {
      return {actionsToTake : [
        () => setUnsavedDocumentChanges(true),
        () => setNextRoute(nextRoute),
      ]};
    }

    const actionsToTake: Function[] = [];

    switch (route) {
      case "DATABASE_DOCUMENT":
        actionsToTake.push(() =>
          handleCurrentDocumentChange(nextRoute.documentId!)
        );
        break;
      case "DATABASE":
        actionsToTake.push(() => handleCurrentDocumentChange(""));
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(nextRoute.databaseId!)
        );
        break;
      case "DATABASE_DASHBOARD":
        actionsToTake.push(() => handleCurrentDocumentChange(""));
        actionsToTake.push(() => handleCurrentDatabaseChange(""));
        break;
      case "CLIENT_DASHBOARD":
        actionsToTake.push(() => handleCurrentDocumentChange(""));
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(this.clientUserDatabaseId)
        );
        break;
      case "CLIENT":
        actionsToTake.push(() => handleCurrentDocumentChange(""));
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(this.clientUserDatabaseId)
        );
        actionsToTake.push(() =>
          handleCurrentClientChange(nextRoute.clientId!)
        );
        break;
      case "SIGN_IN":
        actionsToTake.push(signUserOut);
        return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};
      default:
        return {actionsToTake : []};
    }
    actionsToTake.push(() => setCurrentRoute(nextRoute));
    return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};
  }

  handleRoutingFromDatabase(nextRoute: RouteData):RoutingResponse{
    const route: AvailableRoutes | undefined = this.getRoute(nextRoute);

    if (!route) return {actionsToTake : []};

    const actionsToTake: Function[] = [];
    switch (route) {
      case "DATABASE_DOCUMENT":
        actionsToTake.push(() =>
          handleCurrentDocumentChange(nextRoute.documentId!)
        );
        break;
      case "DATABASE":
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(nextRoute.databaseId!)
        );
        break;
      case "DATABASE_DASHBOARD":
        actionsToTake.push(() => handleCurrentDatabaseChange(""));
        break;
      case "CLIENT_DASHBOARD":
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(this.clientUserDatabaseId)
        );
        break;
      case "CLIENT":
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(this.clientUserDatabaseId)
        );
        actionsToTake.push(() =>
          handleCurrentClientChange(nextRoute.clientId!)
        );
        break;
      case "SIGN_IN":
        actionsToTake.push(signUserOut);
        return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};
      default:
        return{actionsToTake : []};
    }

    actionsToTake.push(() => setCurrentRoute(nextRoute));
    return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};
  }

  handleRoutingFromDatabaseDashboard(nextRoute: RouteData): RoutingResponse {
    const route: AvailableRoutes | undefined = this.getRoute(nextRoute);

    if (!route) return {actionsToTake : []};

    const actionsToTake: Function[] = [];
    switch (route) {
      case "DATABASE":
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(nextRoute.databaseId!)
        );
        break;
      case "CLIENT_DASHBOARD":
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(this.clientUserDatabaseId)
        );
        break;
      case "CLIENT":
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(this.clientUserDatabaseId)
        );
        actionsToTake.push(() =>
          handleCurrentClientChange(nextRoute.clientId!)
        );
        break;
      case "SIGN_IN":
        actionsToTake.push(signUserOut);
        return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};;
      default:
        return {actionsToTake : []};
    }
    actionsToTake.push(() => setCurrentRoute(nextRoute));
     return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};
  }

  handleRoutingFromClientDashboard(nextRoute: RouteData): RoutingResponse {
    const route: AvailableRoutes | undefined = this.getRoute(nextRoute);

    if (!route) return {actionsToTake : []};

    const actionsToTake: Function[] = [];
    switch (route) {
      case "DATABASE":
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(nextRoute.databaseId!)
        );
        break;
      case "DATABASE_DASHBOARD":
        actionsToTake.push(() => handleCurrentDatabaseChange(""));
        break;
      case "CLIENT":
        actionsToTake.push(() =>
          handleCurrentClientChange(nextRoute.clientId!)
        );
        break;
      case "SIGN_IN":
        actionsToTake.push(signUserOut);
         return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};
      default:
        return {actionsToTake : []};
    }
    actionsToTake.push(() => setCurrentRoute(nextRoute));
     return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};
  }

  handleRoutingFromClient(nextRoute: RouteData): RoutingResponse{
    const route: AvailableRoutes | undefined = this.getRoute(nextRoute);

    if (!route) return {actionsToTake : []};

    if (route !== "CLIENT_DOCUMENT" && this.clientHasChanges) {
      return {actionsToTake : [
        () => setUnsavedClientChanges(true),
        () => setNextRoute(nextRoute),
      ]}
    }

    const actionsToTake: Function[] = [];
    switch (route) {
      case "DATABASE":
        actionsToTake.push(() => handleCurrentClientChange(""));
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(nextRoute.databaseId!)
        );
        break;
      case "DATABASE_DASHBOARD":
        actionsToTake.push(() => handleCurrentClientChange(""));
        actionsToTake.push(() => handleCurrentDatabaseChange(""));
        break;
      case "CLIENT_DASHBOARD":
        actionsToTake.push(() => handleCurrentClientChange(""));
        break;
      case "CLIENT":
        actionsToTake.push(() =>
          handleCurrentClientChange(nextRoute.clientId!)
        );
        break;
      case "CLIENT_DOCUMENT":
        actionsToTake.push(() =>
          handleCurrentDocumentChange(nextRoute.documentId!)
        );
        break;
      case "SIGN_IN":
        actionsToTake.push(signUserOut);
        return {actionsToTake , navigateToRoute : this.getRouteToNavigateTo(nextRoute) }
      default:
        return {actionsToTake : []};
    }
    actionsToTake.push(() => setCurrentRoute(nextRoute));
    return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};
  }

  handleRoutingFromClientDocument(nextRoute: RouteData): RoutingResponse {
    const route: AvailableRoutes | undefined = this.getRoute(nextRoute);

    if (!route) return {actionsToTake : []};

    if (this.documentHasChanges) {
      return {actionsToTake : [
        () => setUnsavedDocumentChanges(true),
        () => setNextRoute(nextRoute),
      ]}
    }

    if (this.clientHasChanges) {
      return {actionsToTake : [
        () => setUnsavedClientChanges(true),
        () => setNextRoute(nextRoute),
      ]}
    }

    const actionsToTake: Function[] = [];
    switch (route) {
      case "DATABASE":
        actionsToTake.push(() => handleCurrentDocumentChange(""));
        actionsToTake.push(() => handleCurrentClientChange(""));
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(nextRoute.databaseId!)
        );
        break;
      case "DATABASE_DASHBOARD":
        actionsToTake.push(() => handleCurrentDocumentChange(""));
        actionsToTake.push(() => handleCurrentClientChange(""));
        actionsToTake.push(() => handleCurrentDatabaseChange(""));
        break;
      case "CLIENT_DASHBOARD":
        actionsToTake.push(() => handleCurrentDocumentChange(""));
        actionsToTake.push(() => handleCurrentClientChange(""));
        break;
      case "CLIENT":
        actionsToTake.push(() => handleCurrentDocumentChange(""));
        actionsToTake.push(() =>
          handleCurrentClientChange(nextRoute.clientId!)
        );
        break;
      case "CLIENT_DOCUMENT":
        actionsToTake.push(() =>
          handleCurrentDocumentChange(nextRoute.documentId!)
        );
        break;
      case "SIGN_IN":
        actionsToTake.push(signUserOut);
         return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)}
      default:
         return {actionsToTake : []}
    }

    actionsToTake.push(() => setCurrentRoute(nextRoute));
       return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};
  }

  handleInitialRoute(nextRoute: RouteData): RoutingResponse {
    const route: AvailableRoutes | undefined = this.getRoute(nextRoute);

    if (!route) return {actionsToTake : []} ;

    const actionsToTake: Function[] = [];

    switch (route) {
      case "CLIENT_DASHBOARD":
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(this.clientUserDatabaseId)
        );
        break;
      case "DATABASE":
        actionsToTake.push(() =>
          handleCurrentDatabaseChange(nextRoute.databaseId!)
        );
        break;
      default:
        return {actionsToTake : []}
    }

    actionsToTake.push(() => setCurrentRoute(nextRoute));
    return {actionsToTake, navigateToRoute : this.getRouteToNavigateTo(nextRoute)};
  }

  getRouteToNavigateTo(nextRoute: RouteData): () => void {
    const route: AvailableRoutes | undefined = this.getRoute(nextRoute);

    if (!route) return () => {};

    switch (route) {
      case "DATABASE_DOCUMENT":
        return () =>
          this.routing.navigateToDatabaseDocument(
            nextRoute.databaseId!,
            nextRoute.documentId!
          );
      case "DATABASE":
        return () => this.routing.navigateToDatabase(nextRoute.databaseId!);
      case "DATABASE_DASHBOARD":
        return () => this.routing.navigateToDatabaseDashboard();
      case "CLIENT_DASHBOARD":
        return () => this.routing.navigateToClientDashboard();
      case "CLIENT":
        return () => this.routing.navigateToClient(nextRoute.clientId!);
      case "CLIENT_DOCUMENT":
        return () =>
          this.routing.navigateToClientDocument(
            nextRoute.clientId!,
            nextRoute.documentId!
          );
      case "SIGN_IN":
        return () => this.routing.navigateToSignIn();
      default:
        return () => {};
    }
  }

  handleRouteChange(
    currentRoute: RouteData | undefined,
    nextRoute: RouteData
  ): RoutingResponse {
    const fromRoute: AvailableRoutes | undefined = this.getRoute(currentRoute);

    if (!fromRoute) {
      return this.handleInitialRoute(nextRoute);
    }

    if (fromRoute) {
      switch (fromRoute) {
        case "DATABASE_DOCUMENT":
          return this.handleRoutingFromDatabaseDocument(nextRoute);
        case "DATABASE":
          return this.handleRoutingFromDatabase(nextRoute);
        case "DATABASE_DASHBOARD":
            return this.handleRoutingFromDatabaseDashboard(nextRoute);
        case "CLIENT_DASHBOARD":
          return  this.handleRoutingFromClientDashboard(nextRoute);
        case "CLIENT":
          return this.handleRoutingFromClient(nextRoute);
        case "CLIENT_DOCUMENT":
          return this.handleRoutingFromClientDocument(nextRoute);
        default:
          break;
      }
    }

    return {actionsToTake : []}
  }
}

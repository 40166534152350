import { Action } from "redux";

export const SET_CURRENT_TAB = "SET_CURRENT_TAB";

export interface IActionsSetCurrentTab extends Action {
  type: "SET_CURRENT_TAB";
  index: number;
}

export type DocumentScreenActions = IActionsSetCurrentTab;

import { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

import { Nutrient } from "../../../../../data/models/nutrient";
import { Profile } from "../../../../../data/models/userDatabase";
import { enabledNutrientsSelector } from "../../../../../store/data/selectors/databaseProperties";
import { NutrientsDataSelector } from "../../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../../store/reducers";

const useNutrientsToDisplay = (): [
  (profile: Profile | undefined) => void,
  [string, Nutrient][]
] => {
  const enabledNutrients: string[] = useSelector<RootState, string[]>(
    enabledNutrientsSelector
  );

  const [nutrientProfile, setNutrientProfile] =
    useState<Profile | undefined>(undefined);

  const nutrients: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(NutrientsDataSelector);

  const [filteredNutrients, setFilteredNutrients] = useState<
    [string, Nutrient][]
  >([]);

  useEffect(() => {
    setFilteredNutrients(
      !nutrientProfile
        ? [...nutrients].filter(([id]: [string, Nutrient]) =>
            enabledNutrients.includes(id)
          )
        : [...nutrients].filter(([id]: [string, Nutrient]) =>
            _.intersection(nutrientProfile.nutrients, [
              ...enabledNutrients,
            ]).includes(id)
          )
    );
  }, [nutrientProfile, enabledNutrients, nutrients]);

  const setNutrientProfileCallback = useCallback(setNutrientProfile, [
    setNutrientProfile,
  ]);

  return [setNutrientProfileCallback, filteredNutrients];
};

export default useNutrientsToDisplay;

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setClientNotes } from "../../../../../../store/data/current_client/action_creators/client";
import { clientNotesSelector } from "../../../../../../store/data/current_client/selectors/client";
import { RootState } from "../../../../../../store/reducers";

const useClientNotes = (): [string, (input: string) => void, () => void] => {
  const dispatch = useDispatch();

  const notes: string = useSelector<RootState, string>(clientNotesSelector);

  const [input, setInput] = useState(notes);

  const onSetClientNotes = useCallback(() => dispatch(setClientNotes(input)), [
    dispatch,
    input,
  ]);

  useEffect(() => {
    setInput(notes);
  }, [notes]);

  return [input, setInput, onSetClientNotes];
};

export default useClientNotes;

import React, { FunctionComponent, ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import DocumentNavigationPane from "../navigation/document/DocumentNavigation";
import { OpenDatabase } from "./OpenDatabase";
import { RootState } from "../../../store/reducers";
import { currentRouteSelector } from "../../../store/ui/selectors/routing";
import DatabaseDashboard from "./dashboard/Dashboard";
import { RouteData } from "../../../data/routing/types";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
}));

export const Databases: FunctionComponent = () => {
  const classes = useStyles();

  const currentRoute: RouteData | undefined = useSelector<
    RootState,
    RouteData | undefined
  >(currentRouteSelector);

  const openDatabase: ReactNode = (
    <>
      <DocumentNavigationPane />
      <OpenDatabase />
    </>
  );

  const createDatabaseScreen = (): ReactNode => {
    if (
      currentRoute &&
      currentRoute?.screen === "databases" &&
      currentRoute.databaseId === "dashboard"
    ) {
      return <DatabaseDashboard />;
    }

    return openDatabase;
  };

  return <div className={classes.content}>{createDatabaseScreen()}</div>;
};

import React from "react";
import {
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  skinnyAvatar: {
    minWidth: 40,
    display: "flex",
    alignItems: "center",
  },
}));

interface AppBarMenuItemProps {
  id: string;
  onClick: () => void;
  label: string;
  lastModifiedDate: string;
  icon: JSX.Element;
}

// Unsure why I had to wrap this in a forward Ref, but it solves error being thrown?
const AppBarMenuItem = React.forwardRef(
  ({ id, onClick, label, icon, lastModifiedDate }: AppBarMenuItemProps, _) => {
    const classes = useStyles();

    return (
      <MenuItem
        button
        dense
        onClick={onClick}
        key={id}
        data-cy={`${label.split(" ").join("")}`}
      >
        <ListItemAvatar className={classes.skinnyAvatar}>{icon}</ListItemAvatar>
        <ListItemText
          primary={label}
          primaryTypographyProps={{ variant: "body1" }}
          secondary={`Modified:
            ${new Date(lastModifiedDate).toLocaleDateString()}`}
          secondaryTypographyProps={{ variant: "caption" }}
        />
      </MenuItem>
    );
  }
);

export default AppBarMenuItem;

import {
  FoodItemsActions,
  ADD_FOOD_ITEM,
  CLEAR_FOOD_ITEM,
  REMOVE_FOOD_ITEM,
  UPDATE_QUANTITY,
  CLEAR_QUANTITY,
  UPDATE_NOTE,
  UPDATE_FOOD_ITEM,
  UPDATE_RETENTION_FACTOR,
  CLEAR_RETENTION_FACTOR,
  REMOVE_MULTIPLE_FOOD_ITEMS,
} from "../actions/foodItems";
import { FoodItemState } from "../../../../data/models/documentProperties/foodItem";

const initialState: FoodItemState[] = [];

export const foodItems = (
  state: FoodItemState[] = initialState,
  action: FoodItemsActions
): FoodItemState[] => {
  switch (action.type) {
    case ADD_FOOD_ITEM:
      return [
        ...state.slice(0, action.foodItem.rowIndex),
        action.foodItem.object,
        ...state.slice(action.foodItem.rowIndex).map(
          (item: FoodItemState): FoodItemState => {
            return { ...item, rowIndex: item.rowIndex + 1 };
          }
        ),
      ];

    case CLEAR_FOOD_ITEM:
      return state.map(
        (item: FoodItemState): FoodItemState =>
          item.rowIndex === action.rowIndex
            ? {
                ...item,
                quantity: undefined,
                note: "",
                retentionFactorId: null,
                foodId: undefined,
              }
            : item
      );
    case UPDATE_FOOD_ITEM:
      return state.map(
        (item: FoodItemState): FoodItemState =>
          item.rowIndex === action.foodItem.rowIndex
            ? action.foodItem.object
            : item
      );
    case REMOVE_FOOD_ITEM:
      return state.reduce<FoodItemState[]>((acc, value, index) => {
        if (index !== action.rowIndex) {
          acc.push({ ...value, rowIndex: acc.length });
        }
        return acc;
      }, []);
    case UPDATE_QUANTITY:
      return state.map(
        (item: FoodItemState): FoodItemState =>
          item.rowIndex !== action.rowIndex
            ? item
            : {
                ...item,
                quantity: {
                  amount: action.quantity.amount,
                  measureId: action.quantity.measureId,
                },
              }
      );
    case CLEAR_QUANTITY:
      return state.map(
        (item: FoodItemState): FoodItemState =>
          item.rowIndex !== action.rowIndex
            ? item
            : { ...item, quantity: undefined }
      );
    case UPDATE_NOTE:
      return state.map(
        (item: FoodItemState): FoodItemState =>
          item.rowIndex !== action.rowIndex
            ? item
            : { ...item, note: action.note }
      );
    case UPDATE_RETENTION_FACTOR:
      return state.map(
        (item: FoodItemState): FoodItemState =>
          item.rowIndex !== action.rowIndex
            ? item
            : {
                ...item,
                retentionFactorId: action.id,
              }
      );

    case CLEAR_RETENTION_FACTOR:
      return state.map(
        (item: FoodItemState): FoodItemState =>
          item.rowIndex !== action.rowIndex
            ? item
            : { ...item, retentionFactorId: null }
      );
    case REMOVE_MULTIPLE_FOOD_ITEMS:
    default:
      return [...state];
  }
};

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  isFoodRecord,
  isMealPlan,
} from "../../../../../../../constants/FoodTemplate";
import { FoodItemPosition } from "../../../../../../../data/models/foodItemPosition";
import { templateIdSelector } from "../../../../../../../store/data/current-document/selectors/document";
import { RootState } from "../../../../../../../store/reducers";
import { updateSelectedAmount } from "../../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import { setSelectedRows } from "../../../../../../../store/ui/actionCreators/recipeGrid";
import { NutritionRadioOption } from "../../../../../../../store/ui/reducers/nutritionPaneReducers";
import { compositionOptionSelector } from "../../../../../../../store/ui/selectors/nutritionPaneSelectors";

const useUpdateSelection = () => {
  const dispatch = useDispatch();

  const templateId = useSelector<RootState, string>(templateIdSelector);

  const compositionOption = useSelector<RootState, NutritionRadioOption>(
    compositionOptionSelector
  );

  const updateSelection = useCallback(
    (selectedRows: FoodItemPosition[]) => {
      if (
        (isMealPlan(templateId) || isFoodRecord(templateId)) &&
        selectedRows.length
      ) {
        if (compositionOption === NutritionRadioOption.DAY) {
          dispatch(updateSelectedAmount(NutritionRadioOption.TOTAL));
        }
      }
      dispatch(setSelectedRows(selectedRows));
    },
    [templateId, compositionOption, dispatch]
  );

  return updateSelection;
};

export default useUpdateSelection;

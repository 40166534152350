import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import FoodworksDateTimePicker from "../../../../../common/date/FoodworksDateTimePicker";

export const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    gap : "0.5rem",
    padding: 10,
  },
}));

interface Props {
  min: Date;
  setMin: (date: Date) => void;
  max: Date;
  setMax: (date: Date) => void;
}

const TrackedDateRange = React.memo((props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
        <Typography>from </Typography>
        <FoodworksDateTimePicker
          date={props.min}
          handleChange={(date: MaterialUiPickersDate) =>
            props.setMin(new Date(date!.toISOString()))
          }
        />
        <Typography >to </Typography>
        <FoodworksDateTimePicker
          date={props.max}
          handleChange={(date: MaterialUiPickersDate) =>
            props.setMax(new Date(date!.toISOString()))
          }
        />
    </div>
  );
});

export default TrackedDateRange;

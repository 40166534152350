import React, { Fragment } from "react";
import { Divider, makeStyles, Theme, Typography } from "@material-ui/core";

import { FoodworksTooltip } from "../InfoTooltip";

export const useStyles = makeStyles<Theme, BarProps>((theme: Theme) => ({
  "@keyframes grow": {
    "0%": {
      transform: "scaleX(0)",
    },
    "100%": {
      transform: "scaleX(1)",
    },
  },
  "@keyframes percentSlide": {
    "0%": {
      transform: "translateX(-50%)",
    },
    "100%": {
      transform: "translateX(0%)",
    },
  },
  grow: {
    animationName: "$grow",
    animationDuration: "0.8s",
    animationFillMode: "forwards",
  },
  percentSlide: {
    animationName: "$percentSlide",
    animationDuration: "0.8s",
    animationFillMode: "forwards",
  },
  root: {
    marginTop: 0,
    padding: 0,
    display: "flex",
    width: "100%",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  bar: {
    transformOrigin: "top left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  labels: {
    display: "flex",
    whiteSpace: "nowrap",
    overflow: "hidden",
    margin: 0,
  },
  title: {
    display: "flex",
    overflow: "hidden",
    marginRight: 5,
    justifyContent: (props) =>
      props.percentLocation === "left" ? "flex-start" : "flex-end",
    width: (props) => props.labelWidth,
  },
  barContainer: {
    display: "flex",
    background:
      "repeating-linear-gradient(to right, #F2F2F7 ,#F2F2F7 1%,transparent 2%,transparent 25%)",
    width: "100%",
  },
  percentContainerLeft: {
    display: "flex",
    //justifyContent: 'flex-end',
    flexDirection: "column",
    alignItems: "flex-end",
    marginRight: 5,
    width: 50,
  },
  percentContainerRight: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginLeft: 5,
    width: 50,
  },
  divide: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

export interface BarProps {
  label: string;
  percentage: string;
  value: string;
  barWidth: string;
  barColor: string;
  labelWidth?: string;
  maxPercent?: number;
  percentLocation?: "right" | "left";
  titleDivide?: boolean;
}

const Bar = React.memo((props: BarProps) => {
  const classes = useStyles(props);

  const percentage =
    Number(props.percentage.slice(0, props.percentage!.length - 1)) /
      (props.maxPercent! / 100) +
    "%";
  return (
    <Fragment>
      <div key={props.label} className={classes.root}>
        <div className={classes.title}>
          <FoodworksTooltip title={props.label}>
            <Typography noWrap>{props.label}</Typography>
          </FoodworksTooltip>
        </div>
        {props.percentLocation === "left" && (
          <div className={classes.percentContainerLeft}>
            <FoodworksTooltip title={props.percentage}>
              <Typography noWrap>{props.percentage}</Typography>
            </FoodworksTooltip>
            <Divider />
          </div>
        )}

        <div className={classes.divide}>
          <div className={`${classes.barContainer} `}>
            <div
              key={props.percentage}
              className={`${classes.bar} ${classes.grow}`}
              style={{
                width: percentage,
                height: props.barWidth,
                backgroundColor: props.barColor,
              }}
            ></div>
            {props.percentLocation === "right" && (
              <div
                key={percentage + props.label}
                className={`${classes.percentContainerRight} ${classes.percentSlide}`}
              >
                <FoodworksTooltip title={props.percentage}>
                  <Typography noWrap>{props.percentage}</Typography>
                </FoodworksTooltip>
              </div>
            )}
          </div>
          {!props.titleDivide && <Divider />}
        </div>
      </div>
      {props.titleDivide && <Divider />}
    </Fragment>
  );
});

export default Bar;

import React, { ReactNode, useCallback, useState } from "react";
import {
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ArrowForward, Delete, FileCopy } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { FoodWorksDate } from "../../../../../data/models/documentProperties/date";
import duplicateDatabase from "../../../../../store/data/thunks/database/duplicateDatabase";
import { appTheme } from "../../../../../styling/style";
import {
  DisabledButtonWithToolTip,
  FoodworksTooltip,
} from "../../../../common/InfoTooltip";
import { DeleteDatabaseDialog } from "../../../../dialogs/database/DeleteDatabaseDialog";
import { handleRouteChange } from "../../../../../store/ui/thunks/routing";
import { getDatabaseRouteData } from "../../../../../data/routing/routing";
import { userDatabaseSummariesSelector } from "../../../../../store/data/selectors/user";
import { UserDatabaseSummary } from "../../../../../data/models/userDatabase";
import { RootState } from "../../../../../store/reducers";

const useStyles = makeStyles(() => ({
  iconButtonCopy: {
    flex: 1,
    width: 40,
    height: 40,
    margin: "5px 0px",
    color: appTheme.colors.primary,
  },
  iconButtonDelete: {
    flex: 1,
    width: 40,
    height: 40,
    margin: "5px 0px",
    color: appTheme.colors.error,
  },
}));

interface TableRowProps {
  name: string;
  date: FoodWorksDate;
  id: string;
}

interface IconButtonWithToolTipProps {
  tooltip: string;
  onClick: () => void;
  children?: ReactNode;
  dataCy?: string;
  disabled?: boolean;
}

const IconButtonWithToolTip = ({
  tooltip,
  onClick,
  children,
  dataCy,
  disabled = false,
}: IconButtonWithToolTipProps): JSX.Element => {
  const classes = useStyles();

  return (
    <FoodworksTooltip title={tooltip}>
      <IconButton
        disabled={disabled}
        data-cy={dataCy}
        className={classes.iconButtonCopy}
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        {children}
      </IconButton>
    </FoodworksTooltip>
  );
};

const getLocalDate = (date: string): string =>
  new Date(date).toLocaleDateString();

export const DatabaseTableRow = (props: TableRowProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [toggleDialog, setToggle] = useState(false);
  const onDuplicateDatabase = () => dispatch(duplicateDatabase(props.id));

  const onChangeCurrentDatabase = useCallback(
    () => dispatch(handleRouteChange(getDatabaseRouteData(props.id))),
    [dispatch, props.id]
  );

  const databaseSummaries: UserDatabaseSummary[] = useSelector<
    RootState,
    UserDatabaseSummary[]
  >(userDatabaseSummariesSelector);

  return (
    <>
      <TableRow key={props.name}>
        <TableCell component="th" scope="row">
          <Typography variant="h2">{props.name}</Typography>
        </TableCell>
        <TableCell>{getLocalDate(props.date.created)}</TableCell>
        <TableCell>{getLocalDate(props.date.lastModified)}</TableCell>
        <TableCell>
          <IconButtonWithToolTip
            onClick={onDuplicateDatabase}
            tooltip="Create Copy"
          >
            <FileCopy />
          </IconButtonWithToolTip>
        </TableCell>
        <TableCell>
          <DisabledButtonWithToolTip
            disabled={databaseSummaries.length <= 2}
            disabledTooltip="Cannot delete last database"
            enabledTooltip="Delete"
            label={<Delete />}
            buttonProps={{
              onClick: () => setToggle(true),
              className: classes.iconButtonCopy,
            }}
          />
        </TableCell>
        <TableCell>
          <IconButtonWithToolTip
            onClick={onChangeCurrentDatabase}
            tooltip="Open database"
          >
            <ArrowForward />
          </IconButtonWithToolTip>
        </TableCell>
      </TableRow>
      {toggleDialog && (
        <DeleteDatabaseDialog
          open
          databaseId={props.id}
          databaseName={props.name}
          onClose={() => setToggle(false)}
        />
      )}
    </>
  );
};

export default DatabaseTableRow;

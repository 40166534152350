import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

import ClientsMenu from "./ClientsMenu";
import { RootState } from "../../../../store/reducers";
import { currentRouteSelector } from "../../../../store/ui/selectors/routing";
import { appTheme } from "../../../../styling/style";
import { RouteData } from "../../../../data/routing/types";

const useStyles = makeStyles(() => ({
  dropdownButton: { marginLeft: 10, marginTop: 7 },
  highlighted: {
    backgroundColor: appTheme.colors.oceanBlue[0],
  },
  buttonStyle: { textTransform: "none" },
}));

const ClientsButton = (): JSX.Element => {
  const classes = useStyles();

  const currentRoute: RouteData | undefined =
    useSelector<RootState, RouteData | undefined>(currentRouteSelector);

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  return (
    <>
      <Button
        className={
          currentRoute?.screen === "clients"
            ? `${classes.dropdownButton} ${classes.highlighted}`
            : classes.dropdownButton
        }
        endIcon={<ArrowDropDown />}
        color="secondary"
        size="small"
        onClick={(event) => setAnchor(event.currentTarget)}
      >
        <Typography variant="h3" className={classes.buttonStyle}>
          Clients
        </Typography>
      </Button>
      <ClientsMenu anchorElement={anchor} onClose={() => setAnchor(null)} />
    </>
  );
};

export default ClientsButton;

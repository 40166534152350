import React, { useState } from "react";

import { OpenDialogButton } from "../../../common/OpenDialogButton";
import { ClientTagDialog } from "./ClientTagDialog";

export const ManageClientTagsButton = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <OpenDialogButton
        cypressTag="manageClientTagsButton"
        onClick={() => setOpen(!open)}
        label="Manage client tags"
      />
      <ClientTagDialog open={open} onClose={() => setOpen(!open)} />
    </>
  );
};

import { CSSProperties } from "@material-ui/styles";

export const TABLE_COLUMN_WIDTHS = {
  NAME: 300,
  NUMERIC: 80,
  OVERRIDE: 80,
  NOTE: 100,
};

export const CELL_PADDING = 5;

type Columns = "numeric" | "name" | "override" | "noteInput";

export const TABLE_COLUMN_STYLES: { [key in Columns]: CSSProperties } = {
  numeric: {
    flex: `0 0 auto`,
    width: TABLE_COLUMN_WIDTHS.NUMERIC,
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: CELL_PADDING,
    textAlign: "right",
  },
  name: {
    flex: `0 0 auto`,
    width: TABLE_COLUMN_WIDTHS.NAME,
    padding: CELL_PADDING,
    display: "flex",
  },
  override: {
    flex: `0 0 auto`,
    width: TABLE_COLUMN_WIDTHS.OVERRIDE,
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: CELL_PADDING,
    boxSizing: "content-box",
    textAlign: "right",
  },
  noteInput: {
    flex: `1 1 ${TABLE_COLUMN_WIDTHS.NOTE}px`,
    padding: CELL_PADDING,
    minWidth: TABLE_COLUMN_WIDTHS.NOTE,
    boxSizing: "content-box",
  },
};

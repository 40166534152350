import React, { FunctionComponent, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { batch, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addCommonMeasure } from "../../../../../../store/data/current-document/action-creators/commonMeasures";
import { Document } from "../../../../../../data/models/document";
import { fetchDocument } from "../../../../../../data/Firebase/helpers/fetchDocument";
import Firebase, { withFirebase } from "../../../../../../data/Firebase";
import { FoodWorksDialog } from "../../../../../common/FoodWorksDialog";
import { IngredientSummaryItem } from "../ingredients/editing_grid/rows/cells/IngredientCell";
import { CommonMeasure } from "../../../../../../data/models/documentProperties/measure";
import {
  createSearchMap,
  filterIngredients,
  filterOptions,
} from "../ingredients/editing_grid/rows/cells/ingredientUtils";
import { appTheme } from "../../../../../../styling/style";
import {
  AutoCompleteCell,
  GridCellType,
} from "../ingredients/editing_grid/rows/cells/AutoCompleteCell";
import { initialQuickFilterState } from "../../../../../../constants/QuickFilter";
import { VolumeConversionObject } from "../../../../../../data/models/documentProperties/volumeConversionFactor";
import { updateVolumeConversion } from "../../../../../../store/data/current-document/action-creators/document";
import { BaseDialog } from "../../../../../dialogs/BaseDialog";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
  },
  autoCompleteWrapper: {
    display: "flex",
    flex: 1,
    background: appTheme.colors.input,
    border: `1px solid ${appTheme.colors.gainsbro}`,
    "&:hover": {
      background: appTheme.colors.inputHover,
    },
    borderRadius: 4,
    height: 30,
    marginLeft: 20,
  },
  listItemText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "none",
  },
  measureLabel: {
    minWidth: 140,
  },
  autocomplete: {
    display: "flex",
    alignItems: "center",
    minWidth: 100,
  },
  listButton: {
    borderRadius: 4,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
    padding: "0px",
    alignItems: "center",
  },
  selectedListButton: {
    borderRadius: 4,
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
  },
}));

export interface MeasuresSearchProps {
  combinedSummaries: IngredientSummaryItem[];
  firebase?: Firebase;
}

const MeasuresSearchInner: FunctionComponent<MeasuresSearchProps> = ({
  combinedSummaries,
  firebase,
}) => {
  const classes = useStyles();

  // *** Actions ***
  const dispatch = useDispatch();

  const onMeasureAdded = (measure: CommonMeasure) =>
    dispatch(
      addCommonMeasure({
        id: uuidv4(),
        name: measure.name,
        value: measure.value,
        description: measure.description,
        usedIn: [],
      })
    );

  const onVolumeAdded = (volume: VolumeConversionObject) =>
    dispatch(updateVolumeConversion(volume));
  // *** ----- ***

  // *** State ***

  const [searchInput, setSearchInput] = useState<string>("");
  const [lastSelectedMeasure, setLastSelectedMeasure] = useState("");
  const [document, setDocument] = useState<Document | null>(null);
  const [mappedDocument, setMappedDocument] = useState<Document | null>(null);
  const [copyVolumeChecked, setCopyVolumeChecked] = useState<boolean>(false);
  // *** ----- ***

  /**
   * Create preprocessed data for searching
   */
  const [searchTermMap, setSearchTermMap] = useState(
    new Map<string, string[]>()
  );

  const ref: React.RefObject<HTMLInputElement> =
    React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchTermMap(createSearchMap(combinedSummaries));
  }, [combinedSummaries]);

  const summaries = filterIngredients(
    {
      searchText: searchInput.toLowerCase(),
      quickFilters: initialQuickFilterState,
    },
    combinedSummaries,
    searchTermMap
  ) as IngredientSummaryItem[];

  const handleAdd = () => {
    batch(() => {
      document?.commonMeasures.measures.forEach(
        (measure: CommonMeasure): void => {
          onMeasureAdded(measure);
        }
      );
      mappedDocument?.commonMeasures.measures.forEach(
        (measure: CommonMeasure): void => {
          onMeasureAdded(measure);
        }
      );
    });

    const volumeConversion: VolumeConversionObject | undefined =
      document?.volumeConversion;

    if (!!volumeConversion && copyVolumeChecked) {
      onVolumeAdded(volumeConversion);
    }

    setDocument(null);
    setMappedDocument(null);
  };

  const validDocumentDialog = (
    <BaseDialog
      open={document !== null && document?.commonMeasures.measures.length > 0}
      onClose={() => setDocument(null)}
      title={`Copy measures from ${lastSelectedMeasure}`}
      maxWidth="sm"
      body={[
        <Typography>Are you sure you want to add these measures?</Typography>,
        !!document?.volumeConversion.volumeG &&
          !!document?.volumeConversion.volumeMl && (
            <ListItem
              key="Copy Volume Conversion"
              button
              onClick={() => setCopyVolumeChecked(!copyVolumeChecked)}
              className={
                copyVolumeChecked
                  ? `${classes.selectedListButton} ${classes.listButton}`
                  : `${classes.listButton}`
              }
            >
              <Checkbox checked={copyVolumeChecked} />
              <Typography>Copy volume conversion</Typography>
            </ListItem>
          ),
      ]}
      action={[
        <Button color="default" onClick={() => setDocument(null)}>
          Cancel
        </Button>,
        <Button color="secondary" onClick={() => handleAdd()}>
          Add
        </Button>,
      ]}
    />
  );

  const invalidDocumentDialog = (
    <FoodWorksDialog
      open={document !== null && document?.commonMeasures.measures.length === 0}
      onClose={() => setDocument(null)}
      title={`${lastSelectedMeasure} contains no common measures`}
      description="The document you selected does not contain any non-default common
      measures, so we can't add any!"
      options={[
        {
          text: "Ok",
          onClick: () => setDocument(null),
          color: "secondary",
        },
      ]}
    />
  );

  const onListItemClicked = async (newValue: GridCellType): Promise<void> => {
    if (newValue && "foodId" in newValue) {
      setLastSelectedMeasure(newValue.label);
      const document: Document = await fetchDocument(
        firebase!,
        newValue.foodId,
        newValue.isPublic
      );
      setDocument(document);
      if (document.documentMappingId) {
        const childFoodId = combinedSummaries.find(
          (ingredient: IngredientSummaryItem) =>
            ingredient.foodId.documentId === document.documentMappingId
        );
        const childDocument: Document = await fetchDocument(
          firebase!,
          childFoodId!.foodId,
          true
        );
        setMappedDocument(childDocument);
      }
    }
  };

  const renderItem = (option: GridCellType) => {
    return <Typography>{option.label}</Typography>;
  };

  const autocomplete = (
    <div className={classes.autocomplete}>
      <Typography variant="body1" className={classes.measureLabel}>
        Copy measures from
      </Typography>
      <div className={classes.autoCompleteWrapper}>
        <AutoCompleteCell
          onFocus={() => {}}
          onBlur={() => setSearchInput("")}
          onInputChange={(event, value, reason) =>
            reason === "input" ? setSearchInput(value) : setSearchInput("")
          }
          ref={ref}
          filterOptions={(options: GridCellType[]) =>
            filterOptions(options, searchInput, searchTermMap)
          }
          useSetSize={false}
          placeholder={"Select food item..."}
          cellSize={150}
          initialInput={searchInput || ""}
          onSelect={onListItemClicked}
          items={summaries}
          renderOption={renderItem}
        />
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {autocomplete}
      {validDocumentDialog}
      {invalidDocumentDialog}
    </div>
  );
};

export const MeasuresSearch = withFirebase(MeasuresSearchInner);

import React from "react";
import { IconButton } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { toggleClientMeasurement } from "../../../../../../../store/data/current_client/action_creators/clientMeasurements";
import { FoodworksTooltip } from "../../../../../../common/InfoTooltip";

interface Props {
  measurementTitle: string;
  measurementUnits: string;
}

const RemoveMeasurementButton = (props: Props): JSX.Element => {
  const { measurementTitle, measurementUnits } = { ...props };

  const dispatch = useDispatch();

  const onToggleClientMeasurement = () =>
    dispatch(toggleClientMeasurement(measurementTitle, measurementUnits));

  return (
    <FoodworksTooltip title="Hide measurement">
      <IconButton
        size="small"
        color="inherit"
        onClick={onToggleClientMeasurement}
      >
        <Remove />
      </IconButton>
    </FoodworksTooltip>
  );
};

export default RemoveMeasurementButton;

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import FoodworksSelect from "../../../../../common/FoodworksSelect";
import { measurementSortOptions } from "../../../../../../constants/measurements";

const useStyles = makeStyles(() => ({
  sortButton: {
    overflow: "hidden",
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "start",
    paddingLeft: 15,
    paddingTop: 6,
    paddingBottom: 6,
  },
  inline: {
    display: "inline",
  },
}));

interface SortMeasurementListProps {
  sortOption: string;
  setSortOption: (option: string) => void;
}

const SortMeasurementsList = React.memo<SortMeasurementListProps>(
  (props: SortMeasurementListProps): JSX.Element => {
    const classes = useStyles();

    const { sortOption, setSortOption } = { ...props };

    return (
      <>
        <Typography className={classes.inline}>Sort by </Typography>
        <FoodworksSelect
          onChange={setSortOption}
          value={sortOption}
          options={measurementSortOptions}
        />
      </>
    );
  }
);

export default SortMeasurementsList;

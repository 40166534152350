import React, { ReactNode } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { NavigationTypes } from "../../../../types";

const useStyles = makeStyles(() => ({
  noItemsIcon: { width: 50, height: 50 },
  noItemsDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: 25,
  },
}));

interface NavigationPaneMessageProps {
  isLoading: boolean;
  deletedDocumentFilterApplied: boolean;
  hasNonDeletedDocuments: boolean;
  hasDocumentsToDisplay: boolean;
  itemType: NavigationTypes.CreateItemButtonType;
}

const NavigationPaneMessage = ({
  isLoading,
  deletedDocumentFilterApplied,
  hasNonDeletedDocuments,
  hasDocumentsToDisplay,
  itemType,
}: NavigationPaneMessageProps): JSX.Element => {
  const classes = useStyles();

  const getMessageToDisplay = (
    isLoading: boolean,
    deletedDocumentFilterApplied: boolean,
    hasNonDeletedDocuments: boolean,
    hasDocumentsToDisplay: boolean
  ): ReactNode => {
    if (!isLoading) {
      if (!deletedDocumentFilterApplied && !hasNonDeletedDocuments) {
        return (
          <div className={classes.noItemsDiv}>
            <Typography variant="h5" data-cy="noItemsFound">
              Save your first {itemType} to view it here in the navigation pane.
            </Typography>
          </div>
        );
      }
      if (!hasDocumentsToDisplay) {
        return (
          <div className={classes.noItemsDiv}>
            <Search className={classes.noItemsIcon} />
            <Typography variant="h5" data-cy="noItemsFound">
              We couldn't find anything matching your search.
            </Typography>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <>
      {getMessageToDisplay(
        isLoading,
        deletedDocumentFilterApplied,
        hasNonDeletedDocuments,
        hasDocumentsToDisplay
      )}
    </>
  );
};

export default NavigationPaneMessage;

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RouteData } from "../../../../data/routing/types";
import { RootState } from "../../../../store/reducers";
import { setSelectedClientDocuments } from "../../../../store/ui/actionCreators/clientScreen";
import { setNutritionPaneDimensions } from "../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import { selectedClientDocumentsSelector } from "../../../../store/ui/selectors/clientScreen";
import { currentRouteSelector } from "../../../../store/ui/selectors/routing";
import { useIsClientScreen } from "./useClientScreen";

/**
 * Set nutrition pane width to 0 due to interaction with Documents Tab
 * Documents tab screen wants to initially be resized to full screen
 */
const useAutoResetNutrititionPane = (): void => {
  const dispatch = useDispatch();

  const clientSelectedDocuments: string[] = useSelector<RootState, string[]>(
    selectedClientDocumentsSelector
  );

  const currentRoute: RouteData | undefined =
    useSelector<RootState, RouteData | undefined>(currentRouteSelector);

  const isClientScreen = useIsClientScreen();

  const onResetNutritionPane = useCallback(() => {
    dispatch(setNutritionPaneDimensions({ height: "auto", width: 0 }));
  }, [dispatch]);

  const onResetSelectedClientDocuments = useCallback(() => {
    dispatch(setSelectedClientDocuments([]));
  }, [dispatch]);

  useEffect(() => {
    if (clientSelectedDocuments.length === 0) onResetNutritionPane();
  }, [clientSelectedDocuments, onResetNutritionPane]);

  useEffect(() => {
    if (!currentRoute?.documentId && isClientScreen) {
      onResetNutritionPane();
      onResetSelectedClientDocuments();
    }
  }, [
    onResetNutritionPane,
    currentRoute,
    isClientScreen,
    onResetSelectedClientDocuments,
  ]);

  useEffect(() => {
    onResetNutritionPane();
  }, [onResetNutritionPane]);
};

export default useAutoResetNutrititionPane;

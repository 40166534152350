import { batch } from "react-redux";
import { ThunkAction } from "redux-thunk";

import { Client, ClientSummary } from "../../../data/models/client";
import { getCurrentDate } from "../../../data/models/documentProperties/date";
import { FoodId } from "../../../data/models/documentProperties/foodId";
import { Tag } from "../../../data/models/documentProperties/section";
import { DocumentSummary } from "../../../data/models/userDatabase";
import { RootState } from "../../reducers";
import { ExtraArguments } from "../../store";
import {
  setClientDatabaseTags,
  setClientId,
  setClientSummaries,
  setCurrentClient,
  setServerClient,
} from "../action-creators/clientDatabase";
import {
  IActionsSetClientSummaries,
  IActionsSetCurrentClient,
  IActionsSetServerClient,
  IActionsSetClientId,
  IActionsSetClientDatabaseTags,
} from "../actions/clientDatabase";
import { getDocument } from "../current-document/thunks/getDocument";
import { initialClientState } from "../current_client/reducers/client";
import { TEMPORARY_CLIENT } from "../reducers/clientDatabase";

const setCurrentDate = (client: Client, date: string): Client => ({
  ...client,
  date: { lastModified: date, created: date },
});
const getClientDocumentSummaries = (
  client: Client,
  summaries: DocumentSummary[]
): DocumentSummary[] =>
  summaries.filter((summary: DocumentSummary) =>
    client.documents.includes(summary.documentId)
  );

export const updateClientSummaries =
  (
    summaries: ClientSummary[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetClientSummaries
  > =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string =
      await firebase.userPermissions.doGetUserClientDatabase(
        firebase.auth.currentUser!.uid
      );

    await firebase.clientDatabases.doUpdateClientSummaries(
      currentDatabaseId,
      summaries
    );

    dispatch(setClientSummaries(summaries));
  };

export const fetchClientDocuments =
  (
    clientDatabaseId: string,
    client: Client
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetCurrentClient | IActionsSetServerClient | IActionsSetClientId
  > =>
  async (dispatch, _, { firebase }) =>
    firebase.clientDatabases
      .doGetUserClientDatabase(clientDatabaseId)
      .then((clientDatabase) =>
        firebase.userDatabases
          .doGetUserDatabase(clientDatabase.databaseId)
          .then((database) =>
            getClientDocumentSummaries(
              client,
              database.documentSummaries
            ).forEach((summary: DocumentSummary) =>
              dispatch(
                getDocument(
                  new FoodId({
                    datasourceId: clientDatabase.databaseId,
                    documentId: summary.documentId,
                  }),
                  false,
                  false
                )
              )
            )
          )
      );

export const handleCurrentClientChange =
  (
    clientId: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetCurrentClient | IActionsSetServerClient | IActionsSetClientId
  > =>
  async (dispatch, _, { firebase }) => {
    if (!clientId) {
      batch(() => {
        dispatch(setClientId(""));
        dispatch(setCurrentClient(initialClientState));
        dispatch(setServerClient(undefined));
      });
      return;
    }

    if (clientId === TEMPORARY_CLIENT) {
      const currentDate = getCurrentDate();
      batch(() => {
        dispatch(setClientId(TEMPORARY_CLIENT));
        dispatch(
          setCurrentClient(setCurrentDate(initialClientState, currentDate))
        );
        dispatch(
          setServerClient(setCurrentDate(initialClientState, currentDate))
        );
      });
      return;
    }

    const uid: string = firebase.auth.currentUser!.uid;

    const clientDatabaseId: string =
      await firebase.userPermissions.doGetUserClientDatabase(uid);

    const client: Client = await firebase.clientDatabases.doGetClient(
      clientDatabaseId,
      clientId
    );

    await dispatch(fetchClientDocuments(clientDatabaseId, client));

    batch(() => {
      dispatch(setClientId(clientId));
      dispatch(setCurrentClient(client));
      dispatch(setServerClient(client));
    });
  };

export const updateClientDatabaseTags =
  (
    tags: Tag[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetClientDatabaseTags
  > =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string =
      await firebase.userPermissions.doGetUserClientDatabase(
        firebase.auth.currentUser!.uid
      );

    await firebase.clientDatabases.doUpdateClientDatabaseTags(
      currentDatabaseId,
      tags
    );

    dispatch(setClientDatabaseTags(tags));
  };

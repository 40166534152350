import React, { ReactNode } from "react";
import {
  Button,
  List,
  ListSubheader,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useSelector } from "react-redux";

import { Day } from "../../../../data/models/documentProperties/day";
import { DaySummaryListItem } from "./DaySummaryListItem";
import { RootState } from "../../../../store/reducers";
import { templateIdSelector } from "../../../../store/data/current-document/selectors/document";
import { isMealPlan } from "../../../../constants/FoodTemplate";

const useStyles = makeStyles((theme: Theme) => ({
  daySummaryList: {
    overflow: "auto",
    height: 300,
    padding: 5,
    paddingTop: 0,
    backgroundColor: theme.palette.background.paper,
    flex: 1,
  },
  subheaderRoot: {
    display: "flex",
    justifyContent: "space-between",
    padding: "3px 0px",
  },
  subheaderItem: {
    display: "flex",
  },
  subheaderItemLabel: {
    fontWeight: "bold",
    paddingRight: 5,
  },
  subheaderItemValue: {
    marginLeft: 2,
  },
}));

const getNextDate = (currentDays: Day[], index: number): Date | null =>
  index < currentDays.length - 1
    ? currentDays[index + 1].date
      ? new Date(currentDays[index + 1].date)
      : null
    : null;

const getPreviousDate = (currentDays: Day[], index: number): Date | null =>
  index > 0
    ? currentDays[index - 1].date
      ? new Date(currentDays[index - 1].date)
      : null
    : null;

const startDate = (days: Day[]): string =>
  days[0].date ? new Date(days[0].date).toLocaleDateString() : "N/A";

const endDate = (days: Day[]): string =>
  days[days.length - 1].date
    ? new Date(days[days.length - 1].date).toLocaleDateString()
    : "N/A";

interface DaySummaryListProps {
  currentDays: Day[];
  updateDayTitle: (index: number, title: string) => void;
  updateDayDate: (index: number, date: string) => void;
  deleteDay: (index: number) => void;
  addDay: () => void;
}

const DaySummaryList = (props: DaySummaryListProps): JSX.Element => {
  const classes = useStyles();

  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const { currentDays, updateDayDate, updateDayTitle, deleteDay, addDay } = {
    ...props,
  };

  return (
    <List
      subheader={
        <ListSubheader className={classes.subheaderRoot}>
          <div className={classes.subheaderItem}>
            <Typography className={classes.subheaderItemLabel}>
              Day count
            </Typography>
            <Typography className={classes.subheaderItemValue}>
              {currentDays.length}
            </Typography>
          </div>
          <div className={classes.subheaderItem}>
            <Typography className={classes.subheaderItemLabel}>
              Start date
            </Typography>
            <Typography className={classes.subheaderItemValue}>
              {startDate(currentDays)}
            </Typography>
          </div>
          <div className={classes.subheaderItem}>
            <Typography className={classes.subheaderItemLabel}>
              End date
            </Typography>
            <Typography className={classes.subheaderItemValue}>
              {endDate(currentDays)}
            </Typography>
          </div>
        </ListSubheader>
      }
      className={classes.daySummaryList}
    >
      {currentDays.map(
        (day: Day, index: number): ReactNode => (
          <DaySummaryListItem
            disabled={currentDays.length === 1}
            key={day.index}
            day={day}
            onUpdateTitle={(title: string) => updateDayTitle(day.index, title)}
            previousDate={getPreviousDate(currentDays, index)}
            nextDate={getNextDate(currentDays, index)}
            onUpdateDate={(date: string) => updateDayDate(day.index, date)}
            onDelete={() => deleteDay(day.index)}
            clearable={isMealPlan(templateId)}
          />
        )
      )}
      <Button
        data-cy="createSectionTag"
        startIcon={<Add color="secondary" />}
        onClick={addDay}
        size="small"
      >
        Add day
      </Button>
    </List>
  );
};

export default DaySummaryList;

import { ThunkAction } from "redux-thunk";

import Firebase from "../../../../data/Firebase";
import { getCurrentDate } from "../../../../data/models/documentProperties/date";
import { getIdentifier } from "../../../../data/models/documentProperties/foodId";

import {
  Database,
  DocumentSummary,
  UserDatabaseSummary,
} from "../../../../data/models/userDatabase";
import { RootState } from "../../../reducers";
import { ExtraArguments } from "../../../store";
import { addUserDatabaseSummary } from "../../action-creators/user";
import { IActionsAddUserDatabaseSummary } from "../../actions/user";
import { sortedUserDatabaseSummariesSelector } from "../../selectors/user";
import cloneDocumentsToDatabase from "./helper-functions/cloneDocuments";

const getDocumentIdsToCopy = (database: Database): string[] =>
  database.documentSummaries.map((summary: DocumentSummary): string =>
    getIdentifier(database.summary.id, summary.documentId)
  );

const getUpdatedDatabaseSummary = (
  summary: UserDatabaseSummary,
  currentDate: string
): UserDatabaseSummary => {
  return {
    ...summary,
    name: `${summary.name} -copy`,
    date: { lastModified: currentDate, created: currentDate },
  };
};

const createDatabase = (
  database: Database,
  firebase: Firebase
): Promise<UserDatabaseSummary> =>
  firebase.userDatabases.doCreateCopyOfDatabase(database).then((databaseId) =>
    firebase.userPermissions
      .doAddDatabasePermission(firebase.auth.currentUser!.uid, databaseId)
      .then(() => {
        return { ...database.summary, id: databaseId };
      })
  );

const duplicateDatabase =
  (
    databaseId: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddUserDatabaseSummary
  > =>
  async (dispatch, getState, { firebase }) => {
    const userDatabaseIds: string[] = sortedUserDatabaseSummariesSelector(
      getState()
    ).map((summary: UserDatabaseSummary): string => summary.id);

    firebase.userDatabases.doGetUserDatabase(databaseId).then((database) =>
      createDatabase(
        {
          ...database,
          documentSummaries: [],
          summary: getUpdatedDatabaseSummary(
            database.summary,
            getCurrentDate()
          ),
        },
        firebase
      ).then((databaseSummary) =>
        cloneDocumentsToDatabase(
          getDocumentIdsToCopy(database),
          databaseSummary.id,
          [],
          userDatabaseIds,
          firebase
        ).then(() => dispatch(addUserDatabaseSummary(databaseSummary)))
      )
    );
  };

export default duplicateDatabase;

import React from "react";
import { makeStyles } from "@material-ui/core";

import NameInput from "./components/NameInput";
import ClientProfile from "./profile/ClientProfile";
import ClientContactDetails from "./contact/ClientContactDetails";
import NotesInput from "./components/NotesInput";
import RequirementsPanel from "./requirements/RequirementsPanel";
import DisplayClientDate from "./components/DisplayClientDate";
import { ClientTagMenu } from "../../../../dialogs/tags/client_tags/ClientTagMenu";
import { appTheme } from "../../../../../styling/style";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    border: `solid 1px ${appTheme.colors.gainsbruh}`,
    margin: 25,
    padding: 25,
    overflowY: "auto",
    borderRadius: 4,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tag: {
    width: "auto",
    minWidth: 500,
  },
  bodyContainer: {
    display: "flex",
    marginTop: 25,
  },
  subBodyContainer: {
    display: "flex",
  },
  dateContainer: {
    marginTop: 50,
  },
}));

const GeneralTab = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div>
          <NameInput />
        </div>
        <div className={classes.tag}>
          <ClientTagMenu />
        </div>
      </div>
      <div className={classes.bodyContainer}>
        <div>
          <ClientProfile />
        </div>
        <div>
          <div className={classes.subBodyContainer}>
            <div>
              <RequirementsPanel />
            </div>
            <div>
              <ClientContactDetails />
            </div>
          </div>
          <NotesInput />
        </div>
      </div>
      <div className={classes.dateContainer}>
        <DisplayClientDate />
      </div>
    </div>
  );
};

export default GeneralTab;

import firebase from "firebase";
import { ScreenType } from "../../types";

export interface User {
  allowMarketingEmails: boolean;
  firstName: string;
  lastName: string;
  lastScreen: ScreenType;
  lastUsedDatabase: string;
  id: string;
}

export const userConverter = {
  toFirestore: function (database: User) {
    throw new Error("Unimplemented");
  },

  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): User {
    const data: firebase.firestore.DocumentData = snapshot.data();

    return {
      allowMarketingEmails: data.allowMarketingEmails,
      firstName: data.firstName,
      lastName: data.lastName,
      lastUsedDatabase: data.lastUsedDatabase,
      lastScreen: data.lastScreen || "databases",
      id: data.user,
    };
  },
};

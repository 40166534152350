import {
  IActionsSetUserDatabaseSummaries,
  IActionsAddUserDatabaseSummary,
  IActionsRemoveUserDatabaseSummary,
  IActionsUpdateUserDatabaseSummary,
} from "../actions/user";
import { UserDatabaseSummary } from "../../../data/models/userDatabase";

export const setUserDatabaseSummaries = (
  databaseSummaries: UserDatabaseSummary[]
): IActionsSetUserDatabaseSummaries => {
  return {
    type: "SET_USER_DATABASE_SUMMARIES",
    databaseSummaries: databaseSummaries,
  };
};

export const addUserDatabaseSummary = (
  databaseSummary: UserDatabaseSummary
): IActionsAddUserDatabaseSummary => {
  return {
    type: "ADD_USER_DATABASE_SUMMARY",
    databaseSummary: databaseSummary,
  };
};

export const updateUserDatabaseSummary = (
  databaseSummary: UserDatabaseSummary
): IActionsUpdateUserDatabaseSummary => {
  return {
    type: "UPDATE_USER_DATABASE_SUMMARY",
    databaseSummary: databaseSummary,
  };
};

export const removeUserDatabaseSummary = (
  databaseId: string
): IActionsRemoveUserDatabaseSummary => {
  return {
    type: "REMOVE_USER_DATABASE_SUMMARY",
    databaseId: databaseId,
  };
};

import { call, ForkEffect, put, select, takeLatest } from "redux-saga/effects";
import { ENERGY_ID } from "../../../constants/nutrientIds";

import Firebase from "../../../data/Firebase";
import { ClientProfile } from "../../../data/models/clientProperties/clientProfile";
import {
  calculateBMI,
  getAdjustedWeight,
  getEnergyExpenditure,
  getNutrientReferenceValue,
  getNutrientReferenceValueFromProvidedEnergy,
} from "../../../data/models/nrvCalculator";
import {
  ERVInfo,
  getNRVCategory,
  NRVInfo,
  NutrientReferenceValue,
} from "../../../data/models/nutrientReferenceValues";
import { setCurrentClientNRV } from "../action-creators/clientDatabase";
import { SET_CURRENT_CLIENT } from "../actions/clientDatabase";
import {
  SET_CLIENT_ACTIVITY_LEVEL,
  SET_CLIENT_AGE,
  SET_CLIENT_CURRENT_WEIGHT,
  SET_CLIENT_DOB,
  SET_CLIENT_HEIGHT,
  SET_CLIENT_IS_LACTATING,
  SET_CLIENT_PREGNANCY_LEVEL,
  SET_CLIENT_SEX,
  SET_USE_CER,
} from "../current_client/actions/clientProfile";
import { clientProfileSelector } from "../current_client/selectors/client";
import {
  clientIsUseCER,
  clientRestingMetabolicRateSelector,
} from "../current_client/selectors/clientProfile";

const actionsToListenTo = [
  SET_CURRENT_CLIENT,
  SET_CLIENT_IS_LACTATING,
  SET_CLIENT_ACTIVITY_LEVEL,
  SET_CLIENT_AGE,
  SET_CLIENT_CURRENT_WEIGHT,
  SET_CLIENT_HEIGHT,
  SET_CLIENT_SEX,
  SET_CLIENT_PREGNANCY_LEVEL,
  SET_USE_CER,
  SET_CLIENT_DOB,
];

const getERVInfo = (
  firebase: Firebase,
  profile: ClientProfile
): Promise<ERVInfo> =>
  firebase.referenceData.doFetchEnergyReferenceValue(profile.sex, profile.age!);

const getNRVInfo = (
  firebase: Firebase,
  profile: ClientProfile
): Promise<NRVInfo[]> =>
  firebase.referenceData.doFetchNutrientReferenceValue(
    getNRVCategory(profile.sex, profile.pregnancyLevel, profile.isLactating),
    profile.age!
  );

const profileHasRequiredInformation = (profile: ClientProfile): boolean =>
  !!profile.age && !!profile.height && !!profile.weight;

export function* updateNutrientReferenceValues(firebase: Firebase) {
  const clientProfile: ClientProfile = yield select(clientProfileSelector);
  const useRMR: boolean = yield select(clientIsUseCER);
  let providedEnergy = 0;
  if (useRMR) providedEnergy = yield select(clientRestingMetabolicRateSelector);
  if (!profileHasRequiredInformation(clientProfile)) {
    yield put(setCurrentClientNRV([]));
    return;
  }

  const energyInfo: ERVInfo = yield call(getERVInfo, firebase, clientProfile);

  const nrvInfo: NRVInfo[] = yield call(getNRVInfo, firebase, clientProfile);

  if (!energyInfo || !nrvInfo) {
    yield put(setCurrentClientNRV([]));
    return;
  }
  const nrvs: NutrientReferenceValue[] = [];

  for (const info of nrvInfo) {
    const amount: number = useRMR
      ? yield call(
          getNutrientReferenceValueFromProvidedEnergy,
          info,
          providedEnergy,
          clientProfile.weight!
        )
      : yield call(
          getNutrientReferenceValue,
          info,
          energyInfo,
          clientProfile.weight!,
          clientProfile.pregnancyLevel,
          clientProfile.isLactating,
          clientProfile.activityLevel
        );
    nrvs.push({ nutrient: info.nutrientId, value: amount, type: info.type });
  }

  nrvs.push({
    value: useRMR
      ? providedEnergy
      : getEnergyExpenditure(
          energyInfo,
          clientProfile.weight!,
          clientProfile.pregnancyLevel,
          clientProfile.isLactating,
          clientProfile.activityLevel
        ),
    nutrient: ENERGY_ID,
    type: "EERM",
  });

  nrvs.push({
    value: getEnergyExpenditure(
      energyInfo,
      getAdjustedWeight(
        clientProfile.weight!,
        clientProfile.height!,
        calculateBMI(clientProfile.weight!, clientProfile.height!)
      ),
      clientProfile.pregnancyLevel,
      clientProfile.isLactating,
      clientProfile.activityLevel
    ),
    type: "DEER",
    nutrient: ENERGY_ID,
  });
  nrvs.push({
    value: getEnergyExpenditure(
      energyInfo,
      getAdjustedWeight(
        clientProfile.weight!,
        clientProfile.height!,
        calculateBMI(clientProfile.weight!, clientProfile.height!)
      ),
      clientProfile.pregnancyLevel,
      clientProfile.isLactating,
      clientProfile.activityLevel
    ),
    type: "RDI",
    nutrient: ENERGY_ID,
  });
  nrvs.push({
    value: getEnergyExpenditure(
      energyInfo,
      getAdjustedWeight(
        clientProfile.weight!,
        clientProfile.height!,
        calculateBMI(clientProfile.weight!, clientProfile.height!)
      ),
      clientProfile.pregnancyLevel,
      clientProfile.isLactating,
      clientProfile.activityLevel
    ),
    type: "EAR",
    nutrient: ENERGY_ID,
  });

  yield put(setCurrentClientNRV(nrvs));
}

export function* nutrientReferenceValueSaga(
  firebase: Firebase
): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    actionsToListenTo,

    updateNutrientReferenceValues,
    firebase
  );
}

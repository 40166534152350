import { batch } from "react-redux";
import { ThunkAction } from "redux-thunk";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { Document, DocumentTemplateId } from "../../../../data/models/document";
import { DocumentSummary } from "../../../../data/models/userDatabase";
import { RootState } from "../../../reducers";
import { fetchComposition } from "../../action-creators/compositionCache";
import {
  addDocument,
  updateCachedDocument,
} from "../../action-creators/documentCache";
import { IActionsFetchComposition } from "../../actions/compositionCache";
import {
  IActionsAddUserDocumentSummary,
  IActionsUpdateUserDocumentSummary,
} from "../../actions/database";
import {
  IActionsAddDocument,
  IActionsUpdateCachedDocument,
} from "../../actions/documentCache";
import {
  databaseIdSelector,
  userDocumentSummariesSelector,
} from "../../selectors/database";
import {
  allCachedDocumentsSelector,
  cachedDocumentSelector,
} from "../../selectors/documentCache";
import {
  createDocumentSummary,
  updateDocumentSummary,
} from "../../thunks/database";
import { ExtraArguments } from "../../../store";
import { setCurrentTab } from "../../../ui/actionCreators/documentScreen";
import { setRecentMappedDocumentId } from "../../../ui/actionCreators/overridesScreen";
import {
  updateCurrentDay,
  setSelectedRows,
} from "../../../ui/actionCreators/recipeGrid";
import {
  setDocumentId,
  allDocumentsFetched,
  changeDocumentLoadingState,
  updateServerDocument,
} from "../action-creators/currentDocument";
import { setDocumentData } from "../action-creators/document";
import { initialDocumentState } from "../reducers/document";
import {
  IActionsSetSelectedRows,
  IActionsUpdateCurrentDay,
} from "../../../ui/actions/recipeGrid";
import {
  IActionsAllDocumentsFetched,
  IActionsSetDocumentData,
  IActionsSetDocumentId,
  IActionsSetDocumentLoading,
  IActionsUpdateServerDocument,
} from "../actions/currentDocument";
import { IActionsSetRecentMappedDocumentId } from "../../../ui/actions/overridesScreen";
import { IActionsSetCurrentTab } from "../../../ui/actions/documentScreen";
import { FoodId } from "../../../../data/models/documentProperties/foodId";
import { DocumentMap } from "../../reducers/documentCache";
import { CalculationMethod } from "../../../../constants/calculationMethod";
import {
  DocumentMapReturn,
  getChildDocuments,
} from "../../../../data/dart_to_js_conversion/FoodworksObjectConversion";
import { addError } from "../../../action_creators/errorActionCreators";
import { IActionsAddError } from "../../../actions/errorActions";
import { FoodItemState } from "../../../../data/models/documentProperties/foodItem";
import {
  FOOD_RECORDS,
  hasFoodItems,
  isFood,
  isFoodRecord,
  isMealPlan,
} from "../../../../constants/FoodTemplate";
import Firebase from "../../../../data/Firebase/firebase";
import {
  TEMPORARY_DOCUMENT,
  TEMPORARY_NEW_DOCUMENT,
} from "../reducers/currentDocument";
import { fetchDocument } from "../../../../data/Firebase/helpers/fetchDocument";
import { updateSelectedAmount } from "../../../ui/actionCreators/nutritionPaneActionCreators";
import { NutritionRadioOption } from "../../../ui/reducers/nutritionPaneReducers";
import { IActionsUpdateSelectedAmount } from "../../../ui/actions/nutritionPaneActions";
import { addDocumentToClient } from "../../current_client/thunks/client";
import { DayState } from "../../../../data/models/documentProperties/day";
import { addCommonMeasure } from "../action-creators/commonMeasures";
import { IActionsAddCommonMeasure } from "../actions/commonMeasures";
import { IngredientSummaryItem } from "../../../../components/screens/databases/documents/tabs/ingredients/editing_grid/rows/cells/IngredientCell";
import { getDocument } from "./getDocument";
import { CurrentDocumentIdSelector } from "../selectors/currentDocument";
import { handleRouteChange } from "../../../ui/thunks/routing";
import {
  getClientRouteData,
  getDatabaseRouteData,
} from "../../../../data/routing/routing";
import {
  clientUserDatabaseSelector,
  currentClientIdSelector,
} from "../../selectors/clientDatabase";

export const createDocument =
  (
    databaseId: string,
    document: Document
  ): ThunkAction<
    Promise<string>,
    RootState,
    ExtraArguments,
    | IActionsAddUserDocumentSummary
    | IActionsAddDocument
    | IActionsFetchComposition
  > =>
  async (dispatch, getState, { firebase, routing }) => {
    const newDocumentData = await firebase.userDatabases.doCreateUserDocument(
      databaseId,
      document
    );

    batch(() => {
      dispatch(addDocument(document, `${databaseId}:${newDocumentData.id}`));
      dispatch(
        fetchComposition(`${databaseId}:${newDocumentData.id}`, document)
      );
      dispatch(createDocumentSummary(databaseId, newDocumentData.id, document));
    });

    return newDocumentData.id;
  };

export const updateDocumentTagId =
  (
    documentId: string,
    tagIds: string[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsUpdateCachedDocument | IActionsFetchComposition
  > =>
  async (dispatch, getState, { firebase, routing }) => {
    const databaseId: string = databaseIdSelector(getState());

    const document: Document = await firebase.userDatabases.doGetUserDocument(
      databaseId,
      documentId
    );

    const updatedDocument: Document = {
      ...document,
      documentTags: tagIds,
    };

    await firebase.userDatabases.doUpdateUserDocument(
      databaseId,
      documentId,
      updatedDocument
    );

    batch(() => {
      dispatch(
        updateCachedDocument(`${databaseId}:${documentId}`, updatedDocument)
      );
      dispatch(
        fetchComposition(`${databaseId}:${documentId}`, updatedDocument)
      );
    });
  };

export const updateDocument =
  (
    databaseId: string,
    documentId: string,
    previousDocument: Document,
    updatedDocument: Document
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    | IActionsUpdateCachedDocument
    | IActionsFetchComposition
    | IActionsUpdateUserDocumentSummary
  > =>
  async (dispatch, _getState, { firebase }) => {
    await firebase.userDatabases.doUpdateUserDocument(
      databaseId,
      documentId,
      updatedDocument
    );

    const updatedDocumentSummary: DocumentSummary = {
      documentId: documentId,
      label: updatedDocument.name,
      templateId: updatedDocument.templateId,
      searchableProperties: updatedDocument.identifier,
      sectionTags: updatedDocument.sectionTags,
      status: updatedDocument.properties.state,
      lastModified: updatedDocument.date.lastModified,
      documentTagIds: updatedDocument.documentTags,
    };

    const previousDocumentSummary: DocumentSummary = {
      documentId: documentId,
      label: previousDocument.name,
      templateId: previousDocument.templateId,
      searchableProperties: previousDocument.identifier,
      sectionTags: previousDocument.sectionTags,
      status: previousDocument.properties.state,
      lastModified: previousDocument.date.lastModified,
      documentTagIds: previousDocument.documentTags,
    };

    batch(() => {
      dispatch(
        updateCachedDocument(`${databaseId}:${documentId}`, updatedDocument)
      );
      dispatch(
        fetchComposition(`${databaseId}:${documentId}`, updatedDocument)
      );
      if (!_.isEqual(updatedDocumentSummary, previousDocumentSummary)) {
        dispatch(
          updateDocumentSummary(
            databaseId,
            updatedDocumentSummary,
            previousDocumentSummary
          )
        );
      }
    });
  };

const setDates = (startDate: Date, days: DayState[]) => {
  const updatedDays: DayState[] = [];
  for (let i = 0; i < days.length; ++i) {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);
    updatedDays.push({ ...days[i], date: date.toISOString() });
  }
  return updatedDays;
};

export const convertMealPlanToFoodRecord =
  (
    documentId: string,
    startDate: Date
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddUserDocumentSummary | IActionsAddDocument
  > =>
  async (dispatch, getState) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const documentToCopy: Document | undefined = cachedDocumentSelector(
      getState(),
      `${currentDatabaseId}:${documentId}`
    );

    const userDocumentSummaries: DocumentSummary[] =
      userDocumentSummariesSelector(getState());

    if (!documentToCopy) return;

    const isDocumentNameUnique = (
      userDocumentSummaries: DocumentSummary[],
      name: string
    ) =>
      !userDocumentSummaries.find(
        (documentSummary: DocumentSummary): boolean =>
          documentSummary.label === name
      );

    let documentCounter = 1;
    const documentName = `${documentToCopy.name} (Record)`;
    while (
      !isDocumentNameUnique(
        userDocumentSummaries,
        `${documentName} (${documentCounter})`
      )
    ) {
      ++documentCounter;
    }
    const newDocumentName: string = `${documentName} (${documentCounter})`;

    const currentDate: string = new Date().toISOString();
    const newDocument: Document = {
      ...documentToCopy,
      date: { created: currentDate, lastModified: currentDate },
      name: newDocumentName,
      templateId: FOOD_RECORDS.id.toString() as DocumentTemplateId,
      days: setDates(startDate, documentToCopy.days),
    };

    const newDocId = await dispatch(
      createDocument(currentDatabaseId, newDocument)
    );
    dispatch(addDocumentToClient(newDocId));
  };

export const duplicateDocument =
  (
    documentId: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddUserDocumentSummary | IActionsAddDocument
  > =>
  async (dispatch, getState) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const documentToCopy: Document | undefined = cachedDocumentSelector(
      getState(),
      `${currentDatabaseId}:${documentId}`
    );

    const userDocumentSummaries: DocumentSummary[] =
      userDocumentSummariesSelector(getState());

    if (!documentToCopy) return;

    const isDocumentNameUnique = (
      userDocumentSummaries: DocumentSummary[],
      name: string
    ) =>
      !userDocumentSummaries.find(
        (documentSummary: DocumentSummary): boolean =>
          documentSummary.label === name
      );

    let documentCounter = 1;
    while (
      !isDocumentNameUnique(
        userDocumentSummaries,
        `${documentToCopy.name} (${documentCounter})`
      )
    ) {
      ++documentCounter;
    }
    const newDocumentName: string = `${documentToCopy.name} (${documentCounter})`;

    const newDocument: Document = {
      ...documentToCopy,
      name: newDocumentName,
    };

    await dispatch(createDocument(currentDatabaseId, newDocument));
  };

export const deleteDocument =
  (
    documentId: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddUserDocumentSummary | IActionsUpdateServerDocument
  > =>
  async (dispatch, getState) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const clientDatabaseId: string = clientUserDatabaseSelector(getState());

    const currentDocumentId: string = CurrentDocumentIdSelector(getState());

    const currentClientId: string = currentClientIdSelector(getState());

    let documentToDelete: Document | undefined = cachedDocumentSelector(
      getState(),
      `${currentDatabaseId}:${documentId}`
    );

    if (!documentToDelete) {
      documentToDelete = await dispatch(
        getDocument(
          new FoodId({
            datasourceId: currentDatabaseId,
            documentId: documentId,
          }),
          false,
          false
        )
      );
    }

    const currentDate = new Date().toISOString();
    let updatedDocument: Document = {
      ...documentToDelete,
      date: { created: currentDate, lastModified: currentDate },
      properties: {
        ...documentToDelete.properties,
        state: "archived",
      },
    };
    if (documentToDelete.properties.state === "archived") {
      updatedDocument = {
        ...documentToDelete,
        properties: {
          ...documentToDelete.properties,
          state: "deleted",
        },
      };
    }

    await dispatch(
      updateDocument(
        currentDatabaseId,
        documentId,
        documentToDelete,
        updatedDocument
      )
    );

    if (currentDocumentId === documentId) {
      dispatch(updateServerDocument(initialDocumentState));

      currentDatabaseId === clientDatabaseId
        ? dispatch(handleRouteChange(getClientRouteData(currentClientId)))
        : dispatch(handleRouteChange(getDatabaseRouteData(currentDatabaseId)));
    }
  };

export const archiveDocument =
  (
    documentId: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddUserDocumentSummary
  > =>
  async (dispatch, getState) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    let documentToDelete: Document | undefined = cachedDocumentSelector(
      getState(),
      `${currentDatabaseId}:${documentId}`
    );

    if (!documentToDelete) {
      documentToDelete = await dispatch(
        getDocument(
          new FoodId({
            datasourceId: currentDatabaseId,
            documentId: documentId,
          }),
          false,
          false
        )
      );
    }

    const currentDate = new Date().toISOString();
    let updatedDocument: Document = {
      ...documentToDelete,
      date: { created: currentDate, lastModified: currentDate },
      properties: {
        ...documentToDelete.properties,
        state: "archived",
      },
    };
    if (documentToDelete.properties.state === "archived") {
      updatedDocument = {
        ...documentToDelete,
        properties: {
          ...documentToDelete.properties,
          state: "active",
        },
      };
    }

    await dispatch(
      updateDocument(
        currentDatabaseId,
        documentId,
        documentToDelete,
        updatedDocument
      )
    );
  };

export const resetDocument =
  (): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    | IActionsUpdateCurrentDay
    | IActionsSetDocumentId
    | IActionsSetDocumentData
    | IActionsSetCurrentTab
    | IActionsSetSelectedRows
    | IActionsSetRecentMappedDocumentId
    | IActionsAllDocumentsFetched
  > =>
  async (dispatch) =>
    batch(() => {
      dispatch(updateCurrentDay(0));
      dispatch(setDocumentId(""));
      dispatch(setDocumentData(initialDocumentState));
      dispatch(setCurrentTab(0));
      dispatch(setSelectedRows([]));
      dispatch(setRecentMappedDocumentId(""));
      dispatch(allDocumentsFetched());
    });

export const setDocument =
  (
    documentId: string,
    document: Document
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    | IActionsUpdateCurrentDay
    | IActionsSetDocumentId
    | IActionsSetDocumentData
    | IActionsSetCurrentTab
    | IActionsSetSelectedRows
    | IActionsSetRecentMappedDocumentId
    | IActionsAllDocumentsFetched
  > =>
  async (dispatch) =>
    batch(() => {
      dispatch(updateCurrentDay(0));
      dispatch(setDocumentId(documentId));
      dispatch(setDocumentData(document));
      dispatch(setCurrentTab(0));
      dispatch(setSelectedRows([]));
      dispatch(setRecentMappedDocumentId(document.documentMappingId));
      dispatch(allDocumentsFetched());
    });

export const getDocumentsToAddToCache = async (
  document: Document,
  foodId: FoodId,
  documentsToAddToCache: DocumentMap,
  firebase: Firebase
): Promise<DocumentMapReturn> => {
  let documentMapReturn: DocumentMapReturn = {
    documentMap: documentsToAddToCache,
  };

  let foodIdAsString: string = foodId.identifier;
  documentsToAddToCache[foodIdAsString] = document;
  const documentHasFoodItems: boolean = hasFoodItems(document.templateId);

  const documentIsMapped: boolean =
    document.calculationMethod === CalculationMethod.MAPPED;

  if (documentHasFoodItems || documentIsMapped) {
    let foodItems: FoodItemState[] = [];
    for (const day of document.days) {
      for (const section of day.sections) {
        foodItems = [...foodItems, ...section.foodItems];
      }
    }
    const definedFoodItems = foodItems.filter(
      (foodItem: FoodItemState): boolean => Boolean(foodItem.foodId)
    );
    const foodIdsToFetch: FoodId[] = [];
    if (document.documentMappingId) {
      const [datasourceId, documentId] = document.documentMappingId.split(":");
      const foodId = new FoodId({
        datasourceId: datasourceId,
        documentId: documentId,
      });
      foodIdsToFetch.push(foodId);
    }
    documentMapReturn = await getChildDocuments(
      firebase,
      foodId.documentId!,
      foodIdsToFetch.concat(
        definedFoodItems.map(
          (foodItem: FoodItemState): FoodId => new FoodId(foodItem.foodId!)
        )
      )
    );

    documentMapReturn = {
      ...documentMapReturn,
      documentMap: {
        ...documentsToAddToCache,
        ...documentMapReturn.documentMap,
      },
    };
  }
  return documentMapReturn;
};

export const fetchChildDocuments =
  (
    document: Document,
    foodId: FoodId
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    | IActionsAddError
    | IActionsAddDocument
    | IActionsFetchComposition
    | IActionsSetDocumentLoading
  > =>
  async (dispatch, getState, { firebase }) => {
    const documentCache: DocumentMap = allCachedDocumentsSelector(getState());

    let documentsToAddToCache: DocumentMap = {};

    const documentNotInCache: boolean = !documentCache.hasOwnProperty(
      foodId.identifier
    );

    if (documentNotInCache) {
      const documentMapReturn: DocumentMapReturn =
        await getDocumentsToAddToCache(
          document,
          foodId,
          documentsToAddToCache,
          firebase
        );
      if (documentMapReturn.error) {
        dispatch(addError(documentMapReturn.error));
      }
      documentsToAddToCache = documentMapReturn.documentMap;

      for (const [id, document] of Object.entries(documentsToAddToCache)) {
        dispatch(addDocument(document, id));
      }
      const nonRecipeDocuments = Object.entries(documentsToAddToCache).filter(
        ([_, document]: [string, Document]): boolean =>
          !(
            document.calculationMethod === CalculationMethod.INGREDIENTS &&
            !!document.days.length
          )
      );
      for (const [id, document] of nonRecipeDocuments) {
        dispatch(fetchComposition(id, document));
      }
      const recipeDocuments = Object.entries(documentsToAddToCache).filter(
        ([_, document]: [string, Document]): boolean =>
          document.calculationMethod === CalculationMethod.INGREDIENTS &&
          !!document.days.length
      );

      for (const [id, document] of recipeDocuments) {
        dispatch(fetchComposition(id, document));
      }
    }
    dispatch(changeDocumentLoadingState(false));
    return;
  };

export const handleCurrentDocumentChange =
  (
    documentId: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsUpdateSelectedAmount | IActionsSetDocumentLoading
  > =>
  async (dispatch, getState, { firebase }) => {
    const documentCache: DocumentMap = allCachedDocumentsSelector(getState());

    const databaseId: string = databaseIdSelector(getState());

    if (documentId === TEMPORARY_NEW_DOCUMENT) {
      documentId = TEMPORARY_DOCUMENT;
    }

    if (!documentId) {
      dispatch(resetDocument());
      return;
    }

    let document: Document =
      documentId === TEMPORARY_DOCUMENT
        ? documentCache[TEMPORARY_NEW_DOCUMENT]
        : documentCache[`${databaseId}:${documentId}`];

    if (!document || documentId !== TEMPORARY_DOCUMENT) {
      const foodId = new FoodId({
        datasourceId: databaseId,
        documentId: documentId,
      });

      dispatch(changeDocumentLoadingState(true));

      document = await fetchDocument(firebase, foodId, false);

      await dispatch(fetchChildDocuments(document, foodId));
    }

    if (document) {
      if (isFood(document.templateId)) {
        dispatch(updateSelectedAmount(NutritionRadioOption.ONE_HUNDRED_G));
      } else if (
        isMealPlan(document.templateId) ||
        isFoodRecord(document.templateId)
      ) {
        dispatch(updateSelectedAmount(NutritionRadioOption.DAY));
      } else {
        dispatch(updateSelectedAmount(NutritionRadioOption.TOTAL));
      }
    }

    return dispatch(setDocument(documentId, document));
  };

export const getDocumentAndAddMeasure =
  (
    foodId: FoodId,
    isPublic: boolean,
    isMapped: boolean,
    combinedSummaries: IngredientSummaryItem[],
    hasBeenAdded: FoodId[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddCommonMeasure
  > =>
  async (dispatch, getState, { firebase }) => {
    let document: Document = await dispatch(
      getDocument(foodId, isPublic, isMapped)
    );

    batch(() => {
      for (let measure of document.commonMeasures.measures) {
        dispatch(
          addCommonMeasure({
            id: uuidv4(),
            name: measure.name,
            value: measure.value,
            description: measure.description,
            usedIn: [],
          })
        );
      }
    });

    let mappedDocumentSummary = combinedSummaries.find(
      (summary) => summary.foodId.documentId === document.documentMappingId
    );
    if (
      mappedDocumentSummary &&
      !hasBeenAdded?.includes(mappedDocumentSummary.foodId)
    ) {
      dispatch(
        getDocumentAndAddMeasure(
          mappedDocumentSummary.foodId,
          mappedDocumentSummary.isPublic,
          false,
          combinedSummaries,
          [...hasBeenAdded, mappedDocumentSummary.foodId]
        )
      );
    }
  };

export const addDocumentUsedIn =
  (
    foodId: FoodId,
    identifier: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsUpdateCachedDocument | IActionsAddDocument
  > =>
  async (dispatch, getState, { firebase }) => {
    const documentCache: DocumentMap = allCachedDocumentsSelector(getState());

    const document: Document =
      foodId.identifier in documentCache
        ? documentCache[foodId.identifier]
        : await fetchDocument(firebase, foodId, false);

    if (!document) return;

    const updatedDocument: Document = {
      ...document,
      usedIn: document.usedIn.includes(identifier)
        ? document.usedIn
        : [...document.usedIn, identifier],
    };

    await firebase.userDatabases.doUpdateUserDocument(
      foodId.datasourceId,
      foodId.documentId,
      updatedDocument
    );

    foodId.identifier in documentCache
      ? dispatch(updateCachedDocument(foodId.identifier, updatedDocument))
      : dispatch(addDocument(updatedDocument, foodId.identifier));
  };

export const removeDocumentUsedIn =
  (
    foodId: FoodId,
    identifier: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsUpdateCachedDocument | IActionsAddDocument
  > =>
  async (dispatch, getState, { firebase }) => {
    const documentCache: DocumentMap = allCachedDocumentsSelector(getState());

    if (
      foodId.datasourceId === databaseIdSelector(getState()) &&
      !cachedDocumentSelector(getState(), foodId.identifier)
    ) {
      await dispatch(getDocument(foodId, false, false));
    }

    const document: Document =
      foodId.identifier in documentCache
        ? documentCache[foodId.identifier]
        : await fetchDocument(firebase, foodId, false);

    const updatedDocument: Document = {
      ...document,
      usedIn: document.usedIn.filter(
        (id: string): boolean => id !== identifier
      ),
    };

    await firebase.userDatabases.doUpdateUserDocument(
      foodId.datasourceId,
      foodId.documentId,
      updatedDocument
    );

    foodId.identifier in documentCache
      ? dispatch(updateCachedDocument(foodId.identifier, updatedDocument))
      : dispatch(addDocument(updatedDocument, foodId.identifier));
  };

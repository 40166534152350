import React, { useState } from "react";
import {
  Button,
  Icon,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Add, SwapHoriz } from "@material-ui/icons";
import _ from "lodash";
import { useDispatch } from "react-redux";

import { ReactComponent as MealPlanIcon } from "../../../../../../images/icons/MealPlan.svg";
import DocumentTable, { RowData, SortData } from "./DocumentTable";
import useDocumentData from "../hooks/useDocumentData";
import { FoodWorksTextInput } from "../../../../../common/FoodWorksTextInput";
import useSelectedClientDocuments from "../hooks/useSelectedClientDocuments";
import { FoodworksTooltip } from "../../../../../common/InfoTooltip";
import { MEAL_PLANS } from "../../../../../../constants/FoodTemplate";
import ConvertPlanToRecordDialog from "./ConvertPlanToRecordDialog";
import createNewDocument from "../../../../../../store/data/current-document/thunks/document/createNewDocument";
import { MEDIUM_FIELD } from "../../../../../../constants/textInputs";

const useStyles = makeStyles(() => ({
  tableRoot: {
    flex: 1,
    diplay: "flex",
    flexDirection: "column",
    padding: 10,
  },
  searchContainer: {
    width: "60%",
    marginTop: 15,
  },
  tableContainer: {
    marginTop: 10,
    flex: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    marginLeft: 10,
  },
  buttonBar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const getSelectedMealPlanDocuments = (
  selectedDocumentIds: string[],
  mealPlanRows: RowData[]
): string[] =>
  _.intersection(
    selectedDocumentIds,
    mealPlanRows.map((row: RowData): string => row.documentId)
  );

interface MealPlanTableProps {
  stackTables: boolean;
}

const MealPlanTable = React.memo<MealPlanTableProps>(
  ({ stackTables }): JSX.Element => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const onCreateNewMealPlan = () => dispatch(createNewDocument(MEAL_PLANS));

    const [showConvertDialog, setShowConvertDialog] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [sortMethod, setSortMethod] = useState<SortData>({
      method: "DATE_RANGE",
      order: "desc",
    });

    const [selectedDocuments, setSelectedDocuments]: [
      string[],
      (documents: string[]) => void
    ] = useSelectedClientDocuments();

    const rows: RowData[] = useDocumentData(
      searchText,
      "Plan",
      sortMethod.order,
      sortMethod.method
    );

    return (
      <div className={classes.tableRoot}>
        <div className={classes.header}>
          <Icon>
            <MealPlanIcon />
          </Icon>
          <Typography className={classes.headerText} variant="h2">
            Meal plans
          </Typography>
        </div>
        <div className={classes.searchContainer}>
          <FoodWorksTextInput
            maxLength={MEDIUM_FIELD}
            placeholder="Search meal plans"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.currentTarget.value);
              if (
                _.intersection(
                  selectedDocuments,
                  rows.map((row: RowData): string => row.documentId)
                ).length
              ) {
                setSelectedDocuments([]);
              }
            }}
          />
        </div>
        <div className={classes.tableContainer}>
          <Paper>
            <div className={classes.buttonBar}>
              <Button
                onClick={onCreateNewMealPlan}
                startIcon={<Add color="secondary" />}
              >
                Create new
              </Button>
              <FoodworksTooltip
                title={
                  getSelectedMealPlanDocuments(selectedDocuments, rows)
                    .length !== 1
                    ? ""
                    : "Convert to food record"
                }
              >
                <IconButton
                  onClick={() => setShowConvertDialog(true)}
                  size="small"
                  color="secondary"
                  disabled={
                    getSelectedMealPlanDocuments(selectedDocuments, rows)
                      .length !== 1
                  }
                >
                  <SwapHoriz />
                </IconButton>
              </FoodworksTooltip>
            </div>
            <DocumentTable
              checkedItems={getSelectedMealPlanDocuments(
                selectedDocuments,
                rows
              )}
              setCheckedItems={setSelectedDocuments}
              rows={rows}
              sortMethod={sortMethod}
              setSortMethod={setSortMethod}
              stackTables={stackTables}
            />
          </Paper>
          {showConvertDialog && (
            <ConvertPlanToRecordDialog
              onClose={() => setShowConvertDialog(false)}
              mealPlanDocumentId={selectedDocuments[0]}
            />
          )}
        </div>
      </div>
    );
  }
);

export default MealPlanTable;

export const measurementCategories = [
  "Anthropometric",
  "Biochemical",
  "Clinical",
] as const;

export type MeasurementCategory = typeof measurementCategories[number];

export interface MeasurementEntry {
  id: string;
  value: number;
  date: string;
}
//subCategory will be "Custom" for any user defined measurements
export interface Measurement {
  category: MeasurementCategory;
  subCategory: string;
  label: string;
  unit: string;
  entries: MeasurementEntry[];
  active: boolean;
}

export interface MeasurementInfo {
  label: string;
  unit: string;
  category: MeasurementCategory;
  subCategory: string;
}

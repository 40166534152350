import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { Chip, Grid } from "@material-ui/core";

import { setClientTags } from "../../../../store/data/current_client/action_creators/client";
import { Tag } from "../../../../data/models/documentProperties/section";
import { FoodworksTooltip } from "../../../common/InfoTooltip";
import { useClientSummariesTag, useCurrentClientTags } from "./hooks/ClientTag";
import { CreateClientTagDialog } from "./CreateClientTagDialog";

interface BaseClientTagProps {
  clientId: string;
  disabled?: boolean;
  useLoadTags: [Tag[], Dispatch<SetStateAction<Tag[]>>];
}

export const BaseClientTags = ({
  clientId,
  disabled = false,
  useLoadTags,
}: BaseClientTagProps): JSX.Element => {
  const dispatch = useDispatch();

  const onDeleteDocumentTag = (tagId: string) => {
    const updatedTagIds: string[] = selectedTags
      .filter((tag) => tag.id !== tagId)
      .map((tag: Tag) => tag.id);
    dispatch(setClientTags(updatedTagIds));
  };

  const [selectedTags] = useLoadTags;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedTagId, setSelectedTagId] = useState<string>("");

  const onClickTag = (tagId: string) => {
    setOpenDialog(true);
    setSelectedTagId(tagId);
  };

  const handleOnClose = () => {
    setOpenDialog(false);
    setSelectedTagId("");
  };

  const enabledChip = (tag: Tag): ReactNode => {
    return (
      <Chip
        key={`docTag-${clientId}-${tag.id}`}
        size="small"
        label={tag.label}
        clickable
        onClick={() => onClickTag(tag.id)}
        onDelete={() => onDeleteDocumentTag(tag.id)}
        style={{
          backgroundColor: tag.inactiveColor,
          border: `1px solid ${tag.activeColor}`,
        }}
      />
    );
  };

  const disabledChip = (tag: Tag): ReactNode => {
    return (
      <Chip
        key={`docTag-${clientId}-${tag.id}`}
        size="small"
        label={tag.label}
        style={{
          backgroundColor: tag.inactiveColor,
          border: `1px solid ${tag.activeColor}`,
        }}
      />
    );
  };

  const tagButtons: ReactNode = (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      wrap="nowrap"
    >
      {selectedTags.map(
        (tag: Tag): ReactNode => (
          <FoodworksTooltip
            key={`tooltip-${clientId}-${tag.id}`}
            title={tag.label}
          >
            <span>{!disabled ? enabledChip(tag) : disabledChip(tag)}</span>
          </FoodworksTooltip>
        )
      )}
    </Grid>
  );

  return (
    <div>
      {tagButtons}
      <CreateClientTagDialog
        open={openDialog}
        onClose={handleOnClose}
        isEditing={true}
        tagId={selectedTagId}
      />
    </div>
  );
};

interface ClientTagProps {
  clientId: string;
  disabled?: boolean;
}

export const ClientSummaryTags = ({
  clientId,
  disabled,
}: ClientTagProps): JSX.Element => {
  return (
    <BaseClientTags
      clientId={clientId}
      disabled={disabled}
      useLoadTags={useClientSummariesTag(clientId)}
    />
  );
};

export const ClientDocumentTags = ({
  clientId,
  disabled,
}: ClientTagProps): JSX.Element => {
  return (
    <BaseClientTags
      clientId={clientId}
      disabled={disabled}
      useLoadTags={useCurrentClientTags()}
    />
  );
};

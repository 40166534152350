import React, { FunctionComponent, ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  NutritionPaneState,
  NutritionRadioOption,
} from "../../../../store/ui/reducers/nutritionPaneReducers";
import {
  customCompositionAmountSelector,
  compositionOptionSelector,
} from "../../../../store/ui/selectors/nutritionPaneSelectors";
import { RootState } from "../../../../store/reducers";
import {
  updateCustomAmount,
  updateSelectedAmount,
} from "../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import useCompositionOptions from "./hooks/useCompositionOptions";
import CompositionOptionButton from "./components/CompositionOptionButton";

const useStyles = makeStyles(() => ({
  root: { flex: 1, display: "flex", justifyContent: "flex-end" },
}));

export const QuantityRadioGroup: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUpdateSelectedAmount = (selectedAmount: NutritionRadioOption) =>
    dispatch(updateSelectedAmount(selectedAmount));

  const onUpdateCustomAmount = (value: string) =>
    dispatch(updateCustomAmount(Number(value)));

  const selectedRadioValue: NutritionRadioOption = useSelector<
    RootState,
    NutritionRadioOption
  >(compositionOptionSelector);

  const customAmount = useSelector<
    RootState,
    NutritionPaneState["customAmount"]
  >(customCompositionAmountSelector);

  const compositionOptions: NutritionRadioOption[] = useCompositionOptions();

  return (
    <div className={classes.root} data-cy="compositionRadio">
      {compositionOptions.map(
        (option: NutritionRadioOption): ReactNode => (
          <CompositionOptionButton
            key={option}
            option={option}
            selectedOption={selectedRadioValue}
            customAmount={customAmount}
            onUpdateCustomAmount={onUpdateCustomAmount}
            onSelectOption={onUpdateSelectedAmount}
          />
        )
      )}
    </div>
  );
};

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setClientUseCER } from "../../../../../../../store/data/current_client/action_creators/clientProfile";
import { clientIsUseCER } from "../../../../../../../store/data/current_client/selectors/clientProfile";
import { RootState } from "../../../../../../../store/reducers";
import FoodworksSelect from "../../../../../../common/FoodworksSelect";
import { EnergyRequirementMethod } from "../hooks/useEnergyRequirementMethod";

const EnergyRequirementMethodInput = React.memo((): JSX.Element => {
  const dispatch = useDispatch();

  const onSetUseRMR = useCallback(
    (use: boolean) => {
      dispatch(setClientUseCER(use));
    },
    [dispatch]
  );

  const useCER: boolean = useSelector<RootState, boolean>(clientIsUseCER);

  return (
    <FoodworksSelect<EnergyRequirementMethod>
      value={useCER ? "CER" : "NRVs"}
      onChange={(value: EnergyRequirementMethod) =>
        onSetUseRMR(value === "CER")
      }
      options={["NRVs", "CER"]}
    />
  );
});

export default EnergyRequirementMethodInput;

import { createSelector } from "reselect";

import { Day, Days, DayState } from "../../../../data/models/documentProperties/day";
import { RootState } from "../../../reducers";
import { getRetentionFactorMap } from "../../selectors/referenceData";

export const daysStateSelector = (rootState: RootState): DayState[] =>
  rootState.currentDocument.document.days;

export const daysSelector = createSelector(
  daysStateSelector,
  getRetentionFactorMap,
  (daysState, retentionFactorMap) => {
    return new Days(daysState, retentionFactorMap);
  }
);

export const dayStateSelector = (
  rootState: RootState,
  dayIndex: number
): DayState => rootState.currentDocument.document.days[dayIndex];

export const daySelector = createSelector(
  dayStateSelector,
  getRetentionFactorMap,
  (dayState, retentionFactorMap) => {
    return dayState ? Day.fromObject(dayState, retentionFactorMap) : undefined;
  }
);

export const dayTitleSelector = createSelector(
  daySelector,
  (day): string | undefined => day?.title
);

export const dayDateSelector = createSelector(
  daySelector,
  (day): string | undefined => day?.date
);

export const sectionCountSelector = () =>
  createSelector(
    dayStateSelector,
    (dayState): number => dayState.sections.length
  );

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/core";

import { updateMethodSectionTitle } from "../../../../../../store/data/current-document/action-creators/document";
import { EditableTitleInput } from "../../../../../common/EditableTitleInput";
import { appTheme } from "../../../../../../styling/style";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      paddingLeft: 5,
      fontSize: appTheme.fontSize.textInput,
      backgroundColor: appTheme.colors.white[10],
      border: `2px solid ${appTheme.colors.white[10]}`,
      "&:hover": {
        backgroundColor: appTheme.colors.white[4],
        paddingLeft: 5,
      },
      "&:focus": {
        backgroundColor: appTheme.colors.white[2],
        paddingLeft: 5,
      },
    },
  })
);

interface MethodSectionTitleProps {
  sectionIndex: number;
  title: string;
}

export const MethodSectionTitle = React.memo<MethodSectionTitleProps>(
  ({ sectionIndex, title }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [sectionTitle, setSectionTitle] = useState(title);

    useEffect(() => {
      setSectionTitle(title);
    }, [title]);

    const onFocus = (
      event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => event.target.select();

    const onUpdateSectionTitle = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => setSectionTitle(event.currentTarget.value);

    const onBlur = () => {
      dispatch(updateMethodSectionTitle(sectionIndex, sectionTitle));
    };

    return (
      <EditableTitleInput
        data-cy="sectionTitle"
        classes={{ input: classes.title }}
        value={sectionTitle}
        onChange={onUpdateSectionTitle}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }
);

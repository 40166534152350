import React, { FunctionComponent, ReactNode, useState } from "react";

import { Button } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector, batch } from "react-redux";

import { Tag } from "../../../data/models/documentProperties/section";
import { addSectionTag } from "../../../store/data/current-document/action-creators/sections";
import { addSectionTags } from "../../../store/data/current-document/action-creators/sectionTags";
import { sectionTagsSelector } from "../../../store/data/current-document/selectors/sectionTags";
import { RootState } from "../../../store/reducers";
import { appTheme } from "../../../styling/style";
import { DisabledButtonWithToolTip } from "../../common/InfoTooltip";
import { SectionTagCreation } from "./components/SectionTagCreation";
import { SelectSectionsList } from "./components/SelectSectionsList";
import { BaseDialog } from "../BaseDialog";

interface CreateSectionTagDialogProps {
  dayIndex: number;
  sectionIndex: number;
  onClose: () => void;
}

export const CreateSectionTagDialog: FunctionComponent<CreateSectionTagDialogProps> = ({
  dayIndex,
  sectionIndex,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [selectedSections, setSelectedSections] = useState<
    { day: number; section: number }[]
  >([{ day: dayIndex, section: sectionIndex }]);

  const documentSectionTags: Tag[] = useSelector<RootState, Tag[]>(
    sectionTagsSelector
  );

  const [tagLabel, setTagLabel] = useState("");
  const [colorIndex, setColorIndex] = useState(0);

  const onCreateNewTag = (tag: Tag) => {
    batch(() => {
      dispatch(addSectionTags([tag]));
      for (const section of selectedSections) {
        dispatch(addSectionTag(section.day, section.section, tag.id));
      }
    });
  };

  const onClickCreateTag = () => {
    onCreateNewTag({
      id: uuidv4(),
      label: tagLabel,
      activeColor: appTheme.colors.activeTags[colorIndex],
      inactiveColor: appTheme.colors.inactiveTags[colorIndex],
    });
  };

  const allSectionTagLabels: string[] = documentSectionTags.map(
    (tag: Tag): string => tag.label
  );

  const dialogActions: ReactNode = [
    <Button onClick={onClose} color="default">
      Cancel
    </Button>,
    <DisabledButtonWithToolTip
      disabled={!tagLabel || allSectionTagLabels.includes(tagLabel.trim())}
      label="Create section tag"
      disabledTooltip="Tag label must be unique"
      buttonProps={{ onClick: onClickCreateTag, color: "secondary" }}
    />,
  ];

  const body: ReactNode = [
    <SectionTagCreation
      tagLabel={tagLabel}
      colorIndex={colorIndex}
      setTagLabel={setTagLabel}
      setColorIndex={setColorIndex}
    />,
    <SelectSectionsList
      selectedSections={selectedSections}
      setSelectedSections={setSelectedSections}
      disabled={false}
    />,
  ];

  return (
    <BaseDialog
      onClose={onClose}
      open={true}
      title="Create new section tag"
      body={body}
      action={dialogActions}
      maxWidth="md"
    ></BaseDialog>
  );
};

import Firebase from "../index";
import { Document } from "../../models/document";
import { FoodId } from "../../models/documentProperties/foodId";

export const fetchDocument = async (
  firebase: Firebase,
  foodId: FoodId,
  isPublic: boolean
): Promise<Document> => {
  const documentData: Document = isPublic
    ? await firebase?.publicDatabases.doGetPublicDocument(
        foodId.datasourceId,
        foodId.documentId
      )
    : await firebase?.userDatabases.doGetUserDocument(
        foodId.datasourceId,
        foodId.documentId
      );

  if (!documentData) {
    // error handling
    // this wont be documentData!
    return documentData!;
  }

  return documentData;
};

import _ from "lodash";
import { createSelector } from "reselect";

import { Client, ClientSummary } from "../../../data/models/client";
import { Tag } from "../../../data/models/documentProperties/section";
import {
  NRVType,
  NRVTypeOrder,
  NutrientReferenceValue,
} from "../../../data/models/nutrientReferenceValues";
import { RootState } from "../../reducers";
import { enabledNutrientsSelector } from "./databaseProperties";

export const clientUserDatabaseSelector = (state: RootState): string =>
  state.clientDatabase.databaseId;

export const currentClientSelector = (state: RootState): Client =>
  state.clientDatabase.currentClient;

export const serverClientSelector = (state: RootState): Client | undefined =>
  state.clientDatabase.serverClient;

export const currentClientIdSelector = (state: RootState): string =>
  state.clientDatabase.clientId;

export const clientSummariesSelector = (state: RootState): ClientSummary[] =>
  state.clientDatabase.clientSummaries;

export const clientDatabaseTagsSelector = (state: RootState): Tag[] =>
  state.clientDatabase.tags;

export const currentClientNRVSelector = (
  state: RootState
): NutrientReferenceValue[] => state.clientDatabase.currentClientNRV;

export const mapNRVTypeToValue = (
  values: NutrientReferenceValue[]
): Map<NRVType, number> =>
  new Map(
    _.chain(values)
      .groupBy("type")
      .map((value, type): [NRVType, number] => [
        type as NRVType,
        value[0].value,
      ])
      .value()
  );

export const groupNRVsByNutrient = (
  values: NutrientReferenceValue[]
): [string, Map<NRVType, number>][] =>
  _.chain(values)
    .groupBy("nutrient")
    .map((nrv, nutrient): [string, Map<NRVType, number>] => [
      nutrient,
      mapNRVTypeToValue(nrv),
    ])
    .value();

export const getNRVsForEnabledNutrients = (
  values: NutrientReferenceValue[],
  enabledNutrients: string[]
): Map<string, Map<NRVType, number>> =>
  new Map(
    groupNRVsByNutrient(values).filter(([id]) => enabledNutrients.includes(id))
  );

export const nutrientReferenceValuesSelector = createSelector(
  currentClientNRVSelector,
  enabledNutrientsSelector,
  (clientNutrientReferenceValues, enabledNutrients) =>
    getNRVsForEnabledNutrients(clientNutrientReferenceValues, enabledNutrients)
);

export const mapNutrientToValue = (
  values: NutrientReferenceValue[]
): Map<string, number> =>
  new Map(
    _.chain(values)
      .groupBy("nutrient")
      .map((value, nutrient): [string, number] => [
        nutrient as string,
        value[0].value,
      ])
      .value()
      .filter(item => item[1] !== 0)
  );

export const groupNutrientsByNRVType = (
  values: NutrientReferenceValue[]
): [NRVType, Map<string, number>][] =>
  _.chain(values)
    .groupBy("type")
    .map(
      (
        nrvs: NutrientReferenceValue[],
        type
      ): [NRVType, Map<string, number>] => [
        type as NRVType,
        mapNutrientToValue(nrvs),
      ]
    )
    .value()
    .filter(item => (item[0] as string) !== "null")
    .sort(([aType], [bType]) => {
      if (
        NRVTypeOrder.get(aType as string) === NRVTypeOrder.get(bType as string)
      )
        return 0;
      return NRVTypeOrder.get(aType as string)! >
        NRVTypeOrder.get(bType as string)!
        ? 1
        : -1;
    });

export const filterEnabledNutrientsForNRVs = (
  values: NutrientReferenceValue[],
  enabledNutrients: string[]
): NutrientReferenceValue[] =>
  values.filter(nrv => enabledNutrients.includes(nrv.nutrient));

export const nutrientsPerNRVSelector = createSelector(
  currentClientNRVSelector,
  enabledNutrientsSelector,
  (clientNutrientReferenceValues, enabledNutrients) =>
    new Map(
      groupNutrientsByNRVType(
        filterEnabledNutrientsForNRVs(
          clientNutrientReferenceValues,
          enabledNutrients
        )
      )
    )
);

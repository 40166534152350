import _ from "lodash";

import {
  ClientProfile,
  clientProfileConverter,
} from "./clientProperties/clientProfile";
import { ContactDetails } from "./clientProperties/contactDetails";
import { FoodWorksDate } from "./documentProperties/date";
import { ClientGoal } from "./clientProperties/clientGoal";
import { Measurement } from "./clientProperties/measurement";
import { DocumentStatus } from "./userDatabase";

export interface Client {
  name: string;
  profile: ClientProfile;
  documents: string[];
  contact: ContactDetails;
  notes: string;
  date: FoodWorksDate;
  tagIds: string[];
  goals: ClientGoal[];
  measurements: Measurement[];
  state: DocumentStatus;
}

export const clientsAreEqual = (
  a: Client | undefined,
  b: Client | undefined
): boolean => _.isEqual(a, b);

export interface ClientSummary {
  clientId: string;
  date: FoodWorksDate;
  label: string;
  tagIds: string[];
  state: DocumentStatus;
}

export const clientSummariesAreEqual = (
  a: ClientSummary,
  b: ClientSummary
): boolean => _.isEqual(a, b);

export const clientConverter = {
  toFirestore: function (client: Client): firebase.firestore.DocumentData {
    return {
      name: client.name,
      documents: client.documents,
      profile: clientProfileConverter.toFirestore(client.profile),
      contact: client.contact,
      notes: client.notes,
      date: client.date,
      tagIds: client.tagIds,
      goals: client.goals,
      measurements: client.measurements,
      state: client.state,
    };
  },
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): Client {
    const data = snapshot.data();

    return {
      name: data.name,
      documents: data.documents,
      profile: clientProfileConverter.fromFirestore(data.profile),
      contact: data.contact,
      notes: data.notes,
      date: data.date,
      tagIds: data.tagIds,
      goals: data.goals,
      measurements: data.measurements,
      state: data.state,
    };
  },
};

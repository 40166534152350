import firebase, { firestore } from "firebase";
import _ from "lodash";

import {
  NutrientMap,
  CategoryMap,
  ReferenceMeasureMap,
  RetentionFactorProfileMap,
  RetentionFactorGroupMap,
  DataSourceMap,
} from "../../../store/data/reducers/referenceData";
import { Category, categoryConverter } from "../../models/category";
import { DataSource, dataSourceConverter } from "../../models/datasource";
import {
  RetentionFactorProfile,
  retentionFactorProfilesConverter,
  NutrientRetentionValue,
  nutrientRetentionValuesConverter,
} from "../../models/documentProperties/retentionFactor";
import { Nutrient, nutrientConverter } from "../../models/nutrient";
import {
  ERVInfoConverter,
  NRVInfoConverter,
} from "../../models/nutrientReferenceValues";
import {
  ReferenceMeasureObject,
  referenceMeasureConverter,
} from "../../models/referenceMeasure";
import Firebase from "../firebase";

export class FirebaseReferenceData {
  firestore: firebase.firestore.Firestore;
  collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(firestore: firebase.firestore.Firestore) {
    this.firestore = firestore;
    this.collection = firestore.collection("reference-data");
  }

  async doFetchNutrients(): Promise<NutrientMap> {
    const data: firestore.QuerySnapshot<Nutrient> = await Firebase.cachedCollectionGet(
      this.firestore
        .collection("reference-data")
        .doc("nutrients")
        .collection("definitions")
        .withConverter(nutrientConverter)
    );
    const nutrientMap: NutrientMap = {};

    for (const nutrient of data.docs) {
      nutrientMap[nutrient.id!] = nutrient.data()!;
    }

    return nutrientMap;
  }

  async doFetchCategories(): Promise<CategoryMap> {
    const data: firestore.QuerySnapshot<Category> = await Firebase.cachedCollectionGet(
      this.firestore
        .collection("reference-data")
        .doc("nutrients")
        .collection("categories")
        .withConverter(categoryConverter)
    );

    const categoryMap: CategoryMap = {};

    for (const category of data.docs) {
      categoryMap[category.id!] = category.data()!;
    }

    return categoryMap;
  }

  async doFetchReferenceMeasures(): Promise<ReferenceMeasureMap> {
    const data: firestore.QuerySnapshot<ReferenceMeasureObject> = await Firebase.cachedCollectionGet(
      this.firestore
        .collection("reference-data")
        .doc("reference-measures")
        .collection("definitions")
        .withConverter(referenceMeasureConverter)
    );

    const referenceMeasureMap: ReferenceMeasureMap = {};

    for (const referenceMeasure of data.docs) {
      referenceMeasureMap[referenceMeasure.id!] = referenceMeasure.data()!;
    }

    return referenceMeasureMap;
  }

  async doFetchRetentionFactorProfiles(): Promise<RetentionFactorProfileMap> {
    const data: firestore.QuerySnapshot<
      RetentionFactorProfile[]
    > = await Firebase.cachedCollectionGet(
      this.firestore
        .collection("reference-data")
        .doc("retention-factors")
        .collection("profiles")
        .withConverter(retentionFactorProfilesConverter)
    );

    const retentionFactorProfileMap: RetentionFactorProfileMap = {};

    for (const profiles of data.docs) {
      retentionFactorProfileMap[profiles.id!] = profiles.data()!;
    }

    return retentionFactorProfileMap;
  }

  async doFetchNutrientRetentionValue(
    profileId: string
  ): Promise<firestore.DocumentSnapshot<NutrientRetentionValue[]>> {
    return Firebase.cachedDocumentGet(
      this.firestore
        .collection("reference-data")
        .doc("retention-factors")
        .collection("nutrient-retention-values")
        .doc(profileId)
        .withConverter(nutrientRetentionValuesConverter)
    );
  }

  async doFetchRetentionFactorGroups(): Promise<RetentionFactorGroupMap> {
    const data: firestore.DocumentSnapshot = await Firebase.cachedDocumentGet(
      this.firestore.collection("reference-data").doc("retention-factors")
    );

    const groupMap: RetentionFactorGroupMap = data.data()!.groups;

    return groupMap;
  }

  async doFetchDataSources(): Promise<DataSourceMap> {
    const data: firestore.QuerySnapshot<DataSource> = await Firebase.cachedCollectionGet(
      this.firestore
        .collection("public-databases")
        .withConverter(dataSourceConverter)
    );

    const dataSourceMap: DataSourceMap = {};

    for (const dataSource of data.docs) {
      dataSourceMap[dataSource.id!] = dataSource.data()!;
    }

    return dataSourceMap;
  }

  async doFetchNutrientReferenceValue(category: string, age: number) {
    const data = await this.firestore
      .collection("reference-data")
      .doc("nutrient-reference-values")
      .collection("values")
      .where("category", "==", category)
      .where("fromAge", "<", age)
      .withConverter(NRVInfoConverter)
      .get();

    const data2 = await this.firestore
      .collection("reference-data")
      .doc("nutrient-reference-values")
      .collection("values")
      .where("category", "==", category)
      .where("toAge", ">", age)
      .withConverter(NRVInfoConverter)
      .get();

    const result1 = data.docs.map((docData) => ({
      id: docData.id,
      value: docData.data(),
    }));
    const result2 = data2.docs.map((docData) => ({
      id: docData.id,
      value: docData.data(),
    }));

    return _.intersectionWith(
      result1,
      result2,
      (o1, o2) => o1.id === o2.id
    ).map((data) => data.value);
  }

  async doFetchEnergyReferenceValue(sex: string, age: number) {
    const data = await this.collection
      .doc("nutrient-reference-values")
      .collection("energy-values")
      .where("sex", "==", sex)
      .where("fromAge", "<", age)
      .withConverter(ERVInfoConverter)
      .get();

    const data2 = await this.firestore
      .collection("reference-data")
      .doc("nutrient-reference-values")
      .collection("energy-values")
      .where("sex", "==", sex)
      .where("toAge", ">=", age)
      .withConverter(ERVInfoConverter)
      .get();

    const result1 = data.docs.map((docData) => ({
      id: docData.id,
      value: docData.data(),
    }));
    const result2 = data2.docs.map((docData) => ({
      id: docData.id,
      value: docData.data(),
    }));

    return _.intersectionWith(result1, result2, (o1, o2) => o1.id === o2.id)[0]
      .value;
  }
}

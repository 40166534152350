import React, { useState } from "react";
import {
  Collapse,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import _ from "lodash";

import {
  MeasurementCategory,
  MeasurementInfo,
} from "../../../../../../data/models/clientProperties/measurement";
import AddMeasurementCard from "./AddMeasurementCard";
import TrackedItemCard from "../tracking-card/TrackedItemCard";

const useStyles = makeStyles(() => ({
  header : {
    display : "flex", alignItems : "center", gap : "1rem"
  },
  divider : {
    margin : "0rem 0.5rem"
  }
}))

interface TrackingCategoryProps {
  measurements: MeasurementInfo[];
  category: MeasurementCategory;
}

const compareProps = (
  previousProps: TrackingCategoryProps,
  nextProps: TrackingCategoryProps
): boolean => _.isEqual(previousProps, nextProps);

const TrackingCategory = React.memo<TrackingCategoryProps>(
  (props): JSX.Element => {

    const classes = useStyles();
    
    const { measurements, category } = { ...props };

    const [expanded, setExpanded] = useState(true)

    return (
      <>
      <div>
        <div className={classes.header} >
        <IconButton
        onClick={() => setExpanded(expanded => !expanded)} 
        size="small" 
        color="secondary">
          {expanded ? <ExpandLess/> : <ExpandMore />}
          </IconButton>
          <Typography variant="h2">{category}</Typography>
        </div>
          <Divider className={classes.divider}/>
        </div>
        <Collapse in={expanded}>
          <Grid container justify="flex-start">
            {measurements.map((measurement: MeasurementInfo) => {
              return measurement.category === category ? (
                <Grid
                  key={`${measurement.label}-${measurement.unit}-${category}`}
                  item
                >
                  <TrackedItemCard
                    measurementTitle={measurement.label}
                    measurementUnits={measurement.unit}
                    measurementCategory={measurement.category}
                    measurementSubCategory={measurement.subCategory}
                  />
                </Grid>
              ) : null;
            })}
            <Grid item>
              <AddMeasurementCard category={category as MeasurementCategory} />
            </Grid>
          </Grid>
          </Collapse>
         </>)
  },
  compareProps
);

export default TrackingCategory;

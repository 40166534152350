import React from "react";
import { PersonOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { clientNameSelector } from "../../../../store/data/current_client/selectors/client";
import { RootState } from "../../../../store/reducers";
import { handleRouteChange } from "../../../../store/ui/thunks/routing";
import { currentClientIdSelector } from "../../../../store/data/selectors/clientDatabase";
import {
  getClientDashboardRouteData,
  getClientRouteData,
} from "../../../../data/routing/routing";
import { currentRouteSelector } from "../../../../store/ui/selectors/routing";
import NavigationHeader from "../shared/NavigationHeader";
import { RouteData } from "../../../../data/routing/types";

const ClientNavHeader = (): JSX.Element => {
  const dispatch = useDispatch();

  const onNavigateToClientsOverview = () =>
    dispatch(
      handleRouteChange(
        currentRoute?.documentId
          ? getClientRouteData(currentClientId)
          : getClientDashboardRouteData()
      )
    );

  const currentRoute: RouteData | undefined =
    useSelector<RootState, RouteData | undefined>(currentRouteSelector);

  const currentClientId: string = useSelector<RootState, string>(
    currentClientIdSelector
  );

  const clientName: string = useSelector<RootState, string>(clientNameSelector);

  return (
    <NavigationHeader
      text={clientName}
      icon={<PersonOutlined color="secondary" />}
      onClick={onNavigateToClientsOverview}
    />
  );
};

export default ClientNavHeader;

import React from "react";

import { Routing } from "./routing";

const RoutingContext = React.createContext<Routing | null>(null);

export const withRouting = <P extends object>(
  Component: React.ComponentType<P>
) =>
  class WithRouting extends React.Component<P> {
    render() {
      return (
        <RoutingContext.Consumer>
          {(routing) => <Component {...this.props} routing={routing} />}
        </RoutingContext.Consumer>
      );
    }
  };

export default RoutingContext;

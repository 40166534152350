import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { FeedbackDialog } from "../../../dialogs/FeedbackDialog";
import { addAlert } from "../../../../store/ui/actionCreators/alert";
import { appTheme } from "../../../../styling/style";

const useStyles = makeStyles(() => ({
  bottomNavButtons: {
    marginBottom: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomNavText: {
    fontSize: 10,
    marginRight: 5,
    "&:hover": {
      color: appTheme.colors.help,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  supportLink: {
    fontSize: 10,
    marginLeft: 5,
    color: appTheme.colors.xiketic,
    textDecoration: "none",
    alignContent: "center",
    "&:hover": {
      color: appTheme.colors.help,
      textDecoration: "underline",
    },
  },
  learnMoreTypography: {
    justifyContent: "center",
    display: "flex",
  },
}));

const SupportButtonBar = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onAddAlert = (message: string) => dispatch(addAlert(message));

  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  return (
    <div className={classes.bottomNavButtons}>
      <Typography
        onClick={() => setShowFeedbackDialog(true)}
        className={classes.bottomNavText}
      >
        Give feedback
      </Typography>

      <Typography className={classes.learnMoreTypography}>
        <a
          className={classes.supportLink}
          href="https://support.foodworks.online/hc/en-au"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </Typography>
      {showFeedbackDialog && (
        <FeedbackDialog
          onClose={() => setShowFeedbackDialog(false)}
          onSend={() => {
            setShowFeedbackDialog(false);
            onAddAlert("Thank you for your feedback!");
          }}
        />
      )}
    </div>
  );
};

export default SupportButtonBar;

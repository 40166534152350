import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Button,
  Card,
  CardContent,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { BaseDialog } from "../BaseDialog";
import { RootState } from "../../../store/reducers";
import { ReferenceMeasure } from "../../../data/models/referenceMeasure";
import { ReferenceMeasuresSelector } from "../../../store/data/selectors/referenceData";
import { enabledMeasuresSelector } from "../../../store/data/selectors/databaseProperties";
import { appTheme } from "../../../styling/style";
import { updateEnabledMeasures } from "../../../store/data/thunks/databaseProperties";
import {
  CopyDatabaseProperty,
  CopyPropertiesButton,
} from "../../common/CopyPropertiesButton";

const useStyles = makeStyles((theme) => ({
  listButton: {
    borderRadius: 4,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
    padding: "0px",
    alignItems: "center",
  },
  selectedListButton: {
    borderRadius: 4,
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
    padding: "0px",
    alignItems: "center",
  },
  sectionContainer: {
    margin: "10px 0px",
  },
  overflow: {
    display: "flex",
    flex: 1,
    overflow: "auto",
  },
  content: {
    width: "100%",
    height: 500,
  },
}));

interface ReferenceMeasureDialogProps {
  open: boolean;
  onClose: () => void;
}

interface ReferenceMeasureValues {
  displayName?: string;
  isOpen: boolean;
}

export const ReferenceMeasureDialog: FunctionComponent<ReferenceMeasureDialogProps> =
  ({ open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onUpdateEnabledMeasures = (enabledMeasures: string[]) =>
      dispatch(updateEnabledMeasures(enabledMeasures));

    const referenceMeasures: ReferenceMeasure[] = useSelector<
      RootState,
      ReferenceMeasure[]
    >(ReferenceMeasuresSelector);
    const enabledMeasures: string[] = useSelector<RootState, string[]>(
      enabledMeasuresSelector
    );

    const [currentMeasures, setCurrentMeasures] = useState<
      Map<string, ReferenceMeasureValues>
    >(new Map());

    const loadCurrentMeasures = useCallback(
      (enabledMeasures: string[]) => {
        const measureMap = new Map<string, ReferenceMeasureValues>();
        for (const measure of referenceMeasures) {
          measureMap.set(measure.id, {
            displayName: measure.name,
            isOpen: enabledMeasures.includes(measure.id),
          });
        }
        setCurrentMeasures(measureMap);
      },
      [referenceMeasures]
    );

    useEffect(() => {
      loadCurrentMeasures(enabledMeasures);
    }, [enabledMeasures, loadCurrentMeasures]);

    const handleOnClick = (id: string) => {
      const value = currentMeasures.get(id);
      const newValue = { ...value, isOpen: !value?.isOpen };
      setCurrentMeasures(new Map(currentMeasures.set(id, newValue)));
    };

    const applyChanges = () => {
      const enabledIds = [];
      for (const [key, value] of currentMeasures) {
        if (value.isOpen) enabledIds.push(key);
      }
      onUpdateEnabledMeasures(enabledIds);
      onClose();
    };

    const handleClose = () => {
      loadCurrentMeasures(enabledMeasures);
      onClose();
    };

    const checkBoxes: ReactNode = [...currentMeasures].map(
      ([id, referenceMeasure]: [string, ReferenceMeasureValues]) => (
        <MenuItem
          className={
            referenceMeasure.isOpen
              ? classes.selectedListButton
              : classes.listButton
          }
          key={id}
          button
          onClick={() => handleOnClick(id)}
        >
          <Checkbox
            data-cy={
              referenceMeasure.isOpen
                ? "referenceMeasureCheckboxTrue"
                : "referenceMeasureCheckboxFalse"
            }
            name={id}
            checked={referenceMeasure.isOpen}
          />
          <Typography>{referenceMeasure.displayName}</Typography>
        </MenuItem>
      )
    );

    const body: ReactNode = [
      <Typography>Select the units of measurement shown when setting the quantity of a recipe or food.{"\n"}
      Volume measures are only shown if the density of the food is known.</Typography>,
      <br/>,
      <Typography
        key="referenceMeasureDialog-bodyTypography"
        className={classes.sectionContainer}
      >
        Available reference measures
      </Typography>,
      <Card key="referenceMeasureDialog-bodyCard" className={classes.overflow}>
        <CardContent className={classes.content}>{checkBoxes}</CardContent>
      </Card>,
      <CopyPropertiesButton
        key="referenceMeasureDialog=CopyProp"
        databaseProperty={CopyDatabaseProperty.ENABLED_MEASURES}
        fetchDatabaseProperties={(ids: string[]) => loadCurrentMeasures(ids)}
      />,
    ];

    const action: ReactNode = [
      <Button
        key="referenceMeasureDialog-cancel"
        data-cy="referenceMeasureCancel"
        onClick={handleClose}
      >
        Cancel
      </Button>,
      <Button
        key="referenceMeasureDialog-apply"
        data-cy="referenceMeasureApplyChanges"
        onClick={applyChanges}
      >
        Apply
      </Button>,
    ];

    return (
      <BaseDialog
        open={open}
        onClose={handleClose}
        title="Reference measures"
        body={body}
        action={action}
        maxWidth="sm"
        dataCy="referenceMeasureDialog"
      />
    );
  };

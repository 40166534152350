import React from "react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateRangeRounded } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  createMuiTheme,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  ThemeProvider,
  Typography,
} from "@material-ui/core";

import { appTheme } from "../../../styling/style";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginRight: 10,
  },
  inputRoot: {
    display: "flex",
    paddingRight: 0,
    "&.Mui-error": {
      border: `2px solid ${appTheme.colors.error}`,
      borderRadius: "4px",
    },
  },
  calendarInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: appTheme.colors.input,
    fontSize: appTheme.fontSize.textInput,
    fontFamily: appTheme.fontFamily.textInput,
    margin: 1,
    "&:hover": {
      background: appTheme.colors.inputHover,
    },
    "&:focus": {
      border: `2px solid ${theme.palette.secondary.main}`,
      margin: 0,
      background: appTheme.colors.white[0],
    },
    "&:disabled": {
      background: appTheme.colors.gainsbruh,
    },
  },
}));

const materialCalendarTheme = createMuiTheme({
  overrides: {
    //@ts-ignore
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: appTheme.colors.secondary,
      },
    },
    MuiPickersDay: {
      day: {
        color: appTheme.colors.xiketic,
      },
      daySelected: {
        color: appTheme.colors.primary,
        "&:nth-child(1)": {
          color: "white",
        },
      },
      dayDisabled: {
        color: appTheme.colors.gainsbruh,
      },
      current: {
        color: appTheme.colors.primary,
      },
    },
  },
});

interface FoodworksDatePickerProps {
  date: Date | null;
  maxDate?: ParsableDate;
  minDate?: ParsableDate;
  handleChange: (date: MaterialUiPickersDate) => void;
  label?: string;
  disabled?: boolean;
  clearable?: boolean;
}

const FoodworksDatePicker = (props: FoodworksDatePickerProps) => {
  const classes = useStyles();

  const { date, maxDate, minDate, handleChange, label, disabled, clearable } = {
    ...props,
  };

  const calendarInputProps = {
    width: 135,
    height: 30,
    paddingRight: 0,
    marginRight: 10,
  };
  return (
    <div className={classes.root}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialCalendarTheme}>
          <DatePicker
            clearable={clearable}
            autoOk
            size="small"
            variant="dialog"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            maxDate={maxDate}
            minDate={minDate}
            value={date}
            disabled={disabled}
            InputProps={{
              style: calendarInputProps,
              endAdornment: (
                <InputAdornment position={"end"}>
                  <IconButton disabled={disabled} size="small">
                    <DateRangeRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            TextFieldComponent={(params) => (
              <TextField
                {...params}
                className={classes.calendarInput}
                classes={{
                  root: classes.inputRoot,
                }}
              />
            )}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default FoodworksDatePicker;

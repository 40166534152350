export function applyNutrientOverridesToCompositionWrapper(documentObject) {
  return window.applyOverridesToComposition(documentObject);
}

export function applyRetentionFactorsToCompositionWrapper(documentObject) {
  return window.applyRetentionFactorsToComposition(documentObject);
}

export function applyYieldToCompositionWrapper(documentObject) {
  return window.applyYieldToComposition(documentObject);
}

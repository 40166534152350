import React, { useMemo } from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { DocumentSummary } from "../../data/models/userDatabase";
import useClientDocs from "../screens/clients/tabs/analysis/graphs/hooks/useClientDocs";
import { appTheme } from "../../styling/style";
import { FoodWorksTextInput } from "./FoodWorksTextInput";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fill: appTheme.colors.primary,
    color: appTheme.colors.primary,
  },
  dropBox: {
    backgroundColor: appTheme.colors.input,
    borderRadius: 8,
    fontSize: appTheme.fontSize.textInput,
    fontFamily: appTheme.fontFamily.textInput,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    margin: 5,
    "&:hover": {
      background: appTheme.colors.inputHover,
    },
    "&:focus": {
      border: `2px solid ${theme.palette.secondary.main}`,
      margin: 0,
      background: appTheme.colors.white[0],
    },
    "&:disabled": {
      background: appTheme.colors.gainsbruh,
    },
    width: 200,
  },
  title: {
    marginBottom: 0,
  },
  dropdownInput : {
    width : "400px"
  }
}));

interface DropdownProps {
  onChange: (id: string) => void;
}

interface MenuItemOption {
  name: string;
  value: string;
}

const getMenuItems = (summaries: DocumentSummary[]): MenuItemOption[] => {
  return summaries.map((document) => {
    return {
      name: document.label,
      value: document.documentId,
    };
  });
};

const DocumentDropdown = (props: DropdownProps): JSX.Element => {
  const classes = useStyles();

  const clientDocs = useClientDocs();
  
  const getMenuItemsMemo = useMemo(() => getMenuItems(clientDocs), [
    clientDocs,
  ]);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h3">
        Based on
      </Typography>
      <Autocomplete
        id="source-selector"
        options={getMenuItemsMemo as MenuItemOption[]}
        getOptionLabel={(option) => option.name}
        className={classes.dropBox}
        classes={{
          endAdornment: classes.icon,
        }}
        onChange={async (_: any, newOption: MenuItemOption | null) => {
          if (newOption) {
            props.onChange(newOption.value);
          }
        }}
        renderInput={(params) => (
          <div  ref={params.InputProps.ref}   className={classes.dropdownInput}>
          <FoodWorksTextInput 
              className={classes.dropdownInput}
              inputProps={params.inputProps} />
              </div>
        )}
        getOptionSelected={(option, value) => option.name === value.name}
      />
    </div>
  );
};

export default DocumentDropdown;

import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Collapse,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { activeClientMeasurementsSelector } from "../../../../../../store/data/current_client/selectors/clientMeasurements";
import { Measurement } from "../../../../../../data/models/clientProperties/measurement";
import TrackedMeasurementGraph from "../graphs/TrackedMeasurementGraph";
import { appTheme } from "../../../../../../styling/style";
import { TrackedMeasurementsProps } from "../types";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  graphContainer: {
    display: "flex",
    boxSizing: "border-box",
    width: "100%",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  accordion: {
    borderRadius: 10,
  },
  expandIcon: {
    color: appTheme.colors.primary,
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  divider: {
    margin: "0rem 0.5rem",
  },
}));

const generateGraphs = (measures: Measurement[], min: Date, max: Date) =>
  measures.map((measurement) => (
    <TrackedMeasurementGraph
      key={measurement.label + measurement.unit + measurement.category}
      label={measurement.label}
      units={measurement.unit}
      min={min}
      max={max}
    />
  ));

const TrackedMeasurements = (props: TrackedMeasurementsProps) => {
  const classes = useStyles();

  const { min, max } = { ...props };

  const [expanded, setExpanded] = useState(true);

  const measures: Measurement[] = useSelector(activeClientMeasurementsSelector);

  const graphs = useMemo(
    () => generateGraphs(measures, min, max),
    [measures, min, max]
  );

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.header}>
          <IconButton
            onClick={() => setExpanded((expanded) => !expanded)}
            size="small"
            color="secondary"
          >
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography variant="h2">Tracked measurements</Typography>
        </div>
        <Divider className={classes.divider} />
      </div>
      <Collapse in={expanded}>
        <div className={classes.graphContainer}>{graphs}</div>
      </Collapse>
    </div>
  );
};

export default TrackedMeasurements;

import { InputBase, withStyles, createStyles, Theme } from "@material-ui/core";

import { appTheme, h1Style } from "../../styling/style";

export const EditableTitleInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    input: {
      ...h1Style,
      borderRadius: 4,
      position: "relative",
      backgroundColor: appTheme.colors.white[0],
      border: "none",
      margin: 2,
      "&:hover": {
        background: appTheme.colors.inputHover,
      },
      "&:focus": {
        border: `2px solid ${theme.palette.secondary.main}`,
        margin: 0,
        backgroundColor: appTheme.colors.white[0],
      },
    },
  })
)(InputBase);

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Divider, makeStyles } from "@material-ui/core";

import CollapsePaneButton from "../shared/CollapsePaneButton";
import NavigationPaneBody from "../shared/NavigationPaneBody";
import ClientDatabaseNavHeader from "./ClientDatabaseNavHeader";
import CreateClientButton from "./CreateClientButton";
import ClientDatabaseNavigationList from "./ClientDatabaseNavigationList";
import NavigationFilter from "../shared/NavigationFilter";
import { RootState } from "../../../../store/reducers";
import { NavIsLoadingSelector } from "../../../../store/ui/selectors/navigationSelectors";
import { Tag } from "../../../../data/models/documentProperties/section";
import { clientDatabaseTagsSelector } from "../../../../store/data/selectors/clientDatabase";
import { ClientSummary } from "../../../../data/models/client";
import useClients from "../hooks/useClients";
import FoodWorksTagSwitch from "../../../common/FoodWorksTagSwitch";
import NavigationPaneMessage from "../shared/NavigationPaneMessage";
import { DocumentStatus } from "../../../../data/models/userDatabase";

const useStyles = makeStyles(() => ({
  topButtons: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  currentDatabaseDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "15px 0px",
    overflow: "hidden",
  },
  switchContainer: {
    margin: "5px 0px",
  },
}));

const ClientDatabaseNavigation = (): JSX.Element => {
  const classes = useStyles();

  const navIsLoading: boolean =
    useSelector<RootState, boolean>(NavIsLoadingSelector);

  const [open, setOpen] = useState(true);

  const clientDatabaseTags: Tag[] = useSelector<RootState, Tag[]>(
    clientDatabaseTagsSelector
  );

  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState<DocumentStatus[]>([]);

  const [showTags, setShowTags] = useState<boolean>(false);

  const [clients, hasNonDeletedDocuments, deleteFilterApplied]: [
    ClientSummary[],
    boolean,
    boolean
  ] = useClients(searchText, filters);
  return (
    <NavigationPaneBody open={open} setOpen={setOpen}>
      <div className={classes.topButtons}>
        <div className={classes.currentDatabaseDiv}>
          <ClientDatabaseNavHeader />
          <CollapsePaneButton
            type="Navigation"
            open={open}
            setOpen={(open) => setOpen(open)}
          />
        </div>
        <CreateClientButton />
      </div>
      <NavigationFilter
        availableTags={clientDatabaseTags}
        foodFilters={[]}
        fetchSearchText={setSearchText}
        searchText={searchText}
        isClient={true}
        setClientFilters={setFilters}
      />
      <Divider />
      <div className={classes.switchContainer}>
        <FoodWorksTagSwitch
          label="Show client tags"
          checked={showTags}
          onChange={() => setShowTags((prev) => !prev)}
        />
      </div>
      <NavigationPaneMessage
        isLoading={navIsLoading}
        deletedDocumentFilterApplied={deleteFilterApplied}
        hasNonDeletedDocuments={hasNonDeletedDocuments}
        hasDocumentsToDisplay={!!clients.length}
        itemType="client"
      />
      <ClientDatabaseNavigationList
        showTags={showTags}
        visibleClients={clients}
        searchText={searchText}
      />
    </NavigationPaneBody>
  );
};

export default ClientDatabaseNavigation;

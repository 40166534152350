import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { FoodTemplate } from "../../../../constants/FoodTemplate";
import DocumentMenu from "../../../common/DocumentMenu";
import { RootState } from "../../../../store/reducers";
import {
  currentClientIdSelector,
  currentClientSelector,
  serverClientSelector,
} from "../../../../store/data/selectors/clientDatabase";
import { Client, clientsAreEqual } from "../../../../data/models/client";
import { DisabledButtonWithToolTip } from "../../../common/InfoTooltip";
import createNewDocument from "../../../../store/data/current-document/thunks/document/createNewDocument";

const useStyles = makeStyles(() => ({
  newDocumentButton: {
    overflow: "hidden",
    alignItems: "center",
    padding: 6,
  },
}));

interface CreateDocumentButtonProps {
  options: FoodTemplate[];
}

const CreateDocumentButton = React.memo<CreateDocumentButtonProps>(
  ({ options }): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onCreateNewDocument = (template: FoodTemplate) =>
      dispatch(createNewDocument(template));

    const currentClientId: string = useSelector<RootState, string>(
      currentClientIdSelector
    );

    const currentClient: Client = useSelector<RootState, Client>(
      currentClientSelector
    );

    const serverClient: Client | undefined =
      useSelector<RootState, Client | undefined>(serverClientSelector);

    const invalidClient: boolean =
      !!currentClientId && !clientsAreEqual(currentClient, serverClient);

    const [docAnchor, setDocAnchor] = useState<null | HTMLElement>(null);

    return (
      <div>
        <DisabledButtonWithToolTip
          buttonProps={{
            className: classes.newDocumentButton,
            variant: "contained",
            color: invalidClient ? "inherit" : "secondary",
            size: "small",
            onClick: (event) => setDocAnchor(event.currentTarget),
            endIcon: <ArrowDropDown />,
          }}
          data-cy="createButton"
          disabledTooltip={
            "You must first save your client before you can create a new document."
          }
          disabled={invalidClient}
          label={
            <Typography
              color={invalidClient ? "inherit" : "primary"}
              variant="h3"
            >
              New document
            </Typography>
          }
        />
        <DocumentMenu
          handleClose={() => setDocAnchor(null)}
          anchorElement={docAnchor}
          onClick={(template: FoodTemplate) => {
            onCreateNewDocument(template);
            setDocAnchor(null);
          }}
          options={options}
        />
      </div>
    );
  }
);

export default CreateDocumentButton;

import _ from "lodash";

import Firebase from "../../../../../data/Firebase";
import {
  getUniqueDocumentIds,
  Document,
} from "../../../../../data/models/document";
import { DayState } from "../../../../../data/models/documentProperties/day";
import {
  getIdentifierParts,
  getFoodId,
  getIdentifier,
} from "../../../../../data/models/documentProperties/foodId";
import { FoodItemState } from "../../../../../data/models/documentProperties/foodItem";
import { SectionState } from "../../../../../data/models/documentProperties/section";

const updateDocumentsToAdd = (
  documentsToAdd: string[],
  newDocuments: string[]
): void => {
  documentsToAdd.push(..._.without(newDocuments, ...documentsToAdd));
};

const filterOutCheckedIds = (
  checkedDocuments: string[],
  newDocuments: string[]
): string[] =>
  newDocuments.filter((id: string) => !checkedDocuments.includes(id));

const getMappedDocumentId = (
  databaseIds: string[],
  document: Document
): string[] =>
  document.documentMappingId &&
  databaseIds.includes(getIdentifierParts(document.documentMappingId)[0])
    ? [document.documentMappingId]
    : [];

const getChildDocumentIds = (
  document: Document,
  checkedIds: string[],
  documentsToAdd: string[],
  databaseIds: string[]
): Document => {
  updateDocumentsToAdd(
    documentsToAdd,
    filterOutCheckedIds(checkedIds, [
      ..._.without(
        getMappedDocumentId(databaseIds, document),
        ...getUniqueDocumentIds(databaseIds, document)
      ),
    ])
  );
  return document;
};

const processDocument = (
  identifier: string,
  document: Document,
  checkedIds: string[],
  documentsToAdd: string[],
  databaseIds: string[],
  documents: Map<string, Document>
): void => {
  checkedIds.push(identifier);
  documents.set(
    identifier,
    getChildDocumentIds(document, checkedIds, documentsToAdd, databaseIds)
  );
};

const processDocumentsToImport = async (
  checkedDocuments: string[],
  documentsToAdd: string[],
  documents: Map<string, Document>,
  databaseIds: string[],
  index: number,
  firebase: Firebase
): Promise<Map<string, Document>> =>
  documentsToAdd.length
    ? firebase.userDatabases
        .doGetUserDocument(...getIdentifierParts(documentsToAdd[index]))
        .then((document: Document) =>
          Promise.resolve(
            processDocument(
              documentsToAdd[index],
              document,
              checkedDocuments,
              documentsToAdd,
              databaseIds,
              documents
            )
          ).then(() => {
            if (++index < documentsToAdd.length) {
              return processDocumentsToImport(
                checkedDocuments,
                documentsToAdd,
                documents,
                databaseIds,
                index,
                firebase
              );
            }
            return documents;
          })
        )
    : documents;

const createCopyOfDocument = async (
  oldDocumentIdentifier: string,
  document: Document,
  databaseId: string,
  newDocumentIdMappingPromise: Promise<Map<string, string>>,
  firebase: Firebase
): Promise<Map<string, string>> =>
  firebase.userDatabases
    .doCreateUserDocument(databaseId, document)
    .then((documentData) =>
      newDocumentIdMappingPromise
        .then((newDocumentIdMapping) =>
          newDocumentIdMapping.set(
            oldDocumentIdentifier,
            getIdentifier(databaseId, documentData.id)
          )
        )
        .then((mapping) => mapping)
    );

const copyDocumentsToDatabase = async (
  documentsToImport: Map<string, Document>,
  databaseId: string,
  firebase: Firebase
): Promise<Map<string, string>> =>
  [...documentsToImport].reduce<Promise<Map<string, string>>>(
    async (newDocumentIdMap, [id, document]) =>
      createCopyOfDocument(
        id,
        document,
        databaseId,
        newDocumentIdMap,
        firebase
      ),
    Promise.resolve(new Map())
  );

const updateFoodItem = (
  item: FoodItemState,
  documentIdMappings: Map<string, string>
): FoodItemState => {
  return {
    ...item,
    foodId: getFoodId(
      documentIdMappings.get(
        getIdentifier(item.foodId!.datasourceId, item.foodId!.documentId)
      )!
    ),
  };
};

const mapFoodItemsWithNewIds = (
  days: DayState[],
  documentIdMappings: Map<string, string>
): DayState[] =>
  days.map(
    (day: DayState): DayState => {
      return {
        ...day,
        sections: day.sections.map(
          (section: SectionState): SectionState => {
            return {
              ...section,
              foodItems: section.foodItems.map(
                (item: FoodItemState): FoodItemState =>
                  item.foodId &&
                  documentIdMappings.has(
                    getIdentifier(
                      item.foodId.datasourceId,
                      item.foodId.documentId
                    )
                  )
                    ? updateFoodItem(item, documentIdMappings)
                    : item
              ),
            };
          }
        ),
      };
    }
  );

const updateReferencesToParentDocuments = (
  ids: string[],
  documentIdMappings: Map<string, string>
): string[] =>
  ids.map((id: string): string =>
    documentIdMappings.has(id) ? documentIdMappings.get(id)! : id
  );

const resolveDocumentNameConflict = (
  documentName: string,
  existingDocumentNames: string[]
): string => {
  let index = 0;
  let name: string = documentName;
  while (existingDocumentNames.includes(name)) {
    name = index
      ? `${documentName} -imported (${index})`
      : `${documentName} -imported`;
    ++index;
  }
  return name;
};

const updateDocumentMappingId = (
  mappedToDocument: string,
  documentIdMappings: Map<string, string>
): string =>
  documentIdMappings.has(mappedToDocument)
    ? documentIdMappings.get(mappedToDocument)!
    : mappedToDocument;

const updateDocumentWitNewIds = (
  document: Document,
  documentIdMappings: Map<string, string>,
  existingDocumentNames: string[]
): Document => {
  return {
    ...document,
    days: mapFoodItemsWithNewIds(document.days, documentIdMappings),
    usedIn: updateReferencesToParentDocuments(
      document.usedIn,
      documentIdMappings
    ),
    name: resolveDocumentNameConflict(document.name, existingDocumentNames),
    documentMappingId: updateDocumentMappingId(
      document.documentMappingId,
      documentIdMappings
    ),
  };
};

const updateCopiedDocuments = (
  newDocuments: Map<string, Document>,
  newDocumentIdMapping: Map<string, string>,
  databaseId: string,
  existingDocumentNames: string[],
  firebase: Firebase
) =>
  Promise.all(
    [...newDocuments].map(async ([id, document]) => {
      return firebase.userDatabases
        .doUpdateUserDocument(
          databaseId,
          getIdentifierParts(newDocumentIdMapping.get(id)!)[1],
          updateDocumentWitNewIds(
            document,
            newDocumentIdMapping,
            existingDocumentNames
          )
        )
        .then(([documentId, document]) =>
          firebase.userDatabases.doCreateUserDocumentSummary(
            databaseId,
            document,
            documentId
          )
        );
    })
  );

const cloneDocumentsToDatabase = async (
  documentsToClone: string[],
  databaseToCloneInto: string,
  existingDocumentNames: string[],
  userDatabaseIds: string[],
  firebase: Firebase
) =>
  processDocumentsToImport(
    [],
    [...documentsToClone],
    new Map<string, Document>(),
    userDatabaseIds,
    0,
    firebase
  ).then((allDocumentsToImport) =>
    copyDocumentsToDatabase(
      allDocumentsToImport,
      databaseToCloneInto,
      firebase
    ).then((newDocumentIdMap) =>
      updateCopiedDocuments(
        allDocumentsToImport,
        newDocumentIdMap,
        databaseToCloneInto,
        existingDocumentNames,
        firebase
      )
    )
  );

export default cloneDocumentsToDatabase;

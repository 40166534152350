import { initialDocumentState, document, DocumentState } from "./document";
import {
  CurrentDocumentActions,
  SET_DOCUMENT_ID,
  UPDATE_BASE_COMPOSITION,
  UPDATE_FINAL_COMPOSITION,
  SET_DOCUMENT_DATA,
  SET_DOCUMENT_LOADING,
  UPDATE_SERVER_DOCUMENT,
  UPDATE_CURRENT_DOCUMENT,
} from "../actions/currentDocument";
import {
  FoodItemsActions,
  ADD_FOOD_ITEM,
  REMOVE_FOOD_ITEM,
  CLEAR_FOOD_ITEM,
  UPDATE_FOOD_ITEM,
  SET_ALL_FOOD_ITEMS,
  UPDATE_QUANTITY,
  CLEAR_QUANTITY,
  UPDATE_NOTE,
  UPDATE_RETENTION_FACTOR,
  CLEAR_RETENTION_FACTOR,
  REMOVE_MULTIPLE_FOOD_ITEMS,
} from "../actions/foodItems";
import {
  DocumentActions,
  UPDATE_DOCUMENT_NAME,
  UPDATE_DOCUMENT_IDENTIFIER,
  UPDATE_DOCUMENT_ALT_IDENTIFIER,
  UPDATE_DOCUMENT_DESCRIPTION,
  UPDATE_DOCUMENT_METHOD,
  CREATE_METHOD_SECTION,
  DELETE_METHOD_SECTION,
  UPDATE_METHOD_SECTION_IS_COLLAPSED,
  UPDATE_METHOD_SECTION_TITLE,
  UPDATE_DOCUMENT_YIELD,
  UPDATE_DOCUMENT_SERVES,
  UPDATE_VOLUME_CONVERSION,
  TOGGLE_PROPERTY_IS_LIQUID,
  UPDATE_DOCUMENT_NOTE,
  UPDATE_DOCUMENT_STATUS,
  UPDATE_DOCUMENT_MODIFIED_DATE,
  UPDATE_CALCULATION_METHOD,
  SET_DOCUMENT_MAPPING_ID,
  SET_DOCUMENT_TAG_IDS,
} from "../actions/document";
import {
  NutrientOverridesActions,
  ADD_NUTRIENT_OVERRIDE,
  REMOVE_NUTRIENT_OVERRIDE,
  UPDATE_NUTRIENT_OVERRIDE_VALUE,
  UPDATE_NUTRIENT_OVERRIDE_NOTE,
} from "../actions/nutrientOverrides";
import {
  CommonMeasuresActions,
  ADD_COMMON_MEASURE,
  SET_DEFAULT_COMMON_MEASURE,
  UPDATE_COMMON_MEASURE,
  REMOVE_COMMON_MEASURE,
} from "../actions/commonMeasures";
import {
  ADD_MULTIPLE_SECTIONS,
  ADD_SECTION,
  ADD_SECTION_TAG,
  DELETE_SECTIONS,
  REMOVE_SECTION_TAG,
  UPDATE_MULTIPLE_SECTIONS,
  UPDATE_SECTION,
  UPDATE_SECTION_IS_COLLAPSED,
  UPDATE_SECTION_TITLE,
} from "../actions/sections";
import {
  ADD_DAY,
  SET_DAYS,
  UPDATE_DAY_DATE,
  UPDATE_DAY_TITLE,
} from "../actions/days";
import { CompositionState } from "../../../../data/models/composition";
import {
  ADD_SECTION_TAGS,
  DELETE_SECTION_TAG,
  UPDATE_SECTION_TAG,
} from "../actions/sectionTags";

export const TEMPORARY_NEW_DOCUMENT = "Temporary New Document";
export const TEMPORARY_DOCUMENT = "Temporary Document";

type Actions =
  | CurrentDocumentActions
  | DocumentActions
  | FoodItemsActions
  | NutrientOverridesActions
  | CommonMeasuresActions;

export interface CurrentDocumentState {
  id: string;
  isLoading: boolean;
  serverDocument: DocumentState | undefined;
  document: DocumentState;
  dayCount: number;
  baseComposition: CompositionState;
  finalComposition: CompositionState;
}

export const initialCurrentDocumentState: CurrentDocumentState = {
  id: "",
  isLoading: false,
  serverDocument: undefined,
  document: initialDocumentState,
  dayCount: 0,
  baseComposition: {},
  finalComposition: {},
};

export const currentDocument = (
  state: CurrentDocumentState = initialCurrentDocumentState,
  action: Actions
): CurrentDocumentState => {
  switch (action.type) {
    case SET_DOCUMENT_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SET_DOCUMENT_DATA:
      return {
        ...state,
        serverDocument: action.document,
        document: action.document,
        dayCount: action.document.days.length,
        baseComposition: {},
        finalComposition: {},
      };
    case SET_DOCUMENT_ID:
      return {
        ...state,
        id: action.id,
      };
    case UPDATE_SERVER_DOCUMENT: {
      return {
        ...state,
        serverDocument: action.document,
        document: action.document,
      };
    }
    case UPDATE_CURRENT_DOCUMENT: {
      return {
        ...state,
        document: action.document,
        dayCount: action.document.days.length,
      };
    }
    case UPDATE_BASE_COMPOSITION:
      return { ...state, baseComposition: action.composition };
    case UPDATE_FINAL_COMPOSITION:
      return { ...state, finalComposition: action.composition };
    case ADD_DAY:
      return {
        ...state,
        document: document(state.document, action),
        dayCount: state.dayCount + 1,
      };
    case SET_DAYS:
      return {
        ...state,
        document: document(state.document, action),
        dayCount: action.days.length,
      };
    case UPDATE_DOCUMENT_NAME:
    case UPDATE_DOCUMENT_IDENTIFIER:
    case UPDATE_DOCUMENT_ALT_IDENTIFIER:
    case UPDATE_DOCUMENT_DESCRIPTION:
    case UPDATE_DOCUMENT_NOTE:
    case UPDATE_DOCUMENT_METHOD:
    case CREATE_METHOD_SECTION:
    case DELETE_METHOD_SECTION:
    case UPDATE_METHOD_SECTION_IS_COLLAPSED:
    case UPDATE_METHOD_SECTION_TITLE:
    case UPDATE_DOCUMENT_YIELD:
    case UPDATE_DOCUMENT_SERVES:
    case ADD_NUTRIENT_OVERRIDE:
    case REMOVE_NUTRIENT_OVERRIDE:
    case UPDATE_NUTRIENT_OVERRIDE_VALUE:
    case UPDATE_NUTRIENT_OVERRIDE_NOTE:
    case ADD_COMMON_MEASURE:
    case SET_DEFAULT_COMMON_MEASURE:
    case UPDATE_COMMON_MEASURE:
    case REMOVE_COMMON_MEASURE:
    case UPDATE_VOLUME_CONVERSION:
    case TOGGLE_PROPERTY_IS_LIQUID:
    case UPDATE_DOCUMENT_STATUS:
    case ADD_SECTION:
    case DELETE_SECTIONS:
    case UPDATE_DAY_DATE:
    case UPDATE_DAY_TITLE:
    case ADD_MULTIPLE_SECTIONS:
    case UPDATE_MULTIPLE_SECTIONS:
    case REMOVE_MULTIPLE_FOOD_ITEMS:
    case ADD_SECTION_TAG:
    case REMOVE_SECTION_TAG:
    case ADD_FOOD_ITEM:
    case REMOVE_FOOD_ITEM:
    case UPDATE_SECTION:
    case UPDATE_SECTION_TITLE:
    case UPDATE_SECTION_IS_COLLAPSED:
    case CLEAR_FOOD_ITEM:
    case UPDATE_FOOD_ITEM:
    case SET_ALL_FOOD_ITEMS:
    case UPDATE_QUANTITY:
    case CLEAR_QUANTITY:
    case UPDATE_NOTE:
    case UPDATE_RETENTION_FACTOR:
    case CLEAR_RETENTION_FACTOR:
    case UPDATE_DOCUMENT_MODIFIED_DATE:
    case UPDATE_CALCULATION_METHOD:
    case SET_DOCUMENT_MAPPING_ID:
    case ADD_SECTION_TAGS:
    case DELETE_SECTION_TAG:
    case UPDATE_SECTION_TAG:
      return { ...state, document: document(state.document, action) };
    case SET_DOCUMENT_TAG_IDS:
      return { ...state, document: document(state.document, action) };
    default:
      return state;
  }
};

import React, { FunctionComponent, useState } from "react";

import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { batch, useDispatch, useSelector } from "react-redux";

import {
  Section,
  Sections,
} from "../../../../../../../../data/models/documentProperties/section";
import { setClipboard } from "../../../../../../../../store/action_creators/clipboardActionCreators";
import { ClipboardDataType } from "../../../../../../../../store/reducers/clipboardReducers";
import { RetentionFactor } from "../../../../../../../../data/models/documentProperties/retentionFactor";
import { getRetentionFactorMap } from "../../../../../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../../../../../store/reducers";
import {
  deleteSections,
  updateSectionIsCollapsed,
} from "../../../../../../../../store/data/current-document/action-creators/sections";
import { templateIdSelector } from "../../../../../../../../store/data/current-document/selectors/document";
import { FoodworksTooltip } from "../../../../../../../common/InfoTooltip";
import { addAlert } from "../../../../../../../../store/ui/actionCreators/alert";
import { ReactComponent as AddTagIcon } from "../../../../../../../../images/icons/Add_Tag.svg";
import { SectionTagMenu } from "./SectionTagMenu";
import { SectionMenu } from "./SectionMenu";
import { SectionDeleteDialog } from "../../../../../../../dialogs/SectionDeleteDialog";
import { setSelectedSectionTags } from "../../../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import useUpdateSelection from "../../hooks/useUpdateSelection";

const useStyles = makeStyles(() =>
  createStyles({
    expandButton: {
      cursor: "pointer",
    },
    moreButton: {
      width: 40,
      minWidth: 40,
    },
  })
);

interface CollapseSectionButtonProps {
  dayIndex: number;
  sectionIndex: number;
}
export const HideSectionButton = React.memo<CollapseSectionButtonProps>(
  ({ dayIndex, sectionIndex }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onHideSection = () =>
      dispatch(updateSectionIsCollapsed(dayIndex, sectionIndex, true));

    return (
      <IconButton
        className={`notSelectable`}
        color="secondary"
        size="small"
        onClick={onHideSection}
      >
        <ArrowDropUp
          data-cy="expandLess"
          className={`notSelectable ${classes.expandButton}`}
          color="secondary"
        />
      </IconButton>
    );
  }
);

export const ShowSectionButton = React.memo<CollapseSectionButtonProps>(
  ({ dayIndex, sectionIndex }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onShowSection = () =>
      dispatch(updateSectionIsCollapsed(dayIndex, sectionIndex, false));

    return (
      <IconButton
        className={`notSelectable`}
        color="secondary"
        size="small"
        onClick={onShowSection}
      >
        <ArrowDropDown
          data-cy="expandMore"
          className={`notSelectable ${classes.expandButton}`}
          color="secondary"
        />
      </IconButton>
    );
  }
);

interface SectionMenuButtonProps {
  dayIndex: number;
  section: Section;
  onClickCreateTag: () => void;
}

export const SectionMenuButton: FunctionComponent<SectionMenuButtonProps> = ({
  dayIndex,
  section,
  onClickCreateTag,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onShowMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchorEl(event.currentTarget);

  return (
    <>
      <Button
        data-cy="sectionMenuButton"
        key={`moreOptions-${section.index}`}
        className={`${classes.moreButton}`}
        onClick={onShowMenu}
        size="small"
      >
        <MoreHorizIcon />
      </Button>
      <SectionMenu
        dayIndex={dayIndex}
        anchorElement={anchorEl}
        onClose={() => setAnchorEl(null)}
        section={section}
        onClickCreateTag={onClickCreateTag}
      />
    </>
  );
};

interface CopySectionButtonProps {
  section: Section;
}

export const CopySectionButton = React.memo<CopySectionButtonProps>(
  ({ section }) => {
    const dispatch = useDispatch();

    const onSetClipboard = () =>
      dispatch(
        setClipboard({
          dataType: ClipboardDataType.SECTIONS,
          data: new Sections([section.object], retentionFactorMap),
        })
      );

    const onAddAlert = (message: string) => dispatch(addAlert(message));

    const retentionFactorMap: Map<string, RetentionFactor> = useSelector<
      RootState,
      Map<string, RetentionFactor>
    >(getRetentionFactorMap);

    const onCopySection = () => {
      onSetClipboard();
      onAddAlert("Section copied successfully!");
    };

    const classes = useStyles();
    return (
      <FoodworksTooltip title="Copy section" placement="bottom">
        <div>
          <IconButton
            size="small"
            data-cy="copyButton"
            className={`notSelectable ${classes.moreButton}`}
            onClick={onCopySection}
          >
            <CopyIcon className={classes.moreButton} />
          </IconButton>
        </div>
      </FoodworksTooltip>
    );
  }
);

interface DeleteSectionButtonProps {
  dayIndex: number;
  sectionIndex: number;
  sectionItemsLength: number;
}

export const DeleteSectionButton = React.memo<DeleteSectionButtonProps>(
  ({ dayIndex, sectionIndex, sectionItemsLength }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const updateSelection = useUpdateSelection();

    const onDeleteSections = () =>
      dispatch(deleteSections(dayIndex, [sectionIndex], templateId));

    const onResetSelection = () =>
      batch(() => {
        updateSelection([])
        dispatch(setSelectedSectionTags([]));
      });

    const templateId: string = useSelector<RootState, string>(
      templateIdSelector
    );
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const onDeleteSection = () =>
      !sectionItemsLength
        ? batch(() => {
            onDeleteSections();
            onResetSelection();
          })
        : setShowDeleteDialog(true);

    const onCloseDeleteDialog = () => {
      setShowDeleteDialog(false);
    };

    return (
      <>
        <FoodworksTooltip title="Delete section" placement="bottom">
          <div>
            <IconButton
              size="small"
              data-cy="deleteSectionButton"
              className={`notSelectable ${classes.moreButton}`}
              onClick={onDeleteSection}
            >
              <DeleteIcon className={classes.moreButton} />
            </IconButton>
          </div>
        </FoodworksTooltip>
        <SectionDeleteDialog
          open={showDeleteDialog}
          onClose={onCloseDeleteDialog}
          dayIndex={dayIndex}
          sectionIndex={sectionIndex}
          foodItemsLength={sectionItemsLength}
        />
      </>
    );
  }
);

interface AddSectionTagButtonProps {
  dayIndex: number;
  section: Section;
  onClickCreateTag: () => void;
}

export const AddSectionTagButton = React.memo<AddSectionTagButtonProps>(
  ({ dayIndex, section, onClickCreateTag }) => {
    const classes = useStyles();

    const [tagMenuAnchorEl, setTagMenuAnchorEl] = useState<HTMLElement | null>(
      null
    );

    const onAddTag = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      setTagMenuAnchorEl(event.currentTarget);
    };

    return (
      <>
        <FoodworksTooltip title="Add section tag">
          <IconButton
            className={`notSelectable ${classes.moreButton}`}
            data-cy="addTag"
            size="small"
            onClick={onAddTag}
          >
            <AddTagIcon />
          </IconButton>
        </FoodworksTooltip>
        <SectionTagMenu
          dayIndex={dayIndex}
          section={section}
          anchorElement={tagMenuAnchorEl}
          onClose={() => setTagMenuAnchorEl(null)}
          onClickCreateTag={onClickCreateTag}
        />
      </>
    );
  }
);

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { batch, useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  DEFAULT_MEAL_PLAN_SECTIONS,
  isMealPlan,
} from "../../../constants/FoodTemplate";
import { Day, Days } from "../../../data/models/documentProperties/day";
import { RetentionFactor } from "../../../data/models/documentProperties/retentionFactor";
import { setDays } from "../../../store/data/current-document/action-creators/days";
import { daysSelector } from "../../../store/data/current-document/selectors/days";
import { templateIdSelector } from "../../../store/data/current-document/selectors/document";
import { RootState } from "../../../store/reducers";
import { setSelectedSectionTags } from "../../../store/ui/actionCreators/nutritionPaneActionCreators";
import {
  updateCurrentDay,
} from "../../../store/ui/actionCreators/recipeGrid";
import { BaseDialog } from "../BaseDialog";
import { DAY_IN_MILLISECONDS } from "../../../data/models/documentProperties/date";
import DaySummaryList from "./components/DaySummaryList";
import { InfoTooltip } from "../../common/InfoTooltip";
import RecordDaySelection from "./components/RecordDaySelection";
import useUpdateSelection from "../../screens/databases/documents/tabs/ingredients/hooks/useUpdateSelection";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },

  checkBox: {
    borderRadius: 10,
  },
  body: {
    margin: 25,
    marginTop: 5,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  inputContainer: {
    display: "flex",
    flex: 1,
    marginTop: 10,
  },

  keepExistingButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  checkboxContainer: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
  },

  daySummaryList: {
    marginTop: 10,
    display: "flex",
    width: "90%",
  },
}));

const getNewDayDate = (previousDate: string): Date | null => {
  const date: Date | null = previousDate ? new Date(previousDate) : null;
  if (date) {
    date.setTime(date.getTime() + DAY_IN_MILLISECONDS);
  }

  return date;
};

interface SetMealPlanDialogProps {
  open: boolean;
  onClose: () => void;
}

export const SetMealPlanDialog = React.memo<SetMealPlanDialogProps>(
  ({ open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onSetDays = (days: Day[]) => dispatch(setDays(days));

    const onResetCurrentDay = () => dispatch(updateCurrentDay(0));

    const onResetSelection = () =>
      batch(() => {
        updateSelection([])
        dispatch(setSelectedSectionTags([]));
      });

      const updateSelection = useUpdateSelection();

    const days: Days = useSelector<RootState, Days>(daysSelector);

    const templateId: string =
      useSelector<RootState, string>(templateIdSelector);

    const [currentDays, setCurrentDays] = useState<Day[]>(days.days);
    const [clearDayData, setClearDayData] = useState(false);

    useEffect(() => {
      setCurrentDays(days.days);
    }, [days]);

    const onUpdateDayTitle = (dayIndex: number, title: string) =>
      setCurrentDays(
        currentDays.map(
          (day: Day): Day =>
            day.index === dayIndex
              ? new Day(day.id, day.index, day.sections, title, day.date)
              : day
        )
      );

    const onUpdateDayDate = (dayIndex: number, date: string) =>
      setCurrentDays(
        currentDays.map(
          (day: Day): Day =>
            day.index === dayIndex
              ? new Day(day.id, day.index, day.sections, day.title, date)
              : day
        )
      );

    const onDeleteDay = (dayIndex: number) => {
      setCurrentDays(
        currentDays
          .filter((day: Day): boolean => day.index !== dayIndex)
          .map(
            (day: Day): Day =>
              day.index < dayIndex
                ? day
                : new Day(
                    day.id,
                    day.index - 1,
                    day.sections,
                    day.title,
                    day.date
                  )
          )
      );
    };

    const onClickAddDay = () => {
      const newDate: Date | null = getNewDayDate(
        currentDays[currentDays.length - 1].date
      );

      setCurrentDays(
        currentDays.concat(
          Day.fromObject(
            {
              id: uuidv4(),
              index: currentDays.length,
              sections: DEFAULT_MEAL_PLAN_SECTIONS,
              title: `Day ${currentDays.length + 1}`,
              date: newDate ? newDate.toISOString() : "",
            },
            new Map<string, RetentionFactor>([])
          )
        )
      );
    };

    const onSaveChanges = () => {
      onApplyChanges();
      onClose();
    };

    const onApplyChanges = () => {
      batch(() => {
        onSetDays(currentDays);
        onResetSelection();
        onResetCurrentDay();
      });
    };

    const daysHasChanged = (d1: Day[], d2: Day[]) => !_.isEqual(d1, d2);

    const onToggleKeepExistingEntries = () => setClearDayData((prev) => !prev);

    return (
      <BaseDialog
        dataCy="setMealPlan"
        open={open}
        onClose={onClose}
        title={
          ""
        }
        body={
          <div className={classes.body}>
            <Typography>{isMealPlan(templateId)
            ? "Set the duration and dates of your meal plan."
            : "Set the duration and dates of your food record."}</Typography>
            <br/>
            <RecordDaySelection
              currentDays={currentDays}
              setCurrentDays={setCurrentDays}
              clearDayData={clearDayData}
            />
            <Card className={classes.daySummaryList}>
              <DaySummaryList
                currentDays={currentDays}
                updateDayDate={onUpdateDayDate}
                updateDayTitle={onUpdateDayTitle}
                deleteDay={onDeleteDay}
                addDay={onClickAddDay}
              />
            </Card>
            <div className={classes.keepExistingButtonContainer}>
              <div className={classes.checkboxContainer}>
                <InfoTooltip
                  title={`Check this box to erase all previously entered day data when generating a new ${
                    isMealPlan(templateId) ? "meal" : "record"
                  } structure.`}
                />
                <Checkbox
                  size="small"
                  color="secondary"
                  checked={clearDayData}
                  onChange={onToggleKeepExistingEntries}
                />
                <Typography variant="body1">Clear day data</Typography>
              </div>
            </div>
          </div>
        }
        action={[
          <Button
            key="setMeanPlanDialogCancel"
            color="default"
            onClick={onClose}
          >
            Cancel
          </Button>,
          <Button
            key="setMeanPlanDialogSave"
            color="secondary"
            onClick={onSaveChanges}
          >
            Save changes
          </Button>,
          <Button
            disabled={!daysHasChanged(days.days, currentDays)}
            color="secondary"
            onClick={onApplyChanges}
          >
            Apply
          </Button>,
          <Button color="secondary" onClick={onSaveChanges}>
            OK
          </Button>,
        ]}
        maxWidth="sm"
      />
    );
  }
);

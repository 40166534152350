import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateClientName } from "../../../../../../store/data/current_client/action_creators/client";
import { clientNameSelector } from "../../../../../../store/data/current_client/selectors/client";
import { RootState } from "../../../../../../store/reducers";

const useClientName = (): [string, (input: string) => void, () => void] => {
  const dispatch = useDispatch();

  const onSetClientName = () => dispatch(updateClientName(input));

  const name: string = useSelector<RootState, string>(clientNameSelector);

  const [input, setInput] = useState<string>(name);

  useEffect(() => {
    setInput(name);
  }, [name]);

  return [input, setInput, onSetClientName];
};

export default useClientName;

import { Client } from "../../../../data/models/client";
import {
  ADD_CLIENT_GOAL,
  ADD_DOCUMENT_ID,
  ClientActions,
  REMOVE_CLIENT_GOAL,
  SET_CLIENT_ADDRESS,
  SET_CLIENT_EMAIL,
  SET_CLIENT_MOBILE,
  SET_CLIENT_NOTES,
  SET_CLIENT_TAGS,
  TOGGLE_CLIENT_STATE,
  UPDATE_CLIENT_GOAL,
  UPDATE_CLIENT_NAME,
} from "../actions/client";
import {
  SET_CLIENT_ACTIVITY_LEVEL,
  SET_CLIENT_AGE,
  SET_CLIENT_CURRENT_WEIGHT,
  SET_CLIENT_DOB,
  SET_CLIENT_HEIGHT,
  SET_CLIENT_IS_LACTATING,
  SET_CLIENT_PREGNANCY_LEVEL,
  SET_CLIENT_RESTING_METABOLIC_RATE,
  SET_CLIENT_SEX,
  SET_CLIENT_START_WEIGHT,
  SET_USE_CER,
} from "../actions/clientProfile";
import { clientProfile, initialClientProfileState } from "./clientProfile";
import { ClientGoal } from "../../../../data/models/clientProperties/clientGoal";
import {
  ADD_CLIENT_MEASUREMENT,
  ADD_CLIENT_MEASUREMENT_ENTRY,
  DELETE_CLIENT_MEASUREMENT,
  DELETE_CLIENT_MEASUREMENT_ENTRY,
  EDIT_CLIENT_MEASUREMENT,
  TOGGLE_CLIENT_MEASUREMENT,
  UPDATE_CLIENT_MEASUREMENT_ENTRY,
} from "../actions/clientMeasurements";
import {
  clientMeasurements,
  initialMeasurementsState,
} from "./clientMeasurements";

export type ClientState = Client;

export const initialClientState: ClientState = {
  name: "New client",
  documents: [],
  profile: initialClientProfileState,
  contact: {
    mobile: "",
    email: "",
    address: "",
  },
  notes: "",
  date: { created: "", lastModified: "" },
  tagIds: [],
  goals: [],
  measurements: initialMeasurementsState,
  state: "active",
};

export const client = (
  state: ClientState = initialClientState,
  action: ClientActions
): ClientState => {
  switch (action.type) {
    case UPDATE_CLIENT_NAME:
      return { ...state, name: action.name };
    case ADD_DOCUMENT_ID:
      return {
        ...state,
        documents: state.documents.includes(action.id)
          ? state.documents
          : [...state.documents, action.id],
      };
    case ADD_CLIENT_GOAL:
      return {
        ...state,
        goals: state.goals.includes(action.goal)
          ? state.goals
          : [...state.goals].concat(action.goal),
      };
    case REMOVE_CLIENT_GOAL:
      return {
        ...state,
        goals: state.goals.filter(
          (goal) =>
            goal.type !== action.goal.type ||
            goal.nutrientId !== action.goal.nutrientId
        ),
      };
    case UPDATE_CLIENT_GOAL:
      return {
        ...state,
        goals: state.goals.map(
          (goal: ClientGoal): ClientGoal =>
            goal.type === action.goal.type &&
            goal.nutrientId === action.goal.nutrientId
              ? action.goal
              : goal
        ),
      };
    case SET_CLIENT_MOBILE:
      return { ...state, contact: { ...state.contact, mobile: action.mobile } };
    case SET_CLIENT_EMAIL:
      return { ...state, contact: { ...state.contact, email: action.email } };
    case SET_CLIENT_ADDRESS:
      return {
        ...state,
        contact: { ...state.contact, address: action.address },
      };
    case SET_CLIENT_NOTES:
      return { ...state, notes: action.notes };
    case SET_CLIENT_TAGS:
      return { ...state, tagIds: action.tagIds };
    case TOGGLE_CLIENT_STATE:
      return { ...state };
    case SET_CLIENT_AGE:
    case SET_CLIENT_DOB:
    case SET_CLIENT_SEX:
    case SET_CLIENT_START_WEIGHT:
    case SET_CLIENT_CURRENT_WEIGHT:
    case SET_CLIENT_HEIGHT:
    case SET_CLIENT_ACTIVITY_LEVEL:
    case SET_CLIENT_PREGNANCY_LEVEL:
    case SET_CLIENT_IS_LACTATING:
    case SET_USE_CER:
    case SET_CLIENT_RESTING_METABOLIC_RATE:
      return { ...state, profile: clientProfile(state.profile, action) };
    case ADD_CLIENT_MEASUREMENT:
    case EDIT_CLIENT_MEASUREMENT:
    case DELETE_CLIENT_MEASUREMENT:
    case TOGGLE_CLIENT_MEASUREMENT:
    case ADD_CLIENT_MEASUREMENT_ENTRY:
    case UPDATE_CLIENT_MEASUREMENT_ENTRY:
    case DELETE_CLIENT_MEASUREMENT_ENTRY:
      return {
        ...state,
        measurements: clientMeasurements(state.measurements, action),
      };

    default:
      return state;
  }
};

import React, { ReactNode, useMemo, useState } from "react";
import { Card, makeStyles, MenuItem } from "@material-ui/core";
import { Add, PieChart, TableChart } from "@material-ui/icons";

import { BaseDialog } from "../../dialogs/BaseDialog";
import FoodworksSelect from "../FoodworksSelect";
import {GraphDisplayType} from "../types"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  select: {
    marginRight: 20,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 30,
    padding: 10,
    width: "40%",
    cursor: "pointer",
  },
}));

interface GraphSelectorDialogProps {
  title?: string;
  graph: ReactNode;
  table: ReactNode;
}

const getContent = (
  graph: ReactNode,
  table: ReactNode,
  option: "Graph" | "Table" | "GraphTable"
) => {
  if (option === "Graph") {
    return graph;
  } else if (option === "Table") {
    return table;
  } else {
    return (
      <>
        {graph} {table}
      </>
    );
  }
};

const GraphSelectorDialog = React.memo(
  ({ title, graph, table }: GraphSelectorDialogProps) => {
    const classes = useStyles();

    const [option, setOption] =
      useState<GraphDisplayType>("Graph");
    const [open, onClose] = useState(false);

    const handleChange = (value: GraphDisplayType) => {
      setOption(value as GraphDisplayType);
    };

    const content = useMemo(
      () => getContent(graph, table, option),
      [graph, table, option]
    );

    const select = (
      <FoodworksSelect<GraphDisplayType> className={classes.select} value={option} onChange={handleChange}>
        <MenuItem className={classes.menuItem} value={"Graph"}>
          <PieChart />
        </MenuItem>
        <MenuItem className={classes.menuItem} value={"Table"}>
          <TableChart />
        </MenuItem>
        <MenuItem value={"GraphTable"}>
          <PieChart />
          <Add />
          <TableChart />
        </MenuItem>
      </FoodworksSelect>
    );

    const body = (
      <div className={classes.root}>
        <div className={classes.content}>{content}</div>
      </div>
    );

    return (
      <>
        <Card className={classes.card} onClick={() => onClose(true)}>
          {graph}
        </Card>
        <BaseDialog
          key={title}
          maxWidth="md"
          open={open}
          onClose={() => onClose(false)}
          title={title ? title : ""}
          body={body}
          topActions={select}
        />
      </>
    );
  }
);

export default GraphSelectorDialog;

import React, { FunctionComponent, ReactNode } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";

import { appTheme } from "../../styling/style";

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  appBar: {
    background: appTheme.colors.white[0],
    boxShadow: "none",
    borderRadius: 5,
  },
  tabSize: {
    minWidth: 120,
  },
  tab: {
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
    },
  },
  tabText: { color: appTheme.colors.xiketic },
  tabPanel: {
    display: "flex",
    overflow: "hidden",
    visbility: "visible",
    flex: 1,
  },
  tabPanelHidden: {
    display: "flex",
    overflow: "hidden",
    visbility: "hidden",
    flex: 0,
  },
}));

interface TabbedAppBarProps {
  tabs: TabbedPaneTab[];
  currentTab: number;
  onChangeTab: (tabIndex: number) => void;
}

export const TabbedAppBar = React.memo(
  ({ tabs, onChangeTab, currentTab }: TabbedAppBarProps): JSX.Element => {
    const classes = useStyles();

    const tabTitles = tabs.map((tab: TabbedPaneTab) => (
      <Tab
        classes={{ root: classes.tabSize }}
        className={classes.tab}
        key={tab.title}
        label={
          <Typography variant="h3" className={classes.tabText}>
            {tab.title}
          </Typography>
        }
        data-cy="tab"
      />
    ));

    return (
      <div data-cy="tabs">
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Tabs
            indicatorColor="secondary"
            value={currentTab}
            onChange={(_, tabIndex: number) => onChangeTab(tabIndex)}
          >
            {tabTitles}
          </Tabs>
        </AppBar>
      </div>
    );
  }
);

export interface TabbedPaneTab {
  title: string;
  content: ReactNode;
}

interface TabbedPanelProps {
  tabs: TabbedPaneTab[];
  currentTab: number;
}

export const TabbedPanel = React.memo(
  ({ tabs, currentTab }: TabbedPanelProps): JSX.Element => {
    const classes = useStyles();

    return (
      <>
        {tabs.map((tab, index) => (
          <div
            key={`tabbedPanelItem-${index}-${tab.title}`}
            className={
              currentTab === index ? classes.tabPanel : classes.tabPanelHidden
            }
          >
            {tab.content}
          </div>
        ))}
      </>
    );
  }
);

interface TabbedPaneProps {
  tabs: TabbedPaneTab[];
  currentTab: number;
  onChangeTab: (index: number) => void;
}

export const TabbedPane: FunctionComponent<TabbedPaneProps> = ({
  tabs,
  currentTab = 0,
  onChangeTab,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TabbedAppBar
        tabs={tabs}
        currentTab={currentTab}
        onChangeTab={(tabIndex: number) => onChangeTab(tabIndex)}
      />
      <Divider />
      <TabbedPanel tabs={tabs} currentTab={currentTab} />
    </div>
  );
};

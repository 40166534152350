import {
  IActionsSetNavSearchText,
  IActionSetQuickFilters,
  IActionsToggleQuickFilters,
  IActionTappedNavAccordion,
  IActionSetNavIsLoading,
} from "../actions/navigationActions";
import { QuickFilter } from "../../../constants/QuickFilter";

export const setNavSearchText = (text: string): IActionsSetNavSearchText => {
  return {
    type: "SET_NAV_SEARCH_TEXT",
    text: text,
  };
};

export const setNavQuickFilters = (
  filters: QuickFilter
): IActionSetQuickFilters => {
  return {
    type: "SET_NAV_SEARCH_FILTERS",
    filters: filters,
  };
};
export const toggleQuickFilters = (): IActionsToggleQuickFilters => {
  return {
    type: "TOGGLE_QUICK_FILTERS",
  };
};

export const tappedNavAccordion = (
  templateId: number
): IActionTappedNavAccordion => {
  return {
    type: "TAPPED_NAV_ACCORDION",
    templateId: templateId,
  };
};

export const setNavIsLoading = (isLoading: boolean): IActionSetNavIsLoading => {
  return {
    type: "SET_NAV_IS_LOADING",
    isLoading: isLoading,
  };
};

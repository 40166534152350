import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Measurement,
  MeasurementEntry,
} from "../../../../../data/models/clientProperties/measurement";
import {
  addClientMeasurementEntry,
  editClientMeasurement,
} from "../../../../../store/data/current_client/action_creators/clientMeasurements";
import {
  setClientCurrentWeight,
  setClientHeight,
} from "../../../../../store/data/current_client/action_creators/clientProfile";
import { usePreviousPerpetualMeasurement } from "../tracking/tracking-card/hooks/usePreviousMeasurement";

const useClientMeasurementDispatches = (): [
  (entry: MeasurementEntry, title: string, units: string) => void,
  (measurement: Measurement, newLabel: string, newUnits: string) => void
] => {
  const dispatch = useDispatch();

  const latestWeight = usePreviousPerpetualMeasurement("Weight", "kg");
  const latestHeight = usePreviousPerpetualMeasurement("Height", "cm");

  const onAddClientMeasurementEntry = (
    entry: MeasurementEntry,
    title: string,
    units: string
  ): void => {
    dispatch(addClientMeasurementEntry(entry, title, units));
  };

  const onEditClientMeasurement = (
    measurement: Measurement,
    newLabel: string,
    newUnits: string
  ) => {
    dispatch(editClientMeasurement(measurement, newLabel, newUnits, "Custom"));
  };

  useEffect(() => {
    if (latestWeight) dispatch(setClientCurrentWeight(latestWeight!.value));
  }, [dispatch, latestWeight]);

  useEffect(() => {
    if (latestHeight) dispatch(setClientHeight(latestHeight!.value));
  }, [dispatch, latestHeight]);

  return [onAddClientMeasurementEntry, onEditClientMeasurement];
};

export default useClientMeasurementDispatches;

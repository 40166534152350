import React from "react";
import { makeStyles } from "@material-ui/core";
import { Save } from "@material-ui/icons";

import { DisabledButtonWithToolTip } from "../InfoTooltip";

const useStyles = makeStyles(() => ({
  root: {
    width: 75,
  },
}));

interface SaveButtonProps {
  disabled: boolean;
  tooltip: string;
  onClick: () => void;
  cypressTag?: string;
}

const SaveButton = ({
  disabled,
  tooltip,
  onClick,
  cypressTag,
}: SaveButtonProps): JSX.Element => {
  const classes = useStyles();
  return (
    <DisabledButtonWithToolTip
      dataCy={cypressTag}
      disabled={disabled}
      label="Save"
      disabledTooltip={"No changes detected"}
      enabledTooltip={`Save ${tooltip}`}
      buttonProps={{
        onClick: onClick,
        startIcon: <Save />,
        color: "secondary",
        className: classes.root,
      }}
    />
  );
};

export default SaveButton;

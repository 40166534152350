import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, batch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

import { AutoCompleteCell, GridCellType } from "./AutoCompleteCell";
import { CommonMeasure } from "../../../../../../../../../data/models/documentProperties/measure";
import { Document } from "../../../../../../../../../data/models/document";
import {
  updateQuantity,
  clearQuantity,
} from "../../../../../../../../../store/data/current-document/action-creators/foodItems";
import { ReferenceMeasure } from "../../../../../../../../../data/models/referenceMeasure";
import { FoodItem } from "../../../../../../../../../data/models/documentProperties/foodItem";
import {
  isNumber,
  quantityFromCellValue,
  quantityOptionFromLabel,
} from "./quantityUtil";
import { appTheme } from "../../../../../../../../../styling/style";
import { commonMeasureBuilderSelector } from "../../../../../../../../../store/data/current-document/selectors/commonMeasures";
import { RootState } from "../../../../../../../../../store/reducers";

const SERVE_MEASURE_ID = "Serve";
const SERVE_MEASURE_NAME = "serve(s)";

export interface QuantityCellProps {
  useSetSize: boolean;
  foodItem: FoodItem;
  foodDocument: Document;
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
  columnIndex: number;
  currentFocusedCell: string;
  setFocusedCell: (cell: string) => void;
  relatedReferenceMeasures: ReferenceMeasure[];
}

export type QuantityCellInput = {
  value: string;
  measure: string;
};

const EXTRA_CELL_SPACING = 2;
const SPACE_STRING_LENGTH = 1;
const QUANTITY_PLACEHOLDER = "Quantity";
const MAX_WIDTH = 50;

export interface QuantityOption {
  label: string;
  measureId: string;
}

export const isQuantityOption = (object: any): object is QuantityOption => {
  const quantityOption: QuantityOption = {
    label: object.label,
    measureId: object.measureId,
  };
  return (
    typeof quantityOption.label === "string" &&
    typeof quantityOption.measureId === "string"
  );
};

export const QuantityCell: FunctionComponent<QuantityCellProps> = ({
  useSetSize,
  foodItem,
  foodDocument,
  dayIndex,
  sectionIndex,
  rowIndex,
  columnIndex,
  currentFocusedCell,
  setFocusedCell,
  relatedReferenceMeasures,
}) => {
  const quantity = foodItem ? foodItem.quantity : null;

  // *** Actions ***
  const dispatch = useDispatch();
  const onUpdateQuantity = (amount: number, measureId: string) =>
    dispatch(
      updateQuantity(dayIndex, sectionIndex, rowIndex, {
        amount: amount,
        measureId: measureId,
      })
    );

  const onClearQuantity = () =>
    dispatch(clearQuantity(dayIndex, sectionIndex, rowIndex));
  // *** ----- ***

  const selectCommonMeasures = useMemo(commonMeasureBuilderSelector, []);

  const measures: CommonMeasure[] = useSelector<RootState, CommonMeasure[]>(
    (state) => selectCommonMeasures(state, foodDocument)
  );

  const getMeasureNameById = useCallback(
    (id: string): string | undefined => {
      return (
        measures.find((measure) => measure.id === id)?.name ||
        relatedReferenceMeasures.find((measure) => measure.id === id)
          ?.displayName
      );
    },
    [measures, relatedReferenceMeasures]
  );

  const getSavedQuantityData = useCallback(() => {
    const measureName = quantity
      ? quantity.measureId === SERVE_MEASURE_ID
        ? SERVE_MEASURE_NAME
        : getMeasureNameById(quantity?.measureId)
      : "";

    if (measureName === undefined) {
      return {
        value: "",
        measure: "Deleted Measure",
      };
    }

    return quantity && measureName
      ? {
          value: quantity.amount.toString(),
          measure: measureName!,
        }
      : undefined;
  }, [quantity, getMeasureNameById]);

  const savedQuantity: QuantityCellInput | undefined = getSavedQuantityData();

  const sizeFromQuantity = (
    newQuantity: QuantityCellInput | undefined
  ): number =>
    newQuantity
      ? newQuantity.value.length +
        newQuantity.measure.length +
        SPACE_STRING_LENGTH +
        EXTRA_CELL_SPACING
      : QUANTITY_PLACEHOLDER.length + EXTRA_CELL_SPACING;

  const initialOptions: QuantityOption[] = [];

  // *** State ***
  const [currentQuantity, setCurrentQuantity] = useState(savedQuantity);
  const [cellSize, setCellSize] = useState(sizeFromQuantity(savedQuantity));
  const [options, setOptions] = useState(initialOptions);
  const [usesSpace, setUsesSpace] = useState(false);
  const [currentMeasureId, setCurrentMeasureId] = useState<string>(
    quantity?.measureId || ""
  );

  const ref: React.RefObject<HTMLInputElement> =
    React.useRef<HTMLInputElement>(null);

  // *** ----- ***

  /**
   * Changes on [savedQuantity] (which changes on [quantity])
   * Effect: updates current cell state to quantity from ingredient row.
   * Extra: This is doing a deep check on an object. Requires NoCheck due to object being able to be undefined
   */
  useEffect(() => {
    setCurrentQuantity(savedQuantity);
    setCellSize(sizeFromQuantity(savedQuantity));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  /**
   * Changes on [rowIndex, columnIndex, currentFocusedCell]
   * Effect: triggers ref focus if this cell is meant to be focused.
   */
  useEffect(() => {
    if (currentFocusedCell === `${sectionIndex}-${rowIndex}-${columnIndex}`) {
      ref?.current?.focus();
    }
  }, [currentFocusedCell, rowIndex, columnIndex, sectionIndex]);

  /**
   * Changes on: foodItem, foodDocument, relatedReferenceMeasures
   * Effect: Populates list of available measures for dropdown.
   */
  useEffect(() => {
    let availableMeasures: QuantityOption[] = relatedReferenceMeasures.map(
      (referenceMeasure: ReferenceMeasure): QuantityOption => ({
        label: referenceMeasure.displayName,
        measureId: referenceMeasure.id,
      })
    );

    const visibleOptions = measures.map(
      (measure: CommonMeasure): QuantityOption => ({
        label: measure.name,
        measureId: measure.id,
      })
    );

    availableMeasures = availableMeasures.concat(visibleOptions);

    //This is to disable serve measures for Auckland as they are currently causing performance issues

    // if (foodDocument.serve.value) {
    //   const serveOption: QuantityOption = {
    //     label: SERVE_MEASURE_NAME,
    //     measureId: SERVE_MEASURE_ID,
    //   };
    //   availableMeasures = availableMeasures.concat(serveOption);
    // }

    const defaultMeasureId: string = foodDocument.commonMeasures.default;
    if (defaultMeasureId) {
      const defaultMeasure: QuantityOption = availableMeasures.find(
        (measureOption: QuantityOption): boolean =>
          measureOption.measureId === defaultMeasureId
      )!;

      // TODO: this should never be falsey once the hack above is removed
      if (defaultMeasure) {
        availableMeasures = [
          defaultMeasure,
          ...availableMeasures.filter(
            (measureOption: QuantityOption): boolean =>
              measureOption.measureId !== defaultMeasureId
          ),
        ];
      }
    }

    setOptions(availableMeasures);
  }, [foodItem, foodDocument, relatedReferenceMeasures, measures]);

  const setQuantityState = (value: string, measureName: string) =>
    setCurrentQuantity({ value: value, measure: measureName });

  const onInputChange = (
    event: React.ChangeEvent<{}>,
    cellValue: string,
    reason: string
  ) => {
    if (cellValue === ".") {
      cellValue = "0.";
    }
    if (isNumber(cellValue)) {
      const hasSpaceAfterNumber = cellValue[cellValue.length - 1] === " ";
      setUsesSpace(hasSpaceAfterNumber);
    }

    const newQuantity: QuantityCellInput | undefined = quantityFromCellValue(
      options,
      cellValue
    );

    const amountChosen: number = Number(currentQuantity?.value) || 1;

    const measureId: string | undefined = quantityOptionFromLabel(
      options,
      cellValue
    )?.measureId;

    if (measureId && event) {
      if (event.nativeEvent instanceof KeyboardEvent) {
        batch(() => {
          onUpdateQuantity(amountChosen, currentMeasureId);
          setFocusedCell(`${sectionIndex}-${rowIndex}-${columnIndex + 1}`);
        });
      } else if (event.nativeEvent instanceof MouseEvent) {
        batch(() => {
          onUpdateQuantity(amountChosen, measureId);
          setFocusedCell(`${sectionIndex}-${rowIndex}-${columnIndex + 1}`);
        });
      }
    } else if (!cellValue || !isNumber(cellValue)) {
      setCurrentMeasureId("");
    }

    setCurrentQuantity(newQuantity);
    setCellSize(sizeFromQuantity(newQuantity));
  };

  const getCellText = (): string =>
    currentQuantity
      ? currentQuantity.measure
        ? `${currentQuantity.value} ${currentQuantity.measure}`
        : `${currentQuantity.value}${usesSpace ? " " : ""}`
      : "";

  const onBlur = (): void => {
    if (!currentQuantity) {
      onClearQuantity();
      setCurrentMeasureId("");
    }
    if (savedQuantity && isNumber(currentQuantity?.value)) {
      onUpdateQuantity(Number(currentQuantity?.value), quantity!.measureId);
      setCurrentMeasureId(quantity!.measureId);
    }
    setFocusedCell("");
  };

  const onFocus = (): void => {
    if (savedQuantity) {
      if (savedQuantity.measure === "Deleted Measure") {
        onClearQuantity();
      }
      setQuantityState(savedQuantity.value, "");

      !quantity || !quantity.measureId
        ? setCurrentMeasureId("")
        : setCurrentMeasureId(quantity.measureId);
    }
    setFocusedCell(`${sectionIndex}-${rowIndex}-${columnIndex}`);
  };

  const onSelect = (newInputValue: GridCellType | null) => {
    if (newInputValue && isQuantityOption(newInputValue)) {
      const amountChosen = Number(currentQuantity?.value) || 1;
      setQuantityState(
        amountChosen.toString(),
        newInputValue.measureId === SERVE_MEASURE_ID
          ? SERVE_MEASURE_NAME
          : getMeasureNameById(newInputValue.measureId)!
      );

      if (!currentMeasureId) {
        batch(() => {
          onUpdateQuantity(amountChosen, newInputValue.measureId);
          setFocusedCell(`${sectionIndex}-${rowIndex}-${columnIndex + 1}`);
          setCurrentMeasureId(newInputValue.measureId);
        });
      }
    } else {
      onClearQuantity();
      setCurrentMeasureId("");
    }
  };

  const filterOptions = (options: GridCellType[]) => {
    let spaceIndex: number = getCellText().indexOf(" ");
    if (spaceIndex === -1) return options;
    const currentMeasure: string = getCellText().slice(spaceIndex + 1);
    if (currentMeasure === "") return options;
    return options.filter((option) =>
      option.label.toLowerCase().includes(currentMeasure.toLowerCase())
    );
  };

  const renderItem = (option: GridCellType) => (
    <Typography>{option.label}</Typography>
  );

  return (
    <AutoCompleteCell
      key={`${rowIndex}-${columnIndex}`}
      cellSize={cellSize > MAX_WIDTH ? MAX_WIDTH : cellSize}
      items={options}
      initialInput={getCellText()}
      placeholder={QUANTITY_PLACEHOLDER}
      ref={ref}
      useSetSize={useSetSize}
      onInputChange={onInputChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onSelect={onSelect}
      filterOptions={filterOptions}
      renderOption={renderItem}
      inputStyle={{
        fontWeight: "bolder",
        color: currentQuantity?.value
          ? appTheme.colors.xiketic
          : appTheme.colors.error,
      }}
    />
  );
};

import React, { ReactNode, useState } from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { appTheme } from "../../../styling/style";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  tableContainer: {
    flex: 1,
    overflow: "auto",
  },
  closedContainer: {
    height: 0,
  },
  table: {
    display: "block",
    height: 50,
    width: 50,
    textAlign: "left",
    position: "relative",
    borderCollapse: "collapse",
    "& th": {
      position: "sticky",
      top: 0,
    },
    " & td": {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  hidden: {
    visibility: "hidden",
    height: 0,
  },
  show: {
    visibility: "visible",
  },
  module: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    paddingLeft: 13,
  },
  icon: {
    color: appTheme.colors.table.fontColor,
  },
}));

interface NRVTableProps {
  title: string;
  stickyTableItems: ReactNode[];
}

export const NRVTable = ({
  title,
  stickyTableItems,
}: NRVTableProps): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(true);

  const module: ReactNode = (
    <div className={classes.module}>
      <div>
        <IconButton
          className={classes.icon}
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>
      <div className={classes.title}>
        <Typography variant="h2">{title}</Typography>
      </div>
    </div>
  );

  return (
    <div className={open ? classes.root : `${classes.root}${classes.hidden}`}>
      <div>{module}</div>
      <div className={open ? classes.container : classes.closedContainer}>
        <div className={classes.tableContainer}>
          <table
            className={
              open
                ? `${classes.table} ${classes.show}`
                : `${classes.table} ${classes.hidden}`
            }
          >
            {stickyTableItems.map((item: ReactNode, idx: number) => (
              <div key={"StickTableItem-" + idx}>{item}</div>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

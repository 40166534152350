import { ThunkAction } from "redux-thunk";

import {
  DatabaseProperties,
  UserDatabaseSummary,
} from "../../../data/models/userDatabase";
import { RootState } from "../../reducers";
import { ExtraArguments } from "../../store";
import { setDatabaseProperties } from "../action-creators/database";
import {
  setDisplayedNutrients,
  setIsAlternateIdsEnabled,
  setEnabledDatasources,
  setEnabledMeasures,
  setEnabledNutrients,
} from "../action-creators/databaseProperties";
import { setUserDatabaseSummaries } from "../action-creators/user";
import { IActionsSetDatabaseProperties } from "../actions/database";
import {
  IActionsSetDisplayedNutrients,
  IActionsSetEnabledMeasures,
  IActionSetEnabledNutrients,
  IActionsSetEnabledDatasources,
  IActionSetIsAlternateIdsEnabled,
} from "../actions/databaseProperties";
import { IActionsSetUserDatabaseSummaries } from "../actions/user";
import { databaseIdSelector } from "../selectors/database";
import { DatabasePropertiesSelector } from "../selectors/databaseProperties";
import { sortedUserDatabaseSummariesSelector } from "../selectors/user";

export const updateDatabaseSummaryCopiedProperties =
  (
    currentDatabaseId: string,
    databaseProperties: DatabaseProperties
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetUserDatabaseSummaries
  > =>
  async (dispatch, getState, { firebase }) => {
    const userDatabaseSummaries: UserDatabaseSummary[] =
      sortedUserDatabaseSummariesSelector(getState());

    for (const userDatabaseSummary of userDatabaseSummaries) {
      if (userDatabaseSummary.id === currentDatabaseId)
        userDatabaseSummary.copiedDatabaseProperties = databaseProperties;
    }

    await firebase.userDatabases.doUpdateUserDatabaseCopiedDatabaseProperties(
      currentDatabaseId,
      databaseProperties
    );
    dispatch(setUserDatabaseSummaries(userDatabaseSummaries));
  };

export const updateDatabaseProperties =
  (
    databaseProperties: DatabaseProperties
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetDatabaseProperties
  > =>
  async (dispatch, getState, { firebase, routing }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    await firebase.userDatabases.doUpdateUserDatabaseProperties(
      currentDatabaseId,
      databaseProperties
    );

    dispatch(setDatabaseProperties(databaseProperties));
    dispatch(
      updateDatabaseSummaryCopiedProperties(
        currentDatabaseId,
        databaseProperties
      )
    );
  };

export const updateDisplayedNutrients =
  (
    nutrientIds: string[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetDisplayedNutrients
  > =>
  async (dispatch, getState, { firebase, routing }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const databaseProperties: DatabaseProperties = DatabasePropertiesSelector(
      getState()
    );
    const updatedDatabaseProperties: DatabaseProperties = {
      displayedNutrients: nutrientIds,
      enabledMeasures: databaseProperties.enabledMeasures,
      enabledNutrients: databaseProperties.enabledNutrients,
      enabledDatasources: databaseProperties.enabledDatasources,
      isAlternateIdsEnabled: databaseProperties.isAlternateIdsEnabled,
    };

    await firebase.userDatabases.doUpdateUserDatabaseProperties(
      currentDatabaseId,
      updatedDatabaseProperties
    );
    dispatch(setDisplayedNutrients(nutrientIds));
    dispatch(
      updateDatabaseSummaryCopiedProperties(
        currentDatabaseId,
        updatedDatabaseProperties
      )
    );
  };

export const updateEnabledMeasures =
  (
    enabledMeasures: string[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetEnabledMeasures
  > =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const databaseProperties: DatabaseProperties = DatabasePropertiesSelector(
      getState()
    );
    const updatedDatabaseProperties: DatabaseProperties = {
      displayedNutrients: databaseProperties.displayedNutrients,
      enabledMeasures: enabledMeasures,
      enabledNutrients: databaseProperties.enabledNutrients,
      enabledDatasources: databaseProperties.enabledDatasources,
      isAlternateIdsEnabled: databaseProperties.isAlternateIdsEnabled,
    };

    await firebase.userDatabases.doUpdateUserDatabaseProperties(
      currentDatabaseId,
      updatedDatabaseProperties
    );

    dispatch(setEnabledMeasures(enabledMeasures));
    dispatch(
      updateDatabaseSummaryCopiedProperties(
        currentDatabaseId,
        updatedDatabaseProperties
      )
    );
  };

export const updateEnabledNutrients =
  (
    enabledNutrients: string[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionSetEnabledNutrients
  > =>
  async (dispatch, getState, { firebase, routing }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const databaseProperties: DatabaseProperties = DatabasePropertiesSelector(
      getState()
    );

    const updatedDatabaseProperties: DatabaseProperties = {
      displayedNutrients: databaseProperties.displayedNutrients,
      enabledMeasures: databaseProperties.enabledMeasures,
      enabledNutrients: enabledNutrients,
      enabledDatasources: databaseProperties.enabledDatasources,
      isAlternateIdsEnabled: databaseProperties.isAlternateIdsEnabled,
    };

    await firebase.userDatabases.doUpdateUserDatabaseProperties(
      currentDatabaseId,
      updatedDatabaseProperties
    );
    dispatch(setEnabledNutrients(enabledNutrients));
    dispatch(
      updateDatabaseSummaryCopiedProperties(
        currentDatabaseId,
        updatedDatabaseProperties
      )
    );
  };

export const updateEnabledDatasources =
  (
    enabledDatasources: string[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetEnabledDatasources
  > =>
  async (dispatch, getState, { firebase, routing }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const databaseProperties: DatabaseProperties = DatabasePropertiesSelector(
      getState()
    );

    const updatedDatabaseProperties: DatabaseProperties = {
      displayedNutrients: databaseProperties.displayedNutrients,
      enabledMeasures: databaseProperties.enabledMeasures,
      enabledNutrients: databaseProperties.enabledNutrients,
      enabledDatasources: enabledDatasources,
      isAlternateIdsEnabled: databaseProperties.isAlternateIdsEnabled,
    };

    await firebase.userDatabases.doUpdateUserDatabaseProperties(
      currentDatabaseId,
      updatedDatabaseProperties
    );
    dispatch(setEnabledDatasources(enabledDatasources));
    dispatch(
      updateDatabaseSummaryCopiedProperties(
        currentDatabaseId,
        updatedDatabaseProperties
      )
    );
  };

export const updateEnabledAlternateIds =
  (
    isAlternateIdsEnabled: boolean
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionSetIsAlternateIdsEnabled
  > =>
  async (dispatch, getState, { firebase, routing }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const databaseProperties: DatabaseProperties = DatabasePropertiesSelector(
      getState()
    );

    const updatedDatabaseProperties: DatabaseProperties = {
      displayedNutrients: databaseProperties.displayedNutrients,
      enabledMeasures: databaseProperties.enabledMeasures,
      enabledNutrients: databaseProperties.enabledNutrients,
      enabledDatasources: databaseProperties.enabledDatasources,
      isAlternateIdsEnabled: isAlternateIdsEnabled,
    };

    await firebase.userDatabases.doUpdateUserDatabaseProperties(
      currentDatabaseId,
      updatedDatabaseProperties
    );
    dispatch(setIsAlternateIdsEnabled(isAlternateIdsEnabled));
    dispatch(
      updateDatabaseSummaryCopiedProperties(
        currentDatabaseId,
        updatedDatabaseProperties
      )
    );
  };

import {
  IActionsAddDocument,
  IActionsRemoveDocument,
  IActionsUpdateCachedDocument,
} from "../actions/documentCache";
import { Document } from "../../../data/models/document";

export const addDocument = (
  document: Document,
  documentMapId: string
): IActionsAddDocument => {
  return {
    type: "ADD_DOCUMENT",
    documentMapId: documentMapId,
    document: document,
  };
};

export const removeDocument = (
  documentMapId: string
): IActionsRemoveDocument => {
  return {
    type: "REMOVE_DOCUMENT",
    documentMapId: documentMapId,
  };
};

export const updateCachedDocument = (
  documentMapId: string,
  document: Document
): IActionsUpdateCachedDocument => {
  return {
    type: "UPDATE_CACHED_DOCUMENT",
    documentMapId: documentMapId,
    document: document,
  };
};

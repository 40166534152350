import React, { FunctionComponent } from "react";

import { Snackbar, makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { FoodWorksError } from "../../constants/errors";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 50,
    width: "30%",
    height: 100,
    display: "flex",
  },
  alert: {
    flex: "1",
  },
}));

const ERROR_TIMEOUT = 10000;

export interface ErrorPopupProps {
  showError: boolean;
  error: FoodWorksError;
  onSnackBarClose: (reason: string) => void;
  onAlertClose: () => void;
}

export const ErrorPopup: FunctionComponent<ErrorPopupProps> = ({
  showError,
  error,
  onSnackBarClose,
  onAlertClose,
}) => {
  const classes = useStyles();
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      className={classes.root}
      open={showError}
      autoHideDuration={ERROR_TIMEOUT}
      onClose={(event, reason) => onSnackBarClose(reason)}
    >
      <Alert
        className={classes.alert}
        onClose={() => onAlertClose()}
        severity="error"
      >
        <AlertTitle>Error</AlertTitle>
        <strong>{error.message}</strong>
        {error.description}
      </Alert>
    </Snackbar>
  );
};

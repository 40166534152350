import { v4 as uuidv4 } from "uuid";

import {
  DocumentActions,
  UPDATE_DOCUMENT_METHOD,
  UPDATE_DOCUMENT_YIELD,
  UPDATE_DOCUMENT_SERVES,
  UPDATE_DOCUMENT_NAME,
  UPDATE_DOCUMENT_IDENTIFIER,
  UPDATE_DOCUMENT_ALT_IDENTIFIER,
  UPDATE_DOCUMENT_DESCRIPTION,
  UPDATE_VOLUME_CONVERSION,
  TOGGLE_PROPERTY_IS_LIQUID,
  UPDATE_DOCUMENT_NOTE,
  UPDATE_DOCUMENT_STATUS,
  UPDATE_DOCUMENT_MODIFIED_DATE,
  UPDATE_CALCULATION_METHOD,
  SET_DOCUMENT_MAPPING_ID,
  SET_DOCUMENT_TAG_IDS,
  DELETE_METHOD_SECTION,
  CREATE_METHOD_SECTION,
  UPDATE_METHOD_SECTION_IS_COLLAPSED,
  UPDATE_METHOD_SECTION_TITLE,
} from "../actions/document";
import {
  UPDATE_FOOD_ITEM,
  UPDATE_QUANTITY,
  CLEAR_QUANTITY,
  UPDATE_NOTE,
  CLEAR_FOOD_ITEM,
  ADD_FOOD_ITEM,
  REMOVE_FOOD_ITEM,
  UPDATE_RETENTION_FACTOR,
  CLEAR_RETENTION_FACTOR,
  REMOVE_MULTIPLE_FOOD_ITEMS,
} from "../actions/foodItems";
import { FoodWorksDate } from "../../../../data/models/documentProperties/date";
import { Identifier } from "../../../../data/models/documentProperties/identifier";
import { DocumentProperties } from "../../../../data/models/documentProperties/extraProperties";
import { RECIPES } from "../../../../constants/FoodTemplate";
import {
  nutrientOverrides,
  initialNutrientOverridesState,
} from "./nutrientOverrides";
import {
  ADD_NUTRIENT_OVERRIDE,
  REMOVE_NUTRIENT_OVERRIDE,
  UPDATE_NUTRIENT_OVERRIDE_VALUE,
  UPDATE_NUTRIENT_OVERRIDE_NOTE,
} from "../actions/nutrientOverrides";
import { commonMeasures, initialCommonMeasuresState } from "./commonMeasures";
import {
  ADD_COMMON_MEASURE,
  UPDATE_COMMON_MEASURE,
  SET_DEFAULT_COMMON_MEASURE,
  REMOVE_COMMON_MEASURE,
} from "../actions/commonMeasures";
import { Document, DocumentTemplateId } from "../../../../data/models/document";
import {
  ServeType,
  Serve,
} from "../../../../data/models/documentProperties/serve";
import {
  YieldType,
  Yield,
} from "../../../../data/models/documentProperties/yield";
import { VolumeConversionObject } from "../../../../data/models/documentProperties/volumeConversionFactor";
import { days } from "./days";
import {
  ADD_DAY,
  SET_DAYS,
  UPDATE_DAY_DATE,
  UPDATE_DAY_TITLE,
} from "../actions/days";
import {
  ADD_MULTIPLE_SECTIONS,
  ADD_SECTION,
  ADD_SECTION_TAG,
  DELETE_SECTIONS,
  REMOVE_SECTION_TAG,
  UPDATE_MULTIPLE_SECTIONS,
  UPDATE_SECTION,
  UPDATE_SECTION_IS_COLLAPSED,
  UPDATE_SECTION_TITLE,
} from "../actions/sections";
import {
  ADD_SECTION_TAGS,
  DELETE_SECTION_TAG,
  UPDATE_SECTION_TAG,
} from "../actions/sectionTags";
import { sectionTags } from "./sectionTags";

export const initialDate: FoodWorksDate = {
  created: "",
  lastModified: "",
};

export const initialIdentifiers: Identifier = {
  id1: "",
  id2: "",
};

export const initialProperties: DocumentProperties = {
  state: "active",
  isLiquid: false,
};

export const initialServe: Serve = {
  type: ServeType.PERCENT,
  value: 1,
};

export const initialYield: Yield = {
  type: YieldType.PERCENT,
  amount: 100,
};

export const initialVolumeConversion: VolumeConversionObject = {
  volumeG: undefined,
  volumeMl: undefined,
};

export type DocumentState = Document;

export const initialDocumentState: DocumentState = {
  name: "",
  // Temp default to recipe
  templateId: RECIPES.id.toString() as DocumentTemplateId,
  date: initialDate,
  identifier: initialIdentifiers,
  yield: initialYield,
  calculationMethod: 0,
  documentMappingId: "",
  note: "",
  description: "",
  method: [
    {
      title: "New section",
      data: "",
      index: 0,
      isCollapsed: false,
    },
  ],
  properties: initialProperties,
  days: [
    {
      title: "",
      id: "",
      index: 0,
      date: "",
      sections: [
        {
          title: "New section",
          id: uuidv4(),
          index: 0,
          foodItems: [],
          isCollapsed: false,
          tags: [],
        },
      ],
    },
  ],
  commonMeasures: initialCommonMeasuresState,
  volumeConversion: initialVolumeConversion,
  serve: initialServe,
  nutrientOverrides: initialNutrientOverridesState,
  nutrientOverrideMappings: [""],
  sectionTags: [],
  usedIn: [],
  documentTags: [],
};

export const document = (
  state: DocumentState = initialDocumentState,
  action: DocumentActions
): DocumentState => {
  switch (action.type) {
    case UPDATE_VOLUME_CONVERSION:
      return {
        ...state,
        volumeConversion: action.volumeConversion,
      };
    case UPDATE_DOCUMENT_MODIFIED_DATE:
      return {
        ...state,
        date: {
          ...state.date,
          lastModified: action.date,
        },
      };
    case UPDATE_DOCUMENT_NAME:
      return {
        ...state,
        name: action.name,
      };
    case UPDATE_DOCUMENT_IDENTIFIER:
      return {
        ...state,
        identifier: {
          ...state.identifier,
          id1: action.identifier,
        },
      };
    case UPDATE_DOCUMENT_ALT_IDENTIFIER:
      return {
        ...state,
        identifier: {
          ...state.identifier,
          id2: action.altIdentifier,
        },
      };
    case UPDATE_DOCUMENT_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };
    case UPDATE_DOCUMENT_METHOD:
      return {
        ...state,
        method: state.method.map((section) =>
          section.index === action.index ? action.section : section
        ),
      };

    case UPDATE_METHOD_SECTION_IS_COLLAPSED:
      return {
        ...state,
        method: state.method.map((section) =>
          section.index === action.index
            ? { ...section, isCollapsed: action.isCollapsed }
            : section
        ),
      };
    case DELETE_METHOD_SECTION:
      return {
        ...state,
        method: state.method.filter(
          (section) => section.index !== action.index
        ),
      };
    case CREATE_METHOD_SECTION:
      return {
        ...state,
        method: [...state.method, action.newSection],
      };
    case UPDATE_METHOD_SECTION_TITLE:
      return {
        ...state,
        method: state.method.map((section) =>
          section.index === action.index
            ? { ...section, title: action.newTitle }
            : section
        ),
      };
    case UPDATE_DOCUMENT_YIELD:
      return {
        ...state,
        yield: {
          ...state.yield,
          type: action.yieldType,
          amount: action.amount,
        },
      };
    case UPDATE_DOCUMENT_SERVES:
      return {
        ...state,
        serve: {
          ...state.serve,
          type: action.serveType,
          value: action.amount,
        },
      };
    case TOGGLE_PROPERTY_IS_LIQUID:
      return {
        ...state,
        properties: {
          ...state.properties,
          isLiquid: !state.properties.isLiquid,
        },
        volumeConversion: {
          volumeG:
            !state.properties.isLiquid && !state.volumeConversion.volumeG
              ? 1.0
              : state.volumeConversion.volumeG,
          volumeMl:
            !state.properties.isLiquid && !state.volumeConversion.volumeMl
              ? 1.0
              : state.volumeConversion.volumeMl,
        },
      };
    case UPDATE_DOCUMENT_STATUS:
      return {
        ...state,
        properties: {
          ...state.properties,
          state: action.status
        },
      };
    case UPDATE_DOCUMENT_NOTE:
      return {
        ...state,
        note: action.note,
      };
    case UPDATE_CALCULATION_METHOD:
      return {
        ...state,
        calculationMethod: action.calculationMethod,
        documentMappingId: action.mappedDocumentId,
      };
    case SET_DOCUMENT_MAPPING_ID:
      return { ...state, documentMappingId: action.documentId };
    case ADD_SECTION_TAGS:
    case DELETE_SECTION_TAG:
    case UPDATE_SECTION_TAG:
      return { ...state, sectionTags: sectionTags(state.sectionTags, action) };
    case REMOVE_COMMON_MEASURE:
    case ADD_COMMON_MEASURE:
    case SET_DEFAULT_COMMON_MEASURE:
    case UPDATE_COMMON_MEASURE:
      return {
        ...state,
        commonMeasures: commonMeasures(state.commonMeasures, action),
      };
    case ADD_NUTRIENT_OVERRIDE:
    case REMOVE_NUTRIENT_OVERRIDE:
    case UPDATE_NUTRIENT_OVERRIDE_VALUE:
    case UPDATE_NUTRIENT_OVERRIDE_NOTE:
      return {
        ...state,
        nutrientOverrides: nutrientOverrides(state.nutrientOverrides, action),
      };
    case REMOVE_MULTIPLE_FOOD_ITEMS:
    case ADD_DAY:
    case SET_DAYS:
    case UPDATE_DAY_DATE:
    case UPDATE_DAY_TITLE:
    case ADD_SECTION:
    case UPDATE_SECTION:
    case ADD_MULTIPLE_SECTIONS:
    case UPDATE_MULTIPLE_SECTIONS:
    case UPDATE_SECTION_TITLE:
    case UPDATE_SECTION_IS_COLLAPSED:
    case ADD_SECTION_TAG:
    case REMOVE_SECTION_TAG:
    case DELETE_SECTIONS:
    case ADD_FOOD_ITEM:
    case REMOVE_FOOD_ITEM:
    case CLEAR_FOOD_ITEM:
    case UPDATE_FOOD_ITEM:
    case UPDATE_QUANTITY:
    case CLEAR_QUANTITY:
    case UPDATE_NOTE:
    case UPDATE_RETENTION_FACTOR:
    case CLEAR_RETENTION_FACTOR:
      return {
        ...state,
        days: days([...state.days], action),
      };
    case SET_DOCUMENT_TAG_IDS:
      return {
        ...state,
        documentTags: action.tagIds,
      };
    default:
      return state;
  }
};

import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Paper } from "@material-ui/core";
import _ from "lodash";

import { NRVTable } from "../../../common/sticky_table/NRVTable";
import { nutrientReferenceValuesSelector } from "../../../../store/data/selectors/clientDatabase";
import { NRVType } from "../../../../data/models/nutrientReferenceValues";
import { CategoriesSelector } from "../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../store/reducers";
import { Category } from "../../../../data/models/category";
import { NRVTableItems } from "../../../common/sticky_table/components/NRVTableItems";
import {
  buildHeader,
  convertEnergyNRValuesToRows,
  convertNRValuesToRows,
  NutrientValues,
  useCategoryNutrientsMap,
} from "../../../common/sticky_table/helper/NRVTableBuilder";
import { ClientGoal } from "../../../../data/models/clientProperties/clientGoal";
import { clientGoalSelector } from "../../../../store/data/current_client/selectors/client";
import {
  clientIsUseCER,
  clientRestingMetabolicRateSelector,
} from "../../../../store/data/current_client/selectors/clientProfile";
import { ENERGY_ID } from "../../../../constants/nutrientIds";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: 15,
  },
}));

const mapGoalsToNutrient = (goals: ClientGoal[]): Map<string, ClientGoal[]> => {
  const map = new Map<string, ClientGoal[]>([]);
  for (const goal of goals) {
    map.has(goal.nutrientId)
      ? map.set(goal.nutrientId, map.get(goal.nutrientId)!.concat([goal]))
      : map.set(goal.nutrientId, [goal]);
  }
  return map;
};

const EnergyTable = (): JSX.Element => {
  const nutrientReferenceValues: Map<string, Map<NRVType, number>> =
    useSelector(nutrientReferenceValuesSelector);

  const deepCloneNRVs: Map<string, Map<NRVType, number>> = _.cloneDeep(
    nutrientReferenceValues
  );

  const goals: ClientGoal[] =
    useSelector<RootState, ClientGoal[]>(clientGoalSelector);
  const goalsMap = useMemo(() => mapGoalsToNutrient(goals), [goals]);

  const [categoryNutrientsMap] = useCategoryNutrientsMap();

  const useRMR: boolean = useSelector<RootState, boolean>(clientIsUseCER);

  const RMR: number | undefined = useSelector<RootState, number | undefined>(
    clientRestingMetabolicRateSelector
  );

  const getEnergyRows = useCallback(
    (nutrientsData: Map<string, NutrientValues>): string[][] => {
      const energyDatas: string[][] = [];
      for (const [nutrientId, data] of [...nutrientsData]) {
        const units: string = data.units ? ` (${data.units})` : "";
        const label: string = `${data.name}${units}`;

        const nrvalues: Map<NRVType, number> | undefined =
          deepCloneNRVs.get(nutrientId);

        const customEnergyRequirement =
          useRMR && ENERGY_ID === nutrientId
            ? nrvalues?.set("EERM", RMR!)
            : undefined;

        if (data.name.toLowerCase().includes("energy")) {
          const nrvRows: string[] = convertEnergyNRValuesToRows(
            customEnergyRequirement || nrvalues
          );
          const rowData: string[] = [label].concat(nrvRows);
          energyDatas.push(rowData);
        }
      }
      return energyDatas;
    },
    [deepCloneNRVs, useRMR, RMR]
  );

  const energyHeaderMemo: string[] = useMemo(() => buildHeader("Energy"), []);

  const energyRowsMemo: string[][][] = useMemo(
    () =>
      [...categoryNutrientsMap]
        .map(([, nutrientsData]) => getEnergyRows(nutrientsData))
        .filter((row: string[][]) => !!row.length),
    [categoryNutrientsMap, getEnergyRows]
  );

  const energyNutrientIdRowsMemo: string[][] = useMemo(() => {
    const nutrientIdRows: string[][] = [];
    for (const nutrientsData of categoryNutrientsMap.values()) {
      const nutrientIds = [...nutrientsData]
        .filter(([, data]) => data.name.toLowerCase().includes("energy"))
        .map(([nutrientId]) => nutrientId);
      nutrientIdRows.push(nutrientIds);
    }
    return nutrientIdRows.filter((row: string[]) => !!row.length);
  }, [categoryNutrientsMap]);

  return (
    <NRVTableItems
      key="Energy"
      headers={energyHeaderMemo}
      rows={energyRowsMemo[0] || []}
      nutrientIds={energyNutrientIdRowsMemo[0] || []}
      goals={goalsMap}
    />
  );
};

const MainTableItems = (): JSX.Element => {
  const nutrientReferenceValues: Map<string, Map<NRVType, number>> =
    useSelector(nutrientReferenceValuesSelector);

  const categories: Map<string, Category> =
    useSelector<RootState, Map<string, Category>>(CategoriesSelector);

  const goals: ClientGoal[] =
    useSelector<RootState, ClientGoal[]>(clientGoalSelector);
  const goalsMap = useMemo(() => mapGoalsToNutrient(goals), [goals]);

  const [categoryNutrientsMap] = useCategoryNutrientsMap();

  const getHeader = useCallback(
    (categoryId: string): string[] => {
      const categoryData: Category | undefined = categories.get(categoryId);

      if (!!categoryData) {
        const categoryName = categoryData.name;
        return buildHeader(categoryName);
      } else {
        return [];
      }
    },
    [categories]
  );

  const getRows = useCallback(
    (
      nutrientsData: Map<string, NutrientValues>,
      categoryId: string
    ): string[][] => {
      const rowDatas: string[][] = [];
      const categoryName = categories.get(categoryId)?.name;
      if (!categoryName) return [];
      for (const [nutrientId, data] of [...nutrientsData]) {
        const units: string = data.units ? ` (${data.units})` : "";
        const label: string = `${data.name}${units}`;

        const nrvalues: Map<NRVType, number> | undefined =
          nutrientReferenceValues.get(nutrientId);

        if (data.name.toLowerCase().includes("energy")) continue;
        else {
          const nrvRows: string[] = convertNRValuesToRows(nrvalues);
          const rowData: string[] = [label].concat(nrvRows);
          rowDatas.push(rowData);
        }
      }
      return rowDatas;
    },
    [nutrientReferenceValues, categories]
  );

  const headerMemo: string[][] = useMemo(
    () =>
      [...categoryNutrientsMap].map(([categoryId]) => getHeader(categoryId)),
    [categoryNutrientsMap, getHeader]
  );

  const rowsMemo: string[][][] = useMemo(
    () =>
      [...categoryNutrientsMap].map(([categoryId, nutrientsData]) =>
        getRows(nutrientsData, categoryId)
      ),
    [categoryNutrientsMap, getRows]
  );

  const nutrientIdRowsMemo: string[][] = useMemo(() => {
    const nutrientIdRows: string[][] = [];
    for (const nutrientsData of categoryNutrientsMap.values()) {
      const nutrientIds = [...nutrientsData]
        .filter(([, data]) => !data.name.toLowerCase().includes("energy"))
        .map(([nutrientId]) => nutrientId);

      nutrientIdRows.push(nutrientIds);
    }
    return nutrientIdRows;
  }, [categoryNutrientsMap]);

  return (
    <>
      {headerMemo.map((val: string[], i: number) => (
        <NRVTableItems
          key={headerMemo[i][0]}
          headers={headerMemo[i]}
          rows={rowsMemo[i]}
          nutrientIds={nutrientIdRowsMemo[i]}
          goals={goalsMap}
        />
      ))}
    </>
  );
};

const NRVTab = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <NRVTable
        title="Dietary Needs"
        stickyTableItems={[<EnergyTable />, <MainTableItems />]}
      />
    </Paper>
  );
};

export default NRVTab;

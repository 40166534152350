import { useSelector } from "react-redux";

import { UserDatabaseSummary } from "../../../../data/models/userDatabase";
import { sortedUserDatabaseSummariesSelector } from "../../../../store/data/selectors/user";
import { RootState } from "../../../../store/reducers";

const useRecentDatabases = (numberToReturn: number): UserDatabaseSummary[] => {
  const databaseSummaries: UserDatabaseSummary[] = useSelector<
    RootState,
    UserDatabaseSummary[]
  >(sortedUserDatabaseSummariesSelector);

  const sortedDatabaseSummaries = databaseSummaries.sort((a, b) =>
    a.date.lastModified < b.date.lastModified
      ? -1
      : a.date.lastModified > b.date.lastModified
      ? 1
      : 0
  );

  return sortedDatabaseSummaries.length <= numberToReturn
    ? sortedDatabaseSummaries
    : sortedDatabaseSummaries.slice(0, numberToReturn);
};

export default useRecentDatabases;

import React, { ReactNode } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  collapseButton: { minWidth: 24 },
}));

type PaneType = "Navigation" | "Nutrition";

const getNavigationIcon = (type: PaneType, open: boolean): ReactNode => {
  if (type === "Navigation") {
    return open ? <NavigateBefore /> : <NavigateNext />;
  }

  return open ? <NavigateNext /> : <NavigateBefore />;
};

interface CollapsePaneButtonProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  type: PaneType;
}

const CollapsePaneButton = ({
  open,
  setOpen,
  type,
}: CollapsePaneButtonProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      data-cy="navPaneCollapseButton"
      className={classes.collapseButton}
      onClick={() => setOpen(!open)}
    >
      {getNavigationIcon(type, open)}
    </Button>
  );
};

export default CollapsePaneButton;

import { useState } from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";

import {
  Measurement,
  MeasurementCategory,
} from "../../../../../../data/models/clientProperties/measurement";
import { BaseDialog } from "../../../../../dialogs/BaseDialog";
import { FoodWorksTextInput } from "../../../../../common/FoodWorksTextInput";
import useClientMeasurementDispatches from "../../hooks/useClientMeasurementDispatches";
import { NAME_FIELD, UNITS_FIELD } from "../../../../../../constants/textInputs";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginRight: 5,
  },
}));

interface Props {
  onClose: () => void;
  measurementTitle: string;
  measurementUnits: string;
  measurementCategory: MeasurementCategory;
  measurementSubCategory: string;
}

const EditMeasurementPropertiesDialog = (props: Props): JSX.Element => {
  const [, editMeasurementEntry] = useClientMeasurementDispatches();

  const onEditClientMeasurement = (
    measurement: Measurement,
    newLabel: string,
    newUnits: string
  ) => editMeasurementEntry(measurement, newLabel, newUnits);

  const classes = useStyles();

  const {
    onClose,
    measurementTitle,
    measurementUnits,
    measurementCategory,
    measurementSubCategory,
  } = { ...props };

  const [name, setName] = useState(measurementTitle);
  const [units, setUnits] = useState(measurementUnits);

  const onSaveChanges = () => {
    const measurement: Measurement = {
      category: measurementCategory,
      subCategory: measurementSubCategory,
      label: measurementTitle,
      unit: measurementUnits,
      entries: [],
      active: true,
    };

    onEditClientMeasurement(measurement, name.trim(), units.trim());
    onClose();
  };

  const changesMade: boolean =
    measurementTitle !== name.trim() || measurementUnits !== units.trim();

  return (
    <BaseDialog
      title={`Edit ${measurementTitle} measurement`}
      open
      onClose={onClose}
      maxWidth="sm"
      body={
        <div className={classes.root}>
          <div className={classes.inputContainer}>
            <Typography className={classes.label}>Name: </Typography>
            <FoodWorksTextInput
              maxLength={NAME_FIELD}
              value={name}
              onChange={(event) => setName(event.currentTarget.value)}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography className={classes.label}>Units:</Typography>
            <FoodWorksTextInput
              maxLength={UNITS_FIELD}
              value={units}
              onChange={(event) => setUnits(event.target.value)}
            />
          </div>
        </div>
      }
      action={[
        <Button onClick={onClose}>Cancel</Button>,
        <Button
          disabled={!changesMade}
          color="secondary"
          onClick={onSaveChanges}
        >
          Save changes
        </Button>,
      ]}
    />
  );
};

export default EditMeasurementPropertiesDialog;

import {
  SET_NAV_SEARCH_TEXT,
  SET_NAV_SEARCH_FILTERS,
  TAPPED_NAV_ACCORDION,
  TOGGLE_QUICK_FILTERS,
  NavigationActions,
  SET_NAV_IS_LOADING,
} from "../actions/navigationActions";
import {
  initialQuickFilterState,
  QuickFilter,
} from "../../../constants/QuickFilter";

export interface NavigationState {
  searchText: string;
  quickFiltersEnabled: boolean;
  selectedQuickFilters: QuickFilter;
  navAccordionsExpanded: number[];
  isLoading: boolean;
}

export const initialNavigationState = {
  searchText: "",
  selectedQuickFilters: initialQuickFilterState,
  quickFiltersEnabled: false,
  navAccordionsExpanded: [0, 1, 2, 3, 4],
  isLoading: true,
};

export const navigation = (
  state: NavigationState = initialNavigationState,
  action: NavigationActions
) => {
  switch (action.type) {
    case SET_NAV_SEARCH_TEXT:
      return { ...state, searchText: action.text };
    case SET_NAV_SEARCH_FILTERS:
      return {
        ...state,
        selectedQuickFilters: action.filters,
      };
    case TOGGLE_QUICK_FILTERS:
      return {
        ...state,
        quickFiltersEnabled: !state.quickFiltersEnabled,
      };
    case TAPPED_NAV_ACCORDION:
      if (state.navAccordionsExpanded.includes(action.templateId)) {
        return {
          ...state,
          navAccordionsExpanded: state.navAccordionsExpanded.filter(
            (templateId) => templateId !== action.templateId
          ),
        };
      }
      return {
        ...state,
        navAccordionsExpanded: state.navAccordionsExpanded.concat(
          action.templateId
        ),
      };
    case SET_NAV_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

import React, { FunctionComponent, ReactNode } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { DialogProps } from "@material-ui/core/Dialog";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleBar: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    padding : "1rem"
  },
  hideScrollBar: {
    overflow: "hidden",
  },
  topActionBar : {
    display : "flex",
    alignItems : "center",
  }
}));

export interface BaseDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  body: ReactNode;
  action?: ReactNode;
  maxWidth: DialogProps["maxWidth"];
  dataCy?: string;
  fullWidth?: boolean;
  topActions? : ReactNode
}

export const BaseDialog: FunctionComponent<BaseDialogProps> = ({
  open,
  onClose,
  title,
  body,
  action,
  dataCy = "",
  maxWidth,
  fullWidth = true,
  topActions
}) => {
  const classes = useStyles();

  const header: ReactNode = (
    <div className={classes.titleBar}>
      <DialogTitle>{title}</DialogTitle>
      <div className={classes.topActionBar}>
        {topActions}
        <IconButton size="small" data-cy="dialogXButton" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      data-cy={dataCy}
      onBackdropClick={onClose}
    >
      {header}
      <DialogContent data-cy="dialogXContent" className={classes.hideScrollBar}>
        {body}
      </DialogContent>
      <DialogActions>{action}</DialogActions>
    </Dialog>
  );
};

import { ActivityLevel, PregnancyLevel, Sex } from "../nutrientReferenceValues";

export interface ClientProfile {
  age: number | undefined;
  dob: string | undefined;
  sex: Sex;
  weight: number | undefined;
  height: number | undefined;
  activityLevel: ActivityLevel;
  pregnancyLevel: PregnancyLevel;
  isLactating: boolean;
  restingMetabolicRate: number | undefined;
  useCER: boolean;
}

export const clientProfileConverter = {
  toFirestore: function (
    profile: ClientProfile
  ): firebase.firestore.DocumentData {
    return {
      age: profile.age === undefined ? null : profile.age,
      dob: profile.dob === undefined ? null : profile.dob,
      sex: profile.sex,
      weight: profile.weight === undefined ? null : profile.weight,
      height: profile.height === undefined ? null : profile.height,
      activityLevel: profile.activityLevel,
      pregnancyLevel: profile.pregnancyLevel,
      isLactating: profile.isLactating,
      useCER: profile.useCER,
      restingMetabolicRate:
        profile.restingMetabolicRate === undefined
          ? null
          : profile.restingMetabolicRate,
    };
  },
  fromFirestore: function (
    data: firebase.firestore.DocumentData
  ): ClientProfile {
    return {
      age: data.age === null ? undefined : data.age,
      dob: data.dob === undefined ? null : data.dob,
      sex: data.sex,
      weight: data.weight === null ? undefined : data.weight,
      height: data.height === null ? undefined : data.height,
      activityLevel: data.activityLevel,
      pregnancyLevel: data.pregnancyLevel,
      isLactating: data.isLactating,
      useCER: data.useCER,
      restingMetabolicRate:
        data.restingMetabolicRate === null
          ? undefined
          : data.restingMetabolicRate,
    };
  },
};

import React, { FunctionComponent } from "react";

import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export enum HandleAlignment {
  LEFT = "left",
  RIGHT = "right",
}

const useStyles = makeStyles(() => ({
  customHandle: {
    flex: 1,
    alignSelf: "center",
    background: "#fff",
    borderRadius: "2px",
    border: "1px solid #ddd",
  },
  resizableHandle: { width: 10, height: 50 },
  container: {
    height: "100%",
    display: "flex",
  },
}));

export interface ResizableHandleProps {
  isDragged: boolean;
  isHovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  alignment: HandleAlignment;
}

export const ResizableHandle: FunctionComponent<ResizableHandleProps> = ({
  isDragged,
  isHovered,
  onMouseEnter,
  onMouseLeave,
  alignment,
}) => {
  const classes = useStyles();

  const handleMargin: CSSProperties =
    alignment === HandleAlignment.RIGHT
      ? { marginLeft: isDragged || isHovered ? 1 : -1 }
      : { marginRight: isDragged || isHovered ? 2 : 0 };

  return (
    <div
      className={classes.container}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={classes.customHandle} style={handleMargin}>
        <svg viewBox="4 4 16 16" className={classes.resizableHandle}>
          <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
        </svg>
      </div>
    </div>
  );
};

import React, { ReactNode } from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";

import { appTheme } from "../../../../styling/style";

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: 15,
    marginLeft: 5,
    fill: appTheme.colors.primary,
    height: 24,
    width: 24,
  },
  text: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  root: {
    overflow: "hidden",
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "start",
    paddingLeft: 0,
  },
}));

interface Props {
  icon: ReactNode;
  text: string;
  onClick: () => void;
}

const NavigationHeader = (props: Props): JSX.Element => {
  const classes = useStyles();

  const { icon, text, onClick } = { ...props };

  return (
    <Button
      onClick={onClick}
      className={classes.root}
      startIcon={<div className={classes.icon}>{icon}</div>}
    >
      <Typography variant="h3" color="textPrimary" className={classes.text}>
        {text}
      </Typography>
    </Button>
  );
};

export default NavigationHeader;

import { NutrientOverride } from "../../../../data/models/documentProperties/nutrientOverride";
import {
  NutrientOverridesActions,
  ADD_NUTRIENT_OVERRIDE,
  REMOVE_NUTRIENT_OVERRIDE,
  UPDATE_NUTRIENT_OVERRIDE_VALUE,
  UPDATE_NUTRIENT_OVERRIDE_NOTE,
} from "../actions/nutrientOverrides";

export type NutrientOverridesState = NutrientOverride[];

export const initialNutrientOverridesState: NutrientOverride[] = [
  { id: "0", value: 100, note: "" },
];

export const nutrientOverrides = (
  state: NutrientOverride[] = initialNutrientOverridesState,
  action: NutrientOverridesActions
): NutrientOverride[] => {
  switch (action.type) {
    case ADD_NUTRIENT_OVERRIDE:
      return [...state, { id: action.id, value: action.value, note: "" }];
    case REMOVE_NUTRIENT_OVERRIDE:
      return [...state.filter((override) => override.id !== action.id)];
    case UPDATE_NUTRIENT_OVERRIDE_VALUE:
      return [
        ...state.map((override: NutrientOverride) =>
          override.id !== action.id
            ? override
            : { id: action.id, value: action.value, note: override.note }
        ),
      ];
    case UPDATE_NUTRIENT_OVERRIDE_NOTE:
      return [
        ...state.map((override: NutrientOverride) =>
          override.id !== action.id
            ? override
            : { id: action.id, value: override.value, note: action.note }
        ),
      ];
    default:
      return state;
  }
};

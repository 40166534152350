import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Home } from "@material-ui/icons";

import {
  databaseDescriptionSelector,
  databaseNameSelector,
  databaseIdSelector,
} from "../../../../store/data/selectors/database";
import {
  updateDatabaseDescription,
  updateDatabaseName,
} from "../../../../store/data/thunks/database";
import { RootState } from "../../../../store/reducers";
import { EditableTitleInput } from "../../../common/EditableTitleInput";
import { FoodWorksTextInput } from "../../../common/FoodWorksTextInput";
import { FoodworksTooltip } from "../../../common/InfoTooltip";
import { OpenDialogButton } from "../../../common/OpenDialogButton";
import { handleRouteChange } from "../../../../store/ui/thunks/routing";
import { DeleteDatabaseDialog } from "../../../dialogs/database/DeleteDatabaseDialog";
import { ShareDatabaseDialog } from "../../../dialogs/database/ShareDatabaseDialog";
import { EnabledNutrientsList } from "./EnabledNutrientsList";
import { EnabledMeasuresList } from "./EnabledMeasuresList";
import { EnabledDatasourcesList } from "./EnabledDatasourcesList";
import ImportDocumentsDialog from "../../../dialogs/database/ImportDocumentsDialog";
import { getDatabaseDashboardRouteData } from "../../../../data/routing/routing";
import RecentDocumentList from "./components/RecentDocumentList";
import { DocumentTagDialog } from "../../../dialogs/tags/document_tags/DocumentTagDialog";
import FoodworksBreadcrumb from "../../../common/FoodworksBreadcrumb";
import { LARGE_FIELD } from "../../../../constants/textInputs";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    overflow: "auto",
    marginLeft: 10,
  },
  content: {
    margin: 25,
  },
  centerContent: {
    display: "flex",
    marginTop: 25,
  },
  name: {
    flex: 1,
    margin: 5,
  },
  inputContainer: {
    marginRight: 10,
  },
  textArea: {
    resize: "none",
    width: "70%",
  },
  textAreaRow: {
    marginLeft: 10,
    display: "flex",
    justifyItems: "flex-start",
    flexDirection: "column",
  },
  recentDocumentsRoot: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  recentDocumentsCard: {
    marginTop: 10,
    height: 300,
  },
  documentListButton: {
    flex: 1,
    justifyContent: "start",
  },

  subContent1: { width: 500, display: "flex", flexDirection: "column" },
  buttonContainerRoot: {
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
    marginTop: 25,
  },
  buttonVerticalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  homeIcon: {
    width: 40,
    height: 40,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
  },
  propertiesRoot: { marginLeft: 200 },
  propertiesList: {
    marginTop: 15,
  },
  propertiesMargin: {
    marginTop: 10,
  },
  breadcrumbContainer: {
    margin: 10,
  },
}));

interface configButtons {
  reference: boolean;
  nutrients: boolean;
  sharing: boolean;
  delete: boolean;
  datasources: boolean;
  import: boolean;
  tag: boolean;
}

const useDatabaseDescription = (): [
  string,
  React.Dispatch<React.SetStateAction<string>>,
  string
] => {
  const databaseDescription: string = useSelector<RootState, string>(
    databaseDescriptionSelector
  );

  const [localDescription, setLocalDescription] = useState(databaseDescription);

  useEffect(() => {
    setLocalDescription(databaseDescription);
  }, [databaseDescription]);

  return [localDescription, setLocalDescription, databaseDescription];
};

const useDatabaseName = (): [
  string,
  React.Dispatch<React.SetStateAction<string>>,
  string
] => {
  const databaseName: string = useSelector<RootState, string>(
    databaseNameSelector
  );

  const [localName, setLocalName] = useState(databaseName);

  useEffect(() => {
    setLocalName(databaseName);
  }, [databaseName]);

  return [localName, setLocalName, databaseName];
};

export const DatabaseOverview: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [buttons, setButtons] = useState<configButtons>({
    reference: false,
    nutrients: false,
    sharing: false,
    delete: false,
    datasources: false,
    import: false,
    tag: false,
  });

  const onUpdateDatabaseDescription = (description: string) =>
    dispatch(updateDatabaseDescription(description));

  const onUpdateDatabaseName = (name: string) =>
    dispatch(updateDatabaseName(name));

  const onNavigateToDatabasesDashboard = () =>
    dispatch(handleRouteChange(getDatabaseDashboardRouteData()));

  const currentDatabaseId: string = useSelector<RootState, string>(
    databaseIdSelector
  );

  const [name, setName, reduxDatabaseName] = useDatabaseName();

  const [
    description,
    setDescription,
    reduxDatabaseDescription,
  ] = useDatabaseDescription();

  const onUpdateNameInputBlur = () => {
    const trimmedName: string = name.trimEnd();
    if (trimmedName !== reduxDatabaseName) {
      onUpdateDatabaseName(trimmedName);
    }
  };

  const onDescriptionInputBlur = () => {
    const trimmedDescription: string = description.trimRight();
    if (trimmedDescription !== reduxDatabaseDescription) {
      onUpdateDatabaseDescription(trimmedDescription);
    }
  };

  const nameInput: ReactNode = (
    <EditableTitleInput
      data-cy="databaseNameInput"
      className={classes.name}
      value={name}
      onChange={(event) => setName(event.currentTarget.value)}
      onBlur={onUpdateNameInputBlur}
    />
  );

  const descriptionInput: ReactNode = (
    <div className={classes.textAreaRow}>
      <Typography variant="body1">Description: </Typography>
      <FoodWorksTextInput
        maxLength={LARGE_FIELD}
        data-cy="databaseDescription"
        multiline
        className={classes.textArea}
        rows={8}
        value={description}
        onChange={(event) => setDescription(event.currentTarget.value)}
        onBlur={onDescriptionInputBlur}
      />
    </div>
  );

  const databasePropertiesList: ReactNode = (
    <div className={classes.propertiesRoot}>
      <Typography variant="h3">Database properties</Typography>
      <div className={classes.propertiesList}>
        <EnabledNutrientsList
          openDialog={buttons.nutrients}
          onToggleDialog={() =>
            setButtons({ ...buttons, nutrients: !buttons.nutrients })
          }
        />
        <div className={classes.propertiesMargin}>
          <EnabledMeasuresList
            openDialog={buttons.reference}
            onToggleDialog={() =>
              setButtons({ ...buttons, reference: !buttons.reference })
            }
          />
        </div>
        <div className={classes.propertiesMargin}>
          <EnabledDatasourcesList
            openDialog={buttons.datasources}
            onToggleDialog={() =>
              setButtons({ ...buttons, datasources: !buttons.datasources })
            }
          />
        </div>
      </div>
    </div>
  );

  const navigateToDatabasesDashboardButton: ReactNode = (
    <FoodworksTooltip title="View all databases">
      <IconButton
        size="medium"
        color="secondary"
        onClick={onNavigateToDatabasesDashboard}
        data-cy="databaseDashboardButton"
      >
        <Home className={classes.homeIcon} />
      </IconButton>
    </FoodworksTooltip>
  );

  const deleteDatabaseButton: ReactNode = [
    <OpenDialogButton
      key="databaseOverview-deleteDatabaseButton"
      cypressTag="deleteDatabaseButton"
      onClick={() => setButtons({ ...buttons, delete: true })}
      label="Delete database"
    />,

    buttons.delete && (
      <DeleteDatabaseDialog
        key="databaseOverview-deleteDatabaseDialog"
        open
        onClose={() => setButtons({ ...buttons, delete: false })}
        databaseName={reduxDatabaseName}
        databaseId={currentDatabaseId}
      />
    ),
  ];

  const shareDatabaseButton: ReactNode = [
    <OpenDialogButton
      key="databaseOverview-shareDatabaseButton"
      onClick={() => setButtons({ ...buttons, sharing: true })}
      label="Share database"
    />,

    buttons.sharing && (
      <ShareDatabaseDialog
        open
        onClose={() => setButtons({ ...buttons, sharing: false })}
      />
    ),
  ];

  const importDocumentsButton: ReactNode = [
    <OpenDialogButton
      key="databaseOverview-importsDocumentsButton"
      cypressTag="importDocumentsButton"
      onClick={() => setButtons({ ...buttons, import: true })}
      label="Import documents"
    />,
    buttons.import && (
      <ImportDocumentsDialog
        onClose={() => setButtons({ ...buttons, import: false })}
      />
    ),
  ];

  const importTagsButton: ReactNode = [
    <OpenDialogButton
      key="databaseOverview-importTagsButton"
      cypressTag="manageDocumentTagsButton"
      onClick={() => setButtons({ ...buttons, tag: true })}
      label="Manage document tags"
    />,

    <DocumentTagDialog
      key="databaseOverview-documentTagDialog"
      open={buttons.tag}
      onClose={() => setButtons({ ...buttons, tag: false })}
    />,
  ];

  const databaseActionButtons: ReactNode = (
    <div className={classes.buttonContainerRoot}>
      <div className={classes.buttonVerticalContainer}>
        {importTagsButton}
        {importDocumentsButton}
      </div>
      <div className={classes.buttonVerticalContainer}>
        {shareDatabaseButton}
        {deleteDatabaseButton}
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.breadcrumbContainer}>
        <FoodworksBreadcrumb />
      </div>
      <div className={classes.titleContainer}>
        {nameInput} {navigateToDatabasesDashboardButton}
      </div>
      <div className={classes.content}>
        <div className={classes.inputContainer}>{descriptionInput}</div>
        <div className={classes.centerContent}>
          <div className={classes.subContent1}>
            <RecentDocumentList />
            {databaseActionButtons}
          </div>
          {databasePropertiesList}
        </div>
      </div>
    </div>
  );
};

import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { FoodGeneral } from "./tabs/general/General";
import { FoodMeasures } from "./tabs/measures/Measures";
import { Overrides } from "./tabs/overrides/Overrides";
import { TabbedPane, TabbedPaneTab } from "../../../common/TabbedPane";
import { RecipeIngredients } from "./tabs/ingredients/Ingredients";
import { RootState } from "../../../../store/reducers";
import { templateIdSelector } from "../../../../store/data/current-document/selectors/document";
import { IngredientSummaryItem } from "./tabs/ingredients/editing_grid/rows/cells/IngredientCell";
import { MethodTab } from "./tabs/method/Method";
import { TitleBar } from "./TitleBar";
import {
  is24HourRecall,
  isFood,
  isFoodRecord,
  isMealPlan,
  isRecipe,
} from "../../../../constants/FoodTemplate";
import { LoadingBarPadding } from "../../../common/LoadingBarPadding";
import { useSummaries } from "./foodScreenHooks";
import { createSearchMap } from "./tabs/ingredients/editing_grid/rows/cells/ingredientUtils";
import { setCurrentTab } from "../../../../store/ui/actionCreators/documentScreen";
import { currentTabSelector } from "../../../../store/ui/selectors/documentScreen";
import FoodworksBreadcrumb from "../../../common/FoodworksBreadcrumb";
import useNutritionPaneWidth from "../nutrition_pane/hooks/useNutritionPaneWidth";
import { withFirebase } from "../../../../data/Firebase";
import { FirebaseProps } from "../../../../data/Firebase/firebase";

type FoodScreenProps = {
  publicSummaries: IngredientSummaryItem[];
  screenType: ScreenType;
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: 5,
    marginBottom: 0,
  },
  breadcrumbContainer: {
    margin: 10,
  },
}));

export type ScreenType = "Document" | "Client";

interface DocumentTabsProps {
  summaries: IngredientSummaryItem[];
  searchTermMap: Map<string, string[]>;
  id: string;
  screenType: ScreenType;
}

export const getDocumentTabs = ({
  summaries,
  searchTermMap,
  id,
  screenType,
}: DocumentTabsProps): TabbedPaneTab[] => {
  const addGeneralTab = (): TabbedPaneTab => ({
    title: "General",
    content: <FoodGeneral />,
  });

  const addIngredientsTab = (id: string): TabbedPaneTab | null => {
    if (isRecipe(id)) {
      return {
        title: "Ingredients",
        content: (
          <RecipeIngredients
            summaries={summaries}
            searchTermMap={searchTermMap}
          />
        ),
      };
    } else if (is24HourRecall(id) || isMealPlan(id) || isFoodRecord(id)) {
      return {
        title: "Foods",
        content: (
          <RecipeIngredients
            summaries={summaries}
            searchTermMap={searchTermMap}
          />
        ),
      };
    } else {
      return null;
    }
  };

  const addMethodTab = (id: string): TabbedPaneTab | null => {
    if (isRecipe(id)) {
      return {
        title: "Method",
        content: <MethodTab />,
      };
    } else {
      return null;
    }
  };

  const addOverridesTab = (id: string): TabbedPaneTab | null => {
    if (isRecipe(id)) {
      return {
        title: "Overrides",
        content: (
          <Overrides summaries={summaries} searchTermMap={searchTermMap} />
        ),
      };
    } else if (isFood(id)) {
      return {
        title: "Nutrients",
        content: (
          <Overrides summaries={summaries} searchTermMap={searchTermMap} />
        ),
      };
    } else {
      return null;
    }
  };

  const addMeasuresTab = (id: string): TabbedPaneTab | null => {
    if (isRecipe(id) || isFood(id)) {
      return {
        title: "Measures",
        content: <FoodMeasures summaries={summaries} />,
      };
    } else {
      return null;
    }
  };

  const addNRVTab = (id: string): TabbedPaneTab | null => {
    if (
      screenType === "Document" &&
      (is24HourRecall(id) || isMealPlan(id) || isFoodRecord(id))
    ) {
      return { title: "NRVs/Goals", content: <div>Content to come</div> };
    } else {
      return null;
    }
  };

  return [
    addGeneralTab(),
    addIngredientsTab(id),
    addMethodTab(id),
    addOverridesTab(id),
    addMeasuresTab(id),
    addNRVTab(id),
  ].filter((tab: TabbedPaneTab | null) => tab !== null) as TabbedPaneTab[];
};

const FoodScreenInner = ({ publicSummaries, screenType, firebase }: FoodScreenProps & FirebaseProps): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onSetCurrentTab = (index: number) => dispatch(setCurrentTab(index));

    const currentDocumentTemplateId: string = useSelector<RootState, string>(
      templateIdSelector
    );

    const currentTab: number = useSelector<RootState, number>(
      currentTabSelector
    );

    const nutritionPaneWidth: number = useNutritionPaneWidth();

    const summaries: IngredientSummaryItem[] = useSummaries(publicSummaries, firebase!);
    const searchTermMap = useMemo(
      () => createSearchMap(summaries),
      [summaries]
    );

    const documentTabs: TabbedPaneTab[] = useMemo(
      () =>
        getDocumentTabs({
          summaries,
          searchTermMap,
          id: currentDocumentTemplateId,
          screenType,
        }),
      [summaries, searchTermMap, currentDocumentTemplateId, screenType]
    );

    return (
      <div
        className={classes.root}
        style={{ width: `calc(99% - ${nutritionPaneWidth}px)` }}
      >
        <LoadingBarPadding />
        <div className={classes.breadcrumbContainer}>
          <FoodworksBreadcrumb />
        </div>
        <TitleBar />
        <TabbedPane
          tabs={documentTabs}
          currentTab={currentTab}
          onChangeTab={onSetCurrentTab}
        />
      </div>
    );
  };

  export const FoodScreen = React.memo(withFirebase(FoodScreenInner));


export interface Category {
  name: string;
  shortName: string;
  displayOrder: number;
  groupType: number;
}

export const FOOD_GROUP_CATEGORY_ID = "19";

export const categoryConverter = {
  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): Category {
    const data: firebase.firestore.DocumentData = snapshot.data();
    return {
      name: data.name,
      shortName: data.shortName,
      displayOrder: data.displayOrder,
      groupType: data.groupType,
    };
  },

  toFirestore: function (category: Category): firebase.firestore.DocumentData {
    return {
      name: category.name,
      shortName: category.shortName,
      displayOrder: category.displayOrder,
      groupType: category.groupType,
    };
  },
};

import React, { useState } from "react";
import {
  makeStyles,
  Typography,
} from "@material-ui/core";
import { startOfDay, endOfDay } from "date-fns";

import PFCAGraph from "./graphs/PFCAGraphTable";
import NrVsIntakes from "./components/NRVsIntakes";
import TrackedMeasurements from "./components/TrackedMeasurements";
import { appTheme } from "../../../../../styling/style";
import { DateRange } from "./types";
import TrackedDateRange from "./components/TrackedDateRange";


const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "auto",
  },
  expandIcon: {
    color: appTheme.colors.primary,
  },
   header : {
    display : "flex", alignItems : "center", gap : "0.5rem"
  },
  contentContainer : {
    padding : "1rem", 
    border : `1px solid ${appTheme.colors.gainsbruh}`,
    borderRadius : "4px",
    marginBottom : "1rem"
  }
}));

const initialMinimumDate = (monthsToSubtract: number): Date => {
  const date = new Date(Date.now());
  date.setMonth(date.getMonth() - monthsToSubtract);
  return startOfDay(date);
};

const AnalysisTab = (): JSX.Element => {
  const classes = useStyles();

  const [measurementsDateRange, setMeasurementsDateRange] = 
  useState<DateRange>({min : initialMinimumDate(3), max : endOfDay( new Date())})

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.header} >
          <Typography variant="h2">Tracked measurement analysis</Typography>
          <TrackedDateRange  
           min={measurementsDateRange.min} 
           max={measurementsDateRange.max} 
           setMin={(date : Date) => setMeasurementsDateRange(range => ({...range, min : date}))}   
           setMax={(date : Date) => setMeasurementsDateRange(range => ({...range, max : date}))}/>
        </div>
          <TrackedMeasurements 
           min={measurementsDateRange.min} 
           max={measurementsDateRange.max}  />
          </div>
          <div className={classes.contentContainer}>
        <div className={classes.header} >
          <Typography variant="h2">NRVs/Intake goals analysis</Typography>
        </div>
          <NrVsIntakes />
          </div>
          <div className={classes.contentContainer}>
        <div className={classes.header} >
            <Typography variant="h2">PFCA and PMS</Typography>
          </div>
          <PFCAGraph />
          </div>
    </div>
  );
};

export default AnalysisTab;

import {
  IActionsSetUnsavedClientChanges,
  IActionsSetUnsavedDocumentChanges,
} from "../actions/saving";

export const setUnsavedDocumentChanges = (
  openSaveDialog: boolean
): IActionsSetUnsavedDocumentChanges => {
  return {
    type: "SET_UNSAVED_DOCUMENT_CHANGES",
    openSaveDialog: openSaveDialog,
  };
};

export const setUnsavedClientChanges = (
  openClientDialog: boolean
): IActionsSetUnsavedClientChanges => ({
  type: "SET_UNSAVED_CLIENT_CHANGES",
  openClientDialog,
});

export enum BaseQuantityTypes {
  WEIGHT_G = 0,
  VOLUME_ML,
  PROPORTION_AS_PERCENTAGE,
  UNKNOWN,
}

export class BaseQuantity {
  baseQuantityType: BaseQuantityTypes;
  value: number;

  constructor(baseQuantityType: BaseQuantityTypes, value: number) {
    this.baseQuantityType = baseQuantityType;
    this.value = value;
  }

  multiplier = (factor: number) =>
    new BaseQuantity(this.baseQuantityType, this.value * factor);
}

export class WeightBaseQuantity extends BaseQuantity {
  constructor(value: number) {
    super(BaseQuantityTypes.WEIGHT_G, value);
  }
}

export class VolumeBaseQuantity extends BaseQuantity {
  constructor(value: number) {
    super(BaseQuantityTypes.VOLUME_ML, value);
  }
}

export class PercentageBaseQuantity extends BaseQuantity {
  constructor(value: number) {
    super(BaseQuantityTypes.PROPORTION_AS_PERCENTAGE, value);
  }
}

export class UnknownBaseQuantity extends BaseQuantity {
  constructor(value: number) {
    super(BaseQuantityTypes.UNKNOWN, value);
  }
}

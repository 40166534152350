import { FoodItemPosition } from "../../../data/models/foodItemPosition";
import {
  IActionsSetSelectedRows,
  IActionsToggleDisplayNutrientValues,
  IActionsToggleLockNutrientColumns,
  IActionsUpdateCurrentDay,
} from "../actions/recipeGrid";

export const setSelectedRows = (
  foodItemPositions: FoodItemPosition[]
): IActionsSetSelectedRows => {
  return {
    type: "SET_SELECTED_ROWS",
    foodItemPositions: foodItemPositions,
  };
};

export const updateCurrentDay = (day: number): IActionsUpdateCurrentDay => {
  return {
    type: "UPDATE_CURRENT_DAY",
    day: day,
  };
};

export const toggleDisplayNutrientValues = (): IActionsToggleDisplayNutrientValues => {
  return {
    type: "TOGGLE_DISPLAY_NUTRIENT_VALUES",
  };
};

export const toggleLockNutrientColumns = (): IActionsToggleLockNutrientColumns => {
  return {
    type: "TOGGLE_LOCK_NUTRIENT_COLUMNS",
  };
};

import { QuantityCellInput, QuantityOption } from "./QuantityCell";

export const isOption = (options: QuantityOption[], inputValue: string) =>
  options.find((option) => option.label === inputValue) !== undefined;

export const isCharacterALetter = (char: string) =>
  char.toLowerCase() !== char.toUpperCase();

export const isNumber = (input: string | undefined) =>
  input
    ? !isNaN(parseFloat(input)) &&
      isFinite(Number(input)) &&
      !input.includes("-")
    : false;

export const quantityFromCellValue = (
  options: QuantityOption[],
  cellValue: string
): QuantityCellInput | undefined => {
  if (!cellValue.trim()) {
    return undefined;
  } else if (isNumber(cellValue)) {
    return { value: cellValue.trim(), measure: "" };
  } else if (
    !isOption(options, cellValue) &&
    !isCharacterALetter(cellValue[0])
  ) {
    for (let i = 0; i < cellValue.length; i++) {
      const characterIsSpace = cellValue.charAt(i) === " ";
      const characterIsLetter = isCharacterALetter(cellValue.charAt(i));

      if (characterIsSpace || characterIsLetter) {
        const value = cellValue.slice(0, i);
        const measure = cellValue.slice(characterIsSpace ? i + 1 : i);

        return { value: value, measure: measure };
      }
    }
  }
};

export const quantityOptionFromLabel = (
  options: QuantityOption[],
  label: string
): QuantityOption | undefined =>
  options.find((option: QuantityOption) => option.label === label);

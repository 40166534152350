import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { DocumentStatus } from "../../../../data/models/userDatabase";

interface Props {
  setFilters: (filters: DocumentStatus[]) => void;
}

const ClientFilter = (props: Props) => {
  const filters: DocumentStatus[] = ["archived"];

  return (
    <Autocomplete
      multiple
      openOnFocus
      onChange={(_, newOption) => {
        props.setFilters([...newOption]);
      }}
      limitTags={1}
      options={filters}
      getOptionLabel={(option) => option.toString().toUpperCase()}
      getOptionSelected={(option, value) =>
        option.toString() === value.toString()
      }
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={true}
          label="Selected Quick Filters"
          data-cy="quickFilterInput"
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );
};

export default ClientFilter;

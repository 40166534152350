import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { batch, useDispatch } from "react-redux";

import { appTheme } from "../../../../../../../../styling/style";
import { Day } from "../../../../../../../../data/models/documentProperties/day";
import { setDays } from "../../../../../../../../store/data/current-document/action-creators/days";
import { DEFAULT_MEAL_PLAN_SECTIONS } from "../../../../../../../../constants/FoodTemplate";
import { DAY_IN_MILLISECONDS } from "../../../../../../../../data/models/documentProperties/date";
import { Sections } from "../../../../../../../../data/models/documentProperties/section";
import { updateCurrentDay } from "../../../../../../../../store/ui/actionCreators/recipeGrid";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  addButton: {
    color: appTheme.colors.success,
  },
}));

interface Props {
  days: Day[];
  currentDay: number;
}

const getNextDate = (day: Day): string => {
  const newDate = new Date(day.date);
  newDate.setTime(newDate.getTime() + DAY_IN_MILLISECONDS);
  return newDate.toISOString();
};

const DayConstructionButtons = (props: Props): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { days, currentDay } = { ...props };

  const onSetCurrentDays = (days: Day[]) => dispatch(setDays(days));

  const onSetCurrentDay = (index: number) => dispatch(updateCurrentDay(index));

  const onAddDay = () => {
    const oldDays = [...days];
    const earlierDays: Day[] = oldDays.splice(0, currentDay + 1);

    const newDay = new Day(
      "",
      currentDay + 1,
      new Sections(DEFAULT_MEAL_PLAN_SECTIONS, new Map([])),
      "New day",
      days[currentDay].date ? getNextDate(earlierDays[currentDay]) : ""
    );

    const updatedLaterDays = oldDays.map(
      (day: Day): Day =>
        new Day(
          day.id,
          day.index + 1,
          day.sections,
          day.title,
          day.date ? getNextDate(day) : ""
        )
    );

    batch(() => {
      onSetCurrentDays([...earlierDays, newDay, ...updatedLaterDays]);
      onSetCurrentDay(currentDay + 1);
    });
  };

  const onDeleteDay = () => {
    const oldDays = [...days];
    const laterDays: Day[] = oldDays.splice(currentDay + 1);
    const earlierDays: Day[] = oldDays.splice(0, oldDays.length - 1);

    const updatedLaterDays = laterDays.map(
      (day: Day): Day =>
        new Day(day.id, day.index - 1, day.sections, day.title, day.date)
    );

    batch(() => {
      onSetCurrentDays([...earlierDays, ...updatedLaterDays]);
      onSetCurrentDay(
        currentDay === days.length - 1 ? currentDay - 1 : currentDay
      );
    });
  };

  return (
    <div className={classes.root}>
      <Button
        onClick={onAddDay}
        startIcon={<Add className={classes.addButton} />}
      >
        Add day
      </Button>
      <Button
        disabled={days.length === 1}
        onClick={onDeleteDay}
        startIcon={
          <Remove
            style={{
              color: days.length === 1 ? "inherit" : appTheme.colors.error,
            }}
          />
        }
      >
        Delete day
      </Button>
    </div>
  );
};

export default DayConstructionButtons;

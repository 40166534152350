import { Client, ClientSummary } from "../../../data/models/client";
import { Tag } from "../../../data/models/documentProperties/section";
import { NutrientReferenceValue } from "../../../data/models/nutrientReferenceValues";
import {
  ADD_CLIENT_SUMMARY,
  ClientDatabaseActions,
  REMOVE_CLIENT_SUMMARY,
  SET_CLIENT_ID,
  SET_CLIENT_SUMMARIES,
  SET_CURRENT_CLIENT,
  SET_CLIENT_DATABASE_ID,
  SET_SERVER_CLIENT,
  UPDATE_CLIENT_SUMMARY,
  SET_CURRENT_CLIENT_NRV,
  SET_CLIENT_DATABASE_TAGS,
} from "../actions/clientDatabase";
import {
  ADD_CLIENT_GOAL,
  ADD_DOCUMENT_ID,
  REMOVE_CLIENT_GOAL,
  SET_CLIENT_ADDRESS,
  SET_CLIENT_EMAIL,
  SET_CLIENT_MOBILE,
  SET_CLIENT_NOTES,
  SET_CLIENT_TAGS,
  TOGGLE_CLIENT_STATE,
  UPDATE_CLIENT_GOAL,
  UPDATE_CLIENT_NAME,
} from "../current_client/actions/client";
import {
  ADD_CLIENT_MEASUREMENT,
  DELETE_CLIENT_MEASUREMENT,
  TOGGLE_CLIENT_MEASUREMENT,
  ADD_CLIENT_MEASUREMENT_ENTRY,
  UPDATE_CLIENT_MEASUREMENT_ENTRY,
  DELETE_CLIENT_MEASUREMENT_ENTRY,
  EDIT_CLIENT_MEASUREMENT,
} from "../current_client/actions/clientMeasurements";
import {
  SET_CLIENT_AGE,
  SET_CLIENT_SEX,
  SET_CLIENT_START_WEIGHT,
  SET_CLIENT_CURRENT_WEIGHT,
  SET_CLIENT_HEIGHT,
  SET_CLIENT_ACTIVITY_LEVEL,
  SET_CLIENT_PREGNANCY_LEVEL,
  SET_CLIENT_IS_LACTATING,
  SET_CLIENT_RESTING_METABOLIC_RATE,
  SET_CLIENT_DOB,
  SET_USE_CER,
} from "../current_client/actions/clientProfile";
import { client, initialClientState } from "../current_client/reducers/client";

export const TEMPORARY_CLIENT = "Temporary Client";

export interface ClientDatabaseState {
  clientId: string;
  currentClient: Client;
  serverClient: Client | undefined;
  databaseId: string;
  clientSummaries: ClientSummary[];
  currentClientNRV: NutrientReferenceValue[];
  tags: Tag[];
}

export const initialClientDatabaseState: ClientDatabaseState = {
  clientId: "",
  currentClient: initialClientState,
  serverClient: undefined,
  databaseId: "",
  clientSummaries: [],
  currentClientNRV: [],
  tags: [],
};

export const clientDatabase = (
  state: ClientDatabaseState = initialClientDatabaseState,
  action: ClientDatabaseActions
): ClientDatabaseState => {
  switch (action.type) {
    case SET_CLIENT_ID:
      return { ...state, clientId: action.clientId };
    case SET_CURRENT_CLIENT:
      return { ...state, currentClient: action.client };
    case SET_CLIENT_TAGS:
      return { ...state, currentClient: client(state.currentClient, action) };
    case SET_SERVER_CLIENT:
      return { ...state, serverClient: action.client };
    case SET_CLIENT_DATABASE_ID:
      return { ...state, databaseId: action.databaseId };
    case SET_CLIENT_SUMMARIES:
      return { ...state, clientSummaries: action.summaries };
    case ADD_CLIENT_SUMMARY:
      return {
        ...state,
        clientSummaries: state.clientSummaries
          .map((summary: ClientSummary): string => summary.clientId)
          .includes(action.summary.clientId)
          ? state.clientSummaries
          : [...state.clientSummaries, action.summary],
      };
    case REMOVE_CLIENT_SUMMARY:
      return {
        ...state,
        clientSummaries: state.clientSummaries.filter(
          (summary: ClientSummary) => summary.clientId !== action.clientId
        ),
      };
    case UPDATE_CLIENT_SUMMARY:
      return {
        ...state,
        clientSummaries: state.clientSummaries.map(
          (summary: ClientSummary): ClientSummary =>
            summary.clientId === action.summary.clientId
              ? action.summary
              : summary
        ),
      };
    case SET_CURRENT_CLIENT_NRV:
      return { ...state, currentClientNRV: action.values };
    case TOGGLE_CLIENT_STATE:
      return {
        ...state,
        currentClient: {
          ...state.currentClient,
          state:
            state.currentClient.state === "active"
              ? "archived"
              : state.currentClient.state === "archived"
              ? "active"
              : "deleted",
        },
      };
    case ADD_CLIENT_GOAL:
    case UPDATE_CLIENT_GOAL:
    case REMOVE_CLIENT_GOAL:
    case UPDATE_CLIENT_NAME:
    case SET_CLIENT_NOTES:
    case SET_CLIENT_MOBILE:
    case SET_CLIENT_EMAIL:
    case SET_CLIENT_ADDRESS:
    case ADD_DOCUMENT_ID:
    case SET_CLIENT_AGE:
    case SET_CLIENT_DOB:
    case SET_CLIENT_SEX:
    case SET_CLIENT_START_WEIGHT:
    case SET_CLIENT_CURRENT_WEIGHT:
    case SET_CLIENT_HEIGHT:
    case SET_CLIENT_ACTIVITY_LEVEL:
    case SET_CLIENT_PREGNANCY_LEVEL:
    case SET_CLIENT_IS_LACTATING:
    case SET_CLIENT_RESTING_METABOLIC_RATE:
    case SET_USE_CER:
    case ADD_CLIENT_MEASUREMENT:
    case EDIT_CLIENT_MEASUREMENT:
    case DELETE_CLIENT_MEASUREMENT:
    case TOGGLE_CLIENT_MEASUREMENT:
    case ADD_CLIENT_MEASUREMENT_ENTRY:
    case UPDATE_CLIENT_MEASUREMENT_ENTRY:
    case DELETE_CLIENT_MEASUREMENT_ENTRY:
      return { ...state, currentClient: client(state.currentClient, action) };
    case SET_CLIENT_DATABASE_TAGS:
      return { ...state, tags: action.tags };
    default:
      return state;
  }
};

import React, { ReactNode, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { BaseDialog } from "../../BaseDialog";
import { ClientSummary } from "../../../../data/models/client";
import {
  clientDatabaseTagsSelector,
  clientSummariesSelector,
} from "../../../../store/data/selectors/clientDatabase";
import { RootState } from "../../../../store/reducers";
import { FoodWorksTextInput } from "../../../common/FoodWorksTextInput";
import { appTheme } from "../../../../styling/style";
import { CreateClientTagDialog } from "./CreateClientTagDialog";
import { CreateClientTagButton } from "./CreateClientTagButton";
import { Tag } from "../../../../data/models/documentProperties/section";
import { TagListItem } from "../../section_tags/components/TagListItem";
import {
  updateClientDatabaseTags,
  updateClientSummaries,
} from "../../../../store/data/thunks/clientDatabase";
import { updateClientTagId } from "../../../../store/data/current_client/thunks/client";
import { useLoadClients } from "./hooks/ClientTag";
import { TAG_FIELD } from "../../../../constants/textInputs";

const useStyles = makeStyles((theme) => ({
  componentRootContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  component: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0px",
    height: 300,
  },
  addIcon: {
    color: appTheme.colors.primary,
  },
  listContainer: {
    overflow: "auto",
    flex: "1",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  selectButtonsContainer: {
    display: "flex",
  },
  listButton: {
    borderRadius: 4,
    marginTop: 2,
    marginBottom: 2,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
  },
  selectedListButton: {
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
  },
}));

const getAddedTagsToClientSummary = (
  summary: ClientSummary,
  tagId: string
): ClientSummary => {
  const updatedTagIds: string[] = [...new Set([...summary.tagIds, tagId])];
  return { ...summary, tagIds: updatedTagIds };
};

const getRemovedTagsToClientSummary = (
  summary: ClientSummary,
  tagId: string
): ClientSummary => {
  const updatedTagIds: string[] = summary.tagIds.filter((id) => id !== tagId);
  return { ...summary, tagIds: updatedTagIds };
};

const sameSelectedClients = (ids1: string[], ids2: string[]): boolean => {
  if (ids1.length !== ids2.length) return false;
  const client1 = [...ids1].sort();
  const client2 = [...ids2].sort();
  return client1.every((val, idx) => val === client2[idx]);
};

interface ClientTagDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ClientTagDialog = ({
  open,
  onClose,
}: ClientTagDialogProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const clientSummaries = useSelector<RootState, ClientSummary[]>(
    clientSummariesSelector
  );

  const onDeleteTag = (deletedTagId: string) => {
    const updatedTags: Tag[] = clientTags.filter(
      (tag: Tag) => tag.id !== deletedTagId
    );

    dispatch(updateClientDatabaseTags(updatedTags));

    const newClientSummaries: ClientSummary[] = clientSummaries.map(
      (summary: ClientSummary) =>
        getRemovedTagsToClientSummary(summary, selectedTag)
    );

    batchUpdateSummaries(newClientSummaries);
  };

  const batchUpdateSummaries = (clientSummary: ClientSummary[]) => {
    batch(() => {
      // Update client
      clientSummary.forEach((summary: ClientSummary) =>
        dispatch(updateClientTagId(summary.clientId, summary.tagIds))
      );

      // Update client summaries
      dispatch(updateClientSummaries(clientSummary));
    });
  };

  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<string>("");
  const [selectedEditTag, setSelectedEditTag] = useState<string>("");
  const [showEditTagDialog, setShowEditTagDialog] = useState<boolean>(false);
  const [selectedClients, setSelectedClients] = useLoadClients(selectedTag);
  const [initialClients] = useLoadClients(selectedTag);
  const [isClientSame, setIsClientSame] = useState<boolean>();

  const clientTags = useSelector<RootState, Tag[]>(clientDatabaseTagsSelector);

  const onSelectTag = (tagId: string) => {
    selectedTag === tagId ? setSelectedTag("") : setSelectedTag(tagId);
  };

  useEffect(() => {
    setIsClientSame(sameSelectedClients(selectedClients, initialClients));
  }, [selectedClients, initialClients]);

  const onClickEditTag = (tagId: string) => {
    if (selectedEditTag !== tagId) {
      setSelectedEditTag(tagId);
    }
    setShowEditTagDialog(true);
  };

  const onClickDeleteTag = (tagId: string) => {
    onDeleteTag(tagId);
  };

  const applySelectAll = () => {
    const clientIds: string[] = clientSummaries.map(
      (summary: ClientSummary) => summary.clientId
    );
    setSelectedClients(clientIds);
  };

  const applyDeselectAll = () => {
    setSelectedClients([]);
  };

  const applyChanges = () => {
    if (selectedTag) {
      const newClientSummaries: ClientSummary[] = clientSummaries.map(
        (summary: ClientSummary) =>
          selectedClients.includes(summary.clientId)
            ? getAddedTagsToClientSummary(summary, selectedTag)
            : getRemovedTagsToClientSummary(summary, selectedTag)
      );

      batchUpdateSummaries(newClientSummaries);
    }
  };

  const saveChanges = () => {
    applyChanges();
    onClose();
  };

  const handleClientClick = (clientId: string) => {
    if (selectedClients.includes(clientId)) {
      const removedClient: string[] = selectedClients.filter(
        (id: string) => clientId !== id
      );
      setSelectedClients(removedClient);
    } else {
      const updatedClient: string[] = [...selectedClients, clientId];
      setSelectedClients(updatedClient);
    }
  };

  const clientListButtons: ReactNode = [
    clientSummaries.map(
      (summary: ClientSummary): ReactNode =>
        (!searchInput ||
          summary.label.toLowerCase().includes(searchInput.toLowerCase())) && (
          <MenuItem
            key={summary.clientId}
            button
            onClick={() => handleClientClick(summary.clientId)}
            disabled={!selectedTag}
            className={
              selectedClients.includes(summary.clientId)
                ? `${classes.listButton} ${classes.selectedListButton}`
                : classes.listButton
            }
            data-cy={
              selectedClients.includes(summary.clientId)
                ? "clientSelected"
                : "clientUnselected"
            }
          >
            {summary.label}
          </MenuItem>
        )
    ),
  ];

  const clientTagButtons: ReactNode = clientTags.map(
    (tag: Tag): ReactNode => (
      <TagListItem
        sectionTag={tag}
        isSelected={tag.id === selectedTag}
        onSelectTag={onSelectTag}
        onDeleteTag={onClickDeleteTag}
        onEditTag={onClickEditTag}
      />
    )
  );

  const createTagComponent: ReactNode = [
    <div key={"ClientTagDialogTitle"} className={classes.headerContainer}>
      <Typography variant="h3">Available client tags</Typography>
    </div>,
    <div key={"ClientTagDialogListComponent"} className={classes.component}>
      <Card className={classes.listContainer}>
        <List data-cy="clientTagList">
          <ListItem>
            <CreateClientTagButton />
          </ListItem>

          {clientTagButtons}
        </List>
      </Card>
    </div>,
    <CreateClientTagDialog
      key={"ClientTagDialogCreator"}
      open={showEditTagDialog}
      onClose={() => setShowEditTagDialog(false)}
      isEditing={true}
      tagId={selectedEditTag}
    />,
  ];

  const addTagToClientComponent: ReactNode = (
    <div data-cy="clientComponent">
      <Typography variant="body1">Add tag to clients</Typography>
      <FoodWorksTextInput
        maxLength={TAG_FIELD}
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value)}
        placeholder={"Search client names..."}
      />
      <div className={classes.selectButtonsContainer}>
        <Button
          onClick={applySelectAll}
          disabled={
            !selectedTag || clientSummaries.length === selectedClients.length
          }
          data-cy="selectAllClientTags"
        >
          Select all
        </Button>
        <Button
          onClick={applyDeselectAll}
          disabled={!selectedTag || selectedClients.length === 0}
          data-cy="deselectAllClientTags"
        >
          Deselect all
        </Button>
      </div>
      <div className={classes.component}>
        <Card className={classes.listContainer}>
          <List data-cy="clientListButtons">{clientListButtons}</List>
        </Card>
      </div>
    </div>
  );

  const body: ReactNode = (
    <div className={classes.componentRootContainer}>
      {createTagComponent}
      {addTagToClientComponent}
    </div>
  );

  const action: ReactNode = [
    <Button
      key={"ClientTagDialogCloseButton"}
      data-cy="clientTagCancel"
      onClick={onClose}
    >
      Close
    </Button>,
    <Button
      key={"ClientTagDialogApplyButton"}
      color="secondary"
      disabled={!selectedTag || isClientSame}
      data-cy="clientTagApply"
      onClick={applyChanges}
    >
      Apply
    </Button>,
    <Button
      key={"ClientTagDialogOKButton"}
      color="secondary"
      onClick={saveChanges}
    >
      OK
    </Button>,
  ];

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Manage client tags"
      body={body}
      action={action}
      maxWidth="sm"
      dataCy="clientTagDialog"
    />
  );
};

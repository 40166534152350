import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setClientAddress } from "../../../../../../../store/data/current_client/action_creators/client";
import { clientAddressSelector } from "../../../../../../../store/data/current_client/selectors/client";
import { RootState } from "../../../../../../../store/reducers";

const useClientAddress = (): [string, (input: string) => void, () => void] => {
  const dispatch = useDispatch();

  const address: string = useSelector<RootState, string>(clientAddressSelector);

  const [input, setInput] = useState(address);

  const onSetClientAddress = useCallback(
    () => dispatch(setClientAddress(input)),
    [dispatch, input]
  );

  useEffect(() => {
    setInput(address);
  }, [address]);

  return [input, setInput, onSetClientAddress];
};

export default useClientAddress;

import Firebase from "../firebase";
import { DataSource, DataSourceSummaryItem } from "../../models/datasource";
import { IngredientSummaryItem } from "../../../components/screens/databases/documents/tabs/ingredients/editing_grid/rows/cells/IngredientCell";
import { Database, DocumentSummary } from "../../models/userDatabase";
import { FoodId } from "../../models/documentProperties/foodId";
import { FOODS } from "../../../constants/FoodTemplate";

export type SummaryItemCallback = (
  summaryItems: IngredientSummaryItem[]
) => void;

export const fetchPublicIngredientSummaries = async (
  firebase: Firebase,
  databaseIds: string[]
): Promise<IngredientSummaryItem[]> =>
  fetchIngredientSummaries(true, firebase, databaseIds);

const fetchIngredientSummaries = async (
  isPublic: boolean,
  firebase: Firebase,
  databaseIds: string[]
) => {
  let summaryItems: IngredientSummaryItem[] = [];

  for (const databaseId of databaseIds) {
    const items = await fetchDatabaseSummaries(isPublic, databaseId, firebase);
    summaryItems = summaryItems.concat(items);
  }

  return summaryItems;
};

const fetchDatabaseSummaries = async (
  isPublic: boolean,
  databaseId: string,
  firebase: Firebase
): Promise<IngredientSummaryItem[]> => {
  const dataDocument: DataSource | Database | undefined = isPublic
    ? await firebase?.publicDatabases.doGetDataSource(databaseId)
    : await firebase?.userDatabases.doGetUserDatabase(databaseId);

  if (!dataDocument) {
    // Todo error handling with UI
    return [];
  }

  const newItems: IngredientSummaryItem[] = (dataDocument.documentSummaries as Array<
    DocumentSummary | DataSourceSummaryItem
  >).map(
    (
      summary: DataSourceSummaryItem | DocumentSummary
    ): IngredientSummaryItem => {
      return {
        foodId: new FoodId({
          datasourceId: databaseId,
          documentId: summary.documentId,
        }),
        label: summary.label,
        isPublic: isPublic,
        state: "status" in summary ? summary.status : "active",
        templateId:
          "templateId" in summary ? summary.templateId : FOODS.id.toString(),
      };
    }
  );
  return newItems;
};

import { IActionsAddAlert, IActionsResetAlert } from "../actions/alert";

export const addAlert = (message: string): IActionsAddAlert => {
  return {
    type: "ADD_ALERT",
    message: message,
  };
};

export const resetAlert = (): IActionsResetAlert => {
  return {
    type: "RESET_ALERT",
  };
};

import { Action } from "redux";

import { CommonMeasure } from "../../../../data/models/documentProperties/measure";

export const ADD_COMMON_MEASURE = "ADD_COMMON_MEASURE";
export const SET_DEFAULT_COMMON_MEASURE = "SET_DEFAULT_COMMON_MEASURE";
export const UPDATE_COMMON_MEASURE = "UPDATE_COMMON_MEASURE";
export const REMOVE_COMMON_MEASURE = "REMOVE_COMMON_MEASURE";

export interface IActionsAddCommonMeasure extends Action {
  type: "ADD_COMMON_MEASURE";
  measure: CommonMeasure;
}

export interface IActionsSetDefaultCommonMeasure extends Action {
  type: "SET_DEFAULT_COMMON_MEASURE";
  measureId: string;
}

export interface IActionsUpdateCommonMeasure extends Action {
  type: "UPDATE_COMMON_MEASURE";
  updatedMeasure: CommonMeasure;
}

export interface IActionsRemoveCommonMeasure extends Action {
  type: "REMOVE_COMMON_MEASURE";
  measure: string;
}

export type CommonMeasuresActions =
  | IActionsAddCommonMeasure
  | IActionsSetDefaultCommonMeasure
  | IActionsUpdateCommonMeasure
  | IActionsRemoveCommonMeasure;

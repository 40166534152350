import {
  IActionsUpdateNutrientOverrideValue,
  IActionsUpdateNutrientOverrideNote,
  IActionsAddNutrientOverride,
  IActionsRemoveNutrientOverride,
} from "../actions/nutrientOverrides";

export const updateNutrientOverrideValue = (
  id: string,
  value: number
): IActionsUpdateNutrientOverrideValue => {
  return {
    type: "UPDATE_NUTRIENT_OVERRIDE_VALUE",
    id: id,
    value: value,
  };
};

export const updateNutrientOverrideNote = (
  id: string,
  note: string
): IActionsUpdateNutrientOverrideNote => {
  return {
    type: "UPDATE_NUTRIENT_OVERRIDE_NOTE",
    id: id,
    note: note,
  };
};

export const addNutrientOverride = (
  id: string,
  value: number
): IActionsAddNutrientOverride => {
  return {
    type: "ADD_NUTRIENT_OVERRIDE",
    id: id,
    value: value,
  };
};

export const removeNutrientOverride = (
  id: string
): IActionsRemoveNutrientOverride => {
  return {
    type: "REMOVE_NUTRIENT_OVERRIDE",
    id: id,
  };
};

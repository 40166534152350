import React, { ReactNode, useEffect, useMemo, useState } from "react";

import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { RECIPES } from "../../../../../../../../constants/FoodTemplate";
import { Section } from "../../../../../../../../data/models/documentProperties/section";
import { templateIdSelector } from "../../../../../../../../store/data/current-document/selectors/document";
import { sectionSelector } from "../../../../../../../../store/data/current-document/selectors/sections";
import { RootState } from "../../../../../../../../store/reducers";
import { appTheme } from "../../../../../../../../styling/style";
import { SectionInsert } from "./SectionInsert";
import { SectionHeader } from "./SectionHeader";
import { IngredientList } from "./IngredientList";
import { FoodItemPosition } from "../../../../../../../../data/models/foodItemPosition";
import {
  displayNutrientValuesSelector,
  lockNutrientColumnsSelector,
  selectedRowsSelector,
} from "../../../../../../../../store/ui/selectors/recipeGrid";
import { IngredientSummaryItem } from "../rows/cells/IngredientCell";
import { displayedNutrientsSelector } from "../../../../../../../../store/data/selectors/databaseProperties";
import { Nutrient } from "../../../../../../../../data/models/nutrient";
import { NutrientsDataSelector } from "../../../../../../../../store/data/selectors/referenceData";
import { FoodworksTooltip } from "../../../../../../../common/InfoTooltip";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: appTheme.colors.white[10],
      flex: 1,
      marginTop: 7,
      paddingLeft: 5,
      paddingRight: 5,
      borderRadius: 4,
    },
    sectionHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      backgroundColor: appTheme.colors.white[10],
      borderRadius: 4,
      height: 40,
      paddingRight: 10,
      paddingLeft: 10,
    },
    nutrientColumn: {
      width: 70,
      paddingLeft: 1,
      paddingRight: 1,
    },
    columnContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    sectionlessRoot: {
      flex: 1,
      marginTop: 7,
      paddingLeft: 5,
      paddingRight: 5,
      display: "flex",
      flexDirection: "column",
    },
  })
);

export interface RecipeSectionProps {
  dayIndex: number;
  sectionIndex: number;
  focusedCell: string;
  setFocusedCell: (cell: string) => void;
  isLastSection: boolean;
  summaries: IngredientSummaryItem[];
  searchTermMap: Map<string, string[]>;
}

const arePropsEqual = (
  previous: RecipeSectionProps,
  current: RecipeSectionProps
): boolean =>
  previous.dayIndex === current.dayIndex &&
  previous.sectionIndex === current.sectionIndex &&
  previous.focusedCell === current.focusedCell &&
  previous.isLastSection === current.isLastSection;

const RecipeSectionInner = ({
  dayIndex,
  sectionIndex,
  focusedCell,
  setFocusedCell,
  isLastSection,
  summaries,
  searchTermMap,
}: RecipeSectionProps) => {
  const classes = useStyles();

  const selectSection = useMemo(sectionSelector, []);

  const section: Section = useSelector<RootState, Section>((state) =>
    selectSection(state, dayIndex, sectionIndex)
  );

  const displayNutrientValues: boolean = useSelector<RootState, boolean>(
    displayNutrientValuesSelector
  );

  const lockNutrientColumns: boolean = useSelector<RootState, boolean>(
    lockNutrientColumnsSelector
  );

  const displayedNutrients: string[] = useSelector<RootState, string[]>(
    displayedNutrientsSelector
  );

  const selectedRows: FoodItemPosition[] = useSelector<
    RootState,
    FoodItemPosition[]
  >(selectedRowsSelector);

  const nutrientData: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(NutrientsDataSelector);

  const documentTemplate = useSelector<RootState, string>(templateIdSelector);

  const [open, setOpen] = useState(section.isCollapsed);

  useEffect(() => {
    setOpen(!section.isCollapsed);
  }, [section]);

  const isOnlySectionOfRecipe =
    isLastSection &&
    sectionIndex === 0 &&
    documentTemplate === RECIPES.id.toString();

  const columnHeader: ReactNode = (
    <div className={classes.columnContainer}>
      <div
        className={classes.sectionHeader}
        style={{ paddingRight: lockNutrientColumns ? 40 : 2 }}
      >
        {displayedNutrients.map(
          (nutrientId: string): ReactNode => (
            <FoodworksTooltip
              key={`${nutrientId}-tooltip`}
              title={
                `${nutrientData.get(nutrientId)?.name} (${
                  nutrientData.get(nutrientId)?.units
                })` || ""
              }
            >
              <Typography
                key={`${nutrientId}-typography`}
                align="center"
                className={classes.nutrientColumn}
                noWrap
                variant="h4"
              >
                {nutrientData.get(nutrientId)?.name || ""}
              </Typography>
            </FoodworksTooltip>
          )
        )}
      </div>
    </div>
  );

  const ingredientList = (lastSection: boolean) => (
    <IngredientList
      open={open}
      dayIndex={dayIndex}
      sectionIndex={section.index}
      foodItems={section.foodItems.items}
      focusedCell={focusedCell}
      setFocusedCell={setFocusedCell}
      summaries={summaries}
      searchTermMap={searchTermMap}
      isLastSection={lastSection}
    />
  );

  const paddingBottom = open ? 10 : 0;

  return (
    <>
      {isOnlySectionOfRecipe ? (
        <div className={classes.sectionlessRoot}>
          {displayNutrientValues && columnHeader}
          {ingredientList(true)}
        </div>
      ) : (
        <div className={classes.root} style={{ paddingBottom: paddingBottom }}>
          <SectionHeader
            dayIndex={dayIndex}
            section={section}
            sectionIsCollapsed={!open}
            selectedRows={selectedRows}
          />
          {ingredientList(false)}
        </div>
      )}
      {!isLastSection && (
        <SectionInsert
          dayIndex={dayIndex}
          sectionIndex={sectionIndex}
          selectedRows={selectedRows}
        />
      )}
    </>
  );
};

export const RecipeSection = React.memo(RecipeSectionInner, arePropsEqual);

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/core";

import { updateDocumentMethod } from "../../../../../../store/data/current-document/action-creators/document";
import { MethodSectionHeader } from "./MethodSectionHeader";
import { appTheme } from "../../../../../../styling/style";
import { FoodWorksTextInput } from "../../../../../common/FoodWorksTextInput";
import { MethodContents } from "../../../../../../data/models/document";
import { METHOD_FIELD } from "../../../../../../constants/textInputs";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: appTheme.colors.white[10],
      flex: 1,
      padding: 5,
      marginBottom: 10,
      "& > *": {
        padding: 0,
      },
      borderRadius: 4,
    },
    sectionlessRoot: {
      flex: 1,
      marginTop: 7,
      paddingLeft: 5,
      paddingRight: 5,
      display: "flex",
      flexDirection: "column",
    },
    textArea: {
      borderStyle: "none",
      borderRadius: 4,
      background: appTheme.colors.white[0],
      width: "100%",
      margin: 0,
    },
  })
);

export interface MethodSectionProps {
  isOnlySection: boolean;
  sectionContents: MethodContents;
}

const MethodSectionInner = ({
  isOnlySection,
  sectionContents,
}: MethodSectionProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [methodText, setMethodText] = useState<string>(sectionContents.data);

  //forces re-render of screen
  useEffect(() => {
    setMethodText(sectionContents.data);
  }, [sectionContents]);

  const handleMethodTextUpdate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setMethodText(event.currentTarget.value);
  };

  const onBlur = () => {
    const newInput = {
      title: sectionContents.title,
      index: sectionContents.index,
      data: methodText,
      isCollapsed: false,
    };
    dispatch(updateDocumentMethod(newInput));
  };

  return (
    <>
      {isOnlySection ? (
        <div className={classes.sectionlessRoot}>
          <FoodWorksTextInput
            maxLength={METHOD_FIELD}
            value={methodText}
            multiline
            rows={20}
            onChange={handleMethodTextUpdate}
            onBlur={onBlur}
            className={classes.textArea}
            placeholder={
              isOnlySection
                ? "Create a method"
                : "Specify a method for this section."
            }
          />
        </div>
      ) : (
        <div className={classes.root}>
          <MethodSectionHeader section={sectionContents} />
          {!sectionContents.isCollapsed && (
            <FoodWorksTextInput
              maxLength={METHOD_FIELD}
              value={methodText}
              multiline
              rows={20}
              onChange={handleMethodTextUpdate}
              onBlur={onBlur}
              className={classes.textArea}
              placeholder={
                isOnlySection
                  ? "Create a method"
                  : "Specify a method for this section."
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export const MethodSection = React.memo(MethodSectionInner);

import { Action } from "redux";

import {
  ActivityLevel,
  PregnancyLevel,
  Sex,
} from "../../../../data/models/nutrientReferenceValues";

export const SET_CLIENT_AGE = "SET_CLIENT_AGE";
export const SET_CLIENT_DOB = "SET_CLIENT_DOB";
export const SET_CLIENT_SEX = "SET_CLIENT_SEX";
export const SET_CLIENT_START_WEIGHT = "SET_CLIENT_START_WEIGHT";
export const SET_CLIENT_CURRENT_WEIGHT = "SET_CLIENT_CURRENT_WEIGHT";
export const SET_CLIENT_HEIGHT = "SET_CLIENT_HEIGHT";
export const SET_CLIENT_ACTIVITY_LEVEL = "SET_CLIENT_ACTIVITY_LEVEL";
export const SET_CLIENT_PREGNANCY_LEVEL = "SET_CLIENT_PREGNANCY_LEVEL";
export const SET_CLIENT_IS_LACTATING = "SET_CLIENT_IS_LACTATING";
export const SET_CLIENT_RESTING_METABOLIC_RATE =
  "SET_CLIENT_RESTING_METABOLIC_RATE";
export const SET_USE_CER = "SET_USE_CER";

export interface IActionsSetClientAge extends Action {
  type: "SET_CLIENT_AGE";
  age: number | undefined;
}

export interface IActionsSetClientDOB extends Action {
  type: "SET_CLIENT_DOB";
  dob: string;
}

export interface IActionsSetClientSex extends Action {
  type: "SET_CLIENT_SEX";
  sex: Sex;
}

export interface IActionsSetClientStartWeight extends Action {
  type: "SET_CLIENT_START_WEIGHT";
  weight: number | undefined;
}

export interface IActionsSetClientCurrentWeight extends Action {
  type: "SET_CLIENT_CURRENT_WEIGHT";
  weight: number | undefined;
}

export interface IActionsSetClientHeight extends Action {
  type: "SET_CLIENT_HEIGHT";
  height: number | undefined;
}

export interface IActionsSetClientActivityLevel extends Action {
  type: "SET_CLIENT_ACTIVITY_LEVEL";
  activityLevel: ActivityLevel;
}

export interface IActionsSetClientPregnancyLevel extends Action {
  type: "SET_CLIENT_PREGNANCY_LEVEL";
  pregnancyLevel: PregnancyLevel;
}

export interface IActionsSetClientIsLactating extends Action {
  type: "SET_CLIENT_IS_LACTATING";
  isLactating: boolean;
}

export interface IActionsSetClientRestingMetabolicRate extends Action {
  type: "SET_CLIENT_RESTING_METABOLIC_RATE";
  rate: number | undefined;
}

export interface IActionsSetUseCER extends Action {
  type: "SET_USE_CER";
  useCER: boolean;
}

export type ClientProfileActions =
  | IActionsSetClientAge
  | IActionsSetClientDOB
  | IActionsSetClientSex
  | IActionsSetClientStartWeight
  | IActionsSetClientCurrentWeight
  | IActionsSetClientActivityLevel
  | IActionsSetClientHeight
  | IActionsSetClientPregnancyLevel
  | IActionsSetClientIsLactating
  | IActionsSetClientRestingMetabolicRate
  | IActionsSetUseCER;

import React from "react";

import { MEDIUM_FIELD } from "../../../../../../../constants/textInputs";
import { FoodWorksTextInput } from "../../../../../../common/FoodWorksTextInput";
import ProfileItem from "../../profile/components/ProfileItem";
import useClientAddress from "../hooks/useClientAddress";

const AddressInput = React.memo(
  (): JSX.Element => {
    const [address, setAddress, updateAddress] = useClientAddress();

    return (
      <ProfileItem
        component={
          <FoodWorksTextInput
            maxLength={MEDIUM_FIELD}
            value={address}
            onChange={(event) => setAddress(event.currentTarget.value)}
            onBlur={updateAddress}
          />
        }
        label="Address"
      />
    );
  }
);

export default AddressInput;

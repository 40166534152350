import { createSelector } from "reselect";

import { RootState } from "../../reducers";
import { UserDatabaseSummary } from "../../../data/models/userDatabase";
import { clientUserDatabaseSelector } from "./clientDatabase";

export const userDatabaseSummariesSelector = (
  state: RootState
): UserDatabaseSummary[] => state.user.databaseSummaries;

export const sortedUserDatabaseSummariesSelector = createSelector(
  userDatabaseSummariesSelector,
  clientUserDatabaseSelector,
  (databaseSummaries, clientDatabaseId): UserDatabaseSummary[] =>
    databaseSummaries
      .filter((summary: UserDatabaseSummary) => summary.id !== clientDatabaseId)
      .sort((a, b) => -a.date.lastModified.localeCompare(b.date.lastModified))
);

import React from "react";
import { makeStyles } from "@material-ui/core";

import { PregnancyLevel } from "../../../../../../../data/models/nutrientReferenceValues";
import useClientPregnancyLevel from "../hooks/useClientPregnancyLevel";
import FoodworksSelect from "../../../../../../common/FoodworksSelect";
import ProfileItem from "./ProfileItem";
import { appTheme } from "../../../../../../../styling/style";

const useStyles = makeStyles(() => ({
  readOnly: {
    backgroundColor: appTheme.colors.gainsbruh,
  },
}));

const PregnancyLevelInput = React.memo((): JSX.Element => {
  const classes = useStyles();

  const [pregnancyLevel, updatePregnancyLevel, pregnancyIsPossible]: [
    PregnancyLevel,
    (input: PregnancyLevel) => void,
    boolean
  ] = useClientPregnancyLevel();

  return (
    <ProfileItem
      component={
        <FoodworksSelect<PregnancyLevel>
          className={!pregnancyIsPossible ? classes.readOnly : ""}
          value={pregnancyIsPossible ? pregnancyLevel : "None"}
          onChange={updatePregnancyLevel}
          options={["None", "Trimester1", "Trimester2", "Trimester3"]}
          readOnly={!pregnancyIsPossible}
        />
      }
      label="Pregnancy stage"
      isEmptyInfoTooltip={
        pregnancyIsPossible ? "" : "Cannot be pregnant if lactating or male"
      }
      disabled={!pregnancyIsPossible}
    />
  );
});

export default PregnancyLevelInput;

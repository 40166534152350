import { useCallback } from "react";
import _ from "lodash";
import { batch, useDispatch } from "react-redux";

import {
  FoodFilter,
  initialQuickFilterState,
} from "../../../../constants/QuickFilter";
import { Tag } from "../../../../data/models/documentProperties/section";
import {
  setNavQuickFilters,
  toggleQuickFilters,
} from "../../../../store/ui/actionCreators/navigationActionCreators";

const splitFilterTypes = (
  filters: (Tag | FoodFilter)[]
): [FoodFilter[], Tag[]] =>
  _.partition(
    filters,
    (filter: Tag | FoodFilter) => filter instanceof FoodFilter
  ) as [FoodFilter[], Tag[]];

const useUpdateDocumentFilters = () => {
  const dispatch = useDispatch();

  const setFilters = useCallback(
    (foodFilters: FoodFilter[], tagFilters: Tag[]) => {
      dispatch(
        setNavQuickFilters({
          documentTags: tagFilters,
          foodFilter: foodFilters,
          clientTags: tagFilters,
        })
      );
    },
    [dispatch]
  );

  const resetQuickFilters = useCallback(
    () =>
      batch(() => {
        dispatch(setNavQuickFilters(initialQuickFilterState));
        dispatch(toggleQuickFilters());
      }),
    [dispatch]
  );

  const onChangeFilter = useCallback(
    (filters: (Tag | FoodFilter)[]) => {
      !filters.length
        ? resetQuickFilters()
        : setFilters(...splitFilterTypes(filters));
    },
    [resetQuickFilters, setFilters]
  );

  return onChangeFilter;
};

export default useUpdateDocumentFilters;

import { Action } from "redux";

import { ClientProfileActions } from "./clientProfile";
import { ClientGoal } from "../../../../data/models/clientProperties/clientGoal";
import { ClientMeasurementsActions } from "./clientMeasurements";

export const UPDATE_CLIENT_NAME = "UPDATE_CLIENT_NAME";
export const SET_CLIENT_MOBILE = "SET_CLIENT_MOBILE";
export const SET_CLIENT_ADDRESS = "SET_CLIENT_ADDRESS";
export const SET_CLIENT_EMAIL = "SET_CLIENT_EMAIL";
export const SET_CLIENT_NOTES = "SET_CLIENT_NOTES";
export const ADD_DOCUMENT_ID = "ADD_DOCUMENT_ID";
export const ADD_CLIENT_GOAL = "ADD_CLIENT_GOAL";
export const REMOVE_CLIENT_GOAL = "REMOVE_CLIENT_GOAL";
export const UPDATE_CLIENT_GOAL = "UPDATE_CLIENT_GOAL";
export const SET_CLIENT_TAGS = "SET_CLIENT_TAGS";
export const TOGGLE_CLIENT_STATE = "TOGGLE_CLIENT_STATE";

export interface IActionsUpdateClientName extends Action {
  type: "UPDATE_CLIENT_NAME";
  name: string;
}

export interface IActionsSetClientMobile extends Action {
  type: "SET_CLIENT_MOBILE";
  mobile: string;
}

export interface IActionsSetClientAddress extends Action {
  type: "SET_CLIENT_ADDRESS";
  address: string;
}

export interface IActionsSetClientEmail extends Action {
  type: "SET_CLIENT_EMAIL";
  email: string;
}

export interface IActionsSetClientNotes extends Action {
  type: "SET_CLIENT_NOTES";
  notes: string;
}

export interface IActionsAddDocumentId extends Action {
  type: "ADD_DOCUMENT_ID";
  id: string;
}

export interface IActionsSetClientTags extends Action {
  type: "SET_CLIENT_TAGS";
  tagIds: string[];
}

export interface IActionsAddClientGoal extends Action {
  type: "ADD_CLIENT_GOAL";
  goal: ClientGoal;
}

export interface IActionsRemoveClientGoal extends Action {
  type: "REMOVE_CLIENT_GOAL";
  goal: ClientGoal;
}

export interface IActionsUpdateClientGoal extends Action {
  type: "UPDATE_CLIENT_GOAL";
  goal: ClientGoal;
}

export interface IActionsToggleClientState extends Action {
  type: "TOGGLE_CLIENT_STATE";
}

export type ClientActions =
  | IActionsUpdateClientName
  | IActionsAddDocumentId
  | IActionsSetClientMobile
  | IActionsSetClientEmail
  | IActionsSetClientAddress
  | IActionsSetClientNotes
  | IActionsSetClientTags
  | IActionsAddClientGoal
  | IActionsRemoveClientGoal
  | IActionsUpdateClientGoal
  | ClientProfileActions
  | ClientMeasurementsActions
  | IActionsToggleClientState;

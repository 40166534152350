import { ADD_ALERT, AlertActions, RESET_ALERT } from "../actions/alert";

export interface AlertState {
  message: string;
}

export const initialAlertState: AlertState = { message: "" };

export const alert = (
  state: AlertState = initialAlertState,
  action: AlertActions
): AlertState => {
  switch (action.type) {
    case ADD_ALERT:
      return { ...state, message: action.message };
    case RESET_ALERT:
      return { ...state, message: "" };
    default:
      return state;
  }
};

// Temporary Food Template class for basic functionality before knowing template API
import { v4 as uuidv4 } from "uuid";

import { ReactComponent as FoodIcon } from "../images/icons/Food.svg";
import { ReactComponent as RecipeIcon } from "../images/icons/Recipe.svg";
import { ReactComponent as FoodPlusIcon } from "../images/icons/Food_Plus.svg";
import { ReactComponent as RecipePlusIcon } from "../images/icons/Recipe_Plus.svg";
import { ReactComponent as FoodRecordIcon } from "../images/icons/FoodRecord.svg";
import { ReactComponent as FoodRecordPlusIcon } from "../images/icons/FoodRecord_Plus.svg";
import { ReactComponent as MealPlanIcon } from "../images/icons/MealPlan.svg";
import { ReactComponent as MealPlanPlusIcon } from "../images/icons/MealPlan_Plus.svg";
import { SectionState } from "../data/models/documentProperties/section";
import { DayState } from "../data/models/documentProperties/day";

export interface FoodTemplate {
  id: number;
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  createIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const FOODS = {
  id: 0,
  title: "Foods",
  icon: FoodIcon,
  createIcon: FoodPlusIcon,
};
export const RECIPES = {
  id: 1,
  title: "Recipes",
  icon: RecipeIcon,
  createIcon: RecipePlusIcon,
};
export const FOOD_RECORDS = {
  id: 2,
  title: "Food records",
  icon: FoodRecordIcon,
  createIcon: FoodRecordPlusIcon,
};
export const MEAL_PLANS = {
  id: 3,
  title: "Meal plans",
  icon: MealPlanIcon,
  createIcon: MealPlanPlusIcon,
};
export const TWENTY_FOUR_HOUR_RECALL = {
  id: 4,
  title: "24 hour recalls",
  icon: FoodRecordIcon,
  createIcon: FoodRecordPlusIcon,
};

export const isRecipe = (templateId: string): boolean =>
  templateId === RECIPES.id.toString();
export const isFood = (templateId: string): boolean =>
  templateId === FOODS.id.toString();
export const is24HourRecall = (templateId: string): boolean =>
  templateId === TWENTY_FOUR_HOUR_RECALL.id.toString();
export const isMealPlan = (templateId: string): boolean =>
  templateId === MEAL_PLANS.id.toString();
export const isFoodRecord = (templateId: string): boolean =>
  templateId === FOOD_RECORDS.id.toString();

export const hasYieldAndServe = (id: string): boolean => isRecipe(id);

export const hasFoodItems = (id: string): boolean => !isFood(id);

export const DEFAULT_MEAL_PLAN_SECTIONS: SectionState[] = [
  {
    index: 0,
    id: uuidv4(),
    title: "Breakfast",
    foodItems: [],
    isCollapsed: false,
    tags: [],
  },
  {
    index: 1,
    id: uuidv4(),
    title: "Morning snack",
    foodItems: [],
    isCollapsed: false,
    tags: [],
  },
  {
    index: 2,
    id: uuidv4(),
    title: "Lunch",
    foodItems: [],
    isCollapsed: false,
    tags: [],
  },
  {
    index: 3,
    id: uuidv4(),
    title: "Afternoon snack",
    foodItems: [],
    isCollapsed: false,
    tags: [],
  },
  {
    index: 4,
    id: uuidv4(),
    title: "Dinner",
    foodItems: [],
    isCollapsed: false,
    tags: [],
  },
];

export const DAYS_OF_THE_WEEK: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const DEFAULT_MEAL_PLAN_DAYS: DayState[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
].map(
  (name: string, index: number): DayState => {
    return {
      index: index,
      id: uuidv4(),
      title: name,
      date: "",
      sections: DEFAULT_MEAL_PLAN_SECTIONS,
    };
  }
);

export const FOOD_TEMPLATES = [
  FOODS,
  RECIPES,
  MEAL_PLANS,
  TWENTY_FOUR_HOUR_RECALL,
  FOOD_RECORDS,
];

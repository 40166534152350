import React, { ReactNode } from "react";
import {
  Card,
  List,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { DocumentSummary } from "../../../../../data/models/userDatabase";
import useRecentDocuments from "../hooks/useRecentDocuments";
import { handleRouteChange } from "../../../../../store/ui/thunks/routing";
import { getDatabaseDocumentRouteData } from "../../../../../data/routing/routing";
import { databaseIdSelector } from "../../../../../store/data/selectors/database";
import { RootState } from "../../../../../store/reducers";
import {
  FoodTemplate,
  FOOD_TEMPLATES,
} from "../../../../../constants/FoodTemplate";
import { FoodworksTooltip } from "../../../../common/InfoTooltip";
import { appTheme } from "../../../../../styling/style";

const useStyles = makeStyles(() => ({
  card: {
    width: 500,
    height: 300,
    marginTop: 10,
  },
  menuItemRoot: {
    display: "flex",
    alignItems: "start",
  },
  menuText: {
    marginLeft: 10,
  },
  buttonIcon: {
    fill: appTheme.colors.primary,
    height: 24,
    width: 24,
  },
}));

const DOCUMENTS_TO_SHOW = 5;

const createLastModifiedDateText = (date: string): string => {
  const dateStringParts: string[] = new Date(date).toDateString().split(" ");
  return `Last updated on ${dateStringParts[2]} ${dateStringParts[1]}, ${dateStringParts[3]}`;
};

const RecentDocumentList = (): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onNavigateToDocument = (documentId: string) =>
    dispatch(
      handleRouteChange(
        getDatabaseDocumentRouteData(currentDatabaseId, documentId)
      )
    );

  const currentDatabaseId: string =
    useSelector<RootState, string>(databaseIdSelector);

  const sortedDocuments: DocumentSummary[] =
    useRecentDocuments(DOCUMENTS_TO_SHOW);
  return (
    <>
      <Typography variant="h3">Recent documents</Typography>
      <Card className={classes.card}>
        <List>
          {sortedDocuments.map((summary: DocumentSummary): ReactNode => {
            const template = FOOD_TEMPLATES.find(
              (template: FoodTemplate): boolean =>
                template.id.toString() === summary.templateId
            )!;
            return (
              <FoodworksTooltip
                key={`${summary.templateId}-${summary.documentId}`}
                title={createLastModifiedDateText(summary.lastModified)}
              >
                <MenuItem
                  onClick={() => onNavigateToDocument(summary.documentId)}
                  className={classes.menuItemRoot}
                >
                  <template.icon className={classes.buttonIcon} />
                  <Typography className={classes.menuText}>
                    {summary.label}
                  </Typography>
                </MenuItem>
              </FoodworksTooltip>
            );
          })}
        </List>
      </Card>
    </>
  );
};

export default RecentDocumentList;

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import { withFirebase } from "../../../../../../../data/Firebase";
import { FirebaseProps } from "../../../../../../../data/Firebase/firebase";
import FoodworksSelect from "../../../../../../common/FoodworksSelect";
import useClientBMR from "../hooks/useClientBMR";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 0px",
  },
  label: {
    width: "60%",
  },
  value: {
    marginLeft: 15,
    marginRight: 15,
    width: 80,
  },
}));

const EERDisplayInner = React.memo<FirebaseProps>(
  ({ firebase }): JSX.Element => {
    const classes = useStyles();

    const eer = useClientBMR(firebase!);

    return (
      <div className={classes.root}>
        <Typography className={classes.label} noWrap>
          Basal Metabolic Rate (BMR)
        </Typography>
        <Typography align="right" className={classes.value} color="secondary">
          {eer}
        </Typography>
        <FoodworksSelect<"Schofield">
          options={["Schofield"]}
          value="Schofield"
          onChange={() => {}}
        />
      </div>
    );
  }
);

const EERDisplay = withFirebase(EERDisplayInner);

export default EERDisplay;

import { put, takeEvery, all, select, takeLatest } from "redux-saga/effects";

import { Day, DayState } from "../../../data/models/documentProperties/day";
import { RetentionFactor } from "../../../data/models/documentProperties/retentionFactor";
import { Sections } from "../../../data/models/documentProperties/section";
import {
  addDocumentPast,
  removeDuplicateHistory,
} from "../../ui/actionCreators/documentEditing";
import { updateCurrentDay } from "../../ui/actionCreators/recipeGrid";
import {
  ADD_DOCUMENT_PAST,
  REDO_DOCUMENT,
  UNDO_DOCUMENT,
} from "../../ui/actions/documentEditing";
import { currentHistorySelector } from "../../ui/selectors/documentEditing";
import { currentDaySelector } from "../../ui/selectors/recipeGrid";
import { setDays } from "../current-document/action-creators/days";
import { SET_DOCUMENT_DATA } from "../current-document/actions/currentDocument";
import { IActionsSetDays, SET_DAYS } from "../current-document/actions/days";
import {
  ADD_FOOD_ITEM,
  CLEAR_FOOD_ITEM,
  CLEAR_QUANTITY,
  FOOD_ITEMS_PASTED,
  REMOVE_FOOD_ITEM,
  REMOVE_MULTIPLE_FOOD_ITEMS,
  UPDATE_FOOD_ITEM,
  UPDATE_NOTE,
  UPDATE_QUANTITY,
} from "../current-document/actions/foodItems";
import {
  ADD_SECTION,
  ADD_SECTION_TAG,
  DELETE_SECTIONS,
  REMOVE_SECTION_TAG,
  UPDATE_MULTIPLE_SECTIONS,
  UPDATE_SECTION_TITLE,
} from "../current-document/actions/sections";
import { dayCountSelector } from "../current-document/selectors/currentDocument";
import { daysStateSelector } from "../current-document/selectors/days";
import { getRetentionFactorMap } from "../selectors/referenceData";

const actionsToListenTo: string[] = [
  UPDATE_SECTION_TITLE,
  ADD_FOOD_ITEM,
  CLEAR_FOOD_ITEM,
  UPDATE_FOOD_ITEM,
  REMOVE_FOOD_ITEM,
  UPDATE_QUANTITY,
  CLEAR_QUANTITY,
  UPDATE_NOTE,
  ADD_SECTION_TAG,
  REMOVE_SECTION_TAG,
  ADD_SECTION,
  DELETE_SECTIONS,
  FOOD_ITEMS_PASTED,
  UPDATE_MULTIPLE_SECTIONS,
  REMOVE_MULTIPLE_FOOD_ITEMS,
];

const dayStateToDayConverter = (
  dayState: DayState,
  retentionFactorMap: Map<string, RetentionFactor>
): Day => {
  const { id, index, sections, title, date } = dayState;

  const section = new Sections(sections, retentionFactorMap);
  return new Day(id, index, section, title, date);
};

function* getCurrentDay() {
  //@ts-ignore
  const dayStates: DayState[] = [...(yield select(daysStateSelector))];
  const retentionFactorMap = Object.assign(yield select(getRetentionFactorMap));

  const days: Day[] = [];
  for (const dayState of dayStates) {
    const day = dayStateToDayConverter(dayState, retentionFactorMap);
    days.push(day);
  }

  return days;
}

function* dayBouncer(action: IActionsSetDays) {
  if (action.ranByUndoable) {
    const currentDay: number = yield select(currentDaySelector);
    const dayCount: number = yield select(dayCountSelector);

    if (currentDay >= dayCount) yield put(updateCurrentDay(0));
  } else {
    yield dispatchToHistory();
  }
}

function* removeDuplicates() {
  yield put(removeDuplicateHistory());
}

function* dispatchToHistory() {
  const days: Day[] = yield getCurrentDay();

  yield put(addDocumentPast(days, false));
}

function* initialiseHistory() {
  const days: Day[] = yield getCurrentDay();

  yield put(addDocumentPast(days, true));
}

function* dispatchUndoRedo() {
  //@ts-ignore
  const current: Day[] = [...(yield select(currentHistorySelector))];
  yield put(setDays(current, true)); // ranByUndoable=true
}

function* watchCurrentDocumentActions() {
  yield takeEvery(SET_DAYS, dayBouncer);
  yield takeLatest(SET_DOCUMENT_DATA, initialiseHistory);
  yield takeEvery(actionsToListenTo, dispatchToHistory);
}

function* watchRedoUndoActions() {
  yield takeLatest(UNDO_DOCUMENT, dispatchUndoRedo);
  yield takeLatest(REDO_DOCUMENT, dispatchUndoRedo);
}

function* cleanUpHistory() {
  yield takeLatest(ADD_DOCUMENT_PAST, removeDuplicates);
}

export default function* undoRedoDocumentSaga() {
  yield all([
    watchCurrentDocumentActions(),
    watchRedoUndoActions(),
    cleanUpHistory(),
  ]);
}

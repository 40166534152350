import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, batch } from "react-redux";
import { createStyles, makeStyles, Typography } from "@material-ui/core";

import Firebase, {
  withFirebase,
} from "../../../../../../../../../data/Firebase";
import { AutoCompleteCell, GridCellType } from "./AutoCompleteCell";
import { FoodItem } from "../../../../../../../../../data/models/documentProperties/foodItem";
import {
  removeFoodItem,
  clearFoodItem,
  addFoodItem,
} from "../../../../../../../../../store/data/current-document/action-creators/foodItems";
import { FoodId } from "../../../../../../../../../data/models/documentProperties/foodId";
import { filterOptions } from "./ingredientUtils";
import {
  FOOD_TEMPLATES,
  isRecipe,
} from "../../../../../../../../../constants/FoodTemplate";
import { appTheme } from "../../../../../../../../../styling/style";
import { getDocument } from "../../../../../../../../../store/data/current-document/thunks/getDocument";
import { DocumentStatus } from "../../../../../../../../../data/models/userDatabase";

const INGREDIENT = "Ingredient";

export interface IngredientSummaryItem {
  foodId: FoodId;
  label: string;
  isPublic: boolean;
  state: DocumentStatus;
  templateId: string;
}

export const isIngredientSummaryItem = (
  object: any
): object is IngredientSummaryItem => {
  const ingredientSummaryItem: IngredientSummaryItem = {
    foodId: object.foodId,
    label: object.label,
    isPublic: object.isPublic,
    state: object.state,
    templateId: object.templateId,
  };
  return (
    typeof ingredientSummaryItem.foodId === "object" &&
    typeof ingredientSummaryItem.label === "string" &&
    typeof ingredientSummaryItem.isPublic === "boolean" &&
    typeof ingredientSummaryItem.state === "string" &&
    typeof ingredientSummaryItem.templateId === "string"
  );
};

interface IngredientCellProps {
  firebase?: Firebase;
  useSetSize: boolean;
  selectedFoodItem: FoodItem | undefined;
  value: string | undefined;
  summaries: IngredientSummaryItem[];
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
  columnIndex: number;
  currentFocusedCell: string;
  setFocusedCell: (cell: string) => void;
  searchTermMap: Map<string, string[]>;
  documentTemplateId: string;
}

const EXTRA_CELL_SPACING = 2;
const INGREDIENT_PLACEHOLDER = "Ingredient";
const FOOD_PLACEHOLDER = "Food";
const SEARCH_RESULT_LIMIT = 500;
export const INGREDIENT_CELL_MAX_WIDTH = 75;

const useStyles = makeStyles(() =>
  createStyles({
    itemIcon: {
      fill: appTheme.colors.primary,
      width: 18,
      height: 18,
      margin: 5,
    },
    renderItem: {
      display: "flex",
      alignItems: "center",
    },
  })
);

const IngredientCellInner = React.memo(
  ({
    useSetSize,
    dayIndex,
    sectionIndex,
    rowIndex,
    columnIndex,
    value,
    summaries,
    selectedFoodItem,
    firebase,
    currentFocusedCell,
    setFocusedCell,
    searchTermMap,
    documentTemplateId,
  }: IngredientCellProps) => {
    // *** Actions ***
    const classes = useStyles();
    const dispatch = useDispatch();

    const onRemoveFoodItem = (rowIndex: number) =>
      dispatch(removeFoodItem(dayIndex, sectionIndex, rowIndex));

    const onClearFoodItem = (rowIndex: number) =>
      dispatch(clearFoodItem(dayIndex, sectionIndex, rowIndex));

    const onFetchDocument = (foodId: FoodId, isPublic: boolean) =>
      dispatch(getDocument(foodId, isPublic, false));

    const onAddFoodItem = (foodId: FoodId, rowIndex: number) =>
      dispatch(
        addFoodItem(
          dayIndex,
          sectionIndex,
          new FoodItem(
            new FoodId({
              datasourceId: foodId.datasourceId!,
              documentId: foodId.documentId!,
            }),
            undefined,
            "",
            rowIndex,
            null,
            ""
          )
        )
      );

    // *** ----- ***

    // *** State ***

    const ref: React.RefObject<HTMLInputElement> =
      React.useRef<HTMLInputElement>(null);

    const [cellValue, setCellValue] = useState(value);
    const [cellWidth, setCellWidth] = useState(
      (cellValue?.length || INGREDIENT_PLACEHOLDER.length) + EXTRA_CELL_SPACING
    );
    const [user] = useAuthState(firebase!.auth);
    // *** ----- ***

    // *** Effects ***
    useEffect(() => {
      setCellValue(value);
      setCellWidth(
        (value?.length || INGREDIENT_PLACEHOLDER.length) + EXTRA_CELL_SPACING
      );
    }, [value]);

    useEffect(() => {
      if (currentFocusedCell === `${sectionIndex}-${rowIndex}-${columnIndex}`) {
        ref?.current?.focus();
      }
    }, [currentFocusedCell, rowIndex, columnIndex, sectionIndex]);

    // *** ----- ***

    const onBlur = (): void => {
      if (cellValue === "") {
        onClearFoodItem(rowIndex);
      }
      setFocusedCell("");
    };

    const onInputChange = (
      event: object,
      newCellValue: string,
      reason: string
    ): void => {
      if (reason === "reset") newCellValue = value!;

      setCellValue(newCellValue);

      const newInputLength: number = newCellValue
        ? newCellValue.length
        : INGREDIENT.length;

      setCellWidth(newInputLength + EXTRA_CELL_SPACING);
    };

    const onSelect = (newCellValue: GridCellType): void => {
      // This seems like a weird thing to do, but apparently it is standard.
      // "foodId" cannot be a constant due to linter complaining.
      if (user && newCellValue && "foodId" in newCellValue) {
        batch(() => {
          if (selectedFoodItem) {
            onRemoveFoodItem(rowIndex);
          }

          const foodId = newCellValue.foodId;
          const isPublic = newCellValue.isPublic;

          onFetchDocument(foodId, isPublic);
          onAddFoodItem(foodId, rowIndex);
          setFocusedCell(`${sectionIndex}-${rowIndex}-${columnIndex + 1}`);
        });
      }
    };

    const ingredientFilterOptions = (options: GridCellType[]): GridCellType[] =>
      filterOptions(options, cellValue, searchTermMap).slice(
        0,
        SEARCH_RESULT_LIMIT
      );

    const buildOptionIcon = (option: GridCellType) => {
      if ("templateId" in option) {
        for (const template of FOOD_TEMPLATES) {
          if (option.templateId === template.id.toString()) {
            return <template.icon className={classes.itemIcon} />;
          }
        }
      }
    };

    const renderItem = (option: GridCellType) => (
      <div className={classes.renderItem}>
        {buildOptionIcon(option)}
        <Typography>{option.label}</Typography>
      </div>
    );

    return (
      <AutoCompleteCell
        ref={ref}
        cellSize={
          cellWidth > INGREDIENT_CELL_MAX_WIDTH
            ? INGREDIENT_CELL_MAX_WIDTH
            : cellWidth
        }
        items={summaries}
        minWidth={800}
        initialInput={cellValue || ""}
        placeholder={
          isRecipe(documentTemplateId)
            ? INGREDIENT_PLACEHOLDER
            : FOOD_PLACEHOLDER
        }
        useSetSize={useSetSize}
        onInputChange={onInputChange}
        onBlur={onBlur}
        onFocus={() =>
          setFocusedCell(`${sectionIndex}-${rowIndex}-${columnIndex}`)
        }
        onSelect={onSelect}
        filterOptions={ingredientFilterOptions}
        renderOption={renderItem}
      />
    );
  }
);

export const IngredientCell = withFirebase(IngredientCellInner);

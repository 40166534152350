import {
  ActivityLevel,
  PregnancyLevel,
  Sex,
} from "../../../../data/models/nutrientReferenceValues";
import {
  IActionsSetClientActivityLevel,
  IActionsSetClientAge,
  IActionsSetClientCurrentWeight,
  IActionsSetClientDOB,
  IActionsSetClientHeight,
  IActionsSetClientIsLactating,
  IActionsSetClientPregnancyLevel,
  IActionsSetClientRestingMetabolicRate,
  IActionsSetClientSex,
  IActionsSetClientStartWeight,
  IActionsSetUseCER,
} from "../actions/clientProfile";

export const setClientAge = (
  age: number | undefined
): IActionsSetClientAge => ({
  type: "SET_CLIENT_AGE",
  age,
});

export const setClientDOB = (dob: string): IActionsSetClientDOB => ({
  type: "SET_CLIENT_DOB",
  dob,
});

export const setClientSex = (sex: Sex): IActionsSetClientSex => ({
  type: "SET_CLIENT_SEX",
  sex,
});

export const setClientStartWeight = (
  weight: number | undefined
): IActionsSetClientStartWeight => ({
  type: "SET_CLIENT_START_WEIGHT",
  weight,
});

export const setClientCurrentWeight = (
  weight: number | undefined
): IActionsSetClientCurrentWeight => ({
  type: "SET_CLIENT_CURRENT_WEIGHT",
  weight,
});

export const setClientHeight = (
  height: number | undefined
): IActionsSetClientHeight => ({
  type: "SET_CLIENT_HEIGHT",
  height,
});

export const setClientActivityLevel = (
  activityLevel: ActivityLevel
): IActionsSetClientActivityLevel => ({
  type: "SET_CLIENT_ACTIVITY_LEVEL",
  activityLevel,
});

export const setClientPregnancyLevel = (
  pregnancyLevel: PregnancyLevel
): IActionsSetClientPregnancyLevel => ({
  type: "SET_CLIENT_PREGNANCY_LEVEL",
  pregnancyLevel,
});

export const setClientIsLactating = (
  isLactating: boolean
): IActionsSetClientIsLactating => ({
  type: "SET_CLIENT_IS_LACTATING",
  isLactating,
});

export const setClientRestingMetabolicRate = (
  rate: number | undefined
): IActionsSetClientRestingMetabolicRate => ({
  type: "SET_CLIENT_RESTING_METABOLIC_RATE",
  rate,
});

export const setClientUseCER = (useCER: boolean): IActionsSetUseCER => ({
  type: "SET_USE_CER",
  useCER,
});

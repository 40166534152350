import { FoodWorksError } from "../../constants/errors";
import { IActionsAddError, IActionsResetError } from "../actions/errorActions";

export const addError = (error: FoodWorksError): IActionsAddError => {
  return {
    type: "ADD_ERROR",
    error: error,
  };
};

export const resetError = (): IActionsResetError => {
  return {
    type: "RESET_ERROR",
  };
};

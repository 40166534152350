import { RootState } from "../../reducers";
import {
  DocumentSummary,
  Profile,
  SharingHistory,
} from "../../../data/models/userDatabase";
import { createSelector } from "reselect";

import { IngredientSummaryItem } from "../../../components/screens/databases/documents/tabs/ingredients/editing_grid/rows/cells/IngredientCell";
import { FoodId } from "../../../data/models/documentProperties/foodId";
import { Tag } from "../../../data/models/documentProperties/section";
import { clientUserDatabaseSelector } from "./clientDatabase";

export const databaseIdSelector = (state: RootState): string =>
  state.database.databaseId;

export const databaseNameSelector = (state: RootState): string =>
  state.database.name;

export const recentlyModifiedDocumentSummariesSelector = (
  state: RootState
): DocumentSummary[] =>
  state.database.userDocumentSummaries
    .sort((a, b) => -a.lastModified.localeCompare(b.lastModified))
    .slice(0, 5);

export const userDocumentSummariesSelector = (
  state: RootState
): DocumentSummary[] =>
  state.database.userDocumentSummaries.sort((a, b) => {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

export const activeUserDocumentSummariesSelector = createSelector(
  userDocumentSummariesSelector,
  (summaries: DocumentSummary[]): DocumentSummary[] =>
    summaries.filter((summary) => summary.status === "active")
);

export const userIngredientSummariesSelector = createSelector<
  RootState,
  DocumentSummary[],
  string,
  IngredientSummaryItem[]
>(
  activeUserDocumentSummariesSelector,
  databaseIdSelector,
  (documentSummaries, databaseId) =>
    documentSummaries.map(
      (documentSummary: DocumentSummary): IngredientSummaryItem => {
        return {
          foodId: new FoodId({
            datasourceId: databaseId,
            documentId: documentSummary.documentId,
          }),
          label: documentSummary.label,
          isPublic: false,
          state: documentSummary.status,
          templateId: documentSummary.templateId.toString(),
        };
      }
    )
);

export const databaseDescriptionSelector = (state: RootState): string =>
  state.database.description;

export const databaseProfilesSelector = (state: RootState): Profile[] =>
  state.database.profiles;

export const sharingHistorySelector = (state: RootState): SharingHistory[] =>
  state.database.sharingHistory;

export const databaseDocumentTagsSelector = (state: RootState): Tag[] =>
  state.database.documentTags;

export const currentDatabaseIsClientDatabaseSelector = createSelector(
  databaseIdSelector,
  clientUserDatabaseSelector,
  (currentDatabaseId, clientDatabaseId): boolean =>
    currentDatabaseId === clientDatabaseId
);

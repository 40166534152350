import React, { FunctionComponent, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Resizable } from "re-resizable";

import { appTheme } from "../../../../styling/style";
import { LoadingBarPadding } from "../../../common/LoadingBarPadding";
import {
  HandleAlignment,
  ResizableHandle,
} from "../../../common/ResizableHandle";
import CollapsePaneButton from "./CollapsePaneButton";

import { NAV_PANE_SIZE, NUT_PANE_SIZE } from "../../../../constants/paneSize";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    background: appTheme.colors.white[8],
    padding: 5,
    paddingLeft: 10,
    maxWidth: `calc(99% - ${NUT_PANE_SIZE.width}px)`,
  },
  paper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "stretch",
    overflowX: "hidden",
  },
  columnFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowX: "hidden",
  },
  collapsed: {
    padding: 5,
    background: appTheme.colors.white[8],
    borderRight: `1px solid ${appTheme.colors.gainsbro}`,
    height: "100%",
  },
}));

const RESIZABLE_SIDES = {
  top: false,
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

interface NavigationPaneBodyProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const NavigationPaneBody: FunctionComponent<NavigationPaneBodyProps> = ({
  open,
  setOpen,
  children,
}): JSX.Element => {
  const classes = useStyles();

  const [navResizeHovered, setNavResizeHovered] = useState(false);
  const [isDragged, setIsDragged] = useState(false);

  return open ? (
    <Resizable
      className={classes.root}
      style={{
        borderRight:
          navResizeHovered || isDragged
            ? `3px solid ${appTheme.colors.oceanBlue[3]}`
            : `1px solid ${appTheme.colors.gainsbro}`,
        paddingRight: navResizeHovered || isDragged ? 2 : 4,
      }}
      minWidth="1%"
      defaultSize={NAV_PANE_SIZE}
      handleStyles={{ right: { display: "flex", cursor: "ew-resize" } }}
      onResizeStart={() => setIsDragged(true)}
      onResizeStop={() => {
        setIsDragged(false);
        setNavResizeHovered(false);
      }}
      handleComponent={{
        right: (
          <ResizableHandle
            isDragged={isDragged}
            isHovered={navResizeHovered}
            onMouseEnter={() => setNavResizeHovered(true)}
            onMouseLeave={() => setNavResizeHovered(false)}
            alignment={HandleAlignment.RIGHT}
          />
        ),
      }}
      enable={RESIZABLE_SIDES}
    >
      <div className={classes.columnFlex}>
        <LoadingBarPadding />
        <div className={classes.paper}>{children}</div>
      </div>
    </Resizable>
  ) : (
    <div className={classes.collapsed}>
      <LoadingBarPadding />
      <CollapsePaneButton
        open={open}
        setOpen={(open) => setOpen(open)}
        type="Navigation"
      />
    </div>
  );
};

export default NavigationPaneBody;

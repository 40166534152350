import React, { ReactNode } from "react";
import {
  Card,
  List,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { People } from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { ClientSummary } from "../../../../../data/models/client";
import { getClientRouteData } from "../../../../../data/routing/routing";
import { handleRouteChange } from "../../../../../store/ui/thunks/routing";
import { FoodworksTooltip } from "../../../../common/InfoTooltip";
import useRecentClients from "../../../appBar/hooks/useRecentClients";

const useStyles = makeStyles(() => ({
  card: {
    width: 500,
    height: 300,
    marginTop: 10,
  },
  menuItemRoot: {
    display: "flex",
    alignItems: "start",
  },
  menuText: {
    marginLeft: 10,
  },
}));

const CLIENTS_TO_SHOW = 5;

const createLastModifiedDateText = (date: string): string => {
  const dateStringParts: string[] = new Date(date).toDateString().split(" ");
  return `Last updated on  ${dateStringParts[2]} ${dateStringParts[1]}, ${dateStringParts[3]}`;
};

const RecentClientList = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onNavigateToClient = (clientId: string) =>
    dispatch(handleRouteChange(getClientRouteData(clientId)));

  const recentClients: ClientSummary[] = useRecentClients(CLIENTS_TO_SHOW);

  return (
    <>
      <Typography variant="h3">Recent clients</Typography>
      <Card className={classes.card}>
        <List>
          {recentClients.map(
            (summary: ClientSummary): ReactNode => (
              <FoodworksTooltip
                key={summary.clientId + summary.date.lastModified}
                title={createLastModifiedDateText(summary.date.lastModified)}
              >
                <MenuItem onClick={() => onNavigateToClient(summary.clientId)}>
                  <People color="secondary" />
                  <Typography className={classes.menuText}>
                    {summary.label}
                  </Typography>
                </MenuItem>
              </FoodworksTooltip>
            )
          )}
        </List>
      </Card>
    </>
  );
};

export default RecentClientList;

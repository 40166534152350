import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  DELETE_FILTER_ID,
  FoodFilter,
  QuickFilter,
} from "../../../../constants/QuickFilter";
import { DocumentSummary } from "../../../../data/models/userDatabase";
import { userDocumentSummariesSelector } from "../../../../store/data/selectors/database";
import { RootState } from "../../../../store/reducers";
import { SelectedQuickFiltersSelector } from "../../../../store/ui/selectors/navigationSelectors";
import {
  filterIngredients,
  createSearchMap,
} from "../../databases/documents/tabs/ingredients/editing_grid/rows/cells/ingredientUtils";

export interface NavigationSearchFilters {
  searchText: string;
  quickFilters: QuickFilter;
}

export function searchDocuments(
  items: DocumentSummary[],
  filter: NavigationSearchFilters,
  searchTermMap: Map<string, string[]>
): DocumentSummary[] {
  return filterIngredients(filter, items, searchTermMap) as DocumentSummary[];
}

interface NavigationDocuments {
  documents: DocumentSummary[];
  hasNonDeletedDocuments: boolean;
  deletedFilterApplied: boolean;
}

const useDocuments = (searchText: string): NavigationDocuments => {
  const userDocumentSummaries: DocumentSummary[] = useSelector<
    RootState,
    DocumentSummary[]
  >(userDocumentSummariesSelector);

  const quickFilters = useSelector<RootState, QuickFilter>(
    SelectedQuickFiltersSelector
  );

  const [searchTermMap, setSearchTermMap] = useState(
    new Map<string, string[]>()
  );

  /**
   * Create preprocessed data for searching
   */

  useEffect(() => {
    setSearchTermMap(createSearchMap(userDocumentSummaries));
  }, [userDocumentSummaries]);

  return {
    documents: searchDocuments(
      userDocumentSummaries,
      {
        quickFilters: quickFilters,
        searchText: searchText,
      },
      searchTermMap
    ).filter((doc) => doc.status !== "deleted"),
    hasNonDeletedDocuments: !!userDocumentSummaries.filter(
      (summary: DocumentSummary) => summary.status !== "deleted"
    ).length,
    deletedFilterApplied: quickFilters.foodFilter.some(
      (filter: FoodFilter) => filter.id === DELETE_FILTER_ID
    ),
  };
};

export default useDocuments;

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setClientRestingMetabolicRate } from "../../../../../../../store/data/current_client/action_creators/clientProfile";
import {
  clientIsUseCER,
  clientRestingMetabolicRateSelector,
} from "../../../../../../../store/data/current_client/selectors/clientProfile";
import { RootState } from "../../../../../../../store/reducers";

const useClientRMR = (): [
  number | undefined,
  (input: number | undefined) => void,
  () => void,
  boolean
] => {
  const dispatch = useDispatch();

  const restingMetabolicRate: number | undefined = useSelector<
    RootState,
    number | undefined
  >(clientRestingMetabolicRateSelector);

  const useRMR: boolean = useSelector<RootState, boolean>(clientIsUseCER);

  const [input, setInput] = useState<number | undefined>(restingMetabolicRate);

  const onSetClientRestingMetabolicRate = useCallback(
    () => dispatch(setClientRestingMetabolicRate(input)),
    [dispatch, input]
  );

  useEffect(() => {
    setInput(restingMetabolicRate);
  }, [restingMetabolicRate]);

  return [input, setInput, onSetClientRestingMetabolicRate, useRMR];
};

export default useClientRMR;

import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

import { FoodWorksNumberInput } from "../../../../../../common/FoodWorksTextInput";
import useClientCurrentWeight from "../hooks/useClientCurrentWeight";
import ProfileItem from "./ProfileItem";
import {
  Measurement,
  MeasurementEntry,
} from "../../../../../../../data/models/clientProperties/measurement";
import { TextOverflowTooltip } from "../../../../../../common/InfoTooltip";
import SubmitMeasurementEntryButton from "./SubmitMeasurementEntryButton";
import useClientMeasurementDispatches from "../../../hooks/useClientMeasurementDispatches";

const useStyles = makeStyles(() => ({
  input: {
    width: 50,
  },
  inputValueContainer: {
    flex: 5,
    display: "flex",
    alignItems: "center",
  },
  valueInput: {
    width: 75,
    marginRight: 10,
  },
  unitText: {
    width: 75,
  },
}));

const CurrentWeightInput = React.memo((): JSX.Element => {
  const classes = useStyles();

  const [addMeasurementEntry]: [
    (entry: MeasurementEntry, title: string, units: string) => void,
    (measurement: Measurement, newLabel: string, newUnits: string) => void
  ] = useClientMeasurementDispatches();

  const previousWeight: number = useClientCurrentWeight();
  const [newWeight, setNewWeight] = useState<number>(0);

  const onAddClientMeasurementEntry = (entry: MeasurementEntry) =>
    addMeasurementEntry(entry, "Weight", "kg");

  const onClickSubmitEntry = () => {
    onAddClientMeasurementEntry({
      value: newWeight,
      date: new Date().toISOString(),
      id: uuidv4(),
    });
  };

  const inputComponent: JSX.Element = (<div className={classes.inputValueContainer}>
  <FoodWorksNumberInput
    inputProps={{
      value: newWeight ? newWeight.toString() : "",
      className: classes.valueInput,
    }}
    maxValue={1000}
    setValue={(input: string) => setNewWeight(Number(input))}
  />
  <TextOverflowTooltip label="kg">
    <Typography className={classes.unitText} noWrap>
      kg
    </Typography>
  </TextOverflowTooltip>
  <SubmitMeasurementEntryButton
    onClickSubmitEntry={onClickSubmitEntry}
    disabled={!newWeight}
  />
</div>)

const displayComponent: JSX.Element = (
  <Typography color="secondary">{`${previousWeight} kg`}</Typography>
)

  return (
    <>
      <ProfileItem
        tooltip="Updated weight can be logged in the tracking tab"
        component={!previousWeight ? inputComponent : displayComponent}
        label={`${!previousWeight ? "Start" : "Current"} weight`}
        isEmptyInfoTooltip={
          !newWeight && !previousWeight ? "This field must not be empty." : ""
        }
      />
    </>
  );
});

export default CurrentWeightInput;

import React, { FunctionComponent, ReactNode, useState } from "react";

import { IconButton, makeStyles, MenuItem } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";

import { Tag } from "../../../../data/models/documentProperties/section";
import { appTheme } from "../../../../styling/style";
import { FoodworksTooltip } from "../../../common/InfoTooltip";

const useStyles = makeStyles((theme) => ({
  listButton: {
    borderRadius: 4,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
    height: 40,
  },
  selectedListButton: {
    borderRadius: 4,
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
  },
  colorButton: {
    height: 10,
    width: 10,
    minWidth: 10,
    marginRight: 5,
    borderRadius: 4,
  },
  tagMenuLabel: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    height: 40,
    width: 40,
  },
  buttonsContainer: {
    display: "flex",
  },
  itemContainer: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
}));

interface TagListItemProps {
  sectionTag: Tag;
  onSelectTag: (tagId: string) => void;
  isSelected: boolean;
  onDeleteTag: (tagId: string) => void;
  onEditTag: (tagId: string) => void;
}

export const TagListItem: FunctionComponent<TagListItemProps> = ({
  sectionTag,
  onSelectTag,
  isSelected,
  onDeleteTag,
  onEditTag,
}) => {
  const classes = useStyles();

  const [rowHovered, setRowHovered] = useState(false);

  const tagButtons: ReactNode = (
    <div className={classes.buttonsContainer} data-cy="tagButtons">
      <FoodworksTooltip title="Edit tag">
        <IconButton
          size="small"
          className={classes.icon}
          onClick={() => onEditTag(sectionTag.id)}
        >
          <Edit />
        </IconButton>
      </FoodworksTooltip>
      <FoodworksTooltip title="Delete tag">
        <IconButton
          size="small"
          className={classes.icon}
          onClick={() => onDeleteTag(sectionTag.id)}
        >
          <Delete />
        </IconButton>
      </FoodworksTooltip>
    </div>
  );

  return (
    <MenuItem
      className={
        isSelected
          ? `${classes.listButton} ${classes.selectedListButton}`
          : classes.listButton
      }
      onClick={() => onSelectTag(sectionTag.id)}
      onMouseEnter={() => setRowHovered(true)}
      onMouseLeave={() => setRowHovered(false)}
    >
      <div className={classes.itemContainer}>
        <div className={classes.tagMenuLabel}>
          <div
            className={classes.colorButton}
            style={{ backgroundColor: sectionTag.activeColor }}
          ></div>
          {sectionTag.label}
        </div>
        {rowHovered && tagButtons}
      </div>
    </MenuItem>
  );
};

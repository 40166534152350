import {
  Measurement,
  MeasurementEntry,
} from "../../../../data/models/clientProperties/measurement";
import {
  EDIT_CLIENT_MEASUREMENT,
  IActionsAddClientMeasurement,
  IActionsAddClientMeasurementEntry,
  IActionsDeleteClientMeasurement,
  IActionsDeleteClientMeasurementEntry,
  IActionsEditClientMeasurement,
  IActionsToggleClientMeasurement,
  IActionsUpdateClientMeasurementEntry,
} from "../actions/clientMeasurements";

export const addClientMeasurement = (
  measurement: Measurement
): IActionsAddClientMeasurement => ({
  type: "ADD_CLIENT_MEASUREMENT",
  measurement,
});

export const editClientMeasurement = (
  measurement: Measurement,
  newLabel: string,
  newUnits: string,
  newSubCategory: string
): IActionsEditClientMeasurement => ({
  type: EDIT_CLIENT_MEASUREMENT,
  measurement,
  newLabel,
  newUnits,
  newSubCategory,
});

export const deleteClientMeasurement = (
  label: string,
  unit: string
): IActionsDeleteClientMeasurement => ({
  type: "DELETE_CLIENT_MEASUREMENT",
  label,
  unit,
});

export const toggleClientMeasurement = (
  label: string,
  unit: string
): IActionsToggleClientMeasurement => ({
  type: "TOGGLE_CLIENT_MEASUREMENT",
  label,
  unit,
});

export const addClientMeasurementEntry = (
  entry: MeasurementEntry,
  label: string,
  unit: string
): IActionsAddClientMeasurementEntry => ({
  type: "ADD_CLIENT_MEASUREMENT_ENTRY",
  entry,
  label,
  unit,
});

export const updateClientMeasurementEntry = (
  label: string,
  unit: string,
  entry: MeasurementEntry
): IActionsUpdateClientMeasurementEntry => ({
  type: "UPDATE_CLIENT_MEASUREMENT_ENTRY",
  label,
  unit,
  entry,
});

export const deleteClientMeasurementEntry = (
  label: string,
  unit: string,
  id: string
): IActionsDeleteClientMeasurementEntry => ({
  type: "DELETE_CLIENT_MEASUREMENT_ENTRY",
  label,
  unit,
  id,
});

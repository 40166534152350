import { Action } from "redux";

import { FoodItemPosition } from "../../../data/models/foodItemPosition";

export const SET_SELECTED_ROWS = "SET_SELECTED_ROWS";
export const UPDATE_CURRENT_DAY = "UPDATE_CURRENT_DAY";
export const TOGGLE_DISPLAY_NUTRIENT_VALUES = "TOGGLE_DISPLAY_NUTRIENT_VALUES";
export const TOGGLE_LOCK_NUTRIENT_COLUMNS = "TOGGLE_LOCK_NUTRIENT_COLUMNS";

export interface IActionsSetSelectedRows extends Action {
  type: "SET_SELECTED_ROWS";
  foodItemPositions: FoodItemPosition[];
}

export interface IActionsUpdateCurrentDay extends Action {
  type: "UPDATE_CURRENT_DAY";
  day: number;
}

export interface IActionsToggleDisplayNutrientValues extends Action {
  type: "TOGGLE_DISPLAY_NUTRIENT_VALUES";
}

export interface IActionsToggleLockNutrientColumns extends Action {
  type: "TOGGLE_LOCK_NUTRIENT_COLUMNS";
}

export type RecipeGridActions =
  | IActionsSetSelectedRows
  | IActionsUpdateCurrentDay
  | IActionsToggleDisplayNutrientValues
  | IActionsToggleLockNutrientColumns;

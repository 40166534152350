import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";

import { appTheme } from "../../../../styling/style";
import { ClientSummaryTags } from "../../../dialogs/tags/client_tags/ClientTags";
import { DocumentSummaryTags } from "../../../dialogs/tags/document_tags/DocumentTags";
import { FoodworksTooltip } from "../../../common/InfoTooltip";

const useStyles = makeStyles(() => ({
  item: {
    flex: 1,
    justifyContent: "start",
    textTransform: "none",
  },
  selected: {
    backgroundColor: appTheme.colors.oceanBlue[0],
  },
  buttonText: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  selectedText: {
    fontWeight: 600,
    color: appTheme.colors.primary,
  },
  tagsContainer: {
    marginLeft: 10,
  },
}));

type ItemType = "Document" | "Client";

interface NavigationListButtonProps {
  label: string;
  id: string;
  onClick: (id: string) => void;
  type: ItemType;
  isSelected: boolean;
  showTags: boolean;
  isPinned?: boolean;
}

const NavigationListButton = React.memo<NavigationListButtonProps>(
  ({
    label,
    id,
    onClick,
    isSelected,
    showTags,
    type,
    isPinned,
  }): JSX.Element => {
    const classes = useStyles();
    return (
      <Button
        className={
          isSelected ? `${classes.item} ${classes.selected}` : classes.item
        }
        fullWidth={true}
        size="small"
        onClick={() => onClick(id)}
      >
        <FoodworksTooltip title={label}>
          <Typography noWrap variant="body1">
            <span
              className={
                isSelected || isPinned
                  ? `${classes.buttonText} ${classes.selectedText}`
                  : classes.buttonText
              }
            >
              {label}
            </span>
          </Typography>
        </FoodworksTooltip>
        <div className={classes.tagsContainer}>
          {showTags &&
            (type === "Document" ? (
              <DocumentSummaryTags documentId={id} disabled={true} />
            ) : (
              <ClientSummaryTags clientId={id} disabled={true} />
            ))}
        </div>
      </Button>
    );
  }
);

export default NavigationListButton;

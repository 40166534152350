import { DataSource, dataSourceConverter } from "../../models/datasource";
import { Document, documentConverter } from "../../models/document";
import Firebase from "../firebase";

const PUBLIC_DATABASES_DATABASE = "public-databases";

export class FirebasePublicDatabases {
  collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(firestore: firebase.firestore.Firestore) {
    this.collection = firestore.collection(PUBLIC_DATABASES_DATABASE);
  }

  publicDatabaseDocument(
    databaseId: string
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    return this.collection.doc(databaseId);
  }

  publicDocument(
    databaseId: string,
    documentId: string
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    return this.publicDatabaseDocument(databaseId)
      .collection("documents")
      .doc(documentId);
  }

  async doGetDataSource(databaseId: string): Promise<DataSource> {
    const databaseData: firebase.firestore.DocumentSnapshot<DataSource> = await Firebase.cachedDocumentGet(
      this.publicDatabaseDocument(databaseId).withConverter(dataSourceConverter)
    );
    return databaseData.data()!;
  }

  async doGetPublicDocument(
    databaseId: string,
    documentId: string
  ): Promise<Document> {
    const documentData = await this.publicDocument(databaseId, documentId)
      .withConverter(documentConverter)
      .get();

    return documentData.data()!;
  }
}

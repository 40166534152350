export enum ServeType {
  WEIGHT = "Serve weight",
  AUS_BRANDS_WEIGHT = "Weight",
  PERCENT = "Serve percent",
}

export interface Serve {
  type: ServeType;
  value: number | undefined;
}

export const serveConverter = {
  toFirestore: (
    serveValue: Serve | undefined
  ): firebase.firestore.DocumentData => {
    return !serveValue?.value
      ? { value: null, type: serveValue?.type }
      : {
          value: serveValue.value,
          type: serveValue.type,
        };
  },

  fromFirestore: (data: firebase.firestore.DocumentData): Serve => {
    if (!data) {
      return { type: ServeType.PERCENT, value: undefined };
    }
    return data.value === null
      ? { type: data.type, value: undefined }
      : {
          value: data.value,
          type: data.type,
        };
  },
};

import React from "react";

import { FoodWorksTextInput } from "../../../../../../common/FoodWorksTextInput";
import ProfileItem from "../../profile/components/ProfileItem";
import useClientEmail from "../hooks/useClientEmail";
import { EMAIL_FIELD } from "../../../../../../../constants/textInputs";

const EmailInput = React.memo(
  (): JSX.Element => {
    const [email, setEmail, updateEmail] = useClientEmail();

    return (
      <ProfileItem
        component={
          <FoodWorksTextInput
            maxLength={EMAIL_FIELD}
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
            onBlur={updateEmail}
          />
        }
        label="Email"
      />
    );
  }
);

export default EmailInput;

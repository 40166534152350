import React, { Fragment } from 'react'
import { Divider, makeStyles, Typography } from '@material-ui/core';

import { FoodworksTooltip } from '../../../../../common/InfoTooltip';

const useStyles = makeStyles(() => ({
    "@keyframes grow": {
        "0%": {
          
          transform: 'scaleX(0)',
        },
        "100%": {
          transform: 'scaleX(1)',
        },
    },
    grow: {
        animationName: "$grow",
        animationDuration: "0.8s",
        animationFillMode: "forwards",
    },
    root: {
      marginTop: 0,
      padding: 0,
      display: "flex",
      width: '100%',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      justifyContent: 'space-between',
    },
    bar: {
        transformOrigin: 'top left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        
    },
    labels: {
        display: 'flex',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        margin: 0,
    },
    title:{
        width: '50%',
    },
    barContainer: {
        background: "repeating-linear-gradient(to right, #F2F2F7 ,#F2F2F7 1%,transparent 2%,transparent 25%)",
        alignItems: 'center',
        width: '40%'
    },
    percentContainer: {
        alignItems: 'center',
        width: '10%'
    }

}));


export interface BarProps {
    label: string;
    percentage: string;
    value: string;
    barWidth: string;
    barColor: string;
}

const Bar = React.memo((props: BarProps) => {
    const classes = useStyles();
    return (
        <Fragment>
        <div className={classes.root}>  
            <div className={classes.title}>
                <FoodworksTooltip title={props.label}>
                    <Typography noWrap>{props.label}</Typography>
                </FoodworksTooltip>
            </div>
            <div className={classes.percentContainer}>
                    <FoodworksTooltip title={props.percentage}>
                        <Typography noWrap>{props.percentage}</Typography>
                    </FoodworksTooltip>
            </div>
            <div className={`${classes.barContainer} `}>   
                <div key={props.percentage} className={`${classes.bar} ${classes.grow}`} style={{width:props.percentage, height:props.barWidth, backgroundColor: props.barColor}}>
                    <FoodworksTooltip title={props.value}>
                        <Typography noWrap>{props.value}</Typography>
                    </FoodworksTooltip>
                </div>
            </div>
        </div> 
        <Divider />
        </Fragment>
    )
});

export default Bar;
import React from "react";
import { makeStyles } from "@material-ui/core";

import { appTheme } from "../../../../../../styling/style";
import Bar, { BarProps } from "./Bar";

const useStyles = makeStyles(() => ({
  barsContainer: {
    overflowY: "auto",
    width: "100%",
    backgroundColor: appTheme.colors.white[8],
  },
  container: {
    overflowY: "hidden",
    width: "100%",
    backgroundColor: appTheme.colors.secondary,
  },
}));

export interface BarChartProps {
  data: BarProps[];
}

const Barchart = React.memo((props: BarChartProps) => {
  const classes = useStyles();

  const bars = props.data.map((bar: any) => (
    <Bar
      key={bar.label}
      barWidth={bar.barWidth}
      label={bar.label}
      value={bar.value}
      percentage={bar.percentage}
      barColor={bar.barColor}
    />
  ));

  return (
    <div className={classes.container}>
      <div className={classes.barsContainer}>{bars}</div>
    </div>
  );
});

export default Barchart;

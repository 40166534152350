import { Action } from "redux";

export const SET_ENABLED_DATASOURCES = "SET_ENABLED_DATASOURCES";
export const SET_DISPLAYED_NUTRIENTS = "SET_DISPLAYED_NUTRIENTS";
export const SET_ENABLED_MEASURES = "SET_ENABLED_MEASURES";
export const SET_ENABLED_NUTRIENTS = "SET_ENABLED_NUTRIENTS";
export const SET_IS_ALTERNATE_IDS_ENABLED = "SET_IS_ALTERNATE_IDS_ENABLED";

export interface IActionsSetEnabledDatasources extends Action {
  type: "SET_ENABLED_DATASOURCES";
  sources: string[];
}

export interface IActionsSetDisplayedNutrients extends Action {
  type: "SET_DISPLAYED_NUTRIENTS";
  nutrientIds: string[];
}

export interface IActionsSetEnabledMeasures extends Action {
  type: "SET_ENABLED_MEASURES";
  enabledMeasures: string[];
}

export interface IActionSetEnabledNutrients extends Action {
  type: "SET_ENABLED_NUTRIENTS";
  enabledNutrients: string[];
}

export interface IActionSetIsAlternateIdsEnabled extends Action {
  type: "SET_IS_ALTERNATE_IDS_ENABLED";
  isAlternateIdsEnabled: boolean;
}

export type DatabasePropertyActions =
  | IActionsSetEnabledDatasources
  | IActionsSetDisplayedNutrients
  | IActionsSetEnabledMeasures
  | IActionSetEnabledNutrients
  | IActionSetIsAlternateIdsEnabled;

import { FoodWorksDate } from "./documentProperties/date";
import firebase from "firebase";
import { Tag } from "./documentProperties/section";

export type DocumentStatus = "deleted" | "active" | "archived";

export interface DocumentSummary {
  documentId: string;
  label: string;
  templateId: string;
  status: DocumentStatus;
  searchableProperties: {
    id1: string;
    id2: string;
  };
  sectionTags: Tag[];
  lastModified: string;
  documentTagIds: string[];
}

export interface UserDatabaseSummary {
  id: string;
  name: string;
  date: FoodWorksDate;
  copiedDatabaseProperties: DatabaseProperties;
}

export interface DatabaseProperties {
  displayedNutrients: string[];
  enabledMeasures: string[];
  enabledNutrients: string[];
  enabledDatasources: string[];
  isAlternateIdsEnabled: boolean;
}

export const defaultDatabaseProperties: DatabaseProperties = {
  displayedNutrients: [],
  enabledDatasources: [],
  enabledMeasures: [],
  enabledNutrients: [],
  isAlternateIdsEnabled: false,
};

export interface Profile {
  id: string;
  name: string;
  nutrients: string[];
}

export interface SharingHistory {
  recipient: string;
  dateShared: string;
}

export interface Database {
  documentSummaries: DocumentSummary[];
  summary: UserDatabaseSummary;
  properties: DatabaseProperties;
  description: string;
  profiles: Profile[];
  sharingHistory: SharingHistory[];
  documentTags: Tag[];
}

export const defaultDatabase: Database = {
  documentSummaries: [],
  summary: {
    id: "",
    name: "",
    date: { lastModified: "", created: "" },
    copiedDatabaseProperties: defaultDatabaseProperties,
  },
  properties: defaultDatabaseProperties,
  description: "",
  profiles: [],
  sharingHistory: [],
  documentTags: [],
};

export const databaseConverter = {
  toFirestore: function (database: Database) {
    throw new Error("Unimplemented");
  },

  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): Database {
    const data: firebase.firestore.DocumentData = snapshot.data();

    const summaries: firebase.firestore.DocumentData[] = data.documentSummaries;

    return {
      summary: data.summary,
      documentSummaries: summaries.map((summary) => ({
        documentId: summary.documentId as string,
        label: summary.label || (summary.name as string),
        templateId: summary.templateId as string,
        // todo remove || false once all summaries have been set properly
        status: summary.status || "active",
        searchableProperties: summary.searchableProperties || {
          primary: "",
          alternate: "",
        },
        sectionTags: summary.sectionTags || [],
        lastModified: summary.lastModified || "",
        documentTagIds: summary.documentTagIds || [],
      })),
      properties: data.properties,
      description: data.description,
      profiles: data.profiles,
      sharingHistory: data.sharingHistory || [],
      documentTags: data.documentTags || [],
    };
  },
};

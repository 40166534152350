import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { batch } from "react-redux";

import {
  IActionsAddUserDocumentSummary,
  IActionsSetDatabaseDescription,
  IActionsSetDatabaseDocumentTags,
  IActionsSetDatabaseName,
  IActionsSetDatabaseProfiles,
  IActionsSetSharingHistory,
  IActionsSetUserDocumentSummaries,
  IActionsUpdateUserDocumentSummary,
} from "../actions/database";
import {
  addUserDocumentSummary,
  setDatabaseDescription,
  setDatabaseName,
  setSharingHistory,
  setUserDocumentSummaries,
  setDatabaseProfiles,
  updateUserDocumentSummary,
  setDatabaseDocumentTags,
} from "../action-creators/database";
import { databaseIdSelector } from "../selectors/database";
import { setNavIsLoading } from "../../ui/actionCreators/navigationActionCreators";
import {
  Database,
  DatabaseProperties,
  DocumentSummary,
  Profile,
  SharingHistory,
  UserDatabaseSummary,
} from "../../../data/models/userDatabase";
import { fetchNavigationSummaries } from "../../../data/Firebase/helpers/fetchNavigationSummaries";
import { IActionSetNavIsLoading } from "../../ui/actions/navigationActions";
import {
  IActionsRemoveUserDatabaseSummary,
  IActionsAddUserDatabaseSummary,
  IActionsUpdateUserDatabaseSummary,
} from "../actions/user";
import { Document } from "../../../data/models/document";
import {
  removeUserDatabaseSummary,
  addUserDatabaseSummary,
  updateUserDatabaseSummary,
} from "../action-creators/user";
import { sortedUserDatabaseSummariesSelector } from "../selectors/user";
import { RootState } from "../../reducers";
import {
  setDisplayedNutrients,
  setIsAlternateIdsEnabled,
  setEnabledDatasources,
  setEnabledMeasures,
  setEnabledNutrients,
} from "../action-creators/databaseProperties";
import {
  IActionSetIsAlternateIdsEnabled,
  IActionSetEnabledNutrients,
  IActionsSetDisplayedNutrients,
  IActionsSetEnabledDatasources,
  IActionsSetEnabledMeasures,
} from "../actions/databaseProperties";
import { getCurrentDate } from "../../../data/models/documentProperties/date";
import { ExtraArguments } from "../../store";
import { handleRouteChange } from "../../ui/thunks/routing";
import { initialDatabasePropertiesState } from "../reducers/databaseProperties";
import { Tag } from "../../../data/models/documentProperties/section";
import { getDatabaseRouteData } from "../../../data/routing/routing";

export const createDocumentSummary =
  (
    databaseId: string,
    documentId: string,
    document: Document
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddUserDocumentSummary
  > =>
  async (dispatch, getState, { firebase }) => {
    return firebase.userDatabases
      .doCreateUserDocumentSummary(databaseId, document, documentId)
      .then((documentSummary: DocumentSummary) => {
        dispatch(addUserDocumentSummary(documentSummary));
      });
  };

export const updateDocumentSummary =
  (
    databaseId: string,
    updatedDocumentSummary: DocumentSummary,
    existingDocumentSummary: DocumentSummary
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsUpdateUserDocumentSummary
  > =>
  async (dispatch, getState, { firebase }) => {
    await firebase.userDatabases.doUpdateUserDocumentSummary(
      databaseId,
      updatedDocumentSummary,
      existingDocumentSummary
    );

    dispatch(updateUserDocumentSummary(updatedDocumentSummary));
  };

export const updateDocumentSummaries =
  (
    documentSummaries: DocumentSummary[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetUserDocumentSummaries
  > =>
  async (dispatch, getState, { firebase }) => {
    const databaseId = databaseIdSelector(getState());

    await firebase.userDatabases.doUpdateUserDocumentSummaries(
      databaseId,
      documentSummaries
    );

    dispatch(setUserDocumentSummaries(documentSummaries));
  };

export const updateDatabaseLastModifiedDate =
  (
    databaseSummary: UserDatabaseSummary
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsUpdateUserDatabaseSummary
  > =>
  async (dispatch, getState, { firebase }) => {
    await firebase?.userDatabases.doUpdateUserDatabaseSummaryLastModified(
      databaseSummary.id,
      databaseSummary.date.lastModified
    );

    dispatch(updateUserDatabaseSummary(databaseSummary));
  };

export const fetchSummaryData =
  (): ThunkAction<void, RootState, ExtraArguments, Action<any>> =>
  (dispatch) => {
    batch(() => {
      dispatch(fetchUserDocumentSummaries());
    });
  };

export const setDatabaseProperties =
  (
    database: Database | undefined
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    | IActionsSetDisplayedNutrients
    | IActionsSetEnabledMeasures
    | IActionSetEnabledNutrients
    | IActionsSetEnabledDatasources
    | IActionSetIsAlternateIdsEnabled
  > =>
  async (dispatch) => {
    const databaseProperties: DatabaseProperties | undefined =
      database?.properties;

    dispatch(
      setDisplayedNutrients(
        databaseProperties ? databaseProperties.displayedNutrients : []
      )
    );
    dispatch(
      setEnabledMeasures(
        databaseProperties ? databaseProperties.enabledMeasures : []
      )
    );
    dispatch(
      setEnabledNutrients(
        databaseProperties ? databaseProperties.enabledNutrients : []
      )
    );
    dispatch(
      setEnabledDatasources(
        databaseProperties ? databaseProperties.enabledDatasources : []
      )
    );
    dispatch(
      setIsAlternateIdsEnabled(
        databaseProperties ? databaseProperties.isAlternateIdsEnabled : false
      )
    );
  };

export const fetchUserDocumentSummaries =
  (): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetUserDocumentSummaries | IActionSetNavIsLoading
  > =>
  async (dispatch, getState, { firebase }) => {
    const databaseId: string = databaseIdSelector(getState());
    const userDocumentSummaries: DocumentSummary[] =
      await fetchNavigationSummaries(firebase, [databaseId]);

    dispatch(setUserDocumentSummaries(userDocumentSummaries));

    dispatch(setNavIsLoading(false));
  };

export const deleteDatabase =
  (
    databaseId: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsRemoveUserDatabaseSummary
  > =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const databaseSummaries: UserDatabaseSummary[] =
      sortedUserDatabaseSummariesSelector(getState());

    await firebase.userPermissions.doRemoveDatabasePermission(
      firebase.auth.currentUser!.uid,
      databaseId
    );

    if (databaseId === currentDatabaseId) {
      const newDatabaseId: string = databaseSummaries.filter(
        (summary: UserDatabaseSummary): boolean => summary.id !== databaseId
      )[0].id;

      await firebase.users.doUpdateUserLastUsedDatabase(
        firebase.auth.currentUser!.uid,
        newDatabaseId
      );
      dispatch(handleRouteChange(getDatabaseRouteData(newDatabaseId)));
    }
    dispatch(removeUserDatabaseSummary(databaseId));
  };

export const createDatabase =
  (
    newDatabaseName: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddUserDatabaseSummary
  > =>
  async (dispatch, getState, { firebase }) => {
    const newDate = getCurrentDate();

    const uid: string = firebase.auth.currentUser?.uid!;

    const databaseId: string = await firebase!.createNewUserDatabase(
      uid,
      newDatabaseName,
      { created: newDate, lastModified: newDate }
    );

    dispatch(
      addUserDatabaseSummary({
        id: databaseId,
        name: newDatabaseName,
        date: { created: newDate, lastModified: newDate },
        copiedDatabaseProperties: initialDatabasePropertiesState,
      })
    );
  };

export const updateDatabaseName =
  (
    name: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsUpdateUserDatabaseSummary | IActionsSetDatabaseName
  > =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const userDatabaseSummaries: UserDatabaseSummary[] =
      sortedUserDatabaseSummariesSelector(getState());

    const currentDatabaseSummary: UserDatabaseSummary =
      userDatabaseSummaries.find(
        (summary: UserDatabaseSummary): boolean =>
          summary.id === currentDatabaseId
      )!;

    await firebase?.userDatabases.doUpdateUserDatabaseSummaryName(
      currentDatabaseId,
      name
    );

    batch(() => {
      dispatch(setDatabaseName(name));
      dispatch(
        updateUserDatabaseSummary({ ...currentDatabaseSummary, name: name })
      );
    });
  };

export const updateDatabaseDescription =
  (
    description: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetDatabaseDescription
  > =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    await firebase.userDatabases.doUpdateUserDatabaseDescription(
      currentDatabaseId,
      description
    );

    dispatch(setDatabaseDescription(description));
  };

export const updateDatabaseSharingHistory =
  (
    history: SharingHistory[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetSharingHistory
  > =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    await firebase.userDatabases.doUpdateUserDatabaseSharingHistory(
      currentDatabaseId,
      history
    );

    dispatch(setSharingHistory(history));
  };

export const updateDatabaseProfiles =
  (
    profiles: Profile[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetDatabaseProfiles
  > =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    await firebase.userDatabases.doUpdateUserDatabaseProfiles(
      currentDatabaseId,
      profiles
    );

    dispatch(setDatabaseProfiles(profiles));
  };

export const updateDatabaseDocumentTags =
  (
    documentTags: Tag[]
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetDatabaseDocumentTags
  > =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    await firebase.userDatabases.doUpdateDatabaseDocumentTags(
      currentDatabaseId,
      documentTags
    );

    dispatch(setDatabaseDocumentTags(documentTags));
  };

import { ScreenType } from "../../../types";
import { User, userConverter } from "../../models/user";

const USERS_DATABASE = "users";

export class FirebaseUsers {
  collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(firestore: firebase.firestore.Firestore) {
    this.collection = firestore.collection(USERS_DATABASE);
  }

  private userDocument(
    uid: string
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    return this.collection.doc(uid);
  }

  async doCreateUser(user: User): Promise<void> {
    return this.userDocument(user.id).set(user);
  }

  async doGetUser(uid: string): Promise<User | undefined> {
    const userData = await this.userDocument(uid)
      .withConverter(userConverter)
      .get();
    return userData.data();
  }

  doUpdateUserFirstName(uid: string, firstName: string): Promise<void> {
    return this.userDocument(uid).update({ firstName: firstName });
  }

  doUpdateUserLastName(uid: string, name: string): Promise<void> {
    return this.userDocument(uid).update({ lastName: name });
  }

  doUpdateUserLastUsedDatabase(uid: string, databaseId: string): Promise<void> {
    return this.userDocument(uid).update({ lastUsedDatabase: databaseId });
  }

  doUpdateLastScreen(uid: string, screen: ScreenType): Promise<void> {
    return this.userDocument(uid).update({ lastScreen: screen });
  }
}

import React, {
  FunctionComponent,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, ListItem, Typography } from "@material-ui/core";

import { RootState } from "../../../../../../store/reducers";
import {
  documentMappingIdSelector,
  overridesNutrientValueSelector,
  templateIdSelector,
} from "../../../../../../store/data/current-document/selectors/document";
import { appTheme } from "../../../../../../styling/style";
import {
  FoodWorksTextInput,
  FoodWorksNumberInput,
} from "../../../../../common/FoodWorksTextInput";
import {
  addNutrientOverride,
  updateNutrientOverrideValue,
  removeNutrientOverride,
  updateNutrientOverrideNote,
} from "../../../../../../store/data/current-document/action-creators/nutrientOverrides";
import { NutrientValue } from "../../../../../../data/models/nutrientValue";
import { TABLE_COLUMN_STYLES } from "./constants";
import { FOODS } from "../../../../../../constants/FoodTemplate";
import {
  FoodworksTooltip,
  InfoTooltip,
} from "../../../../../common/InfoTooltip";
import { LARGE_FIELD } from "../../../../../../constants/textInputs";
import { WEIGHT_ID } from "../../../../../../constants/nutrientIds";

const UNKNOWN_VALUE = "?";

const useStyles = makeStyles({
  row: {
    display: "flex",
    padding: 0,
    minWidth: 700,
  },
  cell: {
    border: 0,
    fontSize: appTheme.fontSize.tableText,
  },
  name: TABLE_COLUMN_STYLES.name,
  numeric: TABLE_COLUMN_STYLES.numeric,
  override: TABLE_COLUMN_STYLES.override,
  result: TABLE_COLUMN_STYLES.numeric,
  noteInput: TABLE_COLUMN_STYLES.noteInput,
});

const isElementOverflowed = (element: HTMLElement) =>
  element.scrollWidth > element.clientWidth;

export interface NutrientRowProps {
  name: string;
  id: string;
  unit: string;
  description: string;
}

export const OverridesNutrientRow: FunctionComponent<NutrientRowProps> = (
  props: NutrientRowProps
) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onAddNutrientOverride = (id: string, value: number) =>
    dispatch(addNutrientOverride(id, value));

  const onUpdateNutrientOverrideValue = (id: string, value: number) =>
    dispatch(updateNutrientOverrideValue(id, value));

  const onRemoveNutrientOverride = (id: string) =>
    dispatch(removeNutrientOverride(id));

  const onUpdateNutrientOverrideNote = (id: string, note: string) =>
    dispatch(updateNutrientOverrideNote(id, note));

  // *** State ***

  const nutrientValue: NutrientValue = useSelector<RootState, NutrientValue>(
    overridesNutrientValueSelector(props.id)
  );

  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const isMapped: boolean = Boolean(
    useSelector<RootState, string>(documentMappingIdSelector)
  );

  const [noteValue, setNoteValue] = useState(
    nutrientValue.override?.note || ""
  );
  const [defaultValue, setDefaultValue] = useState(nutrientValue.baseValue);
  const [overrideValue, setOverrideValue] = useState(
    nutrientValue?.overrideValue
  );
  const resultCellSpanRef = useRef<HTMLDivElement>(null);

  const [hovered, setHovered] = useState(false);

  const showDefaultColumn: boolean =
    templateId !== FOODS.id.toString() || isMapped;
  /*** ----- ***/

  useEffect(() => {
    if (nutrientValue.override) {
      setOverrideValue(nutrientValue.overrideValue);
      setNoteValue(nutrientValue.override?.note || "");
    } else {
      setOverrideValue("");
      setNoteValue("");
    }
    setDefaultValue(nutrientValue.baseValue);
  }, [nutrientValue]);

  const isOverrideDefined = (): boolean =>
    (nutrientValue.overrideValue && Number(nutrientValue.overrideValue) >= 0) ||
    false;

  const isResultDefined = (): boolean =>
    nutrientValue.displayValue !== UNKNOWN_VALUE;

  const defaultValueCell: ReactNode = (
    <div className={classes.numeric}>{defaultValue || UNKNOWN_VALUE}</div>
  );

  const overrideOnBlur = (): void => {
    if (overrideValue) {
      if (!nutrientValue.overrideValue) {
        onAddNutrientOverride(props.id, parseFloat(overrideValue));
      } else {
        onUpdateNutrientOverrideValue(props.id, parseFloat(overrideValue));
      }
    } else if (props.id === WEIGHT_ID) {
      setOverrideValue("0");
      onUpdateNutrientOverrideValue(props.id, 0);
    } else {
      onRemoveNutrientOverride(props.id);
      setNoteValue("");
    }
  };

  const overrideValueCell: ReactNode = (
    <div className={classes.override} data-cy="overridesInput">
      <FoodWorksNumberInput
        setValue={setOverrideValue}
        inputProps={{
          value: overrideValue,
          onBlur: overrideOnBlur,
        }}
      />
    </div>
  );

  const noteOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    onUpdateNutrientOverrideNote(props.id, event.target.value);
  };

  const noteCell: ReactNode = (
    <FoodWorksTextInput
      maxLength={LARGE_FIELD}
      disabled={!isOverrideDefined()}
      className={`${classes.numeric} ${classes.noteInput}`}
      value={noteValue}
      onChange={(event) => setNoteValue(event.target.value)}
      onBlur={noteOnBlur}
      data-cy="overridesNote"
    />
  );

  const resultSpanStyle: React.CSSProperties = {
    color: isResultDefined()
      ? nutrientValue.displayValue !== defaultValue
        ? appTheme.colors.primary
        : appTheme.colors.xiketic
      : appTheme.colors.warning,
  };

  const resultValueCell: ReactNode = (
    <FoodworksTooltip
      placement="top"
      title={
        resultCellSpanRef.current &&
        isElementOverflowed(resultCellSpanRef.current!)
          ? `${nutrientValue.displayValue}`
          : ""
      }
    >
      <div
        ref={resultCellSpanRef}
        style={resultSpanStyle}
        className={classes.result}
        data-cy="overridesResult"
      >
        {nutrientValue.displayValue}
      </div>
    </FoodworksTooltip>
  );

  const nutrientDisplayValue = `${props.name} (${props.unit})`;

  return (
    <ListItem
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        background: hovered ? appTheme.colors.white[8] : "inherit",
      }}
      className={classes.row}
    >
      <div className={classes.name} data-cy="overridesName">
        <InfoTooltip title={props.description} />
        <Typography variant="body1">{nutrientDisplayValue}</Typography>
      </div>
      {showDefaultColumn ? defaultValueCell : null}
      {overrideValueCell}
      {resultValueCell}
      {noteCell}
    </ListItem>
  );
};

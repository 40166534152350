import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";

import { FoodWorksDate } from "../../../../../../data/models/documentProperties/date";
import { clientDateSelector } from "../../../../../../store/data/current_client/selectors/client";
import { RootState } from "../../../../../../store/reducers";

const useStyles = makeStyles(() => ({
  dateContainer: {
    display: "flex",
    alignItems: "center",
    width: 200,
    justifyContent: "space-between",
  },
  date: {
    marginLeft: 10,
  },
}));

const DisplayClientDate = React.memo(
  (): JSX.Element => {
    const clientDate: FoodWorksDate = useSelector<RootState, FoodWorksDate>(
      clientDateSelector
    );

    const classes = useStyles();

    return (
      <div>
        <div className={classes.dateContainer}>
          <Typography>Created</Typography>
          <Typography className={classes.date}>
            {clientDate.created
              ? new Date(clientDate.created).toLocaleDateString()
              : ""}
          </Typography>
        </div>
        <div className={classes.dateContainer}>
          <Typography>Last modified</Typography>
          <Typography className={classes.date}>
            {clientDate.lastModified
              ? new Date(clientDate.lastModified).toLocaleDateString()
              : ""}
          </Typography>
        </div>
      </div>
    );
  }
);

export default DisplayClientDate;

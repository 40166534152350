import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  is24HourRecall,
  isFoodRecord,
  isMealPlan,
} from "../../../../../../constants/FoodTemplate";
import { Document } from "../../../../../../data/models/document";
import { getIdentifier } from "../../../../../../data/models/documentProperties/foodId";
import { DocumentSummary } from "../../../../../../data/models/userDatabase";
import { DocumentMap } from "../../../../../../store/data/reducers/documentCache";
import { databaseIdSelector } from "../../../../../../store/data/selectors/database";
import { allCachedDocumentsSelector } from "../../../../../../store/data/selectors/documentCache";
import { RootState } from "../../../../../../store/reducers";
import useClientDocuments from "../../analysis/graphs/hooks/useClientDocs";
import { RowData } from "../components/DocumentTable";

export type Order = "asc" | "desc";

export type SortingMethod =
  | "DATE_RANGE"
  | "DOCUMENT_NAME"
  | "DOCUMENT_TYPE"
  | "DATE_CREATED"
  | "DAY_COUNT";

const sortOnName = (rows: RowData[], order: Order): RowData[] =>
  rows.sort((a, b) =>
    order === "asc"
      ? b.name.localeCompare(a.name)
      : a.name.localeCompare(b.name)
  );

const sortOnType = (rows: RowData[], order: Order): RowData[] =>
  rows.sort((a, b) =>
    order === "asc"
      ? b.documentType.localeCompare(a.documentType)
      : a.documentType.localeCompare(b.documentType)
  );

const sortOnCreatedDate = (rows: RowData[], order: Order): RowData[] =>
  rows.sort((a, b) =>
    order === "asc"
      ? new Date(b.createdDate)
          .toISOString()
          .localeCompare(new Date(a.createdDate).toISOString())
      : new Date(a.createdDate)
          .toISOString()
          .localeCompare(new Date(b.createdDate).toISOString())
  );

const sortOnDateRange = (rows: RowData[], order: Order): RowData[] => {
  return rows.sort((a, b) => {
    if (order === "asc") {
      if (!b.startDate || !b.endDate) {
        return 1;
      }

      if (!a.startDate || !a.endDate) {
        return -1;
      }

      return (
        a.startDate.localeCompare(b.startDate) ||
        a.endDate.localeCompare(b.endDate)
      );
    }

    if (!b.startDate || !b.endDate) {
      return -1;
    }

    if (!a.startDate || !a.endDate) {
      return 1;
    }

    return (
      b.startDate.localeCompare(a.startDate) ||
      b.endDate.localeCompare(a.endDate)
    );
  });
};

const sortOnCount = (rows: RowData[], order: Order): RowData[] => {
  return rows.sort((a, b) =>
    order === "asc"
      ? a.dayCount < b.dayCount
        ? -1
        : a.dayCount > b.dayCount
        ? 1
        : 0
      : b.dayCount < a.dayCount
      ? -1
      : b.dayCount > a.dayCount
      ? 1
      : 0
  );
};

const getRecordDocuments = (summaries: DocumentSummary[]): DocumentSummary[] =>
  summaries.filter(
    (summary: DocumentSummary) =>
      isFoodRecord(summary.templateId) || is24HourRecall(summary.templateId)
  );

const getPlanDocuments = (summaries: DocumentSummary[]): DocumentSummary[] =>
  summaries.filter((summary: DocumentSummary) =>
    isMealPlan(summary.templateId)
  );

const getDocumentTypeName = (templateId: string): string => {
  if (isFoodRecord(templateId)) {
    return "Food record";
  }

  if (is24HourRecall(templateId)) {
    return "24 hour recall";
  }

  return "Meal plan";
};

const getDateRange = (document: Document): [string, string] => {
  const startDate: string = document.days[0].date
    ? new Date(document.days[0].date).toISOString()
    : "";
  const endDate: string = document.days[document.days.length - 1].date
    ? new Date(document.days[document.days.length - 1].date).toISOString()
    : "";

  return [startDate, endDate];
};

const getRecordData = (
  summaries: DocumentSummary[],
  documentCache: DocumentMap,
  databaseId: string
): RowData[] => {
  const rowData: RowData[] = [];
  for (const summary of summaries) {
    const document: Document | undefined =
      documentCache[getIdentifier(databaseId, summary.documentId)];

    if (!document) continue;

    const [startDate, endDate] = getDateRange(document);

    rowData.push({
      documentId: summary.documentId,
      documentType: getDocumentTypeName(summary.templateId),
      name: summary.label,
      createdDate: new Date(document.date.created).toISOString(),
      startDate,
      endDate,
      dayCount: document.days.length,
    });
  }
  return rowData;
};

const getFilteredRecordData = (
  entries: RowData[],
  searchText: string
): RowData[] =>
  entries.filter((data: RowData) =>
    data.name.toLowerCase().includes(searchText.toLowerCase())
  );

const useDocumentData = (
  searchText: string,
  type: "Record" | "Plan",
  order: Order,
  method: SortingMethod
): RowData[] => {
  const documentCache: DocumentMap = useSelector<RootState, DocumentMap>(
    allCachedDocumentsSelector
  );

  const currentDatabaseId: string = useSelector<RootState, string>(
    databaseIdSelector
  );

  const clientDocuments = useClientDocuments();

  const foodRecordData = useMemo(
    () =>
      getFilteredRecordData(
        getRecordData(
          type === "Record"
            ? getRecordDocuments(clientDocuments)
            : getPlanDocuments(clientDocuments),
          documentCache,
          currentDatabaseId
        ),
        searchText
      ),
    [clientDocuments, documentCache, currentDatabaseId, searchText, type]
  );

  switch (method) {
    case "DOCUMENT_NAME":
      return sortOnName(foodRecordData, order);
    case "DOCUMENT_TYPE":
      return sortOnType(foodRecordData, order);
    case "DATE_CREATED":
      return sortOnCreatedDate(foodRecordData, order);
    case "DATE_RANGE":
      return sortOnDateRange(foodRecordData, order);
    case "DAY_COUNT":
      return sortOnCount(foodRecordData, order);
    default:
      return foodRecordData;
  }
};

export default useDocumentData;

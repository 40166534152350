import React, { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";

import {
  DatabaseProperties,
  UserDatabaseSummary,
} from "../../../data/models/userDatabase";
import { sortedUserDatabaseSummariesSelector } from "../../../store/data/selectors/user";
import { RootState } from "../../../store/reducers";
import { appTheme } from "../../../styling/style";
import { BaseDialog } from "../BaseDialog";
import { databaseIdSelector } from "../../../store/data/selectors/database";
import { CopyDatabaseProperty } from "../../common/CopyPropertiesButton";
import { clientUserDatabaseSelector } from "../../../store/data/selectors/clientDatabase";

const useStyles = makeStyles((theme) => ({
  components: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0px",
    height: 300,
  },
  componentRootContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  sectionContainer: {
    margin: "10px 0px",
  },
  listContainer: {
    overflow: "auto",
    flex: "1",
  },
  listButton: {
    borderRadius: 4,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
    padding: "0px",
    alignItems: "center",
  },
  selectedListButton: {
    borderRadius: 4,
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
    padding: "0px",
    alignItems: "center",
  },
}));

interface CopyPropertiesDialogProps {
  open: boolean;
  onClose: () => void;
  fetchDatabaseProperties: (ids: string[]) => void;
  databaseProperty: CopyDatabaseProperty;
}

export const CopyPropertiesDialog = ({
  open,
  onClose,
  databaseProperty,
  fetchDatabaseProperties,
}: CopyPropertiesDialogProps): JSX.Element => {
  const classes = useStyles();

  const onUpdateDatabaseProperties = (
    databaseProperties: DatabaseProperties
  ) => {
    switch (databaseProperty) {
      case CopyDatabaseProperty.ENABLED_MEASURES:
        fetchDatabaseProperties(databaseProperties.enabledMeasures);
        break;
      case CopyDatabaseProperty.ENABLED_NUTRIENTS:
        fetchDatabaseProperties(databaseProperties.enabledNutrients);
        break;
      case CopyDatabaseProperty.ENABLED_DATASOURCES:
        fetchDatabaseProperties(databaseProperties.enabledDatasources);
        break;
    }
  };

  const currentDatabaseId: string =
    useSelector<RootState, string>(databaseIdSelector);

  const userDatabaseSummaries: UserDatabaseSummary[] = useSelector<
    RootState,
    UserDatabaseSummary[]
  >(sortedUserDatabaseSummariesSelector);
  const clientDatabaseId = useSelector<RootState, string>(
    clientUserDatabaseSelector
  );

  const [selectedDatabaseId, setSelectedDatabaseId] = useState<string>("");

  const resetLocalState = () => setSelectedDatabaseId("");

  const applyChanges = () => {
    const selectedSummary: UserDatabaseSummary | undefined =
      userDatabaseSummaries.find(
        (summary: UserDatabaseSummary) => summary.id === selectedDatabaseId
      );

    if (selectedSummary)
      onUpdateDatabaseProperties(selectedSummary.copiedDatabaseProperties);

    resetLocalState();
    onClose();
  };

  const handleClose = () => {
    resetLocalState();
    onClose();
  };

  const handleDatabaseOnClick = (databaseId: string) =>
    databaseId === selectedDatabaseId
      ? setSelectedDatabaseId("")
      : setSelectedDatabaseId(databaseId);

  const databaseList: ReactNode = (
    <List>
      {userDatabaseSummaries.map(
        (userDatabase: UserDatabaseSummary): ReactNode =>
          currentDatabaseId !== userDatabase.id &&
          clientDatabaseId !== userDatabase.id && (
            <ListItem
              key={userDatabase.id}
              button
              onClick={() => handleDatabaseOnClick(userDatabase.id)}
              className={
                selectedDatabaseId === userDatabase.id
                  ? classes.selectedListButton
                  : classes.listButton
              }
            >
              <Checkbox
                data-cy="databaseCheckbox"
                checked={selectedDatabaseId === userDatabase.id}
              />
              <Typography>{userDatabase.name}</Typography>
            </ListItem>
          )
      )}
    </List>
  );

  const databaseComponent: ReactNode = [
    <Typography
      key="copyPropertiesDialog-yourDatabases"
      className={classes.sectionContainer}
    >
      Your databases
    </Typography>,
    <Card
      key="copyPropertiesDialog-databaseComponentCard"
      data-cy="databaseList"
      className={classes.listContainer}
    >
      {databaseList}
    </Card>,
  ];

  const body: ReactNode = (
    <div className={classes.componentRootContainer}>
      <Typography>Copy database properties from your other Foodworks databases.</Typography>
      <br/>
      <div className={classes.components}> {databaseComponent}</div>
    </div>
  );

  const action: ReactNode = [
    <Button
      key="copyProp-cancel"
      data-cy="copyPropCancel"
      onClick={handleClose}
    >
      Cancel
    </Button>,
    <Button key="copyProp-apply" data-cy="copyPropApply" onClick={applyChanges}>
      Apply
    </Button>,
  ];

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title="Import database properties"
      body={body}
      action={action}
      maxWidth="sm"
      dataCy="copyPropDialog"
    />
  );
};

import React, { ReactNode, useCallback, useRef, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { MoreHoriz } from "@material-ui/icons";

import { DocumentSummary } from "../../../../data/models/userDatabase";
import { CurrentDocumentIdSelector } from "../../../../store/data/current-document/selectors/currentDocument";
import { currentClientIdSelector } from "../../../../store/data/selectors/clientDatabase";
import { RootState } from "../../../../store/reducers";
import { handleRouteChange } from "../../../../store/ui/thunks/routing";
import { appTheme } from "../../../../styling/style";
import { getClientDocumentRouteData } from "../../../../data/routing/routing";
import NavigationListButton from "../shared/NavigationListButton";
import { DocumentItemMenu } from "../document/DocumentItemMenu";

const useStyles = makeStyles(() => ({
  selected: {
    backgroundColor: appTheme.colors.oceanBlue[0],
  },
  root: {
    display: "flex",
    width: "100%",
    alignContent: "center",
    height: 30,
    justifyContent: "space-between",
    marginLeft: "-10px",
  },
  menuIconButton: {
    color: "grey",
  },
  flex: {
    display: "flex",
  },
  iconButton: {
    width: 40,
    borderRadius: 4,
  },
  listButton: {
    width: "100%",
  },
}));

interface ClientNavigationListItemProps {
  documentSummary: DocumentSummary;
  showTags: boolean;
}

const ClientNavigationListItem = ({
  documentSummary,
  showTags,
}: ClientNavigationListItemProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentClientId: string = useSelector<RootState, string>(
    currentClientIdSelector
  );
  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const onNavigateToDocument = useCallback(
    (documentId: string) =>
      dispatch(
        handleRouteChange(
          getClientDocumentRouteData(currentClientId, documentId)
        )
      ),
    [dispatch, currentClientId]
  );

  const [hovered, setHovered] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const rootDivRef = useRef<HTMLDivElement | null>(null);

  const onHoverButtons: ReactNode = (
    <div className={classes.flex}>
      <IconButton
        size="small"
        className={classes.iconButton}
        onClick={() => setMenuAnchorEl(rootDivRef.current)}
      >
        <MoreHoriz className={classes.menuIconButton} />
      </IconButton>
    </div>
  );

  return (
    <div
      ref={rootDivRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={
        documentSummary.documentId === currentDocumentId
          ? `${classes.root} ${classes.selected}`
          : classes.root
      }
    >
      <span className={classes.listButton}>
        <NavigationListButton
          id={documentSummary.documentId}
          label={documentSummary.label}
          isSelected={documentSummary.documentId === currentDocumentId}
          type="Document"
          onClick={onNavigateToDocument}
          showTags={showTags}
        />
      </span>

      {hovered && onHoverButtons}
      {!!menuAnchorEl && (
        <DocumentItemMenu
          anchorElement={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          documentSummary={documentSummary}
        />
      )}
    </div>
  );
};

export default ClientNavigationListItem;

import React, { FunctionComponent, ReactNode } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { makeStyles, IconButton, Tooltip } from "@material-ui/core";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import { Block } from "@material-ui/icons";

import { RootState } from "../../../../../../../../store/reducers";
import { selectedRowsSelector } from "../../../../../../../../store/ui/selectors/recipeGrid";
import { IngredientRowDelete } from "./IngredientRowDelete";
import { IngredientRowCopy } from "./IngredientRowCopy";
import { FoodItemPosition } from "../../../../../../../../data/models/foodItemPosition";
import { NutrientValueSelection } from "./NutrientValueSelection";
import { FoodworksTooltip } from "../../../../../../../common/InfoTooltip";
import { setSelectedSectionTags } from "../../../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import {
  redoDocument,
  undoDocument,
} from "../../../../../../../../store/ui/actionCreators/documentEditing";
import {
  futureRedoHistorySelector,
  pastUndoHistorySelector,
} from "../../../../../../../../store/ui/selectors/documentEditing";
import { Day } from "../../../../../../../../data/models/documentProperties/day";
import useUpdateSelection from "../../hooks/useUpdateSelection";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },

  toolbarIcons: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    margin: 5,
  },
  filterOption: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  selection: {
    width: 300,
  },
  leftButtonsContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export interface RecipeGridToolbarProps {
  dayIndex: number;
}

export const RecipeGridToolbar: FunctionComponent<RecipeGridToolbarProps> = ({
  dayIndex,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateSelection = useUpdateSelection();

  const pastUndoHistory = useSelector<RootState, Array<Day[]>>(
    pastUndoHistorySelector
  );

  const futureUndoHistory = useSelector<RootState, Array<Day[]>>(
    futureRedoHistorySelector
  );

  const onResetSelection = () =>
    batch(() => {
      updateSelection([])
      dispatch(setSelectedSectionTags([]));
    });

  const selectedRows: FoodItemPosition[] = useSelector<
    RootState,
    FoodItemPosition[]
  >(selectedRowsSelector);

  const clearSelectionButton: ReactNode = (
    <FoodworksTooltip title="Clear selection">
      <span>
        <IconButton
          size="small"
          onClick={onResetSelection}
          disabled={!selectedRows.length}
        >
          <Block />
        </IconButton>
      </span>
    </FoodworksTooltip>
  );

  const undo = () => {
    dispatch(undoDocument());
  };

  const redo = () => {
    dispatch(redoDocument());
  };

  const undoButton: ReactNode = (
    <Tooltip title="Undo">
      <span>
        <IconButton
          disabled={!pastUndoHistory.length}
          aria-label="undo"
          onClick={undo}
        >
          <UndoIcon />
        </IconButton>
      </span>
    </Tooltip>
  );

  const redoButton: ReactNode = (
    <Tooltip title="Redo">
      <span>
        <IconButton disabled={!futureUndoHistory.length} onClick={redo}>
          <RedoIcon />
        </IconButton>
      </span>
    </Tooltip>
  );

  const toolbarButtons = (
    <div className={classes.toolbarIcons}>
      <div className={classes.leftButtonsContainer}>
        <IngredientRowDelete dayIndex={dayIndex} rowsToRemove={selectedRows} />
        <IngredientRowCopy dayIndex={dayIndex} selectedRows={selectedRows} />
        {clearSelectionButton}
        {undoButton}
        {redoButton}
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {toolbarButtons}
      <NutrientValueSelection />
    </div>
  );
};

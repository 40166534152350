import { createSelector } from "reselect";

import {
  FoodItemPosition,
  FoodItemPositionState,
} from "../../../data/models/foodItemPosition";
import { RootState } from "../../reducers";

const selectedRowsStateSelector = (
  rootState: RootState
): FoodItemPositionState[] => rootState.recipeGrid.selectedRows;

export const selectedRowsSelector = createSelector(
  selectedRowsStateSelector,
  (selectedRows) => {
    return selectedRows.map(
      (position: FoodItemPositionState): FoodItemPosition =>
        FoodItemPosition.fromObject(position)
    );
  }
);

export const currentDaySelector = (rootState: RootState): number =>
  rootState.recipeGrid.currentDay;

export const displayNutrientValuesSelector = (rootState: RootState): boolean =>
  rootState.recipeGrid.displayNutrientValues;

export const lockNutrientColumnsSelector = (state: RootState): boolean =>
  state.recipeGrid.lockNutrientColumns;

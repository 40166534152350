import { Action } from "redux";

import { ClipboardType } from "../reducers/clipboardReducers";

export const SET_CLIPBOARD = "SET_CLIPBOARD";

export interface IActionsSetClipboard extends Action {
  type: "SET_CLIPBOARD";
  clipboardData: ClipboardType;
}

export type ClipboardActions = IActionsSetClipboard;

import React, { FunctionComponent, ReactNode } from "react";

import {
  Card,
  List,
  ListItem,
  Button,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";

import { appTheme } from "../../../../styling/style";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      overflow: "auto",
      flex: 1,
    },
    list: {
      flexDirection: "column",
      height: 700,
    },
    listButton: {
      color: appTheme.colors.xiketic,
      width: "100%",
      display: "flex",
      textTransform: "none",
    },
    selectedListButton: {
      backgroundColor: appTheme.colors.oceanBlue[0],
      color: appTheme.colors.primary,
      "&:hover": {
        backgroundColor: appTheme.colors.oceanBlue[0],
        borderColor: appTheme.colors.oceanBlue[0],
        boxShadow: "none",
        color: appTheme.colors.primary,
      },
    },
    item: {
      padding: 0,
      textTransform: "none",
    },
  })
);

export interface RetentionFactorListItem {
  id: string;
  name: string;
}

interface RetentionFactorListBoxProps {
  selectedOption: string | null;
  selectOption: (id: string) => void;
  listItems: RetentionFactorListItem[];
  searchInput?: string;
}

export const RetentionFactorListBox: FunctionComponent<RetentionFactorListBoxProps> = ({
  selectedOption,
  selectOption,
  listItems,
  searchInput,
}) => {
  const classes = useStyles();

  const createListButton = (id: string, name: string): ReactNode => (
    <Button
      key={name}
      color="secondary"
      className={
        id === selectedOption
          ? `${classes.listButton} ${classes.selectedListButton}`
          : classes.listButton
      }
      onClick={() => selectOption(id)}
    >
      <ListItem className={classes.item}> {name}</ListItem>
    </Button>
  );

  const createListItems = (): ReactNode[] => {
    const filteredListItems = searchInput
      ? listItems.filter((item: RetentionFactorListItem): boolean =>
          item.name.toLowerCase().includes(searchInput.toLowerCase())
        )
      : listItems;

    return filteredListItems.map(
      (item: RetentionFactorListItem): ReactNode =>
        createListButton(item.id, item.name)
    );
  };

  return (
    <Card className={classes.listContainer}>
      <List className={classes.list}>{createListItems()}</List>
    </Card>
  );
};

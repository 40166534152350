import React, { ReactNode, useState } from "react";
import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { MeasurementCategory } from "../../../../../../data/models/clientProperties/measurement";
import { appTheme } from "../../../../../../styling/style";
import { BaseDialog } from "../../../../../dialogs/BaseDialog";
import NewMeasurementInput from "./NewMeasurementInput";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    textAlign: "left",
    height: "100%",
    color: appTheme.colors.primary,
  },
  addIcon: {
    color: appTheme.colors.primary,
  },
}));

interface CreateNewMeasurementProps {
  category: MeasurementCategory;
}

const CreateNewMeasurement = (props: CreateNewMeasurementProps) => {
  const classes = useStyles();

  const { category } = { ...props };

  const [open, setOpen] = useState<boolean>(false);

  const dialogBody: ReactNode = (
    <NewMeasurementInput category={category} close={() => setOpen(false)} />
  );

  return (
    <>
      <BaseDialog
        open={open}
        onClose={() => setOpen(false)}
        title={`Create new ${category.toLowerCase()} measurement`}
        body={dialogBody}
        maxWidth="lg"
        fullWidth={false}
      />
      <Button onClick={() => setOpen(true)} className={classes.button}>
        <Add className={classes.addIcon} fontSize={"inherit"} />
        <Typography>Create new</Typography>
      </Button>
    </>
  );
};

export default CreateNewMeasurement;

import { Action } from "redux";

import { Client, ClientSummary } from "../../../data/models/client";
import { Tag } from "../../../data/models/documentProperties/section";
import { NutrientReferenceValue } from "../../../data/models/nutrientReferenceValues";
import { ClientActions } from "../current_client/actions/client";

export const SET_CLIENT_ID = "SET_CLIENT_ID";
export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";
export const SET_SERVER_CLIENT = "SET_SERVER_CLIENT";
export const SET_CLIENT_DATABASE_ID = "SET_CLIENT_DATABASE_ID";
export const SET_CLIENT_SUMMARIES = "SET_CLIENT_SUMMARIES";
export const ADD_CLIENT_SUMMARY = "ADD_CLIENT_SUMMARY";
export const REMOVE_CLIENT_SUMMARY = "REMOVE_CLIENT_SUMMARY";
export const UPDATE_CLIENT_SUMMARY = "UPDATE_CLIENT_SUMMARY";
export const SET_CURRENT_CLIENT_NRV = "SET_CURRENT_CLIENT_NRV";
export const SET_CLIENT_DATABASE_TAGS = "SET_CLIENT_DATABASE_TAGS";

export interface IActionsSetClientId extends Action {
  type: "SET_CLIENT_ID";
  clientId: string;
}

export interface IActionsSetCurrentClient extends Action {
  type: "SET_CURRENT_CLIENT";
  client: Client;
}
export interface IActionsSetServerClient extends Action {
  type: "SET_SERVER_CLIENT";
  client: Client | undefined;
}

export interface IActionsSetClientDatabaseId extends Action {
  type: "SET_CLIENT_DATABASE_ID";
  databaseId: string;
}

export interface IActionsSetClientSummaries extends Action {
  type: "SET_CLIENT_SUMMARIES";
  summaries: ClientSummary[];
}

export interface IActionsAddClientSummary extends Action {
  type: "ADD_CLIENT_SUMMARY";
  summary: ClientSummary;
}

export interface IActionsRemoveClientSummary extends Action {
  type: "REMOVE_CLIENT_SUMMARY";
  clientId: string;
}

export interface IActionsUpdateClientSummary extends Action {
  type: "UPDATE_CLIENT_SUMMARY";
  summary: ClientSummary;
}

export interface IActionsSetCurrentClientNRV extends Action {
  type: "SET_CURRENT_CLIENT_NRV";
  values: NutrientReferenceValue[];
}

export interface IActionsSetClientDatabaseTags extends Action {
  type: "SET_CLIENT_DATABASE_TAGS";
  tags: Tag[];
}

export type ClientDatabaseActions =
  | IActionsSetClientId
  | IActionsSetCurrentClient
  | IActionsSetServerClient
  | IActionsSetClientDatabaseId
  | IActionsSetClientSummaries
  | IActionsAddClientSummary
  | IActionsRemoveClientSummary
  | IActionsUpdateClientSummary
  | IActionsSetCurrentClientNRV
  | IActionsSetClientDatabaseTags
  | ClientActions;

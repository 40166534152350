import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";

import FoodworksSelect from "../../../../../../common/FoodworksSelect";
import useClientEER from "../hooks/useClientEER";
import { RootState } from "../../../../../../../store/reducers";
import {
  clientIsUseCER,
  clientRestingMetabolicRateSelector,
} from "../../../../../../../store/data/current_client/selectors/clientProfile";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 0px",
  },
  label: {
    width: "60%",
  },
  value: {
    marginLeft: 15,
    marginRight: 15,
    width: 80,
  },
}));

const EERDisplay = React.memo((): JSX.Element => {
  const classes = useStyles();

  const useCER: boolean = useSelector<RootState, boolean>(clientIsUseCER);
  const restingMetabolicRate: number | undefined = useSelector<
    RootState,
    number | undefined
  >(clientRestingMetabolicRateSelector);
  const eer: string = useClientEER();

  const energy: string = useCER
    ? restingMetabolicRate
      ? restingMetabolicRate.toFixed(0) + " kJ"
      : "?"
    : eer;

  return (
    <div className={classes.root}>
      <Typography className={classes.label} noWrap>
        {useCER
          ? "Custom Energy Requirement"
          : "Estimated Energy Requirement (EER)"}
      </Typography>
      <Typography align="right" className={classes.value} color="secondary">
        {energy}
      </Typography>
      <FoodworksSelect<"Schofield">
        options={["Schofield"]}
        value="Schofield"
        onChange={() => {}}
      />
    </div>
  );
});

export default EERDisplay;

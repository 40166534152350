import { call, ForkEffect, put, select, takeLatest } from "redux-saga/effects";

import { CalculationMethod } from "../../../constants/calculationMethod";
import {
  is24HourRecall,
  isFoodRecord,
  isMealPlan,
  isRecipe,
} from "../../../constants/FoodTemplate";
import {
  Composition,
  CompositionCache,
} from "../../../data/models/composition";
import { CompositionCalculator } from "../../../data/models/compositionCalculator";
import { Document } from "../../../data/models/document";
import { FoodItem } from "../../../data/models/documentProperties/foodItem";
import { RetentionFactor } from "../../../data/models/documentProperties/retentionFactor";
import { ReferenceMeasure } from "../../../data/models/referenceMeasure";
import { addComposition } from "../action-creators/compositionCache";
import {
  FETCH_COMPOSITION,
  IActionsFetchComposition,
} from "../actions/compositionCache";
import { DocumentMap } from "../reducers/documentCache";
import { compositionCacheSelector } from "../selectors/compositionCache";
import { allCachedDocumentsSelector } from "../selectors/documentCache";
import {
  getRetentionFactorMap,
  ReferenceMeasuresSelector,
} from "../selectors/referenceData";
import { getFoodItemsForComposition } from "./composition";

const actionsToListenTo = [FETCH_COMPOSITION];

export const getRecipeCompositionPer100g = (
  document: Document,
  foodItems: FoodItem[],
  compositionCalculator: CompositionCalculator
): Composition => {
  const composition = new Composition({});
  composition.addComposition(
    compositionCalculator.calculateRecipe(
      foodItems,
      document.yield,
      document.nutrientOverrides
    )
  );

  const weightFactor = 100 / composition.weight;
  composition.multiplyByFactor(weightFactor);
  return composition;
};

export const getPlanCompositionPer100g = (
  foodItems: FoodItem[],
  compositionCalculator: CompositionCalculator
): Composition => {
  const composition = new Composition({});
  composition.addComposition(
    compositionCalculator.calculateCompositionFromFoodItems(foodItems)
  );

  const weightFactor = 100 / composition.weight;
  composition.multiplyByFactor(weightFactor);
  return composition;
};

export const getMappedFoodComposition = (
  document: Document,
  compositionCalculator: CompositionCalculator,
  compositionCache: CompositionCache,
  documentCache: DocumentMap
): Composition => {
  if (compositionCache.hasComposition(document.documentMappingId)) {
    return compositionCalculator.calculateMappedFood(
      document.nutrientOverrides,
      compositionCache.getComposition(document.documentMappingId)!
    );
  }

  //TODO: [FWPW-478] throw an error if document not in document cache

  const mappedFoodComposition: Composition =
    compositionCalculator.calculateFood(
      documentCache[document.documentMappingId].nutrientOverrides
    );

  return compositionCalculator.calculateMappedFood(
    document.nutrientOverrides,
    mappedFoodComposition
  );
};

export const getCompositionForCache = (
  document: Document,
  foodItems: FoodItem[],
  compositionCalculator: CompositionCalculator,
  compositionCache: CompositionCache,
  documentCache: DocumentMap
): Composition | undefined => {
  if (
    is24HourRecall(document.templateId) ||
    isMealPlan(document.templateId) ||
    isFoodRecord(document.templateId)
  )
    return getPlanCompositionPer100g(foodItems, compositionCalculator);

  const calculateCompositionFromRecipe: boolean =
    document.calculationMethod === CalculationMethod.INGREDIENTS &&
    !!foodItems.length &&
    isRecipe(document.templateId);

  if (calculateCompositionFromRecipe) {
    return getRecipeCompositionPer100g(
      document,
      foodItems,
      compositionCalculator
    );
  }

  const isValidMappedFood: boolean =
    document.calculationMethod === CalculationMethod.MAPPED &&
    !!document.documentMappingId;

  if (isValidMappedFood) {
    return getMappedFoodComposition(
      document,
      compositionCalculator,
      compositionCache,
      documentCache
    );
  }
  return compositionCalculator.calculateFood(document.nutrientOverrides);
};

export function* fetchComposition(action: IActionsFetchComposition) {
  const retentionFactorMap: Map<string, RetentionFactor> = yield select(
    getRetentionFactorMap
  );

  const documentCache: DocumentMap = yield select(allCachedDocumentsSelector);

  const compositionCache: CompositionCache = yield select(
    compositionCacheSelector
  );

  const referenceMeasures: ReferenceMeasure[] = yield select(
    ReferenceMeasuresSelector
  );

  const compositionCalculator = new CompositionCalculator(
    compositionCache,
    documentCache,
    referenceMeasures,
    retentionFactorMap
  );
  //@ts-ignore
  const validFoodItems = yield call(
    getFoodItemsForComposition,
    compositionCalculator,
    action.document,
    []
  );

  compositionCalculator.getValidFoodItems(action.document, []);

  const calculatedComposition: Composition | undefined = yield call(
    getCompositionForCache,
    action.document,
    validFoodItems,
    compositionCalculator,
    compositionCache,
    documentCache
  );

  if (calculatedComposition) {
    yield put(addComposition(action.identifier, calculatedComposition.object));
  }
}

export function* fetchCompositionSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(actionsToListenTo, fetchComposition);
}

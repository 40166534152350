import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setClientEmail } from "../../../../../../../store/data/current_client/action_creators/client";
import { clientEmailSelector } from "../../../../../../../store/data/current_client/selectors/client";
import { RootState } from "../../../../../../../store/reducers";

const useClientEmail = (): [string, (input: string) => void, () => void] => {
  const dispatch = useDispatch();

  const email: string = useSelector<RootState, string>(clientEmailSelector);

  const [input, setInput] = useState(email);

  const onSetClientEmail = useCallback(() => dispatch(setClientEmail(input)), [
    dispatch,
    input,
  ]);

  useEffect(() => {
    setInput(email);
  }, [email]);

  return [input, setInput, onSetClientEmail];
};

export default useClientEmail;

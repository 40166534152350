import { Action } from "redux";

export const SET_UNSAVED_DOCUMENT_CHANGES = "SET_UNSAVED_DOCUMENT_CHANGES";
export const SET_UNSAVED_CLIENT_CHANGES = "SET_UNSAVED_CLIENT_CHANGES";

export interface IActionsSetUnsavedDocumentChanges extends Action {
  type: "SET_UNSAVED_DOCUMENT_CHANGES";
  openSaveDialog: boolean;
}

export interface IActionsSetUnsavedClientChanges extends Action {
  type: "SET_UNSAVED_CLIENT_CHANGES";
  openClientDialog: boolean;
}

export type DocumentSavingActions =
  | IActionsSetUnsavedDocumentChanges
  | IActionsSetUnsavedClientChanges;

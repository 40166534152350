import React from "react";
import { Checkbox } from "@material-ui/core";

import useClientIsLactating from "../hooks/useClientIsLactating";
import ProfileItem from "./ProfileItem";

const LactatingInput = React.memo((): JSX.Element => {
  const [lactating, updateLactating, male] = useClientIsLactating();

  return (
    <ProfileItem
      component={
        <Checkbox
          disabled={male}
          checked={lactating}
          onChange={(event) => updateLactating(event.target.checked)}
          color="secondary"
        />
      }
      label="Lactating"
      isEmptyInfoTooltip={male ? "Cannot select 'Lactating' for males" : ""}
      disabled={male}
    />
  );
});

export default LactatingInput;

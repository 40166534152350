import { ClientProfile } from "../../../../data/models/clientProperties/clientProfile";
import {
  ClientProfileActions,
  SET_CLIENT_ACTIVITY_LEVEL,
  SET_CLIENT_AGE,
  SET_CLIENT_CURRENT_WEIGHT,
  SET_CLIENT_DOB,
  SET_CLIENT_HEIGHT,
  SET_CLIENT_IS_LACTATING,
  SET_CLIENT_PREGNANCY_LEVEL,
  SET_CLIENT_RESTING_METABOLIC_RATE,
  SET_CLIENT_SEX,
  SET_USE_CER,
} from "../actions/clientProfile";

export type ClientProfileState = ClientProfile;

export const initialClientProfileState: ClientProfileState = {
  age: undefined,
  dob: "",
  weight: undefined,
  height: undefined,
  sex: "Female",
  pregnancyLevel: "None",
  isLactating: false,
  activityLevel: "Sedentary",
  restingMetabolicRate: undefined,
  useCER: false,
};

export const clientProfile = (
  state: ClientProfileState = initialClientProfileState,
  action: ClientProfileActions
): ClientProfileState => {
  switch (action.type) {
    case SET_CLIENT_AGE:
      return { ...state, age: action.age };
    case SET_CLIENT_DOB:
      return { ...state, dob: action.dob };
    case SET_CLIENT_SEX:
      return { ...state, sex: action.sex };
    case SET_CLIENT_CURRENT_WEIGHT:
      return { ...state, weight: action.weight };
    case SET_CLIENT_HEIGHT:
      return { ...state, height: action.height };
    case SET_CLIENT_ACTIVITY_LEVEL:
      return { ...state, activityLevel: action.activityLevel };
    case SET_CLIENT_PREGNANCY_LEVEL:
      return { ...state, pregnancyLevel: action.pregnancyLevel };
    case SET_CLIENT_IS_LACTATING:
      return { ...state, isLactating: action.isLactating };
    case SET_CLIENT_RESTING_METABOLIC_RATE:
      return { ...state, restingMetabolicRate: action.rate };
    case SET_USE_CER:
      return { ...state, useCER: action.useCER };
    default:
      return state;
  }
};

import firebase from "firebase";

import { Client, clientConverter, ClientSummary } from "../../models/client";
import {
  ClientDatabase,
  clientDatabaseConverter,
} from "../../models/clientDatabase";
import { getCurrentDate } from "../../models/documentProperties/date";
import { Tag } from "../../models/documentProperties/section";
import Firebase from "../firebase";

const CLIENT_DATABASES_DATABASE = "client-databases";

export class FirebaseClientDatabases {
  collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor(firestore: firebase.firestore.Firestore) {
    this.collection = firestore.collection(CLIENT_DATABASES_DATABASE);
  }

  userClientDatabase(
    clientId?: string
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    return clientId ? this.collection.doc(clientId) : this.collection.doc();
  }

  async doGetUserClientDatabase(
    clientDatabaseId: string
  ): Promise<ClientDatabase> {
    return this.userClientDatabase(clientDatabaseId)
      .withConverter(clientDatabaseConverter)
      .get()
      .then((data) => data.data()!);
  }

  async doCreateUserClientDatabase(databaseId: string): Promise<string> {
    const newUserDatabase = this.userClientDatabase();

    const newClientDatabase: ClientDatabase = {
      clientSummaries: [],
      databaseId: databaseId,
      properties: {},
      tags: [],
    };

    await newUserDatabase.set(newClientDatabase);

    return newUserDatabase.id;
  }

  async doGetClient(
    clientDatabaseId: string,
    clientId: string
  ): Promise<Client> {
    return (
      await this.userClientDatabase(clientDatabaseId)
        .collection("clients")
        .withConverter(clientConverter)
        .doc(clientId)
        .get()
    ).data()!;
  }

  async doCreateClient(
    clientDatabaseId: string,
    client: Client
  ): Promise<string> {
    return this.userClientDatabase(clientDatabaseId)
      .collection("clients")
      .withConverter(clientConverter)
      .add(client)
      .then((documentData) => documentData.id);
  }

  doUpdateClient(
    clientDatabaseId: string,
    clientId: string,
    client: Client
  ): Promise<void> {
    return this.userClientDatabase(clientDatabaseId)
      .collection("clients")
      .doc(clientId)
      .withConverter(clientConverter)
      .set(client, { merge: true });
  }

  async doCreateClientSummary(
    clientDatabaseId: string,
    clientId: string,
    client: Client
  ): Promise<ClientSummary> {
    const date: string = getCurrentDate();
    const clientSummary: ClientSummary = {
      label: client.name,
      clientId,
      date: { lastModified: date, created: date },
      tagIds: [],
      state: client.state,
    };

    return this.userClientDatabase(clientDatabaseId)
      .update({
        clientSummaries: firebase.firestore.FieldValue.arrayUnion(
          clientSummary
        ),
      })
      .then(() => clientSummary);
  }

  async doUpdateClientSummary(
    clientDatabaseId: string,
    previousSummary: ClientSummary,
    currentSummary: ClientSummary
  ): Promise<void> {
    const clientRef = this.userClientDatabase(clientDatabaseId);

    const batch = Firebase.batch();

    batch.update(clientRef, {
      clientSummaries: firebase.firestore.FieldValue.arrayRemove(
        previousSummary
      ),
    });

    batch.update(clientRef, {
      clientSummaries: firebase.firestore.FieldValue.arrayUnion(currentSummary),
    });

    await batch.commit();
  }

  async doUpdateClientSummaries(
    clientId: string,
    clientSummaries: ClientSummary[]
  ): Promise<void> {
    return this.userClientDatabase(clientId).update({
      clientSummaries,
    });
  }

  async doUpdateClientDatabaseTags(
    databaseId: string,
    tags: Tag[]
  ): Promise<void> {
    return this.userClientDatabase(databaseId).update({
      tags: tags,
    });
  }
}

import {
  DocumentScreenActions,
  SET_CURRENT_TAB,
} from "../actions/documentScreen";

export interface DocumentScreenState {
  currentTab: number;
}

export const initialDocumentScreenState: DocumentScreenState = {
  currentTab: 0,
};

export const documentScreen = (
  state: DocumentScreenState = initialDocumentScreenState,
  action: DocumentScreenActions
): DocumentScreenState => {
  switch (action.type) {
    case SET_CURRENT_TAB:
      return { ...state, currentTab: action.index };
    default:
      return state;
  }
};

import { useState } from "react";
import {
  createMuiTheme,
  IconButton,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import { AccessTimeRounded } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { appTheme } from "../../../styling/style";

const useStyles = makeStyles(() => ({
  hidden: {
    maxWidth: 0,
  },
}));

const materialCalendarTheme = createMuiTheme({
  overrides: {
    //@ts-ignore
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: appTheme.colors.secondary,
      },
    },
    MuiPickersDay: {
      day: {
        color: appTheme.colors.xiketic,
      },
      daySelected: {
        color: appTheme.colors.primary,
        "&:nth-child(1)": {
          color: "white",
        },
      },
      dayDisabled: {
        color: appTheme.colors.gainsbruh,
      },
      current: {
        color: appTheme.colors.primary,
      },
    },
  },
});

interface CalendarProps {
  date: Date | null;
  handleChange: (date: MaterialUiPickersDate) => void;
  disabled?: boolean;
  open: boolean;
  close: () => void;
}

interface FoodworksTimeDatePickerProps {
  date: Date | null;
  handleChange: (date: MaterialUiPickersDate) => void;
  disabled?: boolean;
}

const Calendar = (props: CalendarProps): JSX.Element => {
  const classes = useStyles();

  const { date, handleChange, disabled, open, close } = {
    ...props,
  };

  return (
    <div className={classes.hidden} hidden>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialCalendarTheme}>
          <KeyboardDateTimePicker
            open={open}
            disabled={disabled ? disabled : false}
            allowKeyboardControl
            disableFuture
            views={["hours", "minutes"]}
            size="medium"
            variant="dialog"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={date}
            onAccept={close}
            onClose={close}
            onChange={handleChange}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const FoodworksTimeDatePicker = (
  props: FoodworksTimeDatePickerProps
): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <IconButton color={"secondary"} onClick={() => setOpen(open => !open)}>
        <AccessTimeRounded />
      </IconButton>
      <Calendar open={open} close={() => setOpen(false)} {...props} />
    </>
  );
};

export default FoodworksTimeDatePicker;

import { ReactNode } from "react";

export class InvalidServeError extends Error {
  documentName: string;
  constructor(documentName: string) {
    super();

    this.documentName = documentName;

    Object.setPrototypeOf(this, InvalidServeError.prototype);
  }
}

export class InvalidVolumeConversionError extends Error {
  documentName: string;
  constructor(documentName: string) {
    super();

    this.documentName = documentName;

    Object.setPrototypeOf(this, InvalidVolumeConversionError.prototype);
  }
}

export class UnknownMeasureError extends Error {
  documentName: string;
  constructor(documentName: string) {
    super();

    this.documentName = documentName;

    Object.setPrototypeOf(this, UnknownMeasureError.prototype);
  }
}

export class UnknownDocumentError extends Error {
  documentIdentifier: string;
  constructor(documentIdentifier: string) {
    super();

    this.documentIdentifier = documentIdentifier;

    Object.setPrototypeOf(this, UnknownDocumentError.prototype);
  }
}

export interface FoodWorksError {
  code: number | null;
  message: string | null;
  description: ReactNode | null;
}

export class CyclicDependencyError implements FoodWorksError {
  code: number;
  message: string;
  description: ReactNode;
  documentId: string;

  constructor(recipeName: string, documentId: string) {
    this.code = 1000;
    this.message = `Error ${
      this.code
    } : Unable to calculate nutritional analysis for the recipe ${recipeName.toUpperCase()}.`;
    this.description = `A cyclic dependency has been detected within the recipe ${recipeName.toUpperCase()}`;
    this.documentId = documentId;
  }
}

export class DocumentSaveError implements FoodWorksError {
  code: number;
  message: string;
  description: ReactNode;

  constructor(description: ReactNode) {
    this.code = 1001;
    this.message = `Error ${this.code} : Unable to save`;
    this.description = description;
  }
}

export class MappedDocumentError implements FoodWorksError {
  code: number;
  message: string;
  description: ReactNode;

  constructor() {
    this.code = 1002;
    this.message = `Error ${this.code} : Problem mapping the composition of this document to another document`;
    this.description = `Please re-select a document to map this document to`;
  }
}

export class YieldWeightError implements FoodWorksError {
  code: number;
  message: string;
  description: ReactNode;

  constructor() {
    this.code = 1003;
    this.message = `Error ${this.code} : Yield factor is less than calculated dry weight. `;
    this.description = `Please enter a valid yield quantity`;
  }
}

export class UnknownCompositionError implements FoodWorksError {
  code: number;
  message: string;
  description: ReactNode;

  constructor() {
    this.code = 1004;
    this.message = `Error ${this.code} : An unknown error occurred while calculating the composition. `;
    this.description = "Please contact Foodworks support.";
  }
}

export class InvalidServeQuantityError implements FoodWorksError {
  code: number;
  message: string;
  description: ReactNode;

  constructor(documentName: string) {
    this.code = 1005;
    this.message = `Error ${this.code} : Food item "${documentName}" is using a serve measure with an invalid serve quantity defined. `;
    this.description =
      "Please fix or enter a valid measure for this food item.";
  }
}

export class InvalidVolumeQuantityError implements FoodWorksError {
  code: number;
  message: string;
  description: ReactNode;

  constructor(documentName: string) {
    this.code = 1006;
    this.message = `Error ${this.code} : Food item "${documentName}" is using a volume measure with an invalid volume conversion defined. `;
    this.description =
      "Please fix or enter a valid measure for this food item.";
  }
}

export class UnknownQuantityError implements FoodWorksError {
  code: number;
  message: string;
  description: ReactNode;

  constructor(documentName: string) {
    this.code = 1007;
    this.message = `Error ${this.code} : Food item "${documentName}" is using an invalid or deleted measure. `;
    this.description =
      "Please fix or enter a valid measure for this food item.";
  }
}

import { IngredientSummaryItem } from "../../../components/screens/databases/documents/tabs/ingredients/editing_grid/rows/cells/IngredientCell";
import {
  DatabaseActions,
  SET_USER_DOCUMENT_SUMMARIES,
  SET_DATABASE_ID,
  ADD_USER_DOCUMENT_SUMMARY,
  UPDATE_USER_DOCUMENT_SUMMARY,
  SET_DATABASE_NAME,
  SET_DATABASE_DESCRIPTION,
  SET_DATABASE_PROFILES,
  SET_SHARING_HISTORY,
  SET_DATABASE_PROPERTIES,
  SET_DATABASE_DOCUMENT_TAGS,
  SET_DATABASE,
} from "../actions/database";
import {
  Profile,
  DocumentSummary,
  SharingHistory,
} from "../../../data/models/userDatabase";
import {
  databaseProperties,
  DatabasePropertiesState,
  initialDatabasePropertiesState,
} from "./databaseProperties";
import {
  DatabasePropertyActions,
  SET_DISPLAYED_NUTRIENTS,
  SET_IS_ALTERNATE_IDS_ENABLED,
  SET_ENABLED_DATASOURCES,
  SET_ENABLED_MEASURES,
  SET_ENABLED_NUTRIENTS,
} from "../actions/databaseProperties";
import { Tag } from "../../../data/models/documentProperties/section";

export interface DatabaseSummaryMap {
  [key: string]: IngredientSummaryItem[];
}

export interface DatabaseState {
  databaseId: string;
  name: string;
  userDocumentSummaries: DocumentSummary[];
  databaseProperties: DatabasePropertiesState;
  description: string;
  profiles: Profile[];
  sharingHistory: SharingHistory[];
  documentTags: Tag[];
}

export const initialDatabaseState: DatabaseState = {
  databaseId: "",
  name: "",
  userDocumentSummaries: [],
  databaseProperties: initialDatabasePropertiesState,
  description: "",
  profiles: [],
  sharingHistory: [],
  documentTags: [],
};

export const database = (
  state: DatabaseState = initialDatabaseState,
  action: DatabaseActions | DatabasePropertyActions
): DatabaseState => {
  switch (action.type) {
    case SET_DATABASE: {
      return {
        ...state,
        databaseId: action.database.summary.id,
        name: action.database.summary.name,
        userDocumentSummaries: action.database.documentSummaries,
        databaseProperties: action.database.properties,
        description: action.database.description,
        profiles: action.database.profiles,
        sharingHistory: action.database.sharingHistory,
        documentTags: action.database.documentTags,
      };
    }
    case SET_USER_DOCUMENT_SUMMARIES:
      return { ...state, userDocumentSummaries: action.userDocumentSummaries };
    case SET_DATABASE_ID:
      return { ...state, databaseId: action.databaseId };
    case SET_DATABASE_NAME:
      return { ...state, name: action.name };
    case ADD_USER_DOCUMENT_SUMMARY:
      return {
        ...state,
        userDocumentSummaries: [
          ...state.userDocumentSummaries,
          action.documentSummary,
        ],
      };
    case UPDATE_USER_DOCUMENT_SUMMARY:
      return {
        ...state,
        userDocumentSummaries: [
          ...state.userDocumentSummaries.map(
            (item: DocumentSummary): DocumentSummary =>
              item.documentId === action.documentSummary.documentId
                ? action.documentSummary
                : item
          ),
        ],
      };
    case SET_ENABLED_DATASOURCES:
      return {
        ...state,
        databaseProperties: databaseProperties(
          state.databaseProperties,
          action
        ),
      };
    case SET_DISPLAYED_NUTRIENTS:
      return {
        ...state,
        databaseProperties: databaseProperties(
          state.databaseProperties,
          action
        ),
      };
    case SET_ENABLED_MEASURES:
      return {
        ...state,
        databaseProperties: databaseProperties(
          state.databaseProperties,
          action
        ),
      };
    case SET_DATABASE_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };
    case SET_ENABLED_NUTRIENTS:
      return {
        ...state,
        databaseProperties: databaseProperties(
          state.databaseProperties,
          action
        ),
      };
    case SET_IS_ALTERNATE_IDS_ENABLED:
      return {
        ...state,
        databaseProperties: databaseProperties(
          state.databaseProperties,
          action
        ),
      };
    case SET_DATABASE_PROFILES:
      return {
        ...state,
        profiles: action.profiles,
      };
    case SET_SHARING_HISTORY:
      return { ...state, sharingHistory: action.history };
    case SET_DATABASE_PROPERTIES:
      return { ...state, databaseProperties: action.databaseProperties };
    case SET_DATABASE_DOCUMENT_TAGS:
      return { ...state, documentTags: action.documentTags };
    default:
      return state;
  }
};

import { ThunkAction } from "redux-thunk";

import { IActionsUserLogout, userLogout } from "./actions";
import { RootState } from "./reducers";

import { ExtraArguments } from "./store";

export const signUserOut = (): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  IActionsUserLogout
> => async (dispatch, getState, { firebase, routing }) => {
  await firebase?.auth.signOut();
  dispatch(userLogout());
};

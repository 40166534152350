import React, { ReactNode, useCallback, useRef, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { MoreHoriz } from "@material-ui/icons";

import { ClientSummary } from "../../../../data/models/client";
import { handleRouteChange } from "../../../../store/ui/thunks/routing";
import { getClientRouteData } from "../../../../data/routing/routing";
import NavigationListButton from "../shared/NavigationListButton";
import { currentClientIdSelector } from "../../../../store/data/selectors/clientDatabase";
import { RootState } from "../../../../store/reducers";
import { ClientItemMenu } from "./ClientItemMenu";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-10px",
    flex: 1,
    justifyContent: "space-between",
  },
  listButton: {
    width: "100%",
  },
  flex: {
    display: "flex",
  },
  iconButton: {
    width: 40,
    borderRadius: 4,
  },
  menuIconButton: {
    color: "grey",
  },
}));

interface ClientDatabaseNavigationListItemProps {
  summary: ClientSummary;
  showTags: boolean;
}

const ClientDatabaseNavigationListItem = ({
  summary,
  showTags,
}: ClientDatabaseNavigationListItemProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentClientId: string = useSelector<RootState, string>(
    currentClientIdSelector
  );

  const [hovered, setHovered] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const rootDivRef = useRef<HTMLDivElement | null>(null);

  const onNavigateToClient = useCallback(
    (clientId: string) =>
      dispatch(handleRouteChange(getClientRouteData(clientId))),
    [dispatch]
  );

  const onHoverButtons: ReactNode = (
    <div className={classes.flex}>
      <IconButton
        size="small"
        className={classes.iconButton}
        onClick={() => setMenuAnchorEl(rootDivRef.current)}
      >
        <MoreHoriz className={classes.menuIconButton} />
      </IconButton>
    </div>
  );

  return (
    <div
      ref={rootDivRef}
      className={classes.root}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <span className={classes.listButton}>
        <NavigationListButton
          id={summary.clientId}
          label={summary.label}
          type="Client"
          onClick={onNavigateToClient}
          isSelected={summary.clientId === currentClientId}
          showTags={showTags}
        />
      </span>

      {hovered && onHoverButtons}
      {!!menuAnchorEl && (
        <ClientItemMenu
          anchorElement={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          clientSummary={summary}
        />
      )}
    </div>
  );
};

export default ClientDatabaseNavigationListItem;

import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { appTheme } from "../../../../../../styling/style";
import { MethodContents } from "../../../../../../data/models/document";

const useStyles = makeStyles((theme: any) => ({
  addSectionButton: {
    color: appTheme.colors.xiketic,
  },
  addIcon: {
    color: appTheme.colors.success,
  },
}));

interface MethodAddSectionButtonProps {
  nextIndex: number;
  isOnlySection: boolean;
  addMethodSection: (methodSection: MethodContents) => void;
}

export const MethodAddSectionButton = ({
  isOnlySection,
  nextIndex,
  addMethodSection,
}: MethodAddSectionButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      data-cy="addSection"
      className={classes.addSectionButton}
      startIcon={<AddIcon className={classes.addIcon} />}
      onClick={() =>
        addMethodSection({
          title: "New section",
          index: nextIndex,
          data: "",
          isCollapsed: false,
        })
      }
      size="small"
    >
      {isOnlySection ? "Create sections" : "Add section"}
    </Button>
  );
};

export enum YieldType {
  WEIGHT = "Yield weight",
  PERCENT = "Yield percent",
}

export interface Yield {
  type: YieldType;
  amount: number | undefined;
}

export const yieldConverter = {
  toFirestore: (
    yieldValue: Yield | undefined
  ): firebase.firestore.DocumentData => {
    return !yieldValue?.amount
      ? { amount: null, type: yieldValue?.type }
      : {
          amount: yieldValue.amount,
          type: yieldValue.type,
        };
  },

  fromFirestore: (data: firebase.firestore.DocumentData): Yield => {
    if (!data) {
      return { type: YieldType.PERCENT, amount: undefined };
    }
    return data?.amount === null
      ? { type: data.type, amount: undefined }
      : {
          amount: data.amount,
          type: data.type,
        };
  },
};

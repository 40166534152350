import React, { FunctionComponent, useState, ReactNode, useRef } from "react";
import {
  makeStyles,
  createStyles,
  Popper,
  ButtonBase,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { batch, useDispatch } from "react-redux";

import { appTheme } from "../../../../../../../styling/style";
import { addFoodItemsFromIndex } from "../../../../../../../store/data/current-document/thunks/foodItems";
import { FoodItem } from "../../../../../../../data/models/documentProperties/foodItem";
import { setSelectedSectionTags } from "../../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import useUpdateSelection from "../hooks/useUpdateSelection";

const useStyles = makeStyles(() =>
  createStyles({
    buttonBase: {
      background: appTheme.colors.primary,
      borderRadius: 5,
    },
    invisiblePopper: {
      height: 8,
      zIndex: 20,
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    addIcon: {
      fill: "white",
    },
    invisibleHoverDiv: {
      position: "relative",
    },
    hoverDiv: { width: "100%", height: 3, position: "absolute", top: -1 },
  })
);

export interface InlineInsertProps {
  dayIndex: number;
  sectionIndex: number;
  addAfterRowIndex: number;
}

export const InlineInsert: FunctionComponent<InlineInsertProps> = ({
  dayIndex,
  sectionIndex,
  addAfterRowIndex,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateSelection = useUpdateSelection();

  const onResetSelection = () =>
    batch(() => {
      updateSelection([])
      dispatch(setSelectedSectionTags([]));
    });

  const onInsertRow = () =>
    batch(() => {
      dispatch(
        addFoodItemsFromIndex(
          [
            new FoodItem(
              undefined,
              undefined,
              "",
              addAfterRowIndex + 1,
              null,
              ""
            ),
          ],
          addAfterRowIndex + 1,
          dayIndex,
          sectionIndex
        )
      );
      onResetSelection();
    });

  const [isHovered, setIsHovered] = useState(false);

  const anchorRef = useRef<HTMLInputElement>(null);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  const hoveredBar: ReactNode = (
    <div ref={anchorRef} className={classes.invisibleHoverDiv}>
      {
        <div
          className={classes.hoverDiv}
          style={{
            backgroundColor: isHovered
              ? appTheme.colors.primary
              : "transparent",
          }}
        />
      }
    </div>
  );

  const plusPopper: ReactNode = (
    <Popper
      open={isHovered}
      anchorEl={anchorRef.current}
      placement="left"
      onClick={onInsertRow}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ButtonBase className={classes.buttonBase}>
        <AddIcon color="secondary" className={classes.addIcon} />
      </ButtonBase>
    </Popper>
  );

  const insertLinePopper: ReactNode = (
    <Popper
      open={true}
      anchorEl={anchorRef.current}
      modifiers={{
        flip: { enabled: false },
        offset: {
          enabled: true,
          offset: "0px, -3px",
        },
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onInsertRow}
    >
      <div
        className={`${classes.invisiblePopper}`}
        style={{
          width: anchorRef.current?.offsetWidth,
        }}
      />
    </Popper>
  );

  return (
    <div>
      {insertLinePopper}
      {hoveredBar}
      {plusPopper}
    </div>
  );
};

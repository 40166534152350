import {
  FoodItem,
  FoodItems,
  foodItemsConverter,
  FoodItemState,
} from "./foodItem";
import { RetentionFactor } from "./retentionFactor";

export interface Tag {
  id: string;
  label: string;
  inactiveColor: string;
  activeColor: string;
}

export const isTag = (o: any): o is Tag => {
  const tag: Tag = {
    id: o.id,
    label: o.label,
    inactiveColor: o.inactiveColor,
    activeColor: o.activeColor,
  };
  return (
    typeof tag.id === "string" &&
    typeof tag.label === "string" &&
    typeof tag.inactiveColor === "string" &&
    typeof tag.activeColor === "string"
  );
};

export class Section {
  id: string;
  foodItems: FoodItems;
  title: string;
  index: number;
  isCollapsed: boolean;
  tags: string[];

  constructor(
    id: string,
    index: number,
    foodItems: FoodItems,
    title: string,
    isCollapsed: boolean,
    tags: string[]
  ) {
    this.id = id;
    this.index = index;
    this.foodItems = foodItems;
    this.title = title;
    this.isCollapsed = isCollapsed;
    this.tags = tags;
  }

  get object(): SectionState {
    const foodItemsState: FoodItemState[] = this.foodItems.items.map(
      (foodItem: FoodItem): FoodItemState => foodItem.object
    );

    return {
      id: this.id,
      index: this.index,
      title: this.title,
      foodItems: foodItemsState,
      isCollapsed: this.isCollapsed,
      tags: this.tags,
    };
  }

  static fromObject = (
    sectionState: SectionState,
    retentionFactorMap: Map<string, RetentionFactor>
  ) => {
    const foodItems: FoodItems = new FoodItems(
      sectionState.foodItems,
      retentionFactorMap
    );
    return new Section(
      sectionState.id,
      sectionState.index,
      foodItems,
      sectionState.title,
      sectionState.isCollapsed,
      sectionState.tags
    );
  };
}

export class Sections {
  items: Section[];

  constructor(
    sectionsState: SectionState[],
    retentionFactorMap: Map<string, RetentionFactor>
  ) {
    this.items = sectionsState.map(
      (sectionState: SectionState): Section =>
        Section.fromObject(sectionState, retentionFactorMap)
    );
  }

  get length(): number {
    return this.items.length;
  }
}

export interface SectionState {
  id: string;
  foodItems: FoodItemState[];
  title: string;
  index: number;
  isCollapsed: boolean;
  tags: string[];
}

export const sectionConverter = {
  toFirestore: function (
    section: SectionState
  ): firebase.firestore.DocumentData {
    const foodItems = foodItemsConverter.toFirestore(section.foodItems);
    return {
      id: section.id,
      index: section.index,
      foodItems: foodItems,
      title: section.title,
      isCollapsed: section.isCollapsed,
      tags: section.tags,
    };
  },
  fromFirestore: function (
    data: firebase.firestore.DocumentData
  ): SectionState {
    const foodItems: FoodItemState[] = foodItemsConverter.fromFirestore(
      data.foodItems
    );
    return {
      id: data.id,
      index: data.index,
      foodItems: foodItems,
      title: data.title,
      isCollapsed: data.isCollapsed || false,
      tags: data.tags || [],
    };
  },
};

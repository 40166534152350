import React, { ReactNode, useState } from "react";
import {
  createStyles,
  List,
  ListSubheader,
  makeStyles,
  MenuItem,
} from "@material-ui/core";

import { Tag } from "../../../../data/models/documentProperties/section";
import { FoodworksMenu } from "../../../common/FoodworksMenu";
import { MAX_TAG_SHOWN } from "../../../../constants/tags";

const useStyles = makeStyles(() =>
  createStyles({
    allTagsList: {
      maxHeight: 200,
      width: 180,
      overflow: "auto",
    },
    menuWidth: {
      width: 260,
    },
    colorButton: {
      height: 10,
      width: 10,
      minWidth: 10,
      marginTop: 2,
      marginRight: 5,
      borderRadius: 4,
    },
    tagMenuLabel: {
      display: "flex",
      alignItems: "center",
    },
  })
);

interface TagMenuProps {
  title: string;
  anchorElement: HTMLElement | null;
  onClose: () => void;
  onClickCreateTag: () => void;
  tags: Tag[];
  tagIds: string[];
  dispatchSetId: (tagIds: string[]) => void;
}

export const TagMenu = ({
  title,
  anchorElement,
  onClose,
  onClickCreateTag,
  tags,
  tagIds,
  dispatchSetId,
}: TagMenuProps): JSX.Element => {
  const classes = useStyles();

  const selectTag = (tagId: string) => {
    const newTagIds: string[] = [...new Set([...tagIds, tagId])];
    dispatchSetId(newTagIds);
  };

  const [
    allTagsMenuAnchor,
    setAllTagsMenuAnchor,
  ] = useState<HTMLElement | null>(null);

  const onCreateDocumentTag = () => {
    onClose();
    onClickCreateTag();
  };

  const onCloseAllTagsMenu = () => {
    setAllTagsMenuAnchor(null);
    onClose();
  };

  const recentlyCreatedTags: ReactNode = !tags.length ? null : (
    <List
      className={classes.menuWidth}
      subheader={
        <ListSubheader key="recentlyUsed" disableSticky>
          {`Recently created ${title} tags`}
        </ListSubheader>
      }
    >
      {tags
        .slice(-MAX_TAG_SHOWN)
        .reverse()
        .map(
          (tag: Tag): ReactNode => (
            <MenuItem key={tag.id} onClick={() => selectTag(tag.id)}>
              <div className={classes.tagMenuLabel}>
                <div
                  className={classes.colorButton}
                  style={{ backgroundColor: tag.activeColor }}
                />
                {tag.label}
              </div>
            </MenuItem>
          )
        )}
    </List>
  );

  const allTagMenu: ReactNode = tags.length > MAX_TAG_SHOWN && [
    <MenuItem
      key="moreTags"
      onClick={(event) => setAllTagsMenuAnchor(event.currentTarget)}
    >
      {`All ${title} tags`}
    </MenuItem>,
    <FoodworksMenu
      id="moreTagsMenu"
      key="tagMenu-foodWorksMenu"
      open={!!allTagsMenuAnchor}
      anchorEl={allTagsMenuAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={onCloseAllTagsMenu}
    >
      <List className={classes.allTagsList}>
        {tags.map(
          (tag: Tag): ReactNode => (
            <MenuItem key={tag.id} onClick={() => selectTag(tag.id)}>
              <div className={classes.tagMenuLabel}>
                <div
                  className={classes.colorButton}
                  style={{ backgroundColor: tag.activeColor }}
                />
                {tag.label}
              </div>
            </MenuItem>
          )
        )}
      </List>
    </FoodworksMenu>,
  ];

  return (
    <>
      <FoodworksMenu
        open={!!anchorElement}
        anchorEl={anchorElement}
        onClose={onClose}
      >
        {allTagMenu}
        {recentlyCreatedTags}
        <MenuItem key={`create${title}Tag`} onClick={onCreateDocumentTag}>
          {`Create ${title} tag`}
        </MenuItem>
      </FoodworksMenu>
    </>
  );
};

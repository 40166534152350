export interface Quantity {
  amount: number;
  measureId: string;
}

export const quantityConverter = {
  toJson: (quantity: Quantity | undefined): Quantity => {
    return !quantity
      ? { amount: -1, measureId: "undefined" }
      : {
          amount: quantity.amount,
          measureId: quantity.measureId,
        };
  },

  fromJson: (data: firebase.firestore.DocumentData): Quantity | undefined => {
    if (!data) return undefined;
    return data.amount === -1 && data.measureId === "undefined"
      ? undefined
      : {
          amount: data.amount,
          measureId: data.measureId,
        };
  },
};

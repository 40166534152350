import React from "react";
import { PeopleOutlined } from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { handleRouteChange } from "../../../../store/ui/thunks/routing";
import { getClientDashboardRouteData } from "../../../../data/routing/routing";
import NavigationHeader from "../shared/NavigationHeader";

const ClientDatabaseNavHeader = (): JSX.Element => {
  const dispatch = useDispatch();

  const onNavigateToClientsDashboard = () =>
    dispatch(handleRouteChange(getClientDashboardRouteData()));

  return (
    <NavigationHeader
      text="Clients"
      onClick={onNavigateToClientsDashboard}
      icon={<PeopleOutlined color="secondary" />}
    />
  );
};

export default ClientDatabaseNavHeader;

import { FoodItem } from "../../../../data/models/documentProperties/foodItem";
import {
  IActionsAddFoodItem,
  IActionsClearFoodItem,
  IActionsRemoveFoodItem,
  IActionsUpdateFoodItem,
  IActionsSetAllFoodItems,
  IActionsUpdateQuantity,
  IActionsClearQuantity,
  IActionsUpdateNote,
  IActionsUpdateRetentionFactor,
  IActionsClearRetentionFactor,
  IActionsFoodItemsPasted,
  IActionsRemoveMultipleFoodItems,
} from "../actions/foodItems";
import { Quantity } from "../../../../data/models/documentProperties/quantity";

export const addFoodItem = (
  dayIndex: number,
  sectionIndex: number,
  foodItem: FoodItem
): IActionsAddFoodItem => {
  return {
    type: "ADD_FOOD_ITEM",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    foodItem: foodItem,
  };
};

export const clearFoodItem = (
  dayIndex: number,
  sectionIndex: number,
  rowIndex: number
): IActionsClearFoodItem => {
  return {
    type: "CLEAR_FOOD_ITEM",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    rowIndex: rowIndex,
  };
};

export const removeFoodItem = (
  dayIndex: number,
  sectionIndex: number,
  rowIndex: number
): IActionsRemoveFoodItem => {
  return {
    type: "REMOVE_FOOD_ITEM",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    rowIndex: rowIndex,
  };
};

export const updateFoodItem = (
  dayIndex: number,
  sectionIndex: number,
  foodItem: FoodItem
): IActionsUpdateFoodItem => {
  return {
    type: "UPDATE_FOOD_ITEM",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    foodItem: foodItem,
  };
};

export const removeMultipleFoodItem = (
  foodObjects: {
    dayIndex: number;
    sectionIndex: number;
    rowIndex: number;
  }[]
): IActionsRemoveMultipleFoodItems => {
  return {
    type: "REMOVE_MULTIPLE_FOOD_ITEMS",
    foodObjects,
  };
};

export const setAllFoodItems = (
  dayIndex: number,
  sectionIndex: number,
  foodItems: FoodItem[]
): IActionsSetAllFoodItems => {
  return {
    type: "SET_ALL_FOOD_ITEMS",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    foodItems: foodItems,
  };
};

export const updateQuantity = (
  dayIndex: number,
  sectionIndex: number,
  rowIndex: number,
  quantity: Quantity
): IActionsUpdateQuantity => {
  return {
    type: "UPDATE_QUANTITY",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    rowIndex: rowIndex,
    quantity: quantity,
  };
};

export const clearQuantity = (
  dayIndex: number,
  sectionIndex: number,
  rowIndex: number
): IActionsClearQuantity => {
  return {
    type: "CLEAR_QUANTITY",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    rowIndex: rowIndex,
  };
};

export const updateNote = (
  dayIndex: number,
  sectionIndex: number,
  rowIndex: number,
  note: string
): IActionsUpdateNote => {
  return {
    type: "UPDATE_NOTE",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    rowIndex: rowIndex,
    note: note,
  };
};

export const updateRetentionFactor = (
  dayIndex: number,
  sectionIndex: number,
  rowIndex: number,
  id: string | null
): IActionsUpdateRetentionFactor => {
  return {
    type: "UPDATE_RETENTION_FACTOR",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    rowIndex: rowIndex,
    id: id,
  };
};

export const clearRetentionFactor = (
  dayIndex: number,
  sectionIndex: number,
  rowIndex: number
): IActionsClearRetentionFactor => {
  return {
    type: "CLEAR_RETENTION_FACTOR",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    rowIndex: rowIndex,
  };
};

export const foodItemsPasted = (): IActionsFoodItemsPasted => {
  return {
    type: "FOOD_ITEMS_PASTED",
  };
};

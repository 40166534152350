import {
  FOODS,
  FoodTemplate,
  FOOD_RECORDS,
  FOOD_TEMPLATES,
  MEAL_PLANS,
  RECIPES,
  TWENTY_FOUR_HOUR_RECALL,
} from "./FoodTemplate";
import { FoodSummaryType } from "../components/screens/databases/documents/tabs/ingredients/editing_grid/rows/cells/ingredientUtils";
import { Tag } from "../data/models/documentProperties/section";

export const DELETE_FILTER_ID = 10;

export interface QuickFilter {
  foodFilter: FoodFilter[];
  documentTags: Tag[];
  clientTags: Tag[];
}

export const initialQuickFilterState: QuickFilter = {
  foodFilter: [],
  documentTags: [],
  clientTags: [],
};

// Temporary Food Item class for basic functionality before knowing template API
export class FoodFilter {
  id: number;
  text: string;
  filter: (summary: FoodSummaryType) => boolean;

  constructor(
    id: number,
    text: string,
    filter: (summary: FoodSummaryType) => boolean
  ) {
    this.id = id;
    this.text = text;
    this.filter = filter;
  }
}

function templateFilter(template: FoodTemplate) {
  return new FoodFilter(
    template.id,
    template.title,
    (summary: FoodSummaryType) => {
      if ("templateId" in summary) {
        return summary.templateId === template.id.toString();
      }
      return false;
    }
  );
}
export const FOODS_FILTER = templateFilter(FOODS);
export const RECIPES_FILTER = templateFilter(RECIPES);
export const TWENTY_FOUR_HOUR_RECALL_FILTER = templateFilter(
  TWENTY_FOUR_HOUR_RECALL
);
export const MEAL_PLANS_FILTER = templateFilter(MEAL_PLANS);
export const FOOD_RECORDS_FILTER = templateFilter(FOOD_RECORDS);

export const DELETED_DOCUMENTS_FILTER = new FoodFilter(
  DELETE_FILTER_ID,
  "Archived",
  (summary: FoodSummaryType) => {
    if ("status" in summary) {
      return summary.status === "archived";
    }
    return false;
  }
);

export const QUICK_FILTERS = FOOD_TEMPLATES.map(
  (template: FoodTemplate): FoodFilter => templateFilter(template)
).concat([DELETED_DOCUMENTS_FILTER]);

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Card, makeStyles, Theme, Typography } from "@material-ui/core";

import {
  Measurement,
  MeasurementEntry,
} from "../../../../../../data/models/clientProperties/measurement";
import { measurementEntriesSelector } from "../../../../../../store/data/current_client/selectors/clientMeasurements";
import { RootState } from "../../../../../../store/reducers";
import { appTheme } from "../../../../../../styling/style";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    margin: "10px 30px 5px 5px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: {
    zIndex: 10,
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    width: "100%",
    height: "80%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const getDates = (
  entries: MeasurementEntry[] | undefined,
  label: string,
  min: Date,
  max: Date
) => {
  if (!entries) return [];
  const dateRange = entries.filter(
    (entry) => entry.date > min.toISOString() && entry.date < max.toISOString()
  );
  const data = dateRange.map(
    (entry): DatePoint => ({
      x: new Date(entry.date).getTime(),
      y: entry.value,
    })
  );
  return [
    {
      name: label,
      data: data,
      xaxis: {
        type: "datetime",
      },
    },
  ];
};

const generateLineData = (
  label: string,
  units: string,
  measurement: Measurement | undefined,
  min: Date,
  max: Date
): ApexOptions => {
  if (!measurement) return {};
  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: !measurement.entries.length ? false : true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
    },
    colors: [appTheme.colors.barPurple],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    markers: {
      colors: [appTheme.colors.barGreen],
      strokeWidth: 0,
      size: 5,
    },
    title: {
      text: label + " over time",
      align: "center",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },
      title: {
        text: units,
      },
    },
    xaxis: {
      type: "datetime",
      min: min.getTime(),
      max: max.getTime(),
    },
    noData: {
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
      },
    },
  };
  return options;
};
interface Props {
  label: string;
  units: string;
  min: Date;
  max: Date;
}

interface DatePoint {
  x: number;
  y: number;
}

const TrackedMeasurementGraph = React.memo((props: Props) => {
  const classes = useStyles();
  const measurement = useSelector<RootState, Measurement | undefined>(
    (state: RootState) =>
      measurementEntriesSelector(state, props.label, props.units)
  );
  const options = useMemo(
    () =>
      generateLineData(
        props.label,
        props.units,
        measurement,
        props.min,
        props.max
      ),
    [props.label, props.units, measurement, props.min, props.max]
  );
  const series = useMemo(
    () =>
      getDates(measurement!.entries, measurement!.label, props.min, props.max),
    [measurement, props.min, props.max]
  );

  return (
    <Card elevation={1} className={classes.root}>
      {!series[0].data.length && (
        <div className={classes.overlay}>
          <Typography variant="h2" color="error">
            No data for the selected period
          </Typography>
        </div>
      )}
      <Chart options={options} series={series} type="area" width={"170%"} />
    </Card>
  );
});

export default TrackedMeasurementGraph;

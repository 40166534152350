import React, { FunctionComponent, ReactNode, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";

import { MethodSectionTitle } from "./MethodSectionTitle";
import {
  DeleteSectionButton,
  HideMethodSectionButton,
  ShowMethodSectionButton,
} from "./MethodSectionButtons";
import { MethodContents } from "../../../../../../data/models/document";

const useStyles = makeStyles(() =>
  createStyles({
    sectionHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    leftButtonGroup: {
      display: "flex",
      alignItems: "center",
    },
  })
);

interface MethodSectionHeaderProps {
  section: MethodContents;
}

export const MethodSectionHeader: FunctionComponent<MethodSectionHeaderProps> = ({
  section,
}) => {
  const classes = useStyles();

  const [rowHovered, setRowHovered] = useState(false);

  const onMouseEnterHeader = () => setRowHovered(true);

  const onMouseLeaveHeader = () => setRowHovered(false);

  const sectionIsCollapsed = section.isCollapsed;
  const collapseSectionButton = (): ReactNode =>
    sectionIsCollapsed ? (
      <ShowMethodSectionButton sectionIndex={section.index} />
    ) : (
      <HideMethodSectionButton sectionIndex={section.index} />
    );

  return (
    <div
      className={classes.sectionHeader}
      onMouseEnter={onMouseEnterHeader}
      onMouseLeave={onMouseLeaveHeader}
    >
      <div className={classes.leftButtonGroup}>
        {collapseSectionButton()}
        <MethodSectionTitle
          sectionIndex={section.index}
          title={section.title}
        />
      </div>
      <div className={classes.leftButtonGroup}>
        {rowHovered && (
          <DeleteSectionButton
            sectionIndex={section.index}
            isSectionContent={!!section.data}
          />
        )}
      </div>
    </div>
  );
};

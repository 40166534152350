import { Size } from "re-resizable";

import { RootState } from "../../reducers";
import {
  NutritionPaneState,
  NutritionRadioOption,
} from "../reducers/nutritionPaneReducers";

export const compositionOptionSelector = (
  state: RootState
): NutritionRadioOption => state.nutritionPane.selectedAmount;

export const customCompositionAmountSelector = (
  state: RootState
): NutritionPaneState["customAmount"] => state.nutritionPane.customAmount;

export const expandedCategoriesSelector = (state: RootState): string[] =>
  state.nutritionPane.categoriesExpanded;

export const selectedSectionTagsSelector = (state: RootState): string[] =>
  state.nutritionPane.selectedSectionTags;

export const nutritionPaneSizeSelector = (state: RootState): Size =>
  state.nutritionPane.dimensions;

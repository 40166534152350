import { CompositionState } from "../../../data/models/composition";
import { Document } from "../../../data/models/document";
import {
  IActionsAddComposition,
  IActionsFetchComposition,
  IActionsUpdateComposition,
} from "../actions/compositionCache";

export const addComposition = (
  identifier: string,
  composition: CompositionState
): IActionsAddComposition => {
  return {
    type: "ADD_COMPOSITION",
    identifier: identifier,
    composition: composition,
  };
};

export const updateComposition = (
  identifier: string,
  composition: CompositionState
): IActionsUpdateComposition => {
  return {
    type: "UPDATE_COMPOSITION",
    identifier: identifier,
    composition: composition,
  };
};

export const fetchComposition = (
  identifier: string,
  document: Document
): IActionsFetchComposition => {
  return {
    type: "FETCH_COMPOSITION",
    identifier: identifier,
    document: document,
  };
};

import { useSelector } from "react-redux";

import { RouteData } from "../../../../data/routing/types";
import { RootState } from "../../../../store/reducers";
import { currentRouteSelector } from "../../../../store/ui/selectors/routing";

export const useIsClientDashBoard = (): boolean => {
  const currentRoute: RouteData | undefined =
    useSelector<RootState, RouteData | undefined>(currentRouteSelector);

  return currentRoute?.clientId === "dashboard";
};

export const useIsClientScreen = () => {
  const currentRoute: RouteData | undefined =
    useSelector<RootState, RouteData | undefined>(currentRouteSelector);

  return !!currentRoute?.clientId && currentRoute?.clientId !== "dashboard";
};

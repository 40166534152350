import React, { ReactNode, useState, MutableRefObject } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  Button,
  makeStyles,
  Typography,
  createStyles,
  Theme,
  Popper,
  PopperProps,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { FilterOptionsState } from "@material-ui/lab";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete";
import { ListChildComponentProps, VariableSizeList } from "react-window";

import { BaseDialog } from "./BaseDialog";
import { IngredientSummaryItem } from "../screens/databases/documents/tabs/ingredients/editing_grid/rows/cells/IngredientCell";
import { RootState } from "../../store/reducers";
import { updateCalculationMethod } from "../../store/data/current-document/action-creators/document";
import { filterOptions } from "../screens/databases/documents/tabs/ingredients/editing_grid/rows/cells/ingredientUtils";
import { FoodId } from "../../data/models/documentProperties/foodId";
import { appTheme } from "../../styling/style";
import { calculationMethodSelector } from "../../store/data/current-document/selectors/document";
import { getDocumentAndAddMeasure } from "../../store/data/current-document/thunks/document";
import { useReduxDispatch } from "../../store/store";
import { setMappedDocumentId } from "../../store/data/current-document/thunks/currentDocument";
import { QuantityOption } from "../screens/databases/documents/tabs/ingredients/editing_grid/rows/cells/QuantityCell";
import { GridCellInput } from "../common/GridAutoCompleteInput";
import { FOOD_TEMPLATES } from "../../constants/FoodTemplate";
import { getDocument } from "../../store/data/current-document/thunks/getDocument";

const useAutoCompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    listbox: {
      width: 500,
      margin: 0,
      padding: 0,
      zIndex: 1,
      position: "absolute",
      listStyle: "none",
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      maxHeight: 300,
      "& li": {
        padding: 0,
      },
      '& li[data-focus="true"]': {
        backgroundColor: appTheme.colors.oceanBlue[0],
        color: appTheme.colors.xiketic,
        cursor: "pointer",
      },
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      flex: 1,
    },
    listContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      background: appTheme.colors.white[8],
      border: `1px solid ${appTheme.colors.white[12]}`,
      borderRadius: 5,
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 270,
    },
    itemIcon: {
      fill: appTheme.colors.primary,
      width: 18,
      height: 18,
      margin: 5,
    },
    renderItem: {
      display: "flex",
      alignItems: "center",
    },
    popperCaption: {
      marginLeft: 5,
    },
    popper: {
      boxSizing: "border-box",
      paddingLeft: "3%",
      paddingTop: "1%",
      paddingRight: "2%",
      width: "100%",
      zIndex: theme.zIndex.modal,
    },
    listItem: {
      backgroundColor: theme.palette.background.paper,
      "& li:active": {
        backgroundColor: appTheme.colors.primary,
        color: "white",
      },
    },
    bottomActionButtons: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    checkboxContainer: {
      marginRight: "auto",
      marginLeft: 20,
    },
    checkbox: {
      padding: 0,
    },
    actionButtons: {
      flex: "right",
    },
    body: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
  })
);

const LISTBOX_PADDING = 8; // px
const ITEMS_IN_AUTOCOMPLETE = 8;
const SEARCH_RESULT_LIMIT = 500;
const initialSelectedMappedDocument: SelectedMappedDocument = {
  foodId: undefined,
  isPublic: undefined,
};

interface MappedDocumentDialogOption {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  color?: "secondary" | "default" | "inherit" | "primary" | undefined;
}
interface MappedFoodDialogProps {
  open: boolean;
  onClose: () => void;
  combinedSummaries: IngredientSummaryItem[];
  searchTermMap: Map<string, string[]>;
}
interface SelectedMappedDocument {
  foodId: FoodId | undefined;
  isPublic: boolean | undefined;
}

export type AutocompleteRef =
  | ((instance: HTMLInputElement | null) => void)
  | MutableRefObject<HTMLInputElement | null>
  | null;

export type GridCellType = QuantityOption | IngredientSummaryItem;

export interface CellProps {
  items: GridCellType[];
  initialInput: string;
  cellSize: number;
  placeholder: string;
  useSetSize: boolean;
  inputStyle?: React.CSSProperties | undefined;
  ref: AutocompleteRef;
  onSelect: (value: GridCellType) => void;
  onBlur: () => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInputChange: (event: object, value: string, reason: string) => void;
  filterOptions: (
    options: GridCellType[],
    state: FilterOptionsState<GridCellType>
  ) => GridCellType[];
  renderOption: (option: GridCellType) => ReactNode;
}

const AutocompletePopper = React.memo<PopperProps>(
  ({ children, anchorEl, open }) => {
    const classes = useStyles();
    if (typeof anchorEl === "function") return null;
    return (
      <Popper
        modifiers={{
          hide: {
            enabled: false,
          },
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
        disablePortal
        anchorEl={anchorEl}
        className={classes.popper}
        open={open}
        placement="bottom-start"
        data-cy="autocompleteDropdown"
      >
        {children}
      </Popper>
    );
  }
);

export const MappedFoodDialog = React.memo(
  ({
    open,
    onClose,
    combinedSummaries,
    searchTermMap,
  }: MappedFoodDialogProps) => {
    const ref: React.RefObject<HTMLInputElement> =
      React.useRef<HTMLInputElement>(null);

    const classes = useStyles();

    const dispatch = useReduxDispatch();

    const wrapperStyle = {
      display: "flex",
      flex: 1,
    };

    const onFetchMappedDocument = (foodId: FoodId, isPublic: boolean) => {
      copyMeasures
        ? dispatch(
            getDocumentAndAddMeasure(
              foodId,
              isPublic,
              true,
              combinedSummaries,
              []
            )
          )
        : dispatch(getDocument(foodId, isPublic, true));
    };
    const onUpdateCalculationMethod = (
      calculationMethod: number,
      documentId: string
    ) => dispatch(updateCalculationMethod(calculationMethod, documentId));

    const previousCalculationMethod: number = useSelector<RootState, number>(
      calculationMethodSelector
    );

    const handleFoodSelection = async (
      mappedDocument?: SelectedMappedDocument
    ) => {
      let documentSelected = mappedDocument || selectedMappedDocument;
      if (documentSelected.foodId && documentSelected.isPublic !== undefined) {
        onFetchMappedDocument(
          documentSelected.foodId!,
          documentSelected.isPublic!
        );
        await dispatch(setMappedDocumentId(documentSelected.foodId.identifier));
        onClose();
      }
    };

    const handleCloseDialog = () => {
      if (
        !selectedMappedDocument.foodId &&
        selectedMappedDocument.isPublic === undefined
      ) {
        onUpdateCalculationMethod(previousCalculationMethod, "");
      }
      onClose();
    };

    const [inputValue, setInputValue] = useState("");
    const [copyMeasures, setCopyMeasures] = useState<boolean>(false);
    const [selectedMappedDocument, setSelectedMappedDocument] =
      useState<SelectedMappedDocument>(initialSelectedMappedDocument);

    const handleCopyMeasuresCheckbox = () => setCopyMeasures((prev) => !prev);

    const buildOptionIcon = (option: GridCellType) => {
      if ("templateId" in option) {
        for (const template of FOOD_TEMPLATES) {
          if (option.templateId === template.id.toString()) {
            return <template.icon className={classes.itemIcon} />;
          }
        }
      }
    };

    const renderRow = React.memo((props: ListChildComponentProps) => {
      const { data, index, style } = props;
      return React.cloneElement(data[index], {
        style: {
          ...style,
          top: (style.top as number) + LISTBOX_PADDING,
        },
        className: classes.listItem,
      });
    });

    const OuterElementContext = React.createContext({});
    const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
      const outerProps = React.useContext(OuterElementContext);
      return (
        <div data-cy="autocompleteMenu" ref={ref} {...props} {...outerProps} />
      );
    });

    const useResetCache = (data: any) => {
      const ref = React.useRef<VariableSizeList>(null);
      React.useEffect(() => {
        ref?.current?.resetAfterIndex(0, true);
      }, [data]);
      return ref;
    };

    const ListboxComponent = React.forwardRef<HTMLDivElement>(
      function ListboxComponent(listboxProps, ref) {
        const { children, ...other } = listboxProps;
        const itemData = React.Children.toArray(children);
        const itemCount = itemData.length;
        const itemSize = 28;

        const getChildSize = (child: React.ReactNode) => itemSize;

        const getHeight = () =>
          itemCount > ITEMS_IN_AUTOCOMPLETE
            ? ITEMS_IN_AUTOCOMPLETE * itemSize
            : itemData.map(getChildSize).reduce((a, b) => a + b, 0);

        const gridRef = useResetCache(itemCount);

        const captionText =
          itemCount >= 500
            ? "Search results limited to 500 items"
            : itemCount > ITEMS_IN_AUTOCOMPLETE
            ? `${itemCount} results`
            : "";

        return (
          <div ref={ref}>
            <OuterElementContext.Provider value={other}>
              <VariableSizeList
                itemData={itemData}
                style={{ overflowX: "hidden" }}
                height={getHeight() + 2 * LISTBOX_PADDING}
                width="100%"
                ref={gridRef}
                outerElementType={OuterElementType}
                innerElementType="ul"
                itemSize={(index) => getChildSize(itemData[index])}
                overscanCount={5}
                itemCount={itemCount}
              >
                {renderRow}
              </VariableSizeList>
              {captionText && (
                <Typography className={classes.popperCaption} variant="caption">
                  {captionText}
                </Typography>
              )}
            </OuterElementContext.Provider>
          </div>
        );
      }
    );

    const autoCompleteInput = (params: AutocompleteRenderInputParams) => (
      <GridCellInput
        ref={params.InputProps.ref}
        inputProps={{
          ...params.inputProps,
        }}
        className={classes.flex}
        inputRef={ref}
      />
    );

    const ingredientFilterOptions = (options: GridCellType[]): GridCellType[] =>
      filterOptions(combinedSummaries, inputValue, searchTermMap).slice(
        0,
        SEARCH_RESULT_LIMIT
      );

    const foodDocumentList: ReactNode = (
      <Card className={classes.listContainer}>
        <div style={wrapperStyle}>
          <div className={classes.flex}>
            <Autocomplete
              open
              disableListWrap
              classes={useAutoCompleteStyles()}
              ListboxComponent={
                ListboxComponent as React.ComponentType<
                  React.HTMLAttributes<HTMLElement>
                >
              }
              options={combinedSummaries}
              autoHighlight
              openOnFocus
              disableClearable
              onInputChange={(event, value, reason) => setInputValue(value)}
              onChange={async (event: any, newOption: any, reason: any) => {
                if (newOption) {
                  setSelectedMappedDocument({
                    foodId: newOption.foodId,
                    isPublic: newOption.isPublic,
                  });
                }
              }}
              PopperComponent={AutocompletePopper}
              getOptionSelected={() => true}
              filterOptions={ingredientFilterOptions}
              getOptionLabel={(option) => option.label}
              renderInput={autoCompleteInput}
              renderOption={(option: GridCellType, { selected }) => (
                <div className={classes.renderItem}>
                  {buildOptionIcon(option)}
                  <Typography>{option.label}</Typography>
                </div>
              )}
            />
          </div>
        </div>
      </Card>
    );

    const bottomDialogActions: MappedDocumentDialogOption[] = [
      {
        text: "Cancel",
        onClick: () => handleCloseDialog(),
        disabled: false,
        color: "default",
      },
      {
        text: "Ok",
        onClick: () => handleFoodSelection(),
        disabled: !selectedMappedDocument.foodId,
        color: "secondary",
      },
    ];

    const bottomActionButtons: ReactNode = (
      <div className={classes.bottomActionButtons}>
        <div className={classes.checkboxContainer}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                data-cy="rowCheckbox"
                size="small"
                disabled={false}
                checked={copyMeasures}
                onClick={handleCopyMeasuresCheckbox}
              />
            }
            label="Copy Measures"
          />
        </div>
        <div className={classes.actionButtons}>
          {bottomDialogActions.map((option: MappedDocumentDialogOption) => (
            <Button
              data-cy="mappedDialogAction"
              key={option.text}
              onClick={option.onClick}
              disabled={option.disabled}
              color={option.color}
            >
              {option.text}
            </Button>
          ))}
        </div>
      </div>
    );

    const body: ReactNode = (
      <div className={classes.body}><Typography>Select a reference food or recipe to base your foods nutritional analysis on.</Typography><br/>{foodDocumentList}</div>
    );

    return (
      <BaseDialog
        dataCy="mappedFoodDialog"
        open={open}
        onClose={onClose}
        title="Select food or recipe"
        body={body}
        maxWidth="md"
        action={bottomActionButtons}
      />
    );
  }
);

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setClientDOB } from "../../../../../../../store/data/current_client/action_creators/clientProfile";
import { clientDOBSelector } from "../../../../../../../store/data/current_client/selectors/clientProfile";
import { RootState } from "../../../../../../../store/reducers";

const useClientDOB = (): [Date | null, (input: string) => void] => {
  const dispatch = useDispatch();

  const onSetClientDOB = useCallback(
    (input: string) => dispatch(setClientDOB(input)),
    [dispatch]
  );

  const dob: string | undefined = useSelector<RootState, string | undefined>(clientDOBSelector);

  const [date, setDate] = useState<null | Date>(null);

  useEffect(() => {
    setDate(!dob ? null : new Date(dob));
  }, [dob]);

  return [date, onSetClientDOB];
};
export default useClientDOB;

import React, { ReactNode, useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { FoodWorksTextInput } from "../../../common/FoodWorksTextInput";
import {
  databaseDocumentTagsSelector,
  userDocumentSummariesSelector,
} from "../../../../store/data/selectors/database";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import { OpenDialogButton } from "../../../common/OpenDialogButton";
import { BaseDialog } from "../../BaseDialog";
import { Tag } from "../../../../data/models/documentProperties/section";
import { TagListItem } from "../../section_tags/components/TagListItem";
import { DocumentSummary } from "../../../../data/models/userDatabase";
import { updateDocumentTagId } from "../../../../store/data/current-document/thunks/document";
import {
  updateDatabaseDocumentTags,
  updateDocumentSummaries,
} from "../../../../store/data/thunks/database";
import { CreateDocumentTagButton } from "./CreateDocumentTagButton";
import { CreateDocumentTagDialog } from "./CreateDocumentTagDialog";
import ImportDocumentTagDialog from "./ImportDocumentTagDialog";
import { useLoadDocuments } from "./hooks/DocumentTags";
import { TAG_FIELD } from "../../../../constants/textInputs";

const useStyles = makeStyles((theme) => ({
  componentRootContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  component: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0px",
    height: 300,
  },
  addIcon: {
    color: appTheme.colors.primary,
  },
  listContainer: {
    overflow: "auto",
    flex: "1",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  selectButtonsContainer: {
    display: "flex",
  },
  listButton: {
    borderRadius: 4,
    marginTop: 2,
    marginBottom: 2,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
  },
  selectedListButton: {
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
  },
}));

const getAddedTagsToDocumentSummary = (
  summary: DocumentSummary,
  tagId: string
): DocumentSummary => {
  const updatedTagIds: string[] = [
    ...new Set([...summary.documentTagIds, tagId]),
  ];
  return { ...summary, documentTagIds: updatedTagIds };
};

const getRemovedTagsToDocumentSummary = (
  summary: DocumentSummary,
  tagId: string
): DocumentSummary => {
  const updatedTagIds: string[] = summary.documentTagIds.filter(
    (id) => id !== tagId
  );
  return { ...summary, documentTagIds: updatedTagIds };
};

const sameSelectedDocuments = (
  docIds1: string[],
  docIds2: string[]
): boolean => {
  if (docIds1.length !== docIds2.length) return false;
  const document1 = [...docIds1].sort();
  const document2 = [...docIds2].sort();
  return document1.every((val, idx) => val === document2[idx]);
};

interface DocumentTagDialogProps {
  open: boolean;
  onClose: () => void;
}

export const DocumentTagDialog = ({
  open,
  onClose,
}: DocumentTagDialogProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDeleteTag = (deletedTagId: string) => {
    const updatedTags: Tag[] = documentTags.filter(
      (tag: Tag) => tag.id !== deletedTagId
    );
    dispatch(updateDatabaseDocumentTags(updatedTags));

    const newDocumentSummaries: DocumentSummary[] = userDocumentSummaries.map(
      (summary: DocumentSummary) =>
        getRemovedTagsToDocumentSummary(summary, selectedTag)
    );

    batchUpdateSummaries(newDocumentSummaries);
  };

  const documentTags = useSelector<RootState, Tag[]>(
    databaseDocumentTagsSelector
  );

  const userDocumentSummaries = useSelector<RootState, DocumentSummary[]>(
    userDocumentSummariesSelector
  );

  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<string>("");
  const [selectedEditTag, setSelectedEditTag] = useState<string>("");
  const [showEditTagDialog, setShowEditTagDialog] = useState<boolean>(false);
  const [selectedDocuments, setSelectedDocuments] =
    useLoadDocuments(selectedTag);
  const [initialDocuments] = useLoadDocuments(selectedTag);
  const [openImportTagDialog, setOpenImportTagDialog] =
    useState<boolean>(false);
  const [isDocumentSame, setIsDocumentSame] = useState<boolean>();

  const batchUpdateSummaries = (documentSummary: DocumentSummary[]) => {
    batch(() => {
      // Update document
      documentSummary.forEach((summary: DocumentSummary) =>
        dispatch(
          updateDocumentTagId(summary.documentId, summary.documentTagIds)
        )
      );

      // Update document summaries
      dispatch(updateDocumentSummaries(documentSummary));
    });
  };

  const applyChanges = () => {
    if (selectedTag) {
      const newDocumentSummaries: DocumentSummary[] = userDocumentSummaries.map(
        (summary: DocumentSummary) =>
          selectedDocuments.includes(summary.documentId)
            ? getAddedTagsToDocumentSummary(summary, selectedTag)
            : getRemovedTagsToDocumentSummary(summary, selectedTag)
      );

      batchUpdateSummaries(newDocumentSummaries);
    }
  };

  const saveChanges = () => {
    applyChanges();
    onClose();
  };

  useEffect(() => {
    if (!documentTags.find((tag: Tag) => tag.id === selectedTag))
      setSelectedTag("");
  }, [documentTags, selectedTag]);

  useEffect(() => {
    setIsDocumentSame(
      sameSelectedDocuments(selectedDocuments, initialDocuments)
    );
  }, [selectedDocuments, initialDocuments]);

  const handleClose = () => {
    onClose();
  };

  const importTagsButton: ReactNode = [
    <OpenDialogButton
      key="documentTagDialog-openDialogButton"
      onClick={() => setOpenImportTagDialog(true)}
      label="Import document tags"
      cypressTag="importDocumentTagsButton"
    />,
    openImportTagDialog && (
      <ImportDocumentTagDialog
        key="documentTagDialog-importDocumentTagDialog"
        open
        onClose={() => setOpenImportTagDialog(false)}
      />
    ),
  ];

  const onSelectTag = (tagId: string) => {
    selectedTag === tagId ? setSelectedTag("") : setSelectedTag(tagId);
  };

  const onClickEditTag = (tagId: string) => {
    if (selectedEditTag !== tagId) {
      setSelectedEditTag(tagId);
    }
    setShowEditTagDialog(true);
  };

  const onClickDeleteTag = (tagId: string) => {
    onDeleteTag(tagId);
  };

  const documentTagButtons: ReactNode = documentTags.map(
    (tag: Tag): ReactNode => (
      <TagListItem
        key={tag.id}
        sectionTag={tag}
        isSelected={tag.id === selectedTag}
        onSelectTag={onSelectTag}
        onDeleteTag={onClickDeleteTag}
        onEditTag={onClickEditTag}
      />
    )
  );

  const handleDocumentClick = (documentId: string) => {
    if (selectedDocuments.includes(documentId)) {
      const removedDocument: string[] = selectedDocuments.filter(
        (id: string) => documentId !== id
      );
      setSelectedDocuments(removedDocument);
    } else {
      const updatedDocument: string[] = [...selectedDocuments, documentId];
      setSelectedDocuments(updatedDocument);
    }
  };

  const documentListButtons: ReactNode = [
    userDocumentSummaries.map(
      (summary: DocumentSummary): ReactNode =>
        summary.status !== "archived" &&
        (!searchInput ||
          summary.label.toLowerCase().includes(searchInput.toLowerCase())) && (
          <MenuItem
            key={summary.documentId}
            button
            onClick={() => handleDocumentClick(summary.documentId)}
            disabled={!selectedTag}
            className={
              selectedDocuments.includes(summary.documentId)
                ? `${classes.listButton} ${classes.selectedListButton}`
                : classes.listButton
            }
            data-cy={
              selectedDocuments.includes(summary.documentId)
                ? "documentSelected"
                : "documentUnselected"
            }
          >
            {summary.label}
          </MenuItem>
        )
    ),
  ];

  const applySelectAll = () => {
    const documentSummaryIds: string[] = userDocumentSummaries.map(
      (summary: DocumentSummary) => summary.documentId
    );
    setSelectedDocuments(documentSummaryIds);
  };

  const applyDeselectAll = () => {
    setSelectedDocuments([]);
  };

  const addTagToDocumentComponent: ReactNode = (
    <div data-cy="documentComponent">
      <Typography variant="body1">Add tag to documents</Typography>
      <FoodWorksTextInput
        maxLength={TAG_FIELD}
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value)}
        placeholder={"Search document titles..."}
      />
      <div className={classes.selectButtonsContainer}>
        <Button
          onClick={applySelectAll}
          disabled={
            !selectedTag ||
            userDocumentSummaries.length === selectedDocuments.length
          }
          data-cy="selectAllTags"
        >
          Select all
        </Button>
        <Button
          onClick={applyDeselectAll}
          disabled={!selectedTag || selectedDocuments.length === 0}
          data-cy="deselectAllTags"
        >
          Deselect all
        </Button>
      </div>
      <div className={classes.component}>
        <Card className={classes.listContainer}>
          <List data-cy="documentListButtons">{documentListButtons}</List>
        </Card>
      </div>
    </div>
  );

  const createTagComponent: ReactNode = [
    <div
      key="documentTagDialog-headerContainer"
      className={classes.headerContainer}
    >
      <Typography variant="h3">Available document tags</Typography>
      {importTagsButton}
    </div>,
    <div key="documentTagDialog-listContainer" className={classes.component}>
      <Card className={classes.listContainer}>
        <List data-cy="documentTagList">
          <ListItem>
            <CreateDocumentTagButton />
          </ListItem>

          {documentTagButtons}
        </List>
      </Card>
    </div>,
    <CreateDocumentTagDialog
      key="documentTagDialog-createDocumentTagDialog"
      open={showEditTagDialog}
      onClose={() => setShowEditTagDialog(false)}
      isEditing={true}
      tagId={selectedEditTag}
    />,
  ];

  const body: ReactNode = (
    <div className={classes.componentRootContainer}>
      {createTagComponent}
      {addTagToDocumentComponent}
    </div>
  );

  const action: ReactNode = [
    <Button
      key="DocumentTagDialogCloseButton"
      data-cy="documentTagCancel"
      onClick={handleClose}
    >
      Close
    </Button>,
    <Button
      key="DocumentTagDialogApplyButton"
      color="secondary"
      disabled={!selectedTag || isDocumentSame}
      data-cy="documentTagApply"
      onClick={applyChanges}
    >
      Apply
    </Button>,
    <Button
      key="DocumentTagDialogOKButton"
      color="secondary"
      data-cy="documentTagOK"
      onClick={saveChanges}
    >
      OK
    </Button>,
  ];

  return (
    <BaseDialog
      key="DocumentTagDialog"
      open={open}
      onClose={handleClose}
      title=""
      body={body}
      action={action}
      maxWidth="sm"
      dataCy="documentTagDialog"
    />
  );
};

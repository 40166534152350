import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { Client, clientsAreEqual } from "../../../../../data/models/client";
import { saveClient } from "../../../../../store/data/current_client/thunks/client";
import { TEMPORARY_CLIENT } from "../../../../../store/data/reducers/clientDatabase";
import {
  currentClientSelector,
  serverClientSelector,
  currentClientIdSelector,
} from "../../../../../store/data/selectors/clientDatabase";
import { RootState } from "../../../../../store/reducers";
import { useReduxDispatch } from "../../../../../store/store";

const useSaveClient = () => {

  const dispatch = useReduxDispatch();

  const onSaveClient = useCallback(() => {
    setDisableSave(true)
    dispatch(saveClient()).then(() => {
      setDisableSave(false)
    });
  }, [dispatch])

  const currentClient: Client = useSelector<RootState, Client>(
    currentClientSelector
  );

  const serverClient: Client | undefined = useSelector<
    RootState,
    Client | undefined
  >(serverClientSelector);

  const currentClientId: string = useSelector<RootState, string>(
    currentClientIdSelector
  );

  const [disableSave, setDisableSave] = useState(false)

  return {
    saveEnabled: !disableSave && !!currentClient.name &&
      (currentClientId === TEMPORARY_CLIENT ||
        !clientsAreEqual(currentClient, serverClient)),
    saveClient: onSaveClient
  }
};

export default useSaveClient;

import { calculateBMI } from "../../../../../../../data/models/nrvCalculator";
import { usePreviousPerpetualMeasurement } from "../../../tracking/tracking-card/hooks/usePreviousMeasurement";

const useClientBMI = (): number | undefined => {
  const currentHeight: { value: number; date: Date } | undefined =
    usePreviousPerpetualMeasurement("Height", "cm");
  const currentWeight: { value: number; date: Date } | undefined =
    usePreviousPerpetualMeasurement("Weight", "kg");

  const weight: number | undefined = currentWeight
    ? currentWeight.value
    : undefined;
  const height: number | undefined = currentHeight
    ? currentHeight.value
    : undefined;
  if (!height || !weight) return undefined;

  return calculateBMI(weight, height);
};

export default useClientBMI;

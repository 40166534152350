import { CalculationMethod } from "../../../../../constants/calculationMethod";
import {
  isFood,
  is24HourRecall,
  isMealPlan,
  isFoodRecord,
  DEFAULT_MEAL_PLAN_SECTIONS,
} from "../../../../../constants/FoodTemplate";
import { Client } from "../../../../../data/models/client";
import {
  FoodWorksDate,
  getCurrentDate,
} from "../../../../../data/models/documentProperties/date";
import { DayState } from "../../../../../data/models/documentProperties/day";
import { DocumentSummary } from "../../../../../data/models/userDatabase";
import { initialDocumentState } from "../../reducers/document";
import {
  Document,
  DocumentTemplateId,
} from "../../../../../data/models/document";

export const generateNewDocumentDays = (templateId: string): DayState[] => {
  if (isFood(templateId)) {
    return [];
  }

  if (
    is24HourRecall(templateId) ||
    isMealPlan(templateId) ||
    isFoodRecord(templateId)
  ) {
    return [
      {
        title: "Day 1",
        index: 0,
        id: "",
        sections: DEFAULT_MEAL_PLAN_SECTIONS,
        date: "",
      },
    ];
  }

  return initialDocumentState.days;
};

export const getNextDocumentTypeNumber = (
  documentSummaries: DocumentSummary[],
  templateId: string,
  isClientDatabase: boolean,
  client: Client
): number =>
  documentSummaries.filter((summary: DocumentSummary): boolean =>
    isClientDatabase
      ? client.documents.includes(summary.documentId) &&
        summary.templateId === templateId
      : summary.templateId === templateId
  ).length + 1;

export const generateNewDocumentName = (
  templateTitle: string,
  documentNumber: number
): string =>
  `New ${templateTitle[0].toLowerCase()}${templateTitle.slice(
    1,
    -1
  )} ${documentNumber}`;

export const generateNewCalculationMethod = (
  templateId: string
): CalculationMethod =>
  isFood(templateId)
    ? CalculationMethod.SET_TO_UNKNOWN
    : CalculationMethod.INGREDIENTS;

export const generateNewDocumentDate = (): FoodWorksDate => {
  const date: string = getCurrentDate();
  return { created: date, lastModified: date };
};

export const generateNewDocument = (
  templateId: DocumentTemplateId,
  templateTitle: string,
  documentSummaries: DocumentSummary[],
  isCurrentDatabaseClientDatabase: boolean,
  currentClient: Client
): Document => ({
  ...initialDocumentState,
  name: generateNewDocumentName(
    templateTitle,
    getNextDocumentTypeNumber(
      documentSummaries,
      templateId,
      isCurrentDatabaseClientDatabase,
      currentClient
    )
  ),
  calculationMethod: generateNewCalculationMethod(templateId),
  templateId: templateId,
  date: generateNewDocumentDate(),
  days: generateNewDocumentDays(templateId),
});

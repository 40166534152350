import React from "react";
import { Typography } from "@material-ui/core";

import useClientPAL from "../hooks/useClientPAL";
import ProfileItem from "./ProfileItem";

const PALDisplay = React.memo(
  (): JSX.Element => {
    const pal = useClientPAL();

    return (
      <ProfileItem
        label="PAL"
        component={<Typography color="secondary">{pal.toString()}</Typography>}
      />
    );
  }
);

export default PALDisplay;

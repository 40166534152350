import React from "react";
import { makeStyles, Switch, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginLeft: 10,
  },
  switch: {
    marginTop: 2,
  },
}));

interface FoodWorksTagSwitch {
  label?: string;
  checked: boolean;
  onChange: () => void;
}

const FoodWorksTagSwitch = ({
  label,
  checked,
  onChange,
}: FoodWorksTagSwitch): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography color={checked ? "secondary" : "textPrimary"} variant="h4">
        {label}
      </Typography>
      <Switch
        className={classes.switch}
        color="secondary"
        checked={checked}
        onClick={onChange}
        size="small"
      />
    </div>
  );
};

export default FoodWorksTagSwitch;

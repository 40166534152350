import React from "react";
import {
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Clear, FilterList } from "@material-ui/icons";

import { FoodworksTooltip } from "../../../common/InfoTooltip";
import { appTheme } from "../../../../styling/style";
import DocumentFilter from "./DocumentFilter";
import { FoodFilter } from "../../../../constants/QuickFilter";
import { Tag } from "../../../../data/models/documentProperties/section";
import useToggleDocumentFilter from "../hooks/useToggleDocumentFilter";
import ClientFilter from "./ClientFilter";
import { DocumentStatus } from "../../../../data/models/userDatabase";

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    padding: 10,
  },
  searchBar: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  advancedOptions: {
    paddingLeft: 15,
    paddingRight: 5,
    paddingBottom: 10,
  },
  divider: {
    marginBottom: 5,
  },
}));

type NavigationSearchProps =
  | {
      fetchSearchText: (text: string) => void;
      searchText: string;
      foodFilters: FoodFilter[];
      availableTags: Tag[];
      isClient: undefined;
      setClientFilters?: (filters: DocumentStatus[]) => void;
    }
  | {
      fetchSearchText: (text: string) => void;
      searchText: string;
      foodFilters: FoodFilter[];
      availableTags: Tag[];
      isClient: false;
      setClientFilters?: (filters: DocumentStatus[]) => void;
    }
  | {
      fetchSearchText: (text: string) => void;
      searchText: string;
      foodFilters: FoodFilter[];
      availableTags: Tag[];
      isClient: true;
      setClientFilters: (filters: DocumentStatus[]) => void;
    };

const NavigationFilter = ({
  fetchSearchText,
  searchText,
  foodFilters,
  availableTags,
  isClient,
  setClientFilters,
}: NavigationSearchProps): JSX.Element => {
  const classes = useStyles();

  const [quickFiltersEnabled, toggleQuickFilters] = useToggleDocumentFilter();

  return (
    <>
      <div className={classes.searchBar}>
        <FoodworksTooltip title="Quick Filters">
          <IconButton
            className={classes.iconButton}
            style={{
              background: quickFiltersEnabled
                ? appTheme.colors.oceanBlue[0]
                : "inherit",
              color: quickFiltersEnabled ? appTheme.colors.primary : "inherit",
            }}
            onClick={toggleQuickFilters}
            data-cy="quickFilterIcon"
          >
            <FilterList />
          </IconButton>
        </FoodworksTooltip>
        <InputBase
          value={searchText}
          onChange={(event) => fetchSearchText(event.target.value)}
          className={classes.input}
          placeholder="Search"
          type="text"
          data-cy="navSearchInput"
        />
        <IconButton
          className={classes.iconButton}
          onClick={() => fetchSearchText("")}
          data-cy="clearSearchIcon"
        >
          <Clear />
        </IconButton>
      </div>
      {quickFiltersEnabled && (
        <div className={classes.advancedOptions} data-cy="quickFilters">
          <Divider variant="middle" className={classes.divider} />
          {isClient ? (
            <ClientFilter setFilters={setClientFilters!} />
          ) : (
            <DocumentFilter
              filterOptions={[...foodFilters, ...availableTags]}
            />
          )}
        </div>
      )}
    </>
  );
};

export default NavigationFilter;

import React, { useState, ReactNode } from "react";
import {
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import { Profile } from "../../../../data/models/userDatabase";
import { databaseProfilesSelector } from "../../../../store/data/selectors/database";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import FoodworksSelect from "../../../common/FoodworksSelect";

const useStyles = makeStyles(() => ({
  inputGroup: {
    fontSize: appTheme.fontSize.textInput,
    display: "flex",
    marginRight: "2px",
  },
  profileComponent: {
    display: "flex",
    alignItems : "center",
    flex: 1,
    paddingTop: "10px",
    paddingBottom: "10px",
    justifyContent: "flex-end",
    gap : "0.5rem"
  },
  select: {
    width: 180,
    height: 30,
  },
  input: {
    borderRadius: "0px 4px 4px 0px",
    margin: 0,
    width: 60,
    "&:focus": {
      borderWidth: "1px",
    },
  },
  profileItems: {
    marginRight: "5px",
  },
  profileButton: {
    padding: "0px",
  },
}));

const DEFAULT_PROFILE = "Enabled nutrients"

interface NutrientProfileSelectionProps {
  setNutrientProfile: (profile: Profile | undefined) => void;
}

const NutrientProfileSelection = React.memo<NutrientProfileSelectionProps>(
  ({ setNutrientProfile }): JSX.Element => {
    const classes = useStyles();

    const profileData: Profile[] = useSelector<RootState, Profile[]>(
      databaseProfilesSelector
    );

    const [selectedProfileId, setSelectedProfileId] = useState<string>(DEFAULT_PROFILE);

    const onSelectProfile = (profileId: string) => {
      setSelectedProfileId(profileId);
      setNutrientProfile(
        profileData.find((profile) => profile.id === profileId)
      );
    };

    return (
      <div className={classes.profileComponent}>
          <Typography noWrap>Showing analysis for</Typography>
        <div className={classes.inputGroup}>
          <FoodworksSelect<string>
            value={selectedProfileId}
            className={classes.select}
            onChange={(value) => onSelectProfile(value)}
          >
            <MenuItem value={DEFAULT_PROFILE} key={DEFAULT_PROFILE}>
              Enabled nutrients
            </MenuItem>
            {profileData.map(
              (profile: Profile): ReactNode => (
                <MenuItem
                  value={profile.id}
                  key={profile.id}
                >
                  {profile.name}
                </MenuItem>
              )
            )}
          </FoodworksSelect>
        </div>
      </div>
    );
  }
);

export default NutrientProfileSelection;

import React, { ReactNode } from "react";
import { Divider, makeStyles, MenuItem, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { UserDatabaseSummary } from "../../../../data/models/userDatabase";
import { appTheme } from "../../../../styling/style";
import { FoodworksMenu } from "../../../common/FoodworksMenu";
import { ReactComponent as DatabasePlusIcon } from "../../../../images/icons/Database_Plus.svg";
import { handleRouteChange } from "../../../../store/ui/thunks/routing";
import {
  getDatabaseDashboardRouteData,
  getDatabaseRouteData,
} from "../../../../data/routing/routing";
import useRecentDatabases from "../hooks/useRecentDatabases";
import AppBarMenuItem from "./AppBarMenuItem";

export interface CreateMenuProps {
  onClose: () => void;
  anchorElement: HTMLElement | null;
}

const MAX_DATABASE_SUMMARIES_TO_SHOW = 4;

const useStyles = makeStyles(() => ({
  databaseIcon: {
    fill: appTheme.colors.primary,
    width: 24,
    height: 24,
  },
  skinnyAvatar: {
    minWidth: 40,
    display: "flex",
    alignItems: "center",
  },
  divider: { margin: "5px 0px" },
}));

const getMenuItems = (
  items: UserDatabaseSummary[],
  onClick: (id: string) => void,
  iconStyle: string
): ReactNode =>
  items.map(
    (summary: UserDatabaseSummary): ReactNode => (
      <AppBarMenuItem
        onClick={() => onClick(summary.id)}
        key={summary.id}
        id={summary.id}
        label={summary.name}
        lastModifiedDate={summary.date.lastModified}
        icon={<DatabasePlusIcon className={iconStyle} />}
      />
    )
  );

export const DatabaseMenu = React.memo<CreateMenuProps>(
  ({ onClose, anchorElement }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onChangeCurrentDatabase = (databaseId: string) =>
      dispatch(handleRouteChange(getDatabaseRouteData(databaseId)));

    const onNavigateToDatabaseDashboard = () =>
      dispatch(handleRouteChange(getDatabaseDashboardRouteData()));

    const sortedDatabaseSummaries: UserDatabaseSummary[] = useRecentDatabases(
      MAX_DATABASE_SUMMARIES_TO_SHOW
    );

    const onClickViewAllDatabases = () => {
      onNavigateToDatabaseDashboard();
      onClose();
    };

    const onClickDatabase = (databaseId: string) => {
      onChangeCurrentDatabase(databaseId);
      onClose();
    };

    return (
      <>
        <FoodworksMenu
          id="simple-menu"
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={onClose}
        >
          {[
            <MenuItem key="databaseMenuRecent" disabled>
              <Typography variant="caption">RECENT</Typography>
            </MenuItem>,
            getMenuItems(
              sortedDatabaseSummaries,
              onClickDatabase,
              classes.databaseIcon
            ),

            <Divider key="databaseMenuDivider" className={classes.divider} />,
            <MenuItem
              key="databaseMenuSeeAllDatabases"
              data-cy="seeAllDatabases"
              onClick={onClickViewAllDatabases}
            >
              View all databases
            </MenuItem>,
          ]}
        </FoodworksMenu>
      </>
    );
  }
);

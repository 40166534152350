import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";

import { appTheme } from "../../../styling/style";
import Bar, { BarProps } from "./HBar";

export const useStyles = makeStyles<Theme, styleProps>((theme: Theme) => ({
  root: {
    padding: 0,
    display: "flex",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
  },
  barsContainer: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
    backgroundColor: (props) =>
      props.props.backgroundColor
        ? props.props.backgroundColor
        : props.default.backgroundColor,
  },
  container: {
    overflowY: "hidden",
    width: (props) =>
      props.props.width ? props.props.width : props.default.width,
    height: "100%",
    backgroundColor: (props) =>
      props.props.backgroundColor
        ? props.props.backgroundColor
        : props.default.backgroundColor,
  },
}));

export interface BarChartProps {
  labelWidth?: string;
  data: BarProps[];
  title?: string;
  backgroundColor?: string;
  width?: string;
  maxPercent: number;
  percentLocation?: "left" | "right";
  titleDivider?: boolean;
}

interface styleProps {
  props: BarChartProps;
  default: any;
}

export const Barchart = React.memo((props: BarChartProps) => {
  const defaultProps = {
    labelWidth: "50%",
    backgroundColor: appTheme.colors.white[0],
    width: "100%",
    maxPercent: 100,
    percentLocation: "left",
    titleDivider: false,
  };
  const classes = useStyles({ props: props, default: defaultProps });

  const bars = props.data.map((bar: any) => (
    <Bar
      key={bar.label}
      labelWidth={props.labelWidth ? props.labelWidth : defaultProps.labelWidth}
      maxPercent={props.maxPercent ? props.maxPercent : defaultProps.maxPercent}
      percentLocation={
        props.percentLocation
          ? (props.percentLocation as "left" | "right")
          : (defaultProps.percentLocation as "left" | "right")
      }
      titleDivide={
        props.titleDivider ? props.titleDivider : defaultProps.titleDivider
      }
      barWidth={bar.barWidth}
      label={bar.label}
      value={bar.value}
      percentage={bar.percentage}
      barColor={bar.barColor}
    />
  ));

  return (
    <div key={props.title} className={classes.container}>
      <div className={classes.title}>
        {props.title && <Typography variant={"h1"}>{props.title}</Typography>}
      </div>
      <div>
        <div className={classes.barsContainer}>{bars}</div>
      </div>
    </div>
  );
});

export default Barchart;

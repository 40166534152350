import { Action } from "redux";

import { YieldType } from "../../../../data/models/documentProperties/yield";
import { ServeType } from "../../../../data/models/documentProperties/serve";
import { VolumeConversionObject } from "../../../../data/models/documentProperties/volumeConversionFactor";
import { DaysActions } from "./days";
import { SectionsActions } from "./sections";
import { FoodItemsActions } from "./foodItems";
import { CommonMeasuresActions } from "./commonMeasures";
import { NutrientOverridesActions } from "./nutrientOverrides";
import { SectionTagsActions } from "./sectionTags";
import { MethodContents } from "../../../../data/models/document";
import { DocumentStatus } from "../../../../data/models/userDatabase";

export const UPDATE_METHOD_SECTION_TITLE = "UPDATE_METHOD_SECTION_TITLE";
export const UPDATE_METHOD_SECTION_IS_COLLAPSED =
  "UPDATE_METHOD_SECTION_IS_COLLAPSED";
export const CREATE_METHOD_SECTION = "CREATE_METHOD_SECTION";
export const DELETE_METHOD_SECTION = "DELETE_METHOD_SECTION";
export const UPDATE_DOCUMENT_NAME = "UPDATE_DOCUMENT_NAME";
export const UPDATE_DOCUMENT_IDENTIFIER = "UPDATE_DOCUMENT_IDENTIFIER";
export const UPDATE_DOCUMENT_ALT_IDENTIFIER = "UPDATE_DOCUMENT_ALT_IDENTIFIER";
export const UPDATE_DOCUMENT_DESCRIPTION = "UPDATE_DOCUMENT_DESCRIPTION";
export const UPDATE_DOCUMENT_NOTE = "UPDATE_DOCUMENT_NOTE";
export const UPDATE_DOCUMENT_METHOD = "UPDATE_DOCUMENT_METHOD";
export const UPDATE_DOCUMENT_YIELD = "UPDATE_DOCUMENT_YIELD";
export const UPDATE_DOCUMENT_SERVES = "UPDATE_DOCUMENT_SERVES";
export const UPDATE_DOCUMENT_MODIFIED_DATE = "UPDATE_DOCUMENT_MODIFIED_DATE";
export const UPDATE_VOLUME_CONVERSION = "UPDATE_VOLUME_CONVERSION";
export const TOGGLE_PROPERTY_IS_LIQUID = "TOGGLE_PROPERTY_IS_LIQUID";
export const UPDATE_DOCUMENT_STATUS = "UPDATE_DOCUMENT_STATUS";
export const UPDATE_CALCULATION_METHOD = "UPDATE_CALCULATION_METHOD";
export const SET_DOCUMENT_MAPPING_ID = "SET_DOCUMENT_MAPPING_ID";
export const SET_DOCUMENT_TAG_IDS = "SET_DOCUMENT_TAG_IDS";

export interface IActionsUpdateMethodSectionTitle extends Action {
  type: "UPDATE_METHOD_SECTION_TITLE";
  index: number;
  newTitle: string;
}

export interface IActionsDeleteMethodSection extends Action {
  type: "DELETE_METHOD_SECTION";
  index: number;
}

export interface IActionsCreateMethodSection extends Action {
  type: "CREATE_METHOD_SECTION";
  newSection: MethodContents;
}

export interface IActionsUpdateMethodSectionIsCollapsed extends Action {
  type: "UPDATE_METHOD_SECTION_IS_COLLAPSED";
  index: number;
  isCollapsed: boolean;
}

export interface IActionsUpdateVolumeConversion extends Action {
  type: "UPDATE_VOLUME_CONVERSION";
  volumeConversion: VolumeConversionObject;
}

export interface IActionsUpdateDocumentMethod extends Action {
  type: "UPDATE_DOCUMENT_METHOD";
  section: MethodContents;
  index: number;
}
export interface IActionsUpdateDocumentYield extends Action {
  type: "UPDATE_DOCUMENT_YIELD";
  yieldType: YieldType;
  amount: number | undefined;
}

export interface IActionsUpdateDocumentServes extends Action {
  type: "UPDATE_DOCUMENT_SERVES";
  serveType: ServeType;
  amount: number | undefined;
}

export interface IActionsUpdateDocumentName extends Action {
  type: "UPDATE_DOCUMENT_NAME";
  name: string;
}

export interface IActionsUpdateDocumentIdentifier extends Action {
  type: "UPDATE_DOCUMENT_IDENTIFIER";
  identifier: string;
}

export interface IActionsUpdateDocumentAltIdentifier extends Action {
  type: "UPDATE_DOCUMENT_ALT_IDENTIFIER";
  altIdentifier: string;
}

export interface IActionsUpdateDocumentDescription extends Action {
  type: "UPDATE_DOCUMENT_DESCRIPTION";
  description: string;
}

export interface IActionsUpdateDocumentModifiedDate extends Action {
  type: "UPDATE_DOCUMENT_MODIFIED_DATE";
  date: string;
}

export interface IActionsToggleIsLiquid extends Action {
  type: "TOGGLE_PROPERTY_IS_LIQUID";
}

export interface IActionsUpdateDocumentStatus extends Action {
  type: "UPDATE_DOCUMENT_STATUS";
  status : DocumentStatus
}

export interface IActionsUpdateDocumentNote extends Action {
  type: "UPDATE_DOCUMENT_NOTE";
  note: string;
}

export interface IActionsUpdateCalculationMethod extends Action {
  type: "UPDATE_CALCULATION_METHOD";
  calculationMethod: number;
  mappedDocumentId: string;
}

export interface IActionsSetDocumentMappingId extends Action {
  type: "SET_DOCUMENT_MAPPING_ID";
  documentId: string;
}

export interface IActionsSetDocumentTagIds extends Action {
  type: "SET_DOCUMENT_TAG_IDS";
  tagIds: string[];
}

export type DocumentActions =
  | IActionsUpdateMethodSectionTitle
  | IActionsUpdateMethodSectionIsCollapsed
  | IActionsCreateMethodSection
  | IActionsDeleteMethodSection
  | IActionsUpdateVolumeConversion
  | IActionsUpdateDocumentName
  | IActionsUpdateDocumentIdentifier
  | IActionsUpdateDocumentAltIdentifier
  | IActionsUpdateDocumentDescription
  | IActionsUpdateDocumentMethod
  | IActionsUpdateDocumentServes
  | IActionsUpdateDocumentYield
  | IActionsToggleIsLiquid
  | IActionsUpdateDocumentStatus
  | IActionsUpdateDocumentModifiedDate
  | IActionsUpdateDocumentNote
  | IActionsUpdateCalculationMethod
  | IActionsSetDocumentMappingId
  | IActionsSetDocumentTagIds
  | SectionTagsActions
  | DaysActions
  | SectionsActions
  | FoodItemsActions
  | NutrientOverridesActions
  | CommonMeasuresActions;

import React, { FunctionComponent, ReactNode } from "react";

import { makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { appTheme } from "../../../../../../styling/style";
import { CategoryRow, CategoryRowProps } from "./CategoryRow";
import { TABLE_COLUMN_STYLES } from "./constants";
import {
  documentMappingIdSelector,
  templateIdSelector,
} from "../../../../../../store/data/current-document/selectors/document";
import { RootState } from "../../../../../../store/reducers";
import { FOODS } from "../../../../../../constants/FoodTemplate";

const NUTRIENT_COMPONENT_HEADER = "Nutrient/Component";
const DEFAULT_HEADER = "Default";
const OVERRIDE_HEADER = "Override";
const VALUE_HEADER = "Value";
const RESULT_HEADER = "Result";
const NOTE_HEADER = "Note";

const useStyles = makeStyles({
  root: {
    height: "100%",
    backgroundColor: appTheme.colors.white[0],
    flex: 1,
    overflowY: "auto",
    paddingBottom: 300,
  },
  header: {
    backgroundColor: appTheme.colors.white[12],
    border: `1px solid ${appTheme.colors.gainsbro}`,
    borderRadius: 4,
    margin: 10,
    padding: "5px 0px",
    display: "flex",
  },
  name: TABLE_COLUMN_STYLES.name,
  numeric: TABLE_COLUMN_STYLES.numeric,
  override: TABLE_COLUMN_STYLES.override,
  result: TABLE_COLUMN_STYLES.numeric,
  noteInput: TABLE_COLUMN_STYLES.noteInput,
  tableMain: {
    overflowX: "auto",
  },
});

interface OverridesTableProps {
  categories: CategoryRowProps[];
}

export const OverridesTable: FunctionComponent<OverridesTableProps> = ({
  categories,
}) => {
  const classes = useStyles();

  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const isMapped: boolean = Boolean(
    useSelector<RootState, string>(documentMappingIdSelector)
  );

  const showDefaultColumn: boolean =
    templateId !== FOODS.id.toString() || isMapped;

  const subTableHeader = (
    <div className={classes.header}>
      <div className={classes.name}>
        <Typography variant="h5">{NUTRIENT_COMPONENT_HEADER}</Typography>
      </div>
      {showDefaultColumn ? (
        <div className={classes.numeric}>
          <Typography variant="h5">{DEFAULT_HEADER}</Typography>
        </div>
      ) : null}
      <div className={classes.override}>
        <Typography variant="h5">
          {templateId !== FOODS.id.toString() ? OVERRIDE_HEADER : VALUE_HEADER}
        </Typography>
      </div>
      <div className={classes.numeric}>
        <Typography variant="h5">{RESULT_HEADER}</Typography>
      </div>
      <div className={classes.noteInput}>
        <Typography variant="h5">{NOTE_HEADER}</Typography>
      </div>
    </div>
  );

  return (
    <div className={classes.root} data-cy="overridesTable">
      {subTableHeader}
      <div className={classes.tableMain}>
        {categories.map(
          (category: CategoryRowProps): ReactNode => (
            <CategoryRow
              key={`category-${category.categoryName}`}
              categoryId={category.categoryId}
              categoryName={category.categoryName}
              nutrients={category.nutrients}
            />
          )
        )}
      </div>
    </div>
  );
};

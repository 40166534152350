import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Grid, makeStyles, Typography, Theme } from "@material-ui/core";

import {
  Measurement,
  MeasurementCategory,
} from "../../../../../../data/models/clientProperties/measurement";
import CreateNewMeasurement from "../components/CreateNewMeasurement";
import {
  addClientMeasurement,
  toggleClientMeasurement,
} from "../../../../../../store/data/current_client/action_creators/clientMeasurements";
import MeasurementsSearch from "../components/MeasurementsSearch";
import MeasurementsList from "../components/MeasurementsList";
import SortMeasurementsList from "../components/SortMeasurementsList";
import { useMeasurements } from "../hooks/useMeasurements";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  card: {
    overflowY: "auto",
    height: 500,
  },
  gridContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  filterContainer: {
    paddingLeft: 20,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },
  categoryHeading: {
    margin: 10,
  },
}));

interface Props {
  category: MeasurementCategory;
}

const MeasurementsDialogue = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onToggleClientMeasurement = (label: string, unit: string) =>
    dispatch(toggleClientMeasurement(label, unit));

  const onAddClientMeasurement = (measurement: Measurement) =>
    dispatch(addClientMeasurement({ ...measurement, active: true }));

  const [searchText, setSearchText] = useState<string>("");
  const [sortOption, setSortOption] = useState<string>("Active");

  const { category } = { ...props };

  const [addedMeasurementsList, sortedMeasurements] = useMeasurements(
    category,
    searchText,
    sortOption
  );

  const toggleActive = (measurement: Measurement): void => {
    addedMeasurementsList.includes(measurement.label)
      ? onToggleClientMeasurement(measurement.label, measurement.unit)
      : onAddClientMeasurement(measurement);
  };

  const subCategories = new Set(
    sortedMeasurements.map((measurement) => measurement.subCategory)
  );

  const makeMeasurementsList = () => {
    return sortOption === "Category" ? (
      [...subCategories].map((subCategory: string) => {
        return (
          <>
            <Grid item xs={12} className={classes.categoryHeading}>
              <Typography variant={"h3"} align="center">
                {subCategory}
              </Typography>
            </Grid>
            <MeasurementsList
              sortedMeasurements={sortedMeasurements.filter(
                (measurement) => measurement.subCategory === subCategory
              )}
              toggleActive={toggleActive}
            />
          </>
        );
      })
    ) : (
      <MeasurementsList
        key="measurementsDialog-measurementsList"
        sortedMeasurements={sortedMeasurements}
        toggleActive={toggleActive}
      />
    );
  };
  return (
    <>
      <MeasurementsSearch
        searchText={searchText}
        fetchSearchText={setSearchText}
        category={category}
      />
      <Card className={classes.card}>
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          className={classes.filterContainer}
        >
          <Grid item>
            <CreateNewMeasurement category={props.category} />
          </Grid>
          <Grid item>
            <SortMeasurementsList
              sortOption={sortOption}
              setSortOption={setSortOption}
            />
          </Grid>
        </Grid>
        <Grid className={classes.gridContainer} container spacing={1}>
          {sortedMeasurements.length > 0 ? (
            makeMeasurementsList()
          ) : (
            <Grid item>
              <Typography>No measurements found</Typography>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default MeasurementsDialogue;

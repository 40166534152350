import _ from "lodash";
import { batch } from "react-redux";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import {
  Client,
  clientSummariesAreEqual,
  ClientSummary,
} from "../../../../data/models/client";
import { getCurrentDate } from "../../../../data/models/documentProperties/date";
import { getClientRouteData } from "../../../../data/routing/routing";
import { RootState } from "../../../reducers";
import { ExtraArguments } from "../../../store";
import { handleRouteChange } from "../../../ui/thunks/routing";
import {
  addClientSummary,
  setCurrentClient,
  setServerClient,
  updateClientSummary,
} from "../../action-creators/clientDatabase";
import {
  IActionsAddClientSummary,
  IActionsSetCurrentClient,
  IActionsSetServerClient,
  IActionsUpdateClientSummary,
} from "../../actions/clientDatabase";
import { IActionsFetchComposition } from "../../actions/compositionCache";
import { IActionsUpdateUserDocumentSummary } from "../../actions/database";
import { IActionsUpdateCachedDocument } from "../../actions/documentCache";
import { TEMPORARY_CLIENT } from "../../reducers/clientDatabase";
import {
  clientSummariesSelector,
  currentClientIdSelector,
  currentClientSelector,
  serverClientSelector,
} from "../../selectors/clientDatabase";
import { IActionsAddDocumentId } from "../actions/client";
import { addDocumentId } from "../action_creators/client";

export const createNewClient = (): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  | IActionsUpdateCachedDocument
  | IActionsFetchComposition
  | IActionsUpdateUserDocumentSummary
> => async (dispatch) => {
  dispatch(handleRouteChange(getClientRouteData(TEMPORARY_CLIENT)));
};

export const saveClient = (): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  Action
> => async (dispatch, getState, { firebase }) => {
  const currentClient: Client = currentClientSelector(getState());

  const currentClientId: string = currentClientIdSelector(getState());

  if (currentClientId === TEMPORARY_CLIENT) {
    const clientDatabaseId: string = await firebase.userPermissions.doGetUserClientDatabase(
      firebase.auth.currentUser!.uid
    );

    const clientId: string = await dispatch(
      createClientDocument(clientDatabaseId, currentClient)
    );

    await dispatch(
      createClientSummary(clientDatabaseId, clientId, currentClient)
    );

    dispatch(setServerClient(currentClient));
    dispatch(handleRouteChange(getClientRouteData(clientId)));
    return;
  }

  await dispatch(updateClient(currentClientId, currentClient));

  return;
};

const createClientDocument = (
  clientDatabaseId: string,
  client: Client
): ThunkAction<Promise<string>, RootState, ExtraArguments, Action> => async (
  _dispatch,
  _getState,
  { firebase }
) => firebase.clientDatabases.doCreateClient(clientDatabaseId, client);

const createClientSummary = (
  clientDatabaseId: string,
  clientId: string,
  client: Client
): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  IActionsAddClientSummary
> => async (dispatch, _getState, { firebase }) => {
  firebase.clientDatabases
    .doCreateClientSummary(clientDatabaseId, clientId, client)
    .then((clientSummary) => dispatch(addClientSummary(clientSummary)));
};

export const addDocumentToClient = (
  documentId: string
): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  IActionsAddDocumentId
> => async (dispatch, getState) => {
  const serverClient: Client | undefined = serverClientSelector(getState());

  const currentClientId: string = currentClientIdSelector(getState());

  if (!serverClient) {
    return;
  }

  const updatedClient: Client = {
    ...serverClient,
    documents: _.union(serverClient.documents, [documentId]),
  };

  dispatch(updateClient(currentClientId, updatedClient)).then(() =>
    dispatch(addDocumentId(documentId))
  );
};

const createCurrentClientSummary = (
  clientId: string,
  client: Client
): ClientSummary => ({
  label: client.name,
  clientId,
  date: client.date,
  tagIds: client.tagIds,
  state: client.state,
});

const getPreviousClientSummary = (
  clientId: string,
  summaries: ClientSummary[]
): ClientSummary =>
  summaries.find((summary: ClientSummary) => summary.clientId === clientId)!;

const getSummariesToCompare = (
  clientId: string,
  client: Client,
  summaries: ClientSummary[]
): Promise<[ClientSummary, ClientSummary]> =>
  Promise.all([
    getPreviousClientSummary(clientId, summaries),
    createCurrentClientSummary(clientId, client),
  ]);

const updateClientLastModified = (client: Client): Client => ({
  ...client,
  date: { ...client.date, lastModified: getCurrentDate() },
});

export const updateClientTagId = (
  clientId: string,
  tagIds: string[]
): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  | IActionsSetServerClient
  | IActionsUpdateClientSummary
  | IActionsSetCurrentClient
> => async (dispatch, getState, { firebase }) => {
  const databaseId: string = await firebase.userPermissions.doGetUserClientDatabase(
    firebase.auth.currentUser!.uid
  );

  const client = await firebase.clientDatabases.doGetClient(
    databaseId,
    clientId
  );

  const updatedClient: Client = {
    ...client,
    tagIds,
  };

  const lastClient = updateClientLastModified(updatedClient);

  await firebase.clientDatabases.doUpdateClient(
    databaseId,
    clientId,
    lastClient
  );

  batch(() => {
    dispatch(setServerClient(lastClient));
    dispatch(setCurrentClient(lastClient));
  });
};

export const deleteClient = (
  clientId: string
): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  | IActionsSetServerClient
  | IActionsUpdateClientSummary
  | IActionsSetCurrentClient
> => async (dispatch, getState, { firebase }) => {
  const databaseId: string = await firebase.userPermissions.doGetUserClientDatabase(
    firebase.auth.currentUser!.uid
  );

  const client = await firebase.clientDatabases.doGetClient(
    databaseId,
    clientId
  );

  let updatedClient: Client = {
    ...client,
    state: "archived",
  };
  if (client.state === "archived") {
    updatedClient = {
      ...client,
      state: "deleted",
    };
  }

  dispatch(updateClient(clientId, updatedClient));
};

export const updateClient = (
  clientId: string,
  updatedClient: Client
): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  | IActionsSetServerClient
  | IActionsUpdateClientSummary
  | IActionsSetCurrentClient
> => async (dispatch, getState, { firebase }) => {
  const clientSummaries: ClientSummary[] = clientSummariesSelector(getState());

  const client = updateClientLastModified(updatedClient);

  await firebase.userPermissions
    .doGetUserClientDatabase(firebase.auth.currentUser!.uid)
    .then((clientDatabaseId) =>
      Promise.all([
        firebase.clientDatabases
          .doUpdateClient(clientDatabaseId, clientId, client)
          .then(() =>
            batch(() => {
              dispatch(setServerClient(client));
              dispatch(setCurrentClient(client));
            })
          ),
        getSummariesToCompare(clientId, client, clientSummaries).then(
          ([previousSummary, currentSummary]) => {
            if (!clientSummariesAreEqual(previousSummary, currentSummary)) {
              firebase.clientDatabases
                .doUpdateClientSummary(
                  clientDatabaseId,
                  previousSummary,
                  currentSummary
                )
                .then(() => dispatch(updateClientSummary(currentSummary)));
            }
          }
        ),
      ])
    );

  return;
};

import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import FoodworksDateTimePicker from "../../../../../../common/date/FoodworksDateTimePicker";
import { FoodWorksNumberInput } from "../../../../../../common/FoodWorksTextInput";
import { usePreviousMeasurement } from "../hooks/usePreviousMeasurement";
import { TextOverflowTooltip } from "../../../../../../common/InfoTooltip";

const useStyles = makeStyles(() => ({
  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
  dateContainer: {
    display: "flex",
    alignItems: "start",
    marginTop: 10,
  },
  dateTextContainer: {
    height: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  inputHeading: {
    minWidth: 50,
  },
  inputValueContainer: {
    display: "flex",
    alignItems: "center",
  },
  valueInput: {
    width: 75,
    marginRight: 10,
  },
  unitText: {
    width: 75,
  },
  inputDateContainer: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
}));

interface Props {
  measurementTitle: string;
  measurementUnits: string;
  measurementValue: string | undefined;
  measurementDate: Date | null;
  setMeasurementValue: (value: string) => void;
  setMeasurementDate: (date: Date | null) => void;
  submitButton: any;
}

const MeasurementInputs = React.memo((props: Props): JSX.Element => {
  const classes = useStyles();

  const {
    measurementTitle,
    measurementUnits,
    measurementDate,
    measurementValue,
    setMeasurementValue,
    setMeasurementDate,
    submitButton,
  } = { ...props };

  const latestMeasurement: { value: number; date: Date } | undefined =
    usePreviousMeasurement(measurementTitle, measurementUnits);

  return (
    <div>
      <div className={classes.inputContainer}>
        <div className={classes.inputValueContainer}>
          <Typography className={classes.inputHeading}>Value</Typography>

          <FoodWorksNumberInput
            decimalPlaces={2}
            inputProps={{
              value: measurementValue ? measurementValue.toString() : "",
              className: classes.valueInput,
            }}
            setValue={(input) => setMeasurementValue(input)}
            placeholder={
              latestMeasurement ? latestMeasurement.value.toString() : undefined
            }
          />
          <TextOverflowTooltip label={measurementUnits}>
            <Typography className={classes.unitText} noWrap>
              {measurementUnits}
            </Typography>
          </TextOverflowTooltip>
        </div>
      </div>
      <div className={classes.dateContainer}>
        <div className={classes.dateTextContainer}>
          <Typography className={classes.inputHeading}>Date</Typography>
        </div>
        <div className={classes.inputDateContainer}>
          <FoodworksDateTimePicker
            placeholder={latestMeasurement ? latestMeasurement.date : null}
            date={measurementDate}
            handleChange={setMeasurementDate}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>{submitButton}</div>
    </div>
  );
});

export default MeasurementInputs;

import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { RootState } from "../../../reducers";
import {
  IActionsSetDocumentData,
  IActionsUpdateServerDocument,
} from "../actions/currentDocument";
import {
  IActionsAddUserDocumentSummary,
  IActionsUpdateUserDocumentSummary,
} from "../../actions/database";
import { updateServerDocument } from "../action-creators/currentDocument";
import { UserDatabaseSummary } from "../../../../data/models/userDatabase";
import {
  CurrentDocumentIdSelector,
  ServerDocumentSelector,
} from "../selectors/currentDocument";
import { databaseIdSelector } from "../../selectors/database";
import { documentSelector } from "../selectors/document";
import {
  Document,
  getUniqueDocumentIds,
} from "../../../../data/models/document";
import {
  IActionsAddDocument,
  IActionsUpdateCachedDocument,
} from "../../actions/documentCache";
import {
  addDocument,
  updateCachedDocument,
} from "../../action-creators/documentCache";
import {
  allCachedDocumentsSelector,
  cachedDocumentSelector,
} from "../../selectors/documentCache";
import { TEMPORARY_DOCUMENT } from "../reducers/currentDocument";
import { IActionsSetUserDatabaseSummaries } from "../../actions/user";
import { getCurrentDate } from "../../../../data/models/documentProperties/date";
import { IActionsUpdateDocumentModifiedDate } from "../actions/document";
import { IActionsSetDisableSaveButton } from "../../../ui/actions/documentEditing";
import { ENABLED_DATA_SOURCES } from "../../../../constants/datasources";
import { fetchDocument } from "../../../../data/Firebase/helpers/fetchDocument";
import { FoodId } from "../../../../data/models/documentProperties/foodId";
import { FoodItemState } from "../../../../data/models/documentProperties/foodItem";
import { IActionsFetchComposition } from "../../actions/compositionCache";
import {
  addDocumentUsedIn,
  createDocument,
  removeDocumentUsedIn,
  updateDocument,
} from "./document";
import { updateDatabaseLastModifiedDate } from "../../thunks/database";
import {  userDatabaseSummariesSelector } from "../../selectors/user";
import { ExtraArguments } from "../../../store";
import { IActionsSetNextRoute } from "../../../ui/actions/routing";
import { handleRouteChange } from "../../../ui/thunks/routing";
import { DocumentMap } from "../../reducers/documentCache";
import {
  clientUserDatabaseSelector,
  currentClientIdSelector,
  currentClientSelector,
} from "../../selectors/clientDatabase";
import { addDocumentToClient } from "../../current_client/thunks/client";
import {
  getClientDocumentRouteData,
  getDatabaseDocumentRouteData,
} from "../../../../data/routing/routing";
import { Client } from "../../../../data/models/client";
import { setRecentMappedDocumentId } from "../../../ui/actionCreators/overridesScreen";
import { IActionsSetRecentMappedDocumentId } from "../../../ui/actions/overridesScreen";
import { getDocument } from "./getDocument";

type saveDocumentActions =
  | IActionsSetDocumentData
  | IActionsAddUserDocumentSummary
  | IActionsUpdateUserDocumentSummary
  | IActionsUpdateCachedDocument
  | IActionsAddDocument
  | IActionsUpdateServerDocument
  | IActionsUpdateDocumentModifiedDate
  | IActionsSetUserDatabaseSummaries
  | IActionsSetDisableSaveButton
  | IActionsFetchComposition;

export function getDocumentsToUpdateUsedIn(
  databaseId: string,
  currentDocument: Document,
  serverDocument: Document
): [FoodId[], FoodId[]] {
  const currentDocumentUniqueIds: string[] = getUniqueDocumentIds(
    [databaseId],
    currentDocument
  );

  const serverDocumentUniqueIds: string[] = getUniqueDocumentIds(
    [databaseId],
    serverDocument
  );

  const idsToAdd: FoodId[] = [];

  for (const id of currentDocumentUniqueIds) {
    if (!serverDocumentUniqueIds.includes(id)) {
      const idParts = id.split(":");
      idsToAdd.push(
        new FoodId({ datasourceId: idParts[0], documentId: idParts[1] })
      );
    }
  }

  const idsToRemove: FoodId[] = [];

  for (const id of serverDocumentUniqueIds) {
    if (!currentDocumentUniqueIds.includes(id)) {
      const idParts = id.split(":");
      idsToRemove.push(
        new FoodId({ datasourceId: idParts[0], documentId: idParts[1] })
      );
    }
  }

  return [idsToAdd, idsToRemove];
}

export const saveDocument =
  (
    documentId: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    saveDocumentActions
  > =>
  async (dispatch, getState, { firebase }) => {
    const rootState: RootState = getState();

    const currentDatabaseId: string = databaseIdSelector(rootState);
    const clientUserDatabaseId: string = clientUserDatabaseSelector(rootState);
    const userDatabaseSummaries: UserDatabaseSummary[] =
      userDatabaseSummariesSelector(rootState);
    const currentDocument: Document = documentSelector(rootState);
    const serverDocument: Document | undefined =
      ServerDocumentSelector(rootState);

    const currentClient: Client = currentClientSelector(rootState);

    const currentDate = getCurrentDate();

    await dispatch(
      updateMeasureReferences(currentDocument, serverDocument, documentId)
    );

    const updatedDocument: Document = {
      ...currentDocument,
      date: { ...currentDocument.date, lastModified: currentDate },
    };

    if (documentId === TEMPORARY_DOCUMENT) {
      await dispatch(createDocument(currentDatabaseId, updatedDocument)).then(
        (newDocumentId: string) => {
          dispatch(updateServerDocument(updatedDocument));
          dispatch(changeCurrentDocument(newDocumentId));
          documentId = newDocumentId;
        }
      );
    } else {
      await dispatch(
        updateDocument(
          currentDatabaseId,
          documentId,
          serverDocument!,
          updatedDocument
        )
      );
      dispatch(updateServerDocument(updatedDocument));
    }

    const [idsToAdd, idsToRemove] = getDocumentsToUpdateUsedIn(
      currentDatabaseId,
      updatedDocument,
      serverDocument!
    );

    const currentDocumentIdentifer: string = `${currentDatabaseId}:${documentId}`;

    for (const foodId of idsToAdd) {
      dispatch(addDocumentUsedIn(foodId, currentDocumentIdentifer));
    }

    for (const foodId of idsToRemove) {
      dispatch(removeDocumentUsedIn(foodId, currentDocumentIdentifer));
    }

    const previousDatabaseSummary: UserDatabaseSummary =
      userDatabaseSummaries.find(
        (summary: UserDatabaseSummary): boolean =>
          summary.id === currentDatabaseId
      )!;

    if (
      currentDatabaseId === clientUserDatabaseId &&
      !currentClient.documents.includes(documentId)
    ) {
      dispatch(addDocumentToClient(documentId));
    }

    await dispatch(
      updateDatabaseLastModifiedDate({
        ...previousDatabaseSummary,
        date: { ...previousDatabaseSummary.date, lastModified: currentDate },
      })
    );
  };

export const getUpdatedRecipes = (
  measureDocument: Document,
  measureAdded: boolean,
  documentId: string,
  measureId: string
) => {
  const recipesUsingMeasure: string[] =
    measureDocument.commonMeasures.measures.find(
      (element) => element.id === measureId
    )?.usedIn || [];

  if (measureAdded) {
    if (recipesUsingMeasure.includes(documentId)) {
      return recipesUsingMeasure;
    }
    return recipesUsingMeasure.concat(documentId);
  }
  return recipesUsingMeasure.filter(
    (recipeId: string) => recipeId !== documentId
  );
};

export const handleMeasureUsedInUpdate =
  (
    foodItem: FoodItemState,
    documentIdToSave: string,
    measureId: string,
    shouldAdd: boolean
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddDocument | IActionsUpdateCachedDocument
  > =>
  async (dispatch, getState, { firebase }) => {
    const documentCache: DocumentMap = allCachedDocumentsSelector(getState());
    const measureDocument = await fetchDocument(
      firebase,
      new FoodId(foodItem.foodId!),
      false
    );

    const updatedRecipes = getUpdatedRecipes(
      measureDocument,
      shouldAdd,
      documentIdToSave,
      measureId
    );

    measureDocument.commonMeasures.measures =
      measureDocument.commonMeasures.measures.map((element) => {
        if (element.id === measureId) {
          return {
            ...element,
            usedIn: updatedRecipes,
          };
        }
        return element;
      });

    await firebase.userDatabases.doUpdateUserDocument(
      foodItem.foodId!.datasourceId,
      foodItem.foodId!.documentId,
      measureDocument
    );

    const foodId = new FoodId(foodItem.foodId!);

    foodId.identifier in documentCache
      ? dispatch(updateCachedDocument(foodId.identifier, measureDocument))
      : dispatch(addDocument(measureDocument, foodId.identifier));
  };

export const getValidFoodItems = (
  document: Document | undefined
): FoodItemState[] => {
  let documentSet = new Set<FoodItemState>();
  if (!document) return [...documentSet];

  for (const day of document.days) {
    for (const section of day.sections) {
      for (const foodItem of section.foodItems) {
        const isPublicMeasure = ENABLED_DATA_SOURCES.includes(
          foodItem.foodId?.datasourceId || ""
        );

        const isReferenceMeasure =
          foodItem.quantity && Number(foodItem.quantity.measureId);

        const isValidMeasure = !isPublicMeasure && !isReferenceMeasure;

        if (foodItem.quantity && isValidMeasure) {
          documentSet.add(foodItem);
        }
      }
    }
  }

  return [...documentSet];
};

export const updateMeasureReferences =
  (
    document: Document,
    serverDocument: Document | undefined,
    documentIdToSave: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsAddDocument | IActionsUpdateCachedDocument
  > =>
  async (dispatch) => {
    const documentItems: FoodItemState[] = getValidFoodItems(document);
    const serverItems: FoodItemState[] = getValidFoodItems(serverDocument);

    const documentMeasures = documentItems.map(
      (item: FoodItemState): string => item.quantity!.measureId
    );

    const serverMeasures = serverItems.map(
      (item: FoodItemState): string => item.quantity!.measureId
    );

    for (let i = 0; i < documentMeasures.length; i++) {
      const measureId = documentMeasures[i];

      if (!serverMeasures.includes(measureId)) {
        const foodItem = documentItems[i];

        await dispatch(
          handleMeasureUsedInUpdate(foodItem, documentIdToSave, measureId, true)
        );
      }
    }

    for (let i = 0; i < serverMeasures.length; i++) {
      const measureId = serverMeasures[i];

      if (!documentMeasures.includes(measureId)) {
        const foodItem = serverItems[i];

        await dispatch(
          handleMeasureUsedInUpdate(
            foodItem,
            documentIdToSave,
            measureId,
            false
          )
        );
      }
    }
  };

export const changeCurrentDocument =
  (
    documentId: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionsSetNextRoute
  > =>
  async (dispatch, getState) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const clientUserDatabaseId: string = clientUserDatabaseSelector(getState());

    const currentClientId: string = currentClientIdSelector(getState());

    if (currentDatabaseId === clientUserDatabaseId) {
      return dispatch(
        handleRouteChange(
          getClientDocumentRouteData(currentClientId, documentId)
        )
      );
    }

    return dispatch(
      handleRouteChange(
        getDatabaseDocumentRouteData(currentDatabaseId, documentId)
      )
    );
  };

const getFoodItems = (currentDocument: Document) => {
  let foodItems: FoodItemState[] = [];
  for (const day of currentDocument.days) {
    for (const section of day.sections) {
      for (const foodItem of section.foodItems) {
        foodItems.push(foodItem);
      }
    }
  }
  return foodItems;
};

const checkIfUsedAsIngredient = (
  currentDocument: Document,
  mappedDocSource: string,
  mappedDocId: string,
  currentDatabaseId: string,
  currentDocumentId: string,
  dispatch: ThunkDispatch<
    RootState,
    ExtraArguments,
    IActionsSetRecentMappedDocumentId
  >
) => {
  const foodItems = getFoodItems(currentDocument);
  if (
    !foodItems.some(
      (foodItem) =>
        foodItem.foodId!.datasourceId + ":" + foodItem.foodId!.documentId ===
        mappedDocSource + ":" + mappedDocId
    )
  ) {
    dispatch(
      removeDocumentUsedIn(
        new FoodId({
          datasourceId: mappedDocSource,
          documentId: mappedDocId,
        }),
        currentDatabaseId + ":" + currentDocumentId
      )
    );
  }
};

const addToUsedIn = (
  selectedDocument: Document,
  currentDatabaseId: string,
  currentDocumentId: string,
  dataSource: string,
  documentId: string,
  dispatch: ThunkDispatch<
    RootState,
    ExtraArguments,
    IActionsSetRecentMappedDocumentId
  >
) => {
  if (
    !selectedDocument!.usedIn.includes(
      currentDatabaseId + ":" + currentDocumentId
    )
  ) {
    dispatch(
      addDocumentUsedIn(
        new FoodId({
          datasourceId: dataSource,
          documentId: documentId,
        }),
        currentDatabaseId + ":" + currentDocumentId
      )
    );
  }
};

export const setMappedDocumentId =
  (
    documentAsOverride: string
  ): ThunkAction<
    Promise<IActionsSetRecentMappedDocumentId>,
    RootState,
    ExtraArguments,
    IActionsSetRecentMappedDocumentId
  > =>
  async (dispatch, getState) => {
    const [dataSource, documentId] = documentAsOverride.split(":");
    const currentDatabaseId: string = databaseIdSelector(getState());
    const currentDocument = documentSelector(getState());
    const currentDocumentId: string = CurrentDocumentIdSelector(getState());
    const [mappedDocSource, mappedDocId] =
      currentDocument.documentMappingId.split(":");
    const isAusFoodsDB = dataSource !== currentDatabaseId;

    // Initialize document to be used as override into Redux
    // No need to do this if it's an AusFoods DB
    let selectedDocument = undefined;
    if (!isAusFoodsDB) {
      selectedDocument = cachedDocumentSelector(getState(), documentAsOverride);
      if (!selectedDocument) {
        await dispatch(
          getDocument(
            new FoodId({
              datasourceId: dataSource,
              documentId: documentId,
            }),
            false,
            false
          )
        );
      }
      selectedDocument = cachedDocumentSelector(getState(), documentAsOverride);
    }

    // Check if new document is an AusFoods Document
    if (isAusFoodsDB) {
      // If ausfoods dataSource, no need to add to usedIn
      // If previously mapped document is not AusFoods, remove from used in
      if (!(mappedDocSource !== currentDatabaseId))
        checkIfUsedAsIngredient(
          currentDocument,
          mappedDocSource,
          mappedDocId,
          currentDatabaseId,
          currentDocumentId,
          dispatch
        );
      return dispatch(setRecentMappedDocumentId(documentAsOverride));
    } else {
      // Add to used in
      addToUsedIn(
        selectedDocument!,
        currentDatabaseId,
        currentDocumentId,
        dataSource,
        documentId,
        dispatch
      );
      // Remove previous mapping from usedIn? Only if not an ingredient!!!
      if (!(mappedDocSource !== currentDatabaseId))
        checkIfUsedAsIngredient(
          currentDocument,
          mappedDocSource,
          mappedDocId,
          currentDatabaseId,
          currentDocumentId,
          dispatch
        );

      return dispatch(setRecentMappedDocumentId(documentAsOverride));
    }
  };

import { Action } from "redux";

export const ADD_ALERT = "ADD_ALERT";
export const RESET_ALERT = "RESET_ALERT";

export interface IActionsAddAlert extends Action {
  type: "ADD_ALERT";
  message: string;
}

export interface IActionsResetAlert extends Action {
  type: "RESET_ALERT";
}

export type AlertActions = IActionsAddAlert | IActionsResetAlert;

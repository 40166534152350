import React, { FunctionComponent, ReactNode, useState } from "react";

import { useSelector } from "react-redux";
import {
  Typography,
  IconButton,
  List,
  ListItem,
  Collapse,
  makeStyles,
} from "@material-ui/core";
import {
  Edit,
  ExpandLess,
  ExpandMore,
  FiberManualRecord,
} from "@material-ui/icons";

import { Nutrient } from "../../../../data/models/nutrient";
import { enabledNutrientsDataSelector } from "../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import { NutrientsDialog } from "../../../dialogs/database/NutrientsDialog";

const useStyles = makeStyles(() => ({
  listRoot: { overflow: "auto", maxHeight: 200 },
  listItemRoot: {
    display: "flex",
  },
  bulletPoint: {
    color: appTheme.colors.xiketic,
    marginRight: 2,
    width: 5,
    height: 5,
  },
  nutrientListPart: {
    display: "flex",
    alignItems: "center",
  },
  headerRoot: {
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    textDecoration: "underline",
  },
  editIcon: {
    color: appTheme.colors.secondary,
    width: 20,
    marginLeft: 5,
  },
}));

type NutrientPair = [string, Nutrient];

interface EnabledNutrientsListProps {
  openDialog: boolean;
  onToggleDialog: () => void;
}

export const EnabledNutrientsList: FunctionComponent<EnabledNutrientsListProps> = ({
  openDialog,
  onToggleDialog,
}) => {
  const classes = useStyles();

  const enabledNutrients: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >((state: RootState) => enabledNutrientsDataSelector(state));

  const [showList, setShowList] = useState(false);

  const generatePairedList = (): [NutrientPair, NutrientPair | undefined][] => {
    const nutrients: NutrientPair[] = [...enabledNutrients];
    const pairedNutrients: [NutrientPair, NutrientPair | undefined][] = [];
    for (let i = 0; i < Math.ceil(nutrients.length / 2); ++i) {
      let nutrientPairA, nutrientPairB;
      nutrientPairA = nutrients[i * 2];
      if (i * 2 + 1 < nutrients.length) {
        nutrientPairB = nutrients[i * 2 + 1];
      }

      pairedNutrients.push([nutrientPairA, nutrientPairB]);
    }
    return pairedNutrients;
  };

  const enabledNutrientsHeader: ReactNode = (
    <div className={classes.headerRoot}>
      <IconButton
        size="small"
        color="secondary"
        onClick={() => setShowList((prev) => !prev)}
      >
        {showList ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <Typography className={classes.headerText} variant="h4">
        Nutrients and components
      </Typography>
      <IconButton
        size="small"
        data-cy="nutrientsButton"
        onClick={onToggleDialog}
      >
        <Edit className={classes.editIcon} />
      </IconButton>
      {openDialog && <NutrientsDialog open onClose={onToggleDialog} />}
    </div>
  );

  const createListItem = (label: string, secondItem: boolean): ReactNode => (
    <div className={classes.nutrientListPart}>
      <FiberManualRecord
        className={classes.bulletPoint}
        style={{
          marginLeft: secondItem ? 20 : 0,
        }}
      />
      <Typography>{label}</Typography>
    </div>
  );

  return (
    <div>
      {enabledNutrientsHeader}
      <Collapse in={showList}>
        <List className={classes.listRoot}>
          {generatePairedList().map(
            ([nutrientA, nutrientB]: [
              NutrientPair,
              NutrientPair | undefined
            ]): ReactNode => (
              <ListItem
                key={`enabledNutrientsList-${nutrientA[0]}-${nutrientB?.[0]}`}
                className={classes.listItemRoot}
              >
                {createListItem(nutrientA[1].name, false)}
                {nutrientB && createListItem(nutrientB[1].name, true)}
              </ListItem>
            )
          )}
        </List>
      </Collapse>
    </div>
  );
};

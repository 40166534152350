import { RouteData } from "../../../data/routing/types";
import {
  RoutingActions,
  SET_CURRENT_ROUTE,
  SET_NEXT_ROUTE,
} from "../actions/routing";

export interface RoutingState {
  currentRoute: RouteData | undefined;
  nextRoute: RouteData | undefined;
}

export const initialRoutingState: RoutingState = {
  currentRoute: undefined,
  nextRoute: undefined,
};

export const routing = (
  state: RoutingState = initialRoutingState,
  action: RoutingActions
): RoutingState => {
  switch (action.type) {
    case SET_NEXT_ROUTE:
      return { ...state, nextRoute: action.route };
    case SET_CURRENT_ROUTE:
      return { ...state, nextRoute: undefined, currentRoute: action.route };
    default:
      return state;
  }
};

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Size } from "re-resizable";

import { RootState } from "../../../../../store/reducers";
import { nutritionPaneSizeSelector } from "../../../../../store/ui/selectors/nutritionPaneSelectors";
import { NUT_PANE_SIZE } from "../../../../../constants/paneSize";

const useNutritionPaneWidth = (): number => {
  const nutritionPaneSize: Size = useSelector<RootState, Size>(
    nutritionPaneSizeSelector
  );

  const [nutritionPaneWidth, setNutritionPaneWidth] = useState<number | string>(
    NUT_PANE_SIZE.width
  );

  useEffect(() => {
    setNutritionPaneWidth(nutritionPaneSize.width);
  }, [nutritionPaneSize]);

  return Number(nutritionPaneWidth);
};

export default useNutritionPaneWidth;

import firebase from "firebase";
import { Database } from "../../models/userDatabase";

export class FirebaseFunctions {
  async doGetUserNameByEmail(
    email: string
  ): Promise<{ firstName: string; lastName: string; uid: string }> {
    return firebase
      .functions()
      .httpsCallable("getUserNameFromEmail")({ email })
      .then((data) => {
        if (!data.data) return;
        return data.data;
      });
  }

  async doCreateSharedDatabase(
    uid: string,
    database: Database,
    documents: firebase.firestore.DocumentData[]
  ): Promise<{ success: boolean }> {
    return firebase
      .functions()
      .httpsCallable("addSharedDatabase")({
        uid: uid,
        database: database,
        documents: documents,
      })
      .then((result) => result.data);
  }
}

import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { Chip, Grid } from "@material-ui/core";

import { Tag } from "../../../../data/models/documentProperties/section";
import { setDocumentTagIds } from "../../../../store/data/current-document/action-creators/document";
import { FoodworksTooltip } from "../../../common/InfoTooltip";
import { CreateDocumentTagDialog } from "./CreateDocumentTagDialog";
import { useCurrentDocumentTags, useSummariesTag } from "./hooks/DocumentTags";

interface BaseDocumentTagProps {
  documentId: string;
  disabled?: boolean;
  useLoadTags: [Tag[], Dispatch<SetStateAction<Tag[]>>];
}

export const BaseDocumentTags = ({
  documentId,
  disabled = false,
  useLoadTags,
}: BaseDocumentTagProps): JSX.Element => {
  const dispatch = useDispatch();

  const onDeleteDocumentTag = (tagId: string) => {
    const updatedTagIds: string[] = selectedTags
      .filter((tag) => tag.id !== tagId)
      .map((tag: Tag) => tag.id);
    dispatch(setDocumentTagIds(updatedTagIds));
  };

  const [selectedTags] = useLoadTags;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedTagId, setSelectedTagId] = useState<string>("");

  const onClickTag = (tagId: string) => {
    setOpenDialog(true);
    setSelectedTagId(tagId);
  };

  const handleOnClose = () => {
    setOpenDialog(false);
    setSelectedTagId("");
  };

  const enabledChip = (tag: Tag): ReactNode => {
    return (
      <Chip
        key={`docTag-${documentId}-${tag.id}`}
        size="small"
        label={tag.label}
        clickable
        onClick={() => onClickTag(tag.id)}
        onDelete={() => onDeleteDocumentTag(tag.id)}
        style={{
          backgroundColor: tag.inactiveColor,
          border: `1px solid ${tag.activeColor}`,
        }}
      />
    );
  };

  const disabledChip = (tag: Tag): ReactNode => {
    return (
      <Chip
        key={`docTag-${documentId}-${tag.id}`}
        size="small"
        label={tag.label}
        style={{
          backgroundColor: tag.inactiveColor,
          border: `1px solid ${tag.activeColor}`,
        }}
      />
    );
  };

  const tagButtons: ReactNode = (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      wrap="nowrap"
    >
      {selectedTags.map(
        (tag: Tag): ReactNode => (
          <FoodworksTooltip
            key={`tooltip-${documentId}-${tag.id}`}
            title={tag.label}
          >
            <span>{!disabled ? enabledChip(tag) : disabledChip(tag)}</span>
          </FoodworksTooltip>
        )
      )}
    </Grid>
  );

  return (
    <div>
      {tagButtons}
      <CreateDocumentTagDialog
        open={openDialog}
        onClose={handleOnClose}
        isEditing={true}
        tagId={selectedTagId}
      />
    </div>
  );
};

interface DocumentTagProps {
  documentId: string;
  disabled?: boolean;
}

export const DocumentSummaryTags = ({
  documentId,
  disabled,
}: DocumentTagProps): JSX.Element => {
  return (
    <BaseDocumentTags
      documentId={documentId}
      disabled={disabled}
      useLoadTags={useSummariesTag(documentId)}
    />
  );
};

export const DocumentTags = ({
  documentId,
  disabled,
}: DocumentTagProps): JSX.Element => {
  return (
    <BaseDocumentTags
      documentId={documentId}
      disabled={disabled}
      useLoadTags={useCurrentDocumentTags()}
    />
  );
};

import { CompositionState } from "../../../data/models/composition";
import {
  ADD_COMPOSITION,
  CompositionCacheActions,
} from "../actions/compositionCache";

export interface CompositionMap {
  [key: string]: CompositionState;
}

export type CompositionCacheState = CompositionMap;

export const initialCompositionCacheState = {};

export const compositionCache = (
  state: CompositionCacheState = initialCompositionCacheState,
  action: CompositionCacheActions
): CompositionCacheState => {
  switch (action.type) {
    case ADD_COMPOSITION:
      return { ...state, [action.identifier]: action.composition };
    default:
      return state;
  }
};

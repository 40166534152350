import { useMemo } from "react";
import { useSelector } from "react-redux";

import { Category } from "../../../../../data/models/category";
import {
  Composition,
  CompositionCache,
} from "../../../../../data/models/composition";
import { CompositionCalculator } from "../../../../../data/models/compositionCalculator";
import { DayState } from "../../../../../data/models/documentProperties/day";
import {
  FoodIdObject,
  getIdentifier,
} from "../../../../../data/models/documentProperties/foodId";
import { Quantity } from "../../../../../data/models/documentProperties/quantity";
import { RetentionFactor } from "../../../../../data/models/documentProperties/retentionFactor";
import { SectionState } from "../../../../../data/models/documentProperties/section";
import { Nutrient } from "../../../../../data/models/nutrient";
import { ReferenceMeasure } from "../../../../../data/models/referenceMeasure";
import { CurrentDocumentState } from "../../../../../store/data/current-document/reducers/currentDocument";
import { DocumentMap } from "../../../../../store/data/reducers/documentCache";
import { compositionCacheSelector } from "../../../../../store/data/selectors/compositionCache";
import { allCachedDocumentsSelector } from "../../../../../store/data/selectors/documentCache";
import {
  ReferenceMeasuresSelector,
  getRetentionFactorMap,
  NutrientsDataSelector,
  CategoriesSelector,
} from "../../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../../store/reducers";
import useIntakeGoals from "../../../clients/tabs/analysis/hooks/useIntakeGoals";
import useNutrientsToDisplay from "../../nutrition_pane/hooks/useNutrientsToDisplay";
import { Document } from "../../../../../data/models/document";
import { SERVE_ID } from "../../../../../data/models/documentProperties/measure";

const determineMeasureToUse = ({
  referenceMeasureName,
  commonMeasureName,
  mappedMeasureName,
  servingMeasureName,
}: any): string => {
  if (referenceMeasureName) return referenceMeasureName;
  if (commonMeasureName) return commonMeasureName;
  if (mappedMeasureName) return mappedMeasureName;
  if (servingMeasureName) return servingMeasureName;
  return "";
};

const getFoodMap = (
  document: CurrentDocumentState,
  referenceMeasures: ReferenceMeasure[],
  documentCache: DocumentMap
): Map<
  DayState,
  Map<
    string,
    Map<
      {
        measureName: string | undefined;
        foodId: FoodIdObject | undefined;
        quantity: Quantity | undefined;
        frequency: string;
        rowIndex: number;
        retentionFactorId: string | null;
        note: string;
      },
      string
    >
  >
> => {
  const documentFoodMap: Map<
    DayState,
    Map<
      string,
      Map<
        {
          measureName: string | undefined;
          foodId: FoodIdObject | undefined;
          quantity: Quantity | undefined;
          frequency: string;
          rowIndex: number;
          retentionFactorId: string | null;
          note: string;
        },
        string
      >
    >
  > = new Map(
    document.document.days.map((day: DayState) => [
      day,
      new Map(
        day.sections.map((section: SectionState) => [
          section.title,
          new Map(
            section.foodItems.map(foodItem => {
              const foodDocument: Document =
                documentCache[
                  getIdentifier(
                    foodItem.foodId?.datasourceId || "",
                    foodItem.foodId?.documentId || ""
                  )
                ];
              const measureId = foodItem.quantity?.measureId;
              // Check referenceMeasures
              const referenceMeasureName: string | undefined =
                referenceMeasures.find(
                  referenceMeasure => referenceMeasure.id === measureId
                )?.name;
              //Check common measures
              const commonMeasureName: string | undefined = foodDocument
                ? foodDocument.commonMeasures.measures.find(
                    commonMeasure => commonMeasure.id === measureId
                  )?.name
                : "N/A";

              // Check if there is a mapped food and match to one of its measures
              const mappedMeasureName = foodDocument.documentMappingId
                ? documentCache[
                    foodDocument.documentMappingId
                  ].commonMeasures.measures.find(
                    measure => measure.id === measureId
                  )?.name
                : "";

              // Check serving?
              const servingMeasureName =
                foodItem.quantity?.measureId === SERVE_ID ? "Serve" : "";

              return [
                {
                  ...foodItem,
                  measureName: determineMeasureToUse({
                    referenceMeasureName,
                    commonMeasureName,
                    mappedMeasureName,
                    servingMeasureName,
                  }),
                },
                foodDocument ? foodDocument.name : "Food not properly defined",
              ];
            })
          ),
        ])
      ),
    ])
  );
  return documentFoodMap;
};

const useFoodItemReport = (
  document: CurrentDocumentState
): {
  foodMap: Map<
    DayState,
    Map<
      string,
      Map<
        {
          measureName: string | undefined;
          foodId: FoodIdObject | undefined;
          quantity: Quantity | undefined;
          frequency: string;
          rowIndex: number;
          retentionFactorId: string | null;
          note: string;
        },
        string
      >
    >
  >;
  finalComposition: Composition | undefined;
  nutrientMap: Map<string, number>;
  allNutrientsMap: Map<string, Nutrient>;
  compositionCalculator: CompositionCalculator;
} => {
  //const finalComposition = new Composition(totalComposition);
  const compositionCache: CompositionCache = useSelector<
    RootState,
    CompositionCache
  >(compositionCacheSelector);

  const documentCache: DocumentMap = useSelector<RootState, DocumentMap>(
    allCachedDocumentsSelector
  );

  const referenceMeasures: ReferenceMeasure[] = useSelector<
    RootState,
    ReferenceMeasure[]
  >(ReferenceMeasuresSelector);

  const retentionFactorMap: Map<string, RetentionFactor> = useSelector<
    RootState,
    Map<string, RetentionFactor>
  >(getRetentionFactorMap);

  const allNutrientsMap: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(NutrientsDataSelector);

  const nutrientCategories: Map<string, Category> = useSelector<
    RootState,
    Map<string, Category>
  >(CategoriesSelector);

  const compositionCalculator = new CompositionCalculator(
    compositionCache,
    documentCache,
    referenceMeasures,
    retentionFactorMap
  );

  const getFoodMapMemo = useMemo(() => {
    return getFoodMap(document, referenceMeasures, documentCache);
  }, [document, documentCache, referenceMeasures]);

  const [, finalComposition] = useIntakeGoals(document.id);
  const [, nutrientsToDisplay] = useNutrientsToDisplay();

  const nutrientsToDisplayMap: Map<string, Nutrient> = new Map(
    nutrientsToDisplay
  );

  const nutrientMap: [string, number][] = finalComposition
    ? [...finalComposition?.nutrientValues]
        .filter(([id, value]) => nutrientsToDisplayMap.has(id))
        .sort(([aId, aNutrient], [bId, bNutrient]) => {
          if (
            nutrientsToDisplayMap.get(aId)!.displayOrder ===
            nutrientsToDisplayMap.get(bId)!.displayOrder
          )
            return 0;
          return nutrientsToDisplayMap.get(aId)!.displayOrder >
            nutrientsToDisplayMap.get(bId)!.displayOrder
            ? 1
            : -1;
        })
        .sort(([aId, aValue], [bId, bValue]) => {
          if (
            nutrientsToDisplayMap.get(aId)!.category ===
            nutrientsToDisplayMap.get(bId)!.category
          )
            return 0;
          return nutrientCategories.get(
            nutrientsToDisplayMap.get(aId)!.category
          )!.displayOrder >
            nutrientCategories.get(nutrientsToDisplayMap.get(bId)!.category)!
              .displayOrder
            ? 1
            : -1;
        })
    : [];
  const nutrientMapFiltered = new Map(nutrientMap);

  return {
    foodMap: getFoodMapMemo,
    finalComposition,
    nutrientMap: nutrientMapFiltered,
    allNutrientsMap,
    compositionCalculator,
  };
};

export default useFoodItemReport;

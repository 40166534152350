import { useSelector } from "react-redux";

import { DocumentSummary } from "../../../../data/models/userDatabase";
import { clientDocumentIdsSelector } from "../../../../store/data/current_client/selectors/client";
import { RootState } from "../../../../store/reducers";
import useDocuments from "./useDocuments";

const useClientDocuments = (searchText: string): [DocumentSummary[], boolean, boolean] => {
  const { documents, deletedFilterApplied}  = {...useDocuments(searchText)};
  const clientDocumentIds: string[] = useSelector<RootState, string[]>(
    clientDocumentIdsSelector
  );

  return [documents.filter((summary: DocumentSummary) =>
    clientDocumentIds.includes(summary.documentId)
  ), !!documents.filter((summary: DocumentSummary) =>
  clientDocumentIds.includes(summary.documentId)).length, deletedFilterApplied]
};

export default useClientDocuments;

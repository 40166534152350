import React from "react";
import {
  createMuiTheme,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  ThemeProvider,
} from "@material-ui/core";
import { DateRangeRounded } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { FoodWorksNumberInput } from "../../../../../../common/FoodWorksTextInput";
import useClientAge from "../hooks/useClientAge";
import ProfileItem from "./ProfileItem";
import { appTheme } from "../../../../../../../styling/style";
import useClientDOB from "../hooks/useClientDOB";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 60,
  },
  root: {
    display: "flex",
    alignItems: "start",
  },
  inputRoot: {
    display: "flex",
    paddingRight: 0,
    "&.Mui-error": {
      border: `2px solid ${appTheme.colors.error}`,
      borderRadius: "4px",
    },
  },
  calendarInput: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: appTheme.colors.input,
    fontSize: appTheme.fontSize.textInput,
    fontFamily: appTheme.fontFamily.textInput,
    margin: 1,
    "&:hover": {
      background: appTheme.colors.inputHover,
    },
    "&:focus": {
      border: `2px solid ${theme.palette.secondary.main}`,
      margin: 0,
      background: appTheme.colors.white[0],
    },
    "&:disabled": {
      background: appTheme.colors.gainsbruh,
    },
  },
}));

const materialCalendarTheme = createMuiTheme({
  overrides: {
    //@ts-ignore
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: appTheme.colors.secondary,
      },
    },
    MuiPickersDay: {
      day: {
        color: appTheme.colors.xiketic,
      },
      daySelected: {
        color: appTheme.colors.primary,
        "&:nth-child(1)": {
          color: "white",
        },
      },
      dayDisabled: {
        color: appTheme.colors.gainsbruh,
      },
      current: {
        color: appTheme.colors.primary,
      },
    },
  },
});

const AgeInput = React.memo((): JSX.Element => {
  const classes = useStyles();

  const [date, updateDate] = useClientDOB();
  const [age, setAge, updateAge] = useClientAge(date);

  const calendarInputProps = {
    width: 135,
    height: 30,
    paddingRight: 0,
    marginRight: 10,
  };
  return (
    <ProfileItem
      component={
        <div className={classes.root}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={materialCalendarTheme}>
              <DatePicker
                disableFuture
                clearable
                autoOk
                size="small"
                id="date-picker-dialog"
                variant="dialog"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={date}
                InputProps={{
                  style: calendarInputProps,
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <IconButton size="small">
                        <DateRangeRounded />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(date) => updateDate(date ? date.toISOString() : "")}
                TextFieldComponent={(params) => (
                  <TextField
                    {...params}
                    className={classes.calendarInput}
                    classes={{
                      root: classes.inputRoot,
                    }}
                  />
                )}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
          <FoodWorksNumberInput
            inputProps={{
              value: age?.toString() || "",
              onBlur: updateAge,
              endAdornment: (
                <InputAdornment variant="outlined" position="end">
                  yr
                </InputAdornment>
              ),
            }}
            setValue={(value) => setAge(!value ? undefined : Number(value))}
            maxValue={100}
            className={classes.input}
          />
        </div>
      }
      label="Age"
      isEmptyInfoTooltip={
        age === undefined ? "This field must not be empty." : ""
      }
    />
  );
});

export default AgeInput;

import React, { ReactNode } from "react";

import { Typography, Chip, Button, makeStyles } from "@material-ui/core";

import { appTheme } from "../../../../styling/style";
import { FoodWorksTextInput } from "../../../common/FoodWorksTextInput";
import { MEDIUM_FIELD } from "../../../../constants/textInputs";

const useStyles = makeStyles((theme) => ({
  colorSelection: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  colorsContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 10,
  },
  colorButton: {
    height: 20,
    width: 20,
    minWidth: 20,
    marginLeft: 5,
    marginRight: 5,
  },
  centeredFlex: {
    display: "flex",
    alignItems: "center",
  },
  labelInput: {
    width: 200,
    marginLeft: 15,
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  exampleContainer: {
    marginTop: 10,
  },
  exampleTag: {
    marginLeft: 10,
    width: 150,
    borderRadius: 4,
  },
  inactiveLabel: {
    marginLeft: 20,
  },
  exampleInactiveTag: {
    borderWidth: 1,
    borderStyle: "solid",
  },
}));

interface SectionTagCreationProps {
  colorIndex: number;
  tagLabel: string;
  setColorIndex: (index: number) => void;
  setTagLabel: (label: string) => void;
  ref: HTMLInputElement | null;
}

export const SectionTagCreation = React.forwardRef<
  HTMLInputElement | null,
  SectionTagCreationProps
>(({ colorIndex, tagLabel, setColorIndex, setTagLabel }, ref) => {
  const classes = useStyles();

  const onTagLabelInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setTagLabel(event.currentTarget.value);

  const colorSelection: ReactNode = (
    <div className={classes.colorSelection}>
      <Typography variant="body1">Colour</Typography>
      <div className={classes.colorsContainer} data-cy="colorContainer">
        {appTheme.colors.activeTags.map(
          (color: string, index: number): ReactNode => (
            <Button
              key={`sectionTagCreation-${index}`}
              size="small"
              variant="contained"
              onClick={() => setColorIndex(index)}
              className={classes.colorButton}
              style={{ backgroundColor: color }}
            />
          )
        )}
      </div>
    </div>
  );

  const labelInput: ReactNode = (
    <div className={classes.centeredFlex} data-cy="labelInput">
      <Typography variant="body1">Label </Typography>
      <FoodWorksTextInput
        maxLength={MEDIUM_FIELD}
        inputRef={ref}
        onChange={onTagLabelInputChange}
        className={classes.labelInput}
        value={tagLabel}
        autoFocus
      />
    </div>
  );

  return (
    <div className={classes.flexColumn}>
      {labelInput}
      {colorSelection}
      <div className={`${classes.centeredFlex} ${classes.exampleContainer} `}>
        <Typography variant="body1">Active </Typography>
        <Chip
          className={classes.exampleTag}
          style={{
            backgroundColor: appTheme.colors.activeTags[colorIndex],
          }}
          label={tagLabel}
        ></Chip>
        <Typography className={classes.inactiveLabel} variant="body1">
          Inactive
        </Typography>
        <Chip
          className={`${classes.exampleTag} ${classes.exampleInactiveTag}`}
          style={{
            borderColor: appTheme.colors.activeTags[colorIndex],
            backgroundColor: appTheme.colors.inactiveTags[colorIndex],
          }}
          label={tagLabel}
        ></Chip>
      </div>
    </div>
  );
});

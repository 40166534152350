import React from "react";

import useClientActivityLevel from "../hooks/useClientActivityLevel";
import { ActivityLevel } from "../../../../../../../data/models/nutrientReferenceValues";
import FoodworksSelect from "../../../../../../common/FoodworksSelect";
import ProfileItem from "./ProfileItem";

const ActivityLevelInput = React.memo(
  (): JSX.Element => {
    const [activityLevel, updateActivityLevel] = useClientActivityLevel();

    return (
      <ProfileItem
        component={
          <FoodworksSelect<ActivityLevel>
            value={activityLevel}
            onChange={updateActivityLevel}
            options={[
              "None",
              "Bed-rest",
              "Very-sedentary",
              "Sedentary",
              "Light",
              "Light-moderate",
              "Moderate",
              "Heavy",
              "Very-heavy",
            ]}
          />
        }
        label="Activity level"
      />
    );
  }
);

export default ActivityLevelInput;

import { useSelector } from "react-redux";

import {
  ActivityLevel,
  getActivityLevelValue,
} from "../../../../../../../data/models/nutrientReferenceValues";
import { clientActivityLevelSelector } from "../../../../../../../store/data/current_client/selectors/clientProfile";
import { RootState } from "../../../../../../../store/reducers";

const useClientPAL = (): number => {
  const activityLevel: ActivityLevel = useSelector<RootState, ActivityLevel>(
    clientActivityLevelSelector
  );

  return getActivityLevelValue(activityLevel);
};

export default useClientPAL;

import React, { FunctionComponent } from "react";

import { useSelector } from "react-redux";
import { LinearProgress, makeStyles } from "@material-ui/core";

import { RootState } from "../../store/reducers";
import { DocumentIsLoadingSelector } from "../../store/data/current-document/selectors/currentDocument";
import { NavIsLoadingSelector } from "../../store/ui/selectors/navigationSelectors";

const useStyles = makeStyles(() => ({
  loadingBar: {
    height: LOADING_BAR_HEIGHT,
  },
}));

export const LOADING_BAR_HEIGHT = 3;

export const LoadingBar: FunctionComponent = () => {
  const classes = useStyles();
  const documentIsLoading: boolean = useSelector<RootState, boolean>(
    DocumentIsLoadingSelector
  );

  const navIsLoading: boolean = useSelector<RootState, boolean>(
    NavIsLoadingSelector
  );

  const isLoading = documentIsLoading || navIsLoading;

  return (
    <>
      {isLoading ? (
        <LinearProgress
          className={classes.loadingBar}
          color="secondary"
          data-cy="pageLoadingBar"
        />
      ) : null}
    </>
  );
};

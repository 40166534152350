import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FoodWorksError } from "../../../constants/errors";
import { RootState } from "../../../store/reducers";
import { initialErrorState } from "../../../store/reducers/errorReducers";
import { CurrentErrorSelector } from "../../../store/selectors/errorSelectors";

interface ErrorState {
  showAlert: boolean;
  currentError: FoodWorksError;
  setShowAlert: (show: boolean) => void;
}

const useFoodworksError = (): ErrorState => {
  const error: FoodWorksError =
    useSelector<RootState, FoodWorksError>(CurrentErrorSelector);

  const [showAlert, setShowAlert] = useState(false);
  const [currentError, setCurrentError] = useState(initialErrorState);

  useEffect(() => {
    if (error.message) {
      setShowAlert(true);
      setCurrentError(error);
    }
  }, [error]);

  return { showAlert, setShowAlert, currentError };
};

export default useFoodworksError;

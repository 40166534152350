import { useState, useEffect } from "react";

import { Day } from "../../../../../../../../data/models/documentProperties/day";
import { Section } from "../../../../../../../../data/models/documentProperties/section";
import { FoodItemPosition } from "../../../../../../../../data/models/foodItemPosition";

import {
  countSelectedRowsInDay,
  countSelectedRowsInSection,
} from "./sectionUtils";

export interface CheckboxStatus {
  checked: boolean;
  indeterminate: boolean;
}

export const useSectionCheckbox = (
  dayIndex: number,
  section: Section,
  selectedRows: FoodItemPosition[]
): CheckboxStatus => {
  const [checkbox, setCheckbox] = useState<CheckboxStatus>({
    indeterminate: false,
    checked: false,
  });

  useEffect(() => {
    const selectedChildRowsLength: number = countSelectedRowsInSection(
      dayIndex,
      section,
      selectedRows
    );

    if (
      selectedChildRowsLength &&
      selectedChildRowsLength < section.foodItems.length
    ) {
      setCheckbox({ indeterminate: true, checked: false });
    } else if (
      section.foodItems.length &&
      selectedChildRowsLength === section.foodItems.length
    ) {
      setCheckbox({ indeterminate: false, checked: true });
    } else {
      setCheckbox({ indeterminate: false, checked: false });
    }
  }, [selectedRows, dayIndex, section]);

  return checkbox;
};

export const useDayCheckbox = (
  day: Day,
  selectedRows: FoodItemPosition[]
): CheckboxStatus => {
  const [checkbox, setCheckbox] = useState<CheckboxStatus>({
    indeterminate: false,
    checked: false,
  });

  useEffect(() => {
    const selectedChildRowsLength: number = countSelectedRowsInDay(
      day.index,
      selectedRows
    );

    if (
      selectedChildRowsLength &&
      selectedChildRowsLength < day.foodItemCount
    ) {
      setCheckbox({ indeterminate: true, checked: false });
    } else if (
      day.foodItemCount &&
      selectedChildRowsLength === day.foodItemCount
    ) {
      setCheckbox({ indeterminate: false, checked: true });
    } else {
      setCheckbox({ indeterminate: false, checked: false });
    }
  }, [selectedRows, day]);

  return checkbox;
};

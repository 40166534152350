import React, { useState } from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import { differenceInCalendarDays } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

import { DEFAULT_MEAL_PLAN_SECTIONS, isMealPlan } from "../../../../constants/FoodTemplate";
import { DAY_IN_MILLISECONDS } from "../../../../data/models/documentProperties/date";
import { Day } from "../../../../data/models/documentProperties/day";
import { RetentionFactor } from "../../../../data/models/documentProperties/retentionFactor";
import FoodworksDatePicker from "../../../common/date/FoodworksDatePicker";
import { FoodWorksNumberInput } from "../../../common/FoodWorksTextInput";
import { InfoTooltip } from "../../../common/InfoTooltip";
import useDocumentDates from "../hooks/useDocumentDates";
import { RootState } from "../../../../store/reducers";
import { templateIdSelector } from "../../../../store/data/current-document/selectors/document";

const useStyles = makeStyles(() => ({
  frequencyInput: {
    width: 40,
    marginLeft: 10,
    "&:focus": {
      marginLeft: 10,
    },
  },
  dayInformationRoot: {
    width: "90%",
    display: "flex",
    marginTop: 10,
  },
  dayInformationContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
  },
  inputAndLabel: {
    display: "flex",
    alignItems: "center",
  },
  dateContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  inputRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90%",
  },
  generatePlanButton: {
    display: "flex",
    marginTop: 10,
    justifyContent: "center",
    width: "100%",
  },
}));

const MAX_DAYS = 84;

interface RecordDaySelectionProps {
  currentDays: Day[];
  setCurrentDays: (days: Day[]) => void;
  clearDayData: boolean;
}

const RecordDaySelection = React.memo<RecordDaySelectionProps>(
  (props): JSX.Element => {
    const classes = useStyles();

    const { currentDays, setCurrentDays, clearDayData } = { ...props };

    const templateId : string = useSelector<RootState, string>(templateIdSelector)

    const [value, setValue] = useState(1);
    const { startDate, endDate, setStartDate, setEndDate } = useDocumentDates();

    const updateEndDate = (startDate: Date | null) => {
      if (
        startDate &&
        differenceInCalendarDays(endDate!, startDate!) > MAX_DAYS - 1
      ) {
        endDate!.setTime(
          startDate!.getTime() + (MAX_DAYS - 1) * DAY_IN_MILLISECONDS
        );
      }

      if(differenceInCalendarDays(endDate!, startDate!) < 1){
        setEndDate(startDate)
      }
    };

    const handleStartDateChange = (date: Date | null) => {
      setStartDate(date);
      updateEndDate(date);
    };

    const handleEndDateChange = (date: Date | null) => setEndDate(date);

    const onPeriodQuantityChange = (value: number) => {
      setValue(value);
    };

    const generatePlanStructure = () => {
      const numberOfDaysToAdd: number = value
        ? Math.ceil(
            (differenceInCalendarDays(endDate!, startDate!) + 1) / value
          )
        : 0;

      const newDays: Day[] = [];

      for (let i = 0; i < numberOfDaysToAdd; ++i) {
        if (!clearDayData && i < currentDays.length) {
          const currentDay: Day = currentDays[i];
          const date = new Date();
          date.setTime(
            startDate!.getTime() + i * Number(value) * DAY_IN_MILLISECONDS
          );
          newDays.push(
            new Day(
              currentDay.id,
              i,
              currentDay.sections,
              currentDay.title,
              date.toISOString()
            )
          );
        } else {
          const date = new Date();
          date.setTime(
            startDate!.getTime() + i * Number(value) * DAY_IN_MILLISECONDS
          );
          const dateString: string = date.toISOString();

          newDays.push(
            Day.fromObject(
              {
                id: uuidv4(),
                index: i,
                sections: DEFAULT_MEAL_PLAN_SECTIONS,
                title: `Day ${i + 1}`,
                date: dateString,
              },
              new Map<string, RetentionFactor>([])
            )
          );
        }
      }
      setCurrentDays(newDays);
    };

    return (
      <>
        <div className={classes.inputRoot}>
          <div className={classes.dateContainer}>
            <FoodworksDatePicker
              date={startDate}
              handleChange={handleStartDateChange}
              label="Start date"
            />

            <FoodworksDatePicker
              date={endDate}
              handleChange={handleEndDateChange}
              maxDate={new Date().setTime(
                startDate!.getTime() + (MAX_DAYS - 1) * DAY_IN_MILLISECONDS
              )}
              minDate={startDate}
              label="End date"
            />
          </div>
          <div className={classes.dayInformationRoot}>
            <div className={classes.dayInformationContainer}>
              <div className={classes.inputAndLabel}>
                <InfoTooltip title="The frequency of days within the selected date range" />
                <Typography variant="body1">Day frequency </Typography>
                <FoodWorksNumberInput
                  setValue={(input) => onPeriodQuantityChange(Number(input))}
                  minValue={1}
                  maxValue={differenceInCalendarDays(endDate!, startDate!) + 1}
                  className={classes.frequencyInput}
                  inputProps={{
                    value: value,
                    type: "number",
                  }}
                />
              </div>
              <div className={classes.inputAndLabel}>
                <Typography>{`Total day count: ${
                  Number(value)
                    ? Math.ceil(
                        (differenceInCalendarDays(endDate!, startDate!) + 1) /
                          Number(value)
                      )
                    : 0
                }`}</Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.generatePlanButton}>
          <Button
            color="secondary"
            variant="contained"
            onClick={generatePlanStructure}
          >
            {`Generate ${isMealPlan(templateId) ? "plan" : "record"} structure`}
          </Button>
        </div>
      </>
    );
  }
);

export default RecordDaySelection;

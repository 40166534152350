import React, { ReactNode, useEffect, useState } from "react";
import { batch, useSelector } from "react-redux";
import { Button, Typography } from "@material-ui/core";
import { useBeforeunload } from "react-beforeunload";

import {
  documentsAreEqual,
  getDocumentErrors,
} from "../../../data/models/document";
import { ReferenceMeasure } from "../../../data/models/referenceMeasure";
import { DocumentSummary } from "../../../data/models/userDatabase";
import { updateCurrentDocument } from "../../../store/data/current-document/action-creators/currentDocument";
import {
  ServerDocumentSelector,
  CurrentDocumentIdSelector,
} from "../../../store/data/current-document/selectors/currentDocument";
import { documentSelector } from "../../../store/data/current-document/selectors/document";
import { saveDocument } from "../../../store/data/current-document/thunks/currentDocument";
import {
  currentDatabaseIsClientDatabaseSelector,
  userDocumentSummariesSelector,
} from "../../../store/data/selectors/database";
import { ReferenceMeasuresSelector } from "../../../store/data/selectors/referenceData";
import { RootState } from "../../../store/reducers";
import { useReduxDispatch } from "../../../store/store";
import { setUnsavedDocumentChanges } from "../../../store/ui/actionCreators/saving";
import { setNextRoute } from "../../../store/ui/actionCreators/routing";
import { unsavedDocumentChangesSelector } from "../../../store/ui/selectors/saving";
import { nextRouteSelector } from "../../../store/ui/selectors/routing";
import { handleRouteChange } from "../../../store/ui/thunks/routing";
import { appTheme } from "../../../styling/style";
import { DialogOption } from "../../common/FoodWorksDialog";
import { BaseDialog } from "../../dialogs/BaseDialog";
import { Document } from "../../../data/models/document";
import { RouteData } from "../../../data/routing/types";
import { Client } from "../../../data/models/client";
import { currentClientSelector } from "../../../store/data/selectors/clientDatabase";

const DEFAULT_DESCRIPTION: string =
  "";

const useOpenDialogHook = (openDialog: boolean): [boolean, () => void] => {
  const [open, setOpen] = useState(false);

  const closeDialog = () => setOpen(false);

  useEffect(() => {
    if (openDialog) setOpen(true);
  }, [openDialog]);

  return [open, closeDialog];
};

const SaveDocumentPrompt = React.memo(() => {
  const dispatch = useReduxDispatch();

  const onSaveDocument = (previousId: string) =>
    dispatch(saveDocument(previousId));

  const resetUnsavedChangesState = () =>
    dispatch(setUnsavedDocumentChanges(false));

  const onHandleRouteChange = (route: RouteData) =>
    dispatch(handleRouteChange(route));

  const onUpdateCurrentDocument = (document: Document) =>
    dispatch(updateCurrentDocument(document));

  const onClearNextRoute = () => dispatch(setNextRoute(undefined));

  const currentDocument: Document =
    useSelector<RootState, Document>(documentSelector);

  const serverDocument: Document | undefined = useSelector<
    RootState,
    Document | undefined
  >(ServerDocumentSelector);

  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const nextRoute: RouteData | undefined =
    useSelector<RootState, RouteData | undefined>(nextRouteSelector);

  const unsavedDocumentChanges: boolean = useSelector<RootState, boolean>(
    unsavedDocumentChangesSelector
  );

  const userDocumentSummaries: DocumentSummary[] = useSelector<
    RootState,
    DocumentSummary[]
  >(userDocumentSummariesSelector);

  const referenceMeasures: ReferenceMeasure[] = useSelector<
    RootState,
    ReferenceMeasure[]
  >(ReferenceMeasuresSelector);

  const currentDatabaseIsClientDatabase: boolean = useSelector<
    RootState,
    boolean
  >(currentDatabaseIsClientDatabaseSelector);

  const currentClient : Client = useSelector<RootState, Client>(currentClientSelector)

  const [showDialog, closeDialog] = useOpenDialogHook(unsavedDocumentChanges);

  const onDiscardChanges = () => {
    batch(() => {
      onUpdateCurrentDocument(serverDocument!);
      resetUnsavedChangesState();
    });

    onHandleRouteChange(nextRoute!);
  };

  const onHandleSaveChanges = async () => {
    await onSaveDocument(currentDocumentId);
    resetUnsavedChangesState();

    onHandleRouteChange(nextRoute!);
  };

  const onDiscard = () => {
    closeDialog();
    onDiscardChanges();
  };

  const onSave = () => {
    closeDialog();
    onHandleSaveChanges();
  };

  const onDialogExit = () => {
    closeDialog();
    resetUnsavedChangesState();
    onClearNextRoute();
  };

  const documentHasUnsavedChanges: boolean = !documentsAreEqual(
    currentDocument,
    serverDocument
  );

  useBeforeunload((event: Event) => {
    if (documentHasUnsavedChanges) event.preventDefault();
  });

  const documentErrors: string[] | undefined = getDocumentErrors(
    currentDocument,
    userDocumentSummaries,
    currentDocumentId,
    referenceMeasures.map((measure: ReferenceMeasure): string => measure.name),
    currentDatabaseIsClientDatabase,
    currentClient
  );

  const errorBody: ReactNode = (
    <div>
      {"Please fix the following errors in your document: "}
      <ul>
        {documentErrors?.map(
          (error: string): ReactNode => (
            <li key={error}>{error}</li>
          )
        )}
      </ul>
    </div>
  );

  const documentDialogActions: DialogOption[] = [
    {
      text: "Cancel",
      onClick: onDialogExit,
      color: appTheme.colors.xiketic,
    },
    {
      text: "Discard Changes",
      onClick: onDiscard,
      color: appTheme.colors.error,
    },
  ];

  if (!documentErrors.length) {
    documentDialogActions.push({
      text: "Save Changes",
      onClick: onSave,
      color: appTheme.colors.primary,
    });
  }

  const body: ReactNode = (
    <Typography component="div" variant="body1" data-cy="dialogDescription">
      {documentErrors.length ? errorBody : DEFAULT_DESCRIPTION}
    </Typography>
  );

  const action: ReactNode = (
    <div>
      {documentDialogActions.map((option: DialogOption) => (
        <Button
          data-cy="dialogButton"
          key={option.text}
          onClick={option.onClick}
          style={{ color: option.color }}
        >
          {option.text}
        </Button>
      ))}
    </div>
  );

  return (
    <BaseDialog
      dataCy="saveDialog"
      open={showDialog}
      onClose={onDialogExit}
      title="You have made unsaved changes to this document"
      body={body}
      action={action}
      maxWidth="md"
    />
  );
});

export default SaveDocumentPrompt;

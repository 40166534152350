import { useSelector } from "react-redux";

import { ClientSummary } from "../../../../data/models/client";
import { clientSummariesSelector } from "../../../../store/data/selectors/clientDatabase";
import { RootState } from "../../../../store/reducers";

const useRecentClients = (numberToReturn: number): ClientSummary[] => {
  const clientSummaries: ClientSummary[] = useSelector<
    RootState,
    ClientSummary[]
  >(clientSummariesSelector);

  const sortedClientSummaries = clientSummaries
    .sort((a, b) =>
      b.date.lastModified < a.date.lastModified
        ? -1
        : b.date.lastModified > a.date.lastModified
        ? 1
        : 0
    )
    .filter((client) => client.state === "active");

  return sortedClientSummaries.length <= numberToReturn
    ? sortedClientSummaries
    : sortedClientSummaries.slice(0, numberToReturn);
};

export default useRecentClients;

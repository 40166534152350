import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { Identifier } from "../../../data/models/documentProperties/identifier";
import { DocumentSummary } from "../../../data/models/userDatabase";
import { userDocumentSummariesSelector } from "../../../store/data/selectors/database";
import { RootState } from "../../../store/reducers";

export const useDocumentIdentifier = (): [
  Map<string, Identifier>,
  Dispatch<SetStateAction<Map<string, Identifier>>>
] => {
  const documentSummaries = useSelector<RootState, DocumentSummary[]>(
    userDocumentSummariesSelector
  );

  const [identifierMap, setIdentifierMap] = useState<Map<string, Identifier>>(
    new Map()
  );

  const loadIdentifiers = useCallback(() => {
    const newMap = new Map<string, Identifier>();
    for (const summary of documentSummaries) {
      newMap.set(summary.documentId, summary.searchableProperties);
    }
    setIdentifierMap(newMap);
  }, [documentSummaries]);

  useEffect(() => {
    loadIdentifiers();
  }, [loadIdentifiers]);

  return [identifierMap, setIdentifierMap];
};

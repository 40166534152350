import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { Tag } from "../../../../../data/models/documentProperties/section";
import { DocumentSummary } from "../../../../../data/models/userDatabase";
import { documentTagIdsSelector } from "../../../../../store/data/current-document/selectors/document";
import { RootState } from "../../../../../store/reducers";
import {
  databaseDocumentTagsSelector,
  userDocumentSummariesSelector,
} from "../../../../../store/data/selectors/database";

export const useCurrentDocumentTags = (): [
  Tag[],
  Dispatch<SetStateAction<Tag[]>>
] => {
  const documentTags: Tag[] = useSelector<RootState, Tag[]>(
    databaseDocumentTagsSelector
  );

  const documentTagIds: string[] = useSelector<RootState, string[]>(
    documentTagIdsSelector
  );

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const loadTags = useCallback(() => {
    const tags: Tag[] = documentTags.filter((tag) =>
      documentTagIds.includes(tag.id)
    );

    setSelectedTags(tags);
  }, [documentTags, documentTagIds]);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  return [selectedTags, setSelectedTags];
};

export const useSummariesTag = (
  documentId: string
): [Tag[], Dispatch<SetStateAction<Tag[]>>] => {
  const documentTags: Tag[] = useSelector<RootState, Tag[]>(
    databaseDocumentTagsSelector
  );

  const userDocumentSummaries = useSelector<RootState, DocumentSummary[]>(
    userDocumentSummariesSelector
  );

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const loadTags = useCallback(() => {
    const documentSummary:
      | DocumentSummary
      | undefined = userDocumentSummaries.find(
      (summary: DocumentSummary) => summary.documentId === documentId
    );

    if (documentSummary) {
      const tags: Tag[] = documentTags.filter((tag) =>
        documentSummary.documentTagIds.includes(tag.id)
      );
      setSelectedTags(tags);
    }
  }, [documentTags, documentId, userDocumentSummaries]);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  return [selectedTags, setSelectedTags];
};

export const useLoadDocuments = (
  selectedTag: string
): [string[], Dispatch<SetStateAction<string[]>>] => {
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  const userDocumentSummaries = useSelector<RootState, DocumentSummary[]>(
    userDocumentSummariesSelector
  );

  const loadDocuments = useCallback(() => {
    const documents: string[] = userDocumentSummaries
      .filter((summary: DocumentSummary) =>
        summary.documentTagIds.includes(selectedTag)
      )
      .map((summary: DocumentSummary) => summary.documentId);
    setSelectedDocuments(documents);
  }, [userDocumentSummaries, selectedTag]);

  useEffect(() => {
    loadDocuments();
  }, [userDocumentSummaries, loadDocuments]);

  return [selectedDocuments, setSelectedDocuments];
};

import React from "react";
import {
  Card,
  Checkbox,
  Grid,
  makeStyles,
  Typography,
  Theme,
} from "@material-ui/core";

import { Measurement } from "../../../../../../data/models/clientProperties/measurement";
import { FoodworksTooltip } from "../../../../../common/InfoTooltip";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  gridItem: {
    padding: 5,
    overflowY: "auto",
  },
  card: {
    textAlign: "left",
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

interface Props {
  sortedMeasurements: Measurement[];
  toggleActive: (measurement: Measurement) => void;
}

const MeasurementsList = ({ sortedMeasurements, toggleActive }: Props) => {
  const classes = useStyles();

  return (
    <>
      {sortedMeasurements.map((measurement: Measurement) => {
        return (
          <Grid
            key={measurement.label + measurement.unit + measurement.category}
            className={classes.gridItem}
            item
            xs={4}
          >
            <Card className={classes.card}>
              <Grid container justify="space-around" alignItems="center">
                <Grid item xs={10}>
                  <FoodworksTooltip
                    title={`${measurement.label} (${measurement.unit})`}
                  >
                    <Typography noWrap={true}>
                      {measurement.label} ({measurement.unit})
                    </Typography>
                  </FoodworksTooltip>
                </Grid>
                <Grid item xs={2}>
                  <Checkbox
                    checked={measurement.active}
                    onClick={() => toggleActive(measurement)}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        );
      })}
    </>
  );
};

export default MeasurementsList;

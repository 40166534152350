import React, { useState } from "react";
import { CopyPropertiesDialog } from "../dialogs/database/CopyPropertiesDialog";
import { OpenDialogButton } from "./OpenDialogButton";

export enum CopyDatabaseProperty {
  ENABLED_MEASURES = "enabledMeasures",
  ENABLED_NUTRIENTS = "enabledNutrients",
  ENABLED_DATASOURCES = "enabledDatasources",
}

interface CopyPropertiesButtonProps {
  databaseProperty: CopyDatabaseProperty;
  fetchDatabaseProperties: (ids: string[]) => void;
}

export const CopyPropertiesButton = ({
  databaseProperty,
  fetchDatabaseProperties,
}: CopyPropertiesButtonProps): JSX.Element => {
  const [copyPropertiesButton, setCopyPropertiesButton] = useState(false);

  return (
    <>
      <OpenDialogButton
        cypressTag="copyPropButton"
        label="Copy properties from"
        onClick={() => setCopyPropertiesButton(true)}
      />
      <CopyPropertiesDialog
        databaseProperty={databaseProperty}
        open={copyPropertiesButton}
        fetchDatabaseProperties={(ids: string[]) =>
          fetchDatabaseProperties(ids)
        }
        onClose={() => setCopyPropertiesButton(false)}
      />
    </>
  );
};

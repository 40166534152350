import React, { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Delete, Restore } from "@material-ui/icons";
import { useSelector } from "react-redux";

import { RootState } from "../../../../store/reducers";
import {
  documentPropertiesSelector,
  documentSelector,
  nameSelector,
  templateIdSelector,
} from "../../../../store/data/current-document/selectors/document";
import {
  Document,
  documentsAreEqual,
  DocumentTemplateId,
  getDocumentErrors,
} from "../../../../data/models/document";
import { EditableTitleInput } from "../../../common/EditableTitleInput";
import { updateDocumentName } from "../../../../store/data/current-document/action-creators/document";
import {
  TEMPORARY_DOCUMENT,
  TEMPORARY_NEW_DOCUMENT,
} from "../../../../store/data/current-document/reducers/currentDocument";
import {
  CurrentDocumentIdSelector,
  ServerDocumentSelector,
} from "../../../../store/data/current-document/selectors/currentDocument";
import { saveDocument } from "../../../../store/data/current-document/thunks/currentDocument";
import { appTheme } from "../../../../styling/style";
import { DocumentSummary } from "../../../../data/models/userDatabase";
import {
  currentDatabaseIsClientDatabaseSelector,
  userDocumentSummariesSelector,
} from "../../../../store/data/selectors/database";
import { addError } from "../../../../store/action_creators/errorActionCreators";
import { DocumentSaveError } from "../../../../constants/errors";
import { FoodworksTooltip } from "../../../common/InfoTooltip";
import { addAlert } from "../../../../store/ui/actionCreators/alert";
import { ReferenceMeasure } from "../../../../data/models/referenceMeasure";
import { ReferenceMeasuresSelector } from "../../../../store/data/selectors/referenceData";
import { useReduxDispatch } from "../../../../store/store";
import SaveButton from "../../../common/buttons/SaveButton";
import { NAME_FIELD } from "../../../../constants/textInputs";
import { currentClientSelector } from "../../../../store/data/selectors/clientDatabase";
import { Client } from "../../../../data/models/client";
import useArchiveDocument from "./hooks/useArchiveDocument";
import ClientReportButton from "./ClientReportButton";

const useStyles = makeStyles(() => ({
  title: {
    flex: 1,
    margin: 5,
  },
  titleButton: { width: 75 },
  deleteIcon: {
    color: "white",
  },
  saveSuccessAlert: { marginTop: 40 },
  titleBar: { display: "flex", alignItems: "center" },
}));

export const TitleBar: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useReduxDispatch();

  const onDocumentNameChanged = (name: string) => {
    dispatch(updateDocumentName(name));
  };

  const onSaveDocument = () => {
    setDisableSave(true);
    dispatch(saveDocument(currentDocumentId)).then(() => setDisableSave(false));
  };

  const onDocumentSaveError = (documentErrors: ReactNode) =>
    dispatch(addError(new DocumentSaveError(documentErrors)));

  const onAddAlert = (message: string) => dispatch(addAlert(message));

  const documentName = useSelector<RootState, string>(nameSelector);

  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const templateId = useSelector<RootState, DocumentTemplateId>(
    templateIdSelector
  );

  const documentProperties = useSelector<RootState, Document["properties"]>(
    documentPropertiesSelector
  );

  const currentDocument: Document = useSelector<RootState, Document>(
    documentSelector
  );

  const serverDocument: Document | undefined = useSelector<
    RootState,
    Document | undefined
  >(ServerDocumentSelector);

  const userDocumentSummaries: DocumentSummary[] = useSelector<
    RootState,
    DocumentSummary[]
  >(userDocumentSummariesSelector);

  const referenceMeasures: ReferenceMeasure[] = useSelector<
    RootState,
    ReferenceMeasure[]
  >(ReferenceMeasuresSelector);

  const currentDatabaseIsClientDatabase: boolean = useSelector<
    RootState,
    boolean
  >(currentDatabaseIsClientDatabaseSelector);

  const currentClient: Client = useSelector<RootState, Client>(
    currentClientSelector
  );

  const onArchiveDocument = useArchiveDocument(currentDocumentId, true);

  const [disableSave, setDisableSave] = useState(false);

  const documentIsUnsaved: boolean =
    currentDocumentId === TEMPORARY_DOCUMENT ||
    currentDocumentId === TEMPORARY_NEW_DOCUMENT;

  const documentHasChanges: boolean =
    documentIsUnsaved || !documentsAreEqual(currentDocument, serverDocument);

  const tooltipText =
    documentProperties.state === "archived"
      ? "Restore document"
      : "Archive document";

  const deleteButton = (
    <FoodworksTooltip title={tooltipText}>
      <span>
        <Button
          data-cy="deleteDocument"
          size="small"
          disabled={documentIsUnsaved}
          className={classes.titleButton}
          style={{
            color: documentIsUnsaved
              ? "revert"
              : documentProperties.state === "archived"
              ? appTheme.colors.success
              : appTheme.colors.error,
          }}
          onClick={onArchiveDocument}
          startIcon={
            documentProperties.state === "archived" ? <Restore /> : <Delete />
          }
        >
          {documentProperties.state === "archived" ? "Restore" : "Archive"}
        </Button>
      </span>
    </FoodworksTooltip>
  );

  const handleSaveClick = () => {
    const documentErrors: string[] | undefined = getDocumentErrors(
      currentDocument,
      userDocumentSummaries,
      currentDocumentId,
      referenceMeasures.map(
        (measure: ReferenceMeasure): string => measure.name
      ),
      currentDatabaseIsClientDatabase,
      currentClient
    );

    if (!documentErrors.length) {
      onSaveDocument();
      onAddAlert("Document saved successfully!");
    } else {
      const errorBody: ReactNode = (
        <div>
          {"Please fix the following errors in your document: "}
          <ul>
            {documentErrors.map(
              (error: string): ReactNode => (
                <li key={error}>{error}</li>
              )
            )}
          </ul>
        </div>
      );
      onDocumentSaveError(errorBody);
    }
  };

  const showReportButton: boolean = useMemo(
    () =>
      templateId === DocumentTemplateId.MEAL_PLAN ||
      templateId === DocumentTemplateId.FOOD_RECORD ||
      templateId === DocumentTemplateId.RECALL,
    [templateId]
  );

  return (
    <div className={classes.titleBar}>
      <EditableTitleInput
        className={classes.title}
        value={documentName}
        data-cy="recipeTitle"
        onChange={(event) => onDocumentNameChanged(event.target.value)}
        inputProps={{ maxLength: NAME_FIELD }}
      />
      {showReportButton && <ClientReportButton />}
      <SaveButton
        cypressTag="saveButton"
        onClick={handleSaveClick}
        tooltip="document"
        disabled={disableSave || !documentHasChanges}
      />
      {deleteButton}
    </div>
  );
};

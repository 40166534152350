import React, { FunctionComponent, ReactNode, useState } from "react";

import { Button, makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { FoodWorksTextInput } from "../../common/FoodWorksTextInput";
import { BaseDialog } from "../BaseDialog";
import { createDatabase } from "../../../store/data/thunks/database";
import { UserDatabaseSummary } from "../../../data/models/userDatabase";
import { sortedUserDatabaseSummariesSelector } from "../../../store/data/selectors/user";
import { RootState } from "../../../store/reducers";
import { DisabledButtonWithToolTip } from "../../common/InfoTooltip";
import { NAME_FIELD } from "../../../constants/textInputs";

const useStyles = makeStyles(() => ({
  input: {
    marginTop: 5,
  },
}));

interface CreateDatabaseDialogProps {
  onClose: () => void;
}

export const CreateDatabaseDialog: FunctionComponent<CreateDatabaseDialogProps> =
  ({ onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onCreateDatabase = () => dispatch(createDatabase(name));

    const userDatabaseSummaries: UserDatabaseSummary[] = useSelector<
      RootState,
      UserDatabaseSummary[]
    >(sortedUserDatabaseSummariesSelector);

    const [name, setName] = useState("");

    const isDatabaseNameUnique = (name: string) =>
      !userDatabaseSummaries.find(
        (databaseSummary: UserDatabaseSummary): boolean =>
          databaseSummary.name === name
      );

    const body: ReactNode = (
      <div>
        <Typography>
        Create a new Foodworks.online database.
            </Typography>
            <br/>
        <Typography>Name </Typography>
        <FoodWorksTextInput
          maxLength={NAME_FIELD}
          data-cy="createDatabaseNameInput"
          className={classes.input}
          value={name}
          onChange={(event) => setName(event.currentTarget.value)}
          autoFocus
        />
      </div>
    );

    const actions: ReactNode = [
      <Button onClick={onClose}>Cancel</Button>,
      <DisabledButtonWithToolTip
        dataCy="createDatabaseDialogAction"
        label="Create"
        disabled={!name.trim() || !isDatabaseNameUnique(name.trim())}
        disabledTooltip="Database must have a unique name"
        buttonProps={{
          onClick: () => {
            onCreateDatabase();
            onClose();
          },
        }}
      />,
    ];

    return (
      <BaseDialog
        open
        onClose={onClose}
        title=""
        body={body}
        maxWidth="sm"
        action={actions}
        dataCy="createDatabaseDialog"
      />
    );
  };

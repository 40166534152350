import { ThunkAction } from "redux-thunk";

import { Client, clientsAreEqual } from "../../../data/models/client";
import { Document, documentsAreEqual } from "../../../data/models/document";
import {  Routes } from "../../../data/routing/routing";
import { ServerDocumentSelector } from "../../data/current-document/selectors/currentDocument";
import { documentSelector } from "../../data/current-document/selectors/document";
import {
  clientUserDatabaseSelector,
  currentClientSelector,
  serverClientSelector,
} from "../../data/selectors/clientDatabase";
import { RootState } from "../../reducers";
import { ExtraArguments } from "../../store";
import { IActionsSetUnsavedDocumentChanges } from "../actions/saving";
import {
  IActionsSetCurrentRoute,
  IActionsSetNextRoute,
} from "../actions/routing";
import { currentRouteSelector } from "../selectors/routing";
import { RouteData } from "../../../data/routing/types";

export const handleRouteChange = (
  nextRoute: RouteData
): ThunkAction<
  Promise<void>,
  RootState,
  ExtraArguments,
  | IActionsSetCurrentRoute
  | IActionsSetNextRoute
  | IActionsSetUnsavedDocumentChanges
> => async (dispatch, getState, { routing }) => {
  const state: RootState = getState();

  const currentRoute: RouteData | undefined = currentRouteSelector(state);

  const currentDocument: Document = documentSelector(state);

  const serverDocument: Document | undefined = ServerDocumentSelector(state);

  const currentClient: Client = currentClientSelector(state);

  const serverClient: Client | undefined = serverClientSelector(state);

  const clientUserDatabaseId: string = clientUserDatabaseSelector(state);

  const routes = new Routes(
    clientUserDatabaseId,
    routing,
    !documentsAreEqual(currentDocument, serverDocument),
    !clientsAreEqual(currentClient, serverClient)
  );

  const {actionsToTake, navigateToRoute}  = routes.handleRouteChange(currentRoute, nextRoute);

  for (const action of actionsToTake) {
    dispatch(action());
  }

  if (navigateToRoute) {
    navigateToRoute()
  }


};

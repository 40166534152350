import { ENABLED_NUTRIENTS } from "../../store/data/reducers/referenceData";
import { CompositionState } from "./composition";
import { NutrientOverride } from "./documentProperties/nutrientOverride";
import { Nutrient } from "./nutrient";

export const FOODWORKS_DECIMAL_PLACES = 3;
export class NutrientValue {
  weightFactor: number;
  decimalPlaces: number;
  override: NutrientOverride | undefined;
  base: number | undefined;
  final: number | undefined;

  constructor(decimalPlaces: number, weightFactor: number, final?: number) {
    this.decimalPlaces = decimalPlaces;
    this.weightFactor = weightFactor;
    this.base = final;
    this.final = final;
  }

  public get overrideValue(): string {
    return this.override?.value.toString() || "";
  }

  public get baseValue(): string {
    return this.base !== undefined
      ? (this.base * this.weightFactor).toFixed(FOODWORKS_DECIMAL_PLACES)
      : "?";
  }

  public get displayValue(): string {
    if (this.final !== undefined)
      return (this.final * this.weightFactor).toFixed(FOODWORKS_DECIMAL_PLACES);
    if (this.override)
      return this.override.value.toFixed(FOODWORKS_DECIMAL_PLACES);
    if (this.base !== undefined) return this.baseValue;
    return "?";
  }
}

export class NutrientValues {
  values: Map<string, NutrientValue>;

  constructor(nutrientValues: Map<string, NutrientValue>) {
    this.values = nutrientValues;
  }

  static fromState = (
    weightFactor: number,
    nutrients: Map<string, Nutrient>,
    baseComposition: CompositionState,
    finalComposition: CompositionState,
    nutrientOverrides: NutrientOverride[]
  ): NutrientValues => {
    const nutrientValues = new Map<string, NutrientValue>();
    if (!nutrients.size) return new NutrientValues(nutrientValues);
    for (const id of [...nutrients.keys()]) {
      const nutrient = nutrients.get(id)!;

      const nutrientValue = new NutrientValue(
        nutrient.decimalPlaces,
        weightFactor
      );

      nutrientValue.base = baseComposition[id];
      nutrientValue.final = finalComposition[id];

      nutrientValues.set(id, nutrientValue);
    }

    const filteredNutrientOverrides: NutrientOverride[] = nutrientOverrides.filter(
      (override: NutrientOverride): boolean =>
        ENABLED_NUTRIENTS.includes(override.id)
    );

    for (const override of filteredNutrientOverrides) {
      nutrientValues.get(override.id)!.override = override;
    }

    return new NutrientValues(nutrientValues);
  };

  public nutrientValue = (id: string): NutrientValue => this.values.get(id)!;
}

import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { appTheme } from "../../../../styling/style";
import { CreateClientTagDialog } from "./CreateClientTagDialog";

const useStyles = makeStyles((theme) => ({
  addIcon: {
    color: appTheme.colors.primary,
  },
}));

export const CreateClientTagButton = (): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        data-cy="createDocumentTag"
        startIcon={<Add className={classes.addIcon} />}
        onClick={() => setOpen(true)}
        size="small"
      >
        Create client tag
      </Button>
      <CreateClientTagDialog
        open={open}
        onClose={() => setOpen(false)}
        isEditing={false}
      />
    </>
  );
};

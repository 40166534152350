import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";

import { MeasurementCategory } from "../../../../../../../data/models/clientProperties/measurement";
import { FoodworksTooltip } from "../../../../../../common/InfoTooltip";
import EditMeasurementPropertiesDialog from "../../dialogs/EditMeasurementPropertiesDialog";

interface Props {
  measurementTitle: string;
  measurementUnits: string;
  measurementCategory: MeasurementCategory;
  measurementSubCategory: string;
}

const EditMeasurementPropertiesButton = (props: Props): JSX.Element => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <FoodworksTooltip title="Edit measurement properties">
        <IconButton
          size="small"
          color="inherit"
          onClick={() => setShowDialog(true)}
        >
          <EditOutlined />
        </IconButton>
      </FoodworksTooltip>
      {showDialog && (
        <EditMeasurementPropertiesDialog
          {...props}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  );
};

export default EditMeasurementPropertiesButton;

import { Button } from "@material-ui/core";

import { BaseDialog } from "../../../../../dialogs/BaseDialog";
import PreviousEntriesTable from "./components/PreviousEntriesTable";

interface Props {
  onClose: () => void;
  measurementTitle: string;
  measurementUnits: string;
}
const PreviousEntriesDialog = (props: Props): JSX.Element => {
  const { onClose, measurementTitle, measurementUnits } = { ...props };

  return (
    <BaseDialog
      title={`${measurementTitle} tracking history`}
      open
      onClose={onClose}
      maxWidth="sm"
      body={
        <PreviousEntriesTable
          measurementUnit={measurementUnits}
          measurementTitle={measurementTitle}
        />
      }
      action={[<Button onClick={onClose}>Close</Button>]}
    />
  );
};

export default PreviousEntriesDialog;

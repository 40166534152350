import React, { ReactNode, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  AccordionItem,
  AccordionList,
  AccordionProps,
} from "../../../common/AccordionList";
import { tappedCategoryAccordion } from "../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import { Category } from "../../../../data/models/category";
import { NutrientItem } from "./NutrientItem";
import { Nutrient } from "../../../../data/models/nutrient";
import { CategoriesSelector } from "../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../store/reducers";
import { expandedCategoriesSelector } from "../../../../store/ui/selectors/nutritionPaneSelectors";
import { NutrientValues } from "../../../../data/models/nutrientValue";
import NutrientProfileSelection from "./NutrientProfileSelection";
import useNutrientsToDisplay from "./hooks/useNutrientsToDisplay";

const useStyles = makeStyles(() => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    flex: 1,
  },
}));

const getNutrientValue = (id: string, nutrientValues: NutrientValues): string =>
  nutrientValues.nutrientValue(id)?.displayValue || "?";

const getNewMaxLength = (
  previousMax: number,
  id: string,
  nutrientValues: NutrientValues
): number =>
  getNutrientValue(id, nutrientValues).length > previousMax
    ? getNutrientValue(id, nutrientValues).length
    : previousMax;

const getMaxNutrientValueLength = (
  nutrients: [string, Nutrient][],
  nutrientValues: NutrientValues
): number =>
  nutrients.reduce<number>(
    (maxLength, [id]) => getNewMaxLength(maxLength, id, nutrientValues),
    0
  );

const getNutrientItem = (
  id: string,
  nutrient: Nutrient,
  nutrientValue: (id: string) => string,
  maxValueLength: number
): ReactNode => (
  <NutrientItem
    key={id}
    nutrientId={id}
    name={nutrient.name}
    value={nutrientValue(id)}
    measure={nutrient.units}
    maxValueLength={maxValueLength}
  />
);

const buildNutrientItems = (
  nutrients: [string, Nutrient][],
  nutrientValues: NutrientValues
): AccordionItem[] => {
  const nutrientValue = _.curryRight(getNutrientValue)(nutrientValues);

  const maxValueLength: number = getMaxNutrientValueLength(
    nutrients,
    nutrientValues
  );

  return nutrients.map(
    ([id, nutrient]: [string, Nutrient]): AccordionItem => ({
      id: id,
      item: getNutrientItem(id, nutrient, nutrientValue, maxValueLength),
    })
  );
};

const groupNutrientsByCategory = (nutrients: [string, Nutrient][]) =>
  _(nutrients).groupBy(
    ([_, nutrient]: [string, Nutrient]) => nutrient.category
  );

const buildNutrientAccordionItems = (
  categories: Map<string, Category>,
  nutrients: [string, Nutrient][],
  expandedCategories: string[],
  nutrientItems: AccordionItem[],
  onClickAccordion: (id: string) => void
): AccordionProps[] =>
  groupNutrientsByCategory(nutrients)
    .map(
      (value, key): AccordionProps => ({
        id: Number(key),
        header: categories.get(key)!.name,
        open: expandedCategories.includes(key),
        items: nutrientItems.filter((nutrientItem: AccordionItem) =>
          value
            .map(([id]: [string, Nutrient]): string => id)
            .includes(nutrientItem.id!)
        ),
        onClick: onClickAccordion,
      })
    )
    .value();

/**
 * Re-arrange the Accordion Items based on header order
 * Example: headerOrder = ["General", "Food Groups", "Minerals"]
 * Will place General, Food Groups and Minerals at the top of the list
 * @param accordionItems Nutrient Accordion Array
 * @param headerOrder A string of headers in descending order
 */
const rearrangeNutrientAccordianItems = (
  accordionItems: AccordionProps[],
  headerOrder: string[]
) => {
  const orderedItems: AccordionProps[] = headerOrder
    .map((header: string) =>
      accordionItems.find(
        (accordionProp: AccordionProps) => accordionProp.header === header
      )
    )
    .filter(
      (accordionItem: AccordionProps | undefined) => accordionItem !== undefined
    ) as AccordionProps[];

  const filteredItems: AccordionProps[] = accordionItems.filter(
    (accordionItem: AccordionProps) =>
      !headerOrder.includes(accordionItem.header)
  );

  return orderedItems.concat(filteredItems);
};

interface NutrientAnalysisProps {
  nutrientValues: NutrientValues;
}

const NutrientAnalysis = React.memo<NutrientAnalysisProps>(
  ({ nutrientValues }): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onCategoryTapped = useCallback(
      (categoryId: string) => dispatch(tappedCategoryAccordion(categoryId)),
      [dispatch]
    );

    const nutrientCategories: Map<string, Category> =
      useSelector<RootState, Map<string, Category>>(CategoriesSelector);

    const expandedCategories: string[] = useSelector<RootState, string[]>(
      expandedCategoriesSelector
    );

    const [setNutrientProfile, nutrients] = useNutrientsToDisplay();

    const nutrientItems: AccordionItem[] = useMemo(
      () => buildNutrientItems(nutrients, nutrientValues),
      [nutrients, nutrientValues]
    );

    const categories: AccordionProps[] = useMemo(
      () =>
        buildNutrientAccordionItems(
          nutrientCategories,
          nutrients,
          expandedCategories,
          nutrientItems,
          onCategoryTapped
        ),
      [
        nutrientCategories,
        nutrients,
        expandedCategories,
        nutrientItems,
        onCategoryTapped,
      ]
    );

    const rearrangedCategories: AccordionProps[] = useMemo(
      () => rearrangeNutrientAccordianItems(categories, ["General"]),
      [categories]
    );

    return (
      <div className={classes.root}>
        <NutrientProfileSelection setNutrientProfile={setNutrientProfile} />
        <AccordionList values={rearrangedCategories} />
      </div>
    );
  }
);

export default NutrientAnalysis;

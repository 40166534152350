import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import Firebase, { withFirebase } from "../../../data/Firebase";
import { fetchPublicIngredientSummaries } from "../../../data/Firebase/helpers/fetchIngredientSummaries";
import { CurrentDocumentIdSelector } from "../../../store/data/current-document/selectors/currentDocument";
import { enabledDatasourcesSelector } from "../../../store/data/selectors/databaseProperties";
import { RootState } from "../../../store/reducers";
import { FoodScreen } from "./documents/DocumentScreen";
import { IngredientSummaryItem } from "./documents/tabs/ingredients/editing_grid/rows/cells/IngredientCell";
import { NutritionPane } from "./nutrition_pane/NutritionPane";
import { DatabaseOverview } from "./overview/DatabaseOverview";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: 1,
  },
}));

interface OpenDatabaseProps {
  firebase?: Firebase;
}

export const usePublicSummaries = (
  firebase: Firebase
): IngredientSummaryItem[] => {
  const enabledDatasources: string[] = useSelector<RootState, string[]>(
    enabledDatasourcesSelector
  );

  const [publicSummaries, setPublicSummaries] = useState<
    IngredientSummaryItem[]
  >([]);

  useEffect(() => {
    fetchPublicIngredientSummaries(firebase, enabledDatasources).then(
      setPublicSummaries
    );
  }, [enabledDatasources, firebase]);

  return publicSummaries;
};

const OpenDatabaseInner: FunctionComponent<OpenDatabaseProps> = ({
  firebase,
}) => {
  const classes = useStyles();

  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const publicSummaries = usePublicSummaries(firebase!);

  const createDocumentScreen = (): ReactNode => {
    if (!currentDocumentId) {
      return <DatabaseOverview />;
    }

    return (
      <>
        <div className={classes.root}>
          <FoodScreen publicSummaries={publicSummaries} screenType="Document" />
        </div>
        <NutritionPane />
      </>
    );
  };

  return <> {createDocumentScreen()} </>;
};

export const OpenDatabase = withFirebase(OpenDatabaseInner);

import { Action } from "redux";

export const SET_USE_RMR = "SET_USE_RMR";
export const SET_SELECTED_CLIENT_DOCUMENTS = "SET_SELECTED_CLIENT_DOCUMENTS";

export interface IActionsSetUseRMR extends Action {
  type: "SET_USE_RMR";
  useRMR: boolean;
}

export interface IActionsSetSelectedClientDocuments extends Action {
  type: "SET_SELECTED_CLIENT_DOCUMENTS";
  documents: string[];
}

export type ClientScreenActions =
  | IActionsSetUseRMR
  | IActionsSetSelectedClientDocuments;

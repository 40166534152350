import React, { FunctionComponent, useState, ReactNode } from "react";

import { Button, Typography, makeStyles } from "@material-ui/core";
import * as Bowser from "bowser";
import ImageUploader from "react-images-upload";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";

import { FoodWorksTextInput } from "../common/FoodWorksTextInput";
import Firebase, { withFirebase } from "../../data/Firebase";
import { databaseIdSelector } from "../../store/data/selectors/database";
import { RootState } from "../../store/reducers";
import { User } from "../../data/models/user";
import { BaseDialog } from "./BaseDialog";
import { LARGE_FIELD, SMALL_FIELD } from "../../constants/textInputs";

export interface FeedbackDialogProps {
  onClose: () => void;
  onSend: () => void;
  firebase?: Firebase;
}

const useStyles = makeStyles(() => ({
  textBox: {
    paddingTop: 10,
    width: "100%"
  },
  description: {
    paddingTop: 20,
  },
}));

interface UploadResponse {
  upload: {
    token: string;
  };
}

const ZENDESK_DATABASE_ID_FIELD = 360002625896;
const ZENDESK_OS_FIELD = 360002538615;
const ZENDESK_BROWSER_FIELD = 360002624776;
const ZENDESK_FW_USER_ID_FIELD = 360002538655;
const ZENDESK_USER_EMAIL_FIELD = 360002538635;
const ZENDESK_USER_NAME_FIELD = 360007986075;

export const FeedbackDialogInner: FunctionComponent<FeedbackDialogProps> = ({
  onClose,
  onSend,
  firebase,
}) => {
  const classes = useStyles();

  const [firebaseUser] = useAuthState(firebase!.auth);

  const [subject, setSubject] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [uploadedImages, setUploadedImages] = useState<File[] | null>(null);

  const currentDatabaseId: string = useSelector<RootState, string>(
    databaseIdSelector
  );

  const url: string = "https://xyris.zendesk.com/api/v2/requests.json";

  const createZendeskRequestBody = async (uploadTokens: string[]) => {
    const userSystemInfo = Bowser.parse(window.navigator.userAgent);

    const user: User | undefined = await firebase?.users.doGetUser(
      firebaseUser!.uid
    );

    if (!user) return;

    return {
      request: {
        subject: subject,
        comment: { body: description, uploads: uploadTokens },
        requester: {
          name: `${user.firstName} ${user.lastName}`,
          email: firebaseUser!.email,
        },
        custom_fields: [
          { id: ZENDESK_FW_USER_ID_FIELD, value: user.id },
          { id: ZENDESK_USER_EMAIL_FIELD, value: firebaseUser!.email },
          {
            id: ZENDESK_USER_NAME_FIELD,
            value: `${user.firstName} ${user.lastName}`,
          },
          {
            id: ZENDESK_BROWSER_FIELD,
            value: `${userSystemInfo.browser.name} ${userSystemInfo.browser.version}`,
          },
          {
            id: ZENDESK_OS_FIELD,
            value: `${userSystemInfo.os.name} ${userSystemInfo.os.version} ${userSystemInfo.os.versionName}`,
          },
          { id: ZENDESK_DATABASE_ID_FIELD, value: currentDatabaseId },
        ],
      },
    };
  };

  const sendFeedBack = async () => {
    const uploadTokens: string[] = [];

    if (uploadedImages) {
      for (const file of uploadedImages) {
        const attachmentUrl: string = `https://xyris.zendesk.com/api/v2/uploads.json?filename=${file.name}`;

        const uploadResponse = await fetch(attachmentUrl, {
          method: "POST",
          headers: { "Content-Type": file.type },
          body: file,
        });

        const uploadToken: UploadResponse = await uploadResponse.json();
        uploadTokens.push(uploadToken.upload.token);
      }
    }

    const body = await createZendeskRequestBody(uploadTokens);

    if (!body) return;

    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      console.log(
        `Request to Zendesk api failed with status code:${response.status} ${response.statusText}`
      );
    }
  };

  const subjectText: ReactNode = (
    <>
      <Typography variant="h2">Subject</Typography>
      <FoodWorksTextInput
        maxLength={SMALL_FIELD}
        className={classes.textBox}
        multiline={false}
        rows={1}
        placeholder={"Type subject here..."}
        onChange={(event) => setSubject(event.target.value)}
      />
    </>
  );

  const textArea: ReactNode = (
    <>
      <Typography className={classes.description} variant="h2">
        Description
      </Typography>
      <FoodWorksTextInput
        maxLength={LARGE_FIELD}
        className={classes.textBox}
        multiline={true}
        rows={7}
        placeholder={"Type feedback here..."}
        onChange={(event) => setDescription(event.target.value)}
      />
    </>
  );

  const imageUploader: ReactNode = (
    <ImageUploader
      buttonText="Choose an image"
      withPreview
      withIcon={false}
      label="Optional image upload"
      onChange={(files) => setUploadedImages(files ? files : null)}
      imgExtension={[".jpg", ".png"]}
      maxFileSize={5242880}
    />
  );

  const onClickSend = () => {
    sendFeedBack();
    onSend();
  };

  const body: ReactNode = (
    <div>
      {subjectText}
      {textArea}
      {imageUploader}
    </div>
  );

  const action: ReactNode = (
    <div>
      <Button onClick={onClose} color="default">
        Cancel
      </Button>
      <Button onClick={onClickSend} color="secondary">
        Send
      </Button>
    </div>
  );

  return (
    <BaseDialog
      open={true}
      onClose={onClose}
      title=""
      body={body}
      action={action}
      maxWidth="md"
    />
  );
};

export const FeedbackDialog = withFirebase(FeedbackDialogInner);

import {
  CommonMeasuresActions,
  ADD_COMMON_MEASURE,
  UPDATE_COMMON_MEASURE,
  SET_DEFAULT_COMMON_MEASURE,
  REMOVE_COMMON_MEASURE,
} from "../actions/commonMeasures";
import {
  CommonMeasures,
  CommonMeasure,
} from "../../../../data/models/documentProperties/measure";

export type CommonMeasuresState = CommonMeasures;

export const initialCommonMeasuresState: CommonMeasuresState = {
  default: "",
  measures: [],
};

export const commonMeasures = (
  state: CommonMeasuresState = initialCommonMeasuresState,
  action: CommonMeasuresActions
): CommonMeasuresState => {
  switch (action.type) {
    case REMOVE_COMMON_MEASURE:
      return {
        ...state,
        measures: state.measures.filter(
          (measure) => measure.id !== action.measure
        ),
      };
    case ADD_COMMON_MEASURE:
      return {
        ...state,
        measures: [...state.measures, action.measure],
      };
    case UPDATE_COMMON_MEASURE:
      return {
        ...state,
        measures: [...state.measures].map((measure: CommonMeasure) =>
          measure.id === action.updatedMeasure.id
            ? action.updatedMeasure
            : measure
        ),
      };
    case SET_DEFAULT_COMMON_MEASURE:
      return {
        ...state,
        default: action.measureId,
      };
    default:
      return state;
  }
};

import React, { ReactNode } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import InformationIcon from "@material-ui/icons/InfoOutlined";

import { appTheme } from "../../../../../../../styling/style";
import { InfoTooltip } from "../../../../../../common/InfoTooltip";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginTop: 3,
    marginBottom: 3,
    height: 40,
  },
  label: {
    marginRight: 10,
  },
  tooltipContainer: {
    width: 20,
  },
  contentContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  labelContainer: {
    width: 220,
  },
  componentContainer: {
    display: "flex",
    flex: 1,
  },
}));

interface ProfileItemProps {
  component: ReactNode;
  label: string;
  disabled?: boolean;
  tooltip?: string;
  isEmptyInfoTooltip?: string;
}

const ProfileItem = React.memo(
  ({
    component,
    label,
    disabled,
    tooltip,
    isEmptyInfoTooltip: invalidInfoTooltip,
  }: ProfileItemProps): JSX.Element => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <div className={classes.tooltipContainer}>
          {tooltip && <InfoTooltip title={tooltip} />}
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.labelContainer}>
            <Typography
              align="left"
              noWrap
              style={{
                color: disabled
                  ? appTheme.colors.gainsbruh
                  : appTheme.colors.xiketic,
              }}
              className={classes.label}
            >
              {label}
            </Typography>
          </div>
          <div className={classes.tooltipContainer}>
            {invalidInfoTooltip && (
              <InfoTooltip
                title={invalidInfoTooltip}
                icon={
                  <InformationIcon style={{ color: appTheme.colors.error }} />
                }
              />
            )}
          </div>
          <div className={classes.componentContainer}>{component}</div>
        </div>
      </div>
    );
  }
);

export default ProfileItem;

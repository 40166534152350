import React, { ReactNode } from "react";
import { Chip, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteGetTagProps } from "@material-ui/lab";

import { FoodFilter } from "../../../../constants/QuickFilter";
import { Tag } from "../../../../data/models/documentProperties/section";
import useUpdateDocumentFilters from "../hooks/useUpdateFilters";

const useStyles = makeStyles(() => ({
  colorButton: {
    height: 10,
    width: 10,
    minWidth: 10,
    marginRight: 5,
    borderRadius: 4,
  },
  tagMenuLabel: {
    display: "flex",
    alignItems: "center",
  },
  tag: {
    fontSize: 10,
    marginLeft: 1,
    marginRight: 1,
    borderRadius: 4,
    overflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 60,
  },
}));

const getLabel = (option: FoodFilter | Tag): string =>
  option instanceof FoodFilter ? option.text : option.label;

const getRenderOptions = (
  option: FoodFilter | Tag,
  classes: Record<"colorButton" | "tagMenuLabel", string>
): ReactNode =>
  option instanceof FoodFilter ? (
    <div>{option.text}</div>
  ) : (
    <div className={classes.tagMenuLabel}>
      <div
        className={classes.colorButton}
        style={{ backgroundColor: option.activeColor }}
      />
      {option.label}
    </div>
  );

const getTagsToRender = (
  filterTags: (Tag | FoodFilter)[],
  getTagProps: AutocompleteGetTagProps,
  classes: Record<"tag", string>
): ReactNode =>
  filterTags.map(
    (filterTag: Tag | FoodFilter, index: number): ReactNode =>
      filterTag instanceof FoodFilter ? (
        <Chip
          {...getTagProps({ index })}
          variant="default"
          size="small"
          key={filterTag.id}
          label={filterTag.text}
        />
      ) : (
        <Chip
          {...getTagProps({ index })}
          variant="default"
          size="small"
          key={filterTag.id}
          label={filterTag.label}
          className={classes.tag}
          style={{ backgroundColor: filterTag.activeColor }}
        />
      )
  );

interface FilterSelectionInputProps {
  filterOptions: (FoodFilter | Tag)[];
}

const DocumentFilter = ({
  filterOptions,
}: FilterSelectionInputProps): JSX.Element => {
  const classes = useStyles();

  const updateFilter = useUpdateDocumentFilters();

  return (
    <Autocomplete
      multiple
      openOnFocus
      onChange={(_, filter) => updateFilter(filter)}
      limitTags={1}
      options={filterOptions}
      getOptionLabel={(option) => getLabel(option)}
      renderOption={(option) => getRenderOptions(option, classes)}
      renderTags={(filterTags, getTagProps) =>
        getTagsToRender(filterTags, getTagProps, classes)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={true}
          label="Selected Quick Filters"
          data-cy="quickFilterInput"
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );
};

export default DocumentFilter;

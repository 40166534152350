import { RootState } from "../../reducers";
import { DatabasePropertiesState } from "../reducers/databaseProperties";

export const DatabasePropertiesSelector = (
  state: RootState
): DatabasePropertiesState => state.database.databaseProperties;

export const enabledDatasourcesSelector = (state: RootState): string[] =>
  state.database.databaseProperties.enabledDatasources;

export const displayedNutrientsSelector = (state: RootState): string[] =>
  state.database.databaseProperties.displayedNutrients;

export const enabledMeasuresSelector = (state: RootState): string[] => {
  return state.database.databaseProperties.enabledMeasures;
};

export const enabledNutrientsSelector = (state: RootState): string[] => {
  return state.database.databaseProperties.enabledNutrients;
};

export const IsAlternateIdsEnabledSelector = (state: RootState): boolean => {
  return state.database.databaseProperties.isAlternateIdsEnabled;
};

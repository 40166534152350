import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { Restore, Delete } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import {
  clientNameSelector,
  clientStateSelector,
} from "../../../../store/data/current_client/selectors/client";
import { RootState } from "../../../../store/reducers";
import SaveButton from "../../../common/buttons/SaveButton";
import useSaveClient from "./hooks/useSaveClient";
import { toggleClientState } from "../../../../store/data/current_client/action_creators/client";
import { appTheme } from "../../../../styling/style";
import { FoodworksTooltip } from "../../../common/InfoTooltip";


const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    margin: 7,
  },
  buttonContainer: {
    display: "flex",
  },
  titleButton: { width: 75 },
  deleteButtonContainer : {
    display: "flex", justifyContent: "center"
  }
}));

const ClientTitleBar = (): JSX.Element => {
  const classes = useStyles();

  const dispatch =useDispatch ();

  const onClientToggleDeleted = () => {
    dispatch(toggleClientState());
  };

  const clientName: string = useSelector<RootState, string>(clientNameSelector);

  const clientState: string =
    useSelector<RootState, string>(clientStateSelector);

  const {saveEnabled, saveClient} = useSaveClient();

  const deleteButton = (
    <FoodworksTooltip
      title={clientState === "archived" ? "Restore client" : "Archive client"}
    >
      <div className={classes.deleteButtonContainer}>
        <Button
          data-cy="deleteClient"
          size="small"
          disabled={saveEnabled}
          className={classes.titleButton}
          style={{
            color: saveEnabled
              ? "revert"
              : clientState === "archived"
              ? appTheme.colors.success
              : appTheme.colors.error,
          }}
          onClick={onClientToggleDeleted}
          startIcon={clientState === "archived" ? <Restore /> : <Delete />}
        >
          {clientState === "archived" ? "Restore" : "Archive"}
        </Button>
      </div>
    </FoodworksTooltip>
  );

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h1">
        {clientName}
      </Typography>
      <div className={classes.buttonContainer}>
        <SaveButton
          onClick={saveClient}
          disabled={!saveEnabled}
          tooltip="client"
        />
        {deleteButton}
      </div>
    </div>
  );
};

export default ClientTitleBar;

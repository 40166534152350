import React, { useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";

import { ReactComponent as TableViewIcon } from "../../../../../../../images/icons/TableViewIcon.svg";
import { appTheme } from "../../../../../../../styling/style";
import { FoodworksTooltip } from "../../../../../../common/InfoTooltip";
import PreviousEntriesDialog from "../../dialogs/PreviousEntriesDialog";

const useStyles = makeStyles(() => ({
  icon: {
    fill: appTheme.colors.primary,
  },
}));

interface Props {
  measurementTitle: string;
  measurementUnits: string;
}

const PreviousEntriesButton = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { measurementTitle, measurementUnits } = { ...props };

  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <FoodworksTooltip title="View all entries">
        <IconButton
          size="small"
          color="inherit"
          onClick={() => setShowDialog(true)}
        >
          <TableViewIcon className={classes.icon} />
        </IconButton>
      </FoodworksTooltip>
      {showDialog && (
        <PreviousEntriesDialog
          onClose={() => setShowDialog(false)}
          measurementUnits={measurementUnits}
          measurementTitle={measurementTitle}
        />
      )}
    </>
  );
};

export default PreviousEntriesButton;

import React, { ReactNode } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { useSelector } from "react-redux";

import {
  Measurement,
  measurementCategories,
  MeasurementCategory,
} from "../../../../../data/models/clientProperties/measurement";
import TrackingCategory from "./components/TrackingCategories";
import { RootState } from "../../../../../store/reducers";
import { activeClientMeasurementsByCategorySelector } from "../../../../../store/data/current_client/selectors/clientMeasurements";
import { appTheme } from "../../../../../styling/style";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
  },
  card: {
    width: "100%",
    overflowY: "auto",
    margin : "1rem",
    border : `1px solid ${appTheme.colors.gainsbruh}`,
    borderRadius : "4px"
  },
}));

const TrackingTab = React.memo((): JSX.Element => {
  const classes = useStyles();

  const measurementMap: Map<MeasurementCategory, Measurement[]> = useSelector<
    RootState,
    Map<MeasurementCategory, Measurement[]>
  >(activeClientMeasurementsByCategorySelector);

  return (
    <div className={classes.root}>
      <div className={classes.card} >
        {measurementCategories.map(
          (category: MeasurementCategory): ReactNode => (
            <TrackingCategory
              key={category}
              category={category}
              measurements={measurementMap.get(category) || []}
            />
          )
        )}
      </div>
    </div>
  );
});

export default TrackingTab;

import { Queue } from "queue-typescript";

import Firebase from "../Firebase/firebase";
import { Document } from "../models/document";
import { FoodItemState } from "../models/documentProperties/foodItem";
import { DocumentMap } from "../../store/data/reducers/documentCache";
import { FoodWorksError, CyclicDependencyError } from "../../constants/errors";
import { FoodId } from "../models/documentProperties/foodId";
import { ENABLED_DATA_SOURCES } from "../../constants/datasources";
import { CalculationMethod } from "../../constants/calculationMethod";

const FIREBASE_DOCUMENT_LIMIT = 10;

const fetchDocuments = async (
  firebase: Firebase,
  documentIds: string[],
  databaseId: string,
  isPublic: boolean
): Promise<[string, Document][]> => {
  const documents: [string, Document][] = await firebase.doGetListOfDocuments(
    isPublic,
    databaseId,
    documentIds
  );

  return documents;
};

export interface DocumentMapReturn {
  documentMap: DocumentMap;
  error?: FoodWorksError;
}

interface DocumentLineage {
  parentIds: Set<string>;
  documentId: string;
}

interface DocumentError {
  error?: FoodWorksError;
}

const initialiseQueueMap = (
  documentId: string,
  foodItemIds: FoodId[]
): Map<string, Queue<DocumentLineage>> => {
  const queueMap = new Map<string, Queue<DocumentLineage>>();

  foodItemIds.forEach((foodId: FoodId): void => {
    if (!queueMap.has(foodId.datasourceId!)) {
      queueMap.set(foodId.datasourceId!, new Queue<DocumentLineage>());
    }

    const documentLineage: DocumentLineage = {
      documentId: foodId.documentId!,
      parentIds: new Set<string>([documentId]),
    };
    queueMap.get(foodId.datasourceId!)?.enqueue(documentLineage);
  });

  return queueMap;
};

//Firebase can only retrieve 10 documents at a time
const getTenDocuments = async (
  firebase: Firebase,
  queue: Queue<DocumentLineage>,
  datasourceId: string,
  documentLineages: DocumentLineage[],
  isPublic: boolean
): Promise<[string, Document][]> => {
  while (queue.length && documentLineages.length < FIREBASE_DOCUMENT_LIMIT) {
    documentLineages.push(queue.dequeue());
  }

  const documentIdsToFetch = documentLineages.map(
    (documentLineage: DocumentLineage): string => documentLineage.documentId
  );

  const documents: [string, Document][] = await fetchDocuments(
    firebase,
    documentIdsToFetch,
    datasourceId,
    isPublic
  );

  return documents;
};

const processFoodItems = (
  foodItem: FoodItemState,
  documentMap: DocumentMap,
  queueMap: Map<string, Queue<DocumentLineage>>,
  parentDocumentId: string,
  parentDocumentIds: Set<string>,
  documentError: DocumentError
): void => {
  const datasourceId = foodItem.foodId!.datasourceId;
  const documentId = foodItem.foodId!.documentId;
  const identifier = new FoodId(foodItem.foodId!).identifier;

  if (parentDocumentIds.has(documentId)) {
    documentError.error = new CyclicDependencyError(
      documentMap[documentId].name,
      documentId
    );
    return;
  }

  if (!queueMap.has(datasourceId))
    queueMap.set(datasourceId, new Queue<DocumentLineage>());

  let documentIdValues: string[] = queueMap
    .get(datasourceId)!
    .toArray()
    .map((record) => record.documentId);

  if (
    documentMap.hasOwnProperty(identifier) ||
    documentIdValues.includes(identifier)
  )
    return;

  queueMap.get(datasourceId)!.enqueue({
    documentId: documentId,
    parentIds: parentDocumentIds.add(parentDocumentId),
  });
};

const processDocuments = (
  documentData: [string, Document],
  documentMap: DocumentMap,
  queueMap: Map<string, Queue<DocumentLineage>>,
  documentLineages: DocumentLineage[],
  datasourceId: string,
  documentError: DocumentError
): void => {
  const [documentId, document]: [string, Document] = documentData;
  documentMap[`${datasourceId}:${documentId}`] = document;

  const documentHasChildren: boolean = document.days.length > 0;
  const documentIsMapped: boolean =
    document.calculationMethod === CalculationMethod.MAPPED &&
    Boolean(document.documentMappingId);

  const parentDocumentIds: Set<string> = documentLineages.find(
    (documentLineage: DocumentLineage): boolean =>
      documentLineage.documentId === documentId
  )!.parentIds;

  if (documentIsMapped) {
    const [
      mappedDatasourceId,
      mappedDocumentId,
    ] = document.documentMappingId.split(":");
    if (!queueMap.has(mappedDatasourceId))
      queueMap.set(mappedDatasourceId, new Queue<DocumentLineage>());
    queueMap.get(mappedDatasourceId)!.enqueue({
      documentId: mappedDocumentId,
      parentIds: parentDocumentIds.add(documentId),
    });
  } else if (documentHasChildren) {
    for (const day of document.days) {
      for (const section of day.sections) {
        for (const foodItem of section.foodItems) {
          if (!foodItem.foodId) continue;
          processFoodItems(
            foodItem,
            documentMap,
            queueMap,
            documentId,
            parentDocumentIds,
            documentError
          );
          if (documentError.error) return;
        }
      }
    }
  }
};

export const getChildDocuments = async (
  firebase: Firebase,
  documentId: string,
  foodItemIds: FoodId[]
): Promise<DocumentMapReturn> => {
  const queueMap = initialiseQueueMap(documentId, foodItemIds);
  const documentMap: DocumentMap = {};
  const documentError: DocumentError = {};

  const notAllQueuesAreEmpty = (): boolean =>
    Array.from(queueMap.values()).find((queue) => queue.length > 0) !==
    undefined;

  while (notAllQueuesAreEmpty()) {
    if (documentError.error)
      return { documentMap: documentMap, error: documentError.error };

    for (const [datasourceId, queue] of Array.from(queueMap.entries())) {
      if (documentError.error)
        return { documentMap: documentMap, error: documentError.error };
      if (!queue.length) continue;

      const documentLineages: DocumentLineage[] = [];
      const documents: [string, Document][] = await getTenDocuments(
        firebase,
        queue,
        datasourceId,
        documentLineages,
        //TODO:  Will need to check if datasource is public or not in future
        ENABLED_DATA_SOURCES.includes(datasourceId)
      );

      documents.forEach((documentData: [string, Document]): void => {
        if (documentError.error) return;
        processDocuments(
          documentData,
          documentMap,
          queueMap,
          documentLineages,
          datasourceId,
          documentError
        );
      });
    }
  }

  return { documentMap: documentMap, error: documentError.error };
};

import React from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateRangeRounded } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  createMuiTheme,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  TextFieldProps,
  Theme,
  ThemeProvider,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { appTheme } from "../../../styling/style";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 35,
  },
  root: {
    display: "flex",
    alignItems: "start",
  },
  inputRoot: {
    display: "flex",
    paddingRight: 0,
    "&.Mui-error": {
      border: `2px solid ${appTheme.colors.error}`,
      borderRadius: "4px",
    },
  },
  calendarInput: {
    border: "none",
    borderRadius: 4,
    position: "relative",
    fontSize: appTheme.fontSize.textInput,
    fontFamily: appTheme.fontFamily.textInput,
    margin: 1,
    "&:focus": {
      margin: 0,
    },
    "&:disabled": {
      background: appTheme.colors.gainsbruh,
    },
  },
}));

const materialCalendarTheme = createMuiTheme({
  overrides: {
    //@ts-ignore
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: appTheme.colors.secondary,
      },
    },
    MuiPickersDay: {
      day: {
        color: appTheme.colors.xiketic,
      },
      daySelected: {
        color: appTheme.colors.primary,
        "&:nth-child(1)": {
          color: "white",
        },
      },
      dayDisabled: {
        color: appTheme.colors.gainsbruh,
      },
      current: {
        color: appTheme.colors.primary,
      },
    },
  },
});

interface FoodworksDatePickerProps {
  date: Date | null;
  handleChange: (date: MaterialUiPickersDate) => void;
  disabled?: boolean;
  onBlur?: () => void;
  onClick?: () => void;
  placeholder?: Date | null;
}

// This must be declared outside of the picker component to prevent rebuilding on typing
const TextFieldComponent = (
  params: React.PropsWithChildren<TextFieldProps>
) => {
  const classes = useStyles();
  return (
    <TextField
      {...params}
      className={classes.calendarInput}
      classes={{
        root: classes.inputRoot,
      }}
    />
  );
};

const FoodworksDateTimePicker = (props: FoodworksDatePickerProps) => {
  const { date, handleChange, disabled, onBlur, onClick, placeholder } = {
    ...props,
  };

  const calendarInputProps = {
    height: 30,
    padding: 0,
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialCalendarTheme}>
          <KeyboardDateTimePicker
            placeholder={placeholder?.toLocaleDateString() || ""}
            onBlur={onBlur ? () => onBlur!() : () => null}
            onClick={onClick ? () => onClick!() : () => null}
            disabled={disabled ? disabled : false}
            allowKeyboardControl
            disableFuture
            clearable
            views={["date"]}
            size="medium"
            variant="dialog"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            value={date}
            InputProps={{
              style: calendarInputProps,
              endAdornment: (
                <InputAdornment position={"end"}>
                  <IconButton size="small">
                    <DateRangeRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            TextFieldComponent={TextFieldComponent}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default FoodworksDateTimePicker;

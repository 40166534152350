import React, { useMemo } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { useResizeDetector } from "react-resize-detector";

import { appTheme } from "../../../../../styling/style";
import FoodRecordTable from "./components/FoodRecordTable";
import MealPlanTable from "./components/MealPlanTable";
import useNutritionPaneWidth from "../../../databases/nutrition_pane/hooks/useNutritionPaneWidth";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `solid 1px ${appTheme.colors.gainsbruh}`,
    borderRadius: 4,
    margin: 25,
    padding: 25,
  },
}));

const DocumentsTab = (): JSX.Element => {
  const classes = useStyles();

  const { width, ref } = useResizeDetector<HTMLDivElement>();

  const stackTables: boolean = useMemo(() => !!width && width < 1800, [width]);

  const nutritionPaneWidth: number = useNutritionPaneWidth();

  return (
    <div
      ref={ref}
      className={classes.root}
      style={{ width: `calc(99% - ${nutritionPaneWidth + 80}px)` }}
    >
      <div
        style={{
          flexDirection: stackTables ? "column" : "row",
          display: stackTables ? "inline" : "flex",
        }}
      >
        <FoodRecordTable stackTables={stackTables} />
        <MealPlanTable stackTables={stackTables} />
      </div>
    </div>
  );
};

export default DocumentsTab;

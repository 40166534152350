import { ThunkAction } from "redux-thunk";

import Firebase from "../../../../data/Firebase";
import {
  Database,
  defaultDatabase,
} from "../../../../data/models/userDatabase";
import { ScreenType } from "../../../../types";
import { RootState } from "../../../reducers";
import { ExtraArguments } from "../../../store";
import { setNavIsLoading } from "../../../ui/actionCreators/navigationActionCreators";
import { IActionSetNavIsLoading } from "../../../ui/actions/navigationActions";
import { setDatabase } from "../../action-creators/database";
import { IActionsSetDatabase } from "../../actions/database";
import { clientUserDatabaseSelector } from "../../selectors/clientDatabase";
import { databaseIdSelector } from "../../selectors/database";

const updateLastScreen = (screen: ScreenType, firebase: Firebase) => {
  firebase.users.doUpdateLastScreen(firebase.auth.currentUser!.uid, screen);
};

const handleCurrentDatabaseChange =
  (
    nextDatabaseId: string
  ): ThunkAction<
    Promise<void>,
    RootState,
    ExtraArguments,
    IActionSetNavIsLoading | IActionsSetDatabase
  > =>
  async (dispatch, getState, { firebase }) => {
    const currentDatabaseId: string = databaseIdSelector(getState());

    const clientUserDatabaseId: string = clientUserDatabaseSelector(getState());

    const navigatingIntoClients: boolean =
      currentDatabaseId !== clientUserDatabaseId &&
      nextDatabaseId === clientUserDatabaseId;

    const navigatingIntoDatabases: boolean =
      currentDatabaseId === clientUserDatabaseId &&
      nextDatabaseId !== clientUserDatabaseId;

    const navigatingToNewDatabase: boolean =
      !!nextDatabaseId && nextDatabaseId !== clientUserDatabaseId;

    if (currentDatabaseId !== nextDatabaseId) {
      if (navigatingIntoClients) {
        updateLastScreen("clients", firebase);
      } else if (navigatingIntoDatabases) {
        updateLastScreen("databases", firebase);
      }

      const database: Database | undefined = nextDatabaseId
        ? await firebase.userDatabases.doGetUserDatabase(nextDatabaseId)
        : undefined;

      database
        ? dispatch(setDatabase(database))
        : dispatch(setDatabase(defaultDatabase));

      if (navigatingToNewDatabase) {
        firebase?.users.doUpdateUserLastUsedDatabase(
          firebase.auth.currentUser!.uid,
          nextDatabaseId
        );
      }
    }

    dispatch(setNavIsLoading(false));
  };

export default handleCurrentDatabaseChange;

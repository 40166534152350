import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import { Button } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";

import { Tag } from "../../../data/models/documentProperties/section";
import {
  addSectionTags,
  updateSectionTag,
} from "../../../store/data/current-document/action-creators/sectionTags";
import { sectionTagsSelector } from "../../../store/data/current-document/selectors/sectionTags";
import { RootState } from "../../../store/reducers";
import { appTheme } from "../../../styling/style";
import { DisabledButtonWithToolTip } from "../../common/InfoTooltip";
import { SectionTagCreation } from "./components/SectionTagCreation";
import { BaseDialog } from "../BaseDialog";

interface SimpleCreateSectionTagDialogProps {
  onClose: () => void;
  isEditing: boolean;
  tagId?: string;
}

export const SimpleCreateSectionTagDialog: FunctionComponent<SimpleCreateSectionTagDialogProps> =
  ({ onClose, isEditing, tagId }) => {
    const dispatch = useDispatch();

    const onCreateNewTag = (tag: Tag) => dispatch(addSectionTags([tag]));

    const onUpdateTag = (tag: Tag) => dispatch(updateSectionTag(tag));

    const documentSectionTags: Tag[] =
      useSelector<RootState, Tag[]>(sectionTagsSelector);

    const [tagLabel, setTagLabel] = useState("");
    const [colorIndex, setColorIndex] = useState(0);

    useEffect(() => {
      if (tagId) {
        const sectionTag: Tag = documentSectionTags.find(
          (tag: Tag): boolean => tag.id === tagId
        )!;
        setTagLabel(sectionTag.label);
        setColorIndex(
          appTheme.colors.activeTags.indexOf(sectionTag.activeColor)
        );
      }
    }, [tagId, documentSectionTags]);

    const labelInputRef = useRef<HTMLInputElement>(null);

    const allSectionTagLabels: string[] = documentSectionTags.map(
      (tag: Tag): string => tag.label
    );

    const onClickSaveTag = () => {
      if (isEditing) {
        onUpdateTag({
          id: tagId!,
          label: tagLabel,
          activeColor: appTheme.colors.activeTags[colorIndex],
          inactiveColor: appTheme.colors.inactiveTags[colorIndex],
        });
        onClose();
      } else {
        onCreateNewTag({
          id: uuidv4(),
          label: tagLabel,
          activeColor: appTheme.colors.activeTags[colorIndex],
          inactiveColor: appTheme.colors.inactiveTags[colorIndex],
        });
        labelInputRef.current?.focus();
        labelInputRef.current?.select();
      }
    };

    const dialogActions: ReactNode = (
      <div>
        {[
          <Button
            key="simpleCreateSectionTagDialogClose"
            onClick={onClose}
            color="default"
          >
            Close
          </Button>,
          <DisabledButtonWithToolTip
            key="simpleCreateSectionTagDialogDisabledButton"
            disabled={
              !tagLabel ||
              (!isEditing && allSectionTagLabels.includes(tagLabel.trim())) ||
              (isEditing &&
                !!documentSectionTags.find(
                  (tag: Tag): boolean =>
                    tag.id !== tagId && tagLabel.trim() === tag.label
                ))
            }
            label="Save tag"
            disabledTooltip="Tag label must be unique"
            buttonProps={{ onClick: onClickSaveTag, color: "secondary" }}
          />,
        ]}
      </div>
    );

    const body: ReactNode = (
      <div>
        <SectionTagCreation
          tagLabel={tagLabel}
          colorIndex={colorIndex}
          setTagLabel={setTagLabel}
          setColorIndex={setColorIndex}
          ref={labelInputRef}
        />
      </div>
    );

    return (
      <BaseDialog
        onClose={onClose}
        open={true}
        title={isEditing ? "Edit section tag" : "Create a new section tag"}
        body={body}
        action={dialogActions}
        maxWidth="md"
      ></BaseDialog>
    );
  };

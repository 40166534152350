import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  RefObject,
} from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

import NutrientAnalysis from "./NutrientAnalysis";
import { appTheme } from "../../../../styling/style";
import { AccordionProps } from "../../../common/AccordionList";
import { RootState } from "../../../../store/reducers";
import {
  NutrientValuesSelector,
  templateIdSelector,
} from "../../../../store/data/current-document/selectors/document";
import {
  FoodWorksError,
  YieldWeightError,
  CyclicDependencyError,
  MappedDocumentError,
} from "../../../../constants/errors";
import { CurrentErrorSelector } from "../../../../store/selectors/errorSelectors";
import { LoadingBarPadding } from "../../../common/LoadingBarPadding";
import { FoodItemPosition } from "../../../../data/models/foodItemPosition";
import { selectedRowsSelector } from "../../../../store/ui/selectors/recipeGrid";
import { isRecipe, isFood } from "../../../../constants/FoodTemplate";
import { CompositionSelectionBar } from "./CompositionSelectionBar";
import { SourcesGraph, titleBarHeight } from "./IngredientSources/SourcesGraph";
import { currentRouteSelector } from "../../../../store/ui/selectors/routing";
import NRVAnalysis from "./NRVAnalysis";
import { NutrientValues } from "../../../../data/models/nutrientValue";
import useNutrientValues from "./hooks/useNutrientValues";
import NutritionPaneBody from "./components/NutritionPaneBody";
import CollapsePaneButton from "../../navigation/shared/CollapsePaneButton";
import { setNutritionPaneDimensions } from "../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import { RouteData } from "../../../../data/routing/types";

const useStyles = makeStyles(() => ({
  resizable: {
    flex: 1,
    background: appTheme.colors.white[8],
    display: "flex",
    flexDirection: "column",
    alignContent: "start",
    overflowX: "auto",
    paddingTop: 8,
  },
  nutritionPaneError: {
    marginLeft: 15,
    marginRight: 15,
    flex: 1,
    display: "flex",
    justifyContent: "start",
    maxHeight: 100,
  },
  header: {
    display: "flex",
    justifyContent: "start",
  },
  tooltip: {
    alignSelf: "flex-end",
    margin: 5,
  },
  paneBodyWrapper: {
    display: "flex",
    flex: 1,
  },
}));

const isNutritionPaneError = (error: FoodWorksError): boolean =>
  error instanceof YieldWeightError ||
  error instanceof CyclicDependencyError ||
  error instanceof MappedDocumentError;

export interface NutritionPanelData {
  values: AccordionProps[];
  toggledCallback?: (id: number) => void;
}

export const NutritionPane: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedRows: FoodItemPosition[] =
    useSelector<RootState, FoodItemPosition[]>(selectedRowsSelector);

  const documentTemplateId: string =
    useSelector<RootState, string>(templateIdSelector);

  const currentError: FoodWorksError =
    useSelector<RootState, FoodWorksError>(CurrentErrorSelector);

  const currentRoute: RouteData | undefined =
    useSelector<RootState, RouteData | undefined>(currentRouteSelector);

  const nutrientValues: NutrientValues = useSelector<RootState, NutrientValues>(
    NutrientValuesSelector
  );

  const clientNutrientValues: NutrientValues = useNutrientValues();

  const [showNutrientAnalysis, setShowNutrientAnalysis] =
    useState<boolean>(true);
  const showSourcesGraph = useState<boolean>(true);
  const [showClientGraphToggle] = useState(currentRoute?.screen === "clients");
  const [showGraphMode, setShowGraphMode] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const height: number | undefined = ref.current?.clientHeight;
    const width: number | undefined = ref.current?.clientWidth;

    if (height && width)
      dispatch(setNutritionPaneDimensions({ height, width }));
  }, [open, dispatch]);

  useEffect(() => {
    isNutritionPaneError(currentError)
      ? setShowNutrientAnalysis(false)
      : setShowNutrientAnalysis(true);
  }, [currentError]);

  const daysContainingSelectedItems = () =>
    selectedRows.reduce<string[]>((days, position) => {
      if (!days.includes(position.day.toString())) {
        days.push(position.day.toString());
      }
      return days;
    }, []).length;

  const selectedItemsText =
    isRecipe(documentTemplateId) || isFood(documentTemplateId)
      ? `Calculation based on ${selectedRows.length} selected item(s).`
      : `Calculation based on ${
          selectedRows.length
        } selected item(s) across ${daysContainingSelectedItems()} day(s)`;

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        right: 0,
        display: "flex",
        height: `calc(100% - ${titleBarHeight}px - 10px)`,
        zIndex: 100,
      }}
    >
      <div className={classes.paneBodyWrapper}>
        <NutritionPaneBody
          open={open}
          toggleOpen={() => setOpen(prev => !prev)}
        >
          <div className={classes.resizable}>
            <LoadingBarPadding />
            <div className={classes.header}>
              <CollapsePaneButton
                open={open}
                setOpen={() => setOpen(prev => !prev)}
                type="Nutrition"
              />
              <CompositionSelectionBar
                showGraphMode={showGraphMode}
                setShowGraphMode={setShowGraphMode}
                showGraphToggle={showClientGraphToggle}
              />
            </div>
            {selectedRows.length ? (
              <Typography color="secondary" className={classes.tooltip}>
                {selectedItemsText}
              </Typography>
            ) : null}
            {showNutrientAnalysis && !showGraphMode && (
              <NutrientAnalysis
                nutrientValues={
                  currentRoute?.documentId
                    ? nutrientValues
                    : clientNutrientValues
                }
              />
            )}
            <NRVAnalysis toggle={showNutrientAnalysis && !showGraphMode} />
            {showSourcesGraph ? (
              <SourcesGraph />
            ) : (
              <Alert className={classes.nutritionPaneError} severity="error">
                Error calculating nutrition graph.
              </Alert>
            )}
          </div>
        </NutritionPaneBody>
      </div>
    </div>
  );
};
